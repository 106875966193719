import { useState } from 'react'

type Props = {
  children: JSX.Element
}
const Obfuscator = (props: Props) => {
  const [reveal, setReveal] = useState(false)

  return <span ref="text" style={{ cursor: 'pointer', position: 'relative' }} onClick={() => setReveal(!reveal)}>
    {(reveal) ? props.children : <span style={{ fontFamily: 'auto' }}>░░░░░░░░░░░░░░░░░░░░░░░░░<span style={{ position: 'absolute', left: '70px' }}>click to reveal</span></span>}
  </span>
}

export default Obfuscator
