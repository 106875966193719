import React, { Component } from 'react'

import { 
  Alert,
  Select,
  Tag
} from 'antd';
import t from '../../utils/translate'

class SelectFacebookAudience extends Component<any, any> {

  constructor(props: any) {
    super(props)

    this.state = {
      loading: true,
      audiences: [],
    }

    this.fetchAudiences = this.fetchAudiences.bind(this)
  }

  componentDidMount() {
    this.fetchAudiences()
  }

  fetchAudiences() {
    this.props.app.ajaxRequest({
      method: 'get',
      url: '/integrations.listFacebookAudiences',
      params: {
        projectId: this.props.project.id,
        integrationId: this.props.integrationId
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({loading: false})
        return
      }
      
      // console.log('audiences', response.data);

      this.setState({
        loading: false,
        audiences: response.data.audiences || []
      })
    })
  }

  render() {
    return <React.Fragment>
    <Alert className="margin-b-m" message={t('facebook_audience_size_info', "Make sure your audience contains at least 100 users, otherwise Facebook will refuse to remove users from it!")} type="info" showIcon />
    <Select 
      placeholder={t('select_a_value', "Select a value")}
      value={this.props.value ? this.props.value.id : undefined}
      onChange={(audienceId) => {
        const audience = this.state.audiences.find((x: any) => x.id === audienceId)
        this.props.onChange({
          id: audienceId, 
          name: audience ? audience.name : ''
        })
      }}
      loading={this.state.loading}
    >
      {this.state.audiences.map((audience: any) => {
        return <Select.Option key={audience.id} disabled={audience.is_value_based} value={audience.id}>{audience.name} {audience.is_value_based && <Tag>With LTV</Tag>}</Select.Option>
      })}
    </Select>
    </React.Fragment>
  }
}

export default SelectFacebookAudience



