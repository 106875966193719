const colors: any = {
  cyan: 'rgba(3, 178, 214, 1)', 
  cyanLight: 'rgba(3, 178, 214, 0.3)',
  magenta: 'rgba(233, 30, 99, 1)',
  magentaLight: 'rgba(233, 30, 99, 0.3)',
  purple: 'rgba(103, 58, 183, 1)',
  purpleLight: 'rgba(103, 58, 183, 0.3)',
  indigo: 'rgba(63,81,181, 1)',
  indigoLight: 'rgba(63,81,181, 0.3)',
  orange: 'rgba(255, 87, 34, 1)',
  orangeLight: 'rgba(255, 87, 34, 0.3)',
  teal: 'rgba(0, 150, 136, 1)',
  tealLight: 'rgba(0, 150, 136, 0.3)',
  lime: 'rgba(139, 195, 74, 1)',
  limeLight: 'rgba(139, 195, 74, 0.3)'
}

export default colors