import React, { Component } from 'react'
import {
  Button,
  // Tooltip,
  Table,
  Badge,
  // Modal,
  Tag,
} from 'antd';
import t from '../../utils/translate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Formatters from '../../utils/formatters'
import { InfoCircleFilled } from '@ant-design/icons';
import CommissionSystemPopover from '../commissionGroups/_popover'
import AppLayout from 'components/app/layout';

class MediaPartnersPartnerships extends Component<any, any> {

  // constructor(props: any) {
  //   super(props)

    // this.initPaymentConfirm = this.initPaymentConfirm.bind(this)
    // this.initPaymentRequest = this.initPaymentRequest.bind(this)
  // }

  componentDidMount() {
    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Media Partners Partnerships',
        page: this.props.location.pathname,
        props: {
          orgId: this.props.organizationLayout.organization.organizationId
        }
      })
      window.cmAgent.dispatch()
    }
  }

  render() {

    return <AppLayout currentOrganization={this.props.organizationLayout.organization} admin={this.props.app.state.admin} firebaseUser={this.props.app.state.firebaseUser} projects={this.props.organizationLayout.projects}>
        <h1>Partnerships</h1>

        <Table
          dataSource={this.props.organizationLayout.organization.partnerships}
          rowKey={record => record.partnershipId + record.advertiserProjectId}
          className="edge-table block"
          size="middle"
          pagination={false}
          columns={[
            {
              title: 'Partnership',
              key: 'partnership',
              render: (record: any) => {
                return record.advertiserProjectName + ' - ' + record.partnershipId
              }
            },
            {
              title: t('status', "Status"),
              key: 'status',
              render: (record: any) => {
                if (record.status === 'activated') {
                  return <Badge color="green" text={t('activated', "Activated")} />
                }
                if (record.status === 'paused') {
                  return <Badge color="orange" text={t('paused', "Paused")} />
                }
                if (record.status === 'deleted') {
                  return <Badge status="default" text={t('deleted', "Deleted")} />
                }
              }
            },
            {
              title: t('channels', "Channels") + ' - ' + t('commission_group', "Commission group"),
              key: 'channels',
              render: (record: any) => {

                if (record.channels.length === 0) return '-'

                return record.channels.map((ch: any) => {

                  return <div key={ch.id} className="size-12 padding-b-s">
                    <span>{ch.name} ({ch.groupId}) <span className="padding-r-s">-</span></span>
                    <CommissionSystemPopover commissionSystem={ch.hasCustomCommission ? ch.customCommissionSystem : ch.commissionGroup.commissionSystem} currency={record.advertiserCurrency}>
                      {ch.hasCustomCommission === true && <span className="link"><InfoCircleFilled /> {t('customized', "Customized")}</span>}
                      {!ch.hasCustomCommission && <span>{!ch.commissionGroup ? '(no group)' : <Tag className="small" color={ch.commissionGroup.color}>{ch.commissionGroup.name}</Tag>}</span>}
                    </CommissionSystemPopover>
                  </div>
                })
              }
            },
            {
              title: t('waiting', "Waiting"),
              key: 'waiting',
              render: (record: any) => {
                return <span className="color-orange">{Formatters.currency(record.advertiserCurrency, record.commissionsWaiting || 0)}</span>
              }
            },
            {
              title: t('approved', "Approved"),
              key: 'approved',
              render: (record: any) => {
                return <span className="color-green">{Formatters.currency(record.advertiserCurrency, record.commissionsApproved || 0)}</span>
              }
            },
            {
              title: t('paid', "Paid"),
              key: 'paid',
              render: (record: any) => {
                return <span className="color-grey">{Formatters.currency(record.advertiserCurrency, record.commissionsPaid || 0)}</span>
              }
            },
            {
              key: 'actions',
              title: <span className="pull-right"><Button type="ghost" size="small" onClick={this.props.organizationLayout.refreshPartnerships}><FontAwesomeIcon icon="sync-alt" /></Button></span>,
              className: 'text-right',
              render: (record: any) => <Button.Group>
              </Button.Group>
            },
          ]}
        />
      </AppLayout>
  }
}

export default MediaPartnersPartnerships