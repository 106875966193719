import React, { useState } from 'react'
import { 
  Modal,
  Form,
  Input,
  Button,
} from 'antd';
import t from '../../utils/translate'

const TheButton = ({btnType, btnSize, btnContent, sessionId, changeCommissionStatus}: any) => {
  const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const onClicked = () => {
    setModalVisible(true)
  }

  const onFinish = (values: any) => {
    // console.log('values', values);
    
    if (loading) return

    setLoading(true)

    changeCommissionStatus(sessionId, "cancelled", values.cancelledFor, (err: any) => {

      setLoading(false)

      if (!err) {
        form.resetFields()
        setModalVisible(false)
      }
    })
  }

  return <React.Fragment>
    <Button type={btnType || 'primary'} size={btnSize || 'small'} onClick={onClicked}>{btnContent}</Button>

    <Modal
      visible={modalVisible}
      title={t('cancel_commission', "Cancel commission")}
      okText={t('confirm', "Confirm")}
      okButtonProps={{loading: loading}}
      cancelButtonProps={{loading: loading}}
      cancelText={t('cancel', "Cancel")}
      onCancel={() => { setModalVisible(false) }}
      onOk={() => {
        form.validateFields().then((values: any) => {
            form.resetFields();
            onFinish(values);
          })
          .catch(info => { console.log('Validate Failed:', info); });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
      >
        <Form.Item
          name="cancelledFor"
          label={t('cancellation_reason', "Cancellation reason")}
          rules={[
            { required: true, type: 'string', max: 60 }
          ]}
        >
          <Input maxLength={60} />
        </Form.Item>
      </Form>
    </Modal>
  </React.Fragment>
}

export default TheButton