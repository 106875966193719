import React, { useState } from 'react'

import t from '../../utils/translate'
import { Button, Input, Drawer, Form, Select, Switch, Radio } from 'antd';
// import _ from 'lodash'

const SubscribeTopicButton = (props: any) => {

    const [showDrawer, setShowDrawer] = useState(false)
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()

    const toggleDrawer = () => {
        setShowDrawer(!showDrawer)
    }

    const onFinish = (values: any) => {

        if (loading) return

        values.projectId = props.project.id

        setLoading(true)

        props.app.ajaxRequest({
            method: 'post',
            url: '/data.import',
            data: {
                projectId: props.project.id,
                id: 'subscribe_' + props.user.id + '_' + new Date().getTime(),
                sync: true,
                items: [{
                    id: 'subscribe_' + new Date().getTime(),
                    kind: 'subscribeToNotificationTopic',
                    subscribeToNotificationTopic: {
                        externalUserId: props.user.externalId,
                        userIsAuthenticated: props.user.isAuthenticated,
                        createdAt: new Date().toISOString(),
                        notificationTopicId: values.notificationTopicId,
                        channel: values.channel,
                        recipient: values.recipient,
                        collectionItemExternalId: values.collectionItemExternalId || '',
                        skipDoubleOptIn: values.skipDoubleOptIn,
                    }
                }]
            }
        }, (errorMessage: any, _response: any) => {

            if (errorMessage) {
                props.app.addMessage('error', errorMessage)
                setLoading(false)
                return
            }

            props.app.addMessage('success', t('subscription_added', 'The subscription has been added.'))

            form.resetFields()

            setLoading(false)
            setShowDrawer(false)

            // console.log('response', response.data);

            if (window.cmAgent) {
                window.cmAgent.event({
                    label: 'addSubscription',
                    props: {
                        orgId: props.organizationLayout.organization.id,
                        projectId: props.project.id,
                    }
                })
                window.cmAgent.dispatch()
            }

            if (props.onComplete) {
                props.onComplete()
            }
        })
    }


    if (!showDrawer) {
        return <Button block={props.block || false} type={props.type || "default"} style={props.style || {}} size={props.size} onClick={toggleDrawer}>{props.children}</Button>
    }

    let initialValues = {}

    if (props.notificationTopic) {
        initialValues = { ...props.notificationTopic }
    }

    return <Drawer
        title={props.title}
        visible={true}
        onClose={toggleDrawer}
        className="drawer drawer-form"
        width={960}>
        <Form form={form} initialValues={initialValues} labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} layout="horizontal" className="margin-a-m margin-b-xl" onFinish={onFinish}>
            <Form.Item
                name="notificationTopicId"
                label="Notification topic"
                rules={[{ required: true, type: 'string' }]}
            >
                <Select>
                    {props.notificationTopics.map((x: any) => <Select.Option key={x.id} value={x.id}>{x.name}</Select.Option>)}
                </Select>
            </Form.Item>

            <Form.Item noStyle shouldUpdate>
                {(funcs) => {
                    const topicId = funcs.getFieldValue('notificationTopicId')

                    if (topicId) {
                        const topic = props.notificationTopics.find((x: any) => x.id === topicId)
                        if (!topic) return

                        return <>
                            {topic.kind === 'collection' && <Form.Item name="collectionItemExternalId" label={t('collection_item_ext_id', 'Collection item ext. ID')} rules={[{ required: true, type: 'string' }]}>
                                <Input />
                            </Form.Item>}

                            <Form.Item
                                name="channel"
                                label="Channel"
                                rules={[{ required: true, type: 'string' }]}
                            >
                                <Radio.Group onChange={(e: any) => {
                                    if (e.target.value === 'email') {
                                        funcs.setFieldsValue({ recipient: props.user.email })
                                    }
                                    if (e.target.value === 'sms') {
                                        funcs.setFieldsValue({ recipient: props.user.telephone })
                                    }
                                }}>
                                    {topic.withEmail && <Radio.Button value="email">Email</Radio.Button>}
                                    {topic.withSMS && <Radio.Button value="sms">SMS</Radio.Button>}
                                </Radio.Group>
                            </Form.Item>
                        </>
                    }
                }}
            </Form.Item>

            <Form.Item noStyle shouldUpdate>
                {(funcs) => {
                    const channel = funcs.getFieldValue('channel')

                    if (channel === 'email') return <Form.Item
                        name="recipient"
                        label="Email"
                        rules={[{ required: true, type: 'email' }]}
                    >
                        <Input />
                    </Form.Item>

                    if (channel === 'sms') return <Form.Item
                        name="recipient"
                        label="Telephone"
                        rules={[{ required: true, type: 'string' }]}
                    >
                        <Input />
                    </Form.Item>
                }}
            </Form.Item>

            <Form.Item
                name="skipDoubleOptIn"
                label={t('skip_double_opt_in', "Skip double opt-in")}
                rules={[{ required: false, type: 'boolean' }]}
                valuePropName="checked"
            >
                <Switch />
            </Form.Item>

            <div className="ant-drawer-footer">
                <Button style={{ marginRight: 8 }} onClick={toggleDrawer}>{t('cancel', "Cancel")}</Button>
                <Button type="primary" loading={loading} htmlType="submit">{t('confirm', "Confirm")}</Button>
            </div>
        </Form>
    </Drawer >
}


export default SubscribeTopicButton


