import React, { useState } from 'react'

import t from '../../utils/translate'
import { Button, Spin, Input, Select, Modal, Form } from 'antd';
// import _ from 'lodash'

const AdminsInviteButton = (props: any) => {

  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm();

  const toggleModal = () => {
    setVisible(!visible)
  }

  const onSubmit = () => {

    form.validateFields().then((values: any) => {
      form.resetFields()
      setLoading(true)

      let data = {
        organizationId: props.organization.id,
        email: values.email,
        language: values.language
      }

      props.app.ajaxRequest({
        method: 'post',
        url: '/organizations.inviteAdmin',
        data: data
      }, (errorMessage: any) => {

        if (errorMessage) {
          props.app.addNotification('error', 'Invitation error', errorMessage)
          setLoading(false)
          return
        }

        if (window.cmAgent) {
          window.cmAgent.event({
            label: 'inviteAdmin',
            props: {
              orgId: props.organization.id,
            }
          })
          window.cmAgent.dispatch()
        }

        setLoading(false)
        setVisible(false)
      })
    }).catch((e: any) => {
      console.error(e)
    })
  }

  return <span className="margin-r-m">
    <Button size={props.size} type={props.type || 'default'} style={props.style || {}} onClick={toggleModal}>{t('invite_a_colleague', "Invite a colleague")}</Button>

    {visible &&
      <Modal
        title={t('invite_a_colleague', "Invite a colleague")}
        wrapClassName="vertical-center-modal"
        visible={true}
        onCancel={toggleModal}
        footer={[
          <Button key="back" type="ghost" loading={loading} onClick={toggleModal}>{t('cancel', "Cancel")}</Button>,
          <Button key="submit" type="primary" loading={loading} onClick={onSubmit}>{t('confirm', "Confirm")}</Button>,
        ]}>

        <Spin tip="Loading..." spinning={loading}>
          <Form form={form} initialValues={{ language: props.app.state.admin.language }} layout="vertical">
            <Form.Item
              name="email"
              label={t('email_address', "Email address")}
              rules={[{ type: 'email', required: true, message: t('field_required', "This field is required") }]}
            >
              <Input type="email" />
            </Form.Item>

            <Form.Item
              name="language"
              label={t('preferred_language', "Preferred language")}
              rules={[{ type: 'string', required: true, message: t('field_required', "This field is required") }]}
            >
              <Select>
                <Select.Option value="en">English</Select.Option>
                <Select.Option value="fr">Français</Select.Option>
              </Select>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    }
  </span>
}

export default AdminsInviteButton