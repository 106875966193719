import React, { useState } from 'react'

import { Input, Button, Drawer, Select, Form, Tag, Tabs } from 'antd';
import { ClockCircleFilled, ThunderboltFilled, SettingFilled, BranchesOutlined } from '@ant-design/icons';
import t from '../../utils/translate'
import _ from 'lodash'
import SegmentsCascader from '../segments/_cascader'
import WorkflowTriggers from './_triggers'
import WorkflowEditor from './_workflowEditor'
import { v4 as uuidv4 } from 'uuid'
import InfoRadioGroup from '../common/infoRadio'


const WorkflowsNew = (props: any) => {

  const [showDrawer, setShowDrawer] = useState(false)
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm();
  const [tab, setTab] = useState('settings')

  const toggleDrawer = () => {
    // reset on close
    if (showDrawer === true) {
      form.resetFields()
    }

    setShowDrawer(!showDrawer)

  }

  const goNext = () => {
    form.validateFields([
      'name',
      'kind',
      'segmentId',
      'notificationTopicId',
      'triggers',
      'limitExecPerUser',
    ]).then((values: any) => {
      // console.log('next values', values)
      setTab('workflow')
    }).catch(() => { })
  }

  // console.log('drawer props', props)
  const onFinish = (values: any) => {

    // abort if no changes in update
    if (props.workflow) {
      let hasChanges = false
      if (values.name !== props.workflow.name) hasChanges = true
      if (values.segmentId !== props.workflow.segmentId) hasChanges = true

      const previousTriggers = JSON.stringify(props.workflow.triggers)
      const newTriggers = JSON.stringify(values.triggers)
      if (previousTriggers !== newTriggers) hasChanges = true

      const previousTree = JSON.stringify(props.workflow.tree)
      const newTree = JSON.stringify(values.tree)
      if (previousTree !== newTree) hasChanges = true

      if (hasChanges === false) {
        toggleDrawer()
        // if (props.onComplete) {
        //   props.onComplete()
        // }
        return
      }
    }

    values.projectId = props.project.id

    // new workflow
    if (props.workflow) {
      values.workflowId = props.workflow.id
    }

    setLoading(true)

    props.app.ajaxRequest({
      method: 'post',
      url: props.workflow ? '/workflows.update' : '/workflows.create',
      data: values
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        props.app.addMessage('error', errorMessage)
        setLoading(false)
        return
      }


      if (props.workflow) {
        props.app.addMessage('success', t('workflow_updated', 'The workflow has been updated.'))
      } else {
        props.app.addMessage('success', t('workflow_created', 'The workflow has been created.'))
      }

      // only reset new workflow drawer, not for update
      if (!props.workflow) {
        form.resetFields()
      }

      setLoading(false)
      setShowDrawer(false)
      setTab('settings')

      if (window.cmAgent) {
        window.cmAgent.event({
          label: props.workflow ? 'updateWorkflow' : 'createWorkflow',
          props: {
            orgId: props.project.organizationId,
            projectId: props.project.id,
          }
        })
        window.cmAgent.dispatch()
      }

      if (props.onComplete) {
        props.onComplete()
      }
    })
  }


  if (!showDrawer) {
    return <Button type={props.type || "default"} style={props.style || {}} size={props.size} onClick={toggleDrawer}>{props.children}</Button>
  }

  // console.log('props', props);
  const initialValues = {
    name: _.get(props, 'workflow.name'),
    kind: _.get(props, 'workflow.kind'),
    segmentId: _.get(props, 'workflow.segmentId'),
    notificationTopicId: _.get(props, 'workflow.notificationTopicId'),
    triggers: _.get(props, 'workflow.triggers', []),
    limitExecPerUser: _.get(props, 'workflow.limitExecPerUser', undefined),
    tree: props.workflow ? props.workflow.trees[props.workflow.trees.length - 1] : {
      id: uuidv4(),
      kind: 'start',
      createdAt: new Date(),
      next: {
        id: uuidv4(),
        kind: 'add',
        createdAt: new Date(),
      }
    },
  }

  return <Drawer
    title={<>
      <div className="title">{(props.workflow) ? 'Edit workflow' : 'New workflow'}</div>
      <Tabs activeKey={tab} centered onChange={k => setTab(k)}>
        <Tabs.TabPane tab={<span><SettingFilled /> 1. Settings</span>} key="settings"></Tabs.TabPane>
        <Tabs.TabPane tab={<span><BranchesOutlined rotate={90} /> 2. Workflow</span>} key="workflow"></Tabs.TabPane>
      </Tabs>
    </>}
    visible={true}
    onClose={toggleDrawer}
    maskClosable={false}
    className="with-tabs"
    width={'95%'}
    footer={
      <div style={{ textAlign: 'right' }}>
        <Button loading={loading} onClick={() => setShowDrawer(false)} className="margin-r-s">{t('cancel', "Cancel")}</Button>

        {tab === 'settings' && <Button type="primary" className="margin-r-s" onClick={goNext}>{t('next', "Next")}</Button>}
        {tab === 'workflow' && <Button type="primary" ghost className="margin-r-s" onClick={() => setTab('settings')}>{t('previous', "Previous")}</Button>}

        {tab === 'workflow' && <Button loading={loading} className="margin-r-s" onClick={() => {
          form
            .validateFields()
            .then(onFinish)
            .catch((info) => {
              console.log('Validate Failed:', info);
              if (info.errorFields) {
                info.errorFields.forEach((field: any) => {
                  if (['name', 'kind', 'triggers', 'segmentId', 'notificationTopicId', 'limitExecPerUser'].indexOf(field.name['0']) !== -1) {
                    setTab('settings')
                  }
                })
              }
            });
        }} type="primary">{t('save', "Save")}</Button>}
      </div>
    }
  >

    <Form form={form} initialValues={initialValues} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}>

      <Tabs activeKey={tab} centered onChange={k => setTab(k)} tabBarStyle={{display: 'none'}}>
        <Tabs.TabPane tab={null} key="settings" className="padding-a-l">

          <Form.Item name="name" label={t('name', "Name")} rules={[{ required: true, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
            <Input placeholder="Name" />
          </Form.Item>

          <Form.Item name="kind" label={t('type_of_workflow', 'Type of workflow')} rules={[{ required: true, type: 'string' }]}>
            <InfoRadioGroup disabled={props.workflow ? true : false} layout="horizontal" span={24} options={[
              {
                key: 'generic',
                title: 'Generic',
                icon: <ClockCircleFilled />,
                content: <span>{t('workflow_generic_info', 'Generic workflows are triggered manually, periodically or at a specific time.')}</span>,
              },
              {
                key: 'userEvent',
                title: t('users_events', 'User events'),
                icon: <ThunderboltFilled />,
                content: <span>{t('user_events_info', '"User events" workflows are triggered when a specific event occurs for a user.')} <Tag color="purple" className="small">abandonedCart</Tag>, <Tag color="purple" className="small">enterSegment</Tag>...</span>,
              },
              {
                key: 'globalEvent',
                title: t('global_events', 'Global events'),
                icon: <ThunderboltFilled />,
                content: <span>{t('global_events_info', '"Global events" workflows are triggered when a specific event occurs globally, and are able to notify users who subscribed to "collection-based" notification topics.')} <Tag color="purple" className="small">productBackInStock</Tag>, <Tag color="purple" className="small">productPriceDrop</Tag>...</span>,
              },
            ]} onChange={() => {
              // triggers are unique for each kind, reset them when switching
              form.setFieldsValue({ triggers: [] })
            }} />
          </Form.Item>

          <Form.Item noStyle shouldUpdate>
            {(funcs) => {
              const kind = funcs.getFieldValue('kind')
              const triggers = funcs.getFieldValue('triggers')
              if (!kind) return

              return <>
                <Form.Item shouldUpdate name="triggers" label={t('triggers', "Triggers")} rules={[{ required: true, type: 'array', message: t('field_invalid', "This field is not valid!") }]}>
                  <WorkflowTriggers workflowKind={kind} segments={props.segments} conversionRules={props.project.conversionRules} />
                </Form.Item>

                {kind !== 'userEvent' && triggers && triggers.length > 0 && <>
                  <Form.Item name="segmentId" label={t('select_recipients_from_segment', "Select recipients from segment")} rules={[{ required: true, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
                    <SegmentsCascader segments={props.segments} />
                  </Form.Item>

                  <Form.Item name="notificationTopicId" label={t('limit_to_topic_subscribers', "Limit to topic subscribers")} rules={[{ required: false, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
                    <Select allowClear={true}>
                      {props.notificationTopics.map((topic: any) => <Select.Option key={topic.id} value={topic.id}>{topic.name}</Select.Option>)}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    className="margin-b-l"
                    name="limitExecPerUser"
                    label={t('limit_exec_per_user', "Limit exec per user")}
                    rules={[{ required: false, type: 'string', message: t('field_invalid', "This field is not valid!") }]}
                    help={t('limit_exec_per_user_help', "If the workflow is triggered many times for the same user, would you like to limit it's execution?")}
                  >
                    <Select allowClear={true}>
                      <Select.Option value="once">{t('once', "Once")}</Select.Option>
                      <Select.Option value="oncePerDay">{t('once_per_day', "Once per day")}</Select.Option>
                      <Select.Option value="oncePerWeek">{t('once_per_week', "Once per week")}</Select.Option>
                      <Select.Option value="oncePerMonth">{t('once_per_month', "Once per month")}</Select.Option>
                    </Select>
                  </Form.Item>
                </>}
              </>
            }}
          </Form.Item>
        </Tabs.TabPane>

        <Tabs.TabPane tab={null} key="workflow">

          <Form.Item noStyle name="tree" rules={[{ required: true, type: 'object', message: t('field_invalid', "This field is not valid!") }]}>
            <WorkflowEditor app={props.app} project={props.project} userCustomProperties={props.userCustomProperties} notifications={props.notifications} notificationTopics={props.notificationTopics} />
          </Form.Item>
        </Tabs.TabPane>
      </Tabs>
    </Form>
  </Drawer>
}

export default WorkflowsNew
