import { useState, useEffect, useCallback, useRef } from 'react'

import {
    Table,
    Spin,
    message,
    // Tag,
} from 'antd';
import t from '../../../utils/translate'
import Formatters from '../../../utils/formatters'
import Numeral from 'numeral'
import QS from 'qs'
import _ from 'lodash'
import cn from 'classnames'
import Role from './_role'
import { ResultSet, Query, SqlQuery } from '@cubejs-client/core'
import removeLeadingDates from 'utils/removeLeadingDates'
import { useAnalyticsContext, AnalyticsContextValue } from './index'
import { useProjectContext, ProjectContextValue } from 'components/projects'
import { useHistory } from 'react-router'

const defaultSortKey = 'TimelineSessions.count'

const Report = () => {

    const [data, setData] = useState<any>({})
    const [loading, setLoading] = useState(false)
    const [expandedRowKeys, setExpandedRowKeys] = useState([])
    const analyticsContext: AnalyticsContextValue = useAnalyticsContext()
    const projectCtx: ProjectContextValue = useProjectContext()
    const history = useHistory() as any
    const didMount = useRef(false)


    // default sort order
    const sortKey = analyticsContext.sortKey || defaultSortKey
    const sortOrder = analyticsContext.sortOrder

    const generateQuery = useCallback((
        groupBy: string[],
        segmentId: string,
        conversionType: string,
        domainId: string,
        dateFrom: string,
        dateTo: string,
        dateFromPrevious: string | null,
        dateToPrevious: string | null,
        dimensionFilters: any) => {

        const filters = [...dimensionFilters]

        if (segmentId !== '_all') {
            filters.push({
                'member': 'UserSegments.segmentId',
                'operator': 'equals',
                'values': [segmentId]
            })
        }

        if (conversionType !== 'all') {
            filters.push({
                'member': 'TimelineSessions.isFirstConversion',
                'operator': 'equals',
                'values': [conversionType === 'acquisition' ? '1' : '0']
            })
        }

        if (domainId !== '_all') {
            filters.push({
                'member': 'TimelineSessions.domainId',
                'operator': 'equals',
                'values': [domainId]
            })
        } else {
            const webDomains = projectCtx.currentProject.domains.filter((d: any) => !d.deletedAt && d.kind === 'web')
            filters.push({
                'member': 'TimelineSessions.domainId',
                'operator': 'in',
                'values': webDomains.map((x: any) => x.id)
            })
        }

        const compareDateRange: any = [[dateFrom, dateTo]]

        if (dateFromPrevious && dateToPrevious) {
            compareDateRange.push([dateFromPrevious, dateToPrevious])
        }

        const opts: Query = {
            measures: [
                'TimelineSessions.uniqueUsers',
                'TimelineSignups.count',
                'TimelineSessions.count',
                'TimelineSessions.bounceRate',
                'TimelineSessions.averagePageviewsCount',
                'TimelineSessions.averageSessionDuration',
                // 'TimelineSessions.contributions',
                'TimelineSessions.aloneCount',
                'TimelineSessions.initiatorCount',
                'TimelineSessions.assistingCount',
                'TimelineSessions.closerCount',
                'TimelineSessions.aloneRatio',
                'TimelineSessions.initiatorRatio',
                'TimelineSessions.assistingRatio',
                'TimelineSessions.closerRatio',
                'TimelineSessions.aloneAttributionLinearConversions',
                'TimelineSessions.initiatorAttributionLinearConversions',
                'TimelineSessions.assistingAttributionLinearConversions',
                'TimelineSessions.closerAttributionLinearConversions',
                'TimelineSessions.aloneAttributionLinearRevenue',
                'TimelineSessions.initiatorAttributionLinearRevenue',
                'TimelineSessions.assistingAttributionLinearRevenue',
                'TimelineSessions.closerAttributionLinearRevenue',
                'TimelineOrders.count',
                'TimelineOrders.revenue',
                'TimelineSessions.attributionLinearRevenue',
                'TimelineSessions.attributionLinearConversions',
                'TimelineOrders.averageCart',
            ],
            filters: filters,
            timeDimensions: [
                {
                    "dimension": 'TimelineSessions.truncCreatedAt',
                    "granularity": null,
                    "compareDateRange": compareDateRange
                }
            ],
            timezone: analyticsContext.timezone,
            order: {
                [sortKey]: sortOrder === 'descend' ? 'desc' : 'asc'
            },
            limit: 300
        }

        if (groupBy) {
            opts.dimensions = groupBy
        }

        return opts
    }, [analyticsContext.timezone, sortKey, sortOrder, projectCtx.currentProject.domains])

    const fetchData = useCallback((parentId: string, groupBy: string, opts: any) => {

        // if (noCache) {
        //   opts.renewQuery = true
        // }

        return new Promise((resolve, reject) => {
            analyticsContext.cubejsApi.load(opts).then((value: ResultSet) => {

                const [currentChannels, previousChannels] = value.decompose()

                const returned: any = []

                const lines = currentChannels.tablePivot()
                const previousLines = previousChannels.tablePivot()

                removeLeadingDates(lines)
                removeLeadingDates(previousLines)

                lines.forEach((line: any) => {
                    const previousFound = previousLines.find((x: any) => {
                        if (groupBy !== '_all') {
                            return x[groupBy] === line[groupBy]
                        } else {
                            return true
                        }
                    })

                    const returnedLine: any = {
                        name: groupBy !== '_all' ? line[groupBy] : '_all',
                        expandable: (groupBy !== '_all' && groupBy !== 'TimelineSessions.sessionReferrerPath') ? true : false,
                        childrenLoaded: false,
                        current: line,
                        previous: previousFound || {},
                    }

                    returnedLine.id = parentId !== '' ? parentId + '_' + returnedLine.name : returnedLine.name


                    if (returnedLine.expandable === true) {
                        returnedLine.children = [{ id: 'loading', name: 'loading' }]
                    }

                    returned.push(returnedLine)
                })

                resolve(returned)
                // return Analytics.CreateDataWithCurrentPrevious(currentChannelsTable, previousChannelsTable, groupBy)
            }).catch((error: any) => {

                console.log(error)

                let message = error.message

                if (error.response && error.response.status === 400) {
                    switch (error.response.data.message) {
                        default:
                            message = error.response.data.message
                    }
                }

                reject(message)
            })
        })
    }, [analyticsContext.cubejsApi])

    const loadRootData = useCallback((analyticsContext: AnalyticsContextValue) => {
        return new Promise((resolve, reject) => {

            setLoading(true)

            const filters: any = []

            const totalsData = fetchData('', '_all', generateQuery(
                null,
                analyticsContext.segment,
                analyticsContext.conversionType,
                analyticsContext.domain,
                analyticsContext.dateFrom,
                analyticsContext.dateTo,
                analyticsContext.dateFromPrevious,
                analyticsContext.dateToPrevious,
                filters))

            const query = generateQuery(
                ['TimelineSessions.sessionReferrerDomain'],
                analyticsContext.segment,
                analyticsContext.conversionType,
                analyticsContext.domain,
                analyticsContext.dateFrom,
                analyticsContext.dateTo,
                analyticsContext.dateFromPrevious,
                analyticsContext.dateToPrevious,
                filters)

            const referrerDomainsData = fetchData('', 'TimelineSessions.sessionReferrerDomain', query)

            const sqlQuery = analyticsContext.cubejsApi.sql(query)

            Promise.all([
                referrerDomainsData,
                totalsData,
                sqlQuery,
            ]).then((values: any) => {

                // console.log('values', values)
                // embed current filters to each line
                values[0].forEach((x: any) => {
                    x.parent = {
                        id: '',
                        kind: 'TimelineSessions.sessionReferrerDomain',
                        filters: [...filters],
                    }
                })

                const newData = {
                    'groups': [...values[0], ...values[1]],
                }

                values[2].forEach((queryData: SqlQuery) => {
                    console.log(...queryData.rawQuery().sql)
                })

                setLoading(false)
                setData(newData)
                setExpandedRowKeys([]) // reset expanded lines
                resolve(newData)

            }).catch(msg => {
                message.error(msg)
                setLoading(false)
                reject(msg)
            })
        })
    }, [fetchData, generateQuery])


    // onmount
    useEffect(() => {

        if (didMount.current === true) return
        didMount.current = true

        loadRootData(analyticsContext)

    }, [analyticsContext, loadRootData])

    // onupdate
    useEffect(() => {
        loadRootData(analyticsContext)
    }, [analyticsContext, loadRootData])


    const onExpand = (expanded: boolean, record: any) => {
        if (record.expandable === false) return

        if (expanded) {
            // add key
            const newKeys: any = [...expandedRowKeys]
            newKeys.push(record.id)
            setExpandedRowKeys(newKeys)
        } else {
            // remove eventual key
            const newKeys: any = expandedRowKeys.filter((x: any) => x !== record.id)
            setExpandedRowKeys(newKeys)
            return
        }

        let groupBy = 'TimelineSessions.sessionReferrerPath'
        let member = 'TimelineSessions.sessionReferrerDomain'

        // if (record.current['TimelineSessions.sessionReferrerPath']) {
        //     groupBy = 'TimelineSessions.sourceMedium'
        //     member = 'TimelineSessions.sessionReferrerPath'
        // }

        if (record.childrenLoaded === true) return

        const filters = [...record.parent.filters]

        // add current filter
        filters.push({
            'member': member,
            'operator': 'equals',
            'values': [record.name]
        })

        fetchData(record.id, groupBy, generateQuery(
            // record.id,
            [groupBy],
            analyticsContext.segment,
            analyticsContext.conversionType,
            analyticsContext.domain,
            analyticsContext.dateFrom,
            analyticsContext.dateTo,
            analyticsContext.dateFromPrevious,
            analyticsContext.dateToPrevious,
            filters,
        )).then((value: any) => {

            // add parent filter to the children
            value.forEach((x: any) => {
                x.parent = {
                    id: record.id,
                    kind: member,
                    filters: [...filters],
                }
            })

            // if expand group
            if (member === 'TimelineSessions.sessionReferrerDomain') {
                const groupLine = data['groups'].find((x: any) => x.id === record.id)
                groupLine.children = value
                groupLine.childrenLoaded = true
            }

            // if (member === 'TimelineSessions.sessionReferrerPath') {

            //     data['groups'].forEach((group: any) => {
            //         if (group.children) {
            //             group.children.forEach((ch: any) => {
            //                 if (ch.id === record.id) {
            //                     ch.children = value
            //                     ch.childrenLoaded = true
            //                 }
            //             })
            //         }
            //     })
            // }

            // console.log('DATA', value)

            const newData = { ...data }
            setData(newData)
        })

        // console.log(groupId)
    }


    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        // console.log('filters', filters);
        // console.log('pagination', pagination);
        // console.log('sorter', sorter);
        // const params: any = QS.parse(props.location.search, { ignoreQueryPrefix: true })

        const newParams = _.assign({
            sortKey: analyticsContext.sortKey || 'sessions',
            sortOrder: analyticsContext.sortOrder || 'descend',
        }, {
            sortKey: sorter.columnKey,
            sortOrder: sorter.order,
        })

        history.push('/organizations/' + projectCtx.currentOrganization.id + '/projects/' + projectCtx.currentProject.id + '/analytics/web?tab=referrers&' + QS.stringify(newParams, { indices: false }));
    }

    // const channelsMap = _.keyBy(projectCtx.currentProject.channels, 'id')
    // const channelGroupsMap = _.keyBy(projectCtx.currentProject.channelGroups, 'id')

    const tableData = []

    if (data['groups']) {
        tableData.push(...data['groups'])
    }

    // console.log(tableData)

    const columns: any = [
        {
            title: 'Referrers',
            className: 'table-border-right bg-white',
            // onClick: (e: any) => {
            //   console.log('click', e)
            // },
            width: 250,
            fixed: 'left',
            key: 'channel',
            render: (record: any) => {
                if (record.name === '_all') return ''
                if (record.name === 'loading') return <Spin size="small" />

                if (record.current['TimelineSessions.sessionReferrerDomain']) {
                    return record.name
                }

                if (record.current['TimelineSessions.sessionReferrerPath']) {
                    return record.name
                }

                return ''
            }
        },
        {
            title: t('traffic', "Traffic"),
            className: 'table-border-right table-border-bottom',
            width: 390,
            children: [
                {
                    title: t('users', "Users"),
                    width: 130,
                    key: 'TimelineSessions.uniqueUsers',
                    defaultSortOrder: 'descend',
                    sorter: (a: any, b: any) => {
                        if (a.name === 'loading') return 0

                        if (a.id === '_all' && sortOrder === 'descend') return 1
                        if (a.id === '_all' && sortOrder === 'ascend') return -1
                        if (b.id === '_all' && sortOrder === 'descend') return -1
                        if (b.id === '_all' && sortOrder === 'ascend') return 1

                        if (a.current['TimelineSessions.uniqueUsers'] > b.current['TimelineSessions.uniqueUsers']) return 1
                        if (a.current['TimelineSessions.uniqueUsers'] < b.current['TimelineSessions.uniqueUsers']) return -1
                        return 0
                    },
                    sortDirections: ['descend', 'ascend'],
                    sortOrder: sortKey === 'TimelineSessions.uniqueUsers' ? sortOrder : undefined,
                    render: (record: any) => {
                        if (record.name === 'loading') return <Spin size="small" />

                        return <div>
                            {Numeral(record.current['TimelineSessions.uniqueUsers']).format('0,0')}
                            <div className="size-10">{Formatters.growthRate(record.current['TimelineSessions.uniqueUsers'], record.previous['TimelineSessions.uniqueUsers'])}</div>
                        </div>
                    }
                },
                {
                    title: t('new_signups', "New signups"),
                    width: 130,
                    key: 'TimelineSignups.count',
                    defaultSortOrder: 'descend',
                    sorter: (a: any, b: any) => {
                        if (a.name === 'loading') return 0

                        if (a.id === '_all' && sortOrder === 'descend') return 1
                        if (a.id === '_all' && sortOrder === 'ascend') return -1
                        if (b.id === '_all' && sortOrder === 'descend') return -1
                        if (b.id === '_all' && sortOrder === 'ascend') return 1

                        if (a.current['TimelineSignups.count'] > b.current['TimelineSignups.count']) return 1
                        if (a.current['TimelineSignups.count'] < b.current['TimelineSignups.count']) return -1
                        return 0
                    },
                    sortDirections: ['descend', 'ascend'],
                    sortOrder: sortKey === 'TimelineSignups.count' ? sortOrder : undefined,
                    render: (record: any) => {
                        if (record.name === 'loading') return <Spin size="small" />
                        return <div>
                            {Numeral(record.current['TimelineSignups.count']).format('0,0')}
                            <div className="size-10">{Formatters.growthRate(record.current['TimelineSignups.count'], record.previous['TimelineSignups.count'])}</div>
                        </div >
                    }
                },
                {
                    title: t('sessions', "Sessions"),
                    width: 130,
                    key: 'TimelineSessions.count',
                    className: 'table-border-right',
                    defaultSortOrder: 'descend',
                    sorter: (a: any, b: any) => {
                        if (a.name === 'loading') return 0

                        if (a.id === '_all' && sortOrder === 'descend') return 1
                        if (a.id === '_all' && sortOrder === 'ascend') return -1
                        if (b.id === '_all' && sortOrder === 'descend') return -1
                        if (b.id === '_all' && sortOrder === 'ascend') return 1

                        if (a.current['TimelineSessions.count'] > b.current['TimelineSessions.count']) return 1
                        if (a.current['TimelineSessions.count'] < b.current['TimelineSessions.count']) return -1
                        return 0
                    },
                    sortDirections: ['descend', 'ascend'],
                    sortOrder: sortKey === 'TimelineSessions.count' ? sortOrder : undefined,
                    render: (record: any) => {
                        if (record.name === 'loading') return <Spin size="small" />
                        return <div>
                            {Numeral(record.current['TimelineSessions.count']).format('0,0')}
                            <div className="size-10">{Formatters.growthRate(record.current['TimelineSessions.count'], record.previous['TimelineSessions.count'])}</div>
                        </div>
                    }
                },
            ]
        },
        {
            title: t('behavior', "Behavior"),
            className: 'table-border-right table-border-bottom',
            width: 300,
            children: [
                {
                    title: t('bounce', "Bounce"),
                    width: 100,
                    key: 'TimelineSessions.bounceRate',
                    defaultSortOrder: 'descend',
                    sorter: (a: any, b: any) => {
                        if (a.name === 'loading') return 0

                        if (a.id === '_all' && sortOrder === 'descend') return 1
                        if (a.id === '_all' && sortOrder === 'ascend') return -1
                        if (b.id === '_all' && sortOrder === 'descend') return -1
                        if (b.id === '_all' && sortOrder === 'ascend') return 1

                        if (a.current['TimelineSessions.bounceRate'] > b.current['TimelineSessions.bounceRate']) return 1
                        if (a.current['TimelineSessions.bounceRate'] < b.current['TimelineSessions.bounceRate']) return -1
                        return 0
                    },
                    sortDirections: ['descend', 'ascend'],
                    sortOrder: sortKey === 'TimelineSessions.bounceRate' ? sortOrder : undefined,
                    render: (record: any) => {
                        if (record.name === 'loading') return <Spin size="small" />
                        return <div>
                            {Formatters.percentage(record.current['TimelineSessions.bounceRate'])}
                            <div className="size-10">{Formatters.growthRate(record.current['TimelineSessions.bounceRate'], record.previous['TimelineSessions.bounceRate'], true)}</div>
                        </div>
                    }
                },
                {
                    title: t('pages_per_session', "Pages / session"),
                    width: 100,
                    key: 'TimelineSessions.averagePageviewsCount',
                    sorter: (a: any, b: any) => {
                        if (a.name === 'loading') return 0

                        if (a.id === '_all' && sortOrder === 'descend') return 1
                        if (a.id === '_all' && sortOrder === 'ascend') return -1
                        if (b.id === '_all' && sortOrder === 'descend') return -1
                        if (b.id === '_all' && sortOrder === 'ascend') return 1

                        if (a.current['TimelineSessions.averagePageviewsCount'] > b.current['TimelineSessions.averagePageviewsCount']) return 1
                        if (a.current['TimelineSessions.averagePageviewsCount'] < b.current['TimelineSessions.averagePageviewsCount']) return -1
                        return 0
                    },
                    defaultSortOrder: 'descend',
                    sortDirections: ['descend', 'ascend'],
                    sortOrder: sortKey === 'TimelineSessions.averagePageviewsCount' ? sortOrder : undefined,
                    render: (record: any) => {
                        if (record.name === 'loading') return <Spin size="small" />
                        return <div>
                            {Numeral(record.current['TimelineSessions.averagePageviewsCount']).format('0[.]0')}
                            <div className="size-10">{Formatters.growthRate(record.current['TimelineSessions.averagePageviewsCount'], record.previous['TimelineSessions.averagePageviewsCount'])}</div>
                        </div>
                    }
                },
                {
                    title: t('session_duration', "Avg. session"),
                    width: 100,
                    key: 'TimelineSessions.averageSessionDuration',
                    className: 'table-border-right',
                    sorter: (a: any, b: any) => {
                        if (a.name === 'loading') return 0

                        if (a.id === '_all' && sortOrder === 'descend') return 1
                        if (a.id === '_all' && sortOrder === 'ascend') return -1
                        if (b.id === '_all' && sortOrder === 'descend') return -1
                        if (b.id === '_all' && sortOrder === 'ascend') return 1

                        if (a.current['TimelineSessions.averageSessionDuration'] > b.current['TimelineSessions.averageSessionDuration']) return 1
                        if (a.current['TimelineSessions.averageSessionDuration'] < b.current['TimelineSessions.averageSessionDuration']) return -1
                        return 0
                    },
                    defaultSortOrder: 'descend',
                    sortDirections: ['descend', 'ascend'],
                    sortOrder: sortKey === 'TimelineSessions.averageSessionDuration' ? sortOrder : undefined,
                    render: (record: any) => {
                        if (record.name === 'loading') return <Spin size="small" />
                        return <div>
                            {Formatters.duration(record.current['TimelineSessions.averageSessionDuration'])}
                            <div className="size-10">{Formatters.growthRate(record.current['TimelineSessions.averageSessionDuration'], record.previous['TimelineSessions.averageSessionDuration'])}</div>
                        </div>
                    }
                },
            ]
        },
    ]

    if (analyticsContext.conversionRule) {
        const attributionColumns: any = []

        if (analyticsContext.attribution === 'linear') {
            attributionColumns.push({
                title: 'Linear conversions',
                width: 130,
                key: 'TimelineSessions.attributionLinearConversions',
                sorter: (a: any, b: any) => {
                    if (a.id === 'loading') return 0

                    if (a.id === '_all' && sortOrder === 'descend') return 1
                    if (a.id === '_all' && sortOrder === 'ascend') return -1
                    if (b.id === '_all' && sortOrder === 'descend') return -1
                    if (b.id === '_all' && sortOrder === 'ascend') return 1

                    if (a.current['TimelineSessions.attributionLinearConversions'] > b.current['TimelineSessions.attributionLinearConversions']) return 1
                    if (a.current['TimelineSessions.attributionLinearConversions'] < b.current['TimelineSessions.attributionLinearConversions']) return -1
                    return 0
                },
                defaultSortOrder: 'descend',
                sortDirections: ['descend', 'ascend'],
                sortOrder: sortKey === 'TimelineSessions.attributionLinearConversions' ? sortOrder : undefined,
                render: (record: any) => {
                    if (record.name === '_all') return ''
                    if (record.name === 'loading') return <Spin size="small" />

                    return <div>
                        {Numeral(record.current['TimelineSessions.attributionLinearConversions']).format('0,0[.]0')}
                        <div className="size-10">{Formatters.growthRate(record.current['TimelineSessions.attributionLinearConversions'], record.previous['TimelineOrders.count'])}</div>
                    </div>
                }
            }, {
                title: "Linear revenue",
                width: 130,
                key: 'TimelineSessions.attributionLinearRevenue',
                sorter: (a: any, b: any) => {
                    if (a.id === 'loading') return 0

                    if (a.id === '_all' && sortOrder === 'descend') return 1
                    if (a.id === '_all' && sortOrder === 'ascend') return -1
                    if (b.id === '_all' && sortOrder === 'descend') return -1
                    if (b.id === '_all' && sortOrder === 'ascend') return 1

                    if (a.current['TimelineSessions.attributionLinearRevenue'] > b.current['TimelineSessions.attributionLinearRevenue']) return 1
                    if (a.current['TimelineSessions.attributionLinearRevenue'] < b.current['TimelineSessions.attributionLinearRevenue']) return -1
                    return 0
                },
                defaultSortOrder: 'descend',
                sortDirections: ['descend', 'ascend'],
                sortOrder: sortKey === 'TimelineSessions.attributionLinearRevenue' ? sortOrder : undefined,
                render: (record: any) => {
                    if (record.name === '_all') return ''
                    if (record.name === 'loading') return <Spin size="small" />
                    return <div>
                        {Formatters.currency(projectCtx.currentProject.currency, record.current['TimelineSessions.attributionLinearRevenue'], undefined, undefined, undefined, undefined, true)}
                        <div className="size-10">{Formatters.growthRate(record.current['TimelineSessions.attributionLinearRevenue'], record.previous['TimelineSessions.attributionLinearRevenue'])}</div>
                    </div>
                }
            })
        }

        if (analyticsContext.attribution === 'contributive') {
            attributionColumns.push(
                {
                    title: 'Impacted conversions',
                    width: 130,
                    key: 'TimelineOrders.count',
                    sorter: (a: any, b: any) => {
                        if (a.id === 'loading') return 0

                        if (a.id === '_all' && sortOrder === 'descend') return 1
                        if (a.id === '_all' && sortOrder === 'ascend') return -1
                        if (b.id === '_all' && sortOrder === 'descend') return -1
                        if (b.id === '_all' && sortOrder === 'ascend') return 1

                        if (a.current['TimelineOrders.count'] > b.current['TimelineOrders.count']) return 1
                        if (a.current['TimelineOrders.count'] < b.current['TimelineOrders.count']) return -1
                        return 0
                    },
                    defaultSortOrder: 'descend',
                    sortDirections: ['descend', 'ascend'],
                    sortOrder: sortKey === 'TimelineOrders.count' ? sortOrder : undefined,
                    render: (record: any) => {
                        if (record.name === '_all') return
                        if (record.name === 'loading') return <Spin size="small" />
                        return <div>
                            {Numeral(record.current['TimelineOrders.count']).format('0,0')}
                            <div className="size-10">{Formatters.growthRate(record.current['TimelineOrders.count'], record.previous['TimelineOrders.count'])}</div>
                        </div>
                    }
                },
                // {
                //   title: t('contributions', "Contributions"),
                //   width: 130,
                //   key: 'TimelineSessions.contributions',
                //   sorter: (a: any, b: any) => {
                //     if (a.id === 'loading') return 0

                //     if (a.id === '_all' && sortOrder === 'descend') return 1
                //     if (a.id === '_all' && sortOrder === 'ascend') return -1
                //     if (b.id === '_all' && sortOrder === 'descend') return -1
                //     if (b.id === '_all' && sortOrder === 'ascend') return 1

                //     if (a.current['TimelineSessions.contributions'] > b.current['TimelineSessions.contributions']) return 1
                //     if (a.current['TimelineSessions.contributions'] < b.current['TimelineSessions.contributions']) return -1
                //     return 0
                //   },
                //   defaultSortOrder: 'descend',
                //   sortDirections: ['descend', 'ascend'],
                //   sortOrder: sortKey === 'TimelineSessions.contributions' ? sortOrder : undefined,
                //   render: (record: any) => {
                //     if (record.name === 'loading') return <Spin size="small" />
                //     return <div>
                //       {Numeral(record.current['TimelineSessions.contributions']).format('0,0')}
                //       <div className="size-10">{Formatters.growthRate(record.current['TimelineSessions.contributions'], record.previous['TimelineSessions.contributions'])}</div>
                //     </div>
                //   }
                // },
                {
                    title: t('impacted_revenue', "Impacted revenue"),
                    width: 100,
                    key: 'TimelineOrders.revenue',
                    sorter: (a: any, b: any) => {
                        if (a.id === 'loading') return 0

                        if (a.id === '_all' && sortOrder === 'descend') return 1
                        if (a.id === '_all' && sortOrder === 'ascend') return -1
                        if (b.id === '_all' && sortOrder === 'descend') return -1
                        if (b.id === '_all' && sortOrder === 'ascend') return 1

                        if (a.current['TimelineOrders.revenue'] > b.current['TimelineOrders.revenue']) return 1
                        if (a.current['TimelineOrders.revenue'] < b.current['TimelineOrders.revenue']) return -1
                        return 0
                    },
                    defaultSortOrder: 'descend',
                    sortDirections: ['descend', 'ascend'],
                    sortOrder: sortKey === 'TimelineOrders.revenue' ? sortOrder : undefined,
                    render: (record: any) => {
                        if (record.name === '_all') return ''
                        if (record.name === 'loading') return <Spin size="small" />
                        return <div>
                            {Formatters.currency(projectCtx.currentProject.currency, record.current['TimelineOrders.revenue'], undefined, undefined, undefined, undefined, true)}
                            <div className="size-10">{Formatters.growthRate(record.current['TimelineOrders.revenue'], record.previous['TimelineOrders.revenue'])}</div>
                        </div>
                    }
                })
        }

        columns.push({
            title: <span>{analyticsContext.conversionRule.name}:&nbsp;
                {analyticsContext.conversionType === 'all' && t('all', "All")}
                {analyticsContext.conversionType === 'acquisition' && t('acquisition', "Acquisition")}
                {analyticsContext.conversionType === 'retention' && t('retention', "Retention")}
            </span>,
            width: 130 + 130 + 130 + 100 + 100 + 100,
            className: 'table-border-bottom',
            children: [
                ...attributionColumns,
                {
                    title: t('avg_cart', "Avg. cart"),
                    width: 100,
                    key: 'TimelineOrders.averageCart',
                    sorter: (a: any, b: any) => {
                        if (a.id === 'loading') return 0

                        if (a.id === '_all' && sortOrder === 'descend') return 1
                        if (a.id === '_all' && sortOrder === 'ascend') return -1
                        if (b.id === '_all' && sortOrder === 'descend') return -1
                        if (b.id === '_all' && sortOrder === 'ascend') return 1

                        if (a.current['TimelineOrders.averageCart'] > b.current['TimelineOrders.averageCart']) return 1
                        if (a.current['TimelineOrders.averageCart'] < b.current['TimelineOrders.averageCart']) return -1
                        return 0
                    },
                    defaultSortOrder: 'descend',
                    sortDirections: ['descend', 'ascend'],
                    sortOrder: sortKey === 'TimelineOrders.averageCart' ? sortOrder : undefined,
                    render: (record: any) => {
                        if (record.name === 'loading') return <Spin size="small" />
                        return <div>
                            {Formatters.currency(projectCtx.currentProject.currency, Math.trunc(record.current['TimelineOrders.averageCart'] / 100) * 100, undefined, undefined, undefined, undefined, true)}
                            <div className="size-10">{Formatters.growthRate(record.current['TimelineOrders.averageCart'], record.previous['TimelineOrders.averageCart'])}</div>
                        </div>
                    }
                },
                {
                    title: t('role', "Role"),
                    width: 100,
                    key: 'role',
                    render: (record: any) => {
                        if (record.name === '_all') return ''
                        if (record.name === 'loading') return <Spin size="small" />

                        return <Role record={record} table="TimelineSessions" currency={projectCtx.currentProject.currency} />
                    }
                },
            ]
        })
    }

    let totalX = 0

    columns.forEach((col: any) => {
        if (col.width) totalX += col.width
        // if (col.children) {
        //   col.children.forEach((ch: any) => {
        //     if (ch.width) totalX += ch.width
        //   })
        // }
    })

    return <div>
        <Table
            scroll={{ x: totalX, y: 700 }}
            dataSource={tableData}
            rowKey="id"
            size="middle"
            onChange={handleTableChange}
            loading={loading}
            pagination={false}
            className="block margin-t-l"
            expandable={{
                expandedRowKeys: expandedRowKeys,
                expandRowByClick: true,
                onExpand: onExpand,
                rowExpandable: record => record.expandable,
                indentSize: 10,
            }}
            rowClassName={record => cn({ 'table-all-total': record.name === '_all' })}
            columns={columns}
        />
    </div>
}
export default Report
