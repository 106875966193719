import { useState } from 'react'
import { Button, Form, Input, Drawer, message } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import _ from 'lodash'
import t from '../../utils/translate'
import AceInput from './_aceInput'
import Nunjucks from 'nunjucks'

const MacroForm = ({ inDrawer, loading, macros, macro, drawerVisible, toggleDrawerVisible, onFinish, onFinishFailed }: any) => {
    const [form] = Form.useForm();

    const initialValues: any = macro ? Object.assign({}, macro) : {
        id: 'emails-layout',
        name: t('emails_layout', "Emails layout"),
        content: '',
    }

    // console.log('initialValues', initialValues);

    const formContent = <Form
        form={form}
        layout="horizontal"
        name="create_notif_form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        labelCol={{ sm: { span: 8 } }}
        wrapperCol={{ sm: { span: 14 } }}
        initialValues={initialValues}
    >
        <Form.Item
            name="name"
            label={t('macro_name', "Macros page name")}
            rules={[{ required: true, message: t('field_required', "This field is required!") }]}
        >
            <Input placeholder="i.e: Emails layout" onChange={(e: any) => {
                const name = e.target.value
                if (name) {
                    let newId = _.truncate(_.camelCase(name).toLowerCase(), { omission: '' })
                    if (newId !== '') {
                        form.setFieldsValue({ 'id': newId })
                    }
                }
            }} />
        </Form.Item>

        <Form.Item
            name="id"
            label={t('macro_id', "Macros page ID")}
            rules={[
                { required: true, type: 'string', pattern: /^[a-z0-9-]+$/, message: t('only_alphanumeric_characters', "Only alphanumeric characters allowed!") },
                {
                    validator: (item, value) => {
                        if (macro) {
                            return Promise.resolve(undefined)
                        }

                        if (_.find(macros, x => x.id === value)) {
                            return Promise.reject(t('this_macros_page_id_already_exists', "This macros page ID already exists."))
                        }
                        return Promise.resolve(undefined)
                    }
                },
            ]}
            shouldUpdate
        >
            <Input disabled={macro ? true : false} placeholder="i.e: emails-layout" />
        </Form.Item>

        <Form.Item
            name="content"
            labelCol={{ sm: { span: 0 } }}
            wrapperCol={{ sm: { span: 24 } }}
            validateFirst={true}
            rules={[
                {
                    validator: (xxx, value) => {
                        // TODO: check if nunjucks can compile
                        try {
                            Nunjucks.renderString(value, {})
                            return Promise.resolve(undefined)
                        }
                        catch (e: any) {
                            return Promise.reject(t('templating_syntax_is_not_valid', "The templating syntax is not valid!"))
                        }
                    }
                },
            ]}
        >
            <AceInput id="content" width="100%" height="550px" mode="nunjucks" />
        </Form.Item>

        {!inDrawer && <Form.Item wrapperCol={{ sm: { span: 12, offset: 10 } }} className="text-right margin-t-m">
            <Button loading={loading} type="primary" htmlType="submit">{t('create', "Create")} <ArrowRightOutlined /></Button>
        </Form.Item>}
    </Form>

    if (!inDrawer) {
        return formContent
    }

    return <Drawer
        title={(macro) ? t('edit_macro', "Edit macro") : t('create_a_macros_page', "Create a macros page")}
        visible={drawerVisible}
        closable={true}
        width={960}
        onClose={() => { toggleDrawerVisible() }}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
            <div style={{ textAlign: 'right' }}>
                <Button loading={loading} onClick={() => toggleDrawerVisible()} style={{ marginRight: 8 }}>{t('cancel', "Cancel")}</Button>
                <Button loading={loading} onClick={() => {
                    form
                        .validateFields()
                        .then((values: any) => {
                            onFinish(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                }} type="primary">{t('confirm', "Confirm")}</Button>
            </div>
        }
    >
        {formContent}
    </Drawer>
}


const MacroDrawer = ({
    project,
    macro,
    ajaxRequest,
    onComplete,
    inDrawer,
    btnSize,
}) => {

    const [loading, setLoading] = useState(false)
    const [drawerVisible, setDrawerVisible] = useState(false)

    const toggleDrawerVisible = () => {
        setDrawerVisible(!drawerVisible)
    }

    const onFinishFailed = (error: any) => {
        console.error('form error', error)
        return
    }

    const onFinish = (values: any) => {

        if (loading === true) {
            return
        }
        else {
            setLoading(true)
        }

        values.projectId = project.id

        if (values.testData) {
            try {
                const parsed = JSON.parse(values.testData)
                values.testData = parsed
            }
            catch (e: any) {
                values.testData = {}
            }
        }

        // update
        if (macro) {
            values.id = macro.id
        }

        ajaxRequest({
            method: 'post',
            url: macro ? '/macros.update' : '/macros.create',
            data: values
        }, (errorMessage: any, _response: any) => {

            if (errorMessage) {
                if (errorMessage === 'id/exists') errorMessage = t('this_macro_id_already_exists', "This macro ID already exists.")

                message.error(errorMessage)
                setLoading(false)
                return
            }

            message.success(t('macro_created', "The macros page has successfully been created!"))

            if (window.cmAgent) {
                window.cmAgent.event({
                    label: 'createMacro',
                    // props: {
                    // orgId: response.data.organization.id,
                    // projectId: 
                    // }
                })
                window.cmAgent.dispatch()
            }

            if (inDrawer) toggleDrawerVisible()
            setLoading(false)

            if (onComplete) onComplete()
        })
    }


        // console.log('qsd', this.state);

        // only init the form when needed, so it doesn't cache the fields if the macro testData is updated by templates
        return <span>
            {(!inDrawer || drawerVisible) && <MacroForm
                inDrawer={inDrawer}
                macro={macro}
                macros={project.macros}
                drawerVisible={drawerVisible}
                toggleDrawerVisible={toggleDrawerVisible}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                loading={loading}
            />}
            {inDrawer && <Button loading={loading} type={macro ? 'default' : 'primary'} size={btnSize || 'normal'} onClick={toggleDrawerVisible}>{macro ? t('Edit', "Edit") : t('create_a_macros_page', "Create a macros page")}</Button>}
        </span>
}

export default MacroDrawer
