import React, { Component } from 'react'

import { ReloadOutlined, AreaChartOutlined } from '@ant-design/icons';

import {
    Table,
    Tag,
    Tooltip,
    Button,
} from 'antd';
import t from '../../../utils/translate'
import Formatters from '../../../utils/formatters'
import Moment from 'moment-timezone'
import QS from 'qs'
import _ from 'lodash'
import N from 'numeral'
import { NestSegments } from '../../users/_segmentsMenu'
import SegmentStats from './_stats'
import removeLeadingDates from 'utils/removeLeadingDates'
import AppLayout from 'components/app/layout';

class AnalyticsSegments extends Component<any, any> {
    _isMounted = false;

    constructor(props: any) {
        super(props)

        this.state = {
            // dateRangeVs: 'previous_period',
            // dateRangeLabel: 'last_30_days',
            // dateRangeValue: [Moment().tz(this.props.app.state.admin.timezone).subtract(30, 'day').startOf('day'), Moment().tz(this.props.app.state.admin.timezone).endOf('day')],
            refreshAt: Moment().unix(),
            loading: true,
            data: []
            // current: [],
            // previous: [],
        }

        // this.setDateRange = this.setDateRange.bind(this)
        // this.setDateRangeVs = this.setDateRangeVs.bind(this)
        this.updateParams = this.updateParams.bind(this)
        this.refresh = this.refresh.bind(this)
        this.fetch = this.fetch.bind(this)
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetch()

        if (window.cmAgent) {
            window.cmAgent.pageview({
                title: 'Analytics Segments (project: ' + this.props.projectLayout.project.id + ')',
                page: this.props.location.pathname,
                props: {
                    orgId: this.props.projectLayout.project.organizationId,
                    projectId: this.props.projectLayout.project.id
                }
            })
            window.cmAgent.dispatch()
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        // check url params have changed
        if (prevState.refreshAt !== this.state.refreshAt) {
            window.setTimeout(() => this.fetch())
        } else if (prevProps.projectLayout.project.id !== this.props.projectLayout.project.id) {
            window.setTimeout(() => this.fetch())
        }
    }

    fetch() {

        if (!this.state.loading) {
            this.setState({ loading: true })
        }

        const opts: any = {
            measures: [
                'UserSegments.activeUsers',
                'TimelineOrders.count',
                'TimelineOrders.conversionsPerUser',
                'TimelineOrders.revenuePerUser',
                'TimelineOrders.revenue',
                'TimelineOrders.acquisitionCount',
                'TimelineOrders.acquisitionRevenue',
                'TimelineOrders.acquisitionAverageCart',
                'TimelineOrders.acquisitionTimeToConversion',
                'TimelineOrders.retentionCount',
                'TimelineOrders.retentionRevenue',
                'TimelineOrders.retentionAverageCart',
                'TimelineOrders.retentionTimeToConversion',
            ],
            dimensions: [
                'UserSegments.segmentId',
            ],
            filters: [{
                'member': 'UserSegments.segmentId',
                'operator': 'notEquals',
                'values': ['anon']
            }]
        }


        this.props.projectLayout.cubejsApi.load(opts).then((result: any) => {
            const segments = _.cloneDeep(this.props.projectLayout.segments.filter((x: any) => !x.deletedAt && x.id !== '_all' && x.id !== 'anon'))
            const segmentsWithData: any = []

            const lines = result.tablePivot()

            removeLeadingDates(lines)

            lines.forEach((line: any) => {
                segments.forEach((s: any) => {
                    if (s.id === line['UserSegments.segmentId']) {
                        s.data = line
                        segmentsWithData.push(s)
                    }
                })
            })

            const nested = NestSegments(segmentsWithData)

            const sortSegments = (currentSegments: any, depth: number, result: any) => {

                currentSegments.forEach((currentSegment: any) => {
                    if (currentSegment.children) sortSegments(currentSegment.children, depth + 1, result)
                    currentSegment.depth = depth
                    delete currentSegment.children
                    result.push(currentSegment)
                })

                return result
            }

            const sortedSegments = sortSegments(nested, 0, []).reverse()

            // console.log('nested', nested)
            // console.log('segments', segments)
            // console.log('data', sortedSegments)

            this.setState({
                loading: false,
                data: sortedSegments,
            })
        })
            .catch((error: any) => {

                console.log(error)

                let message = error.message

                if (error.response && error.response.status === 400) {
                    switch (error.response.data.message) {
                        default:
                            message = error.response.data.message
                    }
                }

                this.props.app.addMessage('error', message)
                this.setState({ loading: false })
            })
    }

    refresh() {
        this.setState({ refreshAt: Moment().unix() })
    }

    updateParams(updatedParams: any) {
        let params = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })
        updatedParams.forEach((up: any) => {
            params[up.k] = up.v
        })
        this.props.history.push('/organizations/' + this.props.match.params.organizationId + '/projects/' + this.props.match.params.projectId + '/analytics/segments?' + QS.stringify(params))
    }

    render() {

        return <AppLayout currentProject={this.props.projectLayout.project} currentOrganization={this.props.organizationLayout.organization} admin={this.props.app.state.admin} firebaseUser={this.props.app.state.firebaseUser} projects={this.props.organizationLayout.projects}>

            <h1>
                <span className="actions">
                    <span className="padding-l-s">
                        {/* <DateRange {...this.props}
                                timezone={this.props.app.state.admin.timezone}
                                vs={this.state.dateRangeVs}
                                dateRange={this.state.dateRangeValue}
                                labelKey={this.state.dateRangeLabel}
                                onVsChange={this.setDateRangeVs}
                                onChange={this.setDateRange} /> */}
                        <Tooltip placement="left" title={t('last_refresh_at', "Refreshed {at}", { at: Moment.unix(this.state.refreshAt).fromNow() })}>
                            <Button className="margin-l-s" size="small" type="default" shape="circle" icon={<ReloadOutlined />} onClick={this.refresh} disabled={this.state.loadingSessions} />
                        </Tooltip>
                    </span>
                </span>
                Segments Analytics
            </h1>

            <Table dataSource={this.state.data}
                rowKey="id"
                className="edge-table block"
                size="middle"
                pagination={false}
                loading={this.state.loading}
                columns={[
                    {
                        key: 'name',
                        title: 'Segment',
                        className: 'table-border-right',
                        render: (item: any) => {
                            const indent = item.depth > 0 ? item.depth * 24 : 0
                            return <span style={{ paddingLeft: indent + 'px' }}>↳ <Tag className="no-pointer" color={item.color !== 'default' && item.color}>{item.name}</Tag></span>
                        }
                    },
                    {
                        key: 'global',
                        title: t('global', 'Global'),
                        className: 'table-border-right table-border-bottom',
                        children: [
                            {
                                key: 'activeUsers',
                                title: t('active_users', 'Active users'),
                                render: (item: any) => N(item.data['UserSegments.activeUsers']).format('0,0')
                            },
                            {
                                key: 'conversions',
                                title: t('orders', 'Orders'),
                                render: (item: any) => N(item.data['TimelineOrders.count']).format('0,0')
                            },
                            {
                                key: 'revenue',
                                title: t('revenue', 'Revenue'),
                                render: (item: any) => Formatters.currency(this.props.projectLayout.project.currency, item.data['TimelineOrders.revenue'], undefined, undefined, undefined, undefined, true)
                            },
                            {
                                key: 'revenuePerUser',
                                title: t('revenue_per_user', 'Revenue / user'),
                                render: (item: any) => Formatters.currency(this.props.projectLayout.project.currency, item.data['TimelineOrders.revenuePerUser'], undefined, undefined, undefined, undefined, true)
                            },
                            {
                                key: 'conversionsPerUser',
                                title: t('conversions_per_user', 'Conversions / user'),
                                className: 'table-border-right',
                                render: (item: any) => N(item.data['TimelineOrders.conversionsPerUser']).format('0,0[.]0')
                            },
                        ]
                    },
                    {
                        key: 'acquisition',
                        title: t('acquisition', 'Acquisition'),
                        className: 'table-border-right table-border-bottom',
                        children: [
                            {
                                key: 'acquisitionCount',
                                title: t('orders', 'Orders'),
                                render: (item: any) => N(item.data['TimelineOrders.acquisitionCount']).format('0,0')
                            },
                            {
                                key: 'acquisitionRevenue',
                                title: t('revenue', 'Revenue'),
                                render: (item: any) => Formatters.currency(this.props.projectLayout.project.currency, item.data['TimelineOrders.acquisitionRevenue'], undefined, undefined, undefined, undefined, true)
                            },
                            {
                                key: 'acquisitionAverageCart',
                                title: t('avg_cart', 'Avg. cart'),
                                render: (item: any) => Formatters.currency(this.props.projectLayout.project.currency, item.data['TimelineOrders.acquisitionAverageCart'], undefined, undefined, undefined, undefined, true)
                            },
                            {
                                key: 'acquisitionTimeToConversion',
                                title: <Tooltip title={t('avg_time_to_conversion', "Average Time To Conversion")}>{t('avg_ttc', "Avg. TTC")}</Tooltip>,
                                className: 'table-border-right',
                                render: (item: any) => Formatters.duration(item.data['TimelineOrders.acquisitionTimeToConversion'])
                            },
                        ]
                    },
                    {
                        key: 'retention',
                        title: t('retention', 'Retention'),
                        className: 'table-border-right table-border-bottom',
                        children: [
                            {
                                key: 'retentionCount',
                                title: t('orders', 'Orders'),
                                render: (item: any) => N(item.data['TimelineOrders.retentionCount']).format('0,0')
                            },
                            {
                                key: 'retentionRevenue',
                                title: t('revenue', 'Revenue'),
                                render: (item: any) => Formatters.currency(this.props.projectLayout.project.currency, item.data['TimelineOrders.retentionRevenue'], undefined, undefined, undefined, undefined, true)
                            },
                            {
                                key: 'retentionAverageCart',
                                title: t('avg_cart', 'Avg. cart'),
                                render: (item: any) => Formatters.currency(this.props.projectLayout.project.currency, item.data['TimelineOrders.retentionAverageCart'], undefined, undefined, undefined, undefined, true)
                            },
                            {
                                key: 'retentionTimeToConversion',
                                className: 'table-border-right',
                                title: <Tooltip title={t('avg_time_to_conversion', "Average Time To Conversion")}>{t('avg_ttc', "Avg. TTC")}</Tooltip>,
                                render: (item: any) => Formatters.duration(item.data['TimelineOrders.retentionTimeToConversion'])
                            },
                        ]
                    },
                    {
                        key: 'actions',
                        title: <span>&nbsp;</span>,
                        render: (item: any) => <div className="text-right">
                            <SegmentStats segment={item} app={this.props.app} btnSize="small" btnType="primary" btnGhost={true} projectLayout={this.props.projectLayout}><><AreaChartOutlined /> Stats</></SegmentStats>
                        </div>
                    }
                ]}
            />
        </AppLayout>
    }
}

export default AnalyticsSegments