import { useState, useEffect } from 'react'

import { ReloadOutlined } from '@ant-design/icons';

import {
  Table,
  Row,
  Col,
  message,
  Button,
  Tooltip,
  Switch,
} from 'antd';
import t from '../../../utils/translate'
import Formatters from '../../../utils/formatters'
import Moment from 'moment-timezone'
import DateRange from '../../projects/_dateRange'
import QS from 'qs'
import _ from 'lodash'
import N from 'numeral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SegmentsSelect from '../../segments/_select'
import KPI from '../../dashboard/kpi'
import removeLeadingDates from 'utils/removeLeadingDates'
import AppLayout from 'components/app/layout';
// import {
//   Chart,
//   Geom,
//   Axis,
//   Tooltip as ChartTooltip,
//   Legend,
// } from "bizcharts";
// import SegmentStats from './_stats'


const Report = (props: any) => {

  const defaultDateRangeLabel = 'last_14_days'
  const defaultDateRangeVs = 'previous_period'
  const defaultDateFrom = Moment().tz(props.app.state.admin.timezone).subtract(14, 'day').startOf('day').format('YYYY-MM-DD')
  const defaultDateTo = Moment().tz(props.app.state.admin.timezone).endOf('day').format('YYYY-MM-DD')
  const defaultSegmentId = '_all'
  const defaultNotificationId = '_all'
  const params: any = QS.parse(props.location.search, { ignoreQueryPrefix: true })

  const dateFrom: any = params.dateFrom || defaultDateFrom
  const dateTo: any = params.dateTo || defaultDateTo
  const segmentId = params.segmentId || defaultSegmentId
  const notificationId = params.notificationId || defaultNotificationId
  const dateRangeLabel = params.dateRangeLabel || defaultDateRangeLabel
  const dateRangeVs = params.dateRangeVs || defaultDateRangeVs
  const refreshAt: any = params.refreshAt || Moment().unix()


  let dateFromPrevious: any = Moment(dateFrom)
  let dateToPrevious: any = Moment(dateTo)

  if (dateRangeVs === 'previous_period') {

    // add one day to the diff to avoid days overlaping between the to ranges
    const diff = dateToPrevious.diff(dateFromPrevious, 'days') + 1
    // console.log('diff is', diff);

    dateToPrevious = dateToPrevious.subtract(diff, 'days')
    dateFromPrevious = dateFromPrevious.subtract(diff, 'days')
  }

  if (dateRangeVs === 'previous_year') {
    dateToPrevious = dateToPrevious.subtract(1, 'years')
    dateFromPrevious = dateFromPrevious.subtract(1, 'years')
  }

  dateFromPrevious = dateFromPrevious.format('YYYY-MM-DD')
  dateToPrevious = dateToPrevious.format('YYYY-MM-DD')


  const refresh = () => {
    updateParams([{ k: 'refreshAt', v: Moment().unix() }])
  }

  const updateParams = (updatedParams: any) => {
    const params: any = QS.parse(props.location.search, { ignoreQueryPrefix: true })

    updatedParams.forEach((up: any) => {
      params[up.k] = up.v
    })

    props.history.push('/organizations/' + props.match.params.organizationId + '/projects/' + props.match.params.projectId + '/analytics/notifications?' + QS.stringify(params))
  }


  return <AppLayout currentProject={props.projectLayout.project} currentOrganization={props.organizationLayout.organization} admin={props.app.state.admin} firebaseUser={props.app.state.firebaseUser} projects={props.organizationLayout.projects}>

    <h1>
      <span className="actions">
        <span className="padding-l-s">
          <SegmentsSelect value={segmentId} onChange={(value: any) => updateParams([{ k: 'segmentId', v: value }])} segments={props.projectLayout.segments} forCompanies={false} />
        </span>

        <span className="padding-l-s">
          <DateRange {...props}
            timezone={props.app.state.admin.timezone}
            vs={dateRangeVs}
            dateRange={[
              Moment(dateFrom),
              Moment(dateTo),
            ]}
            labelKey={dateRangeLabel}
            onVsChange={(value: any) => {
              updateParams([{ k: 'dateRangeVs', v: value }])
            }}
            onChange={(label: string, range: any) => {
              updateParams([
                { k: 'dateRangeLabel', v: label },
                { k: 'dateFrom', v: range[0] },
                { k: 'dateTo', v: range[1] },
              ])
            }}
          />
          <Tooltip placement="left" title={t('last_refresh_at', "Refreshed {at}", { at: Moment.unix(refreshAt).fromNow() })}>
            <Button className="margin-l-s" size="small" type="default" shape="circle" icon={<ReloadOutlined />} onClick={refresh} />
          </Tooltip>
        </span>
      </span>
      Notifications Analytics
    </h1>

    <Row gutter={24} className="margin-b-l">
      <Col span={8}>
        <div className="block">
          <h2 className="mini">Global</h2>
          <div className="dash-kpi-container">
            <KPI
              title={t('Notifications_sent', "Notifications sent")}
              measure="TimelineNotifications.count"
              // kind="percentage"
              timeDimension="TimelineNotifications.truncCreatedAt"
              filters={(notificationId && notificationId !== '_all') ? [{
                member: 'TimelineNotifications.notificationId',
                operator: 'equals',
                values: [notificationId],
              }] : []}
              color="cyan"
              projectId={props.projectLayout.project.id}
              cubejsApi={props.projectLayout.cubejsApi}
              timezone={props.app.state.admin.timezone}
              refreshAt={refreshAt}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dateFromPrevious={dateFromPrevious}
              dateToPrevious={dateToPrevious}
              addMessage={props.app.addMessage}
            />
            <KPI
              title={t('unique_users', "Unique users")}
              measure="TimelineNotifications.uniqueUsers"
              // kind="percentage"
              timeDimension="TimelineNotifications.truncCreatedAt"
              filters={(notificationId && notificationId !== '_all') ? [{
                member: 'TimelineNotifications.notificationId',
                operator: 'equals',
                values: [notificationId],
              }] : []}
              color="cyan"
              projectId={props.projectLayout.project.id}
              cubejsApi={props.projectLayout.cubejsApi}
              timezone={props.app.state.admin.timezone}
              refreshAt={refreshAt}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dateFromPrevious={dateFromPrevious}
              dateToPrevious={dateToPrevious}
              addMessage={props.app.addMessage}
            />
          </div>
        </div>
      </Col>
      <Col span={16}>
        <div className="block">
          <h2 className="mini">SMS</h2>
          <div className="dash-kpi-container">
            <KPI
              title={t('sms_sent', "SMS sent")}
              measure="TimelineNotifications.smsCount"
              // kind="percentage"
              timeDimension="TimelineNotifications.truncCreatedAt"
              filters={(notificationId && notificationId !== '_all') ? [{
                member: 'TimelineNotifications.notificationId',
                operator: 'equals',
                values: [notificationId],
              }] : []}
              color="cyan"
              projectId={props.projectLayout.project.id}
              cubejsApi={props.projectLayout.cubejsApi}
              timezone={props.app.state.admin.timezone}
              refreshAt={refreshAt}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dateFromPrevious={dateFromPrevious}
              dateToPrevious={dateToPrevious}
              addMessage={props.app.addMessage}
            />
            <KPI
              title={t('delivery_rate', "Delivery rate")}
              measure="TimelineNotifications.smsDeliveryRate"
              // kind="percentage"
              timeDimension="TimelineNotifications.truncCreatedAt"
              filters={(notificationId && notificationId !== '_all') ? [{
                member: 'TimelineNotifications.notificationId',
                operator: 'equals',
                values: [notificationId],
              }] : []}
              color="cyan"
              projectId={props.projectLayout.project.id}
              cubejsApi={props.projectLayout.cubejsApi}
              timezone={props.app.state.admin.timezone}
              refreshAt={refreshAt}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dateFromPrevious={dateFromPrevious}
              dateToPrevious={dateToPrevious}
              addMessage={props.app.addMessage}
            />
            <KPI
              title={t('error_rate', "Error rate")}
              measure="TimelineNotifications.smsErrorRate"
              // kind="percentage"
              timeDimension="TimelineNotifications.truncCreatedAt"
              filters={(notificationId && notificationId !== '_all') ? [{
                member: 'TimelineNotifications.notificationId',
                operator: 'equals',
                values: [notificationId],
              }] : []}
              color="orange"
              projectId={props.projectLayout.project.id}
              cubejsApi={props.projectLayout.cubejsApi}
              timezone={props.app.state.admin.timezone}
              refreshAt={refreshAt}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dateFromPrevious={dateFromPrevious}
              dateToPrevious={dateToPrevious}
              addMessage={props.app.addMessage}
            />
            <KPI
              title={t('fail_rate', "Fail rate")}
              measure="TimelineNotifications.smsFailRate"
              // kind="percentage"
              timeDimension="TimelineNotifications.truncCreatedAt"
              filters={(notificationId && notificationId !== '_all') ? [{
                member: 'TimelineNotifications.notificationId',
                operator: 'equals',
                values: [notificationId],
              }] : []}
              color="orange"
              projectId={props.projectLayout.project.id}
              cubejsApi={props.projectLayout.cubejsApi}
              timezone={props.app.state.admin.timezone}
              refreshAt={refreshAt}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dateFromPrevious={dateFromPrevious}
              dateToPrevious={dateToPrevious}
              addMessage={props.app.addMessage}
            />
          </div>
        </div>
      </Col>
    </Row>

    <div className="block margin-b-l">
      <h2 className="mini">Email</h2>
      <div className="dash-kpi-container">
        <KPI
          title={t('emails_sent', "Emails sent")}
          measure="TimelineNotifications.emailCount"
          // kind="percentage"
          timeDimension="TimelineNotifications.truncCreatedAt"
          filters={(notificationId && notificationId !== '_all') ? [{
            member: 'TimelineNotifications.notificationId',
            operator: 'equals',
            values: [notificationId],
          }] : []}
          color="cyan"
          projectId={props.projectLayout.project.id}
          cubejsApi={props.projectLayout.cubejsApi}
          timezone={props.app.state.admin.timezone}
          refreshAt={refreshAt}
          dateFrom={dateFrom}
          dateTo={dateTo}
          dateFromPrevious={dateFromPrevious}
          dateToPrevious={dateToPrevious}
          addMessage={props.app.addMessage}
        />
        <KPI
          title={t('error_rate', "Error rate")}
          measure="TimelineNotifications.emailErrorRate"
          kind="percentage"
          timeDimension="TimelineNotifications.truncCreatedAt"
          filters={(notificationId && notificationId !== '_all') ? [{
            member: 'TimelineNotifications.notificationId',
            operator: 'equals',
            values: [notificationId],
          }] : []}
          color="orange"
          projectId={props.projectLayout.project.id}
          cubejsApi={props.projectLayout.cubejsApi}
          timezone={props.app.state.admin.timezone}
          refreshAt={refreshAt}
          dateFrom={dateFrom}
          dateTo={dateTo}
          dateFromPrevious={dateFromPrevious}
          dateToPrevious={dateToPrevious}
          addMessage={props.app.addMessage}
        />
        <KPI
          title={t('drop_rate', "Drop rate")}
          measure="TimelineNotifications.emailDropRate"
          kind="percentage"
          timeDimension="TimelineNotifications.truncCreatedAt"
          filters={(notificationId && notificationId !== '_all') ? [{
            member: 'TimelineNotifications.notificationId',
            operator: 'equals',
            values: [notificationId],
          }] : []}
          color="orange"
          projectId={props.projectLayout.project.id}
          cubejsApi={props.projectLayout.cubejsApi}
          timezone={props.app.state.admin.timezone}
          refreshAt={refreshAt}
          dateFrom={dateFrom}
          dateTo={dateTo}
          dateFromPrevious={dateFromPrevious}
          dateToPrevious={dateToPrevious}
          addMessage={props.app.addMessage}
        />
        <KPI
          title={t('bounce_rate', "Bounce rate")}
          measure="TimelineNotifications.emailBounceRate"
          kind="percentage"
          timeDimension="TimelineNotifications.truncCreatedAt"
          filters={(notificationId && notificationId !== '_all') ? [{
            member: 'TimelineNotifications.notificationId',
            operator: 'equals',
            values: [notificationId],
          }] : []}
          color="orange"
          projectId={props.projectLayout.project.id}
          cubejsApi={props.projectLayout.cubejsApi}
          timezone={props.app.state.admin.timezone}
          refreshAt={refreshAt}
          dateFrom={dateFrom}
          dateTo={dateTo}
          dateFromPrevious={dateFromPrevious}
          dateToPrevious={dateToPrevious}
          addMessage={props.app.addMessage}
        />
        <KPI
          title={t('complain_rate', "Complain rate")}
          measure="TimelineNotifications.emailComplainRate"
          kind="percentage"
          timeDimension="TimelineNotifications.truncCreatedAt"
          filters={(notificationId && notificationId !== '_all') ? [{
            member: 'TimelineNotifications.notificationId',
            operator: 'equals',
            values: [notificationId],
          }] : []}
          color="orange"
          projectId={props.projectLayout.project.id}
          cubejsApi={props.projectLayout.cubejsApi}
          timezone={props.app.state.admin.timezone}
          refreshAt={refreshAt}
          dateFrom={dateFrom}
          dateTo={dateTo}
          dateFromPrevious={dateFromPrevious}
          dateToPrevious={dateToPrevious}
          addMessage={props.app.addMessage}
        />

        {/* <KPI
          title={t('avg_time_to_click', "Avg. time to click")}
          measure="TimelineNotifications.emailAverageTimeToClick"
          kind="duration"
          timeDimension="TimelineNotifications.truncCreatedAt"
          filters={(notificationId && notificationId !== '_all') ? [{
            member: 'TimelineNotifications.notificationId',
            operator: 'equals',
            values: [notificationId],
          }] : []}
          color="cyan"
          projectId={props.projectLayout.project.id}
          cubejsApi={props.projectLayout.cubejsApi}
          timezone={props.app.state.admin.timezone}
          refreshAt={refreshAt}
          dateFrom={dateFrom}
          dateTo={dateTo}
          dateFromPrevious={dateFromPrevious}
          dateToPrevious={dateToPrevious}
          addMessage={props.app.addMessage}
        /> */}
      </div>
      <div className="dash-kpi-container">
        <KPI
          title={t('delivery_rate', "Delivery rate")}
          measure="TimelineNotifications.emailDeliveryRate"
          kind="percentage"
          timeDimension="TimelineNotifications.truncCreatedAt"
          filters={(notificationId && notificationId !== '_all') ? [{
            member: 'TimelineNotifications.notificationId',
            operator: 'equals',
            values: [notificationId],
          }] : []}
          color="cyan"
          projectId={props.projectLayout.project.id}
          cubejsApi={props.projectLayout.cubejsApi}
          timezone={props.app.state.admin.timezone}
          refreshAt={refreshAt}
          dateFrom={dateFrom}
          dateTo={dateTo}
          dateFromPrevious={dateFromPrevious}
          dateToPrevious={dateToPrevious}
          addMessage={props.app.addMessage}
        />
        <KPI
          title={t('avg_time_to_delivery', "Avg. time to delivery")}
          measure="TimelineNotifications.emailAverageTimeToDelivery"
          kind="duration"
          timeDimension="TimelineNotifications.truncCreatedAt"
          filters={(notificationId && notificationId !== '_all') ? [{
            member: 'TimelineNotifications.notificationId',
            operator: 'equals',
            values: [notificationId],
          }] : []}
          color="cyan"
          projectId={props.projectLayout.project.id}
          cubejsApi={props.projectLayout.cubejsApi}
          timezone={props.app.state.admin.timezone}
          refreshAt={refreshAt}
          dateFrom={dateFrom}
          dateTo={dateTo}
          dateFromPrevious={dateFromPrevious}
          dateToPrevious={dateToPrevious}
          addMessage={props.app.addMessage}
        />
        <KPI
          title={t('open_rate', "Open rate")}
          measure="TimelineNotifications.emailOpenRate"
          kind="percentage"
          timeDimension="TimelineNotifications.truncCreatedAt"
          filters={(notificationId && notificationId !== '_all') ? [{
            member: 'TimelineNotifications.notificationId',
            operator: 'equals',
            values: [notificationId],
          }] : []}
          color="cyan"
          projectId={props.projectLayout.project.id}
          cubejsApi={props.projectLayout.cubejsApi}
          timezone={props.app.state.admin.timezone}
          refreshAt={refreshAt}
          dateFrom={dateFrom}
          dateTo={dateTo}
          dateFromPrevious={dateFromPrevious}
          dateToPrevious={dateToPrevious}
          addMessage={props.app.addMessage}
        />
        <KPI
          title={t('avg_time_to_open', "Avg. time to open")}
          measure="TimelineNotifications.emailAverageTimeToOpen"
          kind="duration"
          timeDimension="TimelineNotifications.truncCreatedAt"
          filters={(notificationId && notificationId !== '_all') ? [{
            member: 'TimelineNotifications.notificationId',
            operator: 'equals',
            values: [notificationId],
          }] : []}
          color="cyan"
          projectId={props.projectLayout.project.id}
          cubejsApi={props.projectLayout.cubejsApi}
          timezone={props.app.state.admin.timezone}
          refreshAt={refreshAt}
          dateFrom={dateFrom}
          dateTo={dateTo}
          dateFromPrevious={dateFromPrevious}
          dateToPrevious={dateToPrevious}
          addMessage={props.app.addMessage}
        />
        <KPI
          title={t('click_rate', "Click rate")}
          measure="TimelineNotifications.emailClickRate"
          kind="percentage"
          timeDimension="TimelineNotifications.truncCreatedAt"
          filters={(notificationId && notificationId !== '_all') ? [{
            member: 'TimelineNotifications.notificationId',
            operator: 'equals',
            values: [notificationId],
          }] : []}
          color="cyan"
          projectId={props.projectLayout.project.id}
          cubejsApi={props.projectLayout.cubejsApi}
          timezone={props.app.state.admin.timezone}
          refreshAt={refreshAt}
          dateFrom={dateFrom}
          dateTo={dateTo}
          dateFromPrevious={dateFromPrevious}
          dateToPrevious={dateToPrevious}
          addMessage={props.app.addMessage}
        />
      </div>
    </div>

    <TableReport
      {...props}
      segmentId={segmentId}
      notificationId={notificationId}
      dateFrom={dateFrom}
      dateTo={dateTo}
      updateParams={updateParams}
    />
  </AppLayout>
}

const TableReport = (props: any) => {

  const defaultSortKey = 'TimelineNotifications.count'


  const params: any = QS.parse(props.location.search, { ignoreQueryPrefix: true })
  const sortKey: any = params.sortKey || defaultSortKey
  const sortOrder = params.sortOrder === 'ascend' ? 'asc' : 'desc'

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any>([])

  useEffect(() => {

    setLoading(true)

    const filters: any = []

    if (props.segmentId !== '_all') {
      filters.push({
        'member': 'UserSegments.segmentId',
        'operator': 'equals',
        'values': [props.segmentId]
      })
    }

    const opts: any = {
      measures: [
        'TimelineNotifications.count',
        'TimelineNotifications.uniqueUsers',
        // 'TimelineNotifications.notificationsPerUser',
        'TimelineNotifications.emailCount',
        'TimelineNotifications.emailErrorRate',
        'TimelineNotifications.emailDeliveryRate',
        'TimelineNotifications.emailAverageTimeToDelivery',
        'TimelineNotifications.emailDropRate',
        'TimelineNotifications.emailBounceRate',
        'TimelineNotifications.emailComplainRate',
        'TimelineNotifications.emailOpenRate',
        'TimelineNotifications.emailAverageTimeToOpen',
        'TimelineNotifications.emailClickRate',
        'TimelineNotifications.emailAverageTimeToClick',
        'TimelineNotifications.emailUnsubscribeRate',
        'TimelineNotifications.emailAverageTimeToUnsubscribe',
        'TimelineNotifications.smsCount',
        'TimelineNotifications.smsErrorRate',
        'TimelineNotifications.smsDeliveryRate',
        'TimelineNotifications.smsAverageTimeToDelivery',
        'TimelineNotifications.smsFailRate',
      ],
      dimensions: [
        'TimelineNotifications.notificationId',
      ],
      timeDimensions: [
        {
          "dimension": 'TimelineNotifications.truncCreatedAt',
          "granularity": null,
          "compareDateRange": [
            [props.dateFrom, props.dateTo],
          ]
        }
      ],
      timezone: props.app.state.admin.timezone,
      filters: filters,
      order: {
        [sortKey]: sortOrder
      },
    }
    // console.log('opts', opts)

    props.projectLayout.cubejsApi.load(opts).then((result: any) => {
      const notifications = _.cloneDeep(props.projectLayout.notifications.filter((x: any) => !x.deletedAt))
      const notificationsWithData: any = []

      const lines = result.tablePivot()

      removeLeadingDates(lines)

      lines.forEach((line: any) => {
        notifications.forEach((s: any) => {
          if (s.id === line['TimelineNotifications.notificationId']) {
            s.data = line
            notificationsWithData.push(s)
          }
        })
      })

      // console.log('data', notificationsWithData)

      setLoading(false)
      setData(notificationsWithData)
    })
      .catch((error: any) => {

        console.log(error)

        let msg = error.message

        if (error.response && error.response.status === 400) {
          switch (error.response.data.message) {
            default:
              msg = error.response.data.message
          }
        }

        message.error(msg)
        setLoading(false)
      })
  }, [props.segmentId, props.dateFrom, props.dateTo, props.app.state.admin.timezone, props.projectLayout.cubejsApi, sortKey, sortOrder, props.projectLayout.notifications])

  const changeNotificationId = (notifId: string) => {
    if (props.notificationId === notifId) {
      props.updateParams([{ k: 'notificationId', v: '' }])
    } else {
      props.updateParams([{ k: 'notificationId', v: notifId }])
    }
  }

  return <Table dataSource={data}
    rowKey="id"
    className="edge-table block"
    size="middle"
    pagination={false}
    loading={loading}
    columns={[
      {
        key: 'name',
        title: 'Notification',
        className: 'table-border-right',
        render: (item: any) => {
          const notif = props.projectLayout.notifications.find((x: any) => x.id === item.id)
          return <div className="semibold size-13">{notif ? notif.name : item.data['TimelineNotifications.notificationId']}</div>
        }
      },
      {
        key: 'global',
        title: t('global', 'Global'),
        className: 'table-border-right table-border-bottom',
        children: [
          {
            key: 'total',
            title: t('sent', 'Sent'),
            render: (item: any) => N(item.data['TimelineNotifications.count']).format('0,0')
          },
          {
            key: 'users',
            title: t('users', 'Users'),
            className: 'table-border-right',
            render: (item: any) => N(item.data['TimelineNotifications.uniqueUsers']).format('0,0')
          },
        ]
      },
      {
        key: 'email',
        title: t('email', 'Email'),
        className: 'table-border-right table-border-bottom',
        children: [
          {
            key: 'total',
            title: t('sent', 'Sent'),
            render: (item: any) => N(item.data['TimelineNotifications.emailCount']).format('0,0')
          },
          {
            key: 'errorRate',
            title: <Tooltip title="Error rate"><FontAwesomeIcon icon="exclamation-triangle" /></Tooltip>,
            render: (item: any) => item.data['TimelineNotifications.errorRate'] ? Formatters.percentage(item.data['TimelineNotifications.errorRate']) : '-'
          },
          {
            key: 'emailDeliveryRate',
            title: <Tooltip title="Delivery rate &amp; average time to delivery"><FontAwesomeIcon icon="check-square" /></Tooltip>,
            render: (item: any) => item.data['TimelineNotifications.emailDeliveryRate'] ? (Formatters.percentage(item.data['TimelineNotifications.emailDeliveryRate']) + ' in ' + Formatters.duration(item.data['TimelineNotifications.emailAverageTimeToDelivery'])) : '-'
          },
          // {
          //   key: 'emailAverageTimeToDelivery',
          //   title: <Tooltip title="Average time to delivery"><FontAwesomeIcon icon="check-square" /> <FontAwesomeIcon icon="clock" /></Tooltip>,
          //   render: (item: any) => Formatters.duration(item.data['TimelineNotifications.emailAverageTimeToDelivery'])
          // },
          {
            key: 'emailDropRate',
            title: <Tooltip title="Drop rate"><FontAwesomeIcon icon="times" /></Tooltip>,
            render: (item: any) => item.data['TimelineNotifications.emailDropRate'] ? Formatters.percentage(item.data['TimelineNotifications.emailDropRate']) : '-'
          },
          {
            key: 'emailBounceRate',
            title: <Tooltip title="Bounce rate"><FontAwesomeIcon icon="hourglass-half" /></Tooltip>,
            render: (item: any) => item.data['TimelineNotifications.emailBounceRate'] ? Formatters.percentage(item.data['TimelineNotifications.emailBounceRate']) : '-'
          },
          {
            key: 'emailComplainRate',
            title: <Tooltip title="Complain rate"><FontAwesomeIcon icon="thumbs-down" /></Tooltip>,
            render: (item: any) => item.data['TimelineNotifications.emailComplainRate'] ? Formatters.percentage(item.data['TimelineNotifications.emailComplainRate']) : '-'
          },
          {
            key: 'emailOpenRate',
            title: <Tooltip title="Open rate &amp; average time to first open"><FontAwesomeIcon icon="eye" /></Tooltip>,
            render: (item: any) => item.data['TimelineNotifications.emailOpenRate'] ? (Formatters.percentage(item.data['TimelineNotifications.emailOpenRate']) + ' in ' + Formatters.duration(item.data['TimelineNotifications.emailAverageTimeToOpen'])) : '-'
          },
          // {
          //   key: 'emailAverageTimeToOpen',
          //   title: <Tooltip title="Average time to open"><FontAwesomeIcon icon="eye" /> <FontAwesomeIcon icon="clock" /></Tooltip>,
          //   render: (item: any) => Formatters.duration(item.data['TimelineNotifications.emailAverageTimeToOpen'])
          // },
          {
            key: 'emailClickRate',
            title: <Tooltip title="Click rate &amp; average time to first click"><FontAwesomeIcon icon="mouse-pointer" /></Tooltip>,
            className: 'table-border-right',
            render: (item: any) => item.data['TimelineNotifications.emailClickRate'] ? (Formatters.percentage(item.data['TimelineNotifications.emailClickRate']) + ' in ' + Formatters.duration(item.data['TimelineNotifications.emailAverageTimeToClick'])) : '-'
          },
          // {
          //   key: 'emailAverageTimeToClick',
          //   title: <Tooltip title="Average time to click"><FontAwesomeIcon icon="mouse-pointer" /> <FontAwesomeIcon icon="clock" /></Tooltip>,
          //   className: 'table-border-right',                  
          //   render: (item: any) => Formatters.duration(item.data['TimelineNotifications.emailAverageTimeToClick'])
          // },
        ]
      },
      {
        key: 'sms',
        title: 'SMS',
        className: 'table-border-right table-border-bottom',
        children: [
          {
            key: 'total',
            title: t('sent', 'Sent'),
            render: (item: any) => item.data['TimelineNotifications.smsCount'] ? N(item.data['TimelineNotifications.smsCount']).format('0,0') : '-'
          },
          {
            key: 'errorRate',
            title: <Tooltip title="Error rate"><FontAwesomeIcon icon="exclamation-triangle" /></Tooltip>,
            render: (item: any) => item.data['TimelineNotifications.errorRate'] ? Formatters.percentage(item.data['TimelineNotifications.errorRate']) : '-'
          },
          {
            key: 'smsDeliveryRate',
            title: <Tooltip title="Delivery rate"><FontAwesomeIcon icon="check-square" /></Tooltip>,
            render: (item: any) => item.data['TimelineNotifications.smsDeliveryRate'] ? (Formatters.percentage(item.data['TimelineNotifications.smsDeliveryRate']) + ' in ' + Formatters.duration(item.data['TimelineNotifications.smsAverageTimeToDelivery'])) : '-'
          },
          // {
          //   key: 'smsAverageTimeToDelivery',
          //   title: <Tooltip title="Average time to delivery"><FontAwesomeIcon icon="check-square" /> <FontAwesomeIcon icon="clock" /></Tooltip>,
          //   render: (item: any) => Formatters.duration(item.data['TimelineNotifications.smsAverageTimeToDelivery'])
          // },
          {
            key: 'smsFailRate',
            title: <Tooltip title="Fail rate"><FontAwesomeIcon icon="times" /></Tooltip>,
            className: 'table-border-right',
            render: (item: any) => item.data['TimelineNotifications.smsFailRate'] ? Formatters.percentage(item.data['TimelineNotifications.smsFailRate']) : '-'
          },
        ]
      },
      {
        key: 'sms',
        title: 'Charts',
        className: 'table-border-right table-border-bottom',
        children: [
          {
            key: 'actions',
            title: <>
              {props.notificationId !== '_all' && <span className="link" onClick={changeNotificationId.bind(null, '_all')}>Show all</span>}
            </>,
            className: 'text-center',
            render: (item: any) => <div className="text-right">
              <Switch checked={props.notificationId === item.id} onChange={changeNotificationId.bind(null, item.id)} />
              {/* <SegmentStats segment={item} app={props.app} btnSize="small" btnType="primary" btnGhost={true} projectLayout={this.props.projectLayout}><><AreaChartOutlined /> Stats</></SegmentStats> */}
            </div>
          }
        ]
      },
    ]}
  />
}
export default Report
