import React, { Component } from 'react'

import { DeleteOutlined } from '@ant-design/icons';

import { Button, Popconfirm } from 'antd';
import t from '../../utils/translate'

class OrdersDeleteButton extends Component<any, any> {

  constructor(props: any) {
    super(props)

    this.state = {
      loading: false
    }

    this.deleteOrder = this.deleteOrder.bind(this)
  }

  deleteOrder() {
    if (this.state.loading === true) return
    else this.setState({loading: true})

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/orders.delete',
      data: {
        projectId: this.props.projectLayout.project.id,
        conversionRuleId: this.props.order.conversionRuleId,
        externalId: this.props.order.conversionExternalId
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({loading: false})
        return
      }

      this.props.app.addMessage('success', t('order_deleted', "Your order has successfully been removed!"))

      this.setState({loading: false})

      if (this.props.onComplete) {
        this.props.onComplete()
      }

      if (window.cmAgent) {
        window.cmAgent.event({
          label: 'deleteOrder',
          props: {
            orgId: this.props.projectLayout.project.organizationId,
            projectId: this.props.projectLayout.project.id,
          }
        })
        window.cmAgent.dispatch()
      }
    })
  }

  render() {
    return (
      <Popconfirm placement="topRight" title={t('delete_order_confirm', "Do you really want to delete this order?")} onConfirm={this.deleteOrder} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
        <Button type="ghost" icon={<DeleteOutlined />} />
      </Popconfirm>
    );
  }
}

export default OrdersDeleteButton