import React, { Component } from 'react'



import { Table, Button, Input, Badge } from 'antd';
import t from '../../utils/translate'
import Formatters from '../../utils/formatters'
import Moment from 'moment-timezone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import QS from 'qs'
import _ from 'lodash'
import AppLayout from 'components/app/layout';

const defaultParams = {
  limit: 15,
  skip: 0,
  sortKey: 'createdAt',
  sortOrder: 'descend',
}

class ProductsList extends Component<any, any> {
  _isMounted = false;

  constructor(props: any) {
    super(props)

    this.state = {
      loading: false,
      products: [],
      productsCount: 0,
    }

    this.fetchProducts = this.fetchProducts.bind(this)
    this.handleTableChange = this.handleTableChange.bind(this)
    this.onSearch = this.onSearch.bind(this)
    this.updateParams = this.updateParams.bind(this)
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchProducts()

    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Products (project: ' + this.props.projectLayout.project.id + ')',
        page: this.props.location.pathname,
        props: {
          orgId: this.props.projectLayout.project.organizationId,
          projectId: this.props.projectLayout.project.id
        }
      })
      window.cmAgent.dispatch()
    }
  }

  componentDidUpdate(prevProps: any) {
    // check url params have changed
    if (prevProps.location.search !== this.props.location.search) {

      const params: any = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })
      const prevParams = QS.parse(prevProps.location.search, { ignoreQueryPrefix: true })

      // dont refresh on toggle user
      if (params.showUser !== prevParams.showUser) {
        return
      }

      this.fetchProducts()
    }
  }

  onSearch(value: any) {
    this.updateParams([{ k: 'search', v: value }])
  }

  handleTableChange(pagination: any, filters: any, sorter: any) {
    // console.log('filters', filters);
    // console.log('pagination', pagination);
    // console.log('sorter', sorter);
    const params: any = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })

    const newParams = _.assign({
      limit: params.limit || defaultParams.limit,
      skip: params.skip || defaultParams.skip,
      sortKey: params.sortKey || defaultParams.sortKey,
      sortOrder: params.sortOrder || defaultParams.sortOrder,
    }, {
      limit: pagination.pageSize,
      skip: (pagination.current * pagination.pageSize) - pagination.pageSize,
      sortKey: sorter.columnKey,
      sortOrder: sorter.order,
    })

    this.props.history.push(window.location.pathname + '?' + QS.stringify(newParams, { indices: false }));
  }

  updateParams(updatedParams: any) {
    let params = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })
    updatedParams.forEach((up: any) => {
      params[up.k] = up.v
    })
    this.props.history.push(window.location.pathname + '?' + QS.stringify(params))
  }

  fetchProducts() {

    if (this.state.loading === true) {
      return
    }

    this.setState({ loading: true })

    const params: any = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })

    let query: any = {
      projectId: this.props.projectLayout.project.id,
      limit: params.limit || defaultParams.limit,
      skip: params.skip || defaultParams.skip,
      sortKey: params.sortKey || defaultParams.sortKey,
      sortOrder: params.sortOrder || defaultParams.sortOrder,
      search: params.search || '',
    }

    if (params.conversionRule) {
      query.conversionRuleId = params.conversionRule
    }

    this.props.app.ajaxRequest({
      method: 'get',
      url: '/products.list',
      params: query
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        // this.setState({loading: false})
        return
      }

      console.log('res', response.data);

      if (this._isMounted) {
        this.setState({
          products: response.data.products,
          productsCount: response.data.productsCount,
          loading: false
        })
      }
    })
  }

  render() {
    const params: any = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })

    return <AppLayout currentProject={this.props.projectLayout.project} currentOrganization={this.props.organizationLayout.organization} admin={this.props.app.state.admin} firebaseUser={this.props.app.state.firebaseUser} projects={this.props.organizationLayout.projects}>

      <h1>
        <div className="actions">
          <Input.Search style={{ width: '300px' }} allowClear placeholder={t('search', "Search")} defaultValue={params.search} onSearch={this.onSearch} />
        </div>
        {t('products', "Products")} <Badge className="margin-l-s" count={this.state.productsCount} overflowCount={999999999} style={{ backgroundColor: '#fff', color: '#999', boxShadow: '0 0 0 1px #d9d9d9 inset' }} />
      </h1>

      <Table
        dataSource={this.state.products}
        rowKey="id"
        className="edge-table block"
        onChange={this.handleTableChange}
        loading={this.state.loading}
        size="middle"
        pagination={{
          position: ['bottomCenter'],
          total: this.state.productsCount,
          pageSize: defaultParams.limit
        }}
        columns={[
          {
            title: '',
            key: 'cover',
            width: '50px',
            render: (record: any) => {

              if (record.coverId) {
                const img = record.images.find((x: any) => x.id === record.coverId)
                if (!img) return
                // console.log('record', record)
                // console.log('img', img)
                return <span className="aspect-ratio">
                  <img className="aspect-ratio-content" src={img.url} alt={img.alt} height={50} />
                </span>
              }

              return <span className="aspect-ratio">
                <svg className="image-dummy aspect-ratio-content" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path fill="currentColor" d="M14 9l-5 5-3-2-5 3v4h18v-6z"></path><path d="M19 0H1C.448 0 0 .448 0 1v18c0 .552.448 1 1 1h18c.552 0 1-.448 1-1V1c0-.552-.448-1-1-1zM8 6c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm0 4c1.654 0 3-1.346 3-3S9.654 4 8 4 5 5.346 5 7s1.346 3 3 3zm-6 8v-2.434l3.972-2.383 2.473 1.65c.398.264.925.21 1.262-.126l4.367-4.367L18 13.48V18H2zM18 2v8.92l-3.375-2.7c-.398-.32-.973-.287-1.332.073l-4.42 4.42-2.318-1.545c-.322-.214-.74-.225-1.07-.025L2 13.233V2h16z"></path>
                </svg>
              </span>
            }
          },
          {
            title: t('product', "Product"),
            key: 'title',
            render: (record: any) => <span>
              {record.title}
            </span>
          },
          {
            title: t('brand', "Brand"),
            key: 'brand',
            render: (record: any) => <span>
              {record.brand}
            </span>
          },
          {
            title: t('status', "Status"),
            key: 'status',
            width: 100,
            render: (record: any) => {
              switch (record.status) {
                case 'active':
                  return <Badge status="success" text="Active" />
                case 'archived':
                  return <Badge status="default" text="Archived" />
                case 'draft':
                  return <Badge status="processing" text="Draft" />
                default:
                  return <Badge status="default" text={record.status} />
              }
            }
          },
          {
            title: t('variant', "Variant"),
            key: 'variant',
            render: (record: any) => <div>
              {record.variants.map((variant: any) => <div key={variant.id} className="padding-b-s">
                {variant.title}
              </div>)}
            </div>
          },
          {
            title: t('price', "Price"),
            key: 'price',
            render: (record: any) => <div>
              {record.variants.map((variant: any) => <div key={variant.id} className="padding-b-s">
                {Formatters.multiPrices(variant.prices, this.props.projectLayout.project.currency)}
              </div>)}
            </div>
          },
          {
            title: t('in_stock', "In stock"),
            key: 'quantity',
            render: (record: any) => <div>
              {record.variants.map((variant: any) => <div key={variant.id} className="padding-b-s">
                {variant.stockLevel}
              </div>)}
            </div>
          },
          {
            title: t('created_at', "Created at"),
            key: 'date',
            render: (record: any) => Moment(record.createdAt).format('ll')
          },
          {
            key: 'actions',
            title: <span className="pull-right"><Button type="ghost" size="small" onClick={this.fetchProducts} disabled={this.state.loading}><FontAwesomeIcon icon="sync-alt" spin={this.state.loading} /></Button></span>,
            className: 'text-right',
            render: (record: any) => <Button.Group>
              {/*<DeleteOrderButton {...this.props} product={record} onComplete={this.fetchProducts} />*/}
            </Button.Group>
          },
        ]}
      />
    </AppLayout>
  }
}

export default ProductsList