
import React, { Component } from 'react'
 
// import t from '../../utils/translate'
import { Table } from 'antd';
import Formatters from '../../utils/formatters'

class OrderItemsTable extends Component<any, any> {

  render() {
    return <Table className="margin-v-m" pagination={false} showHeader={false} rowKey="externalId" dataSource={this.props.items} size="small" columns={[
        {
          key: 'sku',
          className: 'size-12',
          render: (item) => <div>
            <div className="semibold">{item.name+' '+(item.brand ? ', '+item.brand : '')+' '+(item.variant ? '('+item.variant+')' : '')}</div>
            <div className="size-10">{item.sku}</div>
          </div>
        },
        {
          key: 'category',
          className: 'size-12',
          render: (item) => item.category || ''
        },
        {
          key: 'price',
          className: 'size-12',
          render: (item) => <span>{item.quantity}x {(item.price >= 0) && <span>{Formatters.currency(this.props.projectLayout.project.currency, item.price, item.priceSource, item.currency || this.props.currency)}</span>}</span>
        },  
        // {
        //   key: 'coupon',
        //   className: 'size-12',
        //   render: (item) => item.coupon && <span><Icon type="tag" className="color-black-40" /> {item.coupon}</span>
        // },
      ]} />
  }
}

export default OrderItemsTable