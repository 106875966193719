import React, { Component } from 'react'

import t from '../../utils/translate'
import { DeleteOutlined, EditOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import {
    Button,
    Table,
    Tag,
    Popconfirm,
    Row,
    Col,
} from 'antd';
import CommissionGroupButton from '../commissionGroups/_button'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Numeral from 'numeral'
import Formatters from '../../utils/formatters'
import AppLayout from 'components/app/layout';

class CommissionGroupsList extends Component<any, any> {

    constructor(props: any) {
        super(props)

        this.state = {
            loading: false
        }

        this.deleteCommissionGroup = this.deleteCommissionGroup.bind(this)
    }

    deleteCommissionGroup(group: any) {
        if (this.state.loading === true) {
            return
        }
        else {
            this.setState({ loading: true })
        }

        this.props.app.ajaxRequest({
            method: 'post',
            url: '/commissionGroups.delete',
            data: { projectId: this.props.projectLayout.project.id, id: group.id }
        }, (errorMessage: any, response: any) => {

            if (errorMessage) {
                this.props.app.addMessage('error', errorMessage)
                this.setState({ loading: false })
                return
            }

            let projects = _.filter(this.props.organizationLayout.projects, (p: any) => p.id !== response.data.project.id)
            projects.push(response.data.project)
            this.props.organizationLayout.setProjects(projects)

            this.props.app.addMessage('success', t('commission_group_deleted', "Your commission group has successfully been removed!"))

            if (window.cmAgent) {
                window.cmAgent.event({
                    label: 'deleteCommissionGroup',
                    props: {
                        orgId: this.props.organizationLayout.organization.id,
                        projectId: this.props.projectLayout.project.id,
                    }
                })
                window.cmAgent.dispatch()
            }

            this.setState({ loading: false })
        })
    }

    render() {

        return <AppLayout currentProject={this.props.projectLayout.project} currentOrganization={this.props.organizationLayout.organization} admin={this.props.app.state.admin} firebaseUser={this.props.app.state.firebaseUser} projects={this.props.organizationLayout.projects}>
            <h1>
                <div className="actions">
                    <CommissionGroupButton app={this.props.app} organizationLayout={this.props.organizationLayout} project={this.props.projectLayout.project} btnType="primary" btnSize="default" btnContent={<span><PlusOutlined /> {t('new_commission_group', "New commission group")}</span>} />
                </div>
                {t('commission_groups', "Commission groups")}
            </h1>

            <Table
                pagination={false}
                dataSource={this.props.projectLayout.project.commissionGroups.filter((x: any) => !x.deletedAt)}
                rowKey="id"
                showHeader={false}
                className="block"
                columns={[
                    {
                        title: t('group', "Groups"),
                        key: 'name',
                        render: (group: any) => <div>
                            <div className="margin-t-m margin-l-m size-16"><Tag className="large" color={group.color}>{group.name}</Tag></div>
                            <div>
                                <div className="margin-t-m margin-l-m">
                                    <span className="subtitle"><FontAwesomeIcon className="margin-r-s" icon="mouse-pointer" /> CPC</span>
                                    {group.commissionSystem.hasCPC && Formatters.currency(this.props.projectLayout.project.currency, group.commissionSystem.cpc)}
                                    {!group.commissionSystem.hasCPC && <CloseOutlined />}
                                </div>
                            </div>
                            <Row gutter={24}>
                                {group.commissionSystem.conversionRules.map((cr: any) => {
                                    const crFound = _.find(this.props.projectLayout.project.conversionRules, x => x.id === cr.conversionRuleId)

                                    return <Col key={cr.conversionRuleId}>
                                        <div className="subtitle margin-a-m">{crFound.kind === 'order' && <FontAwesomeIcon className="margin-r-s" icon="shopping-cart" />}{crFound.name}</div>

                                        <div className="margin-l-m">
                                            {cr.tiers.map((tier: any, i: number) => <div key={i}>
                                                {cr.commissionType === 'percentage' && <div>
                                                    <span className="color-kpi-blue">{Numeral(tier.rate / 100).format('0[.]00') + '%'}</span> - {tier.threshold === 0 ? t('base_tier', "Base tier") : t('from_x_conversions_per_month', "From {x} conversions / month", { x: tier.threshold })}
                                                </div>}
                                            </div>)}
                                        </div>
                                    </Col>
                                })}
                            </Row>
                        </div>
                    },
                    {
                        title: '',
                        key: 'actions',
                        className: 'actions',
                        render: (group: any) => <Button.Group>
                            <Popconfirm placement="topRight" title={t('delete_commission_group_confirm', "Do you really want to delete this commission group?")} onConfirm={this.deleteCommissionGroup.bind(null, group)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}><Button type="ghost" size="small" icon={<DeleteOutlined />} /></Popconfirm>
                            <CommissionGroupButton app={this.props.app} organizationLayout={this.props.organizationLayout} project={this.props.projectLayout.project} commissionGroup={group} btnType="ghost" btnSize="small" btnContent={<span><EditOutlined /></span>} />
                        </Button.Group>
                    }
                ]}
            />
        </AppLayout>
    }
}

export default CommissionGroupsList