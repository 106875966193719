import React, { Component } from 'react'


import { CheckOutlined, CloseOutlined, LockFilled } from '@ant-design/icons';

import { Popconfirm, Table, Tag, Button, Tooltip, DatePicker, Input } from 'antd';
import t from '../../utils/translate'
import Formatters from '../../utils/formatters'
import CancelButton from './_cancelCommissionButton'
import Moment from 'moment-timezone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import QS from 'qs'
import _ from 'lodash'
import ReattributeCommissionsModal from './_reattributeCommissionsModal'
import AppLayout from 'components/app/layout';

const defaultParams = {
  limit: 30,
  skip: 0,
  sortKey: 'createdAt',
  sortOrder: 'descend',
}

class CommissionsList extends Component<any, any> {
  _isMounted = false;

  constructor(props: any) {
    super(props)

    this.state = {
      loading: false,
      commissions: [],
      commissionsCount: 0,

      filterConversionIdVisible: false,
      filterPartnershipIdVisible: false,
      filterDateVisible: false,

      showReattribute: false,
    }

    this.fetchCommissions = this.fetchCommissions.bind(this)
    this.handleTableChange = this.handleTableChange.bind(this)
    this.resetFilters = this.resetFilters.bind(this)
    this.onChangeExternalID = this.onChangeExternalID.bind(this)
    this.onChangePartnershipID = this.onChangePartnershipID.bind(this)
    this.changeCommissionStatus = this.changeCommissionStatus.bind(this)
    this.lockCommissionStatus = this.lockCommissionStatus.bind(this)
    this.toggleReattribute = this.toggleReattribute.bind(this)
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchCommissions()

    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Commissions (project: ' + this.props.projectLayout.project.id + ')',
        page: this.props.location.pathname,
        props: {
          orgId: this.props.projectLayout.project.organizationId,
          projectId: this.props.projectLayout.project.id
        }
      })
      window.cmAgent.dispatch()
    }
  }

  componentDidUpdate(prevProps: any) {
    // check url params have changed
    if (prevProps.location.search !== this.props.location.search) {

      const params: any = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })
      const prevParams = QS.parse(prevProps.location.search, { ignoreQueryPrefix: true })

      // dont refresh on toggle order
      if ((!prevParams.showOrder && params.showOrder) || (params.showOrder !== prevParams.showOrder)) {
        return
      }

      this.fetchCommissions()
    }
  }

  handleTableChange(pagination: any, filters: any, sorter: any) {
    // console.log('filters', filters);
    // console.log('pagination', pagination);
    // console.log('sorter', sorter);
    const params: any = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })

    const newParams = _.assign({
      limit: params.limit || defaultParams.limit,
      skip: params.skip || defaultParams.skip,
      sortKey: params.sortKey || defaultParams.sortKey,
      sortOrder: params.sortOrder || defaultParams.sortOrder,
      conversionRule: params.conversionRule,
    }, {
      limit: pagination.pageSize,
      skip: (pagination.current * pagination.pageSize) - pagination.pageSize,
      sortKey: sorter.columnKey,
      sortOrder: sorter.order,
      conversionRule: _.get(filters, 'conversionRule[0]'),
    })

    this.updateQuery(newParams)

    // this.props.history.push('/organizations/'+this.props.organizationLayout.organization.id+'/projects/'+this.props.projectLayout.project.id+'/commissions?'+QS.stringify(newParams, { indices: false }));
  }

  onChangeExternalID(value: any) {
    this.updateQuery({
      skip: 0,
      externalId: (value === '') ? undefined : value
    })
    this.setState({ filterConversionIdVisible: false })
  }

  onChangePartnershipID(value: any) {
    this.updateQuery({
      skip: 0,
      partnershipId: (value === '') ? undefined : value
    })
    this.setState({ filterPartnershipIdVisible: false })
  }

  resetFilters() {
    this.props.history.push('/organizations/' + this.props.organizationLayout.organization.id + '/projects/' + this.props.projectLayout.project.id + '/commissions?' + QS.stringify(defaultParams, { indices: false }));
  }

  updateQuery(update: any) {
    const params: any = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })

    let query = {
      limit: params.limit || defaultParams.limit,
      skip: params.skip || defaultParams.skip,
      dateFrom: params.dateFrom || undefined,
      dateTo: params.dateTo || undefined,
      externalId: params.externalId || undefined,
      channelId: params.channelId || undefined,
      partnershipId: params.partnershipId || undefined,
      sortKey: params.sortKey || defaultParams.sortKey,
      sortOrder: params.sortOrder || defaultParams.sortOrder,
      conversionRule: params.conversionRule || undefined,
    }

    this.props.history.push('/organizations/' + this.props.organizationLayout.organization.id + '/projects/' + this.props.projectLayout.project.id + '/commissions?' + QS.stringify(_.assign(query, update), { indices: false }));
  }

  fetchCommissions() {

    if (this.state.loading === true) {
      return
    }

    this.setState({ loading: true })

    const params: any = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })

    let query: any = {
      projectId: this.props.projectLayout.project.id,
      externalId: params.externalId || undefined,
      channelId: params.channelId || undefined,
      partnershipId: params.partnershipId || undefined,
      dateFrom: params.dateFrom || undefined,
      dateTo: params.dateTo || undefined,
      limit: params.limit || defaultParams.limit,
      skip: params.skip || defaultParams.skip,
      sortKey: params.sortKey || defaultParams.sortKey,
      sortOrder: params.sortOrder || defaultParams.sortOrder,
      // segments: params.segments || defaultParams.segments,
    }

    if (params.conversionRule) {
      query.conversionRuleId = params.conversionRule
    }

    this.props.app.ajaxRequest({
      method: 'get',
      url: '/partnerships.commissions',
      params: query
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        // this.setState({loading: false})
        return
      }

      // console.log('res', response.data);

      if (this._isMounted) {
        this.setState({
          commissions: response.data.commissions,
          commissionsCount: response.data.commissionsCount,
          loading: false
        })
      }
    })
  }

  changeCommissionStatus(id: string, status: string, reason: string, callback: any) {

    if (this.state.loading === true) {
      return
    }

    this.setState({ loading: true })

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/partnerships.setCommissionStatus',
      data: {
        projectId: this.props.projectLayout.project.id,
        sessionId: id,
        status: status,
        cancelledFor: reason
      }
    }, (errorMessage: any, response: any) => {

      if (callback) callback(errorMessage)

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }

      // console.log('res', response.data);
      this.props.app.addMessage('success', t('commission_status_updated', 'The status of this commission has been updated!'))

      if (this._isMounted) {
        this.setState({ loading: false })
        this.fetchCommissions()
      }
    })
  }

  lockCommissionStatus(id: string) {

    if (this.state.loading === true) {
      return
    }

    this.setState({ loading: true })

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/partnerships.lockCommission',
      data: {
        projectId: this.props.projectLayout.project.id,
        sessionId: id
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }

      // console.log('res', response.data);
      this.props.app.addMessage('success', t('commission_locked', 'The commission status has been locked!'))

      if (this._isMounted) {
        this.setState({ loading: false })
        this.fetchCommissions()
      }
    })
  }

  toggleReattribute() {
    this.setState({ showReattribute: !this.state.showReattribute })
  }

  render() {
    const params: any = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })

    // const channelsMap = _.keyBy(this.props.projectLayout.project.channels, 'id');
    const conversionRulesMap = _.keyBy(this.props.projectLayout.project.conversionRules, 'id')

    return <AppLayout currentProject={this.props.projectLayout.project} currentOrganization={this.props.organizationLayout.organization} admin={this.props.app.state.admin} firebaseUser={this.props.app.state.firebaseUser} projects={this.props.organizationLayout.projects}>

      <h1>
        <div className="actions">
          <Button type="ghost" onClick={this.toggleReattribute}>{t('reattribute_commissions', "Reattribute commissions")}</Button>
        </div>
        {t('commissions', "Commissions")}
      </h1>

      {this.state.showReattribute && <ReattributeCommissionsModal app={this.props.app} projectLayout={this.props.projectLayout} toggleModal={this.toggleReattribute} onComplete={() => { this.toggleReattribute(); }} />}

      <Table
        dataSource={this.state.commissions}
        rowKey="id"
        className="edge-table block"
        onChange={this.handleTableChange}
        loading={this.state.loading}
        pagination={{
          position: ['bottomCenter'],
          pageSize: defaultParams.limit,
          total: this.state.commissionsCount
        }}
        locale={{
          'emptyText': <span>
            {t('no_data', "No data")}
            {(params.externalId || params.channelId || params.dateFrom || params.dateTo) && <span className="padding-l-s">&bull; <span className="padding-l-s link" onClick={this.resetFilters}>
              {t('reset_filters', "Reset filters")}
            </span></span>}
          </span>
        }}
        columns={[
          {
            title: 'Session ID',
            key: 'sessionId',
            render: (record: any) => {
              if (record.id.length > 15) {
                return <Tooltip title={record.id}><span className="size-12">{_.truncate(record.id, { length: 16, omission: '...' })}</span></Tooltip>
              }
              return <span className="size-12">{record.id}</span>
            }
          },
          {
            title: 'Media Partner',
            key: 'partnershipId',
            sortOrder: params.sortKey === 'partnershipId' ? params.sortOrder : undefined,
            sorter: (a: any, b: any) => {
              if (a.commissionPartnershipId < b.commissionPartnershipId) { return -1 }
              if (a.commissionPartnershipId > b.commissionPartnershipId) { return 1 }
              return 0
            },
            filterDropdown: (
              <div className="custom-filter-dropdown">
                <Input.Search
                  placeholder="Search partnership ID"
                  defaultValue={params.partnershipId}
                  enterButton
                  allowClear
                  onSearch={this.onChangePartnershipID}
                />
              </div>
            ),
            filterDropdownVisible: this.state.filterPartnershipIdVisible,
            onFilterDropdownVisibleChange: (visible: any) => this.setState({ filterPartnershipIdVisible: visible }),
            filteredValue: (params.partnershipId && params.partnershipId !== '') ? [params.partnershipId] : [],
            render: (record: any) => <span className="link" onClick={() => this.props.history.push('/organizations/' + this.props.organizationLayout.organization.id + '/projects/' + this.props.projectLayout.project.id + '/media-partners?partnershipId=' + record.commissionPartnershipId)}>{record.commissionPartnershipId}</span>
          },
          {
            title: t('channel', "Channel"),
            key: 'channelId',
            // sortOrder: params.sortKey === 'channelId' ? params.sortOrder : undefined,
            // sorter: (a: any, b: any) => {
            //   if (a.commissionPartnershipId < b.commissionPartnershipId) { return -1 }
            //   if (a.commissionPartnershipId > b.commissionPartnershipId) { return 1 }
            //   return 0
            // },
            // filterDropdown: (
            //   <div className="custom-filter-dropdown">
            //     <Input.Search
            //       placeholder="Search partnership ID"
            //       defaultValue={params.channelId}
            //       enterButton
            //       allowClear
            //       onSearch={this.onChangePartnershipID}
            //     />
            //   </div>
            // ),
            // filterDropdownVisible: this.state.filterPartnershipIdVisible,
            // onFilterDropdownVisibleChange: visibl(e: any) => this.setState({ filterPartnershipIdVisible: visible }),
            // filteredValue: (params.channelId && params.channelId !== '') ? [params.channelId] : [],
            render: (record: any) => {
              const channel = this.props.projectLayout.project.channels.find((x: any) => x.id === record.commissionChannelId)

              if (!channel) return '-'
              const channelGroup = this.props.projectLayout.project.channelGroups.find((x: any) => channel.groupId === x.id)
              return <span>{channel.name} <Tag className="small" color={channelGroup.color}>{channelGroup.name}</Tag></span>
            }
          },
          {
            title: 'Conversion',
            key: 'externalId',
            sortOrder: params.sortKey === 'externalId' ? params.sortOrder : undefined,
            sorter: (a: any, b: any) => {
              if (a.conversionExternalId < b.conversionExternalId) { return -1 }
              if (a.conversionExternalId > b.conversionExternalId) { return 1 }
              return 0
            },
            filterDropdown: (
              <div className="custom-filter-dropdown">
                <Input.Search
                  placeholder="Search conversion ID"
                  defaultValue={params.externalId}
                  enterButton
                  allowClear
                  onSearch={this.onChangeExternalID}
                />
              </div>
            ),
            filterDropdownVisible: this.state.filterConversionIdVisible,
            onFilterDropdownVisibleChange: (visible: any) => this.setState({ filterConversionIdVisible: visible }),
            filteredValue: (params.externalId && params.externalId !== '') ? [params.externalId] : [],
            render: (record: any) => {
              if (!record.conversionExternalId) return ''
              return <span>
                <span className="link" onClick={this.props.projectLayout.viewOrder.bind(null, record.conversionExternalId, record.conversionRuleId)}>{record.conversionExternalId.length > 15 ?
                  <Tooltip title={record.conversionExternalId}>{_.truncate(record.conversionExternalId, { length: 16, omission: '...' })}</Tooltip>
                  : record.conversionExternalId
                }
                </span>
                {record.conversionRevenue && <small className="margin-l-s">{Formatters.currency(this.props.projectLayout.project.currency, record.conversionRevenue)}</small>}
              </span>
            }
          },
          {
            title: t('conversion_rule', "Conversion rule"),
            key: 'conversionRule',
            filters: this.props.projectLayout.project.conversionRules.map((cr: any) => {
              return {
                text: cr.name,
                value: cr.id
              }
            }),
            filterMultiple: false,
            onFilter: (value, record) => record.conversionRuleId === value,
            render: (record: any) => {
              // console.log('record', record);
              if (record.conversionRuleId) return conversionRulesMap[record.conversionRuleId].name
              return ''
            }
          },
          {
            title: 'CPC',
            key: 'cpc',
            render: (record: any) => <span className="color-green">{Formatters.currency(this.props.projectLayout.project.currency, record.commissionCpc)}</span>
          },
          {
            title: 'CPA / CPL',
            key: 'cpa',
            render: (record: any) => <span className="color-green">{Formatters.currency(this.props.projectLayout.project.currency, record.commissionAmount)}</span>
          },
          {
            title: 'Date',
            key: 'createdAt',
            filterDropdown: (
              <div className="custom-filter-dropdown">
                <DatePicker
                  style={{ width: '100%', marginBottom: '7px' }}
                  defaultValue={(params.dateFrom) ? Moment(params.dateFrom, 'YYYY-MM-DD') : undefined}
                  disabledDate={(date: any) => {
                    return date && date.isAfter(Moment())
                  }}
                  format="MMM Do YYYY"
                  placeholder="Start date"
                  onChange={(momentDate) => {
                    this.updateQuery({
                      dateFrom: (momentDate) ? momentDate.format('YYYY-MM-DD') : undefined
                    })
                  }} />

                <DatePicker
                  style={{ width: '100%' }}
                  defaultValue={(params.dateTo) ? Moment(params.dateTo, 'YYYY-MM-DD') : undefined}
                  disabledDate={(date: any) => {
                    return date && date.isAfter(Moment())
                  }}
                  format="MMM Do YYYY"
                  placeholder="End date"
                  onChange={(momentDate) => {
                    this.updateQuery({
                      dateTo: (momentDate) ? momentDate.format('YYYY-MM-DD') : undefined
                    })
                  }} />
              </div>
            ),
            filterDropdownVisible: this.state.filterDateVisible,
            onFilterDropdownVisibleChange: (visible: any) => this.setState({ filterDateVisible: visible }),
            filteredValue: (params.dateFrom || params.dateTo) ? [params.dateFrom, params.dateTo] : [],
            sortOrder: params.sortKey === 'createdAt' ? params.sortOrder : undefined,
            sorter: (a: any, b: any) => {
              if (a.createdAt < b.createdAt) { return -1 }
              if (a.createdAt > b.createdAt) { return 1 }
              return 0
            },
            render: (record: any) => <small>{Moment(record.createdAt).tz('UTC').format('lll')}</small>
          },
          {
            title: t('status', "Status"),
            key: 'status',
            render: (record: any) => {
              // console.log('record', record);
              if (record.commissionPaymentId) {
                return <Tag color="purple">{t('paid', "Paid")}</Tag>
              }
              let label = <Tag color="green">{t('approved', "Approved")}</Tag>

              if (record.commissionStatus === 'waiting') {
                label = <Tag color="orange">{t('waiting', "Waiting")}</Tag>
              }
              if (record.commissionStatus === 'cancelled') {
                label = <Tooltip title={record.commissionCancelledFor}><Tag color="red">{t('cancelled', "Cancelled")}</Tag></Tooltip>
              }

              if (record.commissionLockedAt && Moment(record.commissionLockedAt).tz('UTC').isAfter(Moment())) {
                return <span>{label} <div><small>{Moment(record.commissionLockedAt).tz('UTC').fromNow()}</small></div></span>
              }

              return label
            }
          },
          {
            key: 'actions',
            title: <span className="pull-right"><Button type="ghost" size="small" onClick={this.fetchCommissions} disabled={this.state.loading}><FontAwesomeIcon icon="sync-alt" spin={this.state.loading} /></Button></span>,
            className: 'text-right',
            render: (record: any) => <Button.Group>
              {(!record.commissionLockedAt || (record.commissionLockedAt && Moment(record.commissionLockedAt).tz('UTC').isAfter(Moment()))) && <React.Fragment>
                {(record.commissionStatus === 'waiting' || record.commissionStatus === 'approved') &&
                  <CancelButton app={this.props.app}
                    sessionId={record.id}
                    btnSize="small"
                    btnType="default"
                    btnContent={<CloseOutlined />}
                    changeCommissionStatus={this.changeCommissionStatus}
                  />}
                {(record.commissionStatus === 'waiting' || record.commissionStatus === 'cancelled') && <Popconfirm title={t('confirm_approve_commission', "Do you really want to approve this commission?")} onConfirm={this.changeCommissionStatus.bind(null, record.id, "approved", '', undefined)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                  <Button size="small"><CheckOutlined /></Button>
                </Popconfirm>}

                <Popconfirm title={t('confirm_lock_commission', "Do you really want to lock the status of this commission?")} onConfirm={this.lockCommissionStatus.bind(null, record.id)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                  <Button size="small"><LockFilled /></Button>
                </Popconfirm>
              </React.Fragment>}
            </Button.Group>
          },
        ]}
      />
    </AppLayout>
  }
}

export default CommissionsList