import React, { Component } from 'react'

import t from '../../utils/translate'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Table, Tag, Drawer, Popconfirm } from 'antd';
import DomainForm from './_form'
import _ from 'lodash'

class DomainsTable extends Component<any, any> {

  constructor(props: any) {
    super(props)
    
    this.state = {
      loading: false,
      drawerVisible: false,
      domainToEdit: undefined
    }

    this.closeDrawer = this.closeDrawer.bind(this)
    this.editDomain = this.editDomain.bind(this)
    this.deleteDomain = this.deleteDomain.bind(this)
  }

  editDomain(domain: any) {
    this.setState({drawerVisible: true, domainToEdit: domain})
  }

  deleteDomain(domain: any) {
    if (this.state.loading === true) {
      return
    }
    else {
      this.setState({loading: true})
    }

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/domains.delete',
      data: {projectId: this.props.projectLayout.project.id, id: domain.id}
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({loading: false})
        return
      }

      let projects = _.filter(this.props.organizationLayout.projects, (p: any) => p.id !== response.data.project.id)
      projects.push(response.data.project)
      this.props.organizationLayout.setProjects(projects)

      this.props.app.addMessage('success', t('project_domain_deleted', "Your domain has successfully been removed!"))

        
      if (window.cmAgent) {
        window.cmAgent.event({
          label: 'deleteDomain',
          props: {
            orgId: this.props.organizationLayout.organization.id,
            projectId: this.props.projectLayout.project.id,
            domainKind: domain.kind
          }
        })
        window.cmAgent.dispatch()
      }

      this.setState({loading: false})
    })
  }

  closeDrawer() {
    this.setState({
      drawerVisible: !this.state.drawerVisible,
      domainToEdit: undefined
    })
  }

  render() {

    if (this.props.projectLayout.project.domains.length === 0) {
      const url = new URL(this.props.projectLayout.project.websiteUrl)
      return <div className="block margin-h-m padding-a-m">
        <DomainForm 
        {...this.props} 
        edit={false} 
        domain={{
          kind: 'web', 
          host: url.host, 
          paramsWhitelist: ['page', 'category', 'id'],
          homepagesPaths: ['/'],
        }} /></div>
    }

    return (
      <div>
        {this.state.drawerVisible === true && <Drawer
          width={800}
          title={this.state.domainToEdit ? t('edit_domain', "Edit domain") : t('new_domain', "New domain")}
          visible={this.state.drawerVisible}
          onClose={() => this.setState({drawerVisible: false, domainToEdit: undefined})}
        >
          <DomainForm {...this.props} isDrawer={true} edit={this.state.domainToEdit ? true : false} domain={this.state.domainToEdit} onComplete={() => this.setState({drawerVisible: false})} closeDrawer={this.closeDrawer} />
        </Drawer>}

      <Table
        pagination={false}
        className="block"
        dataSource={this.props.projectLayout.project.domains.filter((x: any) => !x.deletedAt)}
        rowKey="id"
        loading={this.state.loading}
        columns={[
          {
            title: 'Name / ID',
            key: 'id',
            render: dom => <div>
               <b>{dom.name}</b><br/>
               {dom.id}
            </div>
          },
          {
            title: 'Type',
            key: 'type',
            render: dom => dom.kind
          },
          {
            title: 'Details',
            key: 'params',
            render: dom => <div>
              {dom.kind === 'web' && <>
              <div className="margin-b-m"><b>Hosts:</b> {dom.hosts && dom.hosts.map((x: any) => <div className="padding-b-s" key={x.host}>
                - <Tag className="no-pointer">{x.host}</Tag> {x.pathPrefix && x.pathPrefix!== '' && <small>rewrite URLs prefix with <Tag>{x.pathPrefix}</Tag></small>}
              </div>)}</div>
              <div className="margin-b-m"><b>Keep URL params:</b> {dom.paramsWhitelist && dom.paramsWhitelist.map((p: any) => <div className="padding-b-s" key={p}>
                - <Tag className="no-pointer" key={p}>{p}</Tag>
              </div>)}</div>
              <div className="margin-b-m"><b>Homepages URL paths:</b> {dom.homepagesPaths && dom.homepagesPaths.map((p: any) => <div className="padding-b-s" key={p}>
                - <Tag className="no-pointer" key={p}>{p}</Tag>
              </div>)}</div>
              </>}
            </div>
          },
          {
            title: <Button type="primary" size="small" onClick={() => this.setState({drawerVisible: !this.state.drawerVisible})}><PlusOutlined /> {t('new_domain', "New domain")}</Button>,
            key: 'actions',
            className: 'actions',
            render: dom => <div className="text-right"><Button.Group>
              <Popconfirm placement="topRight" title={t('delete_domain_confirm', "Do you really want to delete this domain?")} onConfirm={() => this.deleteDomain(dom)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}><Button type="ghost" size="small"><DeleteOutlined /></Button></Popconfirm>
              <Button type="ghost" size="small" onClick={() => this.editDomain(dom)}><EditOutlined /></Button>
            </Button.Group>
            </div>
          }
        ]}
      />
      </div>
    );
  }
}

export default DomainsTable