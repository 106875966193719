import React, { useState } from 'react'

import { Button, Badge, Popconfirm, Input, Form } from 'antd';
import t from '../../utils/translate'
import _ from 'lodash'
import logoTwilio from '../../images/integrations/twilio.png'

const ConnectTwilio = ({ app, projectLayout, organizationLayout, integration }: any) => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const onFinish = (values: any) => {
    setLoading(true)

    app.ajaxRequest({
      method: 'post',
      url: '/integrations.connect',
      data: {
        projectId: projectLayout.project.id,
        integrationId: integration.id,
        twilio: values,
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        app.addMessage('error', errorMessage)
        setLoading(false)
        return
      }

      let projects = _.filter(organizationLayout.projects, (p: any) => p.id !== response.data.project.id)
      projects.push(response.data.project)
      organizationLayout.setProjects(projects)

      app.addMessage('success', t('twilio_connect_success', "Twilio has successfully been connected!"))
      setLoading(false)
    })
  }

  return <div style={{ width: '500px', margin: '32px auto' }}>
    <div className="paper padding-a-xl text-center">
      <img src={logoTwilio} alt="" style={{ height: '50px' }} />

      <Form form={form} layout="vertical" onFinish={onFinish}>

        <Form.Item name="accountSid" label={t('enter_your_account_sid', "Enter your Account SID") + ':'} rules={[{ required: true, type: 'string' }]}>
          <Input />
        </Form.Item>

        <Form.Item name="authToken" label={t('enter_your_api_key', "Enter your Auth Token") + ':'} rules={[{ required: true, type: 'string' }]}>
          <Input.Password />
        </Form.Item>

        <Form.Item name="messagingServiceSid" label={t('enter_your_messaging_service_sid', "Enter your Messaging Service SID") + ':'} rules={[{ required: true, type: 'string' }]}>
          <Input />
        </Form.Item>

        <Button size="large" type="primary" htmlType="submit" loading={loading}>{t('confirm', "Confirm")}</Button>
      </Form>
    </div>
  </div>
}

const IntegrationsTwilio = (props: any) => {
  return <div>
    <h2 className="margin-t-m">{t('settings', "Settings")}</h2>
    {(props.integration.status === 'setup') || (props.integration.status === 'disconnected') ?
      <ConnectTwilio
        integration={props.integration}
        app={props.app}
        organizationLayout={props.organizationLayout}
        projectLayout={props.projectLayout}
      />
      :
      <div className="margin-a-m">
        <table className="key-value-table">
          <tbody>
            <tr>
              <th style={{ width: '300px' }}>{t('integration_id', "Integration ID")}</th>
              <td>{props.integration.id}</td>
            </tr>
            <tr>
              <th>{t('status', "Status")}</th>
              <td>
                {props.integration.status === 'connected' && <span>
                  <Badge status="success" /> {t('connected', "Connected")}
                  <Popconfirm placement="top" title={t('disconnect_twilio_confirm', "Do you really want to disconnect Twilio?")} onConfirm={props.disconnect.bind(null, props.integration)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                    <Button className="margin-l-m" ghost size="small" danger={true}>{t('disconnect', "Disconnect")}</Button>
                  </Popconfirm>
                </span>}
              </td>
            </tr>
            <tr>
              <th>Account SID</th>
              <td>
                {props.integration.twilioSettings.accountSid}
              </td>
            </tr>
            <tr>
              <th>Messaging Service SID</th>
              <td>
                {props.integration.twilioSettings.messagingServiceSid}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    }
  </div>
}

export default IntegrationsTwilio