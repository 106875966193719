import {
  Row,
  Col
} from 'antd';
import t from '../../../utils/translate'

import {
  Chart,
  Geom,
  Legend,
  Tooltip,
  Coordinate,
  View,
  Axis,
  Edge
} from "bizcharts";
import DataSet from "@antv/data-set";

const OrderChannelsDependenciesDiagram = ({ data }) => {


    const currentDs = new DataSet()
    const currentDv = currentDs.createView().source(data.currentPeriod, {
      type: "graph",
      edges: d => d.links
    })

    currentDv.transform({
      type: "diagram.arc",
      sourceWeight: (e: any) => e.sourceWeight,
      targetWeight: (e: any) => e.targetWeight,
      weight: true,
      marginRatio: 0.3
    })

    const previousDs = new DataSet()
    const previousDv = previousDs.createView().source(data.previousPeriod, {
      type: "graph",
      edges: d => d.links
    })

    previousDv.transform({
      type: "diagram.arc",
      sourceWeight: (e: any) => e.sourceWeight,
      targetWeight: (e: any) => e.targetWeight,
      weight: true,
      marginRatio: 0.3
    })

    const scale = { x: { sync: true, nice: true }, y: { sync: true, nice: true, max: 1 } }

    return <div>
      {data && <Row gutter={24}>
        <Col span={12}>
          <div className="block">
            <h2>{t('current_period', "Current period")}</h2>

            <div className="margin-a-m">
            <Chart
                height={window.innerHeight - 300}
                padding={[0, 30, 30, 30]}
                scale={scale}
                autoFit
              >
                <Legend visible={false} />
                <Tooltip showTitle={false} showMarkers={false} />
                <View data={currentDv.edges} axis={false}>
                  <Coordinate type="polar" reflect="y" />
                  <Axis visible={false} />
                  <Edge
                    position="x*y"
                    shape="arc"
                    color="source"
                    opacity={0.5}
                    tooltip={['source*target*value', (source, target, value) => {
                      const sourceNode = data.currentPeriod.nodes.find((n: any) => n.id === source)
                      const targetNode = data.currentPeriod.nodes.find((n: any) => n.id === target)
                      return {
                        name: sourceNode.name,
                        value: targetNode.name,
                      }
                    }]}
                    style={{ fillOpacity: 0.5 }}
                  />
                </View>
                <View data={currentDv.nodes} axis={false}>
                  <Coordinate type="polar" reflect="y" />
                  <Axis visible={false} />
                  <Geom type="polygon" position="x*y" color="id"
                    label={['name', {
                      labelEmit: true,
                      style: {
                        fill: "black"
                      }
                    }]}
                    style={{ fillOpacity: 0.5 }}
                  />
                </View>
              </Chart>
            </div>
          </div>
        </Col>

        <Col span={12}>
          <div className="block">
            <h2>{t('previous_period', "Previous period")}</h2>

            <div className="margin-a-m">
              <Chart
                height={window.innerHeight - 300}
                padding={[0, 30, 30, 30]}
                scale={scale}
                autoFit
              >
                <Legend visible={false} />
                <Tooltip showTitle={false} showMarkers={false} />
                <View data={previousDv.edges} axis={false}>
                  <Coordinate type="polar" reflect="y" />
                  <Axis visible={false} />
                  <Edge
                    position="x*y"
                    shape="arc"
                    color="source"
                    opacity={0.5}
                    tooltip={['source*target*value', (source, target, value) => {
                      const sourceNode = data.previousPeriod.nodes.find((n: any) => n.id === source)
                      const targetNode = data.previousPeriod.nodes.find((n: any) => n.id === target)
                      return {
                        name: sourceNode.name,
                        value: targetNode.name,
                      }
                    }]}
                    style={{ fillOpacity: 0.5 }}
                  />
                </View>
                <View data={previousDv.nodes} axis={false}>
                  <Coordinate type="polar" reflect="y" />
                  <Axis visible={false} />
                  <Geom type="polygon" position="x*y" color="id"
                    label={['name', {
                      labelEmit: true,
                      style: {
                        fill: "black"
                      }
                    }]}
                    style={{ fillOpacity: 0.5 }}
                  />
                </View>
              </Chart>
            </div>
          </div>

        </Col>
      </Row>}
    </div>
}

export default OrderChannelsDependenciesDiagram


