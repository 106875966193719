import React, { useState } from 'react'
import { 
  Table,
  Drawer,
  Form,
  Input,
  Button,
  InputNumber,
  Select,
  Radio,
  Divider,
  Row,
  Col,
  Switch,
  Tag,
} from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import t from '../../utils/translate'
import _ from 'lodash'

const TheForm = ({btnType, btnSize, btnContent, project, app, organizationLayout, commissionGroup, onComplete}: any) => {
  const [form] = Form.useForm()
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const closeDrawer = () => {
    setDrawerVisible(false)
  }

  const addTier = (field: any) => {
    const values = form.getFieldsValue()
    const lastTier: any = _.last(values.commissionSystem.conversionRules[field.name].tiers)

    if (values.commissionSystem.conversionRules[field.name].commissionType === 'percentage') {
      values.commissionSystem.conversionRules[field.name].tiers.push({
        threshold: lastTier.threshold+10,
        rate: lastTier.rate+100,
      })
    } else {
      values.commissionSystem.conversionRules[field.name].tiers.push({
        threshold: lastTier.threshold+10,
        amount: lastTier.amount+100,
      })
    }

    form.setFieldsValue(values)
  }

  const generateId = (name: string, existingGroups: any, increment: number): string => {
    let id = _.kebabCase(name)

    if (_.find(existingGroups, x => x.id === id)) {
      increment = increment+1

      id = name+increment

      // check with new increment
      if (_.find(existingGroups, x => x.id === id)) {
        return generateId(name, existingGroups, increment)
      }
    }

    return id
  }

  const onFinish = (values: any) => {
    // console.log('values', values);
    
    if (loading) return

    setLoading(true)

    if (commissionGroup) {
      values.id = commissionGroup.id
    } else {
      // generate id
      values.id = generateId(values.name, project.commissionGroups || [], 1)
    }

    values.projectId = project.id

    app.ajaxRequest({
      method: 'post',
      url: commissionGroup ? '/commissionGroups.update' : '/commissionGroups.create',
      data: values
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        app.addMessage('error', errorMessage)
        setLoading(false)
        return
      }

      if (commissionGroup) {
        app.addMessage('success', t('commission_group_updated', 'Your commission group has successfully been updated.'))
      } else {
        app.addMessage('success', t('commission_group_created', 'Your commission group has successfully been created.'))        
      }

      setLoading(false)

      let projects = _.filter(organizationLayout.projects, (p: any) => p.id !== response.data.project.id)
      projects.push(response.data.project)
      organizationLayout.setProjects(projects)

      if (window.cmAgent) {
        // window.cmAgent.event({label: commissionGroup ? 'update_domain' : 'add_domain'})
        // window.cmAgent.dispatch();
      }

      if (onComplete) {
        onComplete()
      }

      form.resetFields()
      setDrawerVisible(false)
      // let updatedProjects = _.filter(this.props.organizationLayout.projects, (p: any) => p.id !== this.props.project.id)
      // updatedProjects.push(response.data.project)
      // this.props.organizationLayout.setProjects(updatedProjects)
    })
  }

  
  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };

  // console.log('commissionGroup', commissionGroup);

  const initialValues = commissionGroup ? Object.assign({}, commissionGroup) : {
    commissionSystem: {
      conversionRules: []
    }
  }

  // inject missing conversion rules
  project.conversionRules.forEach((cr: any) => {
    if (cr.deletedAt) return

    // add cr as non activated
    if (!_.find(initialValues.commissionSystem.conversionRules, x => x.conversionRuleId === cr.id)) {
      initialValues.commissionSystem.conversionRules.push({
        isActivated: false,
        conversionRuleId: cr.id,
        commissionType: 'percentage',
        tiers: [{threshold: 0, rate: 500}]
      })
    }
  })

  // console.log('initialValues', initialValues);

  return <React.Fragment>
      <Button type={btnType || 'primary'} size={btnSize || 'small'} onClick={() => setDrawerVisible(true)}>{btnContent}</Button>

      <Drawer
        title={commissionGroup ? t('edit_commission_group', "Edit commission group") : t('new_commission_group', "New commission group")}
        width={720}
        visible={drawerVisible}
        onClose={closeDrawer}
        bodyStyle={{ paddingBottom: 80 }}
        footer={<div style={{textAlign: 'right'}}>
          <Button loading={loading} onClick={closeDrawer} style={{ marginRight: 8 }}>{t('cancel', "Cancel")}</Button>
          <Button loading={loading} onClick={() => {
            form.submit()
          }} type="primary">{t('confirm', "Confirm")}</Button>
        </div>}
      >
      <Form form={form} initialValues={initialValues} labelCol={{span: 8}} wrapperCol={{span: 16}} name="groupForm" onFinish={onFinish}>
        <Form.Item
          label={t('group_name', "Group name")}
          name="name"
          validateFirst={true}
          rules={[{ required: true, type: 'string', message: t('field_required', "This field is required") },]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('color', "Color")}
          name="color"
          rules={[{ required: true, type: 'string', message: t('field_required', "This field is required") },]}          
        >
          <Select style={{ width: 120 }}>
            <Select.Option value="magenta"><Tag className="small" color="magenta">magenta</Tag></Select.Option>
            <Select.Option value="red"><Tag className="small" color="red">red</Tag></Select.Option>
            <Select.Option value="volcano"><Tag className="small" color="volcano">volcano</Tag></Select.Option>
            <Select.Option value="orange"><Tag className="small" color="orange">orange</Tag></Select.Option>
            <Select.Option value="gold"><Tag className="small" color="gold">gold</Tag></Select.Option>
            <Select.Option value="lime"><Tag className="small" color="lime">lime</Tag></Select.Option>
            <Select.Option value="green"><Tag className="small" color="green">green</Tag></Select.Option>
            <Select.Option value="cyan"><Tag className="small" color="cyan">cyan</Tag></Select.Option>
            <Select.Option value="blue"><Tag className="small" color="blue">blue</Tag></Select.Option>
            <Select.Option value="geekblue"><Tag className="small" color="geekblue">geekblue</Tag></Select.Option>
            <Select.Option value="purple"><Tag className="small" color="purple">purple</Tag></Select.Option>
            <Select.Option value="grey"><Tag className="small">grey</Tag></Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label={t('has_cpc', "Has CPC")}
          name={['commissionSystem', 'hasCPC']}
          validateFirst={true}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>

        <Form.Item shouldUpdate noStyle>
          {(funcs) => {
            if (funcs.getFieldValue(['commissionSystem', 'hasCPC']) === true) {
              return <Form.Item
                labelCol={{span: 8}} wrapperCol={{span: 16}}
                label="CPC"
                name={['commissionSystem', 'cpc']}
                validateFirst={true}
                rules={[{ required: true, type: 'number', min: 1, message: t('field_required', "This field is required") },]}
              >
                <InputNumber style={{width: 150}} min={1} precision={2} formatter={(val: any) => val ? project.currency+' '+(val/100): ''} parser={(val: any) => val ? Number(val.replace(project.currency+' ', ''))*100 : 0} />
              </Form.Item>
            }
          }}
        </Form.Item>

        <Divider plain>{t('conversion_rules', "Conversion rules")}</Divider>

        <Form.List name={['commissionSystem', 'conversionRules']}>

          {(fields) => <div>
            {fields.map(field => {
              const cr = project.conversionRules.find((x: any) => x.id === initialValues.commissionSystem.conversionRules[field.name].conversionRuleId)

              return <div className="paper margin-b-l padding-a-l" key={field.name}>
              <Row>
                <Col span={12}><h3>{cr && cr.name}</h3></Col>
                <Col span={12} className="text-right">
                  <span className="padding-r-m">{t('activate_commissions', "Activate commissions")}:</span>

                  <Form.Item shouldUpdate noStyle {...field} valuePropName="checked" name={[field.name, 'isActivated']} fieldKey={[field.fieldKey, 'isActivated']}>
                    <Switch />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item shouldUpdate noStyle>
                {(funcs) => {
                  if (funcs.getFieldValue(['commissionSystem', 'conversionRules', field.name, 'isActivated']) === true) {
                    return <React.Fragment>
                      <Form.Item {...field} name={[field.name, 'commissionType']} fieldKey={[field.fieldKey, 'commissionType']} noStyle>
                        <Radio.Group className="margin-t-m">
                          <Radio style={radioStyle} value="percentage">{t('percentage_of_revenue', "Percentage of revenue")}</Radio>
                          <Radio style={radioStyle} value="flatAmount">{t('flat_amount', "Flat amount")}</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item name={[field.name, 'conversionRuleId']} fieldKey={[field.fieldKey, 'conversionRuleId']} noStyle hidden={true}>
                        <Input />
                      </Form.Item>

                      <Form.List name={[field.name, 'tiers']}>
                        {(tierFields, tierFuncs) => {
                          // console.log('tierFields', tierFields);

                          return <div className="margin-t-m">
                            <Table size="small" dataSource={tierFields} pagination={false}
                              columns={[
                                {
                                  title: t('commission', "Commission"),
                                  key: 'value',
                                  render: tierField => {

                                    return <Form.Item noStyle shouldUpdate>
                                      {(funcs) => {
                                        // console.log('values', funcs.getFieldsValue());
                                        const payout = funcs.getFieldValue(['commissionSystem', 'conversionRules', field.fieldKey, 'commissionType'])

                                        if (payout === 'percentage') {
                                          return <span>
                                            <Form.Item isListField={true} 
                                              name={[tierField.name, 'rate']} 
                                              fieldKey={[tierField.fieldKey, 'rate']}
                                              className="no-margin"
                                              rules={[{ required: true, type: 'number', min: 1, message: t('field_required', "This field is required") }]}
                                              >
                                              <InputNumber min={1} style={{width: 120}} precision={2} formatter={(val: any) => val ? (val/100)+' %' : ''} parser={(val: any) => Number(val.replace(' %', ''))*100} />
                                            </Form.Item>
                                          </span>
                                        } else {
                                          return <div>
                                            <Form.Item isListField={true} 
                                              name={[tierField.name, 'amount']} 
                                              fieldKey={[tierField.fieldKey, 'amount']} 
                                              className="no-margin"
                                              rules={[{ required: true, type: 'number', min: 1, message: t('field_required', "This field is required") }]}
                                            >
                                              <InputNumber min={1} style={{width: 120}} precision={2} formatter={(val: any) => project.currency+' '+(val/100)} parser={(val: any) => Number(val.replace(project.currency+' '))*100} />
                                            </Form.Item>
                                          </div>
                                        }
                                      }}
                                    </Form.Item>
                                  }
                                },
                                {
                                  title: t('conversions_in_the_last_30_days', "Conversions in the last 30 days"),
                                  key: 'threshold',
                                  render: tierField => {
                                    if (tierField.fieldKey === 0) {
                                      return t('base_tier', "Base tier")
                                    }
                                    return <Form.Item isListField={true} 
                                      className="no-margin"
                                      name={[tierField.name, 'threshold']}
                                      fieldKey={[tierField.fieldKey, 'threshold']}
                                      rules={[{ required: true, type: 'number', min: 1, message: t('field_required', "This field is required") }]}
                                      >
                                      <InputNumber step={1} min={1} />
                                    </Form.Item>
                                  }
                                },
                                {
                                  title: '',
                                  key: 'btn',
                                  className: 'text-right',
                                  render: tierField => {
                                    if (tierField.fieldKey === 0) return ''
                                    return <Button type="default" icon={<DeleteOutlined />} onClick={() => tierFuncs.remove(tierField.name)} />
                                  }
                                }
                              ]}
                            />

                            <Button block className="margin-t-m" type="dashed" onClick={addTier.bind(null, field)}>
                              <PlusOutlined /> {t('add_tier', "Add tier")}
                            </Button>
                        </div>}}
                      </Form.List>
                    </React.Fragment>
                  } else {
                    return ''
                  }
                }}
              </Form.Item>
            </div>})}
          </div>}
        </Form.List>
      </Form>
    </Drawer>
  </React.Fragment>
}

export default TheForm