
import React, { Component } from 'react'

import t from '../../utils/translate'
import _ from 'lodash';
// import { TagOutlined } from '@ant-design/icons';
import { Popover, Tag, Tooltip } from 'antd';
import Moment from 'moment-timezone';
import GoogleMap from '../../utils/gmap'
import Formatters from '../../utils/formatters'
import Config from '../../config'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import Numeral from 'numeral'

class ShowLeadDetails extends Component<any, any> {

  render() {
    let client = _.find(this.props.user?.clients, c => c.id === this.props.lead.clientId)
    // console.log(this.props);
    return (
      <>
        <div className="block">
          <h2 className="mini">{t('order_details', 'Lead details')}</h2>
          <table className="table-attributes">
            <tbody>


              <tr>
                <td style={{ width: '50%' }}>
                  <span className="subsider-attribute-key">{t('external_id', "External ID")}</span>
                  <span className="subsider-attribute-value">{this.props.lead.conversionExternalId}</span>
                </td>
                <td>
                  <span className="subsider-attribute-key">{t('domain', "Domain")}</span>
                  <span className="subsider-attribute-value">{this.props.lead.domainId}</span>
                </td>
              </tr>
              <tr>
                <td style={{ width: '50%' }}>
                  <span className="subsider-attribute-key">{t('date', "Date")}</span>
                  <span className="subsider-attribute-value"><Tooltip title={Moment(this.props.lead.createdAt).tz(this.props.app.state.admin.timezone).format('lll') + ' (' + this.props.app.state.admin.timezone + ')'}>{Moment(this.props.lead.createdAt).fromNow()}</Tooltip></span>
                </td>
                <td>
                  <span className="subsider-attribute-key">{t('time_to_conversion', "Time to conversion")}</span>
                  <span className="subsider-attribute-value">{this.props.lead.timeToConversion > 0 ? Formatters.duration(this.props.lead.timeToConversion) : t('unknown', "unknown")}</span>
                </td>
              </tr>
              <tr>
                {client && <td>
                  <span className="subsider-attribute-key">{t('device', "Device")}</span>
                  <span className="subsider-attribute-value">
                    <Popover content={<div>
                      <div className="subsider-attribute">
                        <span className="subsider-attribute-key">OS</span>
                        <span className="subsider-attribute-value">{client.platform + ' - ' + client.os}</span>
                      </div>
                      <div className="subsider-attribute">
                        <span className="subsider-attribute-key">{t('resolution', "Resolution")}</span>
                        <span className="subsider-attribute-value">{client.resolution}</span>
                      </div>
                      <div className="subsider-attribute">
                        <span className="subsider-attribute-key">{t('ad_block', "Ad block")}</span>
                        <span className="subsider-attribute-value">{client.adBlock ? t('yes', "yes") : t('no', "no")}</span>
                      </div>
                      <div className="subsider-attribute">
                        <span className="subsider-attribute-key">{t('languages', "Languages")}</span>
                        <span className="subsider-attribute-value">{client.languages.replace(/~/g, ', ')}</span>
                      </div>
                      <div className="subsider-attribute">
                        <span className="subsider-attribute-key">{t('time_zone', "Time zone")}</span>
                        <span className="subsider-attribute-value">{client.timezone + ' (' + (client.timezoneOffset > 0 ? '-' + (client.timezoneOffset / 60) : '+' + (Math.abs(client.timezoneOffset) / 60)) + 'h)'}</span>
                      </div>
                    </div>}>{Formatters.deviceIcon(client.device)}&nbsp;&nbsp; {client.browser + ' ' + (client.browserVersionMajor ? client.browserVersionMajor : '') + (client.browserVersionMinor ? '.' + client.browserVersionMinor + '.' : '') + (client.browserVersionPatch ? '.' + client.browserVersionPatch : '')}</Popover>
                  </span>
                </td>}
                <td>
                  <span className="subsider-attribute-key">IP</span>
                  <span className="subsider-attribute-value">{this.props.lead.ip}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="subsider-attribute-key">Stage</span>
                  <span className="subsider-attribute-value">{this.props.lead.stageId}</span>
                </td>
                <td>
                  <span className="subsider-attribute-key">Status</span>
                  <span className="subsider-attribute-value">{this.props.lead.status}</span>
                </td>
              </tr>
              <tr>
                <td>
                  {/* <span className="subsider-attribute-key">{t('tip', "Tips")}</span>
                  <span className="subsider-attribute-value">{this.props.lead.totalTips ? Formatters.currency(this.props.projectLayout.project.currency, this.props.lead.totalTips, this.props.lead.totalTipsSource, this.props.lead.currency) : '-'}</span> */}
                </td>
                <td>
                  <span className="subsider-attribute-key">{t('tags', "Tags")}</span>
                  <span className="subsider-attribute-value">{this.props.lead.tags && this.props.lead.tags.length > 0 && this.props.lead.tags.map((t: any) => <Tag className="no-pointer" key={t}>{t}</Tag>)}</span>
                </td>
              </tr>
              <tr>
                <td>
                </td>
                <td>
                  <span className="subsider-attribute-key padding-b-s" style={{ float: 'inherit' }}>{t('properties', "Properties")}</span>
                  <span className="subsider-attribute-value"><pre>{this.props.lead.properties ? JSON.stringify(this.props.lead.properties, null, 2) : '-'}</pre></span>
                </td>
              </tr>
            </tbody>
          </table>
          {_.get(this.props.order, 'location.coordinates[0]') &&
            <GoogleMap
              googleMapURL={"https://maps.googleapis.com/maps/api/js?key=" + Config().gmap_key}
              loadingElement={<div className="loadingElement" style={{ height: '150px' }} />}
              containerElement={<div className="containerElement" style={{ height: '150px' }} />}
              mapElement={<div className="mapElement" style={{ height: '100%' }} />}
              latitude={this.props.lead.location.coordinates[1]}
              longitude={this.props.lead.location.coordinates[0]}
            />
          }
        </div>
      </>
    );
  }
}

export default ShowLeadDetails