
const industries = [
  {id: "arts-entertainment", name: "Arts & Entertainment"},
  {id: "automotive", name: "Automotive"},
  {id: "beauty-fitness", name: "Beauty & Fitness"},
  {id: "books-literature", name: "Books & Literature"},
  {id: "business-industrial-markets", name: "Business & Industrial markets"},
  {id: "computer-electronics", name: "Computer & Electronics"},
  {id: "finance", name: "Finance"},
  {id: "food-drink", name: "Food & Drink"},
  {id: "games", name: "Games"},
  {id: "healthcare", name: "Healtcare"},
  {id: "hobbies-leisure", name: "Hobbies & Leisure"},
  {id: "home-garden", name: "Home & Garden"},
  {id: "internet-telecom", name: "Internet & Telecom"},
  {id: "jobs-education", name: "Jobs & Education"},
  {id: "law-government", name: "Law & Government"},
  {id: "news", name: "News"},
  {id: "online-communities", name: "Online communities"},
  {id: "people-society", name: "People & Society"},
  {id: "pets-animals", name: "Pets & Animals"},
  {id: "real-estate", name: "Real estate"},
  {id: "science", name: "Science"},
  {id: "shopping", name: "Shopping"},
  {id: "sports", name: "Sports"},
  {id: "travel", name: "Travel"},
  {id: "other", name: "Other"},
];

export default industries