import React, { useState } from 'react'
import {
  Button,
  Dropdown,
  Menu,
  message,
  Modal,
} from 'antd'
import t from '../../utils/translate'
import { CopyOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import WidgetForm from './_widgetForm'
import EmailForm from './_emailForm'
import SmsForm from './_smsForm'

const TemplateButton = ({ visible, ajaxRequest, project, template, notification, language, channel, channelTitle, onComplete, btnSize, macros }) => {

  const [loading, setLoading] = useState(false)
  const [isVisible, setIsVisible] = useState(visible || false)
  const [fromTemplate, setFromTemplate] = useState(undefined)

  // const toggleModal = () => {
  //   setIsVisible(!isVisible)
  //   setFromTemplate(undefined)
  // }

  const cloneFrom = (fromTemplate: any) => {
    // console.log('from', from);
    setFromTemplate(fromTemplate)
    setIsVisible(true)
  }

  const deleteTemplate = () => {
    Modal.confirm({
      title: t('delete_template', "Delete template"),
      icon: <ExclamationCircleOutlined />,
      content: t('confirm_delete_template', "Do you really want to delete this template?"),
      okText: t('confirm', "Confirm"),
      cancelText: t('cancel', "Cancel"),
      okType: 'danger',
      onOk: () => {

        return new Promise((resolve, reject) => {

          ajaxRequest({
            method: 'post',
            url: '/notificationTemplates.delete',
            data: {
              projectId: project.id,
              templateId: template.id
            }
          }, (error: any, data: any) => {

            if (error) {
              message.error(error)
              return reject()
            }

            message.success('Your template has been successfully deleted!')

            onComplete()
            return resolve(undefined)
          })

        }).catch((e: any) => {
          message.error(e)
          console.error(e)
        });
      },
      onCancel() { },
    })
  }

  const submitForm = (values: any, onSuccess: any) => {

    if (loading === true) {
      return
    }

    // parse json

    try {
      const parsed = JSON.parse(values.testData)
      values.testData = parsed
    }
    catch (e: any) {
      values.testData = {}
    }

    values.projectId = project.id

    if (template) {
      values.templateId = template.id
    }

    values.notificationId = notification.id
    values.language = language
    values.channel = channel

    if (values.channel === 'sms') {
      values.editor = 'code'
    }

    if (values.cc) {
      if (values.cc.length) {
        values.cc = values.cc.join(',')
      } else {
        values.cc = ''
      }
    }
    if (values.bcc) {
      if (values.bcc.length) {
        values.bcc = values.bcc.join(',')
      } else {
        values.bcc = ''
      }
    }

    // console.log('values', values);

    setLoading(true)

    ajaxRequest({
      method: 'post',
      url: '/notificationTemplates.upsert',
      data: values
    }, (error: any, data: any) => {

      setLoading(false)

      if (error) {
        // if (error === 'key.exists') {
        //   error = 'This notification key already exists!'
        // }
        message.error(error)
        return
      }

      onSuccess()

      if (template) {
        message.success('Your template has been successfully updated!')
      } else {
        message.success('Your template has been successfully created!')
      }

      onComplete()
    })
  }


  const clonables = notification.templates.filter((x: any) => x.channel === channel && x.language !== language)
  // console.log('clonables', clonables);

  const hasDropdown = template || clonables.length > 0

  const mainBtn = <Button type={template ? 'default' : 'primary'} size={btnSize || 'default'} onClick={() => setIsVisible(true)}>{template ? t('edit', "Edit") : t('create_template', "Create template")}</Button>

  let btn = mainBtn

  if (hasDropdown) {
    btn = <Dropdown.Button buttonsRender={([_leftButton, rightButton]) => [mainBtn, rightButton]} type={template ? undefined : 'primary'} size={btnSize || 'default'} onClick={() => { setFromTemplate(undefined); setIsVisible(true) }} overlay={<Menu>
      {template && <Menu.Item key="delete" onClick={deleteTemplate} icon={<DeleteOutlined />}>{t('delete', "Delete")}</Menu.Item>}
      {clonables.map((x: any) => <Menu.Item key={'clone-' + x.language} onClick={cloneFrom.bind(null, x)} icon={<CopyOutlined />}>{t('clone_from_template', "Clone from template")} {x.language.toUpperCase()}</Menu.Item>)}
    </Menu>}></Dropdown.Button>
  }

  if (isVisible) {
    return <React.Fragment>
      {btn}
      {/* {this.props.channel === 'widget' && <WidgetForm
          loading={loading}
          submitForm={this.submitForm}
          visible={isVisible}
          fromTemplate={this.state.fromTemplate}
          setVisible={(value: any) => this.setState({visible: value, fromTemplate: null})}
          macros={macros}
          notification={notification}
          template={template}
          language={language}
          channelTitle={channelTitle}
          organizationId={project.organizationId}
        />} */}
      {channel === 'email' && <EmailForm
        loading={loading}
        submitForm={submitForm}
        visible={isVisible}
        fromTemplate={fromTemplate}
        setVisible={(value: any) => {
          setFromTemplate(undefined)
          setIsVisible(value)
        }}
        macros={macros}
        notification={notification}
        template={template}
        language={language}
        channelTitle={channelTitle}
        project={project}
      />}
      {channel === 'sms' && <SmsForm
        loading={loading}
        submitForm={submitForm}
        visible={isVisible}
        fromTemplate={fromTemplate}
        setVisible={(value: any) => {
          setFromTemplate(undefined)
          setIsVisible(value)
        }}
        macros={macros}
        notification={notification}
        template={template}
        language={language}
        channelTitle={channelTitle}
      />}
    </React.Fragment>
  }

  return btn
}

export default TemplateButton
