import React, { useState, Component } from 'react'

import t from '../../utils/translate'
import { Tag, Button, Input, Radio, Select, Col, Form, Table, Modal } from 'antd';
import _ from 'lodash'
import { CloseOutlined } from '@ant-design/icons';

const ProjectDomainForm = (props: any) => {

  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const onFinish = (values: any) => {

    if (loading === true) {
      return
    }
    else {
      setLoading(true)
    }

    values.projectId = props.projectLayout.project.id

    props.app.ajaxRequest({
      method: 'post',
      url: props.edit ? '/domains.update' : '/domains.create',
      data: values
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        props.app.addMessage('error', errorMessage)
        setLoading(false)
        return
      }

      let projects = _.filter(props.organizationLayout.projects, (p: any) => p.id !== response.data.project.id)
      projects.push(response.data.project)
      props.organizationLayout.setProjects(projects)

      if (props.edit) {
        props.app.addMessage('success', t('project_domain_updated', "Your domain has successfully been updated!"))
      } else {
        props.app.addMessage('success', t('project_domain_created', "Your domain has successfully been created!"))
        form.resetFields()
      }

      if (window.cmAgent) {
        window.cmAgent.event({
          label: props.edit ? 'updateDomain' : 'createDomain',
          props: {
            orgId: props.organizationLayout.organization.id,
            projectId: props.projectLayout.project.id,
            domainKind: values.kind
          }
        })
        window.cmAgent.dispatch()
      }

      if (props.onComplete) {
        props.onComplete()
      }
    })
  }

  let initialValues = {
    kind: 'web',
    homepagesPaths: ['/'],
    hosts: [],
    // hosts: [{
    //   host: '',
    //   prefix: '',
    // }]
  }

  if (props.domain) {
    initialValues = { ...props.domain }
  }

  return <Form form={form} initialValues={initialValues} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} layout="horizontal" className="margin-a-m margin-b-xl" onFinish={onFinish}>
    <Form.Item name="kind" label={t('kind', "Kind")} rules={[{ required: true, message: t('field_required', "This field is required!") }]}>
      <Radio.Group disabled={props.edit}>
        <Radio.Button value="web">Web</Radio.Button>
        <Radio.Button value="marketplace">Marketplace</Radio.Button>
        <Radio.Button value="app">App</Radio.Button>
        <Radio.Button value="retail">Retail</Radio.Button>
        <Radio.Button value="telephone">Telephone</Radio.Button>
      </Radio.Group>
    </Form.Item>

    <Form.Item name="name" label={t('name', "Name")} rules={[{ required: true, message: t('field_required', "This field is required!") }]}>
      <Input placeholder="i.e: ios, android, paris, amazon, lazada..."
        onChange={(e: any) => {
          if (props.edit !== true) {
            form.setFieldsValue({ id: _.kebabCase(e.target.value) })
          }
        }} />
    </Form.Item>

    <Form.Item name="id" label="ID" rules={[{ required: true, type: 'string', pattern: /^[a-z0-9]+(-[a-z0-9]+)*$/, message: t('value_should_be_written_like_this', "The value should-be-written-like-this") }]}>
      <Input placeholder="i.e: web" />
    </Form.Item>


    <Form.Item noStyle shouldUpdate>
      {(funcs) => {
        const kind = funcs.getFieldValue('kind')

        return <>
          {kind === 'web' && <>

            <Form.Item
              name="hosts"
              label="Hosts"
              rules={[{ required: true, type: 'array', min: 1 }]}
              shouldUpdate
            >
              <HostsInput />
            </Form.Item>

            {/* <Form.Item name="host" label={t('domain_name', "Domain name")} rules={[{ required: true, pattern: /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])+(:[0-9]{1,5})?$/, message: t('hostname_invalid', "The value should be a valid host name!") }]}>
              <Input placeholder="i.e: my-website.com / blog.my-website.com / localhost:8080" />
            </Form.Item> */}

            <Form.Item
              name="homepagesPaths"
              label={t('homepages_urls', "Homepages URL paths")}
              extra={<div>{t('homepages_paths_info', "These paths are used to detect visits coming from SEO brand keywords.")} {t('press_enter_to_insert_a_new_value', "Press enter to insert a new value.")}</div>}
              rules={[{
                required: true,
                validator: (_rule, value) => {
                  return new Promise((resolve, reject) => {
                    if (value && value.length) {
                      value.forEach((param: any) => {
                        if (!/^\/([A-Za-z0-9-_~/]*)$/.test(param)) {
                          return reject(new Error(t('pattern_value_invalid', "The value should match the following expression: {exp}", { exp: '/([A-Za-z0-9-_~/]*)' })))
                        }
                      })
                    } else {
                      return reject(new Error(t('field_required', "This field is required!")))
                    }
                    return resolve(undefined)
                  })
                },
                message: t('pattern_value_invalid', "The value should match the following expression: {exp}", { exp: '/([A-Za-z0-9-_~/]*)' })
              }]}
            >
              <Select mode="tags" placeholder="" dropdownStyle={{ display: 'none' }} />
            </Form.Item>

            <Form.Item
              name="paramsWhitelist"
              label={t('url_params_whitelist', "URL parameters to preserve")}
              extra={t('press_enter_to_insert_a_new_value', "Press enter to insert a new value.")}
              rules={[{
                required: false,
                validator: (_rule, value) => {
                  return new Promise((resolve, reject) => {
                    if (value && value.length) {
                      value.forEach((param: any) => {
                        if (!/^([A-Za-z0-9-_~]*)$/.test(param)) {
                          return reject(new Error(t('pattern_value_invalid', "The value should match the following expression: {exp}", { exp: '([A-Za-z0-9-_~]*)' })))
                        }
                      })
                    }
                    return resolve(undefined)
                  })
                },
                message: t('pattern_value_invalid', "The value should match the following expression: {exp}", { exp: '([A-Za-z0-9-_~]*)' })
              }]}
            >
              <Select mode="tags" placeholder="" dropdownStyle={{ display: 'none' }}>
                {_.get(props, 'domain.paramsWhitelist', []).map((param: any) => <Select.Option key={param} value={param}>{param}</Select.Option>)}
              </Select>
            </Form.Item>
          </>}
        </>
      }}
    </Form.Item>


    {props.isDrawer === true ?
      <div className="ant-drawer-footer">
        <Button style={{ marginRight: 8 }} onClick={props.closeDrawer}>{t('cancel', "Cancel")}</Button>
        <Button type="primary" loading={loading} htmlType="submit">{t('confirm', "Confirm")}</Button>
      </div>
      :
      <Form.Item noStyle>
        <Col xs={22} sm={22} className="text-right">
          <Button type="primary" loading={loading} htmlType="submit">{t('confirm', "Confirm")}</Button>
        </Col>
      </Form.Item>
    }
  </Form>
}

export default ProjectDomainForm



class HostsInput extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.removeHost = this.removeHost.bind(this)
  }

  removeHost(index: number) {
    let hosts = this.props.value.slice()
    hosts.splice(index, 1)
    this.props.onChange(hosts)
  }

  render() {
    return <div>
      {(this.props.value && this.props.value.length > 0) && <Table
        size="middle"
        bordered={false}
        pagination={false}
        rowKey="host"
        showHeader={false}
        className="margin-b-m"
        columns={[
          {
            title: '',
            key: 'host',
            render: x => <><Tag className="small">{x.host}</Tag> {x.pathPrefix && x.pathPrefix !== '' && <small>rewrite URLs prefix with <Tag className="small">{x.pathPrefix}</Tag></small>}</>
          },
          {
            title: '',
            key: 'remove',
            render: (_text, _record:any , index: number) => {
              return (
                <div className="text-right">
                  <Button type="dashed" size="small" onClick={this.removeHost.bind(null, index)}>
                    <CloseOutlined />
                  </Button>
                </div>
              );
            }
          }]}
        dataSource={this.props.value} />
      }

      <AddHostButton channels={this.props.channels} onComplete={(newHost: any) => {
        let hosts = this.props.value ? this.props.value.slice() : []
        hosts.push(newHost)
        this.props.onChange(hosts)
      }} />
    </div>
  }
}


const AddHostButton = ({ onComplete }: any) => {
  const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)

  const onClicked = () => {
    setModalVisible(true)
  }

  return <React.Fragment>
    <Button type="primary" size="small" block ghost onClick={onClicked}>{t('add', "Add")}</Button>

    <Modal
      visible={modalVisible}
      title="Add host"
      okText={t('confirm', "Confirm")}
      cancelText={t('cancel', "Cancel")}
      onCancel={() => { setModalVisible(false) }}
      onOk={() => {
        form.validateFields().then((values: any) => {
          // console.log('onComplete', values);
          form.resetFields()
          setModalVisible(false)
          onComplete(values)
        })
          .catch(info => { console.log('Validate Failed:', info); });
      }}
    >
      <Form form={form} name="form_add_host" labelCol={{ span: 10 }} wrapperCol={{ span: 14 }} layout="horizontal">
        <Form.Item name="host" label="Hostname" rules={[{ required: true, type: 'string', pattern: /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])+(:[0-9]{1,5})?$/, message: t('hostname_invalid', "The value should be a valid host name!") }]}>
          <Input placeholder="blog.website.com" />
        </Form.Item>

        <Form.Item name="pathPrefix" label="Rewrite URLs with prefix" rules={[{ required: false, type: 'string', pattern: /^[a-z0-9]+(-[a-z0-9]+)*$/, message: t('value_should_be_written_like_this', "The value should-be-written-like-this") }]}>
          <Input placeholder="i.e: blog" />
        </Form.Item>
      </Form>
    </Modal>
  </React.Fragment >
}
