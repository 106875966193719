import React, { Component } from 'react'

import { Tag, Badge, Spin, Tooltip } from 'antd';
import t from '../../utils/translate'
import cn from 'classnames'
import N from 'numeral'
import _ from 'lodash'

export function NestSegments (segments: any, currentParentId?: string) {
  const result: any = []

  segments.forEach((s: any) => {
    
    // exclude children who dont belong to this parent
    if (currentParentId && s.parentId !== currentParentId) {
      return
    }

    // exclude non root when not a child
    if (!currentParentId && s.parentId) {
      return
    }

    s.children = NestSegments(segments, s.id)
    result.push(s)
  })

  // sort by count desc
  result.sort((a: any, b: any) => {
    if (a.count < b.count) return 1
    if (a.count > b.count) return -1
    return 0
  })

  return result
}

class SegmentsMenu extends Component<any, any> {

  render() {

    if (this.props.loading === true || !this.props.currentSegment) {
      return <div className="margin-h-m">
        <Spin />
      </div>
    }

    const segmentsMap = _.keyBy(this.props.segments.filter((s: any) => !s.forCompanies), (s) => s.id)
    segmentsMap['_all'].count = segmentsMap['anon'].count+segmentsMap['auth'].count
    const segments = this.props.segments.filter((x: any) => !x.deletedAt && x.id !== '_all')

    const renderSegmentsMenu = (nestedSegments: any, indent: number) => {
      const indentPx = indent > 0 ? (indent*16) : 0
      return nestedSegments.map((s: any) => <React.Fragment key={s.id}>
        <li onClick={() => this.props.changeSegment(s.id)} className={cn({active: this.props.currentSegment.id === s.id})}>
          {(s.status === "refreshing") && <span className="pull-right"><Badge status="processing" /></span>}
          {(s.status === "queued") && <span className="pull-right"><Badge status="processing" /></span>}
          {indent > 0 && <div style={{width: indentPx+'px', display: 'inline-block', textAlign: 'right', paddingRight: '4px', opacity: '0.6'}}>↳</div>}
          <Tag className="no-pointer" color={s.color !== 'default' && s.color}>{s.name}</Tag> <Tooltip title={N((s.count*100)/segmentsMap['_all'].count).format('0.[0]')+'%'}><span className="size-10 semibold">{N(s.count).format('0,0')}</span></Tooltip>
        </li>
        {s.children.length > 0 && renderSegmentsMenu(s.children, indent+1)}
      </React.Fragment>)
    }

    return <ul className="second-menu-list">
      <li onClick={() => this.props.changeSegment('')} className={cn({active: this.props.currentSegment.id === 'all'})}>{t('all_users', "All users")} <span className="size-10 semibold padding-l-s">{N(segmentsMap['_all'].count).format('0,0')}</span></li>
      {renderSegmentsMenu(NestSegments(segments), 0)}
    </ul>
  }
}

export default SegmentsMenu