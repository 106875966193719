import { useRef, useState, useEffect } from 'react'

import t from 'utils/translate'
import { ReloadOutlined } from '@ant-design/icons';
import { Row, Col, Button, Tooltip } from 'antd'
import DateRange from 'components/projects/_dateRange'
import Moment from 'moment-timezone'
import KPI from './kpi'
import OrdersPerDomain from './ordersPerDomain'
import LeadsStages from './leadsStages'
import UsersPerCountry from './usersPerCountry'
import UsersPerDevice from './usersPerDevice'
import TrafficSources from './trafficSources'
import OnlineNow from './onlineNow'
import AppLayout from 'components/app/layout'
import { useProjectContext, ProjectContextValue } from 'components/projects'
import { useHistory, useLocation } from 'react-router'
import { ConversionRule } from 'components/app/interfaces';

const ProjectDashboard = () => {

  // const [loading, setLoading] = useState(true)
  const projectCtx: ProjectContextValue = useProjectContext()
  const history = useHistory() as any
  const location = useLocation() as any
  const prevProjectId = useRef(undefined)

  const [dateRangeVs, setDateRangeVs] = useState('previous_period')
  const [dateRangeLabel, setDateRangeLabel] = useState('last_30_days')
  const [dateRangeValue, setDateRangeValue] = useState([Moment().tz(projectCtx.admin.timezone).subtract(30, 'day').startOf('day'), Moment().tz(projectCtx.admin.timezone).subtract(1, 'day').endOf('day')])
  const [refreshAt, setRefreshAt] = useState(Moment().unix())


  useEffect(() => {

    if (prevProjectId.current === projectCtx.currentOrganization.id) return
    prevProjectId.current = projectCtx.currentOrganization.id

    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Dashboard (project: ' + projectCtx.currentProject.id + ')',
        page: location.pathname,
        props: {
          orgId: projectCtx.currentOrganization.id,
          projectId: projectCtx.currentProject.id
        }
      })
      window.cmAgent.dispatch()
    }
  }, [history, location.pathname, projectCtx.currentOrganization.id, projectCtx.currentProject.id, projectCtx.projects])


  const refresh = () => {
    setRefreshAt(Moment().unix())
  }

  const setDateRange = (label: string, range: any) => {
    setDateRangeValue(range)
    setDateRangeLabel(label)
    refresh()
  }

  const setDateRangeVsAndRefresh = (value) => {
    setDateRangeVs(value)
    refresh()
  }

  // console.log('state', this.state);

  const dateFrom = Moment(dateRangeValue[0])
  const dateTo = Moment(dateRangeValue[1])

  let dateFromPrevious: any = Moment(dateFrom)
  let dateToPrevious: any = Moment(dateTo)

  if (dateRangeVs === "previous_period") {

    // add one day to the diff to avoid days overlaping between the to ranges
    const diff = dateTo.diff(dateFrom, 'days') + 1
    // console.log('diff is', diff);

    dateToPrevious = dateToPrevious.subtract(diff, 'days')
    dateFromPrevious = dateFromPrevious.subtract(diff, 'days')
  }

  if (dateRangeVs === "previous_year") {
    dateToPrevious = dateToPrevious.subtract(1, 'years')
    dateFromPrevious = dateFromPrevious.subtract(1, 'years')
  }

  dateFromPrevious = dateFromPrevious.format('YYYY-MM-DD')
  dateToPrevious = dateToPrevious.format('YYYY-MM-DD')

  const webDomains = projectCtx.currentProject.domains.filter((d: any) => !d.deletedAt && d.kind === 'web')
  const leadConversionRules = projectCtx.currentProject.conversionRules.filter(x => x.kind === 'lead' && !x.deletedAt)

  // console.log('dateFrom', dateFrom.format('YYYY-MM-DD'));
  // console.log('dateTo', dateTo.format('YYYY-MM-DD'));
  // console.log('dateFromPrevious', dateFromPrevious);
  // console.log('dateToPrevious', dateToPrevious);

  return <AppLayout currentProject={projectCtx.currentProject} currentOrganization={projectCtx.currentOrganization} admin={projectCtx.admin} firebaseUser={projectCtx.firebaseUser} projects={projectCtx.projects}>
    <Row gutter={4} className="dash-live">
      <Col span={9}><OnlineNow projectId={projectCtx.currentProject.id} locationSearch={location.search} ajaxRequest={projectCtx.ajaxRequest} refreshAt={refreshAt} /></Col>
      <Col span={15}>
        <span className="pull-right">
          <DateRange
            timezone={projectCtx.admin.timezone}
            refreshAt={refreshAt}
            vs={dateRangeVs}
            dateRange={dateRangeValue}
            labelKey={dateRangeLabel}
            onVsChange={setDateRangeVsAndRefresh}
            onChange={setDateRange} />
          <Tooltip placement="left" title={t('last_refresh_at', "Refreshed {at}", { at: Moment.unix(refreshAt).fromNow() })}>
            <Button className="margin-l-s" size="small" type="default" shape="circle" icon={<ReloadOutlined />} onClick={refresh} />
          </Tooltip>
        </span>
      </Col>
    </Row>

    <Row gutter={24} className="margin-t-m">
      <Col span={12}>
        <div className="block">
          <h2 className="mini">{t('users', 'Users')}</h2>
          <div className="dash-kpi-container">
            <KPI
              title={t('users', "Users")}
              measure="ActiveUsers.count"
              timeDimension="ActiveUsers.truncCreatedAt"
              color="purple"
              projectId={projectCtx.currentProject.id}
              cubejsApi={projectCtx.cubejsApi}
              timezone={projectCtx.admin.timezone}
              refreshAt={refreshAt}
              dateFrom={dateFrom.format('YYYY-MM-DD')}
              dateTo={dateTo.format('YYYY-MM-DD')}
              dateFromPrevious={dateFromPrevious}
              dateToPrevious={dateToPrevious}
            />
            <KPI
              title={t('new_signups', "New signups")}
              measure="TimelineSignups.count"
              timeDimension="TimelineSignups.truncCreatedAt"
              color="purple"
              projectId={projectCtx.currentProject.id}
              cubejsApi={projectCtx.cubejsApi}
              timezone={projectCtx.admin.timezone}
              refreshAt={refreshAt}
              dateFrom={dateFrom.format('YYYY-MM-DD')}
              dateTo={dateTo.format('YYYY-MM-DD')}
              dateFromPrevious={dateFromPrevious}
              dateToPrevious={dateToPrevious}
            />
            <KPI
              title={t('authenticated', "Authenticated")}
              measure="ActiveUsers.count"
              timeDimension="ActiveUsers.truncCreatedAt"
              segments={['Users.authenticated']}
              color="purple"
              projectId={projectCtx.currentProject.id}
              cubejsApi={projectCtx.cubejsApi}
              timezone={projectCtx.admin.timezone}
              refreshAt={refreshAt}
              dateFrom={dateFrom.format('YYYY-MM-DD')}
              dateTo={dateTo.format('YYYY-MM-DD')}
              dateFromPrevious={dateFromPrevious}
              dateToPrevious={dateToPrevious}
              isLast={true}
            />
          </div>
        </div>

        <div className="block margin-t-l">
          <h2 className="mini">{t('web_traffic', 'Web traffic')}</h2>
          <div className="dash-kpi-container">
            <KPI
              title={t('sessions', "Sessions")}
              measure="TimelineSessions.count"
              filters={[{
                'member': 'TimelineSessions.domainId',
                'operator': 'in',
                'values': webDomains.map((x: any) => x.id)
              }]}
              timeDimension="TimelineSessions.truncCreatedAt"
              color="purple"
              projectId={projectCtx.currentProject.id}
              cubejsApi={projectCtx.cubejsApi}
              timezone={projectCtx.admin.timezone}
              refreshAt={refreshAt}
              dateFrom={dateFrom.format('YYYY-MM-DD')}
              dateTo={dateTo.format('YYYY-MM-DD')}
              dateFromPrevious={dateFromPrevious}
              dateToPrevious={dateToPrevious}
            />
            <KPI
              title={t('bounce_rate', "Bounce rate")}
              measure="TimelineSessions.bounceRate"
              filters={[{
                'member': 'TimelineSessions.domainId',
                'operator': 'in',
                'values': webDomains.map((x: any) => x.id)
              }]}
              kind="percentage"
              reverseGrowthRate={true}
              timeDimension="TimelineSessions.truncCreatedAt"
              color="purple"
              projectId={projectCtx.currentProject.id}
              cubejsApi={projectCtx.cubejsApi}
              timezone={projectCtx.admin.timezone}
              refreshAt={refreshAt}
              dateFrom={dateFrom.format('YYYY-MM-DD')}
              dateTo={dateTo.format('YYYY-MM-DD')}
              dateFromPrevious={dateFromPrevious}
              dateToPrevious={dateToPrevious}
            />
            <KPI
              title={t('session_duration', "Session duration")}
              measure="TimelineSessions.averageSessionDuration"
              filters={[{
                'member': 'TimelineSessions.domainId',
                'operator': 'in',
                'values': webDomains.map((x: any) => x.id)
              }]}
              kind="duration"
              timeDimension="TimelineSessions.truncCreatedAt"
              color="purple"
              projectId={projectCtx.currentProject.id}
              cubejsApi={projectCtx.cubejsApi}
              timezone={projectCtx.admin.timezone}
              refreshAt={refreshAt}
              dateFrom={dateFrom.format('YYYY-MM-DD')}
              dateTo={dateTo.format('YYYY-MM-DD')}
              dateFromPrevious={dateFromPrevious}
              dateToPrevious={dateToPrevious}
              isLast={true}
            />
          </div>
        </div>

        <div className="block margin-t-l">
          <h2 className="mini">{t('users_per_country', 'Users per country')}</h2>
          <UsersPerCountry
            project={projectCtx.currentProject}
            cubejsApi={projectCtx.cubejsApi}
            timezone={projectCtx.admin.timezone}
            refreshAt={refreshAt}
            dateFrom={dateFrom.format('YYYY-MM-DD')}
            dateTo={dateTo.format('YYYY-MM-DD')}
            dateFromPrevious={dateFromPrevious}
            dateToPrevious={dateToPrevious}
          />
        </div>

        <div className="block margin-t-l">
          <h2 className="mini">{t('users_per_device', 'Users per device')}</h2>
          <UsersPerDevice
            project={projectCtx.currentProject}
            cubejsApi={projectCtx.cubejsApi}
            timezone={projectCtx.admin.timezone}
            refreshAt={refreshAt}
            dateFrom={dateFrom.format('YYYY-MM-DD')}
            dateTo={dateTo.format('YYYY-MM-DD')}
            dateFromPrevious={dateFromPrevious}
            dateToPrevious={dateToPrevious}
          />
        </div>

      </Col>

      <Col span={12}>

        {projectCtx.currentProject.conversionRules.find(x => x.kind === 'lead') && <>
          <div className="block">
            <h2 className="mini">Leads status</h2>
            <div className="dash-kpi-container">
              <KPI
                title="Open"
                measure="TimelineLeads.count"
                timeDimension="TimelineLeads.truncCreatedAt"
                color="cyan"
                projectId={projectCtx.currentProject.id}
                cubejsApi={projectCtx.cubejsApi}
                timezone={projectCtx.admin.timezone}
                refreshAt={refreshAt}
                dateFrom={dateFrom.format('YYYY-MM-DD')}
                dateTo={dateTo.format('YYYY-MM-DD')}
                dateFromPrevious={dateFromPrevious}
                dateToPrevious={dateToPrevious}
                filters={[{
                  member: 'TimelineLeads.leadStatus',
                  operator: 'equals',
                  values: ['open']
                }]}
              />
              <KPI
                title="Converted"
                measure="TimelineLeads.count"
                timeDimension="TimelineLeads.truncCreatedAt"
                color="cyan"
                projectId={projectCtx.currentProject.id}
                cubejsApi={projectCtx.cubejsApi}
                timezone={projectCtx.admin.timezone}
                refreshAt={refreshAt}
                dateFrom={dateFrom.format('YYYY-MM-DD')}
                dateTo={dateTo.format('YYYY-MM-DD')}
                dateFromPrevious={dateFromPrevious}
                dateToPrevious={dateToPrevious}
                filters={[{
                  member: 'TimelineLeads.leadStatus',
                  operator: 'equals',
                  values: ['converted']
                }]}
              />
              <KPI
                title="Lost"
                measure="TimelineLeads.count"
                timeDimension="TimelineLeads.truncCreatedAt"
                color="cyan"
                projectId={projectCtx.currentProject.id}
                cubejsApi={projectCtx.cubejsApi}
                timezone={projectCtx.admin.timezone}
                refreshAt={refreshAt}
                dateFrom={dateFrom.format('YYYY-MM-DD')}
                dateTo={dateTo.format('YYYY-MM-DD')}
                dateFromPrevious={dateFromPrevious}
                dateToPrevious={dateToPrevious}
                filters={[{
                  member: 'TimelineLeads.leadStatus',
                  operator: 'equals',
                  values: ['lost']
                }]}
              />
            </div>
          </div>

          {/* <div className="block margin-t-l">
            <h2 className="mini">Leads performance</h2>
            <div className="dash-kpi-container">
              <KPI
                title="Conversion rate"
                measure="TimelineLeads.conversionRate"
                kind="percentage"
                timeDimension="TimelineLeads.truncCreatedAt"
                color="cyan"
                projectId={projectCtx.currentProject.id}
                cubejsApi={projectCtx.cubejsApi}
                timezone={projectCtx.admin.timezone}
                refreshAt={refreshAt}
                dateFrom={dateFrom.format('YYYY-MM-DD')}
                dateTo={dateTo.format('YYYY-MM-DD')}
                dateFromPrevious={dateFromPrevious}
                dateToPrevious={dateToPrevious}
              />
              <KPI
                title={t('avg_ttc', "Avg. TTC")}
                tooltip={t('average_time_to_conversion', "Average time to conversion")}
                measure="TimelineLeads.averageTimeToConversion"
                timeDimension="TimelineLeads.truncCreatedAt"
                color="cyan"
                kind="duration"
                projectId={projectCtx.currentProject.id}
                cubejsApi={projectCtx.cubejsApi}
                timezone={projectCtx.admin.timezone}
                refreshAt={refreshAt}
                dateFrom={dateFrom.format('YYYY-MM-DD')}
                dateTo={dateTo.format('YYYY-MM-DD')}
                dateFromPrevious={dateFromPrevious}
                dateToPrevious={dateToPrevious}
              />
              <KPI
                title={t('abandoned_carts', "Abandoned carts")}
                measure="TimelineAbandonedCarts.count"
                timeDimension="TimelineAbandonedCarts.truncCreatedAt"
                color="cyan"
                projectId={projectCtx.currentProject.id}
                cubejsApi={projectCtx.cubejsApi}
                timezone={projectCtx.admin.timezone}
                refreshAt={refreshAt}
                dateFrom={dateFrom.format('YYYY-MM-DD')}
                dateTo={dateTo.format('YYYY-MM-DD')}
                dateFromPrevious={dateFromPrevious}
                dateToPrevious={dateToPrevious}
                isLast={true}
              />
            </div>
          </div> */}

          {leadConversionRules.map((cr: ConversionRule, i: Number) => <div key={cr.id}>
            <div className="block margin-v-l">
              <h2 className="mini">Leads stages {leadConversionRules.length > 1 && ' - '+cr.name}</h2>
              <LeadsStages
                project={projectCtx.currentProject}
                conversionRule={cr}
                cubejsApi={projectCtx.cubejsApi}
                timezone={projectCtx.admin.timezone}
                refreshAt={refreshAt}
                dateFrom={dateFrom.format('YYYY-MM-DD')}
                dateTo={dateTo.format('YYYY-MM-DD')}
                dateFromPrevious={dateFromPrevious}
                dateToPrevious={dateToPrevious}
              />
            </div>
          </div>)}
        </>}

        {projectCtx.currentProject.conversionRules.find(x => x.kind === 'order') && <>
          <div className="block">
            <h2 className="mini">{t('sales', 'Sales')}</h2>
            <div className="dash-kpi-container">
              <KPI
                title={t('orders', "Orders")}
                measure="TimelineOrders.count"
                timeDimension="TimelineOrders.truncCreatedAt"
                color="cyan"
                projectId={projectCtx.currentProject.id}
                cubejsApi={projectCtx.cubejsApi}
                timezone={projectCtx.admin.timezone}
                refreshAt={refreshAt}
                dateFrom={dateFrom.format('YYYY-MM-DD')}
                dateTo={dateTo.format('YYYY-MM-DD')}
                dateFromPrevious={dateFromPrevious}
                dateToPrevious={dateToPrevious}
              />
              <KPI
                title={t('revenue', "Revenue")}
                measure="TimelineOrders.revenue"
                timeDimension="TimelineOrders.truncCreatedAt"
                color="cyan"
                kind="currency"
                currency={projectCtx.currentProject.currency}
                projectId={projectCtx.currentProject.id}
                cubejsApi={projectCtx.cubejsApi}
                timezone={projectCtx.admin.timezone}
                refreshAt={refreshAt}
                dateFrom={dateFrom.format('YYYY-MM-DD')}
                dateTo={dateTo.format('YYYY-MM-DD')}
                dateFromPrevious={dateFromPrevious}
                dateToPrevious={dateToPrevious}
              />
              <KPI
                title={t('avg_cart', "Avg. cart")}
                measure="TimelineOrders.averageCart"
                timeDimension="TimelineOrders.truncCreatedAt"
                color="cyan"
                kind="currency"
                currency={projectCtx.currentProject.currency}
                projectId={projectCtx.currentProject.id}
                cubejsApi={projectCtx.cubejsApi}
                timezone={projectCtx.admin.timezone}
                refreshAt={refreshAt}
                dateFrom={dateFrom.format('YYYY-MM-DD')}
                dateTo={dateTo.format('YYYY-MM-DD')}
                dateFromPrevious={dateFromPrevious}
                dateToPrevious={dateToPrevious}
                isLast={true}
              />
            </div>
          </div>

          <div className="block margin-t-l">
            <h2 className="mini">{t('web_sales_performance', 'Web sales performance')}</h2>
            <div className="dash-kpi-container">
              <KPI
                title={t('online_conversion_rate', "Online conversion rate")}
                measure="TimelineSessions.conversionRate"
                kind="percentage"
                timeDimension="TimelineSessions.truncCreatedAt"
                filters={[{
                  member: 'TimelineSessions.domainId',
                  operator: 'contains',
                  values: projectCtx.currentProject.domains.filter((x: any) => x.kind === 'web' || x.kind === 'app').map((x: any) => x.id)
                }]}
                color="cyan"
                projectId={projectCtx.currentProject.id}
                cubejsApi={projectCtx.cubejsApi}
                timezone={projectCtx.admin.timezone}
                refreshAt={refreshAt}
                dateFrom={dateFrom.format('YYYY-MM-DD')}
                dateTo={dateTo.format('YYYY-MM-DD')}
                dateFromPrevious={dateFromPrevious}
                dateToPrevious={dateToPrevious}
              />
              <KPI
                title={t('avg_ttc', "Avg. TTC")}
                tooltip={t('average_time_to_conversion', "Average time to conversion")}
                measure="TimelineOrders.averageTimeToConversion"
                timeDimension="TimelineOrders.truncCreatedAt"
                color="cyan"
                kind="duration"
                projectId={projectCtx.currentProject.id}
                cubejsApi={projectCtx.cubejsApi}
                timezone={projectCtx.admin.timezone}
                refreshAt={refreshAt}
                dateFrom={dateFrom.format('YYYY-MM-DD')}
                dateTo={dateTo.format('YYYY-MM-DD')}
                dateFromPrevious={dateFromPrevious}
                dateToPrevious={dateToPrevious}
              />
              <KPI
                title={t('abandoned_carts', "Abandoned carts")}
                measure="TimelineAbandonedCarts.count"
                timeDimension="TimelineAbandonedCarts.truncCreatedAt"
                color="cyan"
                projectId={projectCtx.currentProject.id}
                cubejsApi={projectCtx.cubejsApi}
                timezone={projectCtx.admin.timezone}
                refreshAt={refreshAt}
                dateFrom={dateFrom.format('YYYY-MM-DD')}
                dateTo={dateTo.format('YYYY-MM-DD')}
                dateFromPrevious={dateFromPrevious}
                dateToPrevious={dateToPrevious}
                isLast={true}
              />
            </div>
          </div>

          <div className="block margin-v-l">
            <h2 className="mini">{t('sales_per_domain', 'Sales per domain')}</h2>
            <OrdersPerDomain
              project={projectCtx.currentProject}
              cubejsApi={projectCtx.cubejsApi}
              timezone={projectCtx.admin.timezone}
              refreshAt={refreshAt}
              dateFrom={dateFrom.format('YYYY-MM-DD')}
              dateTo={dateTo.format('YYYY-MM-DD')}
              dateFromPrevious={dateFromPrevious}
              dateToPrevious={dateToPrevious}
            />
          </div>
        </>}

        <div className="block">
          <h2 className="mini">{t('web_traffic_sources', 'Web traffic sources')}</h2>
          <TrafficSources
            project={projectCtx.currentProject}
            cubejsApi={projectCtx.cubejsApi}
            timezone={projectCtx.admin.timezone}
            refreshAt={refreshAt}
            currency={projectCtx.currentProject.currency}
            dateFrom={dateFrom.format('YYYY-MM-DD')}
            dateTo={dateTo.format('YYYY-MM-DD')}
            dateFromPrevious={dateFromPrevious}
            dateToPrevious={dateToPrevious}
          />
        </div>
      </Col>
    </Row>
  </AppLayout>
}

export default ProjectDashboard