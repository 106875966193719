import React, { Component } from 'react'

import {
  Tag,
  Cascader,
} from 'antd';
import _ from 'lodash'


class SegmentsCascader extends Component<any, any> {

  constructor(props: any) {
    super(props)

    this.setSegment = this.setSegment.bind(this)
  }

  setSegment(options: any) {
    this.props.onChange(options[options.length - 1])
  }

  render() {


    const nestSegments = (segments: any, currentParentId: any) => {
      const result: any = []

      segments.forEach((s: any) => {

        // exclude children who dont belong to this parent
        if (currentParentId && s.parentId !== currentParentId) {
          return
        }

        // exclude non root when not a child
        if (!currentParentId && s.parentId) {
          return
        }

        result.push({
          label: <Tag color={s.color !== 'default' && s.color} className="xsmall">{s.name}</Tag>,
          value: s.id,
          children: nestSegments(segments, s.id)
        })
      })

      return result
    }

    // const all =  {
    //   label: <Tag className="xsmall">{t('all_users', "All users")}</Tag>,
    //   value: '_all',
    //   children: []
    // }

    let segments = _.filter(this.props.segments, s => {
      if (s.deletedAt) return false
      if (this.props.excludeSegments && this.props.excludeSegments.length && this.props.excludeSegments.indexOf(s.id) !== -1) {
        return false
      }
      return true
    })

    const roots: any = []

    const nestedSegments = _.concat(roots, nestSegments(segments, undefined))


    const flattenNestedSegments = ((segs: any, items: any, parents: any) => {

      segs.forEach((s: any) => {

        items.push({ segment: s, parents: parents })

        if (s.children && s.children.length > 0) {
          const parentsCopy = parents.slice()
          parentsCopy.push(s)
          flattenNestedSegments(s.children, items, parentsCopy)
        }
      })
    })

    const segmentsWithParents: any = []
    flattenNestedSegments(nestedSegments, segmentsWithParents, [])


    const cascaderValue: any = []

    segmentsWithParents.forEach((item: any) => {
      if (item.segment.value === this.props.value) {
        if (item.parents.length > 0) {
          item.parents.forEach((seg: any) => {
            cascaderValue.push(seg.value)
          })
        }
        cascaderValue.push(item.segment.value)
      }
    })

    const cascaderProps = this.props.cascaderProps || {}

    return <Cascader
      {...cascaderProps}
      value={cascaderValue}
      options={nestedSegments}
      onChange={this.setSegment}
      expandTrigger="hover"
      changeOnSelect
      displayRender={(label: any, options: any) => options.map((opt: any, i: number) => {
        if (i === 0) {
          return <span key={i}>{opt.label}</span>
        } else {
          return <span key={i}> &gt; {opt.label}</span>
        }
      })}
    />
  }
}

export default SegmentsCascader


