import React, { Component } from 'react'

import t from '../../utils/translate'
import { CloseOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Cascader, Input, InputNumber, Select, Tag, Popconfirm, DatePicker } from 'antd';
import { map, get, forEach, cloneDeep, trim, set } from 'lodash'
import Moment from 'moment-timezone'
import Languages from '../../utils/languages'
import Countries from '../../utils/countries'
import Formatters from '../../utils/formatters'
import Numeral from 'numeral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class SegmentConditions extends Component<any, any> {

  static getDerivedStateFromProps(nextProps: any) {
    // Should be a controlled component.
    if ('value' in nextProps) {
      return {
        ...(nextProps.value || {}),
      }
    }
    return null
  }

  userDictionnary: any
  types: any
  operators: any

  constructor(props: any) {
    super(props)

    // console.log('properties', props);
    this.state = {
      editCondition: undefined,
      userProperties: {},
      attributes: this.props.value.kind ? cloneDeep(this.props.value) : {
        "kind": "operator",
        "operator": "and",
        "conditions": [
          // {
          //   "kind": "operator",
          //   "operator": "or",
          //   "conditions": [
          //     {
          //       "kind": "property",
          //       "field": "language",
          //       "fieldType": "string",
          //       "operator": "equals",
          //       "stringValue": "en"
          //     },
          //     {
          //       "kind": "property",
          //       "field": "email",
          //       "fieldType": "string",
          //       "operator": "contains",
          //       "stringValue": "pierre"
          //     },
          //   ]
          // },
          // {
          //   "kind": "operator",
          //   "operator": "and",
          //   "conditions": [
          //     {
          //       "kind": "property",
          //       "field": "language",
          //       "fieldType": "string",
          //       "operator": "equals",
          //       "stringValue": "en"
          //     },
          //     {
          //       "kind": "property",
          //       "field": "email",
          //       "fieldType": "string",
          //       "operator": "contains",
          //       "stringValue": "pierre"
          //     },
          //   ]
          // },

        ]
      }
    } // end state

    // 'contains','notContain','isTrue','isFalse','greaterThan','lessThan','inTheLast','before','after','inBetween','equals','notEqual'

    this.userDictionnary = {
      'property': [
        { key: "signedUpAt", label: "Signed up at", type: "datetime" },
        { key: "createdAt", label: "Created at", type: "datetime" },
        { key: "lastSeenAt", label: "Last seen at", type: "datetime" },
        { key: "tags", label: "Tags", type: "list" },
        // {key: "externalId", label: "External ID", type: "string"},
        // {key: "updatedAt", label: "Updated at", type: "date"},
        { key: "firstName", label: "First name", type: "string" },
        { key: "lastName", label: "Last name", type: "string" },
        { key: "gender", label: "Gender", type: "string" },
        { key: "birthday", label: "Birthday", type: "date" },
        // {key: "photoURL", label: "Photo URL", type: "string"},
        { key: "email", label: "Email", type: "string" },
        { key: "telephone", label: "Telephone", type: "string" },
        { key: "timezone", label: "Time zone", type: "string" },
        { key: "language", label: "Language", type: "string" },
        { key: "addressLine1", label: "Address line 1", type: "string" },
        { key: "addressLine2", label: "Address line 2", type: "string" },
        { key: "city", label: "City", type: "string" },
        { key: "region", label: "Region", type: "string" },
        { key: "postalCode", label: "Postal code", type: "string" },
        { key: "state", label: "State", type: "string" },
        { key: "country", label: "Country", type: "string" },
        { key: "websiteURL", label: "Website URL", type: "string" },
        { key: "linkedInURL", label: "LinkedIn URL", type: "string" },
        { key: "twitterUsername", label: "Twitter username", type: "string" },
        { key: "facebookUsername", label: "Facebook username", type: "string" },
        { key: "facebookId", label: "Facebook Id", type: "string" },
        // {key: "facebookIdHmac", label: "Facebook Id Hmac", type: "string"},
        // {key: "latitude", label: "Latitute", type: "number"},
        // {key: "longitude", label: "Longitude", type: "number"},
        // {key: "attributes", label: "QSD", type: "string"},
      ],
      'cart': [
        { key: "cartAt", label: "Updated at", type: "datetime" },
        { key: "cartTotal", label: "Total value", type: "number" },
        // TODO: add cart contains items...
      ],
      'wishList': [
        { key: "wishListAt", label: "Wish list updated at", type: "datetime" },
        { key: "wishListTotal", label: "Wish list total value", type: "number" },
      ],
      'conversionKPI': [],
      'notificationTopic': {
        key: "notificationTopic",
        label: "Notification topic",
        properties: [
          { key: 'collectionItemExternalId', label: t('collection_item_external_id', "Collection item external ID"), type: 'string' },
          { key: 'withEmail', label: t('with_email', "With email?"), type: 'boolean' },
          { key: 'withSMS', label: t('with_sms', "With SMS?"), type: 'boolean' },
        ]
      },
      // 'notificationTopic': [
      //   {
      //     key: "isSubscribed",
      //     label: "Is subscribed",
      //     properties: [
      //       { key: 'withEmail', label: t('with_email', "With email?"), type: 'boolean' },
      //       { key: 'withSMS', label: t('with_sms', "With SMS?"), type: 'boolean' },
      //     ]
      //   },
      //   {
      //     key: "isNotSubscribed",
      //     label: "Is not subscribed",
      //     // properties: []
      //   },
      // ],
      'action': [
        {
          key: "pageview",
          label: "Pageview",
          properties: [
            { key: 'title', label: t('title', "Title"), type: 'string' },
            { key: 'URL', label: 'URL', type: 'string' },
            { key: 'referrer', label: t('referrer', "Referrer"), type: 'string' },
            { key: 'tags', label: t('tags', "Tags"), type: 'list' },
            { key: 'properties', label: t('custom_property', "Custom property"), type: 'string' },
            // {key: 'duration', label: t('title', "Title"), type: 'string'},
            { key: 'product.externalId', label: t('product_external_id', "Product external ID"), type: 'string' },
            { key: 'product.sku', label: t('product_sku', "Product SKU"), type: 'string' },
            { key: 'product.name', label: t('product_name', "Product name"), type: 'string' },
            { key: 'product.brand', label: t('product_brand', "Product brand"), type: 'string' },
            { key: 'product.category', label: t('product_category', "Product category"), type: 'string' },
            { key: 'product.variant', label: t('product_variant', "Product variant"), type: 'string' },
            { key: 'product.price', label: t('product_price', "Product price"), type: 'number' },
            { key: 'article.author', label: t('article_author', "Article author"), type: 'string' },
          ]
        },
        {
          key: "session",
          label: "Session",
          properties: [
            { key: 'source', label: t('source', "Source"), type: 'string' },
            { key: 'medium', label: t('medium', "Medium"), type: 'string' },
            { key: 'campaign', label: t('campaign', "Campaign"), type: 'string' },
            { key: 'isFirst', label: t('is_first_user_session', "Is first user session?"), type: 'boolean' },
            { key: 'bounced', label: t('bounced', "Bounced?"), type: 'boolean' },
            { key: 'device', label: t('device', "Device"), type: 'string' },
            { key: 'landingPagePath', label: t('landing_page_path', "Landing-page path (i.e: /my-product)"), type: 'string' },
            { key: 'referrerDomain', label: t('referrer_domain', "Referrer domain (i.e: www.website.com)"), type: 'string' },
            { key: 'referrerPath', label: t('referrer_path', "Referrer path (i.e: /article-123)"), type: 'string' },
          ]
        },
        {
          key: "event",
          label: "Event",
          properties: [
            { key: 'label', label: t('label', "Label"), type: 'string' },
            { key: 'value', label: 'Value', type: 'number' },
            { key: 'nonInteractive', label: t('non_interactive', "Non-interactive"), type: 'boolean' },
            { key: 'properties', label: t('custom_property', "Custom property"), type: 'string' },
          ]
        },
        {
          key: "order",
          label: "Order",
          properties: [
            { key: 'revenue', label: t('revenue', "Revenue"), type: 'number' },
            { key: 'isFirst', label: t('is_first_order', "Is first order?"), type: 'boolean' },
            { key: 'financialStatus', label: t('financial_status', "Financial status"), type: 'string' },
            { key: 'fulfillmentStatus', label: t('fulfillment_status', "Fulfillment status"), type: 'string' },
            { key: 'closedAt', label: t('closed_at', "Closed at"), type: 'datetime' },
            { key: 'cancelledAt', label: t('cancelled_at', "Cancelled at"), type: 'datetime' },
            { key: 'timeToConversion', label: t('time_to_conversion', "Time to conversion (in days)"), type: 'number' },
            { key: 'items.externalId', label: t('item_external_id', "Item external ID"), type: 'list' },
            { key: 'items.sku', label: t('item_sku', "Item SKU"), type: 'list' },
            { key: 'items.name', label: t('item_name', "Item name"), type: 'list' },
            { key: 'items.brand', label: t('item_brand', "Item brand"), type: 'list' },
            { key: 'items.category', label: t('item_category', "Item category"), type: 'list' },
            { key: 'items.variant', label: t('item_variant', "Item variant"), type: 'list' },
            // {key: 'items.quantity', label: t('item_quantity', "Item quantity"), type: 'number'},
            // {key: 'items.price', label: t('item_price', "Item price"), type: 'number'},
            { key: 'tags', label: t('tags', "Tags"), type: 'list' },
            { key: 'properties', label: t('custom_property', "Custom property"), type: 'string' },
          ]
        },
        {
          key: "lead",
          label: "Lead",
          properties: [
            { key: 'conversionRuleId', label: 'Conversion rule ID', type: 'string' },
            { key: 'leadStageId', label: 'Stage ID', type: 'string' },
            { key: 'leadStatus', label: 'Status', type: 'string' },
            { key: 'revenue', label: t('revenue', "Revenue"), type: 'number' },
            { key: 'isFirst', label: t('is_first_lead', "Is first lead?"), type: 'boolean' },
            { key: 'timeToConversion', label: t('time_to_conversion', "Time to conversion (in days)"), type: 'number' },
            // {key: 'items.quantity', label: t('item_quantity', "Item quantity"), type: 'number'},
            // {key: 'items.price', label: t('item_price', "Item price"), type: 'number'},
            { key: 'tags', label: t('tags', "Tags"), type: 'list' },
            { key: 'properties', label: t('custom_property', "Custom property"), type: 'string' },
          ]
        },
      ]
    }

    // add custom properties
    forEach(props.userCustomProperties, (propType, key) => {
      // PropertyNumber = 1
      // PropertyString = 2
      // PropertyBool   = 3
      // PropertyDate   = 4
      // PropertyJson   = 5
      // console.log(key, propType)
      switch (propType) {
        case 1:
          propType = 'number'
          break
        case 2:
          propType = 'string'
          break
        case 3:
          propType = 'boolean'
          break
        case 4:
          propType = 'datetime'
          break
        case 5:
          // json is used for doing list
          propType = 'list'
          break
        default:
          propType = 'string'
      }
      this.userDictionnary.property.push({ key: key, label: 'Custom: ' + key, type: propType })
    })

    // add conversion rules
    forEach(props.project.conversionRules, cr => {
      switch (cr.kind) {
        case 'order':
          //'cr_'+cr.id
          this.userDictionnary.conversionKPI.push({ key: cr.id + ".revenue", label: cr.name + ": Revenue (" + this.props.project.currency + ")", type: "number" })
          this.userDictionnary.conversionKPI.push({ key: cr.id + ".ordersCount", label: cr.name + ": Orders count", type: "number" })
          this.userDictionnary.conversionKPI.push({ key: cr.id + ".lastOrderAt", label: cr.name + ": Last order at", type: "datetime" })
          this.userDictionnary.conversionKPI.push({ key: cr.id + ".avgTimeToConversion", label: cr.name + ": Avg. time to conversion (in hours)", type: "number" })
          this.userDictionnary.conversionKPI.push({ key: cr.id + ".avgCart", label: cr.name + ": Avg. cart (" + this.props.project.currency + ")", type: "number" })
          this.userDictionnary.conversionKPI.push({ key: cr.id + ".ordersFrequency", label: cr.name + ": Orders frequency (per year)", type: "number" })
          break

        case 'lead':
          // TODO
          break

        case 'subscription':
          // TODO
          break

        default:
      }
    })

    this.types = {
      'string': { label: 'String', operators: ['contains', 'notContain', 'equals', 'notEqual', 'exists', 'notExist'] },
      'number': { label: 'Number', operators: ['greaterThan', 'lessThan', 'inBetween', 'equals', 'notEqual', 'exists', 'notExist'] },
      'boolean': { label: 'True / false', operators: ['isTrue', 'isFalse'] },
      'datetime': { label: 'Date & time', operators: ['inTheLast', 'before', 'after', 'inBetween', 'exists', 'notExist'] },
      'date': { label: 'Date', operators: ['inTheLast', 'before', 'after', 'inBetween', 'exists', 'notExist'] },
      'list': { label: 'List', operators: ['contains', 'notContain', 'exists', 'notExist'] },
    }

    this.operators = {
      'contains': { label: t('contains', "contains"), excludeFields: ['device', 'financialStatus', 'fulfillmentStatus', 'leadStatus'] },
      'notContain': { label: t('does_not_contain', "does not contain"), excludeFields: ['device', 'financialStatus', 'fulfillmentStatus', 'leadStatus'] },
      'isTrue': { label: t('is_true', "is true") },
      'isFalse': { label: t('is_false', "is false") },
      'greaterThan': { label: t('is_greater_than', "is greater than") },
      'lessThan': { label: t('is_less_than', "is less than") },
      'inTheLast': { label: t('is_in_the_last', "is in the last") },
      'before': { label: t('is_before', "is before") },
      'after': { label: t('is_after', "is after") },
      'inBetween': { label: t('is_in_between', "is in between") },
      'equals': { label: t('is_equal_to', "is equal to") },
      'notEqual': { label: t('is_not_equal_to', "is not equal to") },
      'exists': { label: t('exists', "exists"), excludeKinds: ['conversionKPI', 'cart', 'wishList'], excludeFields: ['device', 'financialStatus', 'fulfillmentStatus', 'leadStatus'] },
      'notExist': { label: t('does_not_exist', "does not exist"), excludeKinds: ['conversionKPI', 'cart', 'wishList'], excludeFields: ['device', 'financialStatus', 'fulfillmentStatus', 'leadStatus'] },
    }

    this.addCondition = this.addCondition.bind(this)
    this.editCondition = this.editCondition.bind(this)
    this.cancelOrDeleteCondition = this.cancelOrDeleteCondition.bind(this)
    this.changeGroupOperator = this.changeGroupOperator.bind(this)
    this.addFilter = this.addFilter.bind(this)
    this.removeFilter = this.removeFilter.bind(this)
    this.setFilterField = this.setFilterField.bind(this)
    this.setFilterCustomPropertyType = this.setFilterCustomPropertyType.bind(this)
    this.setEditConditionValue = this.setEditConditionValue.bind(this)
    this.checkMissingFields = this.checkMissingFields.bind(this)
    this.confirmCondition = this.confirmCondition.bind(this)
  }

  checkMissingFields(fieldType: string, operator: string, edit: any) {

    if (!fieldType || fieldType === '' || !operator || operator === '' || !edit) {
      return true
    }

    let missingField = false

    switch (fieldType) {
      case 'string':
        if (operator !== 'exists' && operator !== 'notExist' && trim(edit.stringValue) === '') {
          missingField = true
        }
        break
      case 'number':
        if (operator !== 'exists' && operator !== 'notExist') {
          if (edit.numberValue === '') missingField = true
          if (operator === 'inBetween' && edit.numberValueBis === '') missingField = true
        }
        break
      case 'datetime':
        if (operator !== 'exists' && operator !== 'notExist' && (edit.stringValue === '' || edit.stringValueBis === '')) {
          missingField = true
        }
        break
      case 'date':
        if (operator !== 'exists' && operator !== 'notExist' && (edit.stringValue === '' || edit.stringValueBis === '')) {
          missingField = true
        }
        break
      case 'list':
        if (operator !== 'exists' && operator !== 'notExist' && trim(edit.stringValue) === '') {
          missingField = true
        }
        break
      default:
    }
    return missingField
  }

  confirmCondition(e: any) {
    if (e) {
      e.preventDefault()
    }

    const edit = this.state.editCondition
    const path = edit.path + '[' + edit.key + ']'

    let attributes = cloneDeep(this.state.attributes)

    const source = get(attributes, path)

    if (source.kind !== 'operator') {

      source.field = trim(edit.field)
      source.fieldType = edit.fieldType
      source.operator = edit.operator

      let missingField = this.checkMissingFields(source.fieldType, source.operator, edit)

      // sanitize
      if (edit.stringValue) source.stringValue = trim(edit.stringValue)
      if (edit.stringValueBis) source.stringValueBis = trim(edit.stringValueBis)
      if (edit.stringValueTer) source.stringValueTer = trim(edit.stringValueTer)
      if (edit.numberValue !== undefined) source.numberValue = edit.numberValue
      if (edit.numberValueBis !== undefined) source.numberValueBis = edit.numberValueBis

      if (source.kind === 'notificationTopic') {
        // add filters
        if (edit.filters && edit.filters.length) {

          source.filters = []

          edit.filters.forEach((filter: any, i: number) => {

            const sanFilter: any = {
              field: filter.field,
              fieldType: filter.fieldType,
              operator: filter.operator
            }

            // check missing fields
            missingField = this.checkMissingFields(sanFilter.fieldType, sanFilter.operator, filter)

            // sanitize

            if (filter.stringValue) sanFilter.stringValue = trim(filter.stringValue)
            if (filter.stringValueBis) sanFilter.stringValueBis = trim(filter.stringValueBis)
            if (filter.stringValueTer) sanFilter.stringValueTer = trim(filter.stringValueTer)
            if (filter.numberValue !== undefined) sanFilter.numberValue = filter.numberValue
            if (filter.numberValueBis !== undefined) sanFilter.numberValueBis = filter.numberValueBis

            // props

            if (sanFilter.field === 'properties') {
              sanFilter.customProperty = trim(filter.customProperty)

              if (!sanFilter.customProperty || sanFilter.customProperty === '') {
                missingField = true
              }
            }

            source.filters.push(sanFilter)
          })
        }
      }

      if (source.kind === 'action') {
        if (edit.timeframe) {
          source.timeframe = edit.timeframe
          // todo date range
        } else {
          // possibly remove timefrace
          delete source['timeframe']
          delete source['timeframeFrom']
          delete source['timeframeTo']
          delete source['timeframeTimezone']
        }

        // add filters
        if (edit.filters && edit.filters.length) {

          source.filters = []

          edit.filters.forEach((filter: any, i: number) => {

            const sanFilter: any = {
              field: filter.field,
              fieldType: filter.fieldType,
              operator: filter.operator
            }

            // check missing fields
            missingField = this.checkMissingFields(sanFilter.fieldType, sanFilter.operator, filter)

            // sanitize

            if (filter.stringValue) sanFilter.stringValue = trim(filter.stringValue)
            if (filter.stringValueBis) sanFilter.stringValueBis = trim(filter.stringValueBis)
            if (filter.stringValueTer) sanFilter.stringValueTer = trim(filter.stringValueTer)
            if (filter.numberValue !== undefined) sanFilter.numberValue = filter.numberValue
            if (filter.numberValueBis !== undefined) sanFilter.numberValueBis = filter.numberValueBis

            // props

            if (sanFilter.field === 'properties') {
              sanFilter.customProperty = trim(filter.customProperty)

              if (!sanFilter.customProperty || sanFilter.customProperty === '') {
                missingField = true
              }
            }

            source.filters.push(sanFilter)
          })
        }
      }

      if (missingField === true) {
        this.props.app.addMessage('error', t('all_fields_required', "All fields are required"))
        return
      }
    }

    set(attributes, path, source)

    this.setState({
      attributes: attributes,
      editCondition: undefined
    })

    // console.log('source', source)
    this.props.onChange(attributes)
  }

  addCondition(path: string, key: number, values: any, selectedOptions: any) {


    const kind = values[0] // operator, property, conversion, notificationTopic, action

    // console.log('values', values);
    // console.log('selectedOptions', selectedOptions);
    // console.log('path', path);
    // console.log('key', key);

    let attributes = cloneDeep(this.state.attributes)
    let add: any = { kind: kind }
    let editCondition: any = undefined

    if (kind === 'operator') {
      add.operator = values[1]
      add.conditions = []
    }

    // console.log('add', add);

    // node path, if non root
    const setPath = path + '[' + key + ']'
    // node key if many conditions
    let newKey = 0

    if (path === '') {
      attributes.conditions.push(add)
      newKey = attributes.conditions.length - 1
    } else {
      // console.log('setPath', setPath);

      const target = get(attributes, setPath)
      target.conditions.push(add)
      newKey = target.conditions.length - 1
    }

    // set the current condition to edit after adding the node
    // if its not an operator AND/OR

    if (kind !== 'operator') {

      const field = Array.isArray(this.userDictionnary[kind]) ? this.userDictionnary[kind].find((x: any) => x.key === values[1]) : this.userDictionnary[kind]

      editCondition = {
        path: (path === '') ? 'conditions' : setPath + '.conditions',
        key: newKey,
        kind: kind,
        isEdit: true,
        isNew: true,
        field: field.key
      }

      // action has no type
      if (field.type) {
        editCondition.fieldType = field.type
      }

      if (kind === 'action') {
        editCondition.numberValue = 1
        editCondition.operator = 'atLeast'
        editCondition.fieldType = 'number'
      }

      if (kind === 'notificationTopic') {
        // editCondition.stringValue = undefined
        editCondition.operator = 'isSubscribed'
        editCondition.fieldType = 'string'
      }
    }

    this.setState({
      attributes: attributes,
      editCondition: editCondition
    })
  }

  editCondition(path: string, key: number) {
    // console.log('path', path);
    // console.log('key', key);

    const source = get(this.state.attributes, path + '[' + key + ']')

    let editCondition = Object.assign({
      path: path,
      key: key
    }, source)

    const field = Array.isArray(this.userDictionnary[editCondition.kind]) ? this.userDictionnary[editCondition.kind].find((x: any) => x.key === editCondition.field) : this.userDictionnary[editCondition.kind]

    editCondition.field = field.key

    if (field.type) {
      editCondition.fieldType = field.type
    }

    editCondition.isEdit = true

    this.setState({ editCondition: editCondition })
  }

  cancelOrDeleteCondition(path: string, key: number) {
    // console.log('path', path);
    // console.log('key', key);
    let attributes = cloneDeep(this.state.attributes)

    // cancel if edit, and is not new
    if (get(this.state, 'editCondition.isEdit') === true && !get(this.state, 'editCondition.isNew')) {
      this.setState({ editCondition: undefined })
      this.props.onChange(attributes)
      return
    }

    let target = get(attributes, path)

    if (target && target.length) {
      set(attributes, path, target.filter((x: any, i: number) => i !== key))
    }

    // reset possible edit mode on current field
    let editCondition = this.state.editCondition

    if (editCondition && editCondition.path === path && editCondition.key === key) {
      editCondition = undefined
    }

    this.setState({
      attributes: attributes,
      editCondition: editCondition
    })

    this.props.onChange(attributes)
  }

  changeGroupOperator(path: string, pathKey: number, value: any) {

    let attributes = cloneDeep(this.state.attributes)

    if (path === '') {
      attributes.operator = value
    } else {
      set(attributes, path + '[' + pathKey + '].operator', value)
    }

    this.setState({ attributes: attributes })
    this.props.onChange(attributes);
  }

  addFilter() {
    const editCondition = cloneDeep(this.state.editCondition)
    if (!editCondition.filters) {
      editCondition.filters = []
    }
    editCondition.filters.push({
      field: undefined,
      fieldType: undefined,
      operator: undefined
    })
    this.setState({ editCondition: editCondition })
  }

  removeFilter(index: number) {
    const editCondition = cloneDeep(this.state.editCondition)
    editCondition.filters.splice(index, 1)
    this.setState({ editCondition: editCondition })
  }

  setFilterField(kind: string, index: number, value: any) {
    const editCondition = cloneDeep(this.state.editCondition)
    // find field
    const field = Array.isArray(this.userDictionnary[kind]) ? this.userDictionnary[kind].find((x: any) => x.key === editCondition.field) : this.userDictionnary[kind]

    // find property
    const filterField = field.properties.find((p: any) => p.key === value)

    editCondition.filters[index].field = value
    editCondition.filters[index].fieldType = filterField.type
    // reset everything else in case of change of field
    editCondition.filters[index].operator = undefined
    editCondition.filters[index].stringValue = undefined
    editCondition.filters[index].stringValueBis = undefined
    editCondition.filters[index].stringValueTer = undefined
    editCondition.filters[index].numberValue = undefined
    editCondition.filters[index].numberValueBis = undefined

    this.setState({ editCondition: editCondition })
  }

  setFilterCustomPropertyType(index: number, value: any) {
    const editCondition = cloneDeep(this.state.editCondition)

    editCondition.filters[index].fieldType = value
    // reset everything else in case of change of field
    editCondition.filters[index].operator = undefined
    editCondition.filters[index].stringValue = undefined
    editCondition.filters[index].stringValueBis = undefined
    editCondition.filters[index].stringValueTer = undefined
    editCondition.filters[index].numberValue = undefined
    editCondition.filters[index].numberValueBis = undefined

    this.setState({ editCondition: editCondition })
  }

  setEditConditionValue(path: string, value: any) {
    const editCondition = cloneDeep(this.state.editCondition)
    set(editCondition, path, value)
    this.setState({ editCondition: editCondition })
  }

  render() {
    let color = 0

    const getColor = () => {
      color++
      return color
    }

    // console.log(this.state);

    const deleteButton = (path: string, pathKey: number) => {
      return (
        <Popconfirm placement="left" title={t('delete_segment_condition_group_confirm', "Do you really want to remove to group?")} onConfirm={this.cancelOrDeleteCondition.bind(null, path, pathKey)} okText={t('yes', "Yes")} cancelText={t('no', "No")}>
          <Button size="small"><CloseOutlined /></Button>
        </Popconfirm>
      );
    }

    const displayExpression = (fieldType: string, operator: string, condition: any) => {
      if (operator === 'exists' || operator === 'notExist') {
        return <span className="opacity-60">{Formatters.conditionOperator(operator)}</span>
      }

      switch (fieldType) {
        case 'string':
          return <>
            <span className="opacity-60">{Formatters.conditionOperator(operator)}</span> <span className="color-kpi-blue">{condition.stringValue}</span>
          </>

        case 'number':
          return <>
            <span className="opacity-60">{Formatters.conditionOperator(operator)}</span> <span className="color-kpi-blue">{Numeral(condition.numberValue).format('0,0[.]00')}</span>
            {operator === 'inBetween' && <>
              <span className="opacity-60">&nbsp;{t('and', "and")}</span> <span className="color-kpi-blue">{Numeral(condition.numberValueBis).format('0,0[.]00')}</span>
            </>}
          </>

        case 'boolean':
          return <span className="opacity-60">{Formatters.conditionOperator(operator)}</span>

        case 'list':
          return <>
            <span className="opacity-60">{Formatters.conditionOperator(operator)}</span> <span className="color-kpi-blue">{condition.stringValue}</span>
          </>

        case 'datetime':
          return <>
            <span className="opacity-60">{Formatters.conditionOperator(operator)}&nbsp;</span>
            {operator === 'inTheLast' ? <>
              <span className="color-kpi-blue">{condition.numberValue}</span> <span className="opacity-60">{t('days', "days")}</span>
            </>
              : <>
                <span className="color-kpi-blue">{condition.stringValue}</span>
                <span className="opacity-60">&nbsp;{t('in', "in")}</span> <span className="color-kpi-blue">{condition.stringValueBis}</span>
              </>}
          </>

        case 'date':
          return <>
            <span className="opacity-60">{Formatters.conditionOperator(operator)}</span> <span className="color-kpi-blue">{condition.stringValue}</span>
          </>

        default:
          return fieldType + ' is not implemented...'
      }
    }

    const renderCompareValuesInputs = (path: string, condition: any) => {

      const operator = get(condition, path + 'operator')
      if (!operator || operator === 'exists' || operator === 'notExist') {
        return
      }

      const field = get(condition, path + 'field')
      const fieldType = get(condition, path + 'fieldType')

      if (field === 'device') {
        return <Select
          size="small"
          className="margin-r-s"
          autoFocus={true}
          placeholder={t('select_a_value', "Select a value")}
          style={{ width: '150px' }}
          value={get(condition, path + 'stringValue')}
          onChange={this.setEditConditionValue.bind(null, path + 'stringValue')}
        >
          <Select.Option value="computer">{t('computer', "Computer")}</Select.Option>
          <Select.Option value="tablet">{t('tablet', "Tablet")}</Select.Option>
          <Select.Option value="phone">{t('phone', "Phone")}</Select.Option>
          <Select.Option value="console">{t('console', "Console")}</Select.Option>
          <Select.Option value="wearable">{t('wearable', "Wearable")}</Select.Option>
          <Select.Option value="tv">{t('tv', "TV")}</Select.Option>
          <Select.Option value="unknown">{t('other', "Other")}</Select.Option>
        </Select>
      }

      if (field === 'leadStatus') {
        return <Select
          size="small"
          className="margin-r-s"
          autoFocus={true}
          placeholder={t('select_a_value', "Select a value")}
          style={{ width: '150px' }}
          value={get(condition, path + 'stringValue')}
          onChange={this.setEditConditionValue.bind(null, path + 'stringValue')}
        >
          <Select.Option value="open">Open</Select.Option>
          <Select.Option value="converted">Converted</Select.Option>
          <Select.Option value="lost">Lost</Select.Option>
        </Select>
      }

      if (field === 'financialStatus') {
        return <Select
          size="small"
          className="margin-r-s"
          autoFocus={true}
          placeholder={t('select_a_value', "Select a value")}
          style={{ width: '150px' }}
          value={get(condition, path + 'stringValue')}
          onChange={this.setEditConditionValue.bind(null, path + 'stringValue')}
        >
          <Select.Option value="pending">{t('pending', "Pending")}</Select.Option>
          <Select.Option value="authorized">{t('authorized', "Authorized")}</Select.Option>
          <Select.Option value="partially_paid">{t('partially_paid', "Partially paid")}</Select.Option>
          <Select.Option value="paid">{t('paid', "Paid")}</Select.Option>
          <Select.Option value="partially_refunded">{t('partially_refunded', "Partially refunded")}</Select.Option>
          <Select.Option value="refunded">{t('refunded', "Refunded")}</Select.Option>
          <Select.Option value="voided">{t('voided', "Voided")}</Select.Option>
        </Select>
      }

      if (field === 'fulfillmentStatus') {
        return <Select
          size="small"
          className="margin-r-s"
          autoFocus={true}
          placeholder={t('select_a_value', "Select a value")}
          style={{ width: '150px' }}
          value={get(condition, path + 'stringValue')}
          onChange={this.setEditConditionValue.bind(null, path + 'stringValue')}
        >
          <Select.Option value="fulfilled">{t('fulfilled', "Fulfilled")}</Select.Option>
          <Select.Option value="partial">{t('partial', "Partial")}</Select.Option>
          <Select.Option value="restocked">{t('restocked', "Restocked")}</Select.Option>
        </Select>
      }

      if (field === 'country') {
        return <Select
          size="small"
          className="margin-r-s margin-v-s"
          autoFocus={true}
          placeholder={t('select_a_value', "Select a value")}
          style={{ width: '150px' }}
          allowClear={true}
          value={get(condition, path + 'stringValue')}
          onChange={this.setEditConditionValue.bind(null, path + 'stringValue')}
          showSearch={true}
          filterOption={(searchText: any, option: any) => {
            return searchText !== '' && option.key.toLowerCase().includes(searchText.toLowerCase())
          }}>
          {Countries.map((country) => {
            return <Select.Option key={country.code} value={country.code}>{country.code + ' - ' + country.name}</Select.Option>
          })}
        </Select>
      }

      if (field === 'language') {
        return <Select
          size="small"
          className="margin-r-s margin-v-s"
          autoFocus={true}
          placeholder={t('select_a_value', "Select a value")}
          style={{ width: '150px' }}
          allowClear={true}
          value={get(condition, path + 'stringValue')}
          onChange={this.setEditConditionValue.bind(null, path + 'stringValue')}
          showSearch={true}
          filterOption={(searchText: any, option: any) => {
            return searchText !== '' && option.key.toLowerCase().includes(searchText.toLowerCase())
          }}>
          {Languages.map((language) => {
            return <Select.Option key={language.alpha2} value={language.alpha2}>{language.alpha2 + ' - ' + language.name}</Select.Option>
          })}
        </Select>
      }

      if (field === 'timezone') {
        return <Select
          size="small"
          className="margin-r-s margin-v-s"
          autoFocus={true}
          placeholder={t('select_a_value', "Select a value")}
          style={{ width: '150px' }}
          allowClear={true}
          value={get(condition, path + 'stringValue')}
          onChange={this.setEditConditionValue.bind(null, path + 'stringValue')}
          showSearch={true}
          filterOption={(searchText: any, option: any) => {
            return searchText !== '' && option.key.toLowerCase().includes(searchText.toLowerCase())
          }}>
          {Moment.tz.names().map((timezone) => {
            return <Select.Option key={timezone} value={timezone}>{timezone}</Select.Option>
          })}
        </Select>
      }

      if (field === 'gender') {
        return <Select
          size="small"
          className="margin-r-s margin-v-s"
          autoFocus={true}
          placeholder={t('select_a_value', "Select a value")}
          style={{ width: '150px' }}
          allowClear={true}
          value={get(condition, path + 'stringValue')}
          onChange={this.setEditConditionValue.bind(null, path + 'stringValue')}>
          <Select.Option key="male" value="male">{t('man', "Man")}</Select.Option>
          <Select.Option key="male" value="female">{t('woman', "Woman")}</Select.Option>
        </Select>
      }

      if (fieldType === 'string') {
        return <Input size="small" className="margin-r-s margin-v-s" autoFocus={true} placeholder={t('enter_a_value', "enter a value")} style={{ width: '150px' }} value={get(condition, path + 'stringValue')} required onChange={(e: any) => this.setEditConditionValue(path + 'stringValue', e.target.value)} />
      }

      if (fieldType === 'list') {
        return <Input size="small" className="margin-r-s margin-v-s" autoFocus={true} placeholder={t('enter_a_value', "enter a value")} style={{ width: '150px' }} value={get(condition, path + 'stringValue')} required onChange={(e: any) => this.setEditConditionValue(path + 'stringValue', e.target.value)} />
      }

      if (fieldType === 'number' || operator === 'inTheLast') {
        return <>
          <InputNumber size="small" min={operator === 'inTheLast' ? 1 : 0.01} step={operator === 'inTheLast' ? 1 : 0.01}
            className="margin-r-s margin-v-s" autoFocus={true}
            placeholder={t('enter_a_value', "enter a value")}
            style={{ width: '120px' }} value={get(condition, path + 'numberValue')}
            required onChange={this.setEditConditionValue.bind(null, path + 'numberValue')}
          />

          {operator === 'inTheLast' && <span className="margin-r-s">{t('days', "days")}</span>}

          {operator === 'inBetween' && <>
            <span className="padding-r-s padding-v-s">{t('and', "and")}</span>
            <InputNumber size="small" step={0.01}
              className="margin-r-s margin-v-s" autoFocus={true}
              placeholder={t('enter_a_value', "enter a value")}
              style={{ width: '120px' }} value={get(condition, path + 'numberValueBis')}
              required onChange={this.setEditConditionValue.bind(null, path + 'numberValueBis')}
            />
          </>}
        </>
      }

      if (fieldType === 'datetime' || fieldType === 'date') {
        return <>
          <DatePicker
            showTime={fieldType === 'datetime' || false}
            size="small"
            className="margin-r-s margin-v-s"
            autoFocus={true}
            style={{ width: '175px' }}
            format={fieldType === 'datetime' ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD"}
            value={get(condition, path + 'stringValue') ? Moment(get(condition, path + 'stringValue')) : undefined}
            onChange={(momentValue, dateString) => {
              // console.log('momentValue', momentValue);
              // console.log('dateString', dateString);
              this.setEditConditionValue(path + 'stringValue', dateString)
            }}
          />

          {(operator === 'inBetween') && <>
            <span className="padding-r-s padding-v-s">{t('and', "and")}</span>
            <DatePicker
              showTime={fieldType === 'datetime' || false}
              size="small"
              className="margin-r-s margin-v-s"
              autoFocus={true}
              style={{ width: '175px' }}
              format={fieldType === 'datetime' ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD"}
              value={get(condition, path + 'stringValueTer') ? Moment(get(condition, path + 'stringValueTer')) : undefined}
              onChange={(momentValue, dateString) => {
                // console.log('momentValue', momentValue);
                // console.log('dateString', dateString);
                this.setEditConditionValue(path + 'stringValueTer', dateString)
              }}
            />
          </>}

          {fieldType === 'datetime' && <span>
            <span className="padding-r-s padding-v-s">{t('is_in', "in")}</span>

            <Select
              size="small"
              className="margin-r-s margin-v-s"
              autoFocus={true}
              placeholder={t('select_a_value', "Select a value")}
              style={{ width: '150px' }}
              allowClear={true}
              value={get(condition, path + 'stringValueBis')}
              onChange={this.setEditConditionValue.bind(null, path + 'stringValueBis')}
              showSearch={true}
              filterOption={(searchText: any, option: any) => {
                return searchText !== '' && option.key.toLowerCase().includes(searchText.toLowerCase())
              }}>
              {Moment.tz.names().map((timezone) => {
                return <Select.Option key={timezone} value={timezone}>{timezone}</Select.Option>
              })}
            </Select>
          </span>}
        </>
      }
    }

    const generateAttributeForm = (condition: any, path: string, pathKey: number) => {

      if (!path) {
        path = ''
      }

      let color = (condition.kind === "operator") ? getColor() : undefined

      const condPath = (path === '') ? 'conditions' : path + '[' + pathKey + '].conditions'

      const isEdit = this.state.editCondition ? true : false
      const isEditCurrent = isEdit && this.state.editCondition.path === path && this.state.editCondition.key === pathKey

      // console.log('this.state.editCondition', this.state.editCondition);

      return (
        <>
          {/*<div className="side-10">path: {path}, {pathKey}</div>*/}

          {/* GROUPS AND OR */}

          {condition.kind === "operator" && <div className="segment-form">
            <div className={'segment-form-group color-' + color}>

              {/* DELETE GROUP BUTTON */}

              {path !== '' && !isEdit && <Button.Group className="pull-right">
                {deleteButton(path, pathKey)}
              </Button.Group>}

              {/* SELET GROUP AND OR */}

              <Select size="small" className="padding-r-s" style={{ width: '110px' }} onChange={this.changeGroupOperator.bind(null, path, pathKey)} value={condition.operator}>
                <Select.Option value="and">{t('operator_all', "ALL")}</Select.Option>
                <Select.Option value="or">{t('operator_any', "ANY")}</Select.Option>
              </Select> <span className="opacity-60">{t('of_the_following', "of the following conditions match:")}</span>
            </div>

            {/* LOOP OVER CONDITIONS */}

            {condition.conditions.map((cond: any, i: number) => {
              return <div key={i} className="segment-form-condition">
                <div className={'segment-form-condition-separator color-' + color}></div>
                {i !== 0 && <div className={'segment-form-condition-' + condition.operator + ' color-' + color}>{t(condition.operator, condition.operator)}</div>}

                {/* recursive call to draw the tree */}
                {generateAttributeForm(cond, condPath, i)}
              </div>
            }
            )}

            {/* ADD CONDITION BUTTON */}

            <div className="segment-form-condition">
              <div className={'segment-form-condition-separator half color-' + color}></div>
              {condition.conditions.length > 0 && <div className={'segment-form-condition-' + condition.operator + ' color-' + color}>{t(condition.operator, condition.operator)}</div>}

              <div className="padding-v-m">
                <Cascader popupClassName="cascader-wide" onChange={this.addCondition.bind(null, path, pathKey)} expandTrigger="hover" options={[
                  {
                    value: 'operator',
                    label: 'AND / OR',
                    children: [
                      { value: 'and', label: t('operator_and', "AND") },
                      { value: 'or', label: t('operator_or', "OR") },
                    ]
                  },
                  {
                    value: 'property',
                    label: t('profile_property', "Profile property"),
                    children: this.userDictionnary.property.map((item: any) => {
                      return { value: item.key, label: item.label }
                    })
                  },
                  {
                    value: 'cart',
                    label: t('shopping_cart', "Shopping cart"),
                    children: this.userDictionnary.cart.map((item: any) => {
                      return { value: item.key, label: item.label }
                    })
                  },
                  {
                    value: 'wishList',
                    label: t('wish_list', "Wish list"),
                    children: this.userDictionnary.wishList.map((item: any) => {
                      return { value: item.key, label: item.label }
                    })
                  },
                  {
                    value: 'conversionKPI',
                    label: t('conversion_kpis', "Conversion KPIs"),
                    children: this.userDictionnary.conversionKPI.map((item: any) => {
                      return { value: item.key, label: item.label }
                    })
                  },
                  {
                    value: 'notificationTopic',
                    label: t('notification_topic', "Notification topic"),
                    // children: this.userDictionnary.notificationTopic.map((item: any) => {
                    //   return { value: item.key, label: item.label }
                    // })
                  },
                  {
                    value: 'action',
                    label: t('actions', "Actions"),
                    children: this.userDictionnary.action.map((item: any) => {
                      return { value: item.key, label: item.label }
                    })
                  },
                ]}>
                  <Button size="small" type="primary" ghost={condition.conditions.length > 0} disabled={this.state.editCondition}><PlusOutlined /> {t('condition', "Condition")}</Button>
                </Cascader>
              </div>
            </div>
          </div>}

          {/* CONDITION LINE */}

          {(condition.kind !== "operator") && <div className="padding-v-s padding-l-m">

            {/* EDIT FORM */}

            {isEditCurrent ? <>

              {/* CONFIRM / CANCEL */}
              <span className="pull-right">
                <Button type="primary" size="small" className="margin-l-l margin-r-s margin-v-s" onClick={this.confirmCondition}>{t('confirm', "Confirm")}</Button>
                <Button size="small" className="margin-v-s" onClick={this.cancelOrDeleteCondition.bind(null, path, pathKey)}>{t('cancel', "Cancel")}</Button>
              </span>


              {condition.kind === 'property' && <Tag className="padding-r-s" color="cyan">{t('property', "Property")}</Tag>}
              {condition.kind === 'cart' && <Tag className="padding-r-s" color="green">{t('shopping_cart', "Shopping cart")}</Tag>}
              {condition.kind === 'wishList' && <Tag className="padding-r-s" color="gold">{t('wish_list', "Wish list")}</Tag>}
              {condition.kind === 'conversionKPI' && <Tag className="padding-r-s" color="magenta">{t('conversion_kpi', "Conversion KPI")}</Tag>}
              {condition.kind === 'notificationTopic' && <Tag className="padding-r-s" color="purple">{t('notification_topic', "Notification topic")}</Tag>}
              {condition.kind === 'action' && <Tag className="padding-r-s" color="blue">{t('action', "Action")}</Tag>}

              {condition.kind !== 'notificationTopic' && <span className="color-kpi-blue padding-r-s">{Formatters.conditionField(this.state.editCondition.kind, this.state.editCondition.field, this.userDictionnary)}</span>}

              {condition.kind === 'notificationTopic' && <>

                <Select size="small" className="margin-r-s margin-v-s" autoFocus={true} placeholder={t('select_a_value', "select a value")} style={{ width: '200px' }} value={this.state.editCondition.operator} onChange={this.setEditConditionValue.bind(null, 'operator')}>
                  <Select.Option value="isSubscribed">{t('is_subscribed_to', "is subscribed to")}</Select.Option>
                  <Select.Option value="isNotSubscribed">{t('is_not_subscribed_to', "is not subscribed to")}</Select.Option>
                </Select>

                <Select options={this.props.notificationTopics.map((x: any) => {
                  return {
                    label: x.name,
                    value: x.id,
                  }
                })}
                  size="small"
                  className="margin-r-s margin-v-s"
                  autoFocus={true}
                  placeholder={t('select_a_value', "select a value")}
                  style={{ width: '200px' }}
                  value={this.state.editCondition.stringValue}
                  onChange={this.setEditConditionValue.bind(null, 'stringValue')}
                />

                {/* FILTERS */}
                {get(this.state, 'editCondition.filters', []).length > 0 && <>
                  {this.state.editCondition.filters.map((filter: any, i: number) => <div key={i} className="margin-t-s" style={{ marginLeft: '60px' }}>

                    {/* property */}
                    <span className="opacity-60"><FontAwesomeIcon icon="filter" className="margin-r-s size-12" /> </span>
                    <Select size="small" className="margin-r-s" autoFocus={true} placeholder={t('select_a_value', "select a value")} style={{ width: '250px' }} dropdownMatchSelectWidth={false} value={filter.field} onChange={this.setFilterField.bind(null, 'notificationTopic', i)}>
                      {this.userDictionnary.notificationTopic.properties.map((prop: any) => <Select.Option key={prop.key} value={prop.key}>{prop.label}</Select.Option>)}
                    </Select>

                    {/* custom props */}
                    {filter.field === 'properties' && <>
                      <Input size="small" className="margin-r-s" autoFocus={true} required
                        placeholder={t('property_key', "property key")}
                        style={{ width: '200px' }}
                        value={filter.customProperty}
                        onChange={(e: any) => this.setEditConditionValue('filters[' + i + '].customProperty', e.target.value)}
                        addonAfter={<Select size="small" dropdownMatchSelectWidth={false} style={{ width: '80px' }} placeholder={t('select_a_value', "select a value")} value={filter.fieldType} onChange={this.setFilterCustomPropertyType.bind(null, i)}>
                          {map(this.types, (x: any, y: any) => {
                            return <Select.Option key={y} value={x.key}>{x.label}</Select.Option>
                          })}
                        </Select>}
                      />
                    </>}

                    {/* operator */}
                    {((filter.field && filter.field !== 'properties') || (filter.customProperty && filter.customProperty.length)) && <Select size="small" className="margin-r-s" placeholder={t('select_a_value', "select a value")} style={{ width: '200px' }} dropdownMatchSelectWidth={false} value={filter.operator} onChange={this.setEditConditionValue.bind(null, 'filters[' + i + '].operator')}>
                      {this.types[filter.fieldType].operators.filter((o: any) => {
                        if (this.operators[o].excludeFields && this.operators[o].excludeFields.indexOf(filter.field) !== -1) {
                          return false
                        }
                        return true
                      }).map((o: any) => <Select.Option key={o} value={o}>{this.operators[o].label}</Select.Option>)}
                    </Select>}

                    {/* compare value */}
                    {renderCompareValuesInputs('filters[' + i + ']', this.state.editCondition)}

                    <Button size="small" onClick={this.removeFilter.bind(null, i)}><CloseOutlined /></Button>
                  </div>)}
                </>}

                {this.userDictionnary.notificationTopic.properties && <div className="margin-t-s" style={{ marginLeft: '60px' }}>
                  <span className="link" onClick={this.addFilter}>+ {t('filter', "Filter")}</span>
                </div>}
              </>}


              {condition.kind === 'action' && <>

                {/* COUNT OPERATOR */}
                <span className="opacity-60 padding-r-s">{t('happened', "happened")}</span>
                <Select size="small" className="margin-r-s margin-v-s" autoFocus={true} placeholder={t('select_a_value', "select a value")} style={{ width: '100px' }} value={this.state.editCondition.operator} onChange={this.setEditConditionValue.bind(null, 'operator')}>
                  <Select.Option value="atLeast">{t('at_least', "at least")}</Select.Option>
                  <Select.Option value="exactly">{t('exactly', "exactly")}</Select.Option>
                  <Select.Option value="atMost">{t('at_most', "at most")}</Select.Option>
                </Select>

                <InputNumber size="small" step={1} min={1}
                  className="margin-r-s margin-v-s"
                  placeholder={t('enter_a_value', "enter a value")}
                  style={{ width: '50px' }} value={this.state.editCondition.numberValue}
                  required onChange={this.setEditConditionValue.bind(null, 'numberValue')}
                />

                <span className="opacity-60">
                  {this.state.editCondition.numberValue > 1 ? t('x_times', "times") : t('1_times', "time")}
                </span>

                {/* TIMEFRAME */}
                <div className="margin-t-s" style={{ marginLeft: '60px' }}>
                  {this.state.editCondition.timeframe ? <span>
                    <span className="opacity-60"><FontAwesomeIcon icon="clock" className="margin-r-s size-12" /> </span>
                    <Select size="small" className="margin-r-s" autoFocus={true} placeholder={t('select_a_value', "select a value")} style={{ width: '150px' }} value={this.state.editCondition.timeframe} onChange={this.setEditConditionValue.bind(null, 'timeframe')}>
                      <Select.Option value="inTheLast24hours">{t('in_the_last_24_hours', "in the last 24 hours")}</Select.Option>
                      <Select.Option value="inTheLast2days">{t('in_the_last_2_days', "in the last 2 days")}</Select.Option>
                      <Select.Option value="inTheLast7days">{t('in_the_last_7_days', "in the last 7 days")}</Select.Option>
                      <Select.Option value="inTheLast14days">{t('in_the_last_14_days', "in the last 14 days")}</Select.Option>
                      <Select.Option value="inTheLast30days">{t('in_the_last_30_days', "in the last 30 days")}</Select.Option>
                      <Select.Option value="inTheLast90days">{t('in_the_last_90_days', "in the last 90 days")}</Select.Option>
                      <Select.Option value="inTheLast365days">{t('in_the_last_365_days', "in the last 365 days")}</Select.Option>
                      {/*<Select.Option value="dateRange">{t('date_range', "date range")}</Select.Option>*/}
                    </Select>
                    <Button size="small" onClick={this.setEditConditionValue.bind(null, 'timeframe', undefined)}><CloseOutlined /></Button>
                  </span>
                    :
                    <span className="link" onClick={this.setEditConditionValue.bind(null, 'timeframe', 'inTheLast30days')}>+ {t('timeframe', "Timeframe")}</span>
                  }
                </div>

                {/* FILTERS */}
                {get(this.state, 'editCondition.filters', []).length > 0 && <>
                  {this.state.editCondition.filters.map((filter: any, i: number) => <div key={i} className="margin-t-s" style={{ marginLeft: '60px' }}>

                    {/* property */}
                    <span className="opacity-60"><FontAwesomeIcon icon="filter" className="margin-r-s size-12" /> </span>
                    <Select size="small" className="margin-r-s" autoFocus={true} placeholder={t('select_a_value', "select a value")} style={{ width: '180px' }} dropdownMatchSelectWidth={false} value={filter.field} onChange={this.setFilterField.bind(null, 'action', i)}>
                      {this.userDictionnary.action.find((a: any) => a.key === this.state.editCondition.field).properties.map((prop: any) => <Select.Option key={prop.key} value={prop.key}>{prop.label}</Select.Option>)}
                    </Select>

                    {/* custom props */}
                    {filter.field === 'properties' && <>
                      <Input size="small" className="margin-r-s" autoFocus={true} required
                        placeholder={t('property_key', "property key")}
                        style={{ width: '200px' }}
                        value={filter.customProperty}
                        onChange={(e: any) => this.setEditConditionValue('filters[' + i + '].customProperty', e.target.value)}
                        addonAfter={<Select size="small" dropdownMatchSelectWidth={false} style={{ width: '80px' }} placeholder={t('select_a_value', "select a value")} value={filter.fieldType} onChange={this.setFilterCustomPropertyType.bind(null, i)}>
                          {map(this.types, (x: any, y: any) => {
                            return <Select.Option key={y} value={x.key}>{x.label}</Select.Option>
                          })}
                        </Select>}
                      />
                    </>}

                    {/* operator */}
                    {((filter.field && filter.field !== 'properties') || (filter.customProperty && filter.customProperty.length)) && <Select size="small" className="margin-r-s" placeholder={t('select_a_value', "select a value")} style={{ width: '120px' }} dropdownMatchSelectWidth={false} value={filter.operator} onChange={this.setEditConditionValue.bind(null, 'filters[' + i + '].operator')}>
                      {this.types[filter.fieldType].operators.filter((o: any) => {
                        if (this.operators[o].excludeFields && this.operators[o].excludeFields.indexOf(filter.field) !== -1) {
                          return false
                        }
                        return true
                      }).map((o: any) => <Select.Option key={o} value={o}>{this.operators[o].label}</Select.Option>)}
                    </Select>}

                    {/* compare value */}
                    {renderCompareValuesInputs('filters[' + i + ']', this.state.editCondition)}

                    <Button size="small" onClick={this.removeFilter.bind(null, i)}><CloseOutlined /></Button>
                  </div>)}
                </>}
                <div className="margin-t-s" style={{ marginLeft: '60px' }}>
                  <span className="link" onClick={this.addFilter}>+ {t('filter', "Filter")}</span>
                </div>
              </>}

              {/* ATTRIBUTES / CONVERSION RULES */}

              {condition.kind !== 'action' && condition.kind !== 'notificationTopic' && <>

                {/* SELECT ATTRIBUTE OPERATOR */}
                <Select size="small" className="margin-r-s margin-v-s" autoFocus={true} placeholder={t('select_a_value', "select a value")} style={{ width: '150px' }} value={this.state.editCondition.operator} onChange={this.setEditConditionValue.bind(null, 'operator')}>
                  {this.types[this.state.editCondition.fieldType].operators.filter((o: any) => {

                    if (this.operators[o].excludeKinds && this.operators[o].excludeKinds.indexOf(condition.kind) !== -1) {
                      return false
                    }
                    if (this.operators[o].excludeFields && this.operators[o].excludeFields.indexOf(this.state.editCondition.field) !== -1) {
                      return false
                    }
                    return true
                  }).map((o: any) => <Select.Option key={o} value={o}>{this.operators[o].label}</Select.Option>)}
                </Select>

                {renderCompareValuesInputs('', this.state.editCondition)}
              </>}

            </>
              :
              <>

                {/* EDIT DELETE BUTTONS */}

                {!isEdit && <Button.Group className="pull-right margin-t-s">
                  <Button size="small" onClick={this.editCondition.bind(null, path, pathKey)}><EditOutlined /></Button>
                  {deleteButton(path, pathKey)}
                </Button.Group>}

                {/* DISPLAY */}

                <div className="margin-v-s">
                  {condition.kind === 'property' && <Tag className="padding-r-s" color="cyan">{t('property', "Property")}</Tag>}
                  {condition.kind === 'cart' && <Tag className="padding-r-s" color="green">{t('shopping_cart', "Shopping cart")}</Tag>}
                  {condition.kind === 'wishList' && <Tag className="padding-r-s" color="gold">{t('wish_list', "Wish list")}</Tag>}
                  {condition.kind === 'conversionKPI' && <Tag className="padding-r-s" color="magenta">{t('conversion_kpi', "Conversion KPI")}</Tag>}
                  {condition.kind === 'notificationTopic' && <Tag className="padding-r-s" color="purple">{t('notification_topic', "Notification topic")}</Tag>}
                  {condition.kind === 'action' && <Tag className="padding-r-s" color="blue">{t('action', "Action")}</Tag>}

                  {condition.kind !== 'notificationTopic' && <span className="color-kpi-blue padding-r-s">{Formatters.conditionField(condition.kind, condition.field, this.userDictionnary)}</span>}

                  {/* NOTIFICATION TOPIC */}

                  {condition.kind === 'notificationTopic' && <>
                    <span className="opacity-60">{Formatters.conditionOperator(condition.operator)}</span> <span className="color-kpi-blue">{condition.stringValue}</span>


                    {condition.filters && condition.filters.length && condition.filters.map((filter: any, i: number) => <div key={i} className="padding-t-s" style={{ marginLeft: '60px' }}>
                      <span className="opacity-60"><FontAwesomeIcon icon="filter" className="margin-r-s size-12" /> </span> <span className="color-kpi-blue">{this.userDictionnary.notificationTopic.properties.find((x: any) => x.key === filter.field).label} {filter.field === 'properties' && filter.customProperty}</span> {displayExpression(filter.fieldType, filter.operator, filter)}
                    </div>)}
                  </>}


                  {/* ACTION */}

                  {condition.kind === 'action' && <>
                    <span className="opacity-60">{t('happened', "happened")}</span> <span className="opacity-60">{Formatters.conditionOperator(condition.operator)}</span> <span className="color-kpi-blue">{condition.numberValue}</span> <span className="opacity-60">{condition.numberValue > 1 ? t('x_times', "times") : t('1_times', "time")}</span>

                    {condition.timeframe && <div className="padding-t-s" style={{ marginLeft: '60px' }}>
                      <span className="opacity-60"><FontAwesomeIcon icon="clock" className="margin-r-s size-12" /> </span> {condition.timeframe !== 'dateRange' && <span className="color-kpi-blue padding-r-s">{Formatters.conditionTimeframe(condition.timeframe)}</span>}
                    </div>}

                    {condition.filters && condition.filters.length && condition.filters.map((filter: any, i: number) => <div key={i} className="padding-t-s" style={{ marginLeft: '60px' }}>
                      <span className="opacity-60"><FontAwesomeIcon icon="filter" className="margin-r-s size-12" /> </span> <span className="color-kpi-blue">{this.userDictionnary.action.find((x: any) => x.key === condition.field).properties.find((x: any) => x.key === filter.field).label} {filter.field === 'properties' && filter.customProperty}</span> {displayExpression(filter.fieldType, filter.operator, filter)}
                    </div>)}
                  </>}

                  {/* ATTRIBUTE, CONVERSION RULE */}

                  {condition.kind !== 'action' && condition.kind !== 'notificationTopic' && displayExpression(condition.fieldType, condition.operator, condition)}
                </div>

              </>}
          </div>}
        </>
      );
    }

    return <>
      {/*<legend>{t('segment_users_who_match', "Segment users who match...")}</legend>*/}

      <div className="margin-l-s padding-l-m padding-b-s" style={{ marginBottom: '200px' }}>
        {generateAttributeForm(this.state.attributes, '', 0)}
      </div>
    </>
  }
}


export default SegmentConditions
