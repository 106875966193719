import React, { useState, ReactNode } from "react"
import { FileProvider, Folder } from '../core/interfaces'
import {
    Modal,
    Form,
    Input,
} from 'antd'
import short from "short-uuid"

export interface CreateFolderProps {
    fileProvider: FileProvider
    path: string
    children: ReactNode
    onComplete: (newFolder: Folder) => void
    onError: (error: any) => void
}

const CreateFolder = (props: CreateFolderProps) => {

    const [loading, setLoading] = useState(false)
    const [modalVisible, setModalVisible] = useState(false)
    const [form] = Form.useForm()

    const onSubmit = () => {
        form
            .validateFields()
            .then(values => {
                const name = sanitizeName(values.name)

                const newFolder: Folder = {
                    id: short.uuid(),
                    name: name,
                    path: props.path === '~' ? '~' + name : props.path + '~' + name,
                    isFolder: true,
                    children: [],
                    childrenLoaded: true,
                    metadata: {}
                }

                if (props.fileProvider.getNodeMetadata) {
                    newFolder.metadata = props.fileProvider.getNodeMetadata(newFolder)
                }

                setLoading(true)

                props.fileProvider.createFolder(props.path, newFolder).then(() => {
                    form.resetFields()
                    setLoading(false)
                    setModalVisible(false)
                    props.onComplete(newFolder)
                })
                    .catch(e => {
                        setLoading(false)
                        props.onError(e)
                    });
            })
            .catch(props.onError);
    }

    const onCancel = () => {
        setModalVisible(false)
    }
    
    const sanitizeName = (value: string) => value.replace(new RegExp('~|/', 'g'), '')

    if (modalVisible === false) {
        return <span onClick={() => setModalVisible(true)}>
            {props.children}
        </span>
    } else {
        return <>
            <Modal
                title="Create folder"
                centered
                visible={true}
                okText="Create"
                onOk={onSubmit}
                onCancel={onCancel}
                cancelButtonProps={{ loading: loading }}
                okButtonProps={{ loading: loading }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                // initialValues={{ modifier: 'public' }}
                >
                    <Form.Item
                        name="name"
                        label="Folder name"
                        rules={[{
                            required: true,
                            type: 'string',
                            whitespace: true,
                            message: 'A folder name is required!',
                            transform: sanitizeName,
                        }]}
                    >
                        <Input addonBefore={props.path.replace(new RegExp('~', 'g'), '/')} />
                    </Form.Item>
                </Form>
            </Modal>
            {props.children}
        </>
    }
}

export default CreateFolder