import { useState } from 'react'
import {
    Button,
    Alert,
    // InputNumber,
    Form,
    Input,
    Divider,
    Switch,
    Drawer,
    Row,
    Col,
    message,
    Select,
} from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import _ from 'lodash'
import t from '../../utils/translate'
import AceInput from './_aceInput'
import Languages from '../../utils/languages'

const NotificationForm = ({ inDrawer, loading, project, notifications, notification, drawerVisible, toggleDrawerVisible, onFinish, onFinishFailed }) => {
    const [form] = Form.useForm();

    const initialValues = notification ? Object.assign({}, notification) : {
        name: t('welcome_email', "Welcome email"),
        id: 'welcome',
        languages: [project.defaultUserLanguage],
        nameTranslations: {},
        // withWidget: true,
        withEmail: true,
        testData: JSON.stringify({}, null, 2),
        visibleInUserPreferences: true,
        allowMuteEmail: true,
        allowMuteSMS: true,
        // increment: false,
        // incrementTimeout: 480,
    }

    if (notification) {
        initialValues.testData = JSON.stringify(notification.testData ? notification.testData : {}, null, 2)
    }

    // console.log('initialValues', initialValues);

    const formContent = <Form
        form={form}
        layout="horizontal"
        name="create_notif_form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        labelCol={{ sm: { span: 8 } }}
        wrapperCol={{ sm: { span: 14 } }}
        initialValues={initialValues}
    >
        <Form.Item
            name="name"
            label={t('notification_name', "Notification name") + ' ' + project.defaultUserLanguage.toUpperCase()}
            rules={[{ required: true, message: t('field_required', "This field is required!") }]}
        >
            <Input placeholder="i.e: Welcome email" onChange={(e: any) => {
                const name = e.target.value
                if (name) {
                    let newId = _.truncate(_.camelCase(name).toLowerCase(), { omission: '' })
                    if (newId !== '') {
                        form.setFieldsValue({ 'id': newId })
                    }
                }
            }} />
        </Form.Item>

        <Form.Item
            name="id"
            label={t('notification_id', "Notification ID")}
            rules={[
                { required: true, type: 'string', pattern: /^[a-z0-9-]+$/, message: t('only_alphanumeric_characters', "Only alphanumeric characters allowed!") },
                {
                    validator: (item, value) => {
                        if (notification) {
                            return Promise.resolve(undefined)
                        }

                        if (_.find(notifications, x => x.id === value)) {
                            return Promise.reject(t('this_notification_id_already_exists', "This notification ID already exists."))
                        }
                        return Promise.resolve(undefined)
                    }
                },
            ]}
            shouldUpdate
        >
            <Input disabled={notification ? true : false} placeholder="i.e: welcome" />
        </Form.Item>

        <Form.Item
            name="languages"
            label={t('notification_languages', "Notification languages")}
            rules={[{ required: true, type: 'array' }]}
        >
            <Select
                mode="tags"
                placeholder={t('select_a_value', "Select a value")}
                allowClear={false}
                showSearch={true}
                filterOption={(searchText: any, option: any) => {
                    return searchText !== '' && option.key.toLowerCase().includes(searchText.toLowerCase())
                }}>
                {Languages.map((language: any) => {
                    return <Select.Option key={language.alpha2} value={language.alpha2}>{language.alpha2 + ' - ' + language.name}</Select.Option>
                })}
            </Select>
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
            {(funcs) => {
                const languages = funcs.getFieldValue('languages')
                return languages.filter((lang: any) => lang !== project.defaultUserLanguage).map((lang: any) => <Form.Item
                    key={lang}
                    name={['nameTranslations', lang]}
                    label={t('notification_name', "Notification name") + ' ' + lang.toUpperCase()}
                    rules={[{ required: false, type: 'string' }]}
                >
                    <Input />
                </Form.Item>)
            }}
        </Form.Item>

        <Divider plain>{t('channels', "Channels")}</Divider>

        <Form.Item
            name="withEmail"
            label={t('email', "Email")}
            rules={[{ required: false, type: 'boolean' }]}
            valuePropName="checked"
        >
            <Switch />
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
            {(funcs) => {
                if (funcs.getFieldValue('withEmail') === true) {
                    return <Form.Item
                        name="emailIntegrationId"
                        label="Email Service Provider"
                        rules={[{ required: true, type: 'string' }]}
                    >
                        <Select>
                            {project.integrations.filter((x: any) => ['sparkPost', 'mailgun', 'postmark'].includes(x.kind)).map((x: any) => <Select.Option key={x.id} value={x.id}>{x.name}</Select.Option>)}
                        </Select>
                    </Form.Item>
                }
            }}
        </Form.Item>

        <Form.Item
            name="withSMS"
            label={t('sms', "SMS")}
            rules={[{ required: false, type: 'boolean' }]}
            valuePropName="checked"
        >
            <Switch />
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
            {(funcs) => {
                if (funcs.getFieldValue('withSMS') === true) {
                    return <Form.Item
                        name="smsIntegrationId"
                        label="SMS Service Provider"
                        rules={[{ required: true, type: 'string' }]}
                    >
                        <Select>
                            {project.integrations.filter((x: any) => ['twilio'].includes(x.kind)).map((x: any) => <Select.Option key={x.id} value={x.id}>{x.name}</Select.Option>)}
                        </Select>
                    </Form.Item>
                }
            }}
        </Form.Item>

        {/* <Form.Item
                    labelCol={{ span: 16 }}
                    name="withWidget"
                    label={t('in_app_widget', "In-app widget")}
                    rules={[{ required: false, type: 'boolean' }]}
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item> */}

        {/* <Form.Item
                    labelCol={{ span: 12 }}
                    name="withWebPush"
                    label="Web Push"
                    help="Coming soon..."
                    rules={[{ required: false, type: 'boolean' }]}
                    valuePropName="checked"
                >
                    <Switch disabled />
                </Form.Item> */}
        {/*<Form.Item 
      name="withSlack"
      label="Slack"
      help="Coming soon..."
      rules={[{ required: false, type: 'boolean' }]}
      valuePropName="checked"
    >
      <Switch disabled />
    </Form.Item>*/}

        <Divider plain>{t('user_preferences', "User preferences")}</Divider>

        <Alert className="margin-a-l" type="info" message={t('visible_in_user_preferences_info', "We recommend you to hide special notifications (i.e: reset passord...) from the user preferences")} />

        <Form.Item
            name="visibleInUserPreferences"
            label={t('visible_in_user_preferences', "Visible in user preferences")}
            rules={[{ required: false, type: 'boolean' }]}
            valuePropName="checked"
        >
            <Switch />
        </Form.Item>

        <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.visibleInUserPreferences !== curValues.visibleInUserPreferences}>
            {({ getFieldValue }: any) => {
                if (getFieldValue('visibleInUserPreferences') === true) {
                    return <Row gutter={12}>
                        <Col span={12}>
                            {/* <Form.Item
                                labelCol={{ span: 16 }}
                                name="allowMuteWidget"
                                label={t('allow_mute_widget', "Allow mute widget?")}
                                rules={[{ required: false, type: 'boolean' }]}
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item> */}

                            <Form.Item
                                labelCol={{ span: 16 }}
                                name="allowMuteSMS"
                                label={t('allow_mute_email', "Allow mute sms?")}
                                rules={[{ required: false, type: 'boolean' }]}
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                labelCol={{ span: 12 }}
                                name="allowMuteEmail"
                                label={t('allow_mute_email', "Allow mute email?")}
                                rules={[{ required: false, type: 'boolean' }]}
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>

                            {/* <Form.Item
                                labelCol={{ span: 12 }}
                                name="allowMuteWebPush"
                                label={t('allow_mute_web_push', "Allow mute web push?")}
                                rules={[{ required: false, type: 'boolean' }]}
                                valuePropName="checked"
                                help={t('coming_soon', "Coming soon...")}
                            >
                                <Switch disabled />
                            </Form.Item> */}
                        </Col>
                    </Row>
                }
            }}
        </Form.Item>

        {/* <Divider plain>{t('incrementation', "Incrementation")}</Divider>

        <Form.Item
            name="increment"
            label={t('allow_incrementation', "Allow incrementation")}
            help="Increments a counter on recurring notification instead of spamming your users."
            rules={[{ required: false, type: 'boolean' }]}
            valuePropName="checked"
        >
            <Switch />
        </Form.Item>

        <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.increment !== curValues.increment}>
            {({ getFieldValue }: any) => {
                if (getFieldValue('increment') === true) {
                    return <Form.Item
                        name="incrementTimeout"
                        label={t('new_increment_every', "New increment every")}
                        rules={[{ required: true, type: 'integer', min: 1 }]}
                    >
                        <InputNumber formatter={(value: any) => `${value} mins`} parser={(value: any) => value.replace(' mins', '')} style={{ width: "150px" }} />
                    </Form.Item>
                }
                return
            }}
        </Form.Item> */}

        {notification && <div>
            <Divider plain>{t('test_data', "Test data")}</Divider>

            <p>{t('test_data_info', "The \"test data\" is a JSON object that is used to customize your templates when you want to test them.")}</p>
            <Form.Item
                name="testData"
                labelCol={{ sm: { span: 0 } }}
                wrapperCol={{ sm: { span: 24 } }}
                validateFirst={true}
                rules={[
                    {
                        validator: (xxx, value) => {
                            // check if data is valid json
                            try {
                                if (JSON.parse(value)) { }
                                return Promise.resolve(undefined)
                            }
                            catch (e: any) {
                                return Promise.reject(t('test_data_is_not_valid_json', "Your test variables is not a valid JSON object!"))
                            }
                        }
                    },
                    {
                        required: false, type: 'object', transform: (value: any) => {
                            try {
                                const parsed = JSON.parse(value)
                                return parsed
                            }
                            catch (e: any) {
                                return value
                            }
                        }
                    },
                ]}
            >
                <AceInput id="testData" width="100%" height="150px" mode="json" />
            </Form.Item>
        </div>}

        {!inDrawer && <Form.Item wrapperCol={{ sm: { span: 12, offset: 10 } }} className="text-right margin-t-m">
            <Button loading={loading} type="primary" htmlType="submit">{t('create', "Create")} <ArrowRightOutlined /></Button>
        </Form.Item>}
    </Form>

    if (!inDrawer) {
        return formContent
    }

    return <Drawer
        title={(notification) ? t('edit_notification', "Edit notification") : t('create_a_notification', "Create a notification")}
        visible={drawerVisible}
        closable={true}
        width="90%"
        onClose={() => { toggleDrawerVisible() }}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
            <div style={{ textAlign: 'right' }}>
                <Button loading={loading} onClick={() => toggleDrawerVisible()} style={{ marginRight: 8 }}>{t('cancel', "Cancel")}</Button>
                <Button loading={loading} onClick={() => {
                    form
                        .validateFields()
                        .then((values: any) => {
                            onFinish(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                }} type="primary">{t('confirm', "Confirm")}</Button>
            </div>
        }
    >
        {formContent}
    </Drawer>
}


const NotificationDrawer = ({ project, notification, notifications, btnSize, ajaxRequest, inDrawer, onComplete }) => {

    const [loading, setLoading] = useState(false)
    const [drawerVisible, setDrawerVisible] = useState(false)

    const toggleDrawerVisible = () => {
        setDrawerVisible(!drawerVisible)
    }

    const onFinish = (values: any) => {

        if (loading === true) {
            return
        }
        setLoading(true)

        values.projectId = project.id

        if (values.testData) {
            try {
                const parsed = JSON.parse(values.testData)
                values.testData = parsed
            }
            catch (e: any) {
                values.testData = {}
            }
        }

        // update
        if (notification) {
            values.id = notification.id
        }

        ajaxRequest({
            method: 'post',
            url: notification ? '/notifications.update' : '/notifications.create',
            data: values
        }, (errorMessage: any, response: any) => {

            if (errorMessage) {
                if (errorMessage === 'id/exists') errorMessage = t('this_notification_id_already_exists', "This notification ID already exists.")

                message.error(errorMessage)
                setLoading(false)
                return
            }

            message.success(t('notification_created', "The notification has successfully been created!"))

            if (window.cmAgent) {
                window.cmAgent.event({
                    label: 'createNotification',
                    // props: {
                    // orgId: response.data.organization.id,
                    // projectId: 
                    // }
                })
                window.cmAgent.dispatch()
            }

            if (inDrawer) toggleDrawerVisible()
            setLoading(false)

            if (onComplete) onComplete()
        })
    }


    // console.log('qsd', this.state);

    // only init the form when needed, so it doesn't cache the fields if the notification testData is updated by templates
    return <>
        {(!inDrawer || drawerVisible) && <NotificationForm
            project={project}
            inDrawer={inDrawer}
            notification={notification}
            notifications={notifications}
            drawerVisible={drawerVisible}
            toggleDrawerVisible={toggleDrawerVisible}
            onFinish={onFinish}
            onFinishFailed={() => { }}
            loading={loading}
        />}
        {inDrawer && <Button loading={loading} type={notification ? 'default' : 'primary'} size={btnSize || 'normal'} onClick={() => toggleDrawerVisible()}>{notification ? t('Edit', "Edit") : t('create_a_notification', "Create a notification")}</Button>}
    </>
}

export default NotificationDrawer
