import React, { Component } from 'react'


import { EyeOutlined } from '@ant-design/icons';

import { Table, Button, Tooltip, DatePicker, Popover, Input } from 'antd';
import t from '../../utils/translate'
import Formatters from '../../utils/formatters'
import Moment from 'moment-timezone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import QS from 'qs'
import _ from 'lodash'
import DeleteOrderButton from './_deleteButton'
import AppLayout from 'components/app/layout';

const defaultParams = {
  limit: 30,
  skip: 0,
  sortKey: 'createdAt',
  sortOrder: 'descend',
}

class OrdersList extends Component<any, any> {
  _isMounted = false;

  constructor(props: any) {
    super(props)

    this.state = {
      loading: false,
      orders: [],
      ordersCount: 0,

      filterConversionIdVisible: false,
      // filterChannelVisible: false,
      filterDateVisible: false,
    }

    this.fetchOrders = this.fetchOrders.bind(this)
    this.handleTableChange = this.handleTableChange.bind(this)
    this.resetFilters = this.resetFilters.bind(this)
    this.deleteOrder = this.deleteOrder.bind(this)
    this.onChangeExternalID = this.onChangeExternalID.bind(this)
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchOrders()

    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Orders (project: ' + this.props.projectLayout.project.id + ')',
        page: this.props.location.pathname,
        props: {
          orgId: this.props.projectLayout.project.organizationId,
          projectId: this.props.projectLayout.project.id
        }
      })
      window.cmAgent.dispatch()
    }
  }

  componentDidUpdate(prevProps: any) {
    // check url params have changed
    if (prevProps.location.search !== this.props.location.search) {

      const params: any = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })
      const prevParams = QS.parse(prevProps.location.search, { ignoreQueryPrefix: true })

      // dont refresh on toggle user
      if (params.showUser !== prevParams.showUser) {
        return
      }

      this.fetchOrders()
    }
  }

  handleTableChange(pagination: any, filters: any, sorter: any) {
    // console.log('filters', filters);
    // console.log('pagination', pagination);
    // console.log('sorter', sorter);
    const params: any = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })

    const newParams = _.assign({
      limit: params.limit || defaultParams.limit,
      skip: params.skip || defaultParams.skip,
      sortKey: params.sortKey || defaultParams.sortKey,
      sortOrder: params.sortOrder || defaultParams.sortOrder,
      conversionRule: params.conversionRule,
      domainId: params.domainId,
    }, {
      limit: pagination.pageSize,
      skip: (pagination.current * pagination.pageSize) - pagination.pageSize,
      sortKey: sorter.columnKey,
      sortOrder: sorter.order,
      conversionRule: _.get(filters, 'conversionRule[0]'),
      domainId: _.get(filters, 'domainId[0]'),
    })

    this.updateQuery(newParams)

    // this.props.history.push('/organizations/'+this.props.organizationLayout.organization.id+'/projects/'+this.props.projectLayout.project.id+'/orders?'+QS.stringify(newParams, { indices: false }));
  }

  deleteOrder(conversion: any) {
    if (this.state.loading === true) return
    else this.setState({ loading: true })

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/orders.delete',
      data: {
        projectId: this.props.projectLayout.project.id,
        conversionRuleId: conversion.order.conversionRuleId,
        orderExternalId: conversion.order.conversionExternalId
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }

      this.props.app.addMessage('success', t('order_deleted', "Your order has successfully been removed!"))

      this.setState({ loading: false })
      this.fetchOrders()

      if (window.cmAgent) {
        window.cmAgent.event({
          label: 'deleteOrder',
          props: {
            orgId: this.props.projectLayout.project.organizationId,
            projectId: this.props.projectLayout.project.id,
          }
        })
        window.cmAgent.dispatch()
      }
    })
  }

  onChangeExternalID(value: any) {
    this.updateQuery({
      skip: 0,
      externalId: (value === '') ? undefined : value
    })
    this.setState({ filterConversionIdVisible: false })
  }

  resetFilters() {
    this.props.history.push('/organizations/' + this.props.organizationLayout.organization.id + '/projects/' + this.props.projectLayout.project.id + '/orders?' + QS.stringify(defaultParams, { indices: false }));
  }

  updateQuery(update: any) {
    const params: any = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })

    let query = {
      limit: params.limit || defaultParams.limit,
      skip: params.skip || defaultParams.skip,
      dateFrom: params.dateFrom || undefined,
      dateTo: params.dateTo || undefined,
      externalId: params.externalId || undefined,
      channelId: params.channelId || undefined,
      sortKey: params.sortKey || defaultParams.sortKey,
      sortOrder: params.sortOrder || defaultParams.sortOrder,
      conversionRule: params.conversionRule || undefined,
      domainId: params.domainId || undefined,
    }

    this.props.history.push('/organizations/' + this.props.organizationLayout.organization.id + '/projects/' + this.props.projectLayout.project.id + '/orders?' + QS.stringify(_.assign(query, update), { indices: false }));
  }

  fetchOrders() {

    if (this.state.loading === true) {
      return
    }

    this.setState({ loading: true })

    const params: any = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })

    let query: any = {
      projectId: this.props.projectLayout.project.id,
      externalId: params.externalId || undefined,
      channelId: params.channelId || undefined,
      domainId: params.domainId || undefined,
      dateFrom: params.dateFrom || undefined,
      dateTo: params.dateTo || undefined,
      limit: params.limit || defaultParams.limit,
      skip: params.skip || defaultParams.skip,
      sortKey: params.sortKey || defaultParams.sortKey,
      sortOrder: params.sortOrder || defaultParams.sortOrder,
      // segments: params.segments || defaultParams.segments,
    }

    if (params.conversionRule) {
      query.conversionRuleId = params.conversionRule
    }

    this.props.app.ajaxRequest({
      method: 'get',
      url: '/orders.list',
      params: query
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        // this.setState({loading: false})
        return
      }

      // console.log('res', response.data);

      if (this._isMounted) {
        this.setState({
          orders: response.data.orders,
          ordersCount: response.data.ordersCount,
          loading: false
        })
      }
    })
  }

  render() {
    const params: any = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })

    const channelsMap = _.keyBy(this.props.projectLayout.project.channels, 'id');
    const conversionRulesMap = _.keyBy(this.props.projectLayout.project.conversionRules, 'id')
    const domainsMap = _.keyBy(this.props.projectLayout.project.domains, 'id')

    const channelsFilter = []

    this.props.projectLayout.project.channelGroups.filter((x: any) => !x.deletedAt).forEach((group: any) => {

      let filter = {
        label: <span className={'channel-group ' + group.id} key={group.id}>{group.name}</span>,
        value: group.id,
        children: this.props.projectLayout.project.channels.filter((ch: any) => ch.groupId === group.id && !ch.deletedAt).map((ch: any) => {
          return { label: ch.name, value: ch.id }
        })
      }

      if (filter.children.length > 0) {
        channelsFilter.push(filter)
      }
    })

    // let cascaderDefaultValue = undefined

    // if (params.channelId) {
    //   const ch = this.props.projectLayout.project.channels.find((ch: any) => ch.id === params.channelId)

    //   if (ch) {
    //     cascaderDefaultValue = [ch.groupId, ch.id]
    //   }
    // }

    return <AppLayout currentProject={this.props.projectLayout.project} currentOrganization={this.props.organizationLayout.organization} admin={this.props.app.state.admin} firebaseUser={this.props.app.state.firebaseUser} projects={this.props.organizationLayout.projects}>

      <h1>{t('orders', "Orders")}</h1>

      <Table
        dataSource={this.state.orders}
        rowKey="id"
        className="edge-table block"
        size="middle"
        onChange={this.handleTableChange}
        loading={this.state.loading}
        pagination={{
          position: ['bottomCenter'],
          total: this.state.ordersCount
        }}
        locale={{
          'emptyText': <span>
            {t('no_data', "No data")}
            {(params.externalId || params.channelId || params.dateFrom || params.dateTo) && <span className="padding-l-s">&bull; <span className="padding-l-s link" onClick={this.resetFilters}>
              {t('reset_filters', "Reset filters")}
            </span></span>}
          </span>
        }}
        columns={[
          {
            title: 'ID',
            key: 'externalId',
            sortOrder: params.sortKey === 'externalId' ? params.sortOrder : undefined,
            sorter: (a: any, b: any) => {
              if (a.order.conversionExternalId < b.order.conversionExternalId) { return -1 }
              if (a.order.conversionExternalId > b.order.conversionExternalId) { return 1 }
              return 0
            },
            filterDropdown: (
              <div className="custom-filter-dropdown">
                <Input.Search
                  placeholder="Search conversion ID"
                  defaultValue={params.externalId}
                  enterButton
                  allowClear
                  onSearch={this.onChangeExternalID}
                />
              </div>
            ),
            filterDropdownVisible: this.state.filterConversionIdVisible,
            onFilterDropdownVisibleChange: (visible: any) => this.setState({ filterConversionIdVisible: visible }),
            filteredValue: (params.externalId && params.externalId !== '') ? [params.externalId] : [],
            render: (record: any) => {
              if (record.conversionExternalId.length > 15) {
                return <Tooltip title={record.conversionExternalId}>{_.truncate(record.conversionExternalId, { length: 16, omission: '...' })}</Tooltip>
              }
              return record.conversionExternalId
            }
          },
          {
            title: t('conversion_rule', "Conversion rule"),
            key: 'conversionRule',
            filters: this.props.projectLayout.project.conversionRules.map((cr: any) => {
              return {
                text: cr.name,
                value: cr.id
              }
            }),
            filterMultiple: false,
            onFilter: (value, record) => record.conversionRuleId === value,
            filteredValue: params.conversionRule ? [params.conversionRule] : null,
            render: (record: any) => conversionRulesMap[record.conversionRuleId].name
          },
          {
            title: t('domain', "Domain"),
            key: 'domainId',
            filters: this.props.projectLayout.project.domains.map((cr: any) => {
              return {
                text: cr.name,
                value: cr.id
              }
            }),
            filterMultiple: false,
            onFilter: (value, record) => record.domainId === value,
            filteredValue: params.domainId ? [params.domainId] : null,
            render: (record: any) => domainsMap[record.domainId].name
          },
          {
            title: 'Date',
            key: 'createdAt',
            filterDropdown: (
              <div className="custom-filter-dropdown">
                <DatePicker
                  style={{ width: '100%', marginBottom: '7px' }}
                  defaultValue={(params.dateFrom) ? Moment(params.dateFrom, 'YYYY-MM-DD') : undefined}
                  disabledDate={(date: any) => {
                    return date && date.isAfter(Moment())
                  }}
                  format="MMM Do YYYY"
                  placeholder="Start date"
                  onChange={(momentDate) => {
                    this.updateQuery({
                      dateFrom: (momentDate) ? momentDate.format('YYYY-MM-DD') : undefined
                    })
                  }} />

                <DatePicker
                  style={{ width: '100%' }}
                  defaultValue={(params.dateTo) ? Moment(params.dateTo, 'YYYY-MM-DD') : undefined}
                  disabledDate={(date: any) => {
                    return date && date.isAfter(Moment())
                  }}
                  format="MMM Do YYYY"
                  placeholder="End date"
                  onChange={(momentDate) => {
                    this.updateQuery({
                      dateTo: (momentDate) ? momentDate.format('YYYY-MM-DD') : undefined
                    })
                  }} />
              </div>
            ),
            filterDropdownVisible: this.state.filterDateVisible,
            onFilterDropdownVisibleChange: (visible: any) => this.setState({ filterDateVisible: visible }),
            filteredValue: (params.dateFrom || params.dateTo) ? [params.dateFrom, params.dateTo] : [],
            sortOrder: params.sortKey === 'createdAt' ? params.sortOrder : undefined,
            sorter: (a: any, b: any) => {
              if (a.createdAt < b.createdAt) { return -1 }
              if (a.createdAt > b.createdAt) { return 1 }
              return 0
            },
            render: order => <small>{Moment(order.createdAt).format('llll')}</small>
          },
          {
            key: 'customer',
            title: t('customer', "Customer"),
            render: t => {
              if (t.company && t.company.id) {
                return <div>
                  {t.company.photoURL ? <img className="user-picture" src={t.company.photoURL} alt="" /> : <span className="opacity-30"><FontAwesomeIcon icon="building" /></span>}
                  <span className="padding-l-s link" onClick={this.props.projectLayout.viewCompany.bind(null, t.company.externalId)}>{(t.company.name || '')}</span>
                </div>
              } else {
                let userName = t.user?.externalId

                if (t.user?.firstName && t.user?.firstName !== '') {
                  userName = t.user.firstName
                }

                if (t.user?.lastName && t.user?.lastName !== '') {
                  userName += ' ' + t.user.lastName
                }

                return <div>
                  {t.user?.photoURL ? <img className="user-picture" src={t.user?.photoURL} alt="" /> : <span className="user-picture-icon"><FontAwesomeIcon icon="user-circle" /></span>}
                  <span className="padding-l-s link" onClick={this.props.projectLayout.viewUser.bind(null, t.user?.externalId)}>{userName}</span>
                </div>
              }
            }
          },
          {
            key: 'revenue',
            title: t('revenue', "Revenue"),
            render: t => Formatters.currency(this.props.projectLayout.project.currency, t.revenue, t.revenueSource, t.currency, 'color-kpi-purple')
          },
          {
            title: t('funnel', "Funnel"),
            key: 'funnel',
            // filterDropdown: (
            //   <div className="custom-filter-dropdown">
            //     <Cascader 
            //       options={channelsFilter}
            //       placeholder="Channel"
            //       defaultValue={cascaderDefaultValue}
            //       expandTrigger="hover"
            //       style={{width: '100%'}}
            //       displayRender={(label, selectedOption) => label[1]}
            //       popupClassName="channels-cascader"
            //       allowClear={true}
            //       showSearch={{
            //         filter: (searchText, path) => {
            //           return searchText !== '' && path[1].value.indexOf(searchText.toLowerCase()) === 0
            //         },
            //         sort: (a,b, inputValue) => {
            //           const callback = (elem) => {
            //             const label = (typeof elem.label === 'object') ? elem.label.props.children : elem.label
            //             return label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
            //           }
            //           return a.findIndex(callback) - b.findIndex(callback)
            //         },
            //         render: (inputValue, path, prefixCls) => {
            //           return path.map((_ref:any , index: number) => {
            //             return index === 0 ? _ref.label : [' / ', _ref.label]
            //           })
            //         },
            //         matchInputWidth: false
            //       }}
            //       onChange={(values: any) => {
            //         this.updateQuery({channelId: values[1] || undefined})
            //       }} />
            //   </div>
            // ),
            // filterDropdownVisible: this.state.filterChannelVisible,
            // onFilterDropdownVisibleChange: visibl(e: any) => this.setState({ filterChannelVisible: visible }),
            // filteredValue: (params.channelId && params.channelId !== '') ? cascaderDefaultValue : [],
            render: (record) => {
              // console.log('record', record);

              // group identical channelId if funnel has different source/medium
              let funnel: any = []

              _.forEach(record.funnel, f => {

                if (!f.count) {
                  f.count = 0
                }

                // find channel
                var hitChannel = this.props.projectLayout.project.channels.find((x: any) => x.id === f.channelId)
                // console.log('hitChannel', hitChannel);

                if (!hitChannel) {
                  hitChannel = { id: 'not-mapped', name: t('not_mapped', "Not mapped") }
                }

                f.channelId = hitChannel.id
                f.channelName = hitChannel.name

                if (funnel.length === 0) {
                  funnel.push(f)
                } else {
                  if (funnel[funnel.length - 1].channelId === f.channelId) {
                    funnel[funnel.length - 1].count += f.count
                    if (funnel[funnel.length - 1].role < f.role) {
                      funnel[funnel.length - 1].role = f.role
                    }
                  } else {
                    funnel.push(f)
                  }
                }
              })

              return funnel.map((contribution: any, i: number) => {

                if (i === 2) return <Popover key={i} content={<div>
                  {funnel.map((c: any, k: number) => <div key={k}>{(c.channelId === 'not-mapped') ? c.source + ' / ' + c.medium : channelsMap[c.channelId].name}</div>)}</div>}>
                  <small className="link">+{funnel.length - 2} {t('more', "more")}</small>
                </Popover>
                if (i > 2) return null
                return <div key={i}><small>{(contribution.channelId === 'not-mapped') ? contribution.source + ' / ' + contribution.medium : channelsMap[contribution.channelId].name}</small></div>
              })
            }
          },
          {
            key: 'actions',
            title: <span className="pull-right"><Button type="ghost" size="small" onClick={this.fetchOrders} disabled={this.state.loading}><FontAwesomeIcon icon="sync-alt" spin={this.state.loading} /></Button></span>,
            className: 'text-right',
            render: (record: any) => <Button.Group>
              <DeleteOrderButton {...this.props} order={record} onComplete={this.fetchOrders} />

              <Tooltip placement="left" title={t('view_conversion_funnel', "View conversion funnel")}>
                <Button type="ghost" icon={<EyeOutlined />} onClick={this.props.projectLayout.viewOrder.bind(null, record.conversionExternalId, record.conversionRuleId)} />
              </Tooltip>
            </Button.Group>
          },
        ]}
      />
    </AppLayout>
  }
}

export default OrdersList