import React, { Component } from 'react'

import t from '../../utils/translate'
import { 
  Button,
  Modal,
  DatePicker,
  Select,
} from 'antd'
import Moment from 'moment-timezone'

class ReattributeCommissionsButton extends Component<any, any> {

  constructor(props: any) {
    super(props)
    
    this.state = {
      loading: false,
      reattributeSinceDate: Moment().subtract(31, 'days'),
      reattributeSinceTimezone: this.props.app.state.admin.timezone,
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit() {
    if (this.state.loading === true) {
      return
    }

    this.setState({loading: true})  

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/tasks.create',
      data: {
        projectId: this.props.projectLayout.project.id,
        kind: "reattributeCommissions",
        fromDate: Moment.tz(this.state.reattributeSinceDate.format('YYYY-MM-DD')+' 00:00:00', this.state.reattributeSinceTimezone).toISOString()
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({loading: false})
        return
      }

      this.props.app.addMessage('success', t('reattribute_commissions_success', 'Your commissions are being re-attributed.'))

        
      if (window.cmAgent) {
        window.cmAgent.event({
          label: 'createTaskManually',
          props: {
            orgId: this.props.projectLayout.project.organizationId,
            projectId: this.props.projectLayout.project.id,
            task: 'reattributeCommissions'
          }
        })
        window.cmAgent.dispatch()
      }

      this.setState({loading: false})
      this.props.onComplete()
    })
  }

  render() {

    return <Modal
      title={t('reattribute_commissions', 'Re-attribute commissions?')}
      wrapClassName="vertical-center-modal"
      visible={true}
      onCancel={this.props.toggleModal}
      width={500}
      footer={[
        <Button key="back" type="ghost" onClick={this.props.toggleModal}>{t('later', "Later")}</Button>,
        <Button key="submit" type="primary" loading={this.state.loading} onClick={this.handleSubmit}>{t('confirm', "Confirm")}</Button>,
      ]}>
        <div>
          <p>{t('recompute_attribution_info', "Your channels have been updated, would you like to re-attribute your media partners commissions with this new mapping?")}</p>
          <p>{t('reattribute_since', "Re-attribute since:")}</p>
          <DatePicker style={{width: '180px'}} value={this.state.reattributeSinceDate} onChange={(moment) => this.setState({reattributeSinceDate: moment})} />
          <Select style={{width: '180px'}} className="margin-l-m" value={this.state.reattributeSinceTimezone} onChange={(tz) => this.setState({reattributeSinceTimezone: tz})}>
            {Moment.tz.names().map((tz) => <Select.Option key={tz} value={tz}>{tz}</Select.Option>)}
          </Select>
        </div>
      </Modal>
  }
}

export default ReattributeCommissionsButton