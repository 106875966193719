import { useEffect } from 'react'
import Splash from './_splash'
import { Spin } from 'antd'

const SignOut = ({signOut}) => {

  useEffect(() => {
    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Logout',
        page: '/logout' // this.props.location.pathname
      })
      window.cmAgent.dispatch()
    }

    signOut()
  }, [signOut])

  return <Splash h1="Signing out..."><Spin size="large" /></Splash>
}

export default SignOut