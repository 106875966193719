

const removeLeadingDates = (lines: any) => {
    let bits: any

    lines.forEach((line: any, index: number) => {

        Object.keys(line).forEach(key => {
            // rename the key
            if (key.includes(',')) {
                bits = key.split(',')
                lines[index][bits[1]] = line[key]
                delete lines[index][key]
            }
        })
    })
}

export default removeLeadingDates