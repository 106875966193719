import { useState } from 'react'

import t from '../../utils/translate'
import { Button, Input, Drawer, Select, Tag, Form } from 'antd';
import { isEqual, kebabCase, get, toArray, filter } from 'lodash'
import SegmentConditions from './_conditions'
import SegmentsCascader from './_cascader'
import nanoid from 'utils/nanoid';

const SegmentFormButton = (props: any) => {

  const [showModal, setShowModal] = useState(false)

  // useEffect(() => {
  //   if (props.segment) {
  //     form.resetFields()
  //     form.setFieldsValue(props.segment)
  //   }
  // }, [props.segment, form])

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  if (!showModal) {
    return <Button block={props.block || false} type={props.type || "default"} style={props.style || {}} size={props.size} onClick={toggleModal}>{props.children}</Button>
  }


  // console.log('initialValues', initialValues)
  return <SegmentDrawer 
    toggleModal={toggleModal}
    setShowModal={setShowModal}
    segment={props.segment}
    onComplete={props.onComplete}
    projectLayout={props.projectLayout}
    organizationLayout={props.organizationLayout}
    app={props.app}
    kind={props.kind}
    title={props.title}
  />
}

const SegmentDrawer = (props: any) => {
  
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const onFinish = (values: any) => {

    // abort if no changes in update
    if (props.segment) {
      let hasChanges = false
      if (values.name !== props.segment.name) hasChanges = true
      if (values.color !== props.segment.color) hasChanges = true
      if (values.parentId !== props.segment.parentId) hasChanges = true
      if (values.matchAttributes !== props.segment.matchAttributes) hasChanges = true
      if (isEqual(values.conditions, props.segment.conditions) === false) hasChanges = true
      if (hasChanges === false) {
        form.resetFields()
        props.setShowModal(false)
        if (props.onComplete) {
          props.onComplete()
        }
        return
      }
    }

    // generate id
    let id = get(props.segment, 'id', kebabCase(values.name))

    if (!props.segment) {

      id = id + '-' + nanoid(4)

      // const existingIds = toArray(props.projectLayout.segments).filter((seg) => seg.id.indexOf(id) === 0)

      // if (existingIds.length > 0) {
      //   let count = existingIds.length
      //   id = id + count
      // }
    }

    values.projectId = props.projectLayout.project.id
    values.segmentId = id
    values.kind = props.kind
    values.forCompanies = props.forCompanies || false

    setLoading(true)

    props.app.ajaxRequest({
      method: 'post',
      url: props.segment ? '/segments.update' : '/segments.create',
      data: values
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        props.app.addMessage('error', errorMessage)
        setLoading(false)
        return
      }

      if (props.segment) {
        props.app.addMessage('success', t('segment_updated', 'The segment has been updated.'))
      } else {
        form.resetFields()
        props.app.addMessage('success', t('segment_created', 'The segment has been created.'))
      }

      let segments = filter(props.projectLayout.segments, (x: any) => x.id !== response.data.segment.id)
      segments.push(response.data.segment)
      props.projectLayout.setSegments(segments)

      setLoading(false)
      props.toggleModal()

      // console.log('response', response.data);

      if (window.cmAgent) {
        window.cmAgent.event({
          label: props.segment ? 'updateSegment' : 'createSegment',
          props: {
            orgId: props.organizationLayout.organization.id,
            projectId: props.projectLayout.project.id,
          }
        })
        window.cmAgent.dispatch()
      }

      if (props.onComplete) {
        props.onComplete()
      }
    })
  }

  const segments = toArray(props.projectLayout.segments).filter(s => {
    if (s.deletedAt) {
      return false
    }
    return props.forCompanies ? s.forCompanies === true : !s.forCompanies
  })

  let initialValues = { color: 'magenta', conditions: {} }

  if (props.segment) {
    initialValues = { ...props.segment }
  }

  return <Drawer
    title={props.title}
    visible={true}
    onClose={props.toggleModal}
    className="drawer drawer-form"
    width="90%">
    <Form form={form} initialValues={initialValues} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} layout="horizontal" className="margin-a-m margin-b-xl" onFinish={onFinish}>

      <Form.Item name="name" label={t('name', "Name")} rules={[{ required: true, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
        <Input
          placeholder="i.e: Big spenders..."
          addonAfter={<Form.Item noStyle name="color"><Select style={{ width: 120 }}>
            <Select.Option value="magenta"><Tag color="magenta">magenta</Tag></Select.Option>
            <Select.Option value="red"><Tag color="red">red</Tag></Select.Option>
            <Select.Option value="volcano"><Tag color="volcano">volcano</Tag></Select.Option>
            <Select.Option value="orange"><Tag color="orange">orange</Tag></Select.Option>
            <Select.Option value="gold"><Tag color="gold">gold</Tag></Select.Option>
            <Select.Option value="lime"><Tag color="lime">lime</Tag></Select.Option>
            <Select.Option value="green"><Tag color="green">green</Tag></Select.Option>
            <Select.Option value="cyan"><Tag color="cyan">cyan</Tag></Select.Option>
            <Select.Option value="blue"><Tag color="blue">blue</Tag></Select.Option>
            <Select.Option value="geekblue"><Tag color="geekblue">geekblue</Tag></Select.Option>
            <Select.Option value="purple"><Tag color="purple">purple</Tag></Select.Option>
            <Select.Option value="grey"><Tag>grey</Tag></Select.Option>
          </Select>
          </Form.Item>}
        // onChange={(e: any) => {
        //   const name = e.target.value
        //   if (name) {
        //     let newId = truncate(camelCase(name).toLowerCase(), { omission: '' })
        //     if (newId !== '') {
        //       form.setFieldsValue({ 'id': newId })
        //     }
        //   }
        // }}
        />
      </Form.Item>
      {/* 
      <Form.Item
        name="segmentId"
        label="Segment ID"
        rules={[
          { required: true, type: 'string', pattern: /^[a-z0-9-]+$/, message: "Only alphanumeric characters allowed!" },
          {
            validator: (_item, value) => {
              if (props.segment) {
                return Promise.resolve(undefined)
              }

              if (find(props.segments, x => x.id === value)) {
                return Promise.reject("This segment ID has already been used.")
              }
              return Promise.resolve(undefined)
            }
          },
        ]}
        shouldUpdate
      >
        <Input disabled={props.segment ? true : false} />
      </Form.Item> */}

      <Form.Item name="parentId" label={t('parent_segment', "Parent segment")} rules={[{ required: false, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
        <SegmentsCascader excludeSegments={props.segment ? [props.segment.id, '_all'] : ['_all']} segments={segments} />
      </Form.Item>

      <Form.Item noStyle name="conditions">
        <SegmentConditions app={props.app} project={props.projectLayout.project} userCustomProperties={props.projectLayout.userCustomProperties} notificationTopics={props.projectLayout.notificationTopics} />
      </Form.Item>

      <div className="ant-drawer-footer">
        <Button style={{ marginRight: 8 }} onClick={props.toggleModal}>{t('cancel', "Cancel")}</Button>
        <Button type="primary" loading={loading} htmlType="submit">{t('confirm', "Confirm")}</Button>
      </div>
    </Form>
  </Drawer>
}
export default SegmentFormButton


