import React, { Component } from 'react'

import { Table, Button, Badge, Modal } from 'antd';
import t from '../../utils/translate'
import QS from 'qs'
import _ from 'lodash'
import Formatters from '../../utils/formatters'
import Moment from 'moment-timezone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ShowPayment from './_showPayment'
import AppLayout from 'components/app/layout';

const defaultParams = {
  limit: 30,
  skip: 0,
  sortKey: 'createdAt',
  sortOrder: 'descend',
}

class PaymentsList extends Component<any, any> {
  _isMounted = false;

  constructor(props: any) {
    super(props)

    this.state = {
      loading: false,
      payments: [],
      paymentsCount: 0,
    }

    this.fetchPayments = this.fetchPayments.bind(this)
    this.handleTableChange = this.handleTableChange.bind(this)
    this.resetFilters = this.resetFilters.bind(this)
    this.showPayment = this.showPayment.bind(this)
    this.markAsPaidConfirm = this.markAsPaidConfirm.bind(this)
    this.markAsPaidRequest = this.markAsPaidRequest.bind(this)
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchPayments()

    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Transaction queries (project: ' + this.props.projectLayout.project.id + ')',
        page: this.props.location.pathname,
        props: {
          orgId: this.props.projectLayout.project.organizationId,
          projectId: this.props.projectLayout.project.id
        }
      })
      window.cmAgent.dispatch()
    }
  }

  componentDidUpdate(prevProps: any) {
    // check url params have changed
    if (prevProps.location.search !== this.props.location.search) {
      this.fetchPayments()
    }
  }

  showPayment(paymentId: string) {
    let params = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })
    params['showPayment'] = paymentId
    this.props.history.push(window.location.pathname + '?' + QS.stringify(params))
  }

  markAsPaidConfirm(paymentId: string) {
    Modal.confirm({
      title: t('mark_as_paid', "Mark as paid"),
      content: t('mark_as_paid_content', "The payment request “{paymentId}“ will now be considered as paid, would you like to continue?", { paymentId: paymentId }),
      onOk: this.markAsPaidRequest.bind(null, paymentId),
      okText: t('confirm', "Confirm"),
      cancelText: t('cancel', "Cancel"),
      onCancel() { },
    })
  }

  markAsPaidRequest(paymentId: string) {
    return new Promise((resolve, reject) => {

      this.props.app.ajaxRequest({
        method: 'post',
        url: '/partnershipPayments.markAsPaid',
        data: {
          projectId: this.props.projectLayout.project.id,
          partnershipPaymentId: paymentId
        }
      }, (errorMessage: any, response: any) => {

        if (errorMessage) {
          this.props.app.addMessage('error', errorMessage)
          return reject(errorMessage)
        }

        this.props.app.addMessage('success', t('payment_request_paid', 'The payment request is now considered paid!'))

        // console.log('res', response.data);

        if (this._isMounted) {
          this.setState({ loading: false })
          this.fetchPayments()
        }

        resolve(undefined)
      })

    }).catch((e: any) => console.log(e));
  }

  handleTableChange(pagination: any, filters: any, sorter: any) {
    // console.log('filters', filters);
    // console.log('pagination', pagination);
    // console.log('sorter', sorter);
    const params: any = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })

    const newParams = _.assign({
      limit: params.limit || defaultParams.limit,
      skip: params.skip || defaultParams.skip,
      sortKey: params.sortKey || defaultParams.sortKey,
      sortOrder: params.sortOrder || defaultParams.sortOrder,
      // partnershipId: params.partnershipId || undefined,
      status: params.status || undefined,
    }, {
      limit: pagination.pageSize,
      skip: (pagination.current * pagination.pageSize) - pagination.pageSize,
      sortKey: sorter.columnKey,
      sortOrder: sorter.order,
      partnershipId: _.get(filters, 'partnershipId[0]'),
      status: _.get(filters, 'status[0]'),
    })

    this.updateQuery(newParams)

    // this.props.history.push('/organizations/'+this.props.organizationLayout.organization.id+'/projects/'+this.props.projectLayout.project.id+'/payments?'+QS.stringify(newParams, { indices: false }));
  }

  resetFilters() {
    this.props.history.push('/organizations/' + this.props.organizationLayout.organization.id + '/projects/' + this.props.projectLayout.project.id + '/payments?' + QS.stringify(defaultParams, { indices: false }));
  }

  updateQuery(update: any) {
    const params: any = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })

    let query = {
      limit: params.limit || defaultParams.limit,
      skip: params.skip || defaultParams.skip,
      sortKey: params.sortKey || defaultParams.sortKey,
      sortOrder: params.sortOrder || defaultParams.sortOrder,
      partnershipId: params.partnershipId || undefined,
      // commissionGroup: params.commissionGroup || undefined,
      status: params.status || undefined,
    }

    this.props.history.push('/organizations/' + this.props.organizationLayout.organization.id + '/projects/' + this.props.projectLayout.project.id + '/payments?' + QS.stringify(_.assign(query, update), { indices: false }));
  }

  fetchPayments() {

    if (this.state.loading === true) {
      return
    }

    this.setState({ loading: true })

    const params: any = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })

    let query: any = {
      projectId: this.props.projectLayout.project.id,
      limit: params.limit || defaultParams.limit,
      skip: params.skip || defaultParams.skip,
      sortKey: params.sortKey || defaultParams.sortKey,
      sortOrder: params.sortOrder || defaultParams.sortOrder,
    }

    if (params.partnershipId) {
      query.partnershipId = params.partnershipId
    }

    if (params.status) {
      query.status = params.status
    }

    this.props.app.ajaxRequest({
      method: 'get',
      url: '/partnershipPayments.list',
      params: query
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        // this.setState({loading: false})
        return
      }

      // console.log('res', response.data);

      if (this._isMounted) {
        this.setState({
          payments: response.data.partnershipPayments,
          paymentsCount: response.data.partnershipPaymentsCount,
          loading: false
        })
      }
    })
  }

  render() {

    const params: any = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })

    return <AppLayout currentProject={this.props.projectLayout.project} currentOrganization={this.props.organizationLayout.organization} admin={this.props.app.state.admin} firebaseUser={this.props.app.state.firebaseUser} projects={this.props.organizationLayout.projects}>
      <h1>{t('payments', "Payments")}</h1>

      {params.showPayment && params.showPayment !== '' && <ShowPayment
        app={this.props.app}
        paymentId={params.showPayment}
        organizationLayout={this.props.organizationLayout}
        projectLayout={this.props.projectLayout}
        history={this.props.history}
        location={this.props.location}
      />}

      <Table
        dataSource={this.state.payments}
        rowKey="id"
        className="edge-table block"
        onChange={this.handleTableChange}
        loading={this.state.loading}
        pagination={{
          position: ['bottomCenter'],
          total: this.state.paymentsCount
        }}
        columns={[
          {
            title: 'ID',
            key: 'id',
            render: (record: any) => {
              return <small>{record.id}</small>
            }
          },
          {
            title: t('media_partner', "Media partner"),
            key: 'mediaPartner',
            render: (record: any) => record.partnershipId
          },
          {
            title: 'CPC',
            key: 'cpc',
            render: (record: any) => <span className="color-green">{Formatters.currency(record.currency, record.commissionsCPC || 0)}</span>
          },
          {
            title: 'CPA / CPL',
            key: 'cpa',
            render: (record: any) => <span className="color-green">{Formatters.currency(record.currency, record.commissionsConversions || 0)}</span>
          },
          {
            title: 'Total',
            key: 'total',
            render: (record: any) => <span className="color-kpi-purple">{Formatters.currency(record.currency, record.commissionsAmount || 0)}</span>
          },
          {
            title: 'Date',
            key: 'date',
            render: (record: any) => <small>{Moment(record.createdAt).format('llll')}</small>
          },

          {
            title: t('status', "Status"),
            key: 'status',
            render: (record: any) => {
              if (record.status === 'paid') {
                return <Badge color="green" text={t('paid', "Paid")} />
              }
              if (record.status === 'pending') {
                return <Badge color="orange" text={t('pending', "Pending")} />
              }
              if (record.status === 'init') {
                return <Badge status="default" text={t('initializing', "Initializing")} />
              }
            }
          },
          {
            key: 'actions',
            title: '',
            className: 'text-right',
            render: (record: any) => <div>
              {record.status === 'pending' && <Button size="small" type="primary" className="margin-r-s" onClick={this.markAsPaidConfirm.bind(null, record.id)}>{t('mark_as_paid', 'Mark as paid')}</Button>}
              <Button type="ghost" size="small" onClick={this.showPayment.bind(null, record.id)}><FontAwesomeIcon icon="search" style={{ color: 'rgba(0,0,0, 0.5)' }} /></Button>
            </div>
          },
        ]}
      />

    </AppLayout>
  }
}

export default PaymentsList