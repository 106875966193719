import React, { Component } from 'react'

import t from '../../utils/translate'
import {
  CaretRightOutlined,
  LeftOutlined,
  PauseOutlined,
  RightOutlined,
  CaretRightFilled,
  RedoOutlined
} from '@ant-design/icons'
import { Table, Button, Badge, Tooltip, Spin, Popconfirm } from 'antd'
import Moment from 'moment-timezone'
import _ from 'lodash'
import Prism from 'prismjs'
import 'prismjs/components/prism-json'
import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  startAfter,
  endBefore,
  limit
} from 'firebase/firestore'
import AppLayout from 'components/app/layout'

const LimitResults = 16

class ExpandedLog extends Component<any, any> {
  _isMounted = false

  constructor(props: any) {
    super(props)

    this.state = {
      loading: true,
      headers: {},
      url: '',
      body: {}
    }

    this.decryptLog = this.decryptLog.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
    this.decryptLog()
  }

  decryptLog() {
    this.props.app.ajaxRequest(
      {
        method: 'get',
        url: '/logs.decrypt',
        params: {
          projectId: this.props.projectLayout.project.id,
          logId: this.props.record.id
        }
      },
      (errorMessage: any, response: any) => {
        if (errorMessage) {
          this.props.app.addMessage('error', errorMessage)
          // this.setState({loading: false})
          return
        }

        // console.log('res', response.data);

        if (this._isMounted) {
          const state = {
            loading: false,
            headers: response.data.log.headers,
            url: response.data.log.url,
            body: {}
          }

          if (response.data.log.body !== '') {
            try {
              state.body = JSON.parse(response.data.log.body)
            } catch (e: any) {
              console.error(e)
            }
          }

          this.setState(state)
        }
      }
    )
  }

  render() {
    if (this.state.loading === true) {
      return <Spin />
    }

    let jsonBody = ''
    let jsonHeaders = ''

    // console.log('state', this.state);
    try {
      jsonBody = Prism.highlight(
        JSON.stringify(this.state.body, null, 2),
        Prism.languages['json'],
        'json'
      )
    } catch (e: any) {
      console.error(e)
    }
    try {
      jsonHeaders = Prism.highlight(
        JSON.stringify(this.state.headers, null, 2),
        Prism.languages['json'],
        'json'
      )
    } catch (e: any) {
      console.error(e)
    }

    return (
      <div>
        <p>
          <b>IP:</b> {this.props.record.ip}
        </p>
        <p>
          <b>URL:</b> {this.state.url}
        </p>

        <div>
          <p>
            <b>Headers:</b>
          </p>
          <pre className="language-json size-12">
            <code dangerouslySetInnerHTML={{ __html: jsonHeaders }} />
          </pre>
        </div>
        <div>
          <p>
            <b>Body:</b>
          </p>
          <pre className="language-json size-12">
            <code dangerouslySetInnerHTML={{ __html: jsonBody }} />
          </pre>
        </div>
      </div>
    )
  }
}

class LogsList extends Component<any, any> {
  _isMounted = false
  unsubscribe: any = undefined
  query: any = undefined

  constructor(props: any) {
    super(props)

    this.state = {
      playing: true,
      loading: true,
      logs: [],
      isAtFirst: true,
      hasMore: false
    }

    this.fetchLogs = this.fetchLogs.bind(this)
    this.playLogs = this.playLogs.bind(this)
    this.pauseLogs = this.pauseLogs.bind(this)
    this.nextPage = this.nextPage.bind(this)
    this.previousPage = this.previousPage.bind(this)
    this.proccessHit = this.proccessHit.bind(this)
    this.requeueHit = this.requeueHit.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
    this.fetchLogs()

    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Logs (project: ' + this.props.projectLayout.project.id + ')',
        page: this.props.location.pathname,
        props: {
          orgId: this.props.projectLayout.project.organizationId,
          projectId: this.props.projectLayout.project.id
        }
      })
      window.cmAgent.dispatch()
    }
  }

  componentDidUpdate(prevProps: any) {
    // check url params have changed
    if (prevProps.location.search !== this.props.location.search) {
      this.fetchLogs()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  playLogs() {
    this.setState({ playing: true, isAtFirst: true })
    if (this.unsubscribe) this.unsubscribe()
    this.fetchLogs()
  }

  pauseLogs() {
    if (this.unsubscribe) this.unsubscribe()
    this.setState({ playing: false })
  }

  fetchLogs(opts?: any) {
    this.setState({ loading: true })

    window.setTimeout(() => {
      if (this.unsubscribe) this.unsubscribe()
    }, 30000)

    const db = getFirestore(this.props.app.firebaseApp)

    const queryConstraints = [
      where('oId', '==', this.props.projectLayout.project.organizationId),
      where('pId', '==', this.props.projectLayout.project.id),
      orderBy('at', 'desc')
    ]

    if (opts && opts.endBefore) {
      queryConstraints.push(endBefore(opts.endBefore))
    }
    if (opts && opts.startAfter) {
      queryConstraints.push(startAfter(opts.startAfter))
    }

    queryConstraints.push(limit(LimitResults))

    var firelogCollection = 'firelog'
    if (new Date().getTime() > 1664834400 * 1000) {
      firelogCollection = 'firelog2'
    }
    if (new Date().getTime() > 1685957408 * 1000) {
      firelogCollection = 'firelog3'
    }

    this.query = query(collection(db, firelogCollection), ...queryConstraints)

    this.unsubscribe = onSnapshot(this.query, (querySnapshot: any) => {
      const logs: any = []

      querySnapshot.forEach((doc: any) => {
        logs.push(doc)
      })

      if (this._isMounted) {
        const state: any = {
          loading: false
        }

        if (opts && opts.endBefore) {
          state.hasMore = true

          if (logs.length === 0) {
            state.isAtFirst = true
          } else {
            state.logs = logs.length === LimitResults ? logs.slice(0, -1) : logs // remove the last element
          }
        } else {
          state.logs = logs.length === LimitResults ? logs.slice(0, -1) : logs // remove the last element
          state.hasMore = logs.length === LimitResults
        }

        this.setState(state)
      }
    })
  }

  previousPage() {
    const firstLog = this.state.logs[0]

    if (firstLog) {
      if (this.unsubscribe) {
        this.unsubscribe()
      }
      this.setState({ playing: false })
      this.fetchLogs({ endBefore: firstLog })
    }
  }

  nextPage() {
    const lastLog = this.state.logs[this.state.logs.length - 1]

    if (lastLog) {
      if (this.unsubscribe) {
        this.unsubscribe()
      }
      this.setState({ playing: false, isAtFirst: false })
      this.fetchLogs({ startAfter: lastLog })
    }
  }

  proccessHit(hitId: string) {
    if (this.state.loading === true) {
      return
    }

    this.setState({ loading: true })

    this.props.app.ajaxRequest(
      {
        method: 'post',
        url: '/logs.reprocess',
        data: {
          projectId: this.props.projectLayout.project.id,
          hitId: hitId
        }
      },
      (errorMessage: any, response: any) => {
        if (errorMessage) {
          this.props.app.addMessage('error', errorMessage)
          this.setState({ loading: false })
          return
        }

        this.props.app.addMessage('success', 'The hit has been reprocessed!')
        this.setState({ loading: false })

        const hit = _.get(response, 'data.hit')
        if (hit) {
          this.state.logs.forEach((log: any) => {
            if (log.id === hit.id) {
              log = hit
            }
          })

          this.setState({ logs: this.state.logs })
        }
        console.log('res', response.data)
      }
    )
  }

  requeueHit(hitId: string) {
    if (this.state.loading === true) {
      return
    }

    this.setState({ loading: true })

    this.props.app.ajaxRequest(
      {
        method: 'post',
        url: '/logs.requeue',
        data: {
          projectId: this.props.projectLayout.project.id,
          hitId: hitId
        }
      },
      (errorMessage: any, response: any) => {
        if (errorMessage) {
          this.props.app.addMessage('error', errorMessage)
          this.setState({ loading: false })
          return
        }

        this.props.app.addMessage('success', 'The hit has been requeued!')
        this.setState({ loading: false })

        const hit = _.get(response, 'data.hit')
        if (hit) {
          this.state.logs.forEach((log: any) => {
            if (log.id === hit.id) {
              log = hit
            }
          })

          this.setState({ logs: this.state.logs })
        }
        console.log('res', response.data)
      }
    )
  }

  render() {
    // console.log('state', this.state);
    const adminsMap = _.keyBy(this.props.organizationLayout.organization.admins, 'id')
    const logs = this.state.logs.map((d: any) => d.data())

    return (
      <AppLayout
        currentProject={this.props.projectLayout.project}
        currentOrganization={this.props.organizationLayout.organization}
        admin={this.props.app.state.admin}
        firebaseUser={this.props.app.state.firebaseUser}
        projects={this.props.organizationLayout.projects}
      >
        <h1>
          {t('logs', 'Logs')}
          <Button.Group className="actions">
            {this.state.playing && (
              <Button type="ghost" onClick={this.pauseLogs}>
                <PauseOutlined />
              </Button>
            )}
            {!this.state.playing && (
              <Button type="ghost" onClick={this.playLogs}>
                <CaretRightOutlined />
              </Button>
            )}
          </Button.Group>
        </h1>

        <Table
          dataSource={logs}
          rowKey="id"
          pagination={false}
          className="edge-table block"
          loading={this.state.loading}
          size="middle"
          expandRowByClick={true}
          expandedRowRender={(record) => <ExpandedLog {...this.props} record={record} />}
          onExpand={this.pauseLogs}
          columns={[
            {
              key: 'id',
              title: 'ID',
              render: (record: any) => (
                <Tooltip title={record.id}>
                  {_.truncate(record.id, { length: 20, omission: '...' })}
                </Tooltip>
              )
            },
            {
              key: 'source',
              title: t('source', 'Source'),
              render: (record: any) =>
                record.src
                  .replace(/js/, 'Web agent')
                  .replace(/api/, 'API')
                  .replace(/task/, 'Task')
                  .replace(/webhook/, 'Webhook')
                  .replace(/amp/, 'AMP')
            },
            {
              key: 'kind',
              title: t('kind', 'Kind'),
              render: (record: any) => record.kind
            },
            {
              key: 'admin',
              title: 'Admin',
              render: (record: any) => (
                <div>
                  {record.adId && (
                    <span>
                      {adminsMap[record.adId].firstName + ' ' + adminsMap[record.adId].lastName}
                    </span>
                  )}
                </div>
              )
            },
            {
              key: 'status',
              title: t('status', 'Status'),
              render: (record: any) => {
                let badge = (
                  <div>
                    <Badge status="error" text={t('failed', 'Failed')} />
                    {record.errors && record.errors.length > 0 && (
                      <div className="padding-t-m color-red size-12">
                        {record.errors.map((e: any, i: number) => (
                          <div key={i}>{e}</div>
                        ))}
                      </div>
                    )}
                  </div>
                )

                switch (record.st) {
                  case 0:
                    badge = <Badge status="default" text={t('queued', 'Queued')} />
                    break
                  case 1:
                    badge = <Badge status="success" text={t('done', 'Done')} />
                    break
                  default:
                }

                return badge
              }
            },
            {
              key: 'createdAt',
              title: t('created_at', 'Created at'),
              render: (record: any) => (
                <span className="size-12">{Moment(record.at.toDate()).fromNow()}</span>
              )
            },
            {
              key: 'actions',
              title: '',
              render: (record: any) => {
                if (record.st !== 1 && (record.src === 'js' || record.src === 'webhook')) {
                  return (
                    <>
                      <Popconfirm
                        title="Do you really want to process this hit now?"
                        onConfirm={this.proccessHit.bind(null, record.id)}
                      >
                        <Button icon={<CaretRightFilled />} />
                      </Popconfirm>
                      <Popconfirm
                        title="Do you really want to requeue this hit now?"
                        onConfirm={this.requeueHit.bind(null, record.id)}
                      >
                        <Button icon={<RedoOutlined />} />
                      </Popconfirm>
                    </>
                  )
                }
              }
            }
          ]}
        />
        <div className="pull-right margin-a-l">
          {this.state.isAtFirst === false && (
            <Button icon={<LeftOutlined />} className="margin-r-m" onClick={this.previousPage} />
          )}
          {this.state.hasMore && <Button icon={<RightOutlined />} onClick={this.nextPage} />}
        </div>
      </AppLayout>
    )
  }
}

export default LogsList
