import React from 'react'
import Moment from 'moment-timezone'
import N from 'numeral'
import {
  Chart,
  Axis,
  Legend,
  Tooltip,
  Interval,
} from "bizcharts"

const TimelineGraph  = (props: any) => {

  // generate date data
  const days = Moment.duration(props.dateTo.diff(props.dateFrom)).days()
  let dataWithDays = []

  // push an empty object for dates without events to build a timeCat graph
  for (let i = 0; i <= days; i++) {
    const date = props.dateFrom.clone().add(i, 'days').format('YYYY-M-D')
    if (!props.data.find((d: any) => d.date === date)) {
      dataWithDays.push({
        date: date,
        count: 0,
        event: ''
      })
    }
  }

  dataWithDays = dataWithDays.concat(props.data)
  // console.log('data', props.data);
  // console.log('dataWithDays', dataWithDays);
  // console.log('days', days);

  return <Chart
    height={props.height || 150}
    data={dataWithDays}
    padding={[20,30,30,30]}
    scale={{
      date: {
        type: 'timeCat',
        nice: true,
      },
      count: {
        tickCount: 3
      }
    }}
    animate
    autoFit
    tooltip="event*count"
  >
    <Legend visible={false} />
    <Axis name="date" position="bottom" title={null} label={{
      style: {
        fill: '#78909C',
        fontSize: 12
      },
      formatter: (text) => Moment(text, 'YYYY-MM-DD').format('MMM D')
    }} 
    line={{
      style: {
        lineWidth: 1,
        stroke: '#CFD8DC',
      }
    }} />
    <Axis name="count" title={null} position="left" line={null} label={{
      offset: 3,
      style: {
        fill: '#B0BEC5',
        fontSize: 10,
      },
      formatter: (value: any) => N(value).format(props.valueFormat || '0,0')
    }} />
    <Tooltip shared={true} />
    <Interval adjust="stack"
      position="date*count"
      tooltip={['count*event', (count: any, event: any) => {
        if (!event) return {}
        return {
          name: event,
          value: count
        }
      }]}
      color={['event', (value: any) => {
        switch(value) {
          case 'enterSegment': return '#52c41a'
          case 'exitSegment': return '#FF5722'
          case 'signup': return '#52c41a'
          case 'session': return '#d9d9d9' // '#13c2c2'
          case 'pageview': return '#1890ff'
          case 'event': return '#722ed1'
          case 'addUserToCompany': return '#722ed1'
          case 'order': return '#fa541c'
          case 'cart': return '#eb2f96'
          case 'wishList': return '#eb2f96'
          case 'workflow': return '#00BCD4'
          case 'notification': return '#722ed1'
          case 'notificationTopic': return '#722ed1'
          case 'abandonedCart': return '#FF5722'
          default: return 'black'
        }
      }]}
    />
  </Chart>
}

export default TimelineGraph