import React, { useState } from 'react'
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
} from 'antd';
import t from '../../utils/translate'
import Currencies from '../../utils/currencies'
import Countries from '../../utils/countries'
import _ from 'lodash'

const maxIdLength = 15


export function OnSubmit(form: any, app: any, loading: boolean, setLoading: any, kind: string, onComplete: any, cb: any) {
  // console.log('values', values);

  if (loading) return

  setLoading(true)

  form.validateFields().then((values: any) => {
    values.kind = kind
    
    app.ajaxRequest({
      method: 'post',
      url: '/organizations.create',
      data: values
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        app.addMessage('error', errorMessage)
        setLoading(false)
        return
      }

      app.addMessage('success', t('organization_created', "Your organization has successfully been created!"))


      let updatedOrganizations = _.filter(app.state.organizations, (org) => org.id !== response.data.organization.id);
      updatedOrganizations.push(response.data.organization);
      app.setState({ organizations: updatedOrganizations });

      if (window.cmAgent) {
        window.cmAgent.addUserToCompany(response.data.organization.id, { role: 'admin' })
        window.cmAgent.updateCompanyProfile({
          set: {
            name: response.data.organization.name,
            currency: response.data.organization.currency,
            attributes: {
              plan: response.data.organization.plan,
              kind: response.data.organization.kind,
              stripeId: response.data.organization.stripeId,
            }
          }
        })
        window.cmAgent.event({
          label: 'createOrganization',
          props: {
            orgId: response.data.organization.id,
          }
        })
        window.cmAgent.dispatch()
      }

      if (onComplete) {
        onComplete(response.data.organization)
      }

      form.resetFields()
      if (cb) cb()
    })
  }).catch((info: any) => { console.log('Validate Failed:', info); });
}

export function CreateForm({ form, showSubmit, app, loading, setLoading, kind, onComplete }: any) {
  return <Form
    form={form}
    layout="vertical"
    name="form_in_modal"
  >
    <Form.Item
      name="name"
      label={t('organization_name', "Organization name")}
      rules={[{ required: true, message: t('field_required', "This field is required!") }]}
    >
      <Input size="large" placeholder="i.e: Acme Inc" onChange={(e: any) => {
        const name = e.target.value
        if (name) {
          let newId = _.truncate(_.camelCase(name).toLowerCase(), { length: maxIdLength, omission: '' })
          if (newId !== '') {
            form.setFieldsValue({ 'id': newId })
          }
        }
      }} />
    </Form.Item>

    <Form.Item
      name="id"
      label={t('organization_id', "Organization ID")}
      rules={[{ required: true, type: 'string', max: maxIdLength, pattern: /^[a-z0-9]+$/, message: t('only_x_alphanumeric_characters', "Only {x} alphanumeric characters allowed!", { x: maxIdLength }) }]}
      shouldUpdate
    >
      <Input size="large" placeholder="i.e: acmeinc" />
    </Form.Item>

    <Form.Item
      name="currency"
      label={t('currency', "Currency")}
      rules={[{ required: true }]}
    >
      <Select
        showSearch
        placeholder={t('select_a_currency', "Select a currency")}
      >
        {Currencies.map((c) => <Select.Option key={c.code} value={c.code}>{c.code + ' - ' + c.currency}</Select.Option>)}
      </Select>
    </Form.Item>

    <Form.Item
      name="country"
      label={t('country', "Country")}
      rules={[{ required: true }]}
    >
      <Select
        showSearch
        placeholder={t('select_a_country', "Select a country")}
      >
        {Countries.map((c) => <Select.Option key={c.code} value={c.code}>{c.code + ' - ' + c.name}</Select.Option>)}
      </Select>
    </Form.Item>

    {showSubmit && <Button loading={loading} type="primary" size="large" onClick={OnSubmit.bind(null, form, app, loading, setLoading, kind, onComplete, null)} block>{t('confirm', 'Confirm')}</Button>}
  </Form>
}
const TheButton = ({ btnType, btnSize, btnContent, app, onComplete, kind }: any) => {
  const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const onClicked = () => {
    setModalVisible(true)
  }

  return <React.Fragment>
    <Button type={btnType || 'primary'} size={btnSize || 'small'} onClick={onClicked}>{btnContent}</Button>

    <Modal
      visible={modalVisible}
      title={t('create_an_organization', "Create a new organization")}
      okText={t('confirm', "Confirm")}
      okButtonProps={{ loading: loading }}
      cancelButtonProps={{ loading: loading }}
      cancelText={t('cancel', "Cancel")}
      onCancel={() => { setModalVisible(false) }}
      onOk={() => {
        OnSubmit(form, app, loading, setLoading, kind, onComplete, () => {
          setModalVisible(false)
        })
      }}
    >
      <CreateForm form={form} showSubmit={false} />
    </Modal>
  </React.Fragment>
}

export default TheButton