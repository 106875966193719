import React, { useState } from 'react'

import t from '../../utils/translate'
import { Button, Input, Select, Row, Col, Radio, Switch, Divider, Form } from 'antd';
import _ from 'lodash'
import Obfuscator from '../../utils/obfuscator'
import Languages from '../../utils/languages'
import Moment from 'moment-timezone'
import Countries from '../../utils/countries'

const ProjectSettingsForm = (props: any) => {

  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const onFinish = (values: any) => {

    if (loading === true) {
      return
    }
    else {
      setLoading(true)
    }

    values.projectId = props.projectLayout.project.id
    values.sessionTimeout = parseInt(values.sessionTimeout, 10)
    values.dataRetention = parseInt(values.dataRetention, 10)
    values.bounceThreshold = parseInt(values.bounceThreshold, 10)
    values.commissionsApprovalPeriod = parseInt(values.commissionsApprovalPeriod, 10)

    props.app.ajaxRequest({
      method: 'post',
      url: '/projects.setSettings',
      data: values
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        props.app.addMessage('error', errorMessage)
        setLoading(false)
        return
      }

      let projects = _.filter(props.organizationLayout.projects, (p: any) => p.id !== response.data.project.id)
      projects.push(response.data.project)
      props.organizationLayout.setProjects(projects)

      props.app.addMessage('success', t('project_settings_updated', "The project settings have successfully been updated!"))

      setLoading(false)

      if (window.cmAgent) {
        window.cmAgent.event({
          label: 'updateProjectSettings',
          props: {
            orgId: props.organizationLayout.organization.id,
            projectId: props.projectLayout.project.id,
          }
        })
        window.cmAgent.dispatch()
      }

      if (props.onComplete) {
        props.onComplete()
      }
    })
  }

  const initialValues = {
    defaultUserTimezone: props.projectLayout.project.defaultUserTimezone,
    defaultUserLanguage: props.projectLayout.project.defaultUserLanguage,
    defaultUserCountry: props.projectLayout.project.defaultUserCountry,
    sessionTimeout: 1800,
    bounceThreshold: 20,
    brandKeywordsAsDirect: true,
    brandKeywords: [],
    signUserIds: 'none',
    commissionsApprovalPeriod: 30,
  }

  return <div className="block padding-t-m padding-b-l">
    <Form
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 14 }}
      initialValues={initialValues}
      layout="horizontal"
      onFinish={onFinish}
    >

      <Divider plain>{t('general_settings', "General settings")}</Divider>

      {/*<Form.Item name="xxx" label={t('data_retention', "Data retention")} extra={t('data_retention_help', "Your users activity will be deleted after x days.")}>
        {props.form.getFieldDecorator('dataRetention', {
          rules: [{ required: true, type: 'integer', transform: (value: any) => parseInt(value, 10), message: t('field_required', "This field is required!") }],
          initialValue: _.get(props.projectLayout.project, 'dataRetention', 730)
        })(
          <Input type="number" placeholder="1800" addonAfter="days" style={{width: "150px"}} />
        )}
      </Form.Item>*/}

      <Form.Item name="defaultUserTimezone" label={t('default_user_timezone', "Default user timezone")} rules={[{ required: true, type: 'string', message: t('field_required', "This field is required!") }]}>
        <Select
          placeholder={t('select_a_value', "Select a value")}
          allowClear={false}
          showSearch={true}
          filterOption={(searchText: any, option: any) => {
            return searchText !== '' && option.key.toLowerCase().includes(searchText.toLowerCase())
          }}>
          {Moment.tz.names().map((timezone) => {
            return <Select.Option key={timezone} value={timezone}>{timezone}</Select.Option>
          })}
        </Select>
      </Form.Item>

      <Form.Item name="defaultUserLanguage" label={t('default_user_language', "Default user language")} rules={[{ required: true, type: 'string', message: t('field_required', "This field is required!") }]}>
        <Select
          placeholder={t('select_a_value', "Select a value")}
          allowClear={false}
          showSearch={true}
          filterOption={(searchText: any, option: any) => {
            return searchText !== '' && option.key.toLowerCase().includes(searchText.toLowerCase())
          }}>
          {Languages.map((language) => {
            return <Select.Option key={language.alpha2} value={language.alpha2}>{language.alpha2 + ' - ' + language.name}</Select.Option>
          })}
        </Select>
      </Form.Item>

      <Form.Item name="defaultUserCountry" label={t('default_user_country', "Default user country")} rules={[{ required: true, type: 'string', message: t('field_required', "This field is required!") }]}>
        <Select
          showSearch
          placeholder={t('select_a_country', "Select a country")}
          optionFilterProp="children"
          filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {Countries.map((c) => <Select.Option key={c.code} value={c.code}>{c.code + ' - ' + c.name}</Select.Option>)}
        </Select>
      </Form.Item>

      <Divider plain>{t('web_tracking_settings', "Web tracking settings")}</Divider>

      <Form.Item name="sessionTimeout" label={t('session_timeout', "Session timeout")} extra={t('session_timeout_help', "Everytime you have a new visit on your website/app, a new session starts. By default, a session expires after 30 minutes (= 1800 secs) of inactivity, or if the visitor comes back from another source of traffic.")} rules={[{ required: true, type: 'integer', transform: (value: any) => parseInt(value, 10), message: t('field_required', "This field is required!") }]}>
        <Input type="number" placeholder="1800" addonAfter="secs" style={{ width: "150px" }} />
      </Form.Item>

      <Form.Item name="bounceThreshold" label={t('bounce_threshold', "Bounce threshold")} extra={t('bounce_threshold_help', "A bounce won't be recorded if the visitor spends more than x secs on a page.")} rules={[{ required: true, type: 'integer', transform: (value: any) => parseInt(value, 10), message: t('field_required', "This field is required!") }]}>
        <Input type="number" placeholder="20" addonAfter="secs" style={{ width: "150px" }} />
      </Form.Item>

      <Form.Item valuePropName="checked" name="brandKeywordsAsDirect" label={t('brand_keywords_as_direct', "Brand keywords as Direct Traffic")} extra={t('consider_brand_keyword_as_direct_info', "When your customers already know you and type your brand in a search engine to visit your website (i.e: Adwords...), we recommend you to consider those sessions as Direct Traffic.")} rules={[{ type: 'boolean', required: false, message: t('field_required', "This field is required!") }]}>
        <Switch />
      </Form.Item>

      <Form.Item name="brandKeywords" label={t('brand_keywords', "Brand keywords")} extra={t('brand_keywords_help', "Your brand keywords are used by attribution models to detect Direct Traffic coming from SEO. Press enter to insert a new value.")} rules={[{ required: false, type: "array", message: "The value should be an array of strings." }]}>
        <Select mode="tags" placeholder={t('enter_brank_keywords', "Enter your brand keywords")} dropdownStyle={{ display: 'none' }}>
          {props.projectLayout.project.brandKeywords?.map((kw: any) => <Select.Option key={kw} value={kw}>{kw}</Select.Option>)}
        </Select>
      </Form.Item>

      <Form.Item name="signUserIds" label={t('secure_web_hits', "Secure web hits")} help={t('secure_web_hits_with_hmac_info', "Sign the user IDs (with HMAC256) sent by the web agent to avoid malicious data corruption.")} rules={[{ required: true, type: 'string', message: t('field_required', "This field is required!") }]}>
        <Radio.Group style={{ width: '100%' }}>
          <Radio.Button style={{ width: '33.33%', textAlign: 'center' }} value="none">{t('none', "None")}</Radio.Button>
          <Radio.Button style={{ width: '33.33%', textAlign: 'center' }} value="authenticated">{t('authenticated_users', "Authenticated users")}</Radio.Button>
          <Radio.Button style={{ width: '33.33%', textAlign: 'center' }} value="all">{t('all_users', "All users")}</Radio.Button>
        </Radio.Group>
      </Form.Item>

      <Form.Item noStyle shouldUpdate>
        {(funcs) => {
          if (funcs.getFieldValue('signUserIds') !== 'none') {
            return <Form.Item label={t('secret_signing_key', "Secret signing key")}>
              <Obfuscator>{props.projectLayout.project.secretKeys.find((k: any) => !k.deletedAt).key}</Obfuscator>
            </Form.Item>
          }
        }}
      </Form.Item>

      <Divider plain>{t('partnerships_settings', "Partnerships settings")}</Divider>

      {/*<Form.Item name="xxx" label={t('default_media_partner_weight', "Default media partner weight")}>
        {props.form.getFieldDecorator('defaultMediaPartnerWeight', {
          rules: [{ required: true, type: 'number', min: 1, max: 10, message: t('field_required', "This field is required!") }],
          initialValue: _.get(props.projectLayout.project, 'defaultMediaPartnerWeight', 5)
        })(
          <Slider marks={marks} dots={true} min={1} max={10} step={1} />
        )}
      </Form.Item>

      <Form.Item name="xxx" label={t('advertiser_own_weight', "Advertiser own weight")}>
        {props.form.getFieldDecorator('advertiserWeight', {
          rules: [{ required: true, type: 'number', min: 1, max: 10, message: t('field_required', "This field is required!") }],
          initialValue: _.get(props.projectLayout.project, 'advertiserWeight', 5)
        })(
          <Slider marks={marks} dots={true} min={1} max={10} step={1} />
        )}
      </Form.Item>*/}

      <Form.Item name="commissionsApprovalPeriod" label={t('commissions_approval_period', "Commissions approval period")} rules={[{ required: true, type: 'number', transform: (value: any) => parseInt(value, 10), min: 0, message: t('field_required', "This field is required!") }]}>
        <Input type="number" addonAfter={t('days', "days")} style={{ width: "150px" }} />
      </Form.Item>

      <Row>
        <Col xs={22} sm={22} className="text-right">
          <Button type="primary" loading={loading} htmlType="submit">{t('confirm', "Confirm")}</Button>
        </Col>
      </Row>
    </Form>
  </div>
}

export default ProjectSettingsForm