import { forEach, size, replace} from 'lodash'

const ts = (key: string, defaultMessage: string, data?: any) => {

    let localizedString = (window.app && window.app[key]) ? window.app[key] : defaultMessage

    // has placeholders
    if (data && size(data) > 0) {

        forEach(data, (value: string, key: string) => {

            localizedString = replace(localizedString, '{'+key+'}', value)
        })
    }

    return localizedString
}

export default ts