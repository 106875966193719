import React, { Component } from 'react'

// import { 
//   Table,
//   Button,
//   Tabs,
//   Icon,
//   Popconfirm 
// } from 'antd';
import t from '../../utils/translate'
// import _ from 'lodash'
// import { Link } from 'react-router-dom'
// import Moment from 'moment-timezone'
// import QS from 'qs'
import ScenarioButton from '../workflows/_drawer'


class Test extends Component<any, any> {
  _isMounted = false;

  constructor(props: any) {
    super(props)

    this.state = {
      // data: [],
      // loading: false,
    }

  }

  render() {
    return <div className="layout-content">

      <h1>TEST</h1>

      <ScenarioButton 
        size="large"
        type="primary"
        title={t('new_scenario', "New scenario")}
        app={this.props.app}
        project={this.props.projectLayout.project}
        >
        {t('new_scenario', "New scenario")}
      </ScenarioButton>
    </div>
  }
}

export default Test