import React from 'react'
import { Popover, Row, Col } from 'antd'
import { CloseOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Numeral from 'numeral'
import Formatters from '../../utils/formatters'
import t from '../../utils/translate'

const CommissionSystemPopover = ({ commissionSystem, title, children, currency }: any) => {

    return <Popover title={title} content={<div>
        <div>
            <div className="margin-l-m">
                <span className="subtitle"><FontAwesomeIcon className="margin-r-s" icon="mouse-pointer" /> CPC</span>
                {commissionSystem.hasCPC && Formatters.currency(currency, commissionSystem.cpc)}
                {!commissionSystem.hasCPC && <CloseOutlined />}
            </div>
        </div>
        <Row gutter={24}>
            {commissionSystem.conversionRules.map((cr: any) => {
                return <Col key={cr.conversionRuleId}>
                    <div className="subtitle margin-a-m">{cr.conversionRuleKind === 'order' && <FontAwesomeIcon className="margin-r-s" icon="shopping-cart" />}{cr.conversionRuleName}</div>

                    <div className="margin-l-m">
                        {cr.tiers.map((tier: any, i: number) => <div key={i}>
                            {cr.commissionType === 'percentage' && <div>
                                <span className="color-kpi-blue">{Numeral(tier.rate / 100).format('0[.]00') + '%'}</span> - {tier.threshold === 0 ? t('base_tier', "Base tier") : t('from_x_conversions_per_month', "From {x} conversions / month", { x: tier.threshold })}
                            </div>}
                            {cr.commissionType === 'flatAmount' && <div>
                                <span className="color-kpi-blue">{Formatters.currency(currency, tier.rate)}</span> - {tier.threshold === 0 ? t('base_tier', "Base tier") : t('from_x_conversions_per_month', "From {x} conversions / month", { x: tier.threshold })}
                            </div>}
                        </div>)}
                    </div>
                </Col>
            })}
        </Row>
    </div>}>
        {children}
    </Popover>
}

export default CommissionSystemPopover