import React, { useState } from 'react'

import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Tag, Drawer, DatePicker, Button, Table, Input, InputNumber, Select, Popconfirm, TimePicker, Form } from 'antd';
import t from '../../utils/translate'
import Formatters from '../../utils/formatters'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'
import Moment from 'moment-timezone'
import SegmentsCascader from '../segments/_cascader'

const daysOfMonth: any = []
for (let i = 1; i < 29; i++) {
  daysOfMonth.push(i)
}

const WorkflowTriggers = ({ segments, conversionRules, workflowKind, value, onChange }: any) => {

  // const [triggers, setTriggers] = useState(value)

  const deleteTrigger = (triggerId: string) => {
    const newTriggers = _.cloneDeep(value).filter((t: any) => t.id !== triggerId)
    // setTriggers(newTriggers)
    onChange(newTriggers)
  }

  // console.log('triggers', triggers);

  const onTriggersChange = (newTriggers: any) => {
    // console.log('newTriggers', newTriggers)
    // setTriggers(newTriggers)
    onChange(newTriggers)
  }

  return <>
    {value.length > 0 && <Table
      rowKey="id"
      dataSource={value}
      showHeader={false}
      size="middle"
      pagination={false}
      className="no-border margin-b-s"
      columns={[
        {
          title: '',
          key: 'kind',
          render: (item: any) => Formatters.workflowTrigger(workflowKind, item, segments)
        },
        {
          title: '',
          key: 'actions',
          width: '100px',
          className: 'text-right',
          render: (item: any) => <Button.Group>
            <Popconfirm
              title={t('confirm_delete_trigger', "Do you really want to delete this trigger?")}
              onConfirm={deleteTrigger.bind(null, item.id)}
              okText={t('confirm', "Confirm")}
              cancelText={t('cancel', "Cancel")}
            >
              <Button size="small"><DeleteOutlined /></Button>
            </Popconfirm>
            {item.kind !== 'onManualExecution' && <TriggerDrawer
              segments={segments}
              conversionRules={conversionRules}
              trigger={item}
              triggers={value}
              onChange={onTriggersChange}
              workflowKind={workflowKind}
            ><EditOutlined /></TriggerDrawer>}
          </Button.Group>
        }
      ]}
    />}
    <TriggerDrawer
      btnSize="default"
      btnType={'primary'}
      btnBlock={true}
      btnGhost={true}
      triggers={value}
      segments={segments}
      conversionRules={conversionRules}
      onChange={onTriggersChange}
      workflowKind={workflowKind}
    >
      {t('add_a_trigger', "Add a trigger")}
    </TriggerDrawer>
  </>
}

const TriggerDrawer = ({ segments, conversionRules, workflowKind, triggers, onChange, trigger, children, btnSize, btnType, btnBlock, btnGhost }: any) => {

  const [form] = Form.useForm();
  const [showDrawer, setShowDrawer] = useState(false)

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer)
  }

  const onFinish = (values: any) => {

    // transform dates
    if (values.scheduledAt) {
      values.scheduledAt = values.scheduledAt.format('YYYY-MM-DD HH:mm:ss')
    }

    if (values.repeatTime) {
      values.repeatTime = values.repeatTime.format('HH:mm:ss')
    }

    const newTriggers = _.cloneDeep(triggers)

    // NEW
    if (!trigger) {
      values.id = uuidv4()
      newTriggers.push(values)
      form.resetFields()
    } else {
      // EDIT
      values.id = trigger.id
      const triggerIndex = newTriggers.findIndex((t: any) => t.id === values.id)
      newTriggers[triggerIndex] = values
    }

    // console.log('values', values)
    setShowDrawer(false)

    onChange(newTriggers)
  }

  let initialValues = {
    scheduledAt: Moment().hour(8).minute(0).second(0),
    eventHappenedInTheLastMinutes: 0,
    repeatMonth: 1,
    repeatDayOfMonth: 1,
    repeatDayOfWeek: 'monday',
    repeatTime: Moment(_.get(trigger, 'repeatTime', '08:00:00'), 'HH:mm:ss'),
  }

  if (trigger) {
    initialValues = _.cloneDeep(trigger)
    // convert string date to moment
    if (trigger.scheduledAt) {
      initialValues.scheduledAt = Moment(trigger.scheduledAt)
    }
    if (trigger.repeatTime) {
      initialValues.repeatTime = Moment(trigger.repeatTime, 'HH:mm:ss')
    }
  }

  // console.log('trigger', trigger)
  // console.log('initialValues', initialValues)
  return <>
    <Button size={btnSize || "small"} block={btnBlock} ghost={btnGhost} type={btnType} onClick={toggleDrawer}>{children}</Button>

    {showDrawer && <Drawer
      title={!trigger ? t('add_a_trigger', "Add a trigger") : t('edit_trigger', "Edit trigger")}
      visible={true}
      onClose={toggleDrawer}
      maskClosable={false}
      className="drawer drawer-form"
      width={600}
    >
      <Form onFinish={onFinish} initialValues={initialValues} form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} className="margin-a-m margin-b-xl">
        <Form.Item name="kind" label={t('trigger_type', "Trigger type")} rules={[{ required: true, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
          <Select>
            {workflowKind === 'generic' && <Select.Option value="onManualExecution" disabled={triggers.find((t: any) => t.kind === 'onManualExecution') ? true : false}>{t('on_manual_execution', "On manual execution")}</Select.Option>}
            {workflowKind === 'generic' && <Select.Option value="scheduled">{t('scheduled', "Scheduled")}</Select.Option>}
            {workflowKind === 'generic' && <Select.Option value="repeat">{t('repeat', "Repeat")}</Select.Option>}
            {workflowKind === 'userEvent' && <Select.Option value="abandonedCart">{t('abandoned_cart', "Abandoned cart")}</Select.Option>}
            {workflowKind === 'userEvent' && <Select.Option value="enterSegment">{t('enter_segment', "Enter segment")}</Select.Option>}
            {workflowKind === 'userEvent' && <Select.Option value="exitSegment">{t('exit_segment', "Exit segment")}</Select.Option>}
            {workflowKind === 'userEvent' && <Select.Option value="custom">{t('custom_event', "Custom event")}</Select.Option>}
            {workflowKind === 'userEvent' && <Select.Option value="lead">Lead (new & updated)</Select.Option>}
            {workflowKind === 'globalEvent' && <Select.Option value="productBackInStock">{t('product_back_in_stock', "Product back in stock")}</Select.Option>}
            {workflowKind === 'globalEvent' && <Select.Option value="productPriceDrop">{t('product_price_drop', "Product price drop")}</Select.Option>}
          </Select>
        </Form.Item>

        {workflowKind === 'generic' && <Form.Item noStyle shouldUpdate>
          {(funcs) => {
            if (funcs.getFieldValue('kind') === 'scheduled') {
              return <>
                <Form.Item name="scheduledAt" label={t('scheduled_at', "Scheduled at")} rules={[{ required: true, type: 'string', transform: (date: any) => date.format('YYYY-MM-DD HH:mm:ss'), message: t('field_invalid', "This field is not valid!") }]}>
                  <DatePicker
                    showTime={true} format="YYYY-MM-DD HH:mm:ss"
                    disabledDate={(current: any) => current && current < Moment()}
                  />
                </Form.Item>

                <Form.Item name="timezone" label={t('time_zone', "Time zone")} rules={[{ required: true, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
                  <Select
                    placeholder={t('select_a_value', "Select a value")}
                    showSearch={true}
                    filterOption={(searchText: any, option: any) => {
                      return searchText !== '' && option.key.toLowerCase().includes(searchText.toLowerCase())
                    }}>
                    {Moment.tz.names().map((timezone) => {
                      return <Select.Option key={timezone} value={timezone}>{timezone}</Select.Option>
                    })}
                  </Select>
                </Form.Item>
              </>
            }


            if (funcs.getFieldValue('kind') === 'repeat') {
              return <>
                <Form.Item name="repeatEvery" label={t('repeat_every', "Repeat every")} rules={[{ required: true, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
                  <Select>
                    <Select.Option value="hour" disabled={triggers.find((t: any) => t.kind === 'repeat' && t.repeatEvery === 'hour') ? true : false}>{t('hour', "Hour")}</Select.Option>
                    <Select.Option value="day">{t('day', "Day")}</Select.Option>
                    <Select.Option value="week">{t('week', "Week")}</Select.Option>
                    <Select.Option value="month">{t('month', "Month")}</Select.Option>
                    <Select.Option value="year">{t('year', "Year")}</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item noStyle shouldUpdate>
                  {(funcs) => {
                    const repeatEvery = funcs.getFieldValue('repeatEvery')

                    return <>
                      {repeatEvery === 'year' && <Form.Item name="repeatMonth" label={t('month', "Month")} rules={[{ required: true, type: 'number', message: t('field_invalid', "This field is not valid!") }]}>
                        <Select>
                          <Select.Option value={1}>{t('january', "January")}</Select.Option>
                          <Select.Option value={2}>{t('february', "February")}</Select.Option>
                          <Select.Option value={3}>{t('march', "March")}</Select.Option>
                          <Select.Option value={4}>{t('april', "April")}</Select.Option>
                          <Select.Option value={5}>{t('may', "May")}</Select.Option>
                          <Select.Option value={6}>{t('june', "June")}</Select.Option>
                          <Select.Option value={7}>{t('july', "July")}</Select.Option>
                          <Select.Option value={8}>{t('august', "August")}</Select.Option>
                          <Select.Option value={9}>{t('september', "September")}</Select.Option>
                          <Select.Option value={10}>{t('october', "October")}</Select.Option>
                          <Select.Option value={11}>{t('november', "November")}</Select.Option>
                          <Select.Option value={12}>{t('december', "December")}</Select.Option>
                        </Select>
                      </Form.Item>}

                      {['month', 'year'].indexOf(repeatEvery) !== -1 && <Form.Item name="repeatDayOfMonth" label={t('date', "Date")} rules={[{ required: true, type: 'number', message: t('field_invalid', "This field is not valid!") }]}>
                        <Select>{daysOfMonth.map((i: any) => <Select.Option key={i} value={i}>{i}</Select.Option>)}</Select>
                      </Form.Item>}

                      {repeatEvery === 'week' && <Form.Item name="repeatDayOfWeek" label={t('day', "Day")} rules={[{ required: true, type: 'number', message: t('field_invalid', "This field is not valid!") }]}>
                        <Select>
                          <Select.Option value={0}>{t('sunday', "Sunday")}</Select.Option>
                          <Select.Option value={1}>{t('monday', "Monday")}</Select.Option>
                          <Select.Option value={2}>{t('tuesday', "Tuesday")}</Select.Option>
                          <Select.Option value={3}>{t('wednesday', "Wednesday")}</Select.Option>
                          <Select.Option value={4}>{t('thursday', "Thursday")}</Select.Option>
                          <Select.Option value={5}>{t('friday', "Friday")}</Select.Option>
                          <Select.Option value={6}>{t('saturday', "Saturday")}</Select.Option>
                        </Select>
                      </Form.Item>}

                      {['day', 'week', 'month', 'year'].indexOf(repeatEvery) !== -1 && <Form.Item name="repeatTime" label={t('time', "Time")} rules={[{ required: true, type: 'string', transform: (val) => val.format('HH:mm'), message: t('field_invalid', "This field is not valid!") }]}>
                        <TimePicker format="HH:mm" allowClear={false} minuteStep={5} />
                      </Form.Item>}

                      {['day', 'week', 'month', 'year'].indexOf(repeatEvery) !== -1 && <Form.Item name="timezone" label={t('time_zone', "Time zone")} rules={[{ required: true, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
                        <Select
                          placeholder={t('select_a_value', "Select a value")}
                          showSearch={true}
                          filterOption={(searchText: any, option: any) => {
                            return searchText !== '' && option.key.toLowerCase().includes(searchText.toLowerCase())
                          }}>
                          {Moment.tz.names().map((timezone) => {
                            return <Select.Option key={timezone} value={timezone}>{timezone}</Select.Option>
                          })}
                        </Select>
                      </Form.Item>}
                    </>
                  }}
                </Form.Item>
              </>
            }
          }}
        </Form.Item>}

        {workflowKind === 'userEvent' && <>
          <Form.Item noStyle shouldUpdate>
            {(funcs) => {
              const kind = funcs.getFieldValue('kind')
              const leadConversionRuleId = funcs.getFieldValue('leadConversionRuleId')
              console.log('cr', leadConversionRuleId);

              if (kind === 'custom') {
                return <Form.Item name="eventCustomLabel" label={t('event_label', "Event label")} rules={[{ required: true, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
                  <Input />
                </Form.Item>
              }

              if (kind === 'lead') {
                return <>
                  <Form.Item name="leadConversionRuleId" label="Lead conversion rule" rules={[{ required: true, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
                    <Select options={conversionRules.filter(cr => cr.kind === 'lead').map(cr => {
                      return {
                        value: cr.id,
                        label: cr.name,
                      }
                    })} />
                  </Form.Item>

                  {(kind === 'lead' && leadConversionRuleId) && <Form.Item name="leadStageId" label="Lead stage" rules={[{ required: true, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
                    <Select options={conversionRules.find(cr => cr.id === leadConversionRuleId).leadStages.map(stage => {
                      return {
                        value: stage.id,
                        label: <Tag color={stage.color}>{stage.label}</Tag>,
                      }
                    })} />
                  </Form.Item>}
                </>
              }

              if (kind === 'enterSegment' || kind === 'exitSegment') {
                  return <Form.Item name="segmentId" label={t('segment', "Segment")} rules={[{ required: true, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
                    <SegmentsCascader excludeSegments={['_id']} segments={segments} forCompanies={false} />
                  </Form.Item>
                }
            }}
          </Form.Item>
        </>}

        {workflowKind !== 'generic' && <Form.Item label={t('happened_in_the_last', "Happened in the last")}>
          <Form.Item name="eventHappenedInTheLastMinutes" noStyle rules={[{ required: true, type: 'number', min: 0, message: t('field_invalid', "This field is not valid!") }]} help={t('happened_in_the_last_help', "Use 0 to remove the limit.")}>
            <InputNumber min={0} step={1} />
          </Form.Item> mins
        </Form.Item>}

        <div className="ant-drawer-footer">
          <Button className="margin-r-m" onClick={toggleDrawer}>{t('cancel', "Cancel")}</Button>
          <Button htmlType="submit" type="primary">{t('confirm', "Confirm")}</Button>
        </div>
      </Form>
    </Drawer>}
  </>
}
export default WorkflowTriggers
