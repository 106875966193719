const Config = () => {

  if (window.location.hostname.indexOf('local') !== -1) {
    return {
      env: 'development',
      cm_agent_host: 'https://localapi.captainmetrics.com',
      api_endpoint: 'https://localapi.captainmetrics.com/v2',
      shopify_key: '693bbda3b2c2328c1f44a91c8473b880',
      mailchimp_client_id: '108498827444',
      firebase_web_api_key: 'AIzaSyA4OhBu_ytivL9xSbAZFxcDCfBQTo1ItVA',
      firebase_auth_domain: 'captainmetrics-dev-eu.firebaseapp.com',
      firebase_database_url: 'https://captainmetrics-dev-eu.firebaseio.com',
      firebase_storage_bucket: 'captainmetrics-dev-eu.appspot.com',
      firebase_sender_id: '819170039919',
      firebase_project_id: 'captainmetrics-dev-eu',
      firebase_app_id: '1:819170039919:web:75b9d4922066d29ee0959a',
      gmap_key: 'AIzaSyBKGpdhotuSYnVb3KONJZRCkT2RI00PVZQ',
      google_api_key: 'AIzaSyBKGpdhotuSYnVb3KONJZRCkT2RI00PVZQ',
      google_client_id: '819170039919-0jkat9heud4261eia05nhel9jb367ao7.apps.googleusercontent.com',
      sentry_dsn: 'https://e4a462bcaec24611a33483cfd0c61c6f@sentry.io/1380697',
      lazada_client_id: '121497',
      analytics_endpoint: 'http://localhost:4000/cubejs-api/v1',
      amazon_app_id: 'amzn1.sp.solution.5d6e31e3-1cab-49f6-be69-c0fd3000ef3a'
    }
  }
  else if (window.location.hostname.indexOf('test.') !== -1) {
    return {
      env: 'test',
      cm_agent_host: 'https://photon.test.captainmetrics.com',
      api_endpoint: 'https://api.test.captainmetrics.com/v2',
      shopify_key: '693bbda3b2c2328c1f44a91c8473b880',
      mailchimp_client_id: '108498827444',
      firebase_web_api_key: 'AIzaSyDtpvigBCUJ9NqSCP-S1W1GjpA4IJ5soUA',
      firebase_auth_domain: 'captain-metrics-test-eu.firebaseapp.com',
      firebase_database_url: 'https://captain-metrics-test-eu.firebaseio.com/',
      firebase_storage_bucket: 'captain-metrics-test-eu.appspot.com',
      firebase_sender_id: '912427866415',
      firebase_project_id: 'captain-metrics-test-eu',
      gmap_key: 'AIzaSyB-1gp1Gyd3llcAYyGsP7cMXyneebYQ2PY',
      google_api_key: 'AIzaSyB-1gp1Gyd3llcAYyGsP7cMXyneebYQ2PY',
      google_client_id: '912427866415-v2a3m16csftdbsqtg5gctvlmvce02l4f.apps.googleusercontent.com',
      sentry_dsn: 'https://e4a462bcaec24611a33483cfd0c61c6f@sentry.io/1380697',
      lazada_client_id: '121497',
      analytics_endpoint: 'https://analytics.test.captainmetrics.com/cubejs-api/v1',
      amazon_app_id: 'amzn1.sp.solution.5d6e31e3-1cab-49f6-be69-c0fd3000ef3a'
    }
  }
  else {
    return {
      env: 'production',
      cm_agent_host: 'https://photon.captainmetrics.com',
      api_endpoint: 'https://api.captainmetrics.com/v2',
      shopify_key: '693bbda3b2c2328c1f44a91c8473b880',
      mailchimp_client_id: '108498827444',
      firebase_web_api_key: 'AIzaSyAdS3DOr0Wm3pdXNafObxWC9bWQigxTLDI',
      firebase_auth_domain: 'captainmetrics-eu.firebaseapp.com',
      firebase_database_url: 'https://captainmetrics-eu.firebaseio.com/',
      firebase_storage_bucket: 'captainmetrics-eu.appspot.com',
      firebase_sender_id: '953380852696',
      firebase_project_id: 'captainmetrics-eu',
      gmap_key: 'AIzaSyCrurTAcZdMbb2o_aWzfIHHtMVVXONVuNA',
      google_api_key: 'AIzaSyCrurTAcZdMbb2o_aWzfIHHtMVVXONVuNA',
      google_client_id: '953380852696-smv1c8542vi9tn2ehpjaj4bfr27t44pb.apps.googleusercontent.com',
      sentry_dsn: 'https://e4a462bcaec24611a33483cfd0c61c6f@sentry.io/1380697',
      lazada_client_id: '121497',
      analytics_endpoint: 'https://analytics.captainmetrics.com/cubejs-api/v1',
      amazon_app_id: 'amzn1.sp.solution.1a350393-a47a-4c76-829d-c2eb21694566'
    }
  }
}

export default Config