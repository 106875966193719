import Config from './config'
import Raven from 'raven-js'
// import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/app'
import "./less/index.less"
import { BrowserRouter as Router } from 'react-router-dom'
import * as serviceWorker from './serviceWorker';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faFacebook, faMailchimp } from '@fortawesome/free-brands-svg-icons'
// import { FirebaseApp } from '@firebase/app'

library.add(fas, faTwitter, faFacebook, faMailchimp)

if (Config().env !== 'development') {
  Raven.config(Config().sentry_dsn).install()
}

declare global {
  interface Window {
      cmAgent: any;
      cmCache: any;
      FB: any;
      CM: any;
      app: any;
      gapi: any;
      // fapp: FirebaseApp
  }
  interface Navigator {
    userLanguage: any;
  }
}

ReactDOM.render(
  <Router basename={(Config().env !== 'development') ? '/' : '/'}>
    <App />
  </Router>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();