import { Component } from 'react'

import t from '../../utils/translate'
import ConversionRulesTable from '../conversionRules/_table'
import ConversionRulesAbout from '../conversionRules/_about'
import AppLayout from 'components/app/layout'

class ConversionRules extends Component<any, any> {

  componentDidMount() {
    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Conversion rules (project: ' + this.props.projectLayout.project.id + ')',
        page: this.props.location.pathname,
        props: {
          orgId: this.props.projectLayout.project.organizationId,
          projectId: this.props.projectLayout.project.id
        }
      })
      window.cmAgent.dispatch()
    }
  }

  render() {
    return <AppLayout currentProject={this.props.projectLayout.project} currentOrganization={this.props.organizationLayout.organization} admin={this.props.app.state.admin} firebaseUser={this.props.app.state.firebaseUser} projects={this.props.organizationLayout.projects}>
      <h1>{t('conversion_rules', "Conversion rules")}</h1>
      <ConversionRulesAbout />
      <ConversionRulesTable {...this.props} />
    </AppLayout>
  }
}

export default ConversionRules