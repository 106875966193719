import React, { useState, useEffect } from 'react'

import { Button, Select, Input, Row, Col, Form } from 'antd';
import t from '../../utils/translate'
import Currencies from '../../utils/currencies'
import Countries from '../../utils/countries'
import AppLayout from 'components/app/layout';

const OrganizationsSettings = (props: any) => {

  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  useEffect(() => {
    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Settings (org: ' + props.organizationLayout.organization.id + ')',
        page: props.location.pathname,
        props: {
          orgId: props.organizationLayout.organization.id,
        }
      })
      window.cmAgent.dispatch()
    }
  }, [props.organizationLayout.organization.id, props.location.pathname])

  const onFinish = (values: any) => {

    if (loading === true) {
      return
    }
    else {
      setLoading(true)
    }

    values.organizationId = props.organizationLayout.organization.id

    props.app.ajaxRequest({
      method: 'post',
      url: '/organizations.setSettings',
      data: values
    }, (errorMessage: any) => {

      if (errorMessage) {
        props.app.addMessage('error', errorMessage)
        setLoading(false)
        return
      }

      props.app.addMessage('success', t('settings_updated', 'The settings have been updated!'))
      setLoading(false)
    })
  }

  let initialValues = {}

  if (props.organizationLayout.organization) {
    initialValues = { ...props.organizationLayout.organization }
  }

  return <AppLayout currentOrganization={props.organizationLayout.organization} admin={props.app.state.admin} firebaseUser={props.app.state.firebaseUser} projects={props.organizationLayout.projects}>

    <h1 className="margin-b-l">{t('settings', "Settings")}</h1>

    <div className="block padding-a-l">
      <Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} initialValues={initialValues} layout="horizontal" onFinish={onFinish} style={{ textAlign: 'left' }}>
        <Form.Item name="name" label={t('organization_name', "Organization name")} rules={[{ required: true, message: t('field_required', "This field is required!") }]}>
          <Input size="large" placeholder="i.e: Acme Inc" />
        </Form.Item>

        <Form.Item name="currency" label={t('currency', "Currency")} rules={[{ required: true, message: t('field_required', "This field is required!") }]}>
          <Select
            size="large"
            showSearch
            placeholder={t('select_a_currency', "Select a currency")}
            optionFilterProp="children"
            filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {Currencies.map((c) => <Select.Option key={c.code} value={c.code}>{c.code + ' - ' + c.currency}</Select.Option>)}
          </Select>
        </Form.Item>

        <Form.Item name="country" label={t('country', "Country")} rules={[{ required: true, message: t('field_required', "This field is required!") }]}>
          <Select
            size="large"
            showSearch
            placeholder={t('select_a_country', "Select a country")}
            optionFilterProp="children"
            filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {Countries.map((c: any) => <Select.Option key={c.code} value={c.code}>{c.code + ' - ' + c.name}</Select.Option>)}
          </Select>
        </Form.Item>

        <Row>
          <Col xs={22} sm={22} className="text-right">
            <Button type="primary" loading={loading} htmlType="submit">{t('confirm', "Confirm")}</Button>
          </Col>
        </Row>
      </Form>
    </div>
  </AppLayout>
}

export default OrganizationsSettings