import React, { useState } from 'react'
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Drawer,
  Select,
  Tooltip,
  Tabs,
  Switch,
  message,
} from 'antd'
import _ from 'lodash'
import t from '../../utils/translate'
import { SettingFilled, FileImageOutlined, CodeFilled, LayoutFilled } from '@ant-design/icons';
import Nunjucks from 'nunjucks'
import IframeSandbox from './_iframeSandbox'
import AceInput from './_aceInput'
import InfoRadioGroup from '../common/infoRadio'

import RootBlockDefinition from '../editor/UI/definitions/Root'
import OneColumnBlockDefinition from '../editor/UI/definitions/OneColumn'
import Columns168BlockDefinition from '../editor/UI/definitions/Columns168'
import Columns204BlockDefinition from '../editor/UI/definitions/Columns204'
import Columns420BlockDefinition from '../editor/UI/definitions/Columns420'
import Columns816BlockDefinition from '../editor/UI/definitions/Columns816'
import Columns888BlockDefinition from '../editor/UI/definitions/Columns888'
import Columns1212BlockDefinition from '../editor/UI/definitions/Columns1212'
import Columns6666BlockDefinition from '../editor/UI/definitions/Columns6666'
import ImageBlockDefinition from '../editor/UI/definitions/Image'
import DividerBlockDefinition from '../editor/UI/definitions/Divider'
import ButtonBlockDefinition from '../editor/UI/definitions/Button'
import TextBlockDefinition from '../editor/UI/definitions/Text'
import HeadingBlockDefinition from '../editor/UI/definitions/Heading'
import { Editor, SelectedBlockButtonsProp } from '../editor/Editor'
import { BlockInterface, BlockDefinitionInterface } from '../editor/Block'
// import SectionBlockDefinition from './definitions/Section'
import ColumnBlockDefinition from '../editor/UI/definitions/Column'
import { ExportHTML } from '../editor/UI/Preview'
import { Layout, DesktopWidth } from '../editor/UI/Layout'
import SelectedBlockButtons from '../editor/UI/SelectedBlockButtons'
import uuid from 'short-uuid'


const generateBlockFromDefinition = (blockDefinition: BlockDefinitionInterface) => {
  const id = uuid.generate()

  const block: BlockInterface = {
    id: id,
    kind: blockDefinition.kind,
    path: '', // path is set when rendering
    children: blockDefinition.children ? blockDefinition.children.map((child: BlockDefinitionInterface) => {
      return generateBlockFromDefinition(child)
    }) : [],
    data: _.cloneDeep(blockDefinition.defaultData),
  }

  return block
}

// const image = generateBlockFromDefinition(ImageBlockDefinition)
// const button = generateBlockFromDefinition(ButtonBlockDefinition)
const text = generateBlockFromDefinition(TextBlockDefinition)
const heading = generateBlockFromDefinition(HeadingBlockDefinition)
const logo = generateBlockFromDefinition(ImageBlockDefinition)
const image = generateBlockFromDefinition(ImageBlockDefinition)
const divider = generateBlockFromDefinition(DividerBlockDefinition)
const btn = generateBlockFromDefinition(ButtonBlockDefinition)
const column = generateBlockFromDefinition(OneColumnBlockDefinition)

// column.data.paddingControl = 'separate'
// column.data.styles.paddingTop = '50px'
// column.data.styles.backgroundColor = '#FFFFFF'
// column.data.styles.borderRadius = '10px'

logo.data.image.src = 'https://cdn.captainmetrics.com/images/emails/logo.png'
logo.data.image.alt = 'Captain Metrics'
logo.data.image.href = 'https://captainmetrics.com'
logo.data.image.width = '150px'

// heading.data.align = 'center'
heading.data.paddingControl = 'separate'
heading.data.paddingTop = '40px'
heading.data.paddingBottom = '40px'
heading.data.editorData[0].children[0].text = 'Hi {{ user.firstName }} 👋'

divider.data.paddingControl = 'separate'
divider.data.paddingTop = '40px'
divider.data.paddingBottom = '20px'
divider.data.paddingLeft = '200px'
divider.data.paddingRight = '200px'

text.data.editorData[0].children[0].text = 'Welcome to the email editor!'

btn.data.button.backgroundColor = '#4e6cff'
btn.data.button.text = '👉 Click me'


column.children[0].children.push(logo)
column.children[0].children.push(heading)
column.children[0].children.push(text)
column.children[0].children.push(divider)
column.children[0].children.push(image)
column.children[0].children.push(btn)

const rootData = _.cloneDeep(RootBlockDefinition.defaultData)
// rootData.styles.body.backgroundColor = '#1A237E'

const rootBlock: BlockInterface = {
  id: 'root',
  kind: 'root',
  path: '',
  children: [
    column,
  ],
  data: rootData
}

const definitions = {
  'root': RootBlockDefinition,
  'column': ColumnBlockDefinition,
  'oneColumn': OneColumnBlockDefinition,
  'columns168': Columns168BlockDefinition,
  'columns204': Columns204BlockDefinition,
  'columns420': Columns420BlockDefinition,
  'columns816': Columns816BlockDefinition,
  'columns888': Columns888BlockDefinition,
  'columns1212': Columns1212BlockDefinition,
  'columns6666': Columns6666BlockDefinition,
  'image': ImageBlockDefinition,
  'divider': DividerBlockDefinition,
  'button': ButtonBlockDefinition,
  'text': TextBlockDefinition,
  'heading': HeadingBlockDefinition,
}

const EmailForm = ({ loading, channelTitle, macros, language, template, notification, visible, fromTemplate, setVisible, submitForm, project}: any) => {
  const [form] = Form.useForm()
  const [contentDecorated, setContentDecorated] = useState('')
  const [tab, setTab] = useState('settings')
  const [contentField, setContentField] = useState('content')

  // console.log('form', form);

  let content = 'Welcome <b>{{ user.firstName }}</b>! 😀'
  let macroId = undefined

  if (macros.find((x: any) => x.id === 'default-email-html-layout')) {
    content = `{{ email_html_header('DEMO', 'https://notifuse.com') }}
<h1>Welcome <b>{{ user.firstName }}</b>! 😀</h1>
{{ email_html_footer('ACME') }}
`
    macroId = 'default-email-html-layout'
  }

  const initialValues = template ? _.cloneDeep(template) : {
    editor: 'visual',
    editorData: rootBlock,
    content: content,
    macroId: macroId,
    testData: JSON.stringify(notification.testData, null, 2),
    cssInlining: true,
  }

  if (template) {
    initialValues.testData = JSON.stringify(notification.testData, null, 2)
  }

  // clone from template
  if (fromTemplate) {
    initialValues.editorData = fromTemplate.editorData
    initialValues.content = fromTemplate.content
    initialValues.altContent = fromTemplate.altContent
    initialValues.testData = JSON.stringify(notification.testData, null, 2)
    initialValues.fromName = fromTemplate.fromName
    initialValues.fromAddress = fromTemplate.fromAddress
    initialValues.subject = fromTemplate.subject
    initialValues.replyTo = fromTemplate.replyTo
    initialValues.cc = fromTemplate.cc
    initialValues.bcc = fromTemplate.bcc
    initialValues.openTracking = fromTemplate.openTracking
    initialValues.cssInlining = fromTemplate.cssInlining
  }

  if (initialValues.cc && initialValues.cc.length) {
    initialValues.cc = initialValues.cc.split(',')
  }
  if (initialValues.bcc && initialValues.bcc.length) {
    initialValues.bcc = initialValues.bcc.split(',')
  }

  if (!initialValues.editorData) {
    initialValues.editorData = rootBlock
  }

  const goNext = () => {
    form.validateFields([
      // TODO
      // 'name',
      // 'kind',
      // 'segmentId',
      // 'notificationTopicId',
      // 'triggers',
      // 'limitExecPerUser',
    ]).then((values: any) => {
      // console.log('next values', values)
      setTab('template')
    }).catch(() => { })
  }

  const decorateContent = (content: any, data: any, macroId: any) => {
    if (!content) {
      return
    }

    let parsedData = {}
    try {
      parsedData = JSON.parse(data)
    }
    catch (e: any) { }

    let macroContent = ''

    if (macroId) {
      macros.forEach((x: any) => {
        if (x.id === macroId) {
          macroContent = x.content
        }
      })
    }

    // console.log('macroId', macroId);
    // console.log('macroContent', macroContent);
    // console.log('parsedData', parsedData);

    Nunjucks.renderString(macroContent + ' ' + content, parsedData, (error, result) => {
      if (error) {
        setContentDecorated(t('templating_syntax_invalid', "Templating syntax is not valid..."))
        return
      }
      // trim result to remove linebreaks introduced by macros sets
      if (result) setContentDecorated(result.trim())
    })
  }

  // layout height

  const doc = document.querySelector('.ant-drawer')
  const topbarHeight = 55
  const footerHeight = 53
  const contentHeight = doc ? parseInt(window.getComputedStyle(doc).height)-topbarHeight-footerHeight : 0

  return <Drawer
    title={<>
      <div className="title">{(template) ? t('edit_template', "Edit template") : t('create_a_template', "Create a template")}</div>
      <Tabs activeKey={tab} centered onChange={k => setTab(k)}>
        <Tabs.TabPane tab={<span><SettingFilled /> 1. {t('settings', 'Settings')}</span>} key="settings"></Tabs.TabPane>
        <Tabs.TabPane tab={<span><FileImageOutlined /> 2. {t('template', 'Template')}</span>} key="template"></Tabs.TabPane>
      </Tabs>
    </>}
    visible={visible}
    closable={true}
    maskClosable={false}
    width={tab === 'settings' ? 750 : '95%'}
    className="with-tabs"
    onClose={() => { setVisible(false) }}
    footer={
      <div style={{ textAlign: 'right' }}>
        <Button loading={loading} onClick={() => setVisible(false)} className="margin-r-s">{t('cancel', "Cancel")}</Button>

        {tab === 'settings' && <Button type="primary" className="margin-r-s" onClick={goNext}>{t('next', "Next")}</Button>}
        {tab === 'template' && <Button type="primary" ghost className="margin-r-s" onClick={() => setTab('settings')}>{t('previous', "Previous")}</Button>}

        {tab === 'template' && <Button loading={loading} className="margin-r-s" onClick={() => {
          form
            .validateFields()
            .then((values: any) => {

              // compile html
              if (values.editor === 'visual') {
                const result = ExportHTML(values.editorData)
                if (result.errors && result.errors.length > 0) {
                  message.error(result.errors[0].formattedMessage)
                  return
                }
                values.content = result.html
              } else {
                values.editorData = undefined
              }

              submitForm(values, () => {
                setVisible(false)
              })
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
              if (info.errorFields) {
                info.errorFields.forEach((field: any) => {
                  if (['fromAddress', 'fromName', 'subject', 'editor', 'replyTo', 'bcc', 'cc'].indexOf(field.name['0']) !== -1) {
                    setTab('settings')
                  }
                })
              }
            });
        }} type="primary">{t('save', "Save")}</Button>}
      </div>
    }
  >
    <Form form={form} layout="vertical" name="widget_form" initialValues={initialValues}>

      <Tabs activeKey={tab} centered onChange={k => setTab(k)} tabBarStyle={{display: 'none'}}>
        <Tabs.TabPane tab={null} key="settings" className="padding-h-l padding-v-m">

          <div className="margin-b-l margin-t-m">Notification: {notification.name + ' - ' + channelTitle + ' - ' + language}</div>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="fromAddress"
                label={t('sender_email_address', "Sender email address")}
                rules={[{ required: true, type: 'email' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="fromName"
                label={t('sender_name', "Sender name")}
                rules={[{ required: true, type: 'string' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            name="subject"
            label={t('subject', "Subject")}
            rules={[{ required: true, type: 'string' }]}
          >
            <Input placeholder="Templating markup allowed" />
          </Form.Item>

          <Row gutter={24}>
            <Col span={12}>

              <Form.Item
                name="cc"
                label="Cc"
                validateFirst={true}
                rules={[
                  { required: false, type: 'array' },
                  {
                    validator: (rule, value) => {
                      return new Promise((resolve, reject) => {
                        if (value && value.length) {
                          const reg = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
                          value.forEach((email: string) => {
                            if (reg.test(email) === false) {
                              return reject(email + ' is not a valid email address')
                            }
                          })
                        }
                        return resolve(undefined)
                      })
                    }
                  }
                ]}
              >
                <Select mode="tags" placeholder="Add an email and press enter" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="bcc"
                label="Bcc"
                rules={[
                  { required: false, type: 'array' },
                  {
                    validator: (rule, value) => {
                      return new Promise((resolve, reject) => {
                        if (value && value.length) {
                          const reg = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
                          value.forEach((email: string) => {
                            if (reg.test(email) === false) {
                              return reject(email + ' is not a valid email address')
                            }
                          })
                        }
                        return resolve(undefined)
                      })
                    }
                  }
                ]}
              >
                <Select mode="tags" placeholder="Add an email and press enter" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="replyTo"
                label={t('reply_to', "Reply to")}
                rules={[{ required: false, type: 'email' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="openTracking"
                label={t('open_tracking', "Open tracking")}
                rules={[{ required: false, type: 'boolean' }]}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="clickTracking"
                label={t('click_tracking', "Click tracking")}
                rules={[{ required: false, type: 'boolean' }]}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item name="editor" label="Editor" rules={[{ required: true, type: 'string' }]}>
            <InfoRadioGroup layout="vertical" span={12} options={[
              {
                key: 'visual',
                title: "Drag'n drop",
                icon: <LayoutFilled />,
                content: <span>Visual drag'n drop editor, for non-technical users.</span>,
              },
              {
                key: 'code',
                title: 'Code',
                icon: <CodeFilled />,
                content: <span>Code editor with templating engine, for maximum control over HTML.</span>,
              },
            ]} onChange={() => {
              // triggers are unique for each kind, reset them when switching
              // form.setFieldsValue({ triggers: [] })
            }} />
          </Form.Item>

        </Tabs.TabPane>

        <Tabs.TabPane tab={null} key="template">

          <Form.Item noStyle shouldUpdate={true}>
            {({ getFieldValue }: any) => {

              if (getFieldValue('editor') === 'visual') {
                return <div>
                  <Form.Item noStyle name="editorData">
                    <Editor
                      blockDefinitions={definitions}
                      savedBlocks={project.emailBlocks || []}
                      templateData={getFieldValue('testData')}
                      selectedBlockId={divider.id}
                      value={rootBlock}
                      onChange={_newTree => {
                        // console.log('new tree', newTree)
                      }}
                      renderSelectedBlockButtons={(props: SelectedBlockButtonsProp) => <SelectedBlockButtons {...props} />}
                      deviceWidth={DesktopWidth}
                    >
                      <Layout form={form} macros={macros} height={contentHeight} />
                    </Editor>
                  </Form.Item>
                </div>
              }

              if (getFieldValue('editor') === 'code') {
                return <>
                  <Row gutter={24} className="margin-a-m">
                    <Col span={12}>

                      <Tabs tabBarExtraContent={<div style={{ width: '250px' }}>
                        <Tooltip title={t('macros_page', "Macros page")}>
                          <div>
                            <Form.Item noStyle name="macroId">
                              <Select
                                style={{ width: '100%' }}
                                dropdownMatchSelectWidth={false}
                                allowClear={true}
                                size="small"
                                placeholder="Select macros page"
                                options={macros.map((x: any) => { return { label: x.name, value: x.id } })}
                              />
                            </Form.Item>
                          </div>
                        </Tooltip></div>}
                        defaultActiveKey={contentField}
                        onChange={(value: any) => {
                          setContentField(value)
                          decorateContent(form.getFieldValue(value), form.getFieldValue('testData'), form.getFieldValue('macroId'))
                        }}>
                        <Tabs.TabPane tab="HTML" key="content">
                          <Form.Item
                            name="content"
                            rules={[{ required: false, type: 'string' }]}
                          >
                            <AceInput id="widgetContent" width="600px" height="300px" mode="nunjucks" />
                          </Form.Item>
                        </Tabs.TabPane>

                        <Tabs.TabPane tab="Text" key="altContent">
                          <Form.Item
                            name="altContent"
                            rules={[{ required: false, type: 'string' }]}
                          >
                            <AceInput id="textContent" width="600px" height="300px" mode="nunjucks" />
                          </Form.Item>
                        </Tabs.TabPane>
                      </Tabs>

                      <Form.Item
                        label={t('notification_test_data', "Notification test data")}
                        name="testData"
                        validateFirst={true}
                        rules={[
                          {
                            validator: (xxx, value) => {
                              // check if data is valid json
                              try {
                                if (JSON.parse(value)) { }
                                return Promise.resolve(undefined)
                              }
                              catch (e: any) {
                                return Promise.reject(t('test_data_is_not_valid_json', "Your test variables is not a valid JSON object!"))
                              }
                            }
                          },
                          {
                            required: false, type: 'object', transform: (value: any) => {
                              try {
                                const parsed = JSON.parse(value)
                                return parsed
                              }
                              catch (e: any) {
                                return value
                              }
                            }
                          },
                        ]}
                      >
                        <AceInput id="testData" width="600px" height="150px" mode="json" />
                      </Form.Item>
                      <Form.Item
                        name="cssInlining"
                        label={t('css_inlining', "CSS inlining")}
                        rules={[{ required: false, type: 'boolean' }]}
                        valuePropName="checked"
                      >
                        <Switch />
                      </Form.Item>
                    </Col>

                    <Col span={12} id="previewContent">
                      <p className="padding-t-s">{t('preview', "Preview")}</p>

                      <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.content !== curValues.content || prevValues.altContent !== curValues.altContent || prevValues.testData !== curValues.testData || prevValues.macroId !== curValues.macroId}>
                        {({ getFieldValue }: any) => {
                          decorateContent(getFieldValue(contentField), getFieldValue('testData'), getFieldValue('macroId'))
                          return <IframeSandbox content={contentDecorated}
                            className="Iframe-normal"
                            sizeId="previewContent"
                            id="templateCompiled" />
                        }}
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              }
            }}
          </Form.Item>
        </Tabs.TabPane>
      </Tabs>
    </Form>
  </Drawer>
}

export default EmailForm

