import React, { useState, useEffect, useCallback } from 'react'

import { Table, Spin, Tooltip, Button, Row, Col, message } from 'antd';
import t from '../../utils/translate'
import Numeral from 'numeral'
import Formatters from '../../utils/formatters'
import DateRange from '../projects/_dateRange'
import QS from 'qs'
import Moment from 'moment-timezone'
import cubejs from '@cubejs-client/core'
import Config from '../../config'
import { ReloadOutlined } from '@ant-design/icons';
import removeLeadingDates from 'utils/removeLeadingDates'
import { useOrganizationContext, OrganizationContextValue } from '../organizations'
import { useHistory, useLocation } from 'react-router-dom'

const OrganizationsGlobalStats = () => {

  const [uniqueEmails, setUniqueEmails] = useState(0)
  const [data, setData] = useState<any>([])
  const orgCtx: OrganizationContextValue = useOrganizationContext()
  const history = useHistory() as any
  const location = useLocation() as any


  // console.log(props.organizationLayout)
  const defaultDateRangeLabel = 'last_30_days'
  const defaultDateRangeVs = 'previous_period'
  const defaultDateFrom = Moment().tz(orgCtx.admin.timezone).subtract(31, 'day').startOf('day').format('YYYY-MM-DD')
  const defaultDateTo = Moment().tz(orgCtx.admin.timezone).subtract(1, 'day').endOf('day').format('YYYY-MM-DD')

  const params: any = QS.parse(location.search, { ignoreQueryPrefix: true })

  const dateFrom = params.dateFrom || defaultDateFrom
  const dateTo = params.dateTo || defaultDateTo

  const dateRangeLabel = params.dateRangeLabel || defaultDateRangeLabel
  const dateRangeVs = params.dateRangeVs || defaultDateRangeVs
  const refreshAt = params.refreshAt || Moment().unix()

  let dateFromPrevious: any = Moment(dateFrom)
  let dateToPrevious: any = Moment(dateTo)

  if (dateRangeVs === 'previous_period') {

    // add one day to the diff to avoid days overlaping between the to ranges
    const diff = dateToPrevious.diff(dateFromPrevious, 'days') + 1
    // console.log('diff is', diff);

    dateToPrevious = dateToPrevious.subtract(diff, 'days')
    dateFromPrevious = dateFromPrevious.subtract(diff, 'days')
  }

  if (dateRangeVs === 'previous_year') {
    dateToPrevious = dateToPrevious.subtract(1, 'years')
    dateFromPrevious = dateFromPrevious.subtract(1, 'years')
  }

  dateFromPrevious = dateFromPrevious.format('YYYY-MM-DD')
  dateToPrevious = dateToPrevious.format('YYYY-MM-DD')


  const refresh = () => {
    updateParams([{ k: 'refreshAt', v: Moment().unix() }])
  }

  const updateParams = (updatedParams: any) => {
    const params: any = QS.parse(location.search, { ignoreQueryPrefix: true })

    updatedParams.forEach((up: any) => {
      params[up.k] = up.v
    })

    history.push('/organizations/' + orgCtx.currentOrganization.id + '/global-stats?' + QS.stringify(params))
  }

  const loadProjectStats = useCallback((project: any, onComplete: Function) => {

    const sessionsQuery: any = {
      measures: [
        'TimelineSessions.uniqueUsers',
        'TimelineSignups.count',
        'TimelineSessions.count',
        // 'TimelineSessions.bounceRate',
        // 'TimelineSessions.averagePageviewsCount',
        // 'TimelineSessions.averageSessionDuration',
      ],
      dimensions: [
        // 'TimelineSessions.sessionReferrerDomain',
      ],
      timeDimensions: [
        {
          "dimension": 'TimelineSessions.truncCreatedAt',
          "granularity": null,
          "compareDateRange": [
            [dateFrom, dateTo],
            [dateFromPrevious, dateToPrevious]
          ]
        }
      ],
      timezone: orgCtx.admin.timezone,
    }

    const authenticatedQuery: any = {
      measures: [
        'ActiveUsers.count',
      ],
      dimensions: [
        // 'TimelineSessions.sessionReferrerDomain',
      ],
      timeDimensions: [
        {
          "dimension": 'ActiveUsers.truncCreatedAt',
          "granularity": null,
          "compareDateRange": [
            [dateFrom, dateTo],
            [dateFromPrevious, dateToPrevious]
          ]
        }
      ],
      timezone: orgCtx.admin.timezone,
      segments: ['Users.authenticated']
    }

    const ordersQuery: any = {
      measures: [
        'TimelineOrders.count',
        'TimelineOrders.revenue',
        'TimelineOrders.averageCart'
      ],
      dimensions: [
        // 'TimelineSessions.sessionReferrerDomain',
      ],
      timeDimensions: [
        {
          "dimension": 'TimelineOrders.truncCreatedAt',
          "granularity": null,
          "compareDateRange": [
            [dateFrom, dateTo],
            [dateFromPrevious, dateToPrevious]
          ]
        }
      ],
      timezone: orgCtx.admin.timezone,
    }

    const cubejsApi = cubejs(project.analyticsToken, { apiUrl: Config().analytics_endpoint })

    Promise.all([
      cubejsApi.load(sessionsQuery),
      cubejsApi.load(authenticatedQuery),
      cubejsApi.load(ordersQuery),
    ]).then((values: any) => {
      const [currentSessions, previousSessions] = values[0].decompose()
      const [currentAuthenticated, previousAuthenticated] = values[1].decompose()
      const [currentOrders, previousOrders] = values[2].decompose()

      const currentSessionsTable = currentSessions.tablePivot()
      const previousSessionsTable = previousSessions.tablePivot()

      const currentAuthenticatedTable = currentAuthenticated.tablePivot()
      const previousAuthenticatedTable = previousAuthenticated.tablePivot()

      const currentOrdersTable = currentOrders.tablePivot()
      const previousOrdersTable = previousOrders.tablePivot()

      removeLeadingDates(currentSessionsTable)
      removeLeadingDates(previousSessionsTable)
      removeLeadingDates(currentAuthenticatedTable)
      removeLeadingDates(previousAuthenticatedTable)
      removeLeadingDates(currentOrdersTable)
      removeLeadingDates(previousOrdersTable)

      const newData = [...data]

      newData.forEach((line: any, i: number) => {

        if (line.project.id === project.id) {
          data[i].loaded = true // mutate state to make sure loadNextProject see the update
          newData[i].loaded = true
          newData[i].current = Object.assign(currentSessionsTable[0], currentAuthenticatedTable[0], currentOrdersTable[0])
          newData[i].previous = Object.assign(previousSessionsTable[0], previousAuthenticatedTable[0], previousOrdersTable[0])
        }
      })

      setData(newData)

      onComplete()
    })
      .catch((error: any) => {

        console.log(error)

        let msg = error.message

        if (error.response && error.response.status === 400) {
          switch (error.response.data.message) {
            default:
              msg = error.response.data.message
          }
        }

        message.error(msg)

        onComplete(error)
      })
  }, [data, dateFrom, dateTo, dateFromPrevious, dateToPrevious, orgCtx.admin.timezone])




  useEffect(() => {
    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Global stats (org: ' + orgCtx.currentOrganization.id + ')',
        page: location.pathname,
        props: {
          orgId: orgCtx.currentOrganization.id,
        }
      })
      window.cmAgent.dispatch()
    }

    // init projects data table
    if (orgCtx.projects.length > 0) {
      const newData = orgCtx.projects.map((project: any) => {
        return {
          project: project,
          loaded: false,
          current: {},
          previous: {}
        }
      })
      setData(newData)
    }

    orgCtx.ajaxRequest({
      method: 'get',
      url: '/organizations.uniqueUserEmails',
      params: {
        organizationId: orgCtx.currentOrganization.id
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        message.error(errorMessage)
        return
      }

      setUniqueEmails(response.data.count)
    })

  }, [orgCtx, orgCtx.projects, orgCtx.currentOrganization.id, location.search, location.pathname])


  const loadNextProject = useCallback(() => {
    const projectToLoad = data.find((x: any) => x.loaded === false)

    if (projectToLoad) {
      loadProjectStats(projectToLoad.project, (err: any) => {
        if (err) return
        loadNextProject()
      })
    }
  }, [data, loadProjectStats])


  // load remaining projects when data changes
  useEffect(() => {

    loadNextProject()
    // data.forEach((x: any) => {
    //   if (x.loaded === false) {
    //     loadProjectStats(x.project)
    //     return
    //   }
    // })
  }, [loadNextProject])

  let currency = 'USD'
  let currentUsers = 0
  let currentNewSignups = 0
  let currentAuthenticated = 0
  let currentSessions = 0
  let currentOrders = 0
  let currentRevenue = 0
  let currentAverageCart = 0
  let previousUsers = 0
  let previousNewSignups = 0
  let previousAuthenticated = 0
  let previousSessions = 0
  let previousOrders = 0
  let previousRevenue = 0
  let previousAverageCart = 0

  if (data.length > 0) {
    data.forEach((line: any) => {
      currency = line.project.currency
      currentUsers += line.current['TimelineSessions.uniqueUsers']
      currentNewSignups += line.current['TimelineSignups.count']
      currentAuthenticated += line.current['ActiveUsers.count']
      currentSessions += line.current['TimelineSessions.count']
      currentOrders += line.current['TimelineOrders.count']
      currentRevenue += line.current['TimelineOrders.revenue']
      previousUsers += line.previous['TimelineSessions.uniqueUsers']
      previousNewSignups += line.previous['TimelineSignups.count']
      previousAuthenticated += line.previous['ActiveUsers.count']
      previousSessions += line.previous['TimelineSessions.count']
      previousOrders += line.previous['TimelineOrders.count']
      previousRevenue += line.previous['TimelineOrders.revenue']
    })

    currentAverageCart = (currentRevenue > 0) ? currentRevenue / currentOrders : 0
    previousAverageCart = (previousRevenue > 0) ? previousRevenue / previousOrders : 0
  }

  return <div className="layout-content">

    <Row className="dash-live">
      <Col span={12}>
        <span className="size15 semibold padding-r-m">{Numeral(uniqueEmails).format('0,0')}</span>
        <span className="widget-title">Unique emails</span>
      </Col>

      <Col span={12}>
        <span className="pull-right">
          <DateRange 
            timezone={orgCtx.admin.timezone}
            vs={dateRangeVs}
            dateRange={[
              Moment(dateFrom),
              Moment(dateTo),
            ]}
            labelKey={dateRangeLabel}
            onVsChange={(value: any) => {
              updateParams([{ k: 'dateRangeVs', v: value }])
            }}
            onChange={(label: string, range: any) => {
              updateParams([
                { k: 'dateRangeLabel', v: label },
                { k: 'dateFrom', v: range[0] },
                { k: 'dateTo', v: range[1] },
              ])
            }}
          />
          <Tooltip placement="left" title={t('last_refresh_at', "Refreshed {at}", { at: Moment.unix(refreshAt).fromNow() })}>
            <Button className="margin-l-s" size="small" type="default" shape="circle" icon={<ReloadOutlined />} onClick={refresh} />
          </Tooltip>
        </span>
      </Col>
    </Row>


    <div className="block">
      <div className="dash-kpi-container">
        <div className="dash-kpi border-right-1">
          <div className="widget-title size-13">Users</div>
          <div className="dash-kpi-value">{Numeral(currentUsers).format('0,0')}</div>
          <div className="dash-kpi-variation">{Formatters.growthRate(currentUsers, previousUsers)}</div>
        </div>

        <div className="dash-kpi border-right-1">
          <div className="widget-title size-13">New signups</div>
          <div className="dash-kpi-value">{Numeral(currentNewSignups).format('0,0')}</div>
          <div className="dash-kpi-variation">{Formatters.growthRate(currentNewSignups, previousNewSignups)}</div>
        </div>

        <div className="dash-kpi border-right-1">
          <div className="widget-title size-13">Authenticated</div>
          <div className="dash-kpi-value">{Numeral(currentAuthenticated).format('0,0')}</div>
          <div className="dash-kpi-variation">{Formatters.growthRate(currentAuthenticated, previousAuthenticated)}</div>
        </div>

        <div className="dash-kpi border-right-1">
          <div className="widget-title size-13">Sessions</div>
          <div className="dash-kpi-value">{Numeral(currentSessions).format('0,0')}</div>
          <div className="dash-kpi-variation">{Formatters.growthRate(currentSessions, previousSessions)}</div>
        </div>

        <div className="dash-kpi border-right-1">
          <div className="widget-title size-13">Orders</div>
          <div className="dash-kpi-value">{Numeral(currentOrders).format('0,0')}</div>
          <div className="dash-kpi-variation">{Formatters.growthRate(currentOrders, previousOrders)}</div>
        </div>

        <div className="dash-kpi border-right-1">
          <div className="widget-title size-13">Revenue</div>
          <div className="dash-kpi-value">{Formatters.currency(currency, currentRevenue)}</div>
          <div className="dash-kpi-variation">{Formatters.growthRate(currentRevenue, previousRevenue)}</div>
        </div>

        <div className="dash-kpi border-right-1">
          <div className="widget-title size-13">Avg. Cart</div>
          <div className="dash-kpi-value">{Formatters.currency(currency, currentAverageCart)}</div>
          <div className="dash-kpi-variation">{Formatters.growthRate(currentAverageCart, previousAverageCart)}</div>
        </div>
      </div>
    </div>

    <Table
      dataSource={data}
      // sticky={true}
      rowKey={(x: any) => x.project.id}
      size="middle"
      // onChange={handleTableChange}
      pagination={false}
      className="block margin-t-l"
      // rowClassName={record => cn({ 'table-all-total': record.id === '_all' })}
      columns={[
        {
          title: '',
          key: 'project',
          render: (record: any) => {
            return record.project.name
          }
        },
        {
          title: 'Users',
          key: 'users',
          render: (record: any) => {
            if (record.loaded === false) return <Spin size="small" />
            return <>
              {Numeral(record.current['TimelineSessions.uniqueUsers']).format('0,0')}
              <div className="size-10">{Formatters.growthRate(record.current['TimelineSessions.uniqueUsers'], record.previous['TimelineSessions.uniqueUsers'])}</div>
            </>
          }
        },
        {
          title: 'New signups',
          key: 'signups',
          render: (record: any) => {
            if (record.loaded === false) return <Spin size="small" />
            return <>
              {Numeral(record.current['TimelineSignups.count']).format('0,0')}
              <div className="size-10">{Formatters.growthRate(record.current['TimelineSignups.count'], record.previous['TimelineSignups.count'])}</div>
            </>
          }
        },
        {
          title: 'Authenticated',
          key: 'authenticated',
          render: (record: any) => {
            if (record.loaded === false) return <Spin size="small" />
            return <>
              {Numeral(record.current['ActiveUsers.count']).format('0,0')}
              <div className="size-10">{Formatters.growthRate(record.current['ActiveUsers.count'], record.previous['ActiveUsers.count'])}</div>
            </>
          }
        },
        {
          title: 'Sessions',
          key: 'sessions',
          render: (record: any) => {
            if (record.loaded === false) return <Spin size="small" />
            return <>
              {Numeral(record.current['TimelineSessions.count']).format('0,0')}
              <div className="size-10">{Formatters.growthRate(record.current['TimelineSessions.count'], record.previous['TimelineSessions.count'])}</div>
            </>
          }
        },
        {
          title: 'Orders',
          key: 'orders',
          render: (record: any) => {
            if (record.loaded === false) return <Spin size="small" />
            return <>
              {Numeral(record.current['TimelineOrders.count']).format('0,0')}
              <div className="size-10">{Formatters.growthRate(record.current['TimelineOrders.count'], record.previous['TimelineOrders.count'])}</div>
            </>
          }
        },
        {
          title: 'Revenue',
          key: 'revenue',
          render: (record: any) => {
            if (record.loaded === false) return <Spin size="small" />
            return <>
              {Formatters.currency(record.project.currency, record.current['TimelineOrders.revenue'])}
              <div className="size-10">{Formatters.growthRate(record.current['TimelineOrders.revenue'], record.previous['TimelineOrders.revenue'])}</div>
            </>
          }
        },
        {
          title: 'Avg. cart',
          key: 'averageCart',
          render: (record: any) => {
            if (record.loaded === false) return <Spin size="small" />
            return <>
              {Formatters.currency(record.project.currency, record.current['TimelineOrders.averageCart'])}
              <div className="size-10">{Formatters.growthRate(record.current['TimelineOrders.averageCart'], record.previous['TimelineOrders.averageCart'])}</div>
            </>
          }
        },
      ]}
      indentSize={10}
    // summary={() => (
    //     <Table.Summary fixed="top">
    //         <Table.Summary.Row>
    //             <Table.Summary.Cell index={0} />
    //             <Table.Summary.Cell index={1} colSpan={2}>
    //                 Summary
    //             </Table.Summary.Cell>
    //             <Table.Summary.Cell index={3} colSpan={8}>
    //                 Content
    //             </Table.Summary.Cell>
    //             <Table.Summary.Cell index={11} colSpan={2}>
    //                 Right
    //             </Table.Summary.Cell>
    //         </Table.Summary.Row>
    //     </Table.Summary>
    // )}
    />
  </div>
}

export default OrganizationsGlobalStats