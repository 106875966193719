import React, { Component } from 'react'

import t from '../../utils/translate'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import ProjectSettingsForm from './_settingsForm'


class ProjectSetupSettings extends Component<any, any> {

  render() {

    return (
      <div>
        <div className="padding-a-m">
          <ProjectSettingsForm {...this.props} onComplete={() => this.props.setStep('settings')} />
        </div>
        <div className="padding-a-m">
          <span className="pull-right">
            <Button type="primary" onClick={(e: any) => this.props.setStep('settings')}>{t('next', "Next")} <ArrowRightOutlined /></Button>
          </span>
          <Button type="ghost" onClick={(e: any) => this.props.setStep('domains')}><ArrowLeftOutlined /> {t('previous', "Previous")}</Button>
        </div>
      </div>
    );
  }
}

export default ProjectSetupSettings