import { useEffect, useState, useCallback } from 'react'

import {
  message,
  Radio,
  Spin,
} from 'antd';
import t from '../../../utils/translate'
import { get, toArray, forEach } from 'lodash'
import ChannelsDependenciesDiagram from './_channelsDependenciesDiagram'
import ChannelsDependenciesTable from './_channelsDependenciesTable'
import { useAnalyticsContext, AnalyticsContextValue } from './index'
import { useProjectContext, ProjectContextValue } from 'components/projects'
// import { useHistory } from 'react-router'

const ReportsChannelsDependencies = () => {

  const [data, setData] = useState<any>(undefined)
  const [loading, setLoading] = useState(false)
  const [view, setView] = useState('table')


  const analyticsContext: AnalyticsContextValue = useAnalyticsContext()
  const projectCtx: ProjectContextValue = useProjectContext()
  // const history = useHistory() as any
  // const didMount = useRef(false)


  const fetchReport = useCallback((query: any, noCache?: boolean) => {

    const cacheKey = Object.assign({ kind: 'reportChannelsDependencies' }, query)

    if (!noCache) {
      const cacheData = window.cmCache.getFromMap(cacheKey)

      if (cacheData) {
        setData(cacheData)
        setLoading(false)
        return
      }
    }

    setLoading(true)

    projectCtx.ajaxRequest({
      method: 'get',
      url: '/reports.channelsDependencies',
      params: query
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        message.error(errorMessage)
        setLoading(false)
        return
      }

      // console.log('res', response.data)

      var i = 0

      const currentNodes = get(response.data, 'report.currentPeriod.nodes', [])
      const currentLinks = get(response.data, 'report.currentPeriod.links', [])

      // change ids to int
      forEach(currentNodes, (node: any) => {
        // change links
        forEach(currentLinks, (link) => {
          if (link.source === node.id) {
            link.source = i
          }
          if (link.target === node.id) {
            link.target = i
          }
        })

        node.id = i
        i++
      })

      i = 0

      const previousNodes = get(response.data, 'report.previousPeriod.nodes', [])
      const previousLinks = get(response.data, 'report.previousPeriod.links', [])

      // change ids to int
      forEach(previousNodes, (node: any) => {
        // change links
        forEach(previousLinks, (link)  => {
          if (link.source === node.id) {
            link.source = i
          }
          if (link.target === node.id) {
            link.target = i
          }
        })

        node.id = i
        i++
      })

      const data = {
        currentPeriod: {
          nodes: toArray(currentNodes),
          links: toArray(currentLinks)
        },
        previousPeriod: {
          nodes: toArray(previousNodes),
          links: toArray(previousLinks)
        }
      }

      window.cmCache.setFromMap(cacheKey, data)

      // console.log('data', data)

      setData(data)
      setLoading(false)
    })
  }, [projectCtx])

  useEffect(() => {

    setLoading(true)

    fetchReport({
      projectId: projectCtx.currentProject.id,
      conversionRuleId: analyticsContext.conversionRule ? analyticsContext.conversionRule.id : undefined,
      conversionType: analyticsContext.conversionType,
      domainId: analyticsContext.domain,
      dateFrom: analyticsContext.dateFrom + ' 00:00:00',
      dateTo: analyticsContext.dateTo + ' 23:59:59',
      compareTo: analyticsContext.dateRangeVs,
      timezone: projectCtx.admin.timezone,
      segmentId: analyticsContext.segment,
    })

  }, [
    projectCtx.currentProject.id,
    analyticsContext.conversionRule,
    analyticsContext.conversionType,
    analyticsContext.domain,
    analyticsContext.dateFrom,
    analyticsContext.dateTo,
    analyticsContext.dateRangeVs,
    analyticsContext.segment,
    projectCtx.admin.timezone,
    analyticsContext.refreshAt,
    fetchReport,
  ])

  const changeView = (e: any) => {
    if (e) {
      setView(e.target.value)
    }
  }


  if (loading) {
    return <div className="margin-a-l text-center"><Spin size="large" spinning={true} /></div>
  }

  if (!data) {
    return <div className="margin-a-l text-center">{t('no_data', "No data...")}</div>
  }

  return <div>
    <div className="margin-a-m">
      {t('display_as', "View")}:
      <Radio.Group onChange={changeView} value={view} size="small" className="margin-l-s">
        <Radio.Button value="table">{t('table', "Table")}</Radio.Button>
        <Radio.Button value="diagram">{t('diagram', "Diagram")}</Radio.Button>
      </Radio.Group>
    </div>
    {view === 'table' && <ChannelsDependenciesTable data={data} project={projectCtx.currentProject} />}
    {view === 'diagram' && <ChannelsDependenciesDiagram data={data} />}
  </div>
}

export default ReportsChannelsDependencies


