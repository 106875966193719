import React, { Component } from 'react'

import t from '../../utils/translate'
import _ from 'lodash';
import { Row, Col, Drawer, Tag, Tooltip } from 'antd';
import Moment from 'moment-timezone';
import Formatters from '../../utils/formatters'
import Feed from '../timeline/_feed'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import Numeral from 'numeral'
import OrderDetails from './_details'
import QS from 'qs'

class ShowOrder extends Component<any, any> {
  _isMounted = false;

  constructor(props: any) {
    super(props)

    this.state = {
      loading: true,
      order: this.props.order || {}
    }

    this.onClose = this.onClose.bind(this)
    this.fetchOrder = this.fetchOrder.bind(this)
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchOrder()

    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Show order ' + this.props.externalId + ' (project: ' + this.props.projectLayout.project.id + ')',
        page: this.props.location.pathname,
        props: {
          orgId: this.props.projectLayout.project.organizationId,
          projectId: this.props.projectLayout.project.id
        }
      })
      window.cmAgent.dispatch()
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onClose() {
    let params = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })
    delete params['showOrder']
    this.props.history.push(window.location.pathname + '?' + QS.stringify(params))
  }

  fetchOrder() {

    this.props.app.ajaxRequest({
      method: 'get',
      url: '/orders.show',
      params: {
        projectId: this.props.projectLayout.project.id,
        conversionRuleId: this.props.conversionRuleId,
        externalId: this.props.externalId,
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        // this.setState({loading: false})
        return
      }

      // console.log('res', response.data);
      if (this._isMounted) {
        this.setState({
          order: response.data.order,
          loading: false
        })
      }
    })
  }

  render() {

    let conversionRule: any = {}
    let segments: any = []
    let segmentsMap: any = {}
    let userName = ''

    if (this.state.order.id) {
      userName = this.state.order.user?.externalId

      if (this.state.order.user?.firstName && this.state.order.user?.firstName !== '') {
        userName = this.state.order.user?.firstName
      }

      if (this.state.order.user?.lastName && this.state.order.user?.lastName !== '') {
        userName += ' ' + this.state.order.user?.lastName
      }

      conversionRule = this.props.projectLayout.project.conversionRules.find((cr: any) => cr.id === this.state.order.conversionRuleId)
      segments = _.toArray(this.props.projectLayout.segments).filter(s => conversionRule.forCompanies ? s.forCompanies === true : !s.forCompanies)
      segmentsMap = _.keyBy(segments, (s) => s.id)
    }

    const icognitoURL = (url: any) => {
      const parser = document.createElement('a')
      parser.href = url;
      const params: any = QS.parse(parser.search, { ignoreQueryPrefix: true })
      params['_nocm'] = 1
      return parser.protocol + '//' + parser.hostname + parser.pathname + '?' + QS.stringify(params, { indices: false }) + parser.hash
    }

    let hasTimeline = false

    if (!this.state.loading && this.state.order.children && this.state.order.children.length) {
      hasTimeline = true
    }

    return <Drawer
      title={this.state.order.id && <span>{t('conversion', "Conversion")}: {this.state.order.conversionExternalId}</span>}
      visible={true}
      onClose={this.onClose}
      className="drawer drawer-bg"
      width={hasTimeline ? '95%' : '600px'}
    >
      <Row gutter={hasTimeline ? 24 : 0} className="margin-h-m">
        <Col span={hasTimeline ? 12 : 24}>
          {this.state.order.id && <>

            <div className="padding-v-m">
              {this.state.order.publicUrl && <span className="pull-right"><a href={icognitoURL(this.state.order.publicUrl)} target="_blank" rel="noopener noreferrer">{t('order_status_url', "View order status")} <FontAwesomeIcon icon="external-link-alt" /></a></span>}
              <span className="size-22">{Formatters.currency(this.props.projectLayout.project.currency, this.state.order.revenue, this.state.order.revenueSource, this.state.order.currency, 'color-kpi-purple')}</span>
            </div>

            <div className="block">
              <h2 className="mini">{t('user', 'User')}</h2>

              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td className="padding-a-m" style={{ width: '24px' }}>
                      {conversionRule.forCompanies === true ? <div>
                        {this.state.order.company.photoURL ? <img className="user-picture" src={this.state.order.company.photoURL} alt="" /> : <span className="user-picture opacity-30"><FontAwesomeIcon icon="building" /></span>}
                      </div>
                        :
                        <div>
                          {this.state.order.user?.photoURL ? <img className="user-picture" src={this.state.order.user?.photoURL} alt="" /> : <div className="user-picture-icon"><FontAwesomeIcon icon="user-circle" /></div>}
                        </div>}
                    </td>

                    <td className="padding-t-m">
                      {conversionRule.forCompanies === true ?
                        <div className="size-16 margin-b-s">{this.state.order.company.name || this.state.order.company.externalId}</div>
                        :
                        <div>
                          <span className="size-16 padding-r-m">
                            <span className="link" onClick={this.props.projectLayout.viewUser.bind(null, this.state.order.user?.externalId)}>{userName}</span>
                            {this.state.order.user?.gender && <span className="padding-l-s"><FontAwesomeIcon icon={this.state.order.user?.gender} style={{ color: 'rgba(0,0,0, 0.5)' }} /></span>}
                          </span>
                          {_.map(this.state.order.user?.segments, (s) => {
                            return <Tooltip title={Moment(s.enterAt).fromNow()} key={s.segmentId}><Tag className="margin-b-s no-pointer" color={segmentsMap[s.segmentId] && segmentsMap[s.segmentId].color}>{segmentsMap[s.segmentId] ? segmentsMap[s.segmentId].name : s.segmentId}</Tag></Tooltip>
                          })}
                        </div>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <OrderDetails app={this.props.app} projectLayout={this.props.projectLayout} order={this.state.order} user={this.state.order.user} />
          </>}
        </Col>

        {hasTimeline && <Col span={12}>
          <div className="block">
            <h2 className="mini">Timeline</h2>
            <Feed {...this.props} expanded={true} timeline={this.state.order.children || []} user={this.state.order.user} />
          </div>
        </Col>}
      </Row>

    </Drawer>
  }
}

export default ShowOrder