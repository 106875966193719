import { useRef, useState, useEffect, useCallback } from 'react'

import {
    Table,
    message,
    Spin,
    Popover,
    Tooltip,
    Dropdown,
    Menu,
    Button,
    Modal,
} from 'antd';
import { DownloadOutlined } from '@ant-design/icons'
import t from '../../../utils/translate'
import Formatters from '../../../utils/formatters'
import Numeral from 'numeral'
import QS from 'qs'
import _ from 'lodash'
import cn from 'classnames'
import Role from './_role'
import removeLeadingDates from 'utils/removeLeadingDates'
import { ResultSet, Query } from '@cubejs-client/core'
import { arrayToCSV, downloadCSV } from 'utils/csv'
import { useProjectContext, ProjectContextValue } from 'components/projects'
import { useAnalyticsContext, AnalyticsContextValue } from './index'
import { useHistory } from 'react-router'

const defaultSortKey = 'TimelineImpressions.count'

const Report = ({ setExtraToolbar }) => {

    const [data, setData] = useState<any>({})
    const [loading, setLoading] = useState(false)
    const [expandedRowKeys, setExpandedRowKeys] = useState([])
    const [loadingCSV, setLoadingCSV] = useState(false)
    const analyticsContext: AnalyticsContextValue = useAnalyticsContext()
    const projectCtx: ProjectContextValue = useProjectContext()
    const history = useHistory() as any
    const analyticsFingerprint = useRef('')


    // default sort order
    const sortKey = analyticsContext.sortKey || defaultSortKey
    const sortOrder = analyticsContext.sortOrder


    const generateQuery = useCallback((
        groupBy: string[],
        segmentId: string,
        conversionType: string,
        // domainId: string,
        dateFrom: string,
        dateTo: string,
        dateFromPrevious: string | null,
        dateToPrevious: string | null,
        dimensionFilters: any) => {

        const filters = [...dimensionFilters]

        if (segmentId !== '_all') {
            filters.push({
                'member': 'UserSegments.segmentId',
                'operator': 'equals',
                'values': [segmentId]
            })
        }

        if (conversionType !== 'all') {
            filters.push({
                'member': 'TimelineImpressions.isFirstConversion',
                'operator': 'equals',
                'values': [conversionType === 'acquisition' ? '1' : '0']
            })
        }

        const compareDateRange: any = [[dateFrom, dateTo]]

        if (dateFromPrevious && dateToPrevious) {
            compareDateRange.push([dateFromPrevious, dateToPrevious])
        }

        const opts: Query = {
            measures: [
                'TimelineImpressions.count',
                // 'TimelineImpressions.contributions',
                'TimelineImpressions.aloneCount',
                'TimelineImpressions.initiatorCount',
                'TimelineImpressions.assistingCount',
                'TimelineImpressions.closerCount',
                'TimelineImpressions.aloneRatio',
                'TimelineImpressions.initiatorRatio',
                'TimelineImpressions.assistingRatio',
                'TimelineImpressions.closerRatio',
                'TimelineImpressions.clickedRatio',
                'TimelineImpressions.aloneAttributionLinearConversions',
                'TimelineImpressions.initiatorAttributionLinearConversions',
                'TimelineImpressions.assistingAttributionLinearConversions',
                'TimelineImpressions.closerAttributionLinearConversions',
                'TimelineImpressions.aloneAttributionLinearRevenue',
                'TimelineImpressions.initiatorAttributionLinearRevenue',
                'TimelineImpressions.assistingAttributionLinearRevenue',
                'TimelineImpressions.closerAttributionLinearRevenue',
                'TimelineImpressions.viewResultUnknownRatio',
                'TimelineImpressions.viewResultDetectedRatio',
                'TimelineImpressions.viewResultNotDetectedRatio',
                'TimelineImpressions.viewResultNotMeasurableRatio',
                'TimelineImpressions.foldPositionUnknownRatio',
                'TimelineImpressions.foldPositionAboveRatio',
                'TimelineImpressions.foldPositionBelowRatio',
                'TimelineOrders.count',
                'TimelineOrders.revenue',
                'TimelineImpressions.attributionLinearRevenue',
                'TimelineImpressions.attributionLinearConversions',
                'TimelineOrders.averageCart',
            ],
            filters: filters,
            timeDimensions: [
                {
                    "dimension": 'TimelineImpressions.truncCreatedAt',
                    "granularity": null,
                    "compareDateRange": compareDateRange
                }
            ],
            timezone: analyticsContext.timezone,
            order: {
                [sortKey]: sortOrder === 'descend' ? 'desc' : 'asc'
            },
            limit: 1000
        }

        if (groupBy) {
            opts.dimensions = groupBy
        }

        return opts
    }, [analyticsContext.timezone, sortKey, sortOrder])

    const fetchData = useCallback((parentId: string, groupBy: string, opts: any) => {

        return new Promise((resolve, reject) => {
            analyticsContext.cubejsApi.load(opts).then((value: ResultSet) => {

                const [currentChannels, previousChannels] = value.decompose()

                const returned: any = []

                const lines = currentChannels.tablePivot()
                const previousLines = previousChannels.tablePivot()

                removeLeadingDates(lines)
                removeLeadingDates(previousLines)

                lines.forEach((line: any) => {
                    const previousFound = previousLines.find((x: any) => {
                        if (groupBy !== '_all') {
                            return x[groupBy] === line[groupBy]
                        } else {
                            return true
                        }
                    })

                    const returnedLine: any = {
                        name: groupBy !== '_all' ? line[groupBy] : '_all',
                        expandable: (groupBy !== '_all' && groupBy !== 'TimelineImpressions.adName') ? true : false,
                        childrenLoaded: false,
                        current: line,
                        previous: previousFound || {},
                    }

                    returnedLine.id = parentId !== '' ? parentId + '_' + returnedLine.name : returnedLine.name


                    if (returnedLine.expandable === true) {
                        returnedLine.children = [{ id: 'loading', name: 'loading' }]
                    }

                    returned.push(returnedLine)
                })

                resolve(returned)
                // return Analytics.CreateDataWithCurrentPrevious(currentChannelsTable, previousChannelsTable, groupBy)
            }).catch((error: any) => {

                console.log(error)

                let message = error.message

                if (error.response && error.response.status === 400) {
                    switch (error.response.data.message) {
                        default:
                            message = error.response.data.message
                    }
                }

                reject(message)
            })
        })
    }, [analyticsContext.cubejsApi])

    const loadRootData = useCallback((analyticsContext: AnalyticsContextValue) => {
        return new Promise((resolve, reject) => {

            setLoading(true)

            const filters: any = []

            const totalsData = fetchData('', '_all', generateQuery(
                null,
                analyticsContext.segment,
                analyticsContext.conversionType,
                // analyticsContext.domain,
                analyticsContext.dateFrom,
                analyticsContext.dateTo,
                analyticsContext.dateFromPrevious,
                analyticsContext.dateToPrevious,
                filters))

            const query = generateQuery(
                ['TimelineImpressions.sourceMedium'],
                analyticsContext.segment,
                analyticsContext.conversionType,
                // analyticsContext.domain,
                analyticsContext.dateFrom,
                analyticsContext.dateTo,
                analyticsContext.dateFromPrevious,
                analyticsContext.dateToPrevious,
                filters)

            const groupsData = fetchData('', 'TimelineImpressions.sourceMedium', query)

            // const sqlQuery = analyticsContext.cubejsApi.sql(query)

            Promise.all([
                groupsData,
                totalsData,
                // sqlQuery,
            ]).then((values: any) => {

                // console.log('values', values)
                // embed current filters to each line
                values[0].forEach((x: any) => {
                    x.parent = {
                        id: '',
                        kind: 'TimelineImpressions.sourceMedium',
                        filters: [...filters],
                    }
                })

                const newData = {
                    'groups': [...values[0], ...values[1]],
                }

                // values[2].forEach((queryData: SqlQuery) => {
                //     console.log(...queryData.rawQuery().sql)
                // })

                setLoading(false)
                setData(newData)
                setExpandedRowKeys([]) // reset expanded lines
                resolve(newData)

            }).catch(msg => {
                message.error(msg)
                setLoading(false)
                reject(msg)
            })
        })
    }, [fetchData, generateQuery])


    const onExportCSV = useCallback((key: string) => {

        // console.log('analyticsContext', analyticsContext)

        setLoadingCSV(true)

        const csvColumns: string[] = [
            'id',
            'name',
            'impressions',
            'clickedRatio',
            'viewResultUnknownRatio',
            'viewResultDetectedRatio',
            'viewResultNotDetectedRatio',
            'viewResultNotMeasurableRatio',
            'foldPositionUnknownRatio',
            'foldPositionAboveRatio',
            'foldPositionBelowRatio',
        ]



        if (analyticsContext.conversionRule) {
            csvColumns.push(
                'averageCart',
                'attributionContributiveConversions',
                'attributionContributiveRevenue',
                'attributionLinearConversions',
                'attributionLinearRevenue',
                'aloneAttributionLinearConversions',
                'initiatorAttributionLinearConversions',
                'assistingAttributionLinearConversions',
                'closerAttributionLinearConversions',
                'aloneAttributionLinearRevenue',
                'initiatorAttributionLinearRevenue',
                'assistingAttributionLinearRevenue',
                'closerAttributionLinearRevenue',
            )
        }

        csvColumns.push(
            'aloneContributions',
            'aloneRatio',
            'initiatorContributions',
            'initiatorRatio',
            'assistingContributions',
            'assistingRatio',
            'closerContributions',
            'closerRatio',

            'filterDateFrom',
            'filterDateTo',
            'filterTimezone',
            'filterUserSegment',
            // 'filterDomain',
            'filterAcquisitionOrRetention',
        )

        const csvArray: any[][] = [csvColumns]

        let q: any

        switch (key) {
            case 'postviews':
                loadRootData(analyticsContext).then((data: any) => {

                    // console.log('data', data)

                    data.groups.forEach((group: any) => {

                        const csvLine: any[] = [
                            group.id,
                            group.name,
                            group.current['TimelineImpressions.count'] || 0,
                            group.current['TimelineImpressions.clickedRatio'] || 0,
                            group.current['TimelineImpressions.viewResultUnknownRatio'] || 0,
                            group.current['TimelineImpressions.viewResultDetectedRatio'] || 0,
                            group.current['TimelineImpressions.viewResultNotDetectedRatio'] || 0,
                            group.current['TimelineImpressions.viewResultNotMeasurableRatio'] || 0,
                            group.current['TimelineImpressions.foldPositionUnknownRatio'] || 0,
                            group.current['TimelineImpressions.foldPositionAboveRatio'] || 0,
                            group.current['TimelineImpressions.foldPositionBelowRatio'] || 0,
                        ]

                        if (analyticsContext.conversionRule) {

                            csvLine.push((group.current['TimelineOrders.averageCart'] || 0) / 100)

                            if (group.id === '_all') {
                                csvLine.push(
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                )
                            } else {
                                csvLine.push(
                                    group.current['TimelineOrders.count'] || 0,
                                    (group.current['TimelineOrders.revenue'] || 0) / 100,
                                    group.current['TimelineImpressions.attributionLinearConversions'] || 0,
                                    (group.current['TimelineImpressions.attributionLinearRevenue'] || 0) / 100,
                                    group.current['TimelineImpressions.aloneAttributionLinearConversions'],
                                    group.current['TimelineImpressions.initiatorAttributionLinearConversions'],
                                    group.current['TimelineImpressions.assistingAttributionLinearConversions'],
                                    group.current['TimelineImpressions.closerAttributionLinearConversions'],
                                    (group.current['TimelineImpressions.aloneAttributionLinearRevenue'] || 0) / 100,
                                    (group.current['TimelineImpressions.initiatorAttributionLinearRevenue'] || 0) / 100,
                                    (group.current['TimelineImpressions.assistingAttributionLinearRevenue'] || 0) / 100,
                                    (group.current['TimelineImpressions.closerAttributionLinearRevenue'] || 0) / 100,
                                )
                            }
                        }

                        csvLine.push(
                            group.current['TimelineImpressions.aloneCount'] || 0,
                            group.current['TimelineImpressions.aloneRatio'] || 0,
                            group.current['TimelineImpressions.initiatorCount'] || 0,
                            group.current['TimelineImpressions.initiatorRatio'] || 0,
                            group.current['TimelineImpressions.assistingCount'] || 0,
                            group.current['TimelineImpressions.assistingRatio'] || 0,
                            group.current['TimelineImpressions.closerCount'] || 0,
                            group.current['TimelineImpressions.closerRatio'] || 0,

                            analyticsContext.dateFrom + ' 00:00:00',
                            analyticsContext.dateTo + ' 23:59:59',
                            analyticsContext.timezone,
                            analyticsContext.segment,
                            // analyticsContext.domain,
                            analyticsContext.conversionType,
                        )

                        csvArray.push(csvLine)
                    })

                    const filename = key + '_' + analyticsContext.dateFrom + '_' + analyticsContext.dateTo + '.csv'
                    downloadCSV(arrayToCSV(csvArray), filename)
                    setLoadingCSV(false)
                }).catch(_err => {
                    setLoadingCSV(false)
                })
                break

            case 'postviews_formats':

                q = generateQuery(
                    ['TimelineImpressions.sourceMedium', 'TimelineImpressions.campaignName'],
                    analyticsContext.segment,
                    analyticsContext.conversionType,
                    analyticsContext.dateFrom,
                    analyticsContext.dateTo,
                    null,
                    null,
                    [])

                analyticsContext.cubejsApi.load(q).then((value: ResultSet) => {

                    // const [currentChannels, previousChannels] = value.decompose()

                    const data = value.tablePivot()

                    removeLeadingDates(data)
                    // console.log('data', data)

                    data.forEach((line: any) => {

                        const csvLine: any[] = [
                            line['TimelineImpressions.sourceMedium'] + ' > ' + line['TimelineImpressions.campaignName'],
                            line['TimelineImpressions.sourceMedium'] + ' > ' + line['TimelineImpressions.campaignName'],
                            line['TimelineImpressions.count'] || 0,
                            line['TimelineImpressions.clickedRatio'] || 0,
                            line['TimelineImpressions.viewResultUnknownRatio'] || 0,
                            line['TimelineImpressions.viewResultDetectedRatio'] || 0,
                            line['TimelineImpressions.viewResultNotDetectedRatio'] || 0,
                            line['TimelineImpressions.viewResultNotMeasurableRatio'] || 0,
                            line['TimelineImpressions.foldPositionUnknownRatio'] || 0,
                            line['TimelineImpressions.foldPositionAboveRatio'] || 0,
                            line['TimelineImpressions.foldPositionBelowRatio'] || 0,
                        ]

                        if (analyticsContext.conversionRule) {

                            csvLine.push((line['TimelineOrders.averageCart'] || 0) / 100)

                            if (line.id === '_all') {
                                csvLine.push(
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                )
                            } else {
                                csvLine.push(
                                    line['TimelineOrders.count'] || 0,
                                    (line['TimelineOrders.revenue'] || 0) / 100,
                                    line['TimelineImpressions.attributionLinearConversions'] || 0,
                                    (line['TimelineImpressions.attributionLinearRevenue'] || 0) / 100,
                                    line['TimelineImpressions.aloneAttributionLinearConversions'],
                                    line['TimelineImpressions.initiatorAttributionLinearConversions'],
                                    line['TimelineImpressions.assistingAttributionLinearConversions'],
                                    line['TimelineImpressions.closerAttributionLinearConversions'],
                                    (line['TimelineImpressions.aloneAttributionLinearRevenue'] || 0) / 100,
                                    (line['TimelineImpressions.initiatorAttributionLinearRevenue'] || 0) / 100,
                                    (line['TimelineImpressions.assistingAttributionLinearRevenue'] || 0) / 100,
                                    (line['TimelineImpressions.closerAttributionLinearRevenue'] || 0) / 100,
                                )
                            }
                        }

                        csvLine.push(
                            line['TimelineImpressions.aloneCount'] || 0,
                            line['TimelineImpressions.aloneRatio'] || 0,
                            line['TimelineImpressions.initiatorCount'] || 0,
                            line['TimelineImpressions.initiatorRatio'] || 0,
                            line['TimelineImpressions.assistingCount'] || 0,
                            line['TimelineImpressions.assistingRatio'] || 0,
                            line['TimelineImpressions.closerCount'] || 0,
                            line['TimelineImpressions.closerRatio'] || 0,

                            analyticsContext.dateFrom + ' 00:00:00',
                            analyticsContext.dateTo + ' 23:59:59',
                            analyticsContext.timezone,
                            analyticsContext.segment,
                            // analyticsContext.domain,
                            analyticsContext.conversionType,
                        )

                        csvArray.push(csvLine)
                    })

                    // console.log(csvContent)
                    const filename = key + '_' + analyticsContext.dateFrom + '_' + analyticsContext.dateTo + '.csv'
                    downloadCSV(arrayToCSV(csvArray), filename)
                    setLoadingCSV(false)

                }).catch(_err => {
                    setLoadingCSV(false)
                })
                break

            case 'postviews_ads':

                q = generateQuery(
                    ['TimelineImpressions.sourceMedium', 'TimelineImpressions.campaignName', 'TimelineImpressions.adName'],
                    analyticsContext.segment,
                    analyticsContext.conversionType,
                    analyticsContext.dateFrom,
                    analyticsContext.dateTo,
                    null,
                    null,
                    [])

                analyticsContext.cubejsApi.load(q).then((value: ResultSet) => {

                    // const [currentChannels, previousChannels] = value.decompose()

                    const data = value.tablePivot()

                    removeLeadingDates(data)
                    // console.log('data', data)

                    data.forEach((line: any) => {

                        const csvLine: any[] = [
                            line['TimelineImpressions.sourceMedium'] + ' > ' + line['TimelineImpressions.campaignName'] + ' > ' + line['TimelineImpressions.adName'],
                            line['TimelineImpressions.sourceMedium'] + ' > ' + line['TimelineImpressions.campaignName'] + ' > ' + line['TimelineImpressions.adName'],
                            line['TimelineImpressions.count'] || 0,
                            line['TimelineImpressions.clickedRatio'] || 0,
                            line['TimelineImpressions.viewResultUnknownRatio'] || 0,
                            line['TimelineImpressions.viewResultDetectedRatio'] || 0,
                            line['TimelineImpressions.viewResultNotDetectedRatio'] || 0,
                            line['TimelineImpressions.viewResultNotMeasurableRatio'] || 0,
                            line['TimelineImpressions.foldPositionUnknownRatio'] || 0,
                            line['TimelineImpressions.foldPositionAboveRatio'] || 0,
                            line['TimelineImpressions.foldPositionBelowRatio'] || 0,
                        ]

                        if (analyticsContext.conversionRule) {

                            csvLine.push((line['TimelineOrders.averageCart'] || 0) / 100)

                            if (line.id === '_all') {
                                csvLine.push(
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                )
                            } else {
                                csvLine.push(
                                    line['TimelineOrders.count'] || 0,
                                    (line['TimelineOrders.revenue'] || 0) / 100,
                                    line['TimelineImpressions.attributionLinearConversions'] || 0,
                                    (line['TimelineImpressions.attributionLinearRevenue'] || 0) / 100,
                                    line['TimelineImpressions.aloneAttributionLinearConversions'],
                                    line['TimelineImpressions.initiatorAttributionLinearConversions'],
                                    line['TimelineImpressions.assistingAttributionLinearConversions'],
                                    line['TimelineImpressions.closerAttributionLinearConversions'],
                                    (line['TimelineImpressions.aloneAttributionLinearRevenue'] || 0) / 100,
                                    (line['TimelineImpressions.initiatorAttributionLinearRevenue'] || 0) / 100,
                                    (line['TimelineImpressions.assistingAttributionLinearRevenue'] || 0) / 100,
                                    (line['TimelineImpressions.closerAttributionLinearRevenue'] || 0) / 100,
                                )
                            }

                            csvLine.push((line['TimelineOrders.averageCart'] || 0) / 100)
                        }

                        csvLine.push(
                            line['TimelineImpressions.aloneCount'] || 0,
                            line['TimelineImpressions.aloneRatio'] || 0,
                            line['TimelineImpressions.initiatorCount'] || 0,
                            line['TimelineImpressions.initiatorRatio'] || 0,
                            line['TimelineImpressions.assistingCount'] || 0,
                            line['TimelineImpressions.assistingRatio'] || 0,
                            line['TimelineImpressions.closerCount'] || 0,
                            line['TimelineImpressions.closerRatio'] || 0,

                            analyticsContext.dateFrom + ' 00:00:00',
                            analyticsContext.dateTo + ' 23:59:59',
                            analyticsContext.timezone,
                            analyticsContext.segment,
                            // analyticsContext.domain,
                            analyticsContext.conversionType,
                        )

                        csvArray.push(csvLine)
                    })

                    const filename = key + '_' + analyticsContext.dateFrom + '_' + analyticsContext.dateTo + '.csv'
                    downloadCSV(arrayToCSV(csvArray), filename)
                    setLoadingCSV(false)

                }).catch(_err => {
                    setLoadingCSV(false)
                })
                break
            default:
        }
    }, [analyticsContext, generateQuery, loadRootData])

    // onmount  + update
    useEffect(() => {

        // we need to update the toolbar otherwise onExportCSV doesnt pick the actual context values
        // to avoid infinite loop we compare analytics context values
        const fingerprint = JSON.stringify(analyticsContext)
    
        if (analyticsFingerprint.current === fingerprint) return
        analyticsFingerprint.current = fingerprint

        loadRootData(analyticsContext)
        // we need to update the toolbar otherwise onExportCSV doesnt pick the actual context values
        setExtraToolbar(<Tooltip placement="topRight" title="Download CSV">
            <Dropdown className="margin-l-s" trigger={['click']} overlay={<Menu onClick={(e: any) => onExportCSV(e.key)}>
                <Menu.Item key="postviews">Postviews</Menu.Item>
                <Menu.Item key="postviews_formats">Formats</Menu.Item>
                <Menu.Item key="postviews_ads">Ads</Menu.Item>
            </Menu>}>
                <Button size="small">
                    <DownloadOutlined />
                </Button>
            </Dropdown>
        </Tooltip>)
    }, [loadRootData, analyticsContext, onExportCSV, setExtraToolbar])


    const onExpand = (expanded: boolean, record: any) => {
        if (record.expandable === false) return

        if (expanded) {
            // add key
            const newKeys: any = [...expandedRowKeys]
            newKeys.push(record.id)
            setExpandedRowKeys(newKeys)
        } else {
            // remove eventual key
            const newKeys: any = expandedRowKeys.filter((x: any) => x !== record.id)
            setExpandedRowKeys(newKeys)
            return
        }

        let groupBy = 'TimelineImpressions.campaignName'
        let member = 'TimelineImpressions.sourceMedium'

        if (record.current['TimelineImpressions.campaignName']) {
            groupBy = 'TimelineImpressions.adName'
            member = 'TimelineImpressions.campaignName'
        }

        if (record.childrenLoaded === true) return

        const filters = [...record.parent.filters]

        // add current filter
        filters.push({
            'member': member,
            'operator': 'equals',
            'values': [record.name]
        })

        fetchData(record.id, groupBy, generateQuery(
            [groupBy],
            analyticsContext.segment,
            analyticsContext.conversionType,
            analyticsContext.dateFrom,
            analyticsContext.dateTo,
            analyticsContext.dateFromPrevious,
            analyticsContext.dateToPrevious,
            filters,
        )).then((value: any) => {

            // add parent filter to the children
            value.forEach((x: any) => {
                x.parent = {
                    id: record.id,
                    kind: member,
                    filters: [...filters],
                }
            })

            // if expand group
            if (member === 'TimelineImpressions.sourceMedium') {
                const groupLine = data['groups'].find((x: any) => x.id === record.id)
                groupLine.children = value
                groupLine.childrenLoaded = true
            }

            if (member === 'TimelineImpressions.campaignName') {

                data['groups'].forEach((group: any) => {
                    if (group.children) {
                        group.children.forEach((ch: any) => {
                            if (ch.id === record.id) {
                                ch.children = value
                                ch.childrenLoaded = true
                            }
                        })
                    }
                })
            }

            // console.log('DATA', value)

            const newData = { ...data }
            setData(newData)
        })

        // console.log(groupId)
    }


    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        // console.log('filters', filters);
        // console.log('pagination', pagination);
        // console.log('sorter', sorter);
        // const params: any = QS.parse(props.location.search, { ignoreQueryPrefix: true })

        const newParams = _.assign({
            sortKey: sortKey || 'sessions',
            sortOrder: sortOrder || 'descend',
        }, {
            sortKey: sorter.columnKey,
            sortOrder: sorter.order,
        })

        history.push('/organizations/' + projectCtx.currentOrganization.id + '/projects/' + projectCtx.currentProject.id + '/analytics/web?tab=postviews&' + QS.stringify(newParams, { indices: false }));
    }

    const tableData = []

    if (data['groups']) {
        tableData.push(...data['groups'])
    }

    // console.log(tableData)

    const columns: any = [
        {
            title: 'Source / Medium',
            className: 'table-border-right bg-white',
            // onClick: (e: any) => {
            //   console.log('click', e)
            // },
            width: 250,
            fixed: 'left',
            key: 'sourceMedium',
            render: (record: any) => {
                if (record.name === '_all') return ''
                if (record.name === 'loading') return <Spin size="small" />
                return record.name
            }
        },
        {
            title: 'Impressions attributed',
            width: 100 + 100 + 130 + 130,
            className: 'table-border-right table-border-bottom',
            children: [
                {
                    title: 'Total',
                    width: 100,
                    key: 'TimelineImpressions.count',
                    defaultSortOrder: 'descend',
                    sorter: (a: any, b: any) => {
                        if (a.id === 'loading') return 0

                        if (a.id === '_all' && sortOrder === 'descend') return 1
                        if (a.id === '_all' && sortOrder === 'ascend') return -1
                        if (b.id === '_all' && sortOrder === 'descend') return -1
                        if (b.id === '_all' && sortOrder === 'ascend') return 1

                        if (a.current['TimelineImpressions.count'] > b.current['TimelineImpressions.count']) return 1
                        if (a.current['TimelineImpressions.count'] < b.current['TimelineImpressions.count']) return -1
                        return 0
                    },
                    sortDirections: ['descend', 'ascend'],
                    sortOrder: sortKey === 'TimelineImpressions.count' ? sortOrder : undefined,
                    render: (record: any) => {
                        if (record.name === 'loading') return <Spin size="small" />
                        return <div>
                            {Numeral(record.current['TimelineImpressions.count']).format('0,0')}
                            <div className="size-10">{Formatters.growthRate(record.current['TimelineImpressions.count'], record.previous['TimelineImpressions.count'])}</div>
                        </div>
                    }
                },
                {
                    title: 'Clicked',
                    width: 100,
                    key: 'TimelineImpressions.clickedRatio',
                    defaultSortOrder: 'descend',
                    sorter: (a: any, b: any) => {
                        if (a.id === 'loading') return 0

                        if (a.id === '_all' && sortOrder === 'descend') return 1
                        if (a.id === '_all' && sortOrder === 'ascend') return -1
                        if (b.id === '_all' && sortOrder === 'descend') return -1
                        if (b.id === '_all' && sortOrder === 'ascend') return 1

                        if (a.current['TimelineImpressions.clickedRatio'] > b.current['TimelineImpressions.clickedRatio']) return 1
                        if (a.current['TimelineImpressions.clickedRatio'] < b.current['TimelineImpressions.clickedRatio']) return -1
                        return 0
                    },
                    sortDirections: ['descend', 'ascend'],
                    sortOrder: sortKey === 'TimelineImpressions.clickedRatio' ? sortOrder : undefined,
                    render: (record: any) => {
                        if (record.name === 'loading') return <Spin size="small" />
                        return <div>
                            {Formatters.percentage(record.current['TimelineImpressions.clickedRatio'])}
                            <div className="size-10">{Formatters.growthRate(record.current['TimelineImpressions.clickedRatio'], record.previous['TimelineImpressions.clickedRatio'])}</div>
                        </div>
                    }
                },
                {
                    title: 'View result',
                    width: 130,
                    key: 'viewResult',
                    render: (record: any) => {
                        if (record.name === 'loading') return <Spin size="small" />
                        if (record.name === '_all') {
                            return
                        }

                        const content = <table style={{ width: '280px' }}>
                            <tbody>
                                <tr>
                                    <td className="padding-b-s">Unknown</td>
                                    <td className="padding-b-s">
                                        <span style={{ width: record.current['TimelineImpressions.viewResultUnknownRatio'] + 'px', display: 'inline-block', backgroundColor: '#607D8B', height: '5px' }}></span> {Numeral(record.current['TimelineImpressions.viewResultUnknownRatio']).format('0.[0]')}%
                                        <div className="size-10">{Formatters.growthRate(record.current['TimelineImpressions.viewResultUnknownRatio'], record.previous['TimelineImpressions.viewResultUnknownRatio'])}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="padding-b-s">Detected</td>
                                    <td className="padding-b-s">
                                        <span style={{ width: record.current['TimelineImpressions.viewResultDetectedRatio'] + 'px', display: 'inline-block', backgroundColor: '#00C853', height: '5px' }}></span> {Numeral(record.current['TimelineImpressions.viewResultDetectedRatio']).format('0.[0]')}%
                                        <div className="size-10">{Formatters.growthRate(record.current['TimelineImpressions.viewResultDetectedRatio'], record.previous['TimelineImpressions.viewResultDetectedRatio'])}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="padding-b-s">Not Detected</td>
                                    <td className="padding-b-s">
                                        <span style={{ width: record.current['TimelineImpressions.viewResultNotDetectedRatio'] + 'px', display: 'inline-block', backgroundColor: '#DD2C00', height: '5px' }}></span> {Numeral(record.current['TimelineImpressions.viewResultNotDetectedRatio']).format('0.[0]')}%
                                        <div className="size-10">{Formatters.growthRate(record.current['TimelineImpressions.viewResultNotDetectedRatio'], record.previous['TimelineImpressions.viewResultNotDetectedRatio'])}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="padding-b-s">Not Measurable</td>
                                    <td className="padding-b-s">
                                        <span style={{ width: record.current['TimelineImpressions.viewResultNotMeasurableRatio'] + 'px', display: 'inline-block', backgroundColor: '#78909C', height: '5px' }}></span> {Numeral(record.current['TimelineImpressions.viewResultNotMeasurableRatio']).format('0.[0]')}%
                                        <div className="size-10">{Formatters.growthRate(record.current['TimelineImpressions.viewResultNotMeasurableRatio'], record.previous['TimelineImpressions.viewResultNotMeasurableRatio'])}</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        return <div>
                            <Popover content={content} title={null} trigger={['hover', 'onClick']} placement="left" className="padding-v-m">
                                <div style={{ cursor: 'help', width: '65px' }}>
                                    <div style={{ marginBottom: '2px', width: (record.current['TimelineImpressions.viewResultUnknownRatio'] || 0) + '%', backgroundColor: '#607D8B', height: '3px' }}></div>
                                    <div style={{ marginBottom: '2px', width: (record.current['TimelineImpressions.viewResultDetectedRatio'] || 0) + '%', backgroundColor: '#00C853', height: '3px' }}></div>
                                    <div style={{ marginBottom: '2px', width: (record.current['TimelineImpressions.viewResultNotDetectedRatio'] || 0) + '%', backgroundColor: '#DD2C00', height: '3px' }}></div>
                                    <div style={{ marginBottom: '2px', width: (record.current['TimelineImpressions.viewResultNotMeasurableRatio'] || 0) + '%', backgroundColor: '#78909C', height: '3px' }}></div>
                                </div>
                            </Popover>
                        </div>
                    }
                },
                {
                    title: 'Fold position',
                    width: 130,
                    key: 'foldPosition',
                    className: 'table-border-right',
                    render: (record: any) => {
                        if (record.name === 'loading') return <Spin size="small" />
                        if (record.name === '_all') {
                            return
                        }

                        const content = <table style={{ width: '280px' }}>
                            <tbody>
                                <tr>
                                    <td className="padding-b-s">Unknown</td>
                                    <td className="padding-b-s">
                                        <span style={{ width: record.current['TimelineImpressions.foldPositionUnknownRatio'] + 'px', display: 'inline-block', backgroundColor: '#607D8B', height: '5px' }}></span> {Numeral(record.current['TimelineImpressions.foldPositionUnknownRatio']).format('0.[0]')}%
                                        <div className="size-10">{Formatters.growthRate(record.current['TimelineImpressions.foldPositionUnknownRatio'], record.previous['TimelineImpressions.foldPositionUnknownRatio'])}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="padding-b-s">Above</td>
                                    <td className="padding-b-s">
                                        <span style={{ width: record.current['TimelineImpressions.foldPositionAboveRatio'] + 'px', display: 'inline-block', backgroundColor: '#00C853', height: '5px' }}></span> {Numeral(record.current['TimelineImpressions.foldPositionAboveRatio']).format('0.[0]')}%
                                        <div className="size-10">{Formatters.growthRate(record.current['TimelineImpressions.foldPositionAboveRatio'], record.previous['TimelineImpressions.foldPositionAboveRatio'])}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="padding-b-s">Below</td>
                                    <td className="padding-b-s">
                                        <span style={{ width: record.current['TimelineImpressions.foldPositionBelowRatio'] + 'px', display: 'inline-block', backgroundColor: '#DD2C00', height: '5px' }}></span> {Numeral(record.current['TimelineImpressions.foldPositionBelowRatio']).format('0.[0]')}%
                                        <div className="size-10">{Formatters.growthRate(record.current['TimelineImpressions.foldPositionBelowRatio'], record.previous['TimelineImpressions.foldPositionBelowRatio'])}</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        return <div>
                            <Popover content={content} title={null} trigger={['hover', 'onClick']} placement="left" className="padding-v-m">
                                <div style={{ cursor: 'help', width: '65px' }}>
                                    <div style={{ marginBottom: '2px', width: (record.current['TimelineImpressions.foldPositionUnknownRatio'] || 0) + '%', backgroundColor: '#607D8B', height: '3px' }}></div>
                                    <div style={{ marginBottom: '2px', width: (record.current['TimelineImpressions.foldPositionAboveRatio'] || 0) + '%', backgroundColor: '#00C853', height: '3px' }}></div>
                                    <div style={{ marginBottom: '2px', width: (record.current['TimelineImpressions.foldPositionBelowRatio'] || 0) + '%', backgroundColor: '#DD2C00', height: '3px' }}></div>
                                </div>
                            </Popover>
                        </div>
                    }
                },
            ]
        }]


    if (analyticsContext.conversionRule) {

        const attributionColumns: any = []

        if (analyticsContext.attribution === 'linear') {
            attributionColumns.push({
                title: 'Linear conversions',
                width: 130,
                key: 'TimelineImpressions.attributionLinearConversions',
                sorter: (a: any, b: any) => {
                    if (a.id === 'loading') return 0

                    if (a.id === '_all' && sortOrder === 'descend') return 1
                    if (a.id === '_all' && sortOrder === 'ascend') return -1
                    if (b.id === '_all' && sortOrder === 'descend') return -1
                    if (b.id === '_all' && sortOrder === 'ascend') return 1

                    if (a.current['TimelineImpressions.attributionLinearConversions'] > b.current['TimelineImpressions.attributionLinearConversions']) return 1
                    if (a.current['TimelineImpressions.attributionLinearConversions'] < b.current['TimelineImpressions.attributionLinearConversions']) return -1
                    return 0
                },
                defaultSortOrder: 'descend',
                sortDirections: ['descend', 'ascend'],
                sortOrder: sortKey === 'TimelineImpressions.attributionLinearConversions' ? sortOrder : undefined,
                render: (record: any) => {
                    if (record.name === '_all') return ''
                    if (record.name === 'loading') return <Spin size="small" />
                    return <div>
                        {Numeral(record.current['TimelineImpressions.attributionLinearConversions']).format('0,0[.]0')}
                        <div className="size-10">{Formatters.growthRate(record.current['TimelineImpressions.attributionLinearConversions'], record.previous['TimelineOrders.count'])}</div>
                    </div>
                }
            }, {
                title: "Linear revenue",
                width: 130,
                key: 'TimelineImpressions.attributionLinearRevenue',
                sorter: (a: any, b: any) => {
                    if (a.id === 'loading') return 0

                    if (a.id === '_all' && sortOrder === 'descend') return 1
                    if (a.id === '_all' && sortOrder === 'ascend') return -1
                    if (b.id === '_all' && sortOrder === 'descend') return -1
                    if (b.id === '_all' && sortOrder === 'ascend') return 1

                    if (a.current['TimelineImpressions.attributionLinearRevenue'] > b.current['TimelineImpressions.attributionLinearRevenue']) return 1
                    if (a.current['TimelineImpressions.attributionLinearRevenue'] < b.current['TimelineImpressions.attributionLinearRevenue']) return -1
                    return 0
                },
                defaultSortOrder: 'descend',
                sortDirections: ['descend', 'ascend'],
                sortOrder: sortKey === 'TimelineImpressions.attributionLinearRevenue' ? sortOrder : undefined,
                render: (record: any) => {
                    if (record.name === '_all') return ''
                    if (record.name === 'loading') return <Spin size="small" />
                    return <div>
                        {Formatters.currency(projectCtx.currentProject.currency, record.current['TimelineImpressions.attributionLinearRevenue'], undefined, undefined, undefined, undefined, true)}
                        <div className="size-10">{Formatters.growthRate(record.current['TimelineImpressions.attributionLinearRevenue'], record.previous['TimelineImpressions.attributionLinearRevenue'])}</div>
                    </div>
                }
            })
        }

        if (analyticsContext.attribution === 'contributive') {
            attributionColumns.push(
                {
                    title: 'Impacted conversions',
                    width: 130,
                    key: 'TimelineOrders.count',
                    sorter: (a: any, b: any) => {
                        if (a.id === 'loading') return 0

                        if (a.id === '_all' && sortOrder === 'descend') return 1
                        if (a.id === '_all' && sortOrder === 'ascend') return -1
                        if (b.id === '_all' && sortOrder === 'descend') return -1
                        if (b.id === '_all' && sortOrder === 'ascend') return 1

                        if (a.current['TimelineOrders.count'] > b.current['TimelineOrders.count']) return 1
                        if (a.current['TimelineOrders.count'] < b.current['TimelineOrders.count']) return -1
                        return 0
                    },
                    defaultSortOrder: 'descend',
                    sortDirections: ['descend', 'ascend'],
                    sortOrder: sortKey === 'TimelineOrders.count' ? sortOrder : undefined,
                    render: (record: any) => {
                        if (record.name === '_all') return
                        if (record.name === 'loading') return <Spin size="small" />
                        return <div>
                            {Numeral(record.current['TimelineOrders.count']).format('0,0')}
                            <div className="size-10">{Formatters.growthRate(record.current['TimelineOrders.count'], record.previous['TimelineOrders.count'])}</div>
                        </div>
                    }
                },
                // {
                //   title: t('contributions', "Contributions"),
                //   width: 130,
                //   key: 'TimelineImpressions.contributions',
                //   sorter: (a: any, b: any) => {
                //     if (a.id === 'loading') return 0

                //     if (a.id === '_all' && sortOrder === 'descend') return 1
                //     if (a.id === '_all' && sortOrder === 'ascend') return -1
                //     if (b.id === '_all' && sortOrder === 'descend') return -1
                //     if (b.id === '_all' && sortOrder === 'ascend') return 1

                //     if (a.current['TimelineImpressions.contributions'] > b.current['TimelineImpressions.contributions']) return 1
                //     if (a.current['TimelineImpressions.contributions'] < b.current['TimelineImpressions.contributions']) return -1
                //     return 0
                //   },
                //   defaultSortOrder: 'descend',
                //   sortDirections: ['descend', 'ascend'],
                //   sortOrder: sortKey === 'TimelineImpressions.contributions' ? sortOrder : undefined,
                //   render: (record: any) => {
                //     if (record.name === 'loading') return <Spin size="small" />
                //     return <div>
                //       {Numeral(record.current['TimelineImpressions.contributions']).format('0,0')}
                //       <div className="size-10">{Formatters.growthRate(record.current['TimelineImpressions.contributions'], record.previous['TimelineImpressions.contributions'])}</div>
                //     </div>
                //   }
                // },
                {
                    title: t('impacted_revenue', "Impacted revenue"),
                    width: 100,
                    key: 'TimelineOrders.revenue',
                    sorter: (a: any, b: any) => {
                        if (a.id === 'loading') return 0

                        if (a.id === '_all' && sortOrder === 'descend') return 1
                        if (a.id === '_all' && sortOrder === 'ascend') return -1
                        if (b.id === '_all' && sortOrder === 'descend') return -1
                        if (b.id === '_all' && sortOrder === 'ascend') return 1

                        if (a.current['TimelineOrders.revenue'] > b.current['TimelineOrders.revenue']) return 1
                        if (a.current['TimelineOrders.revenue'] < b.current['TimelineOrders.revenue']) return -1
                        return 0
                    },
                    defaultSortOrder: 'descend',
                    sortDirections: ['descend', 'ascend'],
                    sortOrder: sortKey === 'TimelineOrders.revenue' ? sortOrder : undefined,
                    render: (record: any) => {
                        if (record.name === '_all') return ''
                        if (record.name === 'loading') return <Spin size="small" />
                        return <div>
                            {Formatters.currency(projectCtx.currentProject.currency, record.current['TimelineOrders.revenue'], undefined, undefined, undefined, undefined, true)}
                            <div className="size-10">{Formatters.growthRate(record.current['TimelineOrders.revenue'], record.previous['TimelineOrders.revenue'])}</div>
                        </div>
                    }
                })
        }

        columns.push({
            title: <span>{analyticsContext.conversionRule.name}:&nbsp;
                {analyticsContext.conversionType === 'all' && t('all', "All")}
                {analyticsContext.conversionType === 'acquisition' && t('acquisition', "Acquisition")}
                {analyticsContext.conversionType === 'retention' && t('retention', "Retention")}
            </span>,
            width: 130 + 130 + 130 + 100 + 100 + 100,
            className: 'table-border-bottom',
            children: [
                ...attributionColumns,
                {
                    title: t('avg_cart', "Avg. cart"),
                    width: 100,
                    key: 'TimelineOrders.averageCart',
                    sorter: (a: any, b: any) => {
                        if (a.id === 'loading') return 0

                        if (a.id === '_all' && sortOrder === 'descend') return 1
                        if (a.id === '_all' && sortOrder === 'ascend') return -1
                        if (b.id === '_all' && sortOrder === 'descend') return -1
                        if (b.id === '_all' && sortOrder === 'ascend') return 1

                        if (a.current['TimelineOrders.averageCart'] > b.current['TimelineOrders.averageCart']) return 1
                        if (a.current['TimelineOrders.averageCart'] < b.current['TimelineOrders.averageCart']) return -1
                        return 0
                    },
                    defaultSortOrder: 'descend',
                    sortDirections: ['descend', 'ascend'],
                    sortOrder: sortKey === 'TimelineOrders.averageCart' ? sortOrder : undefined,
                    render: (record: any) => {
                        if (record.name === 'loading') return <Spin size="small" />
                        return <div>
                            {Formatters.currency(projectCtx.currentProject.currency, Math.trunc(record.current['TimelineOrders.averageCart'] / 100) * 100, undefined, undefined, undefined, undefined, true)}
                            <div className="size-10">{Formatters.growthRate(record.current['TimelineOrders.averageCart'], record.previous['TimelineOrders.averageCart'])}</div>
                        </div>
                    }
                },
                {
                    title: t('role', "Role"),
                    width: 100,
                    key: 'role',
                    render: (record: any) => {
                        if (record.name === '_all') return
                        if (record.name === 'loading') return <Spin size="small" />
                        return <Role record={record} table="TimelineImpressions" currency={projectCtx.currentProject.currency} />
                    }
                },
            ]
        })
    }

    let totalX = 0

    columns.forEach((col: any) => {
        if (col.width) totalX += col.width
        // if (col.children) {
        //   col.children.forEach((ch: any) => {
        //     if (ch.width) totalX += ch.width
        //   })
        // }
    })

    return <div>
        {loadingCSV && <Modal
            title={null}
            centered
            closable={false}
            visible={true}
            footer={null}
            maskClosable={false}
            width={230}
            transitionName=""
            maskTransitionName=""
        >
            <div className="text-center padding-a-l"><Spin size="large" tip="Preparing CSV..." /></div>
        </Modal>}
        <Table
            scroll={{ x: totalX, y: 700 }}
            dataSource={tableData}
            rowKey="id"
            size="middle"
            onChange={handleTableChange}
            loading={loading}
            pagination={false}
            className="block margin-t-l"
            expandable={{
                expandedRowKeys: expandedRowKeys,
                expandRowByClick: true,
                onExpand: onExpand,
                rowExpandable: record => record.expandable,
                indentSize: 10,
            }}
            rowClassName={record => cn({ 'table-all-total': record.name === '_all' })}
            columns={columns}
        />
    </div>
}
export default Report
