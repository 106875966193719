import React from 'react'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

export default withScriptjs(withGoogleMap((props: any) => <GoogleMap
    defaultZoom={props.zoom || 3}
    defaultCenter={{ lat: props.latitude, lng: props.longitude }}
    defaultOptions={{
      // draggable: false,
      fullscreenControl: false,
      scaleControl: true,
      mapTypeControl: false,
      streetViewControl: false,
    }}
  >
    <Marker position={{ lat: props.latitude, lng: props.longitude }} />
  </GoogleMap>
))