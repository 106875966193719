import React, { Component } from 'react'

import t from '../../utils/translate'
import { Spin, Table, Row, Col, message } from 'antd'
import Formatters from '../../utils/formatters'
import CountriesMap from './countriesMap'
import Countries from '../../utils/countries'
import removeLeadingDates from 'utils/removeLeadingDates'

class UsersPerCountry extends Component<any, any> {
  _isMounted = false;

  constructor(props: any) {
    super(props)
    
    this.state = {
      loading: true,
      currentCountries: [],
      previousCountries: [],
      currentLanguages: [],
      previousLanguages: [],
    }

    this.fetch = this.fetch.bind(this)
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetch()
  }

  componentDidUpdate(prevProps: any) {
    // check url params have changed
    if (prevProps.refreshAt !== this.props.refreshAt) {
      window.setTimeout(() => this.fetch())
    } else if (prevProps.project.id !== this.props.project.id) {
      window.setTimeout(() => this.fetch())
    }
  }

  fetch() {

    if (!this.state.loading) {
      this.setState({loading: true})
    }

    const opts: any = {
      measures: ['ActiveUsers.count'],
      dimensions: ['ActiveUsers.country'],
      timeDimensions: [
        {
          "dimension": 'ActiveUsers.truncCreatedAt',
          "granularity": null,
          "compareDateRange": [
            [this.props.dateFrom,this.props.dateTo],
            [this.props.dateFromPrevious,this.props.dateToPrevious]
          ]
        }
      ],
      timezone: this.props.timezone
    }

    const optsLanguages = {
      measures: ['ActiveUsers.count'],
      dimensions: ['ActiveUsers.sessionLanguage'],
      timeDimensions: [
        {
          "dimension": 'ActiveUsers.truncCreatedAt',
          "granularity": null,
          "compareDateRange": [
            [this.props.dateFrom,this.props.dateTo],
            [this.props.dateFromPrevious,this.props.dateToPrevious]
          ]
        }
      ],
      timezone: this.props.timezone
    }


    Promise.all([this.props.cubejsApi.load(opts), this.props.cubejsApi.load(optsLanguages)]).then((values: any) => {
      const [currentCountries, previousCountries] = values[0].decompose()
      const [currentLanguages, previousLanguages] = values[1].decompose()

      const currentCountriesTable = currentCountries.tablePivot()
      const previousCountriesTable = previousCountries.tablePivot()

      removeLeadingDates(currentCountriesTable)
      removeLeadingDates(previousCountriesTable)
      // console.log('currentCountries', currentCountriesTable);

      // compute totals
      const currentCountriesTotal = currentCountriesTable.reduce((acc: any, x: any) => acc+x['ActiveUsers.count'], 0)
      const previousCountriesTotal = previousCountriesTable.reduce((acc: any, x: any) => acc+x['ActiveUsers.count'], 0)

      // compute percentage of total
      currentCountriesTable.forEach((x: any, index: number) => {
        currentCountriesTable[index].key = x['ActiveUsers.country']
        currentCountriesTable[index].percentage = currentCountriesTotal > 0 ? (x['ActiveUsers.count']*100)/currentCountriesTotal : 0
      })
      previousCountriesTable.forEach((x: any, index: number) => {
        previousCountriesTable[index].key = x['ActiveUsers.country']
        previousCountriesTable[index].percentage = previousCountriesTotal > 0 ? (x['ActiveUsers.count']*100)/previousCountriesTotal : 0
      })

      // console.log('currentCountriesTotal', currentCountriesTotal);
      // console.log('previousCountriesTotal', previousCountriesTotal);

      // Languages:

      const currentLanguagesTable = currentLanguages.tablePivot()
      const previousLanguagesTable = previousLanguages.tablePivot()

      removeLeadingDates(currentLanguagesTable)
      removeLeadingDates(previousLanguagesTable)

      // compute totals
      const currentLanguagesTotal = currentLanguagesTable.reduce((acc: any, x: any) => acc+x['ActiveUsers.count'], 0)
      const previousLanguagesTotal = previousLanguagesTable.reduce((acc: any, x: any) => acc+x['ActiveUsers.count'], 0)

      // compute percentage of total
      currentLanguagesTable.forEach((x: any, index: number) => {
        currentLanguagesTable[index].percentage = currentLanguagesTotal > 0 ? (x['ActiveUsers.count']*100)/currentLanguagesTotal : 0
      })
      previousLanguagesTable.forEach((x: any, index: number) => {
        previousLanguagesTable[index].percentage = previousLanguagesTotal > 0 ? (x['ActiveUsers.count']*100)/previousLanguagesTotal : 0
      })

      // console.log('previousCountriesTable', previousCountriesTable);

      this.setState({
        loading: false,
        currentCountries: currentCountriesTable,
        previousCountries: previousCountriesTable,
        currentLanguages: currentLanguagesTable,
        previousLanguages: previousLanguagesTable,
      })
    })
    .catch((error: any) => {

      console.log(error)

      let msg = error.message

      if (error.response && error.response.status === 400) {
        switch(error.response.data.message) {
          default: 
          msg = error.response.data.message
        }
      }

      message.error(msg)
      this.setState({loading: false})
    })
  }

  render() {

    // console.log('state kpi', this.state);

    return <div className="padding-a-m dash-table-stats">
      <Row gutter={24}>
        <Col span={12}>
          <Table rowKey="key"
              size="middle"
              showHeader={false}
              loading={this.state.loading}
              dataSource={this.state.currentCountries}
              pagination={{pageSize: 5, hideOnSinglePage: true, showSizeChanger: false}}
              columns={[
                {
                  title: '',
                  key: 'name',
                  render: (x: any) => {
                    const found = Countries.find((c) => c.code === x['key'])
                    return found ? found.name : t('unknown', 'unknown...')
                  }
                },
                {
                  title: '',
                  key: 'percentage',
                  width: 70,
                  className: 'text-right',
                  sorter: (a: any, b: any) => {
                    if (a.percentage < b.percentage) { return -1 }
                    if (a.percentage > b.percentage) { return 1 }
                    return 0
                  },
                  sortOrder: 'descend',
                  render: (x: any) => <span className="value">{Formatters.percentage(x.percentage)}</span>
                },
                {
                  title: '',
                  key: 'diff',
                  width: 80,
                  className: 'text-right',
                  render: (x: any) => {
                    const previousValue = this.state.previousCountries.find((c: any) => c['key'] === x['key'])
                    return <span className="size-12">{Formatters.growthRate(x.percentage, previousValue ? previousValue.percentage : 0)}</span>
                  }
                }
              ]}
            />
        </Col>
        <Col span={12}>
          <div id="mapContainerRef">
            {this.state.loading && <div className="padding-a-l text-center"><Spin size="large" /></div>}
            {!this.state.loading && <CountriesMap data={this.state.currentCountries} />}
          </div>
        </Col>
      </Row>


      {/*<Row gutter={24}>
        <Col span={12}>
        </Col>

        <Col span={12}>
          <div className="widget-title margin-b-m">{t('users_by_language', "Users by language")}</div>

          <Table rowKey="ActiveUsers.sessionLanguage"
              size="middle"
              showHeader={false}
              loading={this.state.loading}
              dataSource={this.state.currentLanguages}
              pagination={{pageSize: 5, hideOnSinglePage: true, showSizeChanger: false}}
              columns={[
                {
                  title: 'Users by language',
                  key: 'lang',
                  render: (x: any) => x['ActiveUsers.sessionLanguage'].toUpperCase(),
                },
                {
                  title: '',
                  key: 'percentage',
                  width: 70,
                  className: 'text-right',
                  sorter: (a: any, b: any) => {
                    if (a.percentage < b.percentage) { return -1 }
                    if (a.percentage > b.percentage) { return 1 }
                    return 0
                  },
                  sortOrder: 'descend',
                  render: (x: any) => <span className="value">{Formatters.percentage(x.percentage)}</span>
                },
                {
                  title: '',
                  key: 'diff',
                  width: 80,
                  className: 'text-right',
                  render: (x: any) => {
                    const previousValue = this.state.previousCountries.find((c) => c['ActiveUsers.sessionLanguage'] === x['ActiveUsers.sessionLanguage'])
                    return <span className="size-12">{Formatters.growthRate(x.percentage, previousValue ? previousValue.percentage : 0)}</span>
                  }
                }
              ]}
            />
        </Col>
      </Row>*/}
    </div>
  }
}

export default UsersPerCountry