import React, { Component } from 'react'

import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  EyeOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  ExclamationCircleTwoTone,
  DeleteOutlined,
} from '@ant-design/icons';


import { Button, Spin, Tabs, Tooltip, Tag, Row, Col, Drawer, Table, Popover, Popconfirm } from 'antd';
import t from '../../utils/translate'
import Formatters from '../../utils/formatters'
import Moment from 'moment-timezone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Numeral from 'numeral'
import QS from 'qs'
import _ from 'lodash'
import GoogleMap from '../../utils/gmap'
import Config from '../../config'
import Graph from '../timeline/_graph'
import Timeline from '../timeline/_feed'
// import Orders from '../orders/_userOrders'
import UpsertUserButton from './_upsertUserButton'
import AliasButton from './_aliasButton'
import DeleteOrderButton from '../orders/_deleteButton'
import SubscribeTopicButton from '../notifications/_subscribeTopicButton'

class UsersShowDrawer extends Component<any, any> {
  _isMounted = false;

  constructor(props: any) {
    super(props)

    // console.log('props', props);
    this.state = {
      userLoading: true,
      user: this.props.user || {},
      orders: [],
      ordersCount: 0,
      ordersLoading: false,
      leads: [],
      leadsCount: 0,
      leadsLoading: false,
      timeline: [],
      timelineLoading: true,
      timelineGraph: {},
      // timelineGraphLoading: true,
    }

    this.loadUser = this.loadUser.bind(this)
    this.fetchUser = this.fetchUser.bind(this)
    this.fetchTimeline = this.fetchTimeline.bind(this)
    this.fetchOrders = this.fetchOrders.bind(this)
    this.fetchLeads = this.fetchLeads.bind(this)

    this.refresh = this.refresh.bind(this)
    this.viewCompany = this.viewCompany.bind(this)
    this.onClose = this.onClose.bind(this)
    this.updateParams = this.updateParams.bind(this)
    this.deleteSubscription = this.deleteSubscription.bind(this)
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadUser()
  }

  componentDidUpdate(prevProps: any) {

    // change user
    if (prevProps.userId !== this.props.userId) {
      this.loadUser()
      return
    }

    // check url params have changed (refresh...)
    if (prevProps.location.search !== this.props.location.search) {
      this.loadUser()
      // this.fetchTimelineGraph()
    }
  }

  onClose() {
    this.updateParams([{ k: 'showUser', v: undefined }])
  }

  loadUser() {
    this.fetchUser()
    this.fetchTimeline()
    // this.fetchTimelineGraph()

    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'User profile ' + this.props.userId + ' (project: ' + this.props.projectLayout.project.id + ')',
        page: this.props.location.pathname,
        props: {
          orgId: this.props.projectLayout.project.organizationId,
          projectId: this.props.projectLayout.project.id
        }
      })
      window.cmAgent.dispatch()
    }
  }

  fetchTimeline() {
    this.setState({ timelineLoading: true })

    // const params: any = QS.parse(this.props.location.search, {ignoreQueryPrefix: true})

    this.props.app.ajaxRequest({
      method: 'get',
      url: '/users.timeline',
      params: {
        projectId: this.props.projectLayout.project.id,
        timezone: this.props.app.state.admin.timezone,
        externalUserId: this.props.userId,
        // skip: 0, // TODO: params.showUserskip || 0,
        // limit: 40 // TODO params.showUserLimit || 40
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        // this.setState({loading: false})
        return
      }

      if (this._isMounted) {
        this.setState({
          timeline: response.data.timeline,
          timelineGraph: response.data.timelineGraph,
          timelineLoading: false
        })
      }
    })
  }

  fetchOrders(userId: string) {

    if (this.state.ordersLoading === true) {
      return
    }

    this.setState({ ordersLoading: true })

    let query = {
      projectId: this.props.projectLayout.project.id,
      limit: 100,
      skip: 0,
      sortKey: 'createdAt',
      sortOrder: 'descend',
      userId: userId,
    }

    this.props.app.ajaxRequest({
      method: 'get',
      url: '/orders.list',
      params: query
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        // this.setState({ordersLoading: false})
        return
      }

      // console.log('res', response.data);

      if (this._isMounted) {
        this.setState({
          orders: response.data.orders,
          ordersCount: response.data.ordersCount,
          ordersLoading: false
        })
      }
    })
  }

  fetchLeads(userId: string) {

    if (this.state.leadsLoading === true) {
      return
    }

    this.setState({ leadsLoading: true })

    let query = {
      projectId: this.props.projectLayout.project.id,
      limit: 100,
      skip: 0,
      sortKey: 'createdAt',
      sortOrder: 'descend',
      userId: userId,
    }

    this.props.app.ajaxRequest({
      method: 'get',
      url: '/leads.list',
      params: query
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        // this.setState({leadsLoading: false})
        return
      }

      // console.log('res', response.data);

      if (this._isMounted) {
        this.setState({
          leads: response.data.leads,
          leadsCount: response.data.leadsCount,
          leadsLoading: false
        })
      }
    })
  }

  deleteSubscription(notificationTopicId: string, channel: string, collectionItemExternalId?: string) {

    // const params: any = QS.parse(this.props.location.search, {ignoreQueryPrefix: true})
    return new Promise((resolve, reject) => {
      this.props.app.ajaxRequest({
        method: 'post',
        url: '/data.import',
        data: {
          projectId: this.props.projectLayout.project.id,
          id: 'subscribe_' + this.props.userId + '_' + new Date().getTime(),
          sync: true,
          items: [{
            id: 'unsubscribe_' + new Date().getTime(),
            kind: 'unsubscribeFromNotificationTopic',
            unsubscribeFromNotificationTopic: {
              externalUserId: this.state.user.externalId,
              createdAt: new Date().toISOString(),
              notificationTopicId: notificationTopicId,
              channel: channel,
              collectionItemExternalId: collectionItemExternalId || '',
              reason: 'manual',
            }
          }]
        }
      }, (errorMessage: any, _response: any) => {

        if (errorMessage) {
          this.props.app.addMessage('error', errorMessage)
          // this.setState({loading: false})
          reject()
          return
        }

        this.props.app.addMessage('success', t('subscription_removed', 'The subscription has been removed.'))


        this.loadUser()
        resolve(undefined)
      })
    })
  }


  viewCompany(companyId: string) {
    this.props.history.push('/organizations/' + this.props.match.params.organizationId + '/projects/' + this.props.match.params.projectId + '/companies/' + companyId)
  }

  viewUser(userId: string) {
    this.updateParams([{ k: 'showUser', v: userId }])
  }

  updateParams(updatedParams: any) {
    let params = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })
    updatedParams.forEach((up: any) => {
      params[up.k] = up.v
    })
    this.props.history.push(window.location.pathname + '?' + QS.stringify(params))
  }

  refresh() {
    this.fetchUser()
    this.fetchTimeline()
  }

  fetchUser() {
    this.setState({ userLoading: true })

    let query = {
      projectId: this.props.projectLayout.project.id,
      externalUserId: this.props.userId
    }

    this.props.app.ajaxRequest({
      method: 'get',
      url: '/users.show',
      params: query
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        // this.setState({userLoading: false})
        return
      }

      // console.log('res', response.data);

      if (this._isMounted) {
        this.setState({
          user: response.data.user,
          userLoading: false
        })
      }

      if (this.props.projectLayout.project.conversionRules.find(x => x.kind === 'order')) {
        this.fetchOrders(response.data.user.id)
      }
      if (this.props.projectLayout.project.conversionRules.find(x => x.kind === 'lead')) {
        this.fetchLeads(response.data.user.id)
      }
    })
  }

  render() {

    const user = this.state.user
    const segmentsMap = _.keyBy(_.toArray(this.props.projectLayout.segments).filter((s: any) => !s.forCompanies), (s: any) => s.id)
    let dataGraph: any = []

    _.forEach(this.state.timelineGraph, (events, date) => {
      _.forEach(events, (count, event) => {
        dataGraph.push({
          date: date,
          event: event,
          count: count
        })
      })
    })

    const dateFrom = Moment().subtract(30, 'day').startOf('day')
    const dateTo = Moment().endOf('day')


    let hasB2B: any = false

    this.props.projectLayout.project.conversionRules.forEach((rule: any) => {
      if (rule.forCompanies === true) {
        hasB2B = true
      }
    })

    const params: any = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })
    const channelsMap = _.keyBy(this.props.projectLayout.project.channels, 'id');
    const conversionRulesMap = _.keyBy(this.props.projectLayout.project.conversionRules, 'id')


    // console.log('state', this.state);

    return (
      <Drawer
        title={<Row gutter={24}>
          <Col span={6} className="text-center">{(user.firstName || '') + ' ' + (user.lastName || '')}</Col>
          <Col span={18}>{user.segments && user.segments.filter((s: any) => !s.exitAt).map((s: any) => {
            return <Tooltip title={Moment(s.enterAt).fromNow()} placement="bottom" key={s.segmentId}><Tag color={segmentsMap[s.segmentId].color !== 'default' && segmentsMap[s.segmentId].color}>{segmentsMap[s.segmentId].name}</Tag></Tooltip>
          })}</Col>
        </Row>}
        visible={true}
        onClose={this.onClose}
        className="drawer drawer-bg"
        width="95%"
      >
        <Row gutter={24} className="margin-h-m">
          <Col span={6}>
            {this.state.userLoading === true && <div className="text-center margin-a-l"><Spin size="large" /></div>}

            {!this.state.userLoading && <div className="padding-b-xl">
              <div className="text-center">
                <div className="margin-b-m">{user.photoURL ? <img className="user-picture large" src={user.photoURL} alt="" /> : <div className="user-picture-icon large"><FontAwesomeIcon icon="user-circle" /></div>}</div>
                {user.timezone !== 'UTC' && <div className="margin-b-m padding-h-m size-12">
                  <FontAwesomeIcon icon="map-marker-alt" /> {Moment().tz(user.timezone).format('LT') + ' ' + t('in', "in") + ' ' + (user.city ? (user.state ? user.city + ', ' + user.state : user.city) : user.timezone) + (user.country ? ', ' + user.country : '')}
                </div>}
                <UpsertUserButton app={this.props.app} project={this.props.projectLayout.project} type="ghost" size="small" title={t('edit_profile', "Edit profile")} user={user} onComplete={this.refresh}><EditOutlined /> {t('edit_profile', "Edit profile")}</UpsertUserButton>

                <Button.Group className="margin-l-s">
                  <Tooltip title={t('merge_with_another_user', "Merge with another user")}><span><AliasButton app={this.props.app} project={this.props.projectLayout.project} type="ghost" size="small" user={user} onComplete={(userId: any) => this.viewUser(userId)}><FontAwesomeIcon icon="user-minus" /></AliasButton></span></Tooltip>
                  <Tooltip title={t('refresh', "Refresh")}><Button type="ghost" size="small" onClick={this.refresh}><FontAwesomeIcon icon="sync-alt" spin={this.state.userLoading} /></Button></Tooltip>
                </Button.Group>
              </div>

              <Tabs defaultActiveKey="profile" size="small" className="margin-t-l">
                <Tabs.TabPane tab={t('profile', "Profile")} key="profile">
                  <div className="block margin-t-m">
                    <h2 className="mini">{t('user_details', 'User details')}</h2>

                    <div className="subsider-attribute padding-t-m">
                      <span className="subsider-attribute-key">ID</span>
                      <span className="subsider-attribute-value"><Tooltip title={user.id}>{user.externalId}</Tooltip></span>
                    </div>
                    {user.lastSeenAt && <div className="subsider-attribute">
                      <span className="subsider-attribute-key">{t('last_seen', "Last seen")}</span>
                      <span className="subsider-attribute-value"><Tooltip title={Moment(user.lastSeenAt).tz(this.props.app.state.admin.timezone).format('lll') + ' (' + this.props.app.state.admin.timezone + ')'}>{Moment(user.lastSeenAt).fromNow()}</Tooltip></span>
                    </div>}
                    {user.signedUpAt && <div className="subsider-attribute">
                      <span className="subsider-attribute-key">{t('signed_up', "Signed up")}</span>
                      <span className="subsider-attribute-value"><Tooltip title={Moment(user.signedUpAt).tz(this.props.app.state.admin.timezone).format('lll') + ' (' + this.props.app.state.admin.timezone + ')'}>{Moment(user.signedUpAt).fromNow()}</Tooltip></span>
                    </div>}
                    {user.firstName && <div className="subsider-attribute">
                      <span className="subsider-attribute-key">{t('first_name', "First name")}</span>
                      <span className="subsider-attribute-value">{user.firstName}</span>
                    </div>}
                    {user.lastName && <div className="subsider-attribute">
                      <span className="subsider-attribute-key">{t('last_name', "Last name")}</span>
                      <span className="subsider-attribute-value">{user.lastName}</span>
                    </div>}
                    {user.gender && <div className="subsider-attribute">
                      <span className="subsider-attribute-key">{t('gender', "Gender")}</span>
                      <span className="subsider-attribute-value">{t('gender_' + user.gender, user.gender)}</span>
                    </div>}
                    {user.birthday && <div className="subsider-attribute">
                      <span className="subsider-attribute-key">{t('birthday', "Birthday")}</span>
                      <span className="subsider-attribute-value">{Moment(user.birthday, 'YYYY-MM-DD').format('LL')}</span>
                    </div>}
                    {user.email && <div className="subsider-attribute">
                      <span className="subsider-attribute-key"><FontAwesomeIcon icon="envelope" /></span>
                      <span className="subsider-attribute-value"><a href={'mailto:' + user.email}>{user.email}</a></span>
                    </div>}
                    {user.telephone && <div className="subsider-attribute">
                      <span className="subsider-attribute-key"><FontAwesomeIcon icon="phone" /></span>
                      <span className="subsider-attribute-value"><a href={'tel:' + user.telephone}>{user.telephone}</a></span>
                    </div>}
                    <div className="subsider-attribute">
                      <span className="subsider-attribute-key">{t('accepts_newsletter', "Accepts newsletter?")}</span>
                      <span className="subsider-attribute-value">{user.newsletterConsent ? <span className="color-green">
                        <CheckOutlined /></span> : <span className="color-orange">
                        <CloseOutlined /></span>}</span>
                    </div>
                    <div className="subsider-attribute">
                      <span className="subsider-attribute-key">{t('accepts_remarketing', "Accepts remarketing?")}</span>
                      <span className="subsider-attribute-value">{user.remarketingConsent ? <span className="color-green">
                        <CheckOutlined /></span> : <span className="color-orange">
                        <CloseOutlined /></span>}</span>
                    </div>
                    {user.websiteURL && <div className="subsider-attribute">
                      <span className="subsider-attribute-key"><FontAwesomeIcon icon="link" /></span>
                      <span className="subsider-attribute-value"><a href={user.websiteURL} target="_blank" rel="noopener noreferrer">{user.websiteURL}</a></span>
                    </div>}
                    {user.twitterUsername && <div className="subsider-attribute">
                      <span className="subsider-attribute-key"><FontAwesomeIcon icon={["fab", "twitter"]} /></span>
                      <span className="subsider-attribute-value"><a href={'https://twitter.com/' + user.twitterUsername} target="_blank" rel="noopener noreferrer">{user.twitterUsername}</a></span>
                    </div>}
                    {user.facebookUsername && <div className="subsider-attribute">
                      <span className="subsider-attribute-key"><FontAwesomeIcon icon={["fab", "facebook"]} /></span>
                      <span className="subsider-attribute-value"><a href={'https://web.facebook.com/' + user.facebookUsername} target="_blank" rel="noopener noreferrer">{user.facebookUsername}</a></span>
                    </div>}
                    {user.facebookId && <div className="subsider-attribute">
                      <span className="subsider-attribute-key"><FontAwesomeIcon icon={["fab", "facebook"]} /></span>
                      <span className="subsider-attribute-value"><a href={'https://web.facebook.com/' + user.facebookId} target="_blank" rel="noopener noreferrer">{user.facebookId}</a></span>
                    </div>}
                    {user.tags && user.tags.length > 0 && <div className="subsider-attribute">
                      <span className="subsider-attribute-key"><FontAwesomeIcon icon="tags" /></span>
                      <span className="subsider-attribute-value">{user.tags.map((tag: any) => <span key={tag}><Tag className="no-pointer margin-r-s xsmall">{tag}</Tag></span>)}</span>
                    </div>}
                    {user.timezone && <div className="subsider-attribute">
                      <span className="subsider-attribute-key">{t('time_zone', "Time zone")}</span>
                      <span className="subsider-attribute-value">{user.timezone}</span>
                    </div>}
                    {user.language && <div className="subsider-attribute">
                      <span className="subsider-attribute-key">{t('language', "Language")}</span>
                      <span className="subsider-attribute-value">{user.language}</span>
                    </div>}
                    {user.country && <div className="subsider-attribute">
                      <span className="subsider-attribute-key">{t('country', "Country")}</span>
                      <span className="subsider-attribute-value">{user.country}</span>
                    </div>}
                    {user.addressLine1 && <div className="subsider-attribute">
                      <span className="subsider-attribute-key">{t('address', "Address")}</span>
                      <span className="subsider-attribute-value">{user.addressLine1}</span>
                    </div>}
                    {user.addressLine2 && <div className="subsider-attribute">
                      <span className="subsider-attribute-key">{t('address_line_1', "Address line 2")}</span>
                      <span className="subsider-attribute-value">{user.addressLine2}</span>
                    </div>}
                    {user.city && <div className="subsider-attribute">
                      <span className="subsider-attribute-key">{t('city', "City")}</span>
                      <span className="subsider-attribute-value">{user.city}</span>
                    </div>}
                    {user.postalCode && <div className="subsider-attribute">
                      <span className="subsider-attribute-key">{t('postalCode', "Postal code")}</span>
                      <span className="subsider-attribute-value">{user.postalCode}</span>
                    </div>}
                    {user.state && <div className="subsider-attribute">
                      <span className="subsider-attribute-key">{t('state', "State")}</span>
                      <span className="subsider-attribute-value">{user.state}</span>
                    </div>}
                    {user.region && <div className="subsider-attribute">
                      <span className="subsider-attribute-key">{t('region', "Region")}</span>
                      <span className="subsider-attribute-value">{user.region}</span>
                    </div>}
                    {user.lastIP && <div className="subsider-attribute">
                      <span className="subsider-attribute-key">IP</span>
                      <span className="subsider-attribute-value">{user.lastIP}</span>
                    </div>}
                    {user.latitude && user.longitude && <div>
                      <GoogleMap
                        googleMapURL={"https://maps.googleapis.com/maps/api/js?key=" + Config().gmap_key}
                        loadingElement={<div className="loadingElement" style={{ height: '150px' }} />}
                        containerElement={<div className="containerElement" style={{ height: '150px' }} />}
                        mapElement={<div className="mapElement" style={{ height: '100%' }} />}
                        latitude={user.latitude}
                        longitude={user.longitude}
                      />
                    </div>}
                    {user.fcmTokens && <div className="subsider-attribute">
                      <span className="subsider-attribute-key">{t('fcm_tokens', "Tokens Firebase Messaging")}</span>
                      <span className="subsider-attribute-value">{user.fcmTokens.join(' - ')}</span>
                    </div>}

                    {_.size(user.properties) > 0 && <div>
                      <div className="subsider-title margin-t-m">{t('custom_properties', "Custom properties")}</div>

                      {_.map(user.properties, (value, key) => <div key={key} className="subsider-attribute">
                        <span className="subsider-attribute-key">{key}</span>
                        <span className="subsider-attribute-value">{Formatters.customProperty(value, user.timezone)}</span>
                      </div>)}
                    </div>}

                    {hasB2B === true && <div>
                      <div className="subsider-title margin-t-m">{t('companies', "Companies")}</div>

                      {_.map(user.companies, (company) => <div key={company.id} className="subsider-attribute">
                        <Tooltip title={Moment(company.joinedAt).tz(this.props.app.state.admin.timezone).format('lll') + ' (' + this.props.app.state.admin.timezone + ')'}>
                          <span className="subsider-attribute-key link" onClick={this.viewCompany.bind(null, company.externalId)}>{company.name || company.externalId}</span>
                          <span className="subsider-attribute-value">{company.role && <span className="padding-r-s">{company.role}</span>}</span>
                        </Tooltip>
                      </div>)}
                    </div>}
                  </div>

                  {user.cart && user.cart.items && user.cart.items.length > 0 && <div className="block margin-t-m">
                    <h2 className="mini">{t('shopping_cart', "Shopping cart")}</h2>
                    {user.cart.publicURL && <div className="subsider-attribute">
                      <span className="subsider-attribute-key">Public URL</span>
                      <span className="subsider-attribute-value"><a href={user.cart.publicURL} target="_blank" rel="noopener noreferrer">{user.cart.publicURL}</a></span>
                    </div>}

                    {user.cart.items.map((item: any, i: number) => <div key={i} className="subsider-attribute">
                      <span className="subsider-attribute-key"><Tooltip title={item.externalId}>{item.quantity}x {item.name + ' ' + item.brand}</Tooltip></span>
                      <span className="subsider-attribute-value">{Formatters.currency(this.props.projectLayout.project.currency, item.price, item.priceSource, item.currency)}</span>
                    </div>)}
                  </div>}

                  {user.wishList && user.wishList.items && user.wishList.items.length > 0 && <div className="block margin-t-m">
                    <h2 className="mini">{t('wish_list', "Wish list")}</h2>

                    {user.wishList.items.map((item: any) => <div key={item.id} className="subsider-attribute">
                      <span className="subsider-attribute-key"><Tooltip title={item.externalId}>{item.name + ' ' + item.brand}</Tooltip></span>
                      <span className="subsider-attribute-value">{Formatters.currency(this.props.projectLayout.project.currency, item.price, item.priceSource, item.currency)}</span>
                    </div>)}
                  </div>}
                </Tabs.TabPane>

                <Tabs.TabPane tab={t('devices', "Devices")} key="devices">
                  {_.map(user.clients, (client: any) => <div key={client.id} className="block margin-t-m">
                    <h2 className="mini">{Formatters.deviceIcon(client.device)}&nbsp;&nbsp; {client.browser + ' ' + (client.browserVersionMajor ? client.browserVersionMajor : '') + (client.browserVersionMinor ? '.' + client.browserVersionMinor + '.' : '') + (client.browserVersionPatch ? '.' + client.browserVersionPatch : '')}</h2>
                    <div className="subsider-attribute">
                      <span className="subsider-attribute-key">Platform - OS</span>
                      <span className="subsider-attribute-value">{client.platform + ' - ' + client.os}</span>
                    </div>
                    <div className="subsider-attribute">
                      <span className="subsider-attribute-key">{t('resolution', "Resolution")}</span>
                      <span className="subsider-attribute-value">{client.resolution}</span>
                    </div>
                    <div className="subsider-attribute">
                      <span className="subsider-attribute-key">{t('ad_block', "Ad block")}</span>
                      <span className="subsider-attribute-value">{client.adBlock ? t('yes', "yes") : t('no', "no")}</span>
                    </div>
                    <div className="subsider-attribute">
                      <span className="subsider-attribute-key">Mobile Webview</span>
                      <span className="subsider-attribute-value">{client.isWebview ? t('yes', "yes") : t('no', "no")}</span>
                    </div>
                    <div className="subsider-attribute">
                      <span className="subsider-attribute-key">{t('languages', "Languages")}</span>
                      <span className="subsider-attribute-value">{client.languages.replace(/~/g, ', ')}</span>
                    </div>
                    <div className="subsider-attribute">
                      <span className="subsider-attribute-key">{t('time_zone', "Time zone")}</span>
                      <span className="subsider-attribute-value">{client.timezone + ' (' + (client.timezoneOffset > 0 ? '-' + (client.timezoneOffset / 60) : '+' + (Math.abs(client.timezoneOffset) / 60)) + 'h)'}</span>
                    </div>
                  </div>)}
                </Tabs.TabPane>
              </Tabs>
            </div>}
          </Col>



          <Col span={18}>
            <div className="block margin-b-m">
              {this.props.projectLayout.project.conversionRules.filter((cr: any) => !cr.deletedAt).map((cr: any) => {
                if (cr.kind === 'order') {

                  const orderedOrders = _.sortBy(this.state.orders, ['createdAt'])
                  const lastOrder = _.last(orderedOrders)

                  let revenue = 0
                  let conversionsWithTTC = 0
                  let totalTTC = 0
                  let avgCart = 0

                  // reduce
                  _.forEach(this.state.orders, (order) => {
                    revenue += order.revenue
                    if (order.timeToConversion > 0) {
                      conversionsWithTTC++
                      totalTTC += order.timeToConversion
                    }
                  })

                  if (revenue > 0 && this.state.ordersCount > 0) {
                    avgCart = revenue / this.state.ordersCount
                  }

                  return <Row key={cr.id}>

                    <Col span={4} className="dash-kpi dashed-right-1">
                      <div className="widget-title size-13">{t('orders', "Orders")}</div>
                      <div className="dash-kpi-value">{this.state.ordersLoading === true ? <Spin /> : Numeral(this.state.ordersCount).format('0,0')}</div>
                    </Col>

                    <Col span={4} className="dash-kpi dashed-right-1">
                      <div className="widget-title size-13">{t('revenue', "Revenue")}</div>
                      <div className="dash-kpi-value">{this.state.ordersLoading === true ? <Spin /> : Formatters.currency(this.props.projectLayout.project.currency, revenue)}</div>
                    </Col>

                    <Col span={4} className="dash-kpi dashed-right-1">
                      <div className="widget-title size-13">{t('last_order', "Last order")}</div>
                      <div className="dash-kpi-value">{this.state.ordersLoading === true ? <Spin /> : (lastOrder ? Moment(lastOrder.createdAt).fromNow() : '-')}</div>
                    </Col>

                    <Col span={4} className="dash-kpi dashed-right-1">
                      <div className="widget-title size-13"><Tooltip title={t('avg_time_to_conversion', "Average Time To Conversion")}>{t('avg_ttc', "Avg. TTC")}</Tooltip></div>
                      <div className="dash-kpi-value">{this.state.ordersLoading === true ? <Spin /> : Formatters.duration(totalTTC / conversionsWithTTC)}</div>
                    </Col>

                    <Col span={4} className="dash-kpi dashed-right-1">
                      <div className="widget-title size-13">{t('average_cart', "Avg. cart")}</div>
                      <div className="dash-kpi-value">{this.state.ordersLoading === true ? <Spin /> : Formatters.currency(this.props.projectLayout.project.currency, avgCart)}</div>
                    </Col>

                    <Col span={4} className="dash-kpi">
                      <div className="widget-title size-13">{t('orders_frequency', "Orders frequency")}</div>
                      <div className="dash-kpi-value">{this.state.ordersLoading === true ? <Spin /> : (this.state.ordersCount > 1 ? Formatters.frequency(_.first(orderedOrders).createdAt, this.state.ordersCount) : '-')}</div>
                    </Col>
                  </Row>
                }

                if (cr.kind === 'lead') {

                  let revenue = 0
                  let conversionsWithTTC = 0
                  let totalTTC = 0
                  let open = 0
                  let converted = 0
                  let lost = 0

                  // reduce
                  _.forEach(this.state.leads, (lead) => {
                    revenue += lead.revenue
                    if (lead.timeToConversion > 0) {
                      conversionsWithTTC++
                      totalTTC += lead.timeToConversion
                    }
                    if (lead.status === 'open') open++
                    if (lead.status === 'converted') converted++
                    if (lead.status === 'lost') lost++
                  })

                  return <Row className="border-top-1" key={cr.id}>

                    <Col span={4} className="dash-kpi dashed-right-1">
                      <div className="widget-title size-13">Leads</div>
                      <div className="dash-kpi-value">{this.state.leadsLoading === true ? <Spin /> : Numeral(this.state.leadsCount).format('0,0')}</div>
                    </Col>

                    <Col span={4} className="dash-kpi dashed-right-1">
                      <div className="widget-title size-13">{t('revenue', "Revenue")}</div>
                      <div className="dash-kpi-value">{this.state.leadsLoading === true ? <Spin /> : Formatters.currency(this.props.projectLayout.project.currency, revenue)}</div>
                    </Col>

                    <Col span={4} className="dash-kpi dashed-right-1">
                      <div className="widget-title size-13"><Tooltip title={t('avg_time_to_conversion', "Average Time To Conversion")}>{t('avg_ttc', "Avg. TTC")}</Tooltip></div>
                      <div className="dash-kpi-value">{this.state.leadsLoading === true ? <Spin /> : Formatters.duration(totalTTC / conversionsWithTTC)}</div>
                    </Col>

                    <Col span={4} className="dash-kpi dashed-right-1">
                      <div className="widget-title size-13">Open</div>
                      <div className="dash-kpi-value">{this.state.leadsLoading === true ? <Spin /> : open}</div>
                    </Col>

                    <Col span={4} className="dash-kpi dashed-right-1">
                      <div className="widget-title size-13">Converted</div>
                      <div className="dash-kpi-value">{this.state.leadsLoading === true ? <Spin /> : converted}</div>
                    </Col>

                    <Col span={4} className="dash-kpi">
                      <div className="widget-title size-13">Lost</div>
                      <div className="dash-kpi-value">{this.state.leadsLoading === true ? <Spin /> : lost}</div>
                    </Col>
                  </Row>
                }

                return null
              })}
            </div>


            <div className="block margin-b-m">
              <h2 className="mini">{t('last_30_days_activity', "Last 30 days activity")}</h2>

              {this.state.timelineLoading && <div className="text-center margin-a-l"><Spin size="large" /></div>}
              {!this.state.timelineLoading && <Graph height={100} data={dataGraph} dateFrom={dateFrom} dateTo={dateTo} />}
            </div>


            <div className="block">
              <h2 className="mini">
                <div className="actions">
                  <SubscribeTopicButton
                    type="primary"
                    ghost={true}
                    size="small"
                    title={t('add_subscription', "Add subscription")}
                    app={this.props.app}
                    project={this.props.projectLayout.project}
                    notificationTopics={this.props.projectLayout.notificationTopics}
                    user={user}
                    onComplete={this.loadUser}
                  >
                    {t('add_subscription', "Add subscription")}
                  </SubscribeTopicButton>
                </div>
                {t('notification_topics', "Notification topics")}
              </h2>
              <Table
                dataSource={this.props.projectLayout.notificationTopics}
                rowKey="id"
                size="small"
                loading={this.state.userLoading}
                pagination={false}
                showHeader={false}
                columns={[
                  {
                    key: 'title',
                    render: topic => <>
                      {topic.name}
                    </>
                  },
                  {
                    key: 'kind',
                    render: topic => <>
                      {topic.kind === 'broadcast' && <Tag color="purple">Broadcast</Tag>}
                      {topic.kind === 'collection' && <>
                        <Tag color="magenta">Collection</Tag> <span className="size-12">{topic.collectionKind} - {topic.collectionId}</span>
                      </>}
                    </>
                  },
                  {
                    key: 'collectionItem',
                    render: topic => {
                      if (topic.kind !== 'collection') return

                      const subs = user.notificationTopics?.filter((x: any) => x.notificationTopicId === topic.id)

                      return subs ? subs.map((sub: any) => <div key={sub.id}>
                        Item: {sub.collectionItemExternalId}
                      </div>) : ''
                    }
                  },
                  {
                    key: 'email',
                    render: topic => {
                      // console.log('topic', topic)
                      if (!topic.withEmail) return <span className="size-12 padding-r-xs">Email: N/A</span>

                      // can have many subs for collection topic
                      const subs = user.notificationTopics?.filter((x: any) => x.notificationTopicId === topic.id)

                      if (!subs || subs.length === 0) return <><span className="size-12 padding-r-xs">Email:</span> <CloseCircleTwoTone twoToneColor="#CFD8DC" /></>

                      return subs.map((sub: any) => <div key={sub.id}>
                        <span className="size-12 padding-r-xs">Email:</span> {!sub.withEmail && <CloseCircleTwoTone twoToneColor="#CFD8DC" />}
                        {sub.withEmail && <>
                          {sub.emailConfirmedAt && <CheckCircleTwoTone twoToneColor="#52c41a" />}
                          {!sub.emailConfirmedAt && <><ExclamationCircleTwoTone twoToneColor="#FFB74D" /> <span className="size-12">{t('not_yet_confirmed', 'Not yet confirmed')}</span></>}
                          <Popconfirm title={t('confirm_remove_subscription', 'Do you really want to remove this subscription?')} onConfirm={this.deleteSubscription.bind(null, sub.notificationTopicId, 'email', sub.collectionItemExternalId)}><Tooltip title={t('remove', 'Remove')} className="link margin-l-s"><DeleteOutlined /></Tooltip></Popconfirm>
                        </>}
                      </div>)
                    }
                  },
                  {
                    key: 'sms',
                    render: topic => {
                      if (!topic.withSMS) return <span className="size-12 padding-r-xs">SMS: N/A</span>

                      // can have many subs for collection topic
                      const subs = user.notificationTopics?.filter((x: any) => x.notificationTopicId === topic.id)
                      if (!subs || subs.length === 0) return <><span className="size-12 padding-r-xs">SMS:</span> <CloseCircleTwoTone twoToneColor="#CFD8DC" /></>

                      return subs.map((sub: any) => <div key={sub.id}>
                        <span className="size-12 padding-r-xs">SMS:</span> {!sub.withSMS && <CloseCircleTwoTone twoToneColor="#CFD8DC" />}
                        {sub.withSMS && <>
                          {sub.smsConfirmedAt && <CheckCircleTwoTone twoToneColor="#52c41a" />}
                          {!sub.smsConfirmedAt && <><ExclamationCircleTwoTone twoToneColor="#FFB74D" /> <span className="size-12">{t('not_yet_confirmed', 'Not yet confirmed')}</span></>}
                          <Popconfirm title={t('confirm_remove_subscription', 'Do you really want to remove this subscription?')} onConfirm={this.deleteSubscription.bind(null, sub.notificationTopicId, 'sms', sub.collectionItemExternalId)}><Tooltip title={t('remove', 'Remove')} className="link margin-l-s"><DeleteOutlined /></Tooltip></Popconfirm>
                        </>}
                      </div>)
                    }
                  }
                ]}
              />
            </div>



            <Tabs defaultActiveKey="timeline" className="margin-t-m">
              <Tabs.TabPane tab="Timeline" key="timeline">
                {this.state.timelineLoading && <div className="text-center margin-a-l"><Spin size="large" /></div>}
                {!this.state.timelineLoading && <div className="block margin-t-m padding-t-s">
                  <Timeline {...this.props} noTitle={true} timeline={this.state.timeline} user={this.state.user} companies={this.state.user ? this.state.user.companies : []} />
                </div>}
              </Tabs.TabPane>

              {this.props.projectLayout.project.conversionRules.find((cr: any) => cr.kind === "order") && <Tabs.TabPane tab={t('orders', "Orders")} key="orders">
                <div className="block margin-t-m">
                  <Table
                    dataSource={this.state.orders}
                    rowKey="id"
                    className="edge-table"
                    loading={this.state.ordersLoading}
                    pagination={false}
                    columns={[
                      {
                        title: 'ID',
                        key: 'externalId',
                        // sortOrder: params.sortKey === 'externalId' ? params.sortOrder : undefined,
                        // sorter: (a: any, b: any) => {
                        //   if (a.conversionExternalId < b.conversionExternalId) { return -1 }
                        //   if (a.conversionExternalId > b.conversionExternalId) { return 1 }
                        //   return 0
                        // },
                        render: (record: any) => {
                          if (record.conversionExternalId.length > 15) {
                            return <Tooltip title={record.conversionExternalId}>{_.truncate(record.conversionExternalId, { length: 16, omission: '...' })}</Tooltip>
                          }
                          return record.conversionExternalId
                        }
                      },
                      {
                        title: t('conversion_rule', "Conversion rule"),
                        key: 'conversionRule',
                        render: (record: any) => conversionRulesMap[record.conversionRuleId].name
                      },
                      {
                        title: 'Date',
                        key: 'createdAt',
                        sortOrder: params.sortKey === 'createdAt' ? params.sortOrder : undefined,
                        sorter: (a: any, b: any) => {
                          if (a.createdAt < b.createdAt) { return -1 }
                          if (a.createdAt > b.createdAt) { return 1 }
                          return 0
                        },
                        render: order => <small>{Moment(order.createdAt).format('llll')}</small>
                      },
                      {
                        key: 'revenue',
                        title: t('revenue', "Revenue"),
                        render: t => Formatters.currency(this.props.projectLayout.project.currency, t.revenue, t.revenueSource, t.currency, 'color-kpi-purple')
                      },
                      {
                        title: t('funnel', "Funnel"),
                        key: 'funnel',
                        render: (record) => {
                          // console.log('record', record);

                          // group identical channelId if funnel has different source/medium
                          let funnel: any = []

                          _.forEach(record.funnel, f => {

                            if (!f.count) {
                              f.count = 0
                            }

                            // find channel
                            var hitChannel = this.props.projectLayout.project.channels.find((x: any) => x.id === f.channelId)
                            // console.log('hitChannel', hitChannel);

                            if (!hitChannel) {
                              hitChannel = { id: 'not-mapped', name: t('not_mapped', "Not mapped") }
                            }

                            f.channelId = hitChannel.id
                            f.channelName = hitChannel.name

                            if (funnel.length === 0) {
                              funnel.push(f)
                            } else {
                              if (funnel[funnel.length - 1].channelId === f.channelId) {
                                funnel[funnel.length - 1].count += f.count
                                if (funnel[funnel.length - 1].role < f.role) {
                                  funnel[funnel.length - 1].role = f.role
                                }
                              } else {
                                funnel.push(f)
                              }
                            }
                          })

                          return funnel.map((contribution: any, i: number) => {

                            if (i === 2) return <Popover key={i} content={<div>
                              {funnel.map((c: any, k: number) => <div key={k}>{(c.channelId === 'not-mapped') ? c.source + ' / ' + c.medium : channelsMap[c.channelId].name}</div>)}</div>}>
                              <small className="link">+{funnel.length - 2} {t('more', "more")}</small>
                            </Popover>
                            if (i > 2) return null
                            return <div key={i}><small>{(contribution.channelId === 'not-mapped') ? contribution.source + ' / ' + contribution.medium : channelsMap[contribution.channelId].name}</small></div>
                          })
                        }
                      },
                      {
                        key: 'actions',
                        title: <span className="pull-right"><Button type="ghost" size="small" onClick={this.fetchOrders.bind(null, this.state.user.id)} disabled={this.state.loading}><FontAwesomeIcon icon="sync-alt" spin={this.state.loading} /></Button></span>,
                        className: 'text-right',
                        render: (record: any) => <Button.Group>
                          <DeleteOrderButton {...this.props} order={record} onComplete={this.fetchOrders} />
                          <Tooltip placement="left" title={t('view_conversion_funnel', "View conversion funnel")}>
                            <Button type="ghost" icon={<EyeOutlined />} onClick={this.props.projectLayout.viewOrder.bind(null, record.conversionExternalId, record.conversionRuleId)} />
                          </Tooltip>
                        </Button.Group>
                      },
                    ]}
                  />
                </div>
              </Tabs.TabPane>}

              {this.props.projectLayout.project.conversionRules.find((cr: any) => cr.kind === "lead") && <Tabs.TabPane tab="Leads" key="leads">
                <div className="block margin-t-m">
                  <Table
                    dataSource={this.state.leads}
                    rowKey="id"
                    className="edge-table"
                    loading={this.state.leadsLoading}
                    pagination={false}
                    columns={[
                      {
                        title: 'ID',
                        key: 'externalId',
                        // sortOrder: params.sortKey === 'externalId' ? params.sortOrder : undefined,
                        // sorter: (a: any, b: any) => {
                        //   if (a.conversionExternalId < b.conversionExternalId) { return -1 }
                        //   if (a.conversionExternalId > b.conversionExternalId) { return 1 }
                        //   return 0
                        // },
                        render: (record: any) => {
                          if (record.conversionExternalId.length > 15) {
                            return <Tooltip title={record.conversionExternalId}>{_.truncate(record.conversionExternalId, { length: 16, omission: '...' })}</Tooltip>
                          }
                          return record.conversionExternalId
                        }
                      },
                      {
                        title: t('conversion_rule', "Conversion rule"),
                        key: 'conversionRule',
                        render: (record: any) => conversionRulesMap[record.conversionRuleId].name
                      },
                      {
                        key: 'stage',
                        title: 'Stage',
                        render: t => {
                          const stage = conversionRulesMap[t.conversionRuleId].leadStages.find(x => x.id === t.stageId)
                          if (!stage) return t.stageId
                          return <Tag color={stage.color}>{stage.label}</Tag>
                        }
                      },
                      {
                        title: 'Stage date',
                        key: 'createdAt',
                        sortOrder: params.sortKey === 'createdAt' ? params.sortOrder : undefined,
                        sorter: (a: any, b: any) => {
                          if (a.createdAt < b.createdAt) { return -1 }
                          if (a.createdAt > b.createdAt) { return 1 }
                          return 0
                        },
                        render: record => <small>{Moment(record.createdAt).format('llll')}</small>
                      },
                      {
                        key: 'revenue',
                        title: t('revenue', "Revenue"),
                        render: t => Formatters.currency(this.props.projectLayout.project.currency, t.revenue, t.revenueSource, t.currency, 'color-kpi-purple')
                      },
                      // {
                      //   title: t('funnel', "Funnel"),
                      //   key: 'funnel',
                      //   render: (record) => {
                      //     // console.log('record', record);

                      //     // group identical channelId if funnel has different source/medium
                      //     let funnel: any = []

                      //     _.forEach(record.funnel, f => {

                      //       if (!f.count) {
                      //         f.count = 0
                      //       }

                      //       // find channel
                      //       var hitChannel = this.props.projectLayout.project.channels.find((x: any) => x.id === f.channelId)
                      //       // console.log('hitChannel', hitChannel);

                      //       if (!hitChannel) {
                      //         hitChannel = { id: 'not-mapped', name: t('not_mapped', "Not mapped") }
                      //       }

                      //       f.channelId = hitChannel.id
                      //       f.channelName = hitChannel.name

                      //       if (funnel.length === 0) {
                      //         funnel.push(f)
                      //       } else {
                      //         if (funnel[funnel.length - 1].channelId === f.channelId) {
                      //           funnel[funnel.length - 1].count += f.count
                      //           if (funnel[funnel.length - 1].role < f.role) {
                      //             funnel[funnel.length - 1].role = f.role
                      //           }
                      //         } else {
                      //           funnel.push(f)
                      //         }
                      //       }
                      //     })

                      //     return funnel.map((contribution: any, i: number) => {

                      //       if (i === 2) return <Popover key={i} content={<div>
                      //         {funnel.map((c: any, k: number) => <div key={k}>{(c.channelId === 'not-mapped') ? c.source + ' / ' + c.medium : channelsMap[c.channelId].name}</div>)}</div>}>
                      //         <small className="link">+{funnel.length - 2} {t('more', "more")}</small>
                      //       </Popover>
                      //       if (i > 2) return null
                      //       return <div key={i}><small>{(contribution.channelId === 'not-mapped') ? contribution.source + ' / ' + contribution.medium : channelsMap[contribution.channelId].name}</small></div>
                      //     })
                      //   }
                      // },
                      // {
                      //   key: 'actions',
                      //   title: <span className="pull-right"><Button type="ghost" size="small" onClick={this.fetchOrders.bind(null, this.state.user.id)} disabled={this.state.loading}><FontAwesomeIcon icon="sync-alt" spin={this.state.loading} /></Button></span>,
                      //   className: 'text-right',
                      //   render: (record: any) => <Button.Group>
                      //     <DeleteOrderButton {...this.props} order={record} onComplete={this.fetchOrders} />
                      //     <Tooltip placement="left" title={t('view_conversion_funnel', "View conversion funnel")}>
                      //       <Button type="ghost" icon={<EyeOutlined />} onClick={this.props.projectLayout.viewOrder.bind(null, record.conversionExternalId, record.conversionRuleId)} />
                      //     </Tooltip>
                      //   </Button.Group>
                      // },
                    ]}
                  />
                </div>
              </Tabs.TabPane>}
            </Tabs>
          </Col>
        </Row>
      </Drawer >
    );
  }
}

export default UsersShowDrawer