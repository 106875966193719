import React, { Component } from 'react'

import {
  DownOutlined,
  LogoutOutlined,
  MinusSquareOutlined,
  PlusSquareOutlined,
  // TagOutlined,
  // WarningOutlined,
  // CheckCircleTwoTone,
  // CloseCircleTwoTone,
} from '@ant-design/icons';

import { Table, Tooltip, Popover, Tag, Badge } from 'antd';
import t from '../../utils/translate'
import Formatters from '../../utils/formatters'
import Moment from 'moment-timezone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Numeral from 'numeral'
import _ from 'lodash'
import OrderDetails from '../orders/_details'
import OrderItemsTable from '../orders/_itemsTable'
// import Form from 'antd/lib/form/Form';

class TimelineFeed extends Component<any, any> {
  conversionRulesMap: any = _.keyBy(this.props.projectLayout.project.conversionRules, 'id')

  constructor(props: any) {
    super(props)

    this.state = {
      expandAllActions: this.props.expanded || false,
      expandedActions: {}
    }

    this.toggleAllActions = this.toggleAllActions.bind(this)
    this.toggleAction = this.toggleAction.bind(this)
    this.renderNotificationHit = this.renderNotificationHit.bind(this)
    this.renderNotificationTopicHit = this.renderNotificationTopicHit.bind(this)
  }


  toggleAction(id: string) {
    let action: any = {}
    action[id] = this.state.expandedActions[id] ? false : true
    this.setState({ expandedActions: Object.assign(this.state.expandedActions, action) })
  }

  toggleAllActions() {
    if (this.state.expandAllActions === true) {
      this.setState({ expandAllActions: false, expandedActions: {} })
    } else {
      this.setState({ expandAllActions: true })
    }
  }

  renderNotificationTopicHit(hit: any) {
    return <>
      <div className="size-13"><span className="semibold padding-r-s">Topic:</span> {hit.notificationTopicName} - {hit.notificationTopicKind}</div>
      <div className="size-13"><span className="semibold padding-r-s">Channel:</span> {hit.channel} {hit.recipient && <> - {hit.recipient}</>}</div>
      {hit.notificationTopicKind === 'collection' && <div className="size-13"><span className="semibold padding-r-s">Collection:</span> {hit.notificationTopicCollectionKind} - {hit.notificationTopicCollectionId}</div>}
      {hit.collectionItemExternalId && <div className="size-13"><span className="semibold padding-r-s">Collection item:</span> {hit.collectionItemExternalId}</div>}
      {hit.doubleOptIn && <div className="size-13">
        <span className="semibold padding-r-s">Double opt-in:</span> {hit.doubleOptInConfirmedAt ?
          <Tooltip title={Moment(hit.doubleOptInConfirmedAt).tz(this.props.app.state.admin.timezone).format('llll') + ' in ' + this.props.app.state.admin.timezone}>
            <span>
              <FontAwesomeIcon icon="check-square" className="color-green" /> {t('confirmed_after_x', 'Confirmed after {x} hours', { x: Moment(hit.doubleOptInConfirmedAt).diff(hit.createdAt, 'hours') })}
            </span>
          </Tooltip>
          : <>
            <FontAwesomeIcon icon="hourglass-half" className="color-grey" /> {t('not_yet_confirmed', 'Not yet confirmed')}
          </>}
      </div>}
    </>
  }

  renderNotificationHit(hit: any) {
    return <>
      {hit.withEmail && <div className="size-13">
        <span className="semibold padding-r-s">Email:</span> <Badge status={hit.emailSent ? 'success' : 'error'} text={hit.emailSent ? <Tooltip title={'Service Provider ID: ' + hit.emailExternalId}>Sent with template {hit.notificationEmailTemplateLanguage.toUpperCase()}</Tooltip> : hit.emailError} />
        {hit.emailIsDelivered && <span> - <Tooltip title={Moment(hit.emailDeliveredAt).tz(this.props.app.state.admin.timezone).format('llll') + ' in ' + this.props.app.state.admin.timezone}><span><FontAwesomeIcon icon="check-square" className="color-green" /> {t('delivered', 'Delivered')}</span></Tooltip></span>}
        {hit.emailIsDropped && <span> - <Tooltip title={Moment(hit.emailDroppedAt).tz(this.props.app.state.admin.timezone).format('llll') + ' in ' + this.props.app.state.admin.timezone}><span><FontAwesomeIcon icon="times" className="color-red" /> {t('dropped', 'Dropped')}</span></Tooltip></span>}
        {hit.emailIsBounced && <span> - <Tooltip title={Moment(hit.emailBouncedAt).tz(this.props.app.state.admin.timezone).format('llll') + ' in ' + this.props.app.state.admin.timezone}><span><FontAwesomeIcon icon="hourglass-half" className="color-red" /> {t('bounced', 'Bounced')}</span></Tooltip></span>}
        {hit.emailIsComplained && <span> - <Tooltip title={Moment(hit.emailComplainedAt).tz(this.props.app.state.admin.timezone).format('llll') + ' in ' + this.props.app.state.admin.timezone}><span><FontAwesomeIcon icon="thumbs-down" className="color-red" /> {t('complained', 'Complained')}</span></Tooltip></span>}
        {hit.emailIsOpened && <span> - <Tooltip title={Moment(hit.emailOpenedAt).tz(this.props.app.state.admin.timezone).format('llll') + ' in ' + this.props.app.state.admin.timezone}><span><FontAwesomeIcon icon="eye" className="color-green" /> {t('opened', 'Opened')}</span></Tooltip></span>}
        {hit.emailIsClicked && <span> - <Tooltip title={Moment(hit.emailClickedAt).tz(this.props.app.state.admin.timezone).format('llll') + ' in ' + this.props.app.state.admin.timezone}><span><FontAwesomeIcon icon="mouse-pointer" className="color-green" /> {t('clicked', 'Clicked')}</span></Tooltip></span>}
        {hit.emailIsUnsubscribed && <span> - <Tooltip title={Moment(hit.emailUnsubscribedAt).tz(this.props.app.state.admin.timezone).format('llll') + ' in ' + this.props.app.state.admin.timezone}><span><FontAwesomeIcon icon="unlink" className="color-orange" /> {t('unsubscribed', 'Unsubscribed')}</span></Tooltip></span>}
      </div>}
      {hit.withSMS && <div className="size-13">
        <span className="semibold padding-r-s">SMS:</span> {hit.smsSent && hit.notificationSMSTemplateLanguage.toUpperCase()} <Badge status={hit.smsSent ? 'success' : 'error'} text={hit.smsSent ? 'Sent' : hit.smsError} />
        {hit.smsIsDelivered && <span> - <Tooltip title={Moment(hit.smsDeliveredAt).tz(this.props.app.state.admin.timezone).format('llll') + ' in ' + this.props.app.state.admin.timezone}><span><FontAwesomeIcon icon="check-square" className="color-green" /> {t('delivered', 'Delivered')}</span></Tooltip></span>}
        {hit.smsIsFailed && <span> - <Tooltip title={Moment(hit.smsFailedAt).tz(this.props.app.state.admin.timezone).format('llll') + ' in ' + this.props.app.state.admin.timezone}><span><FontAwesomeIcon icon="check-square" className="color-red" /> {t('failed', 'Failed')}</span></Tooltip></span>}
      </div>}
    </>
  }

  render() {

    // if (this.state.loading === true || (!this.props.user && !this.props.company)) {
    //   return <div className="text-center margin-a-l"><Spin size="large" /></div>
    // } 

    let profileTimezone = 'UTC'

    if (this.props.user && this.props.user.timezone !== '') {
      profileTimezone = this.props.user.timezone || 'UTC'
    }

    // BUG sometimes company is set for nothing
    // if (this.props.company && this.props.company.timezone) {
    //   profileTimezone = this.props.company.timezone
    // }

    const now = new Date()

    // this.props.timeline.filter((x: any) => x.kind === 'workflow' || x.kind === 'notification').forEach((x: any) => console.log(x.kind, x))

    return (
      <div className="margin-t-m">

        <div className="margin-h-m size-12">
          <span className="pull-right">{this.state.expandAllActions ? <span className="link" onClick={this.toggleAllActions}><MinusSquareOutlined /> {t('collapse_all', "Collapse all")}</span> : <span className="link" onClick={this.toggleAllActions}><PlusSquareOutlined /> {t('expand_all', "Expand all")}</span>}</span>
          {/*<span className="semibold">Timeline</span> */}
        </div>

        {this.props.timeline.sort((a: any, b: any) => {
          const aa = new Date(a.createdAt).getTime()
          const bb = new Date(b.createdAt).getTime()

          if (aa < bb) return 1
          if (aa > bb) return -1
          return 0
        }).map((parent: any) => {

          // console.log('parent', parent);
          let conversionRule

          if (parent.conversionRuleId && parent.conversionRuleId !== "") {
            conversionRule = this.props.projectLayout.project.conversionRules.find((cr: any) => cr.id === parent.conversionRuleId)
          }

          // console.log('line', line);

          const channel = this.props.projectLayout.project.channels.find((x: any) => x.id === parent.channelId)
          const channelGroup = this.props.projectLayout.project.channelGroups.find((x: any) => {
            if (!channel && x.id === 'not-mapped') return true
            if (channel && channel.groupId === x.id) return true 
            return false
          })

          // parent.children means its a session

          let user = this.props.user
          let userContext = null
          let company = this.props.company || null

          // company timeline
          if (this.props.company) {
            user = this.props.company.users.find((u: any) => u.id === parent.userId)

            if (user) {
              userContext = <span className="pull-right">
                <Tooltip placement="left" title={(user.firstName || '') + ' ' + (user.lastName || '')}>
                  {user.photoURL ? <img className="user-picture" src={user.photoURL} alt="" /> : <FontAwesomeIcon icon="user-circle" />}
                </Tooltip>
              </span>
            }
          }

          let segment = null
          if (parent.kind === 'enterSegment') {
            segment = _.find(this.props.projectLayout.segments, s => s.id === parent.userSegment.segmentId)
          }
          if (parent.kind === 'exitSegment') {
            segment = _.find(this.props.projectLayout.segments, s => s.id === parent.userSegment.segmentId)
          }

          return (
            <div key={parent.id} className="padding-h-m padding-v-l">
              <div>
                <div className="margin-b-m">
                  <span className="size-12 text-center timeline-main-date semibold"><Tooltip title={Moment(parent.createdAt).tz(this.props.app.state.admin.timezone).format('llll') + ' in ' + this.props.app.state.admin.timezone}>{Moment(parent.createdAt).fromNow()}</Tooltip></span>

                  <span className="padding-l-s">
                    {userContext}
                    <Tooltip title={<span className="size-12">ID: {parent.id}</span>}>
                      <Tag className="no-pointer" color={Formatters.actionTagColor(parent.kind)}>{t('tag_' + parent.kind, parent.kind)}</Tag>
                    </Tooltip>

                    <span className="padding-h-s color-black-40">{Formatters.deviceIcon(parent.device)}</span>

                    {parent.children && _.find(parent.children, hit => hit.kind === 'signup') && <Tag color="green">{t('signup', "Sign up")}</Tag>}

                    {conversionRule && <Popover title={t('conversion', "Conversion")} content={<div key={parent.conversionRuleId}><b>{conversionRule.name}:</b> <span className="padding-h-s">{parent.conversionExternalId}</span></div>}>
                      {Formatters.funnelRole(parent.role)}
                    </Popover>}

                    {parent.kind === 'session' && parent.bounced && Moment(parent.expiresAt).isBefore(now) && <Tooltip title={t('bounced', "Bounced")}><span className="padding-h-s color-kpi-orange"><LogoutOutlined /></span></Tooltip>}

                    {(parent.kind === 'enterSegment' || parent.kind === 'exitSegment') && <span>
                      {segment && <Tag className="no-pointer" color={segment.color !== 'default' && segment.color}>{segment.name}</Tag>}
                    </span>}

                    {parent.kind === 'notificationTopic' && <><Badge className="size-12" status={parent.isSubscribing ? 'success' : 'error'} text={parent.isSubscribing ? t('subscribed', 'Subscribed') : t('unsubscribed', 'Unsubscribed')} /></>}
                  </span>
                </div>

                <div className="margin-b-m size-13 timeline-main-info">
                  {parent.kind === 'notification' && this.renderNotificationHit(parent)}

                  {parent.kind === 'notificationTopic' && this.renderNotificationTopicHit(parent)}

                  {parent.kind === 'workflow' && <div>
                    <div><span className="semibold padding-r-s">Workflow:</span> {parent.workflowName}</div>
                    <div><span className="semibold padding-r-s">Trigger:</span> {Formatters.workflowTriggerForTimeline(parent.workflowTriggerKind, this.props.projectLayout.segments, parent.workflowTriggerSegmentId, parent.workflowTriggerEventCustomLabel, parent.workflowTriggerLeadStageId)}</div>
                    {parent.wakeUpAt && <div><span className="semibold padding-r-s">Wake up:</span> {Moment(parent.wakeUpAt).fromNow()}</div>}
                    {parent.experimentIds.length > 0 && <div><span className="semibold padding-r-s">Experiments:</span> {parent.experimentIds.join(', ')}</div>}
                  </div>}

                  {parent.source && <div>
                    <div><span className="semibold padding-r-s">{t('channel', "Channel")}:</span>{channel ? channel.name : 'not mapped'} <span className="padding-l-s"><Tag className="no-pointer xsmall" color={channelGroup.color}>{channelGroup.name}</Tag></span></div>
                    <div><span className="semibold padding-r-s">Source / Medium:</span>{parent.sourceMedium}</div>
                    {parent.campaignName && <div><span className="semibold padding-r-s">{t('campaign', "Campaign")}:</span>{parent.campaignName}</div>}
                    {parent.creativeName && <div><span className="semibold padding-r-s">{t('creative', "Creative")}:</span>{parent.creativeName}</div>}
                    {parent.keywordName && <div><span className="semibold padding-r-s">{t('keyword', "Keyword")}:</span>{parent.keywordName}</div>}
                    {parent.referrer && <div><span className="semibold padding-r-s">Referrer:</span><a href={parent.referrer} target="_blank" rel="noopener noreferrer">{parent.referrer}</a></div>}
                    {/* impresisons */}
                    {parent.adFormat && <div><span className="semibold padding-r-s">Ad format:</span>{parent.adFormat}</div>}
                    {parent.insertionOrderId && <div><span className="semibold padding-r-s">Insertion order:</span>{parent.insertionOrderId}</div>}
                    {parent.lineItemId && <div><span className="semibold padding-r-s">Line item:</span>{parent.lineItemId}</div>}
                    {parent.splitId && <div><span className="semibold padding-r-s">Split:</span>{parent.splitId}</div>}
                    {parent.foldPosition && <div><span className="semibold padding-r-s">Fold position:</span>{parent.foldPosition}</div>}
                    {parent.kind === 'impression' && <div><span className="semibold padding-r-s">Impression clicked:</span>{parent.isClicked ? 'yes' : 'no'}</div>}
                    {parent.viewResult && <div><span className="semibold padding-r-s">Impression viewed:</span>{parent.viewResult}</div>}
                  </div>}

                  {parent.kind === 'order' && <div>
                    <Popover
                      overlayClassName="no-inner-padding"
                      title={<span>{parent.publicUrl && <span className="pull-right"><a href={parent.publicUrl} target="_blank" rel="noopener noreferrer">{t('order_status_url', "View order status")} <FontAwesomeIcon icon="external-link-alt" /></a></span>} {t('tag_order', "Order")}</span>}
                      content={<OrderDetails app={this.props.app} projectLayout={this.props.projectLayout} order={parent} user={user} />}
                    >
                      <div><span className="semibold padding-r-s">{t('conversion_rule', "Conversion rule")}:</span>{this.conversionRulesMap[parent.conversionRuleId].name}</div>
                      <div><span className="semibold padding-r-s">ID:</span>{parent.conversionExternalId}</div>
                      <div><span className="semibold padding-r-s">{t('revenue', "Revenue")}:</span>{Formatters.currency(this.props.projectLayout.project.currency, parent.revenue, parent.revenueSource, parent.currency)}</div>
                    </Popover>
                    {parent.items.length > 0 && <OrderItemsTable currency={parent.currency} projectLayout={this.props.projectLayout} items={parent.items} />}
                  </div>}

                  {parent.kind === 'abandonedCart' && <div>
                    {parent.items.length > 0 && <OrderItemsTable currency={parent.currency} projectLayout={this.props.projectLayout} items={parent.items} />}
                  </div>}

                  {parent.children && parent.children.length > 2 && this.state.expandAllActions === false && this.state.expandedActions[parent.id] !== true && <div className="link size-12 margin-t-m" onClick={this.toggleAction.bind(null, parent.id)}>
                    {parent.children.length} {t('actions_view_more', "actions, view more...")}
                  </div>}

                  {(this.state.expandAllActions === true || this.state.expandedActions[parent.id] === true) && <DownOutlined className="timeline-main-arrow" />}
                </div>

                {parent.children && parent.children.length > 0 && (parent.children.length < 3 || this.state.expandAllActions === true || this.state.expandedActions[parent.id] === true) && <Table rowKey="id"
                  size="middle"
                  showHeader={false}
                  dataSource={parent.children}
                  pagination={false}
                  columns={[
                    {
                      title: '',
                      key: 'time',
                      className: 'text-center',
                      width: '140px',
                      render: (text, hit: any, i: number) => {
                        return <span className="size-12">{(i === 0) ? Moment(hit.createdAt).tz(profileTimezone).format('LT') : '+' + Formatters.duration((new Date(hit.createdAt).getTime() - new Date(parent.children[i - 1].createdAt).getTime()) / 1000)}</span>
                      }
                    },
                    {
                      title: '',
                      key: 'content',
                      render: (hit) => {

                        let pop, companyContext

                        if (hit.companyId && this.props.companies) {
                          company = this.props.companies.find((c: any) => c.id === hit.companyId) || null
                        }

                        // show company context in user timeline, not company timeline
                        if (hit.companyId && company && !this.props.company) {
                          companyContext = <span className="pull-right">
                            <Tooltip placement="left" title={company.name || company.id}>
                              {company.photoURL ? <img className="user-picture" src={company.photoURL} alt="" /> : <FontAwesomeIcon icon="building" />}
                            </Tooltip>
                          </span>
                        }

                        switch (hit.kind) {

                          case 'notification':
                            return <div>
                              {companyContext}
                              <div className="margin-b-s"><Tag className="no-pointer margin-r-s" color={Formatters.actionTagColor('notification')}>Notification</Tag> {hit.notificationName}</div>

                              {this.renderNotificationHit(hit)}
                            </div>

                          case 'notificationTopic':
                            return <div>
                              {companyContext}
                              <div className="margin-b-s"><Tag className="no-pointer margin-r-s" color={Formatters.actionTagColor('notificationTopic')}>Notification Topic</Tag> <Badge status={hit.isSubscribing ? 'success' : 'warning'} text={hit.isSubscribing ? t('subscribed', 'Subscribed') : t('unsubscribed', 'Unsubscribed')} /></div>

                              {this.renderNotificationTopicHit(hit)}
                            </div>

                          case 'pageview':
                            const pageLink = <a href={hit.pageId} target="_blank" rel="noopener noreferrer">{hit.pageId}</a>
                            const referrerLink = <a href={hit.referrer} target="_blank" rel="noopener noreferrer">{hit.referrer}</a>

                            if (hit.product) {

                              pop = <div>
                                <div><span className="semibold">SKU:</span> {hit.product.sku}</div>
                                <div><span className="semibold">{t('product_name', "Name")}:</span> {hit.product.name}</div>
                                {hit.product.brand && <div><span className="semibold">{t('brand', "Brand")}:</span> {hit.product.brand}</div>}
                                {hit.product.variant && <div><span className="semibold">{t('variant', "Variant")}:</span> {hit.product.variant}</div>}
                                {hit.product.category && <div><span className="semibold">{t('category', "Category")}:</span> {hit.product.category}</div>}
                                {hit.product.price && <div><span className="semibold">{t('price', "Price")}:</span> {Formatters.currency(this.props.projectLayout.project.currency, hit.product.price, hit.product.priceSource, hit.product.currency)}</div>}
                                <div><span className="semibold">URL:</span> {pageLink}</div>
                                <div><span className="semibold">{t('time_spent', "Time spent")}:</span> {hit.duration > 0 ? Formatters.duration(hit.duration) : t('unknown', "unknown")}</div>
                                {hit.referrer && <div><span className="semibold">Referrer:</span> {referrerLink}</div>}
                                {hit.props && <div><div className="semibold">{t('properties', "Properties")}:</div>
                                  <pre>{JSON.stringify(hit.props, null, 2)}</pre></div>}
                                {hit.imageURL && <div className="margin-t-m">
                                  <img src={hit.imageURL} className="timeline-hit-image" alt="" />
                                </div>}
                              </div>

                              return <span>
                                {companyContext}
                                <Popover title={t('tag_product', "Product viewed")} content={pop}><Tag className="no-pointer" color="blue">{t('tag_product', "Product viewed")}</Tag>
                                  {hit.product.name + ' ' + hit.product.brand + (hit.product.variant && ', ' + hit.product.variant)}{hit.product.price && <span>, {Formatters.currency(this.props.projectLayout.project.currency, hit.product.price, hit.product.priceSource, hit.product.currency)}</span>}</Popover>
                              </span>

                            } else if (hit.article) {

                              pop = <div>
                                <div><span className="semibold">{t('author', "Author")}:</span> {hit.article.author}</div>
                                <div><span className="semibold">{t('published_at', "Published at")}:</span> {Moment(hit.article.publishedAt).fromNow()}</div>
                                {hit.article.modifiedAt && <div><span className="semibold">{t('modified_at', "Modified at")}:</span> {Moment(hit.article.modifiedAt).fromNow()}</div>}
                                <div><span className="semibold">URL:</span> {pageLink}</div>
                                <div><span className="semibold">{t('time_spent', "Time spent")}:</span> {hit.duration > 0 ? Formatters.duration(hit.duration) : t('unknown', "unknown")}</div>
                                {hit.referrer && <div><span className="semibold">Referrer:</span> {referrerLink}</div>}
                                {hit.props && <div><div className="semibold">{t('properties', "Properties")}:</div>
                                  <pre>{JSON.stringify(hit.props, null, 2)}</pre></div>}
                                {hit.imageURL && <div className="margin-t-m">
                                  <img src={hit.imageURL} className="timeline-hit-image" alt="" />
                                </div>}
                              </div>

                              return <span>
                                {companyContext}
                                <Popover title={t('tag_article', "Article viewed")} content={pop}><Tag className="no-pointer" color="blue">{t('tag_article', "Article viewed")}</Tag>
                                  {hit.title} &bull; {hit.article.author}</Popover>
                              </span>

                            } else {

                              pop = <div>
                                <div><span className="semibold">URL:</span> {pageLink}</div>
                                <div><span className="semibold">{t('time_spent', "Time spent")}:</span> {hit.duration > 0 ? Formatters.duration(hit.duration) : t('unknown', "unknown")}</div>
                                {hit.referrer && <div><span className="semibold">Referrer:</span> {referrerLink}</div>}
                                {hit.props && <div><div className="semibold">{t('properties', "Properties")}:</div>
                                  <pre>{JSON.stringify(hit.props, null, 2)}</pre></div>}
                                {hit.imageURL && <div className="margin-t-m">
                                  <img src={hit.imageURL} className="timeline-hit-image" alt="" />
                                </div>}
                              </div>

                              return <span>
                                {companyContext}
                                <Popover title={t('tag_pageview', "Page viewed")} content={pop}><Tag className="no-pointer" color="blue">{t('tag_pageview', "Page viewed")}</Tag>
                                  {hit.title}</Popover>
                              </span>
                            }

                          case 'event':
                            pop = <div>
                              {hit.value && <div><div className="semibold">{t('value', "Value")}: {Numeral(hit.value / 100).format('0,0[.]0')}</div></div>}
                              {hit.props && <div><div className="semibold">{t('properties', "Properties")}:</div>
                                <pre>{JSON.stringify(hit.props, null, 2)}</pre></div>}
                            </div>

                            return <span>
                              {companyContext}
                              <Popover title={t('tag_event', "Event")} content={pop}><Tag className="no-pointer" color="purple">{hit.label}</Tag>
                                {hit.value && ' - ' + Numeral(hit.value / 100).format('0,0[.]0')}</Popover>
                            </span>

                          case 'order':

                            return <div>
                              {companyContext}
                              <Popover
                                overlayClassName="no-inner-padding"
                                title={<span>{hit.publicUrl && <span className="pull-right"><a href={hit.publicUrl} target="_blank" rel="noopener noreferrer">{t('order_status_url', "View order status")} <FontAwesomeIcon icon="external-link-alt" /></a></span>} {t('tag_order', "Order")}</span>}
                                content={<OrderDetails app={this.props.app} projectLayout={this.props.projectLayout} order={hit} user={user} />}
                              >
                                <Tag className="no-pointer" color="volcano">{t('tag_order', "Order")}</Tag>
                                <span className="semibold">{hit.conversionExternalId}<span className="padding-h-m">{Formatters.currency(this.props.projectLayout.project.currency, hit.revenue, hit.revenueSource, hit.currency)}</span></span> {this.conversionRulesMap[hit.conversionRuleId].name}
                              </Popover>
                              {hit.items.length > 0 && <OrderItemsTable currency={hit.currency} projectLayout={this.props.projectLayout} items={hit.items} />}
                            </div>

                          case 'cart':
                            return <span>
                              {companyContext}
                              <Tag className="no-pointer" color="magenta">{t('tag_cart', "Cart")}</Tag>
                              <OrderItemsTable projectLayout={this.props.projectLayout} items={hit.items} />
                            </span>

                          case 'wishList':
                            return <span>
                              {companyContext}
                              <Tag className="no-pointer" color="magenta">{t('tag_wishList', "Wish list")}</Tag>
                              <OrderItemsTable projectLayout={this.props.projectLayout} items={hit.items} />
                            </span>

                          case 'signup':
                            return <span>
                              {companyContext}
                              <Tag className="no-pointer" color="green">{t('signup', "Sign up")}</Tag>
                            </span>

                          case 'addUserToCompany':
                            return <span>
                              {companyContext}
                              <Tag className="no-pointer" color="purple">{t('tag_addUserToCompany', "Add user to company")}</Tag>
                              {company && (company.name || company.externalId)}{hit.role && <span> - {hit.role}</span>}
                            </span>

                          case 'lead':
                            const conversionRule = this.props.projectLayout.project.conversionRules.find((cr: any) => cr.id === hit.conversionRuleId)
                            const stage = conversionRule.leadStages.find((s: any) => s.id === hit.stageId)

                            return <Popover title="Lead" content={<div>
                              <div className="semibold">{t('properties', "Properties")}:</div>
                              <pre>{JSON.stringify(hit.props, null, 2)}</pre>
                            </div>}>
                              {companyContext}
                              <Tag className="no-pointer" color={stage.color}>Lead: {stage.label}</Tag>
                              {hit.revenue && Formatters.currency(this.props.projectLayout.project.currency, hit.revenue, hit.revenueSource, hit.currency)}
                            </Popover>

                          default: return hit.kind + ' is not yet implemented...'
                        }

                      }
                    }
                  ]}
                />}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default TimelineFeed