import React, { Component } from 'react'

import t from '../../../utils/translate'
import { Table } from 'antd'
import Formatters from '../../../utils/formatters'
import removeLeadingDates from 'utils/removeLeadingDates'
import N from 'numeral'

class PartnershipsTable extends Component<any, any> {
    _isMounted = false;

    constructor(props: any) {
        super(props)

        this.state = {
            loading: true,
            current: [],
            previous: [],
        }

        this.fetch = this.fetch.bind(this)
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetch()
    }

    componentDidUpdate(prevProps: any) {
        // check url params have changed
        if (prevProps.refreshAt !== this.props.refreshAt) {
            window.setTimeout(() => this.fetch())
        } else if (prevProps.project.id !== this.props.project.id) {
            window.setTimeout(() => this.fetch())
        } else if (prevProps.filters !== this.props.filters) {
            window.setTimeout(() => this.fetch())
        }
    }

    fetch() {

        if (!this.state.loading) {
            this.setState({ loading: true })
        }

        const opts: any = {
            measures: [
                'TimelineSessions.count',
                'TimelineOrders.revenue',
                'TimelineOrders.averageCart',
                'TimelineSessions.cpc',
                'TimelineSessions.cpa',
                'TimelineSessions.averageCommissionRate',
            ],
            dimensions: [
                'TimelineSessions.sourceMedium',
                'TimelineSessions.commissionPartnershipId',
                'TimelineSessions.commissionChannelId',
            ],
            timeDimensions: [
                {
                    "dimension": 'TimelineSessions.truncCreatedAt',
                    "granularity": null,
                    "compareDateRange": [
                        [this.props.dateFrom, this.props.dateTo],
                        [this.props.dateFromPrevious, this.props.dateToPrevious]
                    ]
                }
            ],
            timezone: this.props.timezone
        }

        if (this.props.filters) {
            opts.filters = this.props.filters
        }


        this.props.cubejsApi.load(opts).then((result: any) => {
            const [current, previous] = result.decompose()

            const currentLines = current.tablePivot()
            const previousLines = previous.tablePivot()

            removeLeadingDates(currentLines)
            removeLeadingDates(previousLines)

            this.setState({
                loading: false,
                current: currentLines,
                previous: previousLines,
            })
        })
            .catch((error: any) => {

                console.log(error)

                let message = error.message

                if (error.response && error.response.status === 400) {
                    switch (error.response.data.message) {
                        default:
                            message = error.response.data.message
                    }
                }

                message.error(message)
                this.setState({ loading: false })
            })
    }

    render() {

        // console.log('state kpi', this.state);

        return <div className="padding-a-m dash-table-stats">
            <Table
                dataSource={this.state.current}
                loading={this.state.loading}
                rowKey={record => record["TimelineSessions.sourceMedium"] + record["TimelineSessions.commissionPartnershipId"] + record["TimelineSessions.commissionChannelId"]}
                columns={[
                    {
                        title: 'Media Partner',
                        key: 'commissionPartnershipId',
                        render: (record: any) => {
                            //   const domain = this.props.project.domains.find((x: any) => x.id === record['TimelineSessions.domainId'])
                            //   if (domain) return domain.name
                            return record['TimelineSessions.commissionPartnershipId']
                        }
                    },
                    {
                        title: 'Channel',
                        key: 'commissionChannelId',
                        render: (record: any) => {
                            //   const domain = this.props.project.domains.find((x: any) => x.id === record['TimelineSessions.domainId'])
                            //   if (domain) return domain.name
                            return record['TimelineSessions.commissionChannelId']
                        }
                    },
                    {
                        title: 'Source / Medium',
                        key: 'sourceMedium',
                        render: (record: any) => {
                            //   const domain = this.props.project.domains.find((x: any) => x.id === record['TimelineSessions.domainId'])
                            //   if (domain) return domain.name
                            return record['TimelineSessions.sourceMedium']
                        }
                    },
                    {
                        title: t('orders', "Orders"),
                        key: 'orders',
                        sorter: (a: any, b: any) => {
                            if (a['TimelineSessions.count'] < b['TimelineSessions.count']) { return -1 }
                            if (a['TimelineSessions.count'] > b['TimelineSessions.count']) { return 1 }
                            return 0
                        },
                        sortOrder: 'descend',
                        showSorterTooltip: false,
                        render: (record: any) => <span className="value">{N(record['TimelineSessions.count']).format('0,0')}</span>
                    },
                    {
                        title: t('revenue', "Revenue"),
                        key: 'revenue',
                        render: (record: any) => Formatters.currency(this.props.project.currency, record['TimelineOrders.revenue'])
                    },
                    {
                        title: t('avg_cart', "Avg. cart"),
                        key: 'averageCart',
                        render: (record: any) => Formatters.currency(this.props.project.currency, record['TimelineOrders.averageCart'])
                    },
                    {
                        title: 'CPC',
                        key: 'cpc',
                        render: (record: any) => Formatters.currency(this.props.project.currency, record['TimelineSessions.cpc'])
                    },
                    {
                        title: 'CPA / CPL',
                        key: 'cpa',
                        render: (record: any) => Formatters.currency(this.props.project.currency, record['TimelineSessions.cpa'])
                    },
                    {
                        title: t('avg_commission_rate', "Avg. commission rate"),
                        key: 'commissionRate',
                        render: (record: any) => N(record['TimelineSessions.averageCommissionRate']).format('0.0') + '%'
                    },
                ]}
                pagination={false}
                size="middle"
            />
        </div>
    }
}

export default PartnershipsTable