import React, { Component } from 'react'

import t from '../../../utils/translate'
import { Table, Tooltip } from 'antd'
import Formatters from '../../../utils/formatters'
import N from 'numeral'
import _ from 'lodash'
import removeLeadingDates from 'utils/removeLeadingDates'

class TopProducts extends Component<any, any> {
    _isMounted = false;

    constructor(props: any) {
        super(props)

        this.state = {
            loading: true,
            current: [],
            previous: [],
        }

        this.fetch = this.fetch.bind(this)
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetch()
    }

    componentDidUpdate(prevProps: any) {
        // check url params have changed
        if (prevProps.refreshAt !== this.props.refreshAt) {
            window.setTimeout(() => this.fetch())
        } else if (prevProps.partnershipId !== this.props.partnershipId) {
            window.setTimeout(() => this.fetch())
        } else if (prevProps.filters !== this.props.filters) {
            window.setTimeout(() => this.fetch())
        }
    }

    fetch() {

        if (!this.state.loading) {
            if (this._isMounted) {
                this.setState({ loading: true })
            }
        }

        const opts: any = {
            measures: [
                'OrdersItems.quantities',
                'OrdersItems.revenue',
            ],
            dimensions: [
                'OrdersItems.externalId',
                'OrdersItems.sku',
                'OrdersItems.name',
                'OrdersItems.brand',
                'OrdersItems.variant',
            ],
            timeDimensions: [
                {
                    "dimension": 'TimelineOrders.truncCreatedAt',
                    "granularity": null,
                    "compareDateRange": [
                        [this.props.dateFrom, this.props.dateTo],
                        [this.props.dateFromPrevious, this.props.dateToPrevious]
                    ]
                }
            ],
            timezone: this.props.timezone,
            limit: 30,
            order: { 'OrdersItems.quantities': 'desc' }
        }

        if (this.props.filters) {
            opts.filters = this.props.filters
        }

        const tasks: any = []

        this.props.kpiPartnerships.forEach((p: any) => {
            tasks.push(p.cubejs.load(opts))
        })

        if (tasks.length === 0) {
            if (this._isMounted) {
                this.setState({
                    loading: false,
                    current: [],
                    previous: [],
                })
            }
            return
        }

        Promise.all(tasks).then((values: any) => {
            const currentMap: any = {}
            const previousMap: any = {}

            values.forEach((task: any, i: number) => {
                const [currentData, previousData] = task.decompose()
                const { advertiserCurrency, currencyRate } = this.props.kpiPartnerships[i]

                const currentLines = currentData.tablePivot()
                const previousLines = previousData.tablePivot()

                removeLeadingDates(currentLines)
                removeLeadingDates(previousLines)



                currentLines.forEach((line: any) => {

                    const productKey = line['OrdersItems.externalId'] + line['OrdersItems.sku'] + line['OrdersItems.name'] + line['OrdersItems.brand'] + line['OrdersItems.variant']

                    line.currenciesRevenue = {}

                    // convert revenue amounts
                    if (currencyRate !== 1 && advertiserCurrency !== this.props.organization.currency && line['TimelineOrders.revenue'] > 0) {
                        line.currenciesRevenue[advertiserCurrency] = line['TimelineOrders.revenue']
                        line['TimelineOrders.revenue'] = (currencyRate * line['TimelineOrders.revenue']) / 100
                    }

                    if (!currentMap[productKey]) {
                        currentMap[productKey] = line
                    } else {
                        currentMap[productKey] += line['TimelineOrders.quantities']
                        currentMap[productKey] += line['TimelineOrders.revenue']
                    }
                })
                previousLines.forEach((line: any) => {

                    const productKey = line['OrdersItems.externalId'] + line['OrdersItems.sku'] + line['OrdersItems.name'] + line['OrdersItems.brand'] + line['OrdersItems.variant']

                    // convert revenue amounts
                    if (currencyRate !== 1 && advertiserCurrency !== this.props.organization.currency && line['TimelineOrders.revenue'] > 0) {
                        line['TimelineOrders.revenue'] = (currencyRate * line['TimelineOrders.revenue']) / 100
                    }

                    if (!previousMap[productKey]) {
                        previousMap[productKey] = line
                    } else {
                        previousMap[productKey] += line['TimelineOrders.quantities']
                        previousMap[productKey] += line['TimelineOrders.revenue']
                    }
                })
            })

            if (this._isMounted) {
                this.setState({
                    loading: false,
                    current: _.toArray(currentMap),
                    previous: _.toArray(previousMap)
                })
            }
        })
            .catch((error: any) => {

                console.log(error)

                let message = error.message

                if (error.response && error.response.status === 400) {
                    switch (error.response.data.message) {
                        default:
                            message = error.response.data.message
                    }
                }

                message.error(message)
                if (this._isMounted) {
                    this.setState({ loading: false })
                }
            })
    }

    render() {

        // console.log('state kpi', this.state);

        return <div className="padding-a-m dash-table-stats">
            <Table
                dataSource={this.state.current}
                loading={this.state.loading}
                rowKey="OrdersItems.externalId"
                pagination={{
                    pageSize: 10,
                    showSizeChanger: false,
                    size: 'small'
                }}
                columns={[
                    {
                        title: t('top_products', 'Top products'),
                        key: 'name',
                        render: (record: any) => {
                            //   const domain = this.props.project.domains.find((x: any) => x.id === record['OrdersItems.domainId'])
                            //   if (domain) return domain.name
                            return <Tooltip title={'ID: ' + record['OrdersItems.externalId']}>
                                <small>{record['OrdersItems.name']}</small>
                            </Tooltip>
                        }
                    },
                    {
                        title: t('brand', 'Brand'),
                        key: 'brand',
                        render: (record: any) => {
                            //   const domain = this.props.project.domains.find((x: any) => x.id === record['OrdersItems.domainId'])
                            //   if (domain) return domain.name
                            return record['OrdersItems.brand']
                        }
                    },
                    {
                        title: t('variant', 'Variant'),
                        key: 'variant',
                        render: (record: any) => {
                            //   const domain = this.props.project.domains.find((x: any) => x.id === record['OrdersItems.domainId'])
                            //   if (domain) return domain.name
                            return record['OrdersItems.variant']
                        }
                    },
                    {
                        title: t('quantities', "Quantities"),
                        key: 'quantities',
                        sorter: (a: any, b: any) => {
                            if (a['OrdersItems.quantities'] < b['OrdersItems.quantities']) { return -1 }
                            if (a['OrdersItems.quantities'] > b['OrdersItems.quantities']) { return 1 }
                            return 0
                        },
                        sortOrder: 'descend',
                        showSorterTooltip: false,
                        render: (record: any) => <span className="value">{N(record['OrdersItems.quantities']).format('0,0')}</span>
                    },
                    {
                        title: t('revenue', "Revenue"),
                        key: 'revenue',
                        render: (record: any) => Formatters.currency(this.props.organization.currency, record['OrdersItems.revenue'])
                    },
                ]}
                size="middle"
            />
        </div>
    }
}

export default TopProducts