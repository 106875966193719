import React, { Component } from 'react'

import t from '../../utils/translate'
import { Steps, Spin } from 'antd'
import SetupDomains from './_setupDomains'
import SetupConversionRules from './_setupConversionRules'
import SetupSettings from './_setupSettings'
import _ from 'lodash'
import AppLayout from 'components/app/layout'

class ProjectSetup extends Component<any, any> {

  constructor(props: any) {
    super(props)

    this.state = {
      loading: false,
    }

    this.setStep = this.setStep.bind(this)
  }

  componentDidMount() {
    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Setup (project: ' + this.props.projectLayout.project.id + ')',
        page: this.props.location.pathname,
        props: {
          orgId: this.props.projectLayout.project.organizationId,
          projectId: this.props.projectLayout.project.id
        }
      })
      window.cmAgent.dispatch()
    }
  }

  setStep(step: any) {
    if (this.state.loading === true) {
      return
    }
    else {
      this.setState({ loading: true })
    }

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/projects.setSetupStep',
      data: { projectId: this.props.projectLayout.project.id, step: step }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }

      let projects = _.filter(this.props.organizationLayout.projects, (p: any) => p.id !== response.data.project.id)
      projects.push(response.data.project)
      this.props.organizationLayout.setProjects(projects)

      this.setState({ loading: false })

      if (window.cmAgent) {
        window.cmAgent.event({
          label: 'setSetupStep',
          props: {
            orgId: this.props.organizationLayout.organization.id,
            projectId: this.props.projectLayout.project.id,
            setupStep: step
          }
        })
        window.cmAgent.dispatch()
      }

      if (response.data.project.status === 'active') {
        this.props.history.push('/organizations/' + this.props.organizationLayout.organization.id + '/projects/' + response.data.project.id)
      }
    })
  }

  render() {

    let step = 0

    if (this.props.projectLayout.project.setupStep === "domains") {
      step = 1
    }

    if (this.props.projectLayout.project.setupStep === "conversionRules") {
      step = 2
    }

    if (this.props.projectLayout.project.setupStep === "settings") {
      step = 3
    }

    return <AppLayout currentProject={this.props.projectLayout.project} currentOrganization={this.props.organizationLayout.organization} admin={this.props.app.state.admin} firebaseUser={this.props.app.state.firebaseUser} projects={this.props.organizationLayout.projects}>
      <h1>{t('setup', "Setup")}</h1>

      <div className="margin-a-l">
        <Steps size="small" current={step} className="padding-b-m">
          <Steps.Step title={t('domains', "Domains")} />
          <Steps.Step title={t('conversion_rules', "Conversion rules")} />
          <Steps.Step title={t('settings', "Settings")} />
        </Steps>
      </div>

      <Spin size="large" spinning={this.state.loading}>
        {(this.props.projectLayout.project.setupStep === "start") && <SetupDomains {...this.props} setStep={this.setStep} />}
        {(this.props.projectLayout.project.setupStep === "domains") && <SetupConversionRules {...this.props} setStep={this.setStep} />}
        {(this.props.projectLayout.project.setupStep === "conversionRules") && <SetupSettings {...this.props} setStep={this.setStep} />}
      </Spin>
    </AppLayout>
  }
}

export default ProjectSetup