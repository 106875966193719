import AppLayout from 'components/app/layout'
import { Component } from 'react'

import t from '../../utils/translate'
import ProjectSettingsForm from './_settingsForm'

class ProjectSettings extends Component<any, any> {


  componentDidMount() {
    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Settings (project: ' + this.props.projectLayout.project.id + ')',
        page: this.props.location.pathname,
        props: {
          orgId: this.props.projectLayout.project.organizationId,
          projectId: this.props.projectLayout.project.id
        }
      })
      window.cmAgent.dispatch()
    }
  }

  render() {

    return <AppLayout currentProject={this.props.projectLayout.project} currentOrganization={this.props.organizationLayout.organization} admin={this.props.app.state.admin} firebaseUser={this.props.app.state.firebaseUser} projects={this.props.organizationLayout.projects}>

      <h1>{t('project_settings', "Project settings")}</h1>

      <ProjectSettingsForm {...this.props} />
    </AppLayout>
  }
}

export default ProjectSettings