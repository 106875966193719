import { useRef, useState, useEffect, useCallback } from 'react'

import {
    Table,
    Spin,
    Tag,
    Tooltip,
    Dropdown,
    Menu,
    Button,
    Modal,
    message,
} from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import t from '../../../utils/translate'
import Formatters from '../../../utils/formatters'
import Numeral from 'numeral'
import QS from 'qs'
import _ from 'lodash'
import cn from 'classnames'
import Role from './_role'
import { ResultSet, Query, SqlQuery } from '@cubejs-client/core'
import removeLeadingDates from 'utils/removeLeadingDates'
import { arrayToCSV, downloadCSV } from 'utils/csv'
import { useAnalyticsContext, AnalyticsContextValue } from './index'
import { useProjectContext, ProjectContextValue } from 'components/projects'
import { useHistory } from 'react-router'

const defaultSortKey = 'TimelineSessions.count'

const Report = ({ setExtraToolbar }) => {

    const [data, setData] = useState<any>({})
    const [loading, setLoading] = useState(false)
    const [loadingCSV, setLoadingCSV] = useState(false)
    const [expandedRowKeys, setExpandedRowKeys] = useState([])
    const analyticsContext: AnalyticsContextValue = useAnalyticsContext()
    const projectCtx: ProjectContextValue = useProjectContext()
    const history = useHistory() as any
    // const didMount = useRef(false)
    const analyticsFingerprint = useRef('')

    // default sort order
    const sortKey = analyticsContext.sortKey || defaultSortKey
    const sortOrder = analyticsContext.sortOrder

    const generateQuery = useCallback((
        groupBy: string[],
        segmentId: string,
        conversionType: string,
        domainId: string,
        dateFrom: string,
        dateTo: string,
        dateFromPrevious: string | null,
        dateToPrevious: string | null,
        dimensionFilters: any) => {

        const filters = [...dimensionFilters]

        if (segmentId !== '_all') {
            filters.push({
                'member': 'UserSegments.segmentId',
                'operator': 'equals',
                'values': [segmentId]
            })
        }

        if (conversionType !== 'all') {
            filters.push({
                'member': 'TimelineSessions.isFirstConversion',
                'operator': 'equals',
                'values': [conversionType === 'acquisition' ? '1' : '0']
            })
        }

        if (domainId !== '_all') {
            filters.push({
                'member': 'TimelineSessions.domainId',
                'operator': 'equals',
                'values': [domainId]
            })
        } else {
            const webDomains = projectCtx.currentProject.domains.filter((d: any) => !d.deletedAt && d.kind === 'web')
            filters.push({
                'member': 'TimelineSessions.domainId',
                'operator': 'in',
                'values': webDomains.map((x: any) => x.id)
            })
        }

        const compareDateRange: any = [[dateFrom, dateTo]]

        if (dateFromPrevious && dateToPrevious) {
            compareDateRange.push([dateFromPrevious, dateToPrevious])
        }

        const opts: Query = {
            measures: [
                'TimelineSessions.uniqueUsers',
                'TimelineSignups.count',
                'TimelineSessions.count',
                'TimelineSessions.bounceRate',
                'TimelineSessions.averagePageviewsCount',
                'TimelineSessions.averageSessionDuration',
                // 'TimelineSessions.contributions',
                'TimelineSessions.aloneCount',
                'TimelineSessions.initiatorCount',
                'TimelineSessions.assistingCount',
                'TimelineSessions.closerCount',
                'TimelineSessions.aloneRatio',
                'TimelineSessions.initiatorRatio',
                'TimelineSessions.assistingRatio',
                'TimelineSessions.closerRatio',
                'TimelineSessions.aloneAttributionLinearConversions',
                'TimelineSessions.initiatorAttributionLinearConversions',
                'TimelineSessions.assistingAttributionLinearConversions',
                'TimelineSessions.closerAttributionLinearConversions',
                'TimelineSessions.aloneAttributionLinearRevenue',
                'TimelineSessions.initiatorAttributionLinearRevenue',
                'TimelineSessions.assistingAttributionLinearRevenue',
                'TimelineSessions.closerAttributionLinearRevenue',
                'TimelineOrders.count',
                'TimelineOrders.revenue',
                'TimelineSessions.attributionLinearRevenue',
                'TimelineSessions.attributionLinearConversions',
                'TimelineOrders.averageCart',
            ],
            filters: filters,
            timeDimensions: [
                {
                    "dimension": 'TimelineSessions.truncCreatedAt',
                    "granularity": null,
                    "compareDateRange": compareDateRange
                }
            ],
            timezone: analyticsContext.timezone,
            order: {
                [sortKey]: sortOrder === 'descend' ? 'desc' : 'asc'
            },
            limit: 1000
        }

        if (groupBy) {
            opts.dimensions = groupBy
        }

        return opts
    }, [analyticsContext.timezone, sortKey, sortOrder, projectCtx.currentProject.domains])

    const fetchData = useCallback((parentId: string, groupBy: string, opts: any) => {

        return new Promise((resolve, reject) => {
            analyticsContext.cubejsApi.load(opts).then((value: ResultSet) => {

                const [currentChannels, previousChannels] = value.decompose()

                const returned: any = []

                const lines = currentChannels.tablePivot()
                const previousLines = previousChannels.tablePivot()

                removeLeadingDates(lines)
                removeLeadingDates(previousLines)

                lines.forEach((line: any) => {
                    const previousFound = previousLines.find((x: any) => {
                        if (groupBy !== '_all') {
                            return x[groupBy] === line[groupBy]
                        } else {
                            return true
                        }
                    })

                    const returnedLine: any = {
                        name: groupBy !== '_all' ? line[groupBy] : '_all',
                        expandable: (groupBy !== '_all' && groupBy !== 'TimelineSessions.sourceMedium') ? true : false,
                        childrenLoaded: false,
                        current: line,
                        previous: previousFound || {},
                    }

                    returnedLine.id = parentId !== '' ? parentId + '_' + returnedLine.name : returnedLine.name


                    if (returnedLine.expandable === true) {
                        returnedLine.children = [{ id: 'loading', name: 'loading' }]
                    }

                    returned.push(returnedLine)
                })

                resolve(returned)
                // return Analytics.CreateDataWithCurrentPrevious(currentChannelsTable, previousChannelsTable, groupBy)
            }).catch((error: any) => {

                console.log(error)

                let message = error.message

                if (error.response && error.response.status === 400) {
                    switch (error.response.data.message) {
                        default:
                            message = error.response.data.message
                    }
                }

                reject(message)
            })
        })
    }, [analyticsContext.cubejsApi])


    const loadRootData = useCallback((analyticsContext: AnalyticsContextValue) => {
        return new Promise((resolve, reject) => {

            setLoading(true)

            const filters: any = [{
                member: "TimelineSessions.campaignName",
                operator: "set"
            }]

            const totalsData = fetchData('', '_all', generateQuery(
                null,
                analyticsContext.segment,
                analyticsContext.conversionType,
                analyticsContext.domain,
                analyticsContext.dateFrom,
                analyticsContext.dateTo,
                analyticsContext.dateFromPrevious,
                analyticsContext.dateToPrevious,
                filters))

            const query = generateQuery(
                ['TimelineSessions.campaignName'],
                analyticsContext.segment,
                analyticsContext.conversionType,
                analyticsContext.domain,
                analyticsContext.dateFrom,
                analyticsContext.dateTo,
                analyticsContext.dateFromPrevious,
                analyticsContext.dateToPrevious,
                filters)

            const campaignsData = fetchData('', 'TimelineSessions.campaignName', query)

            const sqlQuery = analyticsContext.cubejsApi.sql(query)

            Promise.all([
                campaignsData,
                totalsData,
                sqlQuery,
            ]).then((values: any) => {

                // console.log('values', values)
                // embed current filters to each line
                values[0].forEach((x: any) => {
                    x.parent = {
                        id: '',
                        kind: 'TimelineSessions.campaignName',
                        filters: [...filters],
                    }
                })

                const newData = {
                    'groups': [...values[0], ...values[1]],
                }

                values[2].forEach((queryData: SqlQuery) => {
                  console.log(...queryData.rawQuery().sql)
                })

                setLoading(false)
                setData(newData)
                setExpandedRowKeys([]) // reset expanded lines
                resolve(newData)

            }).catch(msg => {
                message.error(msg)
                setLoading(false)
                reject(msg)
            })
        })
    }, [fetchData, generateQuery])

    const onExportCSV = useCallback((key: string) => {

        // console.log('analyticsContext', analyticsContext)

        setLoadingCSV(true)

        const csvColumns: string[] = [
            'id',
            'name',
            'uniqueUsers',
            'signups',
            'sessions',
            'bounceRate',
            'averagePageviews',
            'averageSessionDuration',
            'averageSessionDurationHumanized',
        ]

        if (analyticsContext.conversionRule) {
            csvColumns.push(
                'averageCart',
                'attributionContributiveConversions',
                'attributionContributiveRevenue',
                'attributionLinearConversions',
                'attributionLinearRevenue',
                'aloneAttributionLinearConversions',
                'initiatorAttributionLinearConversions',
                'assistingAttributionLinearConversions',
                'closerAttributionLinearConversions',
                'aloneAttributionLinearRevenue',
                'initiatorAttributionLinearRevenue',
                'assistingAttributionLinearRevenue',
                'closerAttributionLinearRevenue',
            )
        }

        csvColumns.push(
            'aloneContributions',
            'aloneRatio',
            'initiatorContributions',
            'initiatorRatio',
            'assistingContributions',
            'assistingRatio',
            'closerContributions',
            'closerRatio',

            'filterDateFrom',
            'filterDateTo',
            'filterTimezone',
            'filterUserSegment',
            'filterDomain',
            'filterAcquisitionOrRetention',
        )

        const csvArray: any[][] = [csvColumns]

        let q: any

        const filters: any = [{
            member: "TimelineSessions.campaignName",
            operator: "set"
        }]

        switch (key) {
            case 'campaigns':
                loadRootData(analyticsContext).then((data: any) => {

                    // console.log('data', data)

                    data.groups.forEach((group: any) => {

                        const csvLine: any[] = [
                            group.id,
                            group.name,
                            group.current['TimelineSessions.uniqueUsers'] || 0,
                            group.current['TimelineSignups.count'] || 0,
                            group.current['TimelineSessions.count'] || 0,
                            group.current['TimelineSessions.bounceRate'] || 0,
                            group.current['TimelineSessions.averagePageviewsCount'] || 0,
                            parseInt(group.current['TimelineSessions.averageSessionDuration'] || 0),
                            Formatters.duration(parseInt(group.current['TimelineSessions.averageSessionDuration'] || 0)),
                        ]

                        if (analyticsContext.conversionRule) {

                            csvLine.push((group.current['TimelineOrders.averageCart'] || 0) / 100)

                            if (group.id === '_all') {
                                csvLine.push(
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                )
                            } else {
                                csvLine.push(
                                    group.current['TimelineOrders.count'] || 0,
                                    (group.current['TimelineOrders.revenue'] || 0) / 100,
                                    group.current['TimelineSessions.attributionLinearConversions'] || 0,
                                    (group.current['TimelineSessions.attributionLinearRevenue'] || 0) / 100,
                                    group.current['TimelineSessions.aloneAttributionLinearConversions'],
                                    group.current['TimelineSessions.initiatorAttributionLinearConversions'],
                                    group.current['TimelineSessions.assistingAttributionLinearConversions'],
                                    group.current['TimelineSessions.closerAttributionLinearConversions'],
                                    (group.current['TimelineSessions.aloneAttributionLinearRevenue'] || 0) / 100,
                                    (group.current['TimelineSessions.initiatorAttributionLinearRevenue'] || 0) / 100,
                                    (group.current['TimelineSessions.assistingAttributionLinearRevenue'] || 0) / 100,
                                    (group.current['TimelineSessions.closerAttributionLinearRevenue'] || 0) / 100,
                                )
                            }
                        }

                        csvLine.push(
                            group.current['TimelineSessions.aloneCount'] || 0,
                            group.current['TimelineSessions.aloneRatio'] || 0,
                            group.current['TimelineSessions.initiatorCount'] || 0,
                            group.current['TimelineSessions.initiatorRatio'] || 0,
                            group.current['TimelineSessions.assistingCount'] || 0,
                            group.current['TimelineSessions.assistingRatio'] || 0,
                            group.current['TimelineSessions.closerCount'] || 0,
                            group.current['TimelineSessions.closerRatio'] || 0,

                            analyticsContext.dateFrom + ' 00:00:00',
                            analyticsContext.dateTo + ' 23:59:59',
                            analyticsContext.timezone,
                            analyticsContext.segment,
                            analyticsContext.domain,
                            analyticsContext.conversionType,
                        )

                        csvArray.push(csvLine)
                    })

                    const filename = key + '_' + analyticsContext.dateFrom + '_' + analyticsContext.dateTo + '.csv'
                    downloadCSV(arrayToCSV(csvArray), filename)
                    setLoadingCSV(false)
                }).catch(_err => {
                    setLoadingCSV(false)
                })
                break

            case 'campaigns_channels':

                q = generateQuery(
                    ['TimelineSessions.campaignName', 'TimelineSessions.channelId'],
                    analyticsContext.segment,
                    analyticsContext.conversionType,
                    analyticsContext.domain,
                    analyticsContext.dateFrom,
                    analyticsContext.dateTo,
                    null,
                    null,
                    filters)

                analyticsContext.cubejsApi.load(q).then((value: ResultSet) => {

                    // const [currentChannels, previousChannels] = value.decompose()

                    const data = value.tablePivot()

                    removeLeadingDates(data)
                    // console.log('data', data)

                    data.forEach((line: any) => {
                        const channel = projectCtx.currentProject.channels.find((ch: any) => ch.id === line['TimelineSessions.channelId'])

                        const csvLine: any[] = [
                            line['TimelineSessions.campaignName'] + ' > ' + line['TimelineSessions.channelId'],
                            line['TimelineSessions.campaignName'] + ' > ' + ((channel ? channel.name : line['TimelineSessions.channelId'])),
                            line['TimelineSessions.uniqueUsers'] || 0,
                            line['TimelineSignups.count'] || 0,
                            line['TimelineSessions.count'] || 0,
                            line['TimelineSessions.bounceRate'] || 0,
                            line['TimelineSessions.averagePageviewsCount'] || 0,
                            parseInt(line['TimelineSessions.averageSessionDuration'] || 0),
                            Formatters.duration(parseInt(line['TimelineSessions.averageSessionDuration'] || 0)),
                        ]

                        if (analyticsContext.conversionRule) {

                            csvLine.push((line['TimelineOrders.averageCart'] || 0) / 100)

                            if (line.id === '_all') {
                                csvLine.push(
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                )
                            } else {
                                csvLine.push(
                                    line['TimelineOrders.count'] || 0,
                                    (line['TimelineOrders.revenue'] || 0) / 100,
                                    line['TimelineSessions.attributionLinearConversions'] || 0,
                                    (line['TimelineSessions.attributionLinearRevenue'] || 0) / 100,
                                    line['TimelineSessions.aloneAttributionLinearConversions'],
                                    line['TimelineSessions.initiatorAttributionLinearConversions'],
                                    line['TimelineSessions.assistingAttributionLinearConversions'],
                                    line['TimelineSessions.closerAttributionLinearConversions'],
                                    (line['TimelineSessions.aloneAttributionLinearRevenue'] || 0) / 100,
                                    (line['TimelineSessions.initiatorAttributionLinearRevenue'] || 0) / 100,
                                    (line['TimelineSessions.assistingAttributionLinearRevenue'] || 0) / 100,
                                    (line['TimelineSessions.closerAttributionLinearRevenue'] || 0) / 100,
                                )
                            }
                        }

                        csvLine.push(
                            line['TimelineSessions.aloneCount'] || 0,
                            line['TimelineSessions.aloneRatio'] || 0,
                            line['TimelineSessions.initiatorCount'] || 0,
                            line['TimelineSessions.initiatorRatio'] || 0,
                            line['TimelineSessions.assistingCount'] || 0,
                            line['TimelineSessions.assistingRatio'] || 0,
                            line['TimelineSessions.closerCount'] || 0,
                            line['TimelineSessions.closerRatio'] || 0,

                            analyticsContext.dateFrom + ' 00:00:00',
                            analyticsContext.dateTo + ' 23:59:59',
                            analyticsContext.timezone,
                            analyticsContext.segment,
                            analyticsContext.domain,
                            analyticsContext.conversionType,
                        )

                        csvArray.push(csvLine)
                    })

                    // console.log(csvContent)
                    const filename = key + '_' + analyticsContext.dateFrom + '_' + analyticsContext.dateTo + '.csv'
                    downloadCSV(arrayToCSV(csvArray), filename)
                    setLoadingCSV(false)

                }).catch(_err => {
                    setLoadingCSV(false)
                })
                break

            case 'campaigns_source_medium':

                q = generateQuery(
                    ['TimelineSessions.campaignName', 'TimelineSessions.channelId', 'TimelineSessions.sourceMedium'],
                    analyticsContext.segment,
                    analyticsContext.conversionType,
                    analyticsContext.domain,
                    analyticsContext.dateFrom,
                    analyticsContext.dateTo,
                    null,
                    null,
                    filters)

                analyticsContext.cubejsApi.load(q).then((value: ResultSet) => {

                    // const [currentChannels, previousChannels] = value.decompose()

                    const data = value.tablePivot()

                    removeLeadingDates(data)
                    // console.log('data', data)

                    data.forEach((line: any) => {
                        const channel = projectCtx.currentProject.channels.find((ch: any) => ch.id === line['TimelineSessions.channelId'])

                        const csvLine: any[] = [
                            line['TimelineSessions.campaignName'] + ' > ' + ((channel ? channel.name : line['TimelineSessions.channelId'])) + ' > ' + line['TimelineSessions.sourceMedium'],
                            line['TimelineSessions.campaignName'] + ' > ' + ((channel ? channel.name : line['TimelineSessions.channelId'])) + ' > ' + line['TimelineSessions.sourceMedium'],
                            line['TimelineSessions.uniqueUsers'] || 0,
                            line['TimelineSignups.count'] || 0,
                            line['TimelineSessions.count'] || 0,
                            line['TimelineSessions.bounceRate'] || 0,
                            line['TimelineSessions.averagePageviewsCount'] || 0,
                            parseInt(line['TimelineSessions.averageSessionDuration'] || 0),
                            Formatters.duration(parseInt(line['TimelineSessions.averageSessionDuration'] || 0)),
                        ]

                        if (analyticsContext.conversionRule) {

                            csvLine.push((line['TimelineOrders.averageCart'] || 0) / 100)

                            if (line.id === '_all') {
                                csvLine.push(
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                )
                            } else {
                                csvLine.push(
                                    line['TimelineOrders.count'] || 0,
                                    (line['TimelineOrders.revenue'] || 0) / 100,
                                    line['TimelineSessions.attributionLinearConversions'] || 0,
                                    (line['TimelineSessions.attributionLinearRevenue'] || 0) / 100,
                                    line['TimelineSessions.aloneAttributionLinearConversions'],
                                    line['TimelineSessions.initiatorAttributionLinearConversions'],
                                    line['TimelineSessions.assistingAttributionLinearConversions'],
                                    line['TimelineSessions.closerAttributionLinearConversions'],
                                    (line['TimelineSessions.aloneAttributionLinearRevenue'] || 0) / 100,
                                    (line['TimelineSessions.initiatorAttributionLinearRevenue'] || 0) / 100,
                                    (line['TimelineSessions.assistingAttributionLinearRevenue'] || 0) / 100,
                                    (line['TimelineSessions.closerAttributionLinearRevenue'] || 0) / 100,
                                )
                            }
                        }

                        csvLine.push(
                            line['TimelineSessions.aloneCount'] || 0,
                            line['TimelineSessions.aloneRatio'] || 0,
                            line['TimelineSessions.initiatorCount'] || 0,
                            line['TimelineSessions.initiatorRatio'] || 0,
                            line['TimelineSessions.assistingCount'] || 0,
                            line['TimelineSessions.assistingRatio'] || 0,
                            line['TimelineSessions.closerCount'] || 0,
                            line['TimelineSessions.closerRatio'] || 0,

                            analyticsContext.dateFrom + ' 00:00:00',
                            analyticsContext.dateTo + ' 23:59:59',
                            analyticsContext.timezone,
                            analyticsContext.segment,
                            analyticsContext.domain,
                            analyticsContext.conversionType,
                        )

                        csvArray.push(csvLine)
                    })

                    const filename = key + '_' + analyticsContext.dateFrom + '_' + analyticsContext.dateTo + '.csv'
                    downloadCSV(arrayToCSV(csvArray), filename)
                    setLoadingCSV(false)

                }).catch(_err => {
                    setLoadingCSV(false)
                })
                break
            default:
        }
    }, [analyticsContext, generateQuery, loadRootData, projectCtx.currentProject.channels])


    // onmount
    useEffect(() => {

        // we need to update the toolbar otherwise onExportCSV doesnt pick the actual context values
        // to avoid infinite loop we compare analytics context values
        const fingerprint = JSON.stringify(analyticsContext)

        if (analyticsFingerprint.current === fingerprint) return
        analyticsFingerprint.current = fingerprint

        loadRootData(analyticsContext)

        setExtraToolbar(<Tooltip placement="topRight" title="Download CSV">
            <Dropdown className="margin-l-s" trigger={['click']} overlay={<Menu onClick={(e: any) => onExportCSV(e.key)}>
                <Menu.Item key="campaigns">Campaigns</Menu.Item>
                <Menu.Item key="campaigns_channels">Channels</Menu.Item>
                <Menu.Item key="campaigns_source_medium">Source / Medium</Menu.Item>
            </Menu>}>
                <Button size="small">
                    <DownloadOutlined />
                </Button>
            </Dropdown>
        </Tooltip>)
    }, [analyticsContext, loadRootData, onExportCSV, setExtraToolbar])

    // onupdate
    // useEffect(() => {
    //     loadRootData(analyticsContext)
    // }, [analyticsContext, loadRootData])


    const onExpand = (expanded: boolean, record: any) => {
        if (record.expandable === false) return

        if (expanded) {
            // add key
            const newKeys: any = [...expandedRowKeys]
            newKeys.push(record.id)
            setExpandedRowKeys(newKeys)
        } else {
            // remove eventual key
            const newKeys: any = expandedRowKeys.filter((x: any) => x !== record.id)
            setExpandedRowKeys(newKeys)
            return
        }

        let groupBy = 'TimelineSessions.channelId'
        let member = 'TimelineSessions.campaignName'

        if (record.current['TimelineSessions.channelId']) {
            groupBy = 'TimelineSessions.sourceMedium'
            member = 'TimelineSessions.channelId'
        }

        if (record.childrenLoaded === true) return

        const filters = [...record.parent.filters]

        // add current filter
        filters.push({
            'member': member,
            'operator': 'equals',
            'values': [record.name]
        })

        fetchData(record.id, groupBy, generateQuery(
            // record.id,
            [groupBy],
            analyticsContext.segment,
            analyticsContext.conversionType,
            analyticsContext.domain,
            analyticsContext.dateFrom,
            analyticsContext.dateTo,
            analyticsContext.dateFromPrevious,
            analyticsContext.dateToPrevious,
            filters,
        )).then((value: any) => {

            // add parent filter to the children
            value.forEach((x: any) => {
                x.parent = {
                    id: record.id,
                    kind: member,
                    filters: [...filters],
                }
            })

            const newData: any = { ...data }

            // if expand group
            if (member === 'TimelineSessions.campaignName') {
                const groupLine: any = newData['groups'].find((x: any) => x.id === record.id)
                groupLine.children = value
                groupLine.childrenLoaded = true
            }

            if (member === 'TimelineSessions.channelId') {

                newData['groups'].forEach((group: any) => {
                    if (group.children) {
                        group.children.forEach((ch: any) => {
                            if (ch.id === record.id) {
                                ch.children = value
                                ch.childrenLoaded = true
                            }
                        })
                    }
                })
            }

            // console.log('DATA', value)

            setData(newData)
        })

        // console.log(groupId)
    }


    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        // console.log('filters', filters);
        // console.log('pagination', pagination);
        // console.log('sorter', sorter);
        // const params: any = QS.parse(props.location.search, { ignoreQueryPrefix: true })

        const newParams = _.assign({
            sortKey: sortKey || 'sessions',
            sortOrder: sortOrder || 'descend',
        }, {
            sortKey: sorter.columnKey,
            sortOrder: sorter.order,
        })

        history.push('/organizations/' + projectCtx.currentOrganization.id + '/projects/' + projectCtx.currentProject.id + '/analytics/web?tab=campaigns&' + QS.stringify(newParams, { indices: false }));
    }


    const channelsMap = _.keyBy(projectCtx.currentProject.channels, 'id')
    const channelGroupsMap = _.keyBy(projectCtx.currentProject.channelGroups, 'id')

    const tableData = []

    if (data['groups']) {
        tableData.push(...data['groups'])
    }

    // console.log(tableData)

    const columns: any = [
        {
            title: 'Campaigns',
            className: 'table-border-right bg-white',
            // onClick: (e: any) => {
            //   console.log('click', e)
            // },
            width: 250,
            fixed: 'left',
            key: 'channel',
            render: (record: any) => {
                if (record.name === '_all') return ''
                if (record.name === 'loading') return <Spin size="small" />

                if (record.current['TimelineSessions.campaignName']) {
                    return record.name
                }

                if (record.current['TimelineSessions.channelId']) {
                    const channel = channelsMap[record.name]
                    const group = channel ? channelGroupsMap[channel.groupId] : undefined

                    return group ? <Tag color={group.color}>{channel.name}</Tag> : record.name
                }

                if (record.current['TimelineSessions.sourceMedium']) {
                    return record.name
                }

                return ''
            }
        },
        {
            title: t('traffic', "Traffic"),
            className: 'table-border-right table-border-bottom',
            width: 390,
            children: [
                {
                    title: t('users', "Users"),
                    width: 130,
                    key: 'TimelineSessions.uniqueUsers',
                    defaultSortOrder: 'descend',
                    sorter: (a: any, b: any) => {
                        if (a.id === 'loading') return 0

                        if (a.id === '_all' && sortOrder === 'descend') return 1
                        if (a.id === '_all' && sortOrder === 'ascend') return -1
                        if (b.id === '_all' && sortOrder === 'descend') return -1
                        if (b.id === '_all' && sortOrder === 'ascend') return 1

                        if (a.current['TimelineSessions.uniqueUsers'] > b.current['TimelineSessions.uniqueUsers']) return 1
                        if (a.current['TimelineSessions.uniqueUsers'] < b.current['TimelineSessions.uniqueUsers']) return -1
                        return 0
                    },
                    sortDirections: ['descend', 'ascend'],
                    sortOrder: sortKey === 'TimelineSessions.uniqueUsers' ? sortOrder : undefined,
                    render: (record: any) => {
                        if (record.name === 'loading') return <Spin size="small" />

                        return <div>
                            {Numeral(record.current['TimelineSessions.uniqueUsers']).format('0,0')}
                            <div className="size-10">{Formatters.growthRate(record.current['TimelineSessions.uniqueUsers'], record.previous['TimelineSessions.uniqueUsers'])}</div>
                        </div>
                    }
                },
                {
                    title: t('new_signups', "New signups"),
                    width: 130,
                    key: 'TimelineSignups.count',
                    defaultSortOrder: 'descend',
                    sorter: (a: any, b: any) => {
                        if (a.id === 'loading') return 0

                        if (a.id === '_all' && sortOrder === 'descend') return 1
                        if (a.id === '_all' && sortOrder === 'ascend') return -1
                        if (b.id === '_all' && sortOrder === 'descend') return -1
                        if (b.id === '_all' && sortOrder === 'ascend') return 1

                        if (a.current['TimelineSignups.count'] > b.current['TimelineSignups.count']) return 1
                        if (a.current['TimelineSignups.count'] < b.current['TimelineSignups.count']) return -1
                        return 0
                    },
                    sortDirections: ['descend', 'ascend'],
                    sortOrder: sortKey === 'TimelineSignups.count' ? sortOrder : undefined,
                    render: (record: any) => {
                        if (record.name === 'loading') return <Spin size="small" />
                        return <div>
                            {Numeral(record.current['TimelineSignups.count']).format('0,0')}
                            <div className="size-10">{Formatters.growthRate(record.current['TimelineSignups.count'], record.previous['TimelineSignups.count'])}</div>
                        </div >
                    }
                },
                {
                    title: t('sessions', "Sessions"),
                    width: 130,
                    key: 'TimelineSessions.count',
                    className: 'table-border-right',
                    defaultSortOrder: 'descend',
                    sorter: (a: any, b: any) => {
                        if (a.id === 'loading') return 0

                        if (a.id === '_all' && sortOrder === 'descend') return 1
                        if (a.id === '_all' && sortOrder === 'ascend') return -1
                        if (b.id === '_all' && sortOrder === 'descend') return -1
                        if (b.id === '_all' && sortOrder === 'ascend') return 1

                        if (a.current['TimelineSessions.count'] > b.current['TimelineSessions.count']) return 1
                        if (a.current['TimelineSessions.count'] < b.current['TimelineSessions.count']) return -1
                        return 0
                    },
                    sortDirections: ['descend', 'ascend'],
                    sortOrder: sortKey === 'TimelineSessions.count' ? sortOrder : undefined,
                    render: (record: any) => {
                        if (record.name === 'loading') return <Spin size="small" />
                        return <div>
                            {Numeral(record.current['TimelineSessions.count']).format('0,0')}
                            <div className="size-10">{Formatters.growthRate(record.current['TimelineSessions.count'], record.previous['TimelineSessions.count'])}</div>
                        </div>
                    }
                },
            ]
        },
        {
            title: t('behavior', "Behavior"),
            className: 'table-border-right table-border-bottom',
            width: 300,
            children: [
                {
                    title: t('bounce', "Bounce"),
                    width: 100,
                    key: 'TimelineSessions.bounceRate',
                    defaultSortOrder: 'descend',
                    sorter: (a: any, b: any) => {
                        if (a.id === 'loading') return 0

                        if (a.id === '_all' && sortOrder === 'descend') return 1
                        if (a.id === '_all' && sortOrder === 'ascend') return -1
                        if (b.id === '_all' && sortOrder === 'descend') return -1
                        if (b.id === '_all' && sortOrder === 'ascend') return 1

                        if (a.current['TimelineSessions.bounceRate'] > b.current['TimelineSessions.bounceRate']) return 1
                        if (a.current['TimelineSessions.bounceRate'] < b.current['TimelineSessions.bounceRate']) return -1
                        return 0
                    },
                    sortDirections: ['descend', 'ascend'],
                    sortOrder: sortKey === 'TimelineSessions.bounceRate' ? sortOrder : undefined,
                    render: (record: any) => {
                        if (record.name === 'loading') return <Spin size="small" />
                        return <div>
                            {Formatters.percentage(record.current['TimelineSessions.bounceRate'])}
                            <div className="size-10">{Formatters.growthRate(record.current['TimelineSessions.bounceRate'], record.previous['TimelineSessions.bounceRate'], true)}</div>
                        </div>
                    }
                },
                {
                    title: t('pages_per_session', "Pages / session"),
                    width: 100,
                    key: 'TimelineSessions.averagePageviewsCount',
                    sorter: (a: any, b: any) => {
                        if (a.id === 'loading') return 0

                        if (a.id === '_all' && sortOrder === 'descend') return 1
                        if (a.id === '_all' && sortOrder === 'ascend') return -1
                        if (b.id === '_all' && sortOrder === 'descend') return -1
                        if (b.id === '_all' && sortOrder === 'ascend') return 1

                        if (a.current['TimelineSessions.averagePageviewsCount'] > b.current['TimelineSessions.averagePageviewsCount']) return 1
                        if (a.current['TimelineSessions.averagePageviewsCount'] < b.current['TimelineSessions.averagePageviewsCount']) return -1
                        return 0
                    },
                    defaultSortOrder: 'descend',
                    sortDirections: ['descend', 'ascend'],
                    sortOrder: sortKey === 'TimelineSessions.averagePageviewsCount' ? sortOrder : undefined,
                    render: (record: any) => {
                        if (record.name === 'loading') return <Spin size="small" />
                        return <div>
                            {Numeral(record.current['TimelineSessions.averagePageviewsCount']).format('0[.]0')}
                            <div className="size-10">{Formatters.growthRate(record.current['TimelineSessions.averagePageviewsCount'], record.previous['TimelineSessions.averagePageviewsCount'])}</div>
                        </div>
                    }
                },
                {
                    title: t('session_duration', "Avg. session"),
                    width: 100,
                    key: 'TimelineSessions.averageSessionDuration',
                    className: 'table-border-right',
                    sorter: (a: any, b: any) => {
                        if (a.id === 'loading') return 0

                        if (a.id === '_all' && sortOrder === 'descend') return 1
                        if (a.id === '_all' && sortOrder === 'ascend') return -1
                        if (b.id === '_all' && sortOrder === 'descend') return -1
                        if (b.id === '_all' && sortOrder === 'ascend') return 1

                        if (a.current['TimelineSessions.averageSessionDuration'] > b.current['TimelineSessions.averageSessionDuration']) return 1
                        if (a.current['TimelineSessions.averageSessionDuration'] < b.current['TimelineSessions.averageSessionDuration']) return -1
                        return 0
                    },
                    defaultSortOrder: 'descend',
                    sortDirections: ['descend', 'ascend'],
                    sortOrder: sortKey === 'TimelineSessions.averageSessionDuration' ? sortOrder : undefined,
                    render: (record: any) => {
                        if (record.name === 'loading') return <Spin size="small" />
                        return <div>
                            {Formatters.duration(record.current['TimelineSessions.averageSessionDuration'])}
                            <div className="size-10">{Formatters.growthRate(record.current['TimelineSessions.averageSessionDuration'], record.previous['TimelineSessions.averageSessionDuration'])}</div>
                        </div>
                    }
                },
            ]
        },
    ]

    // if (props.conversionRule) {
    //     columns.push({
    //         title: <span>{props.conversionRule.name}:&nbsp;
    //             {props.conversionType === 'all' && t('all', "All")}
    //             {props.conversionType === 'acquisition' && t('acquisition', "Acquisition")}
    //             {props.conversionType === 'retention' && t('retention', "Retention")}
    //         </span>,
    //         width: 130 + 130 + 130 + 100 + 100 + 100,
    //         className: 'table-border-bottom',
    //         children: [
    //             {
    //                 title: t('conversions', "Conversions"),
    //                 width: 130,
    //                 key: 'TimelineOrders.count',
    //                 sorter: (a: any, b: any) => {
    //                     if (a.id === 'loading') return 0

    //                     if (a.id === '_all' && sortOrder === 'descend') return 1
    //                     if (a.id === '_all' && sortOrder === 'ascend') return -1
    //                     if (b.id === '_all' && sortOrder === 'descend') return -1
    //                     if (b.id === '_all' && sortOrder === 'ascend') return 1

    //                     if (a.current['TimelineOrders.count'] > b.current['TimelineOrders.count']) return 1
    //                     if (a.current['TimelineOrders.count'] < b.current['TimelineOrders.count']) return -1
    //                     return 0
    //                 },
    //                 defaultSortOrder: 'descend',
    //                 sortDirections: ['descend', 'ascend'],
    //                 sortOrder: sortKey === 'TimelineOrders.count' ? sortOrder : undefined,
    //                 render: (record: any) => {
    //                     if (record.name === 'loading') return <Spin size="small" />
    //                     return <div>
    //                         {Numeral(record.current['TimelineOrders.count']).format('0,0')}
    //                         <div className="size-10">{Formatters.growthRate(record.current['TimelineOrders.count'], record.previous['TimelineOrders.count'])}</div>
    //                     </div>
    //                 }
    //             },
    //             {
    //                 title: t('contributions', "Contributions"),
    //                 width: 130,
    //                 key: 'TimelineSessions.contributions',
    //                 sorter: (a: any, b: any) => {
    //                     if (a.id === 'loading') return 0

    //                     if (a.id === '_all' && sortOrder === 'descend') return 1
    //                     if (a.id === '_all' && sortOrder === 'ascend') return -1
    //                     if (b.id === '_all' && sortOrder === 'descend') return -1
    //                     if (b.id === '_all' && sortOrder === 'ascend') return 1

    //                     if (a.current['TimelineSessions.contributions'] > b.current['TimelineSessions.contributions']) return 1
    //                     if (a.current['TimelineSessions.contributions'] < b.current['TimelineSessions.contributions']) return -1
    //                     return 0
    //                 },
    //                 defaultSortOrder: 'descend',
    //                 sortDirections: ['descend', 'ascend'],
    //                 sortOrder: sortKey === 'TimelineSessions.contributions' ? sortOrder : undefined,
    //                 render: (record: any) => {
    //                     if (record.name === 'loading') return <Spin size="small" />
    //                     return <div>
    //                         {Numeral(record.current['TimelineSessions.contributions']).format('0,0')}
    //                         <div className="size-10">{Formatters.growthRate(record.current['TimelineSessions.contributions'], record.previous['TimelineSessions.contributions'])}</div>
    //                     </div>
    //                 }
    //             },
    //             {
    //                 title: t('linear_attribution', "Linear attribution"),
    //                 width: 130,
    //                 key: 'TimelineSessions.attributionLinearRevenue',
    //                 sorter: (a: any, b: any) => {
    //                     if (a.id === 'loading') return 0

    //                     if (a.id === '_all' && sortOrder === 'descend') return 1
    //                     if (a.id === '_all' && sortOrder === 'ascend') return -1
    //                     if (b.id === '_all' && sortOrder === 'descend') return -1
    //                     if (b.id === '_all' && sortOrder === 'ascend') return 1

    //                     if (a.current['TimelineSessions.attributionLinearRevenue'] > b.current['TimelineSessions.attributionLinearRevenue']) return 1
    //                     if (a.current['TimelineSessions.attributionLinearRevenue'] < b.current['TimelineSessions.attributionLinearRevenue']) return -1
    //                     return 0
    //                 },
    //                 defaultSortOrder: 'descend',
    //                 sortDirections: ['descend', 'ascend'],
    //                 sortOrder: sortKey === 'TimelineSessions.attributionLinearRevenue' ? sortOrder : undefined,
    //                 render: (record: any) => {
    //                     if (record.name === 'loading') return <Spin size="small" />
    //                     return <div>
    //                         {Formatters.currency(projectCtx.currentProject.currency, record.current['TimelineSessions.attributionLinearRevenue'], undefined, undefined, undefined, undefined, true)}
    //                         <div className="size-10">{Formatters.growthRate(record.current['TimelineSessions.attributionLinearRevenue'], record.previous['TimelineSessions.attributionLinearRevenue'])}</div>
    //                     </div>
    //                 }
    //             },
    //             {
    //                 title: t('impacted_revenue', "Impacted revenue"),
    //                 width: 100,
    //                 key: 'TimelineOrders.revenue',
    //                 sorter: (a: any, b: any) => {
    //                     if (a.id === 'loading') return 0

    //                     if (a.id === '_all' && sortOrder === 'descend') return 1
    //                     if (a.id === '_all' && sortOrder === 'ascend') return -1
    //                     if (b.id === '_all' && sortOrder === 'descend') return -1
    //                     if (b.id === '_all' && sortOrder === 'ascend') return 1

    //                     if (a.current['TimelineOrders.revenue'] > b.current['TimelineOrders.revenue']) return 1
    //                     if (a.current['TimelineOrders.revenue'] < b.current['TimelineOrders.revenue']) return -1
    //                     return 0
    //                 },
    //                 defaultSortOrder: 'descend',
    //                 sortDirections: ['descend', 'ascend'],
    //                 sortOrder: sortKey === 'TimelineOrders.revenue' ? sortOrder : undefined,
    //                 render: (record: any) => {
    //                     if (record.name === 'loading') return <Spin size="small" />
    //                     return <div>
    //                         {Formatters.currency(projectCtx.currentProject.currency, record.current['TimelineOrders.revenue'], undefined, undefined, undefined, undefined, true)}
    //                         <div className="size-10">{Formatters.growthRate(record.current['TimelineOrders.revenue'], record.previous['TimelineOrders.revenue'])}</div>
    //                     </div>
    //                 }
    //             },
    //             {
    //                 title: t('avg_cart', "Avg. cart"),
    //                 width: 100,
    //                 key: 'TimelineOrders.averageCart',
    //                 sorter: (a: any, b: any) => {
    //                     if (a.id === 'loading') return 0

    //                     if (a.id === '_all' && sortOrder === 'descend') return 1
    //                     if (a.id === '_all' && sortOrder === 'ascend') return -1
    //                     if (b.id === '_all' && sortOrder === 'descend') return -1
    //                     if (b.id === '_all' && sortOrder === 'ascend') return 1

    //                     if (a.current['TimelineOrders.averageCart'] > b.current['TimelineOrders.averageCart']) return 1
    //                     if (a.current['TimelineOrders.averageCart'] < b.current['TimelineOrders.averageCart']) return -1
    //                     return 0
    //                 },
    //                 defaultSortOrder: 'descend',
    //                 sortDirections: ['descend', 'ascend'],
    //                 sortOrder: sortKey === 'TimelineOrders.averageCart' ? sortOrder : undefined,
    //                 render: (record: any) => {
    //                     if (record.name === 'loading') return <Spin size="small" />
    //                     return <div>
    //                         {Formatters.currency(projectCtx.currentProject.currency, Math.trunc(record.current['TimelineOrders.averageCart'] / 100) * 100, undefined, undefined, undefined, undefined, true)}
    //                         <div className="size-10">{Formatters.growthRate(record.current['TimelineOrders.averageCart'], record.previous['TimelineOrders.averageCart'])}</div>
    //                     </div>
    //                 }
    //             },
    //             {
    //                 title: t('role', "Role"),
    //                 width: 100,
    //                 key: 'role',
    //                 render: (record: any) => {
    //                     if (record.name === '_all') {
    //                         return
    //                     }

    //                     if (record.name === 'loading') return <Spin size="small" />

    //                     const content = <table style={{ width: '280px' }}>
    //                         <tbody>
    //                             <tr>
    //                                 <td className="padding-b-s">{t('role_alone', "Alone")}</td>
    //                                 <td className="padding-b-s">
    //                                     <span style={{ width: record.current['TimelineSessions.aloneRatio'] + 'px', display: 'inline-block', backgroundColor: '#607D8B', height: '5px' }}></span> {Numeral(record.current['TimelineSessions.aloneRatio']).format('0.[0]')}%
    //                                     <div className="size-10">{Formatters.growthRate(record.current['TimelineSessions.aloneRatio'], record.previous['TimelineSessions.aloneRatio'])}</div>
    //                                 </td>
    //                             </tr>
    //                             <tr>
    //                                 <td className="padding-b-s">{t('role_init', "Initiator")}</td>
    //                                 <td className="padding-b-s">
    //                                     <span style={{ width: record.current['TimelineSessions.initiatorRatio'] + 'px', display: 'inline-block', backgroundColor: '#00BCD4', height: '5px' }}></span> {Numeral(record.current['TimelineSessions.initiatorRatio']).format('0.[0]')}%
    //                                     <div className="size-10">{Formatters.growthRate(record.current['TimelineSessions.initiatorRatio'], record.previous['TimelineSessions.initiatorRatio'])}</div>
    //                                 </td>
    //                             </tr>
    //                             <tr>
    //                                 <td className="padding-b-s">{t('role_assist', "Assisting")}</td>
    //                                 <td className="padding-b-s">
    //                                     <span style={{ width: record.current['TimelineSessions.assistingRatio'] + 'px', display: 'inline-block', backgroundColor: '#CDDC39', height: '5px' }}></span> {Numeral(record.current['TimelineSessions.assistingRatio']).format('0.[0]')}%
    //                                     <div className="size-10">{Formatters.growthRate(record.current['TimelineSessions.assistingRatio'], record.previous['TimelineSessions.assistingRatio'])}</div>
    //                                 </td>
    //                             </tr>
    //                             <tr>
    //                                 <td className="padding-b-s">{t('role_closer', "Closer")}</td>
    //                                 <td className="padding-b-s">
    //                                     <span style={{ width: record.current['TimelineSessions.closerRatio'] + 'px', display: 'inline-block', backgroundColor: '#F06292', height: '5px' }}></span> {Numeral(record.current['TimelineSessions.closerRatio']).format('0.[0]')}%
    //                                     <div className="size-10">{Formatters.growthRate(record.current['TimelineSessions.closerRatio'], record.previous['TimelineSessions.closerRatio'])}</div>
    //                                 </td>
    //                             </tr>
    //                         </tbody>
    //                     </table>

    //                     return <div>
    //                         <Popover content={content} title={null} trigger={['hover', 'onClick']} placement="left" className="padding-v-m">
    //                             <div style={{ cursor: 'help', width: '65px' }}>
    //                                 <div style={{ marginBottom: '2px', width: (record.current['TimelineSessions.aloneRatio'] || 0) + '%', backgroundColor: '#607D8B', height: '3px' }}></div>
    //                                 <div style={{ marginBottom: '2px', width: (record.current['TimelineSessions.initiatorRatio'] || 0) + '%', backgroundColor: '#00BCD4', height: '3px' }}></div>
    //                                 <div style={{ marginBottom: '2px', width: (record.current['TimelineSessions.assistingRatio'] || 0) + '%', backgroundColor: '#CDDC39', height: '3px' }}></div>
    //                                 <div style={{ marginBottom: '2px', width: (record.current['TimelineSessions.closerRatio'] || 0) + '%', backgroundColor: '#F06292', height: '3px' }}></div>
    //                             </div>
    //                         </Popover>
    //                     </div>
    //                 }
    //             },
    //         ]
    //     })
    // }


    if (analyticsContext.conversionRule) {
        const attributionColumns: any = []

        if (analyticsContext.attribution === 'linear') {
            attributionColumns.push({
                title: 'Linear conversions',
                width: 130,
                key: 'TimelineSessions.attributionLinearConversions',
                sorter: (a: any, b: any) => {
                    if (a.id === 'loading') return 0

                    if (a.id === '_all' && sortOrder === 'descend') return 1
                    if (a.id === '_all' && sortOrder === 'ascend') return -1
                    if (b.id === '_all' && sortOrder === 'descend') return -1
                    if (b.id === '_all' && sortOrder === 'ascend') return 1

                    if (a.current['TimelineSessions.attributionLinearConversions'] > b.current['TimelineSessions.attributionLinearConversions']) return 1
                    if (a.current['TimelineSessions.attributionLinearConversions'] < b.current['TimelineSessions.attributionLinearConversions']) return -1
                    return 0
                },
                defaultSortOrder: 'descend',
                sortDirections: ['descend', 'ascend'],
                sortOrder: sortKey === 'TimelineSessions.attributionLinearConversions' ? sortOrder : undefined,
                render: (record: any) => {
                    if (record.name === '_all') return ''
                    if (record.name === 'loading') return <Spin size="small" />
                    return <div>
                        {Numeral(record.current['TimelineSessions.attributionLinearConversions']).format('0,0[.]0')}
                        <div className="size-10">{Formatters.growthRate(record.current['TimelineSessions.attributionLinearConversions'], record.previous['TimelineOrders.count'])}</div>
                    </div>
                }
            }, {
                title: "Linear revenue",
                width: 130,
                key: 'TimelineSessions.attributionLinearRevenue',
                sorter: (a: any, b: any) => {
                    if (a.id === 'loading') return 0

                    if (a.id === '_all' && sortOrder === 'descend') return 1
                    if (a.id === '_all' && sortOrder === 'ascend') return -1
                    if (b.id === '_all' && sortOrder === 'descend') return -1
                    if (b.id === '_all' && sortOrder === 'ascend') return 1

                    if (a.current['TimelineSessions.attributionLinearRevenue'] > b.current['TimelineSessions.attributionLinearRevenue']) return 1
                    if (a.current['TimelineSessions.attributionLinearRevenue'] < b.current['TimelineSessions.attributionLinearRevenue']) return -1
                    return 0
                },
                defaultSortOrder: 'descend',
                sortDirections: ['descend', 'ascend'],
                sortOrder: sortKey === 'TimelineSessions.attributionLinearRevenue' ? sortOrder : undefined,
                render: (record: any) => {
                    if (record.name === '_all') return ''
                    if (record.name === 'loading') return <Spin size="small" />
                    return <div>
                        {Formatters.currency(projectCtx.currentProject.currency, record.current['TimelineSessions.attributionLinearRevenue'], undefined, undefined, undefined, undefined, true)}
                        <div className="size-10">{Formatters.growthRate(record.current['TimelineSessions.attributionLinearRevenue'], record.previous['TimelineSessions.attributionLinearRevenue'])}</div>
                    </div>
                }
            })
        }

        if (analyticsContext.attribution === 'contributive') {
            attributionColumns.push(
                {
                    title: 'Impacted conversions',
                    width: 130,
                    key: 'TimelineOrders.count',
                    sorter: (a: any, b: any) => {
                        if (a.id === 'loading') return 0

                        if (a.id === '_all' && sortOrder === 'descend') return 1
                        if (a.id === '_all' && sortOrder === 'ascend') return -1
                        if (b.id === '_all' && sortOrder === 'descend') return -1
                        if (b.id === '_all' && sortOrder === 'ascend') return 1

                        if (a.current['TimelineOrders.count'] > b.current['TimelineOrders.count']) return 1
                        if (a.current['TimelineOrders.count'] < b.current['TimelineOrders.count']) return -1
                        return 0
                    },
                    defaultSortOrder: 'descend',
                    sortDirections: ['descend', 'ascend'],
                    sortOrder: sortKey === 'TimelineOrders.count' ? sortOrder : undefined,
                    render: (record: any) => {
                        if (record.name === '_all') return
                        if (record.name === 'loading') return <Spin size="small" />
                        return <div>
                            {Numeral(record.current['TimelineOrders.count']).format('0,0')}
                            <div className="size-10">{Formatters.growthRate(record.current['TimelineOrders.count'], record.previous['TimelineOrders.count'])}</div>
                        </div>
                    }
                },
                // {
                //   title: t('contributions', "Contributions"),
                //   width: 130,
                //   key: 'TimelineSessions.contributions',
                //   sorter: (a: any, b: any) => {
                //     if (a.id === 'loading') return 0

                //     if (a.id === '_all' && sortOrder === 'descend') return 1
                //     if (a.id === '_all' && sortOrder === 'ascend') return -1
                //     if (b.id === '_all' && sortOrder === 'descend') return -1
                //     if (b.id === '_all' && sortOrder === 'ascend') return 1

                //     if (a.current['TimelineSessions.contributions'] > b.current['TimelineSessions.contributions']) return 1
                //     if (a.current['TimelineSessions.contributions'] < b.current['TimelineSessions.contributions']) return -1
                //     return 0
                //   },
                //   defaultSortOrder: 'descend',
                //   sortDirections: ['descend', 'ascend'],
                //   sortOrder: sortKey === 'TimelineSessions.contributions' ? sortOrder : undefined,
                //   render: (record: any) => {
                //     if (record.name === 'loading') return <Spin size="small" />
                //     return <div>
                //       {Numeral(record.current['TimelineSessions.contributions']).format('0,0')}
                //       <div className="size-10">{Formatters.growthRate(record.current['TimelineSessions.contributions'], record.previous['TimelineSessions.contributions'])}</div>
                //     </div>
                //   }
                // },
                {
                    title: t('impacted_revenue', "Impacted revenue"),
                    width: 100,
                    key: 'TimelineOrders.revenue',
                    sorter: (a: any, b: any) => {
                        if (a.id === 'loading') return 0

                        if (a.id === '_all' && sortOrder === 'descend') return 1
                        if (a.id === '_all' && sortOrder === 'ascend') return -1
                        if (b.id === '_all' && sortOrder === 'descend') return -1
                        if (b.id === '_all' && sortOrder === 'ascend') return 1

                        if (a.current['TimelineOrders.revenue'] > b.current['TimelineOrders.revenue']) return 1
                        if (a.current['TimelineOrders.revenue'] < b.current['TimelineOrders.revenue']) return -1
                        return 0
                    },
                    defaultSortOrder: 'descend',
                    sortDirections: ['descend', 'ascend'],
                    sortOrder: sortKey === 'TimelineOrders.revenue' ? sortOrder : undefined,
                    render: (record: any) => {
                        if (record.name === '_all') return ''
                        if (record.name === 'loading') return <Spin size="small" />
                        return <div>
                            {Formatters.currency(projectCtx.currentProject.currency, record.current['TimelineOrders.revenue'], undefined, undefined, undefined, undefined, true)}
                            <div className="size-10">{Formatters.growthRate(record.current['TimelineOrders.revenue'], record.previous['TimelineOrders.revenue'])}</div>
                        </div>
                    }
                })
        }

        columns.push({
            title: <span>{analyticsContext.conversionRule.name}:&nbsp;
                {analyticsContext.conversionType === 'all' && t('all', "All")}
                {analyticsContext.conversionType === 'acquisition' && t('acquisition', "Acquisition")}
                {analyticsContext.conversionType === 'retention' && t('retention', "Retention")}
            </span>,
            width: 130 + 130 + 130 + 100 + 100 + 100,
            className: 'table-border-bottom',
            children: [
                ...attributionColumns,
                {
                    title: t('avg_cart', "Avg. cart"),
                    width: 100,
                    key: 'TimelineOrders.averageCart',
                    sorter: (a: any, b: any) => {
                        if (a.id === 'loading') return 0

                        if (a.id === '_all' && sortOrder === 'descend') return 1
                        if (a.id === '_all' && sortOrder === 'ascend') return -1
                        if (b.id === '_all' && sortOrder === 'descend') return -1
                        if (b.id === '_all' && sortOrder === 'ascend') return 1

                        if (a.current['TimelineOrders.averageCart'] > b.current['TimelineOrders.averageCart']) return 1
                        if (a.current['TimelineOrders.averageCart'] < b.current['TimelineOrders.averageCart']) return -1
                        return 0
                    },
                    defaultSortOrder: 'descend',
                    sortDirections: ['descend', 'ascend'],
                    sortOrder: sortKey === 'TimelineOrders.averageCart' ? sortOrder : undefined,
                    render: (record: any) => {
                        if (record.name === 'loading') return <Spin size="small" />
                        return <div>
                            {Formatters.currency(projectCtx.currentProject.currency, Math.trunc(record.current['TimelineOrders.averageCart'] / 100) * 100, undefined, undefined, undefined, undefined, true)}
                            <div className="size-10">{Formatters.growthRate(record.current['TimelineOrders.averageCart'], record.previous['TimelineOrders.averageCart'])}</div>
                        </div>
                    }
                },
                {
                    title: t('role', "Role"),
                    width: 100,
                    key: 'role',
                    render: (record: any) => {
                        if (record.name === '_all') return ''
                        if (record.name === 'loading') return <Spin size="small" />

                        return <Role record={record} table="TimelineSessions" currency={projectCtx.currentProject.currency} />
                    }
                },
            ]
        })
    }

    let totalX = 0

    columns.forEach((col: any) => {
        if (col.width) totalX += col.width
        // if (col.children) {
        //   col.children.forEach((ch: any) => {
        //     if (ch.width) totalX += ch.width
        //   })
        // }
    })

    return <div>
        {loadingCSV && <Modal
            title={null}
            centered
            closable={false}
            visible={true}
            footer={null}
            maskClosable={false}
            width={230}
            transitionName=""
            maskTransitionName=""
        >
            <div className="text-center padding-a-l"><Spin size="large" tip="Preparing CSV..." /></div>
        </Modal>}
        <Table
            scroll={{ x: totalX, y: 700 }}
            dataSource={tableData}
            rowKey="id"
            size="middle"
            onChange={handleTableChange}
            loading={loading}
            pagination={false}
            className="block margin-t-l"
            expandable={{
                expandedRowKeys: expandedRowKeys,
                expandRowByClick: true,
                onExpand: onExpand,
                rowExpandable: record => record.expandable,
                indentSize: 10,
            }}
            rowClassName={record => cn({ 'table-all-total': record.name === '_all' })}
            columns={columns}
        />
    </div>
}
export default Report
