import React, { Component } from 'react'

import t from '../../../utils/translate'
import { Table, Popover } from 'antd'
import Formatters from '../../../utils/formatters'
import N from 'numeral'
import removeLeadingDates from 'utils/removeLeadingDates'

class PartnershipsTable extends Component<any, any> {
    _isMounted = false;

    constructor(props: any) {
        super(props)

        this.state = {
            loading: true,
            current: [],
            previous: [],
        }

        this.fetch = this.fetch.bind(this)
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetch()
    }

    componentDidUpdate(prevProps: any) {
        // check url params have changed
        if (prevProps.refreshAt !== this.props.refreshAt) {
            window.setTimeout(() => this.fetch())
        } else if (prevProps.partnershipId !== this.props.partnershipId) {
            window.setTimeout(() => this.fetch())
        } else if (prevProps.filters !== this.props.filters) {
            window.setTimeout(() => this.fetch())
        }
    }

    fetch() {

        if (!this.state.loading) {
            if (this._isMounted) {
                this.setState({ loading: true })
            }
        }

        const opts: any = {
            measures: [
                'TimelineSessions.count',
                'TimelineSessions.contributions',
                'TimelineSessions.conversionRate',
                'TimelineSessions.attributionLinearRevenue',
                'TimelineOrders.averageCart',
                'TimelineSessions.cpc',
                'TimelineSessions.cpa',
                'TimelineSessions.averageCommissionRate',
            ],
            dimensions: [
                'TimelineSessions.sourceMedium',
                'TimelineSessions.channelId',
            ],
            timeDimensions: [
                {
                    "dimension": 'TimelineSessions.truncCreatedAt',
                    "granularity": null,
                    "compareDateRange": [
                        [this.props.dateFrom, this.props.dateTo],
                        [this.props.dateFromPrevious, this.props.dateToPrevious]
                    ]
                }
            ],
            timezone: this.props.timezone
        }

        if (this.props.filters) {
            opts.filters = this.props.filters
        }

        const tasks: any = []

        this.props.kpiPartnerships.forEach((p: any) => {
            tasks.push(p.cubejs.load(opts))
        })

        if (tasks.length === 0) {
            if (this._isMounted) {
                this.setState({
                    loading: false,
                    current: [],
                    previous: [],
                })
            }
            return
        }

        Promise.all(tasks).then((values: any) => {
            const current: any = []
            const previous: any = []

            values.forEach((task: any, i: number) => {
                const [currentData] = task.decompose()
                const { advertiserCurrency, currencyRate, advertiserProjectName, partnershipId } = this.props.kpiPartnerships[i]

                const currentLines = currentData.tablePivot()

                removeLeadingDates(currentLines)
                
                currentLines.forEach((line: any) => {
                    line.advertiserProjectName = advertiserProjectName
                    line.advertiserCurrency = advertiserCurrency
                    line.currencyRate = currencyRate
                    line.partnershipId = partnershipId
                    current.push(line)
                })

                // TODO + trends in table
                // previousData.tablePivot().forEach((line: any) => {
                //     previous.push(line)
                // })
            })

            if (this._isMounted) {
                this.setState({
                    loading: false,
                    current,
                    previous,
                })
            }
        })
            .catch((error: any) => {

                console.log(error)

                let message = error.message

                if (error.response && error.response.status === 400) {
                    switch (error.response.data.message) {
                        default:
                            message = error.response.data.message
                    }
                }

                message.error(message)
                if (this._isMounted) {
                    this.setState({ loading: false })
                }
            })
    }

    render() {

        // console.log('state kpi', this.state);

        return <div className="padding-h-m padding-v-l dash-table-stats">
            <Table
                dataSource={this.state.current}
                loading={this.state.loading}
                rowKey={record => record["TimelineSessions.sourceMedium"] + record.partnershipId + record["TimelineSessions.channelId"]}
                columns={[
                    {
                        title: 'Partnership',
                        key: 'partnershipId',
                        render: (record: any) => {
                            //   const domain = this.props.project.domains.find((x: any) => x.id === record['TimelineSessions.domainId'])
                            //   if (domain) return domain.name
                            return record.advertiserProjectName + ' - ' + record.partnershipId
                        }
                    },
                    {
                        title: 'Channel',
                        key: 'channelId',
                        render: (record: any) => {
                            //   const domain = this.props.project.domains.find((x: any) => x.id === record['TimelineSessions.domainId'])
                            //   if (domain) return domain.name
                            return record['TimelineSessions.channelId']
                        }
                    },
                    {
                        title: 'Source / Medium',
                        key: 'sourceMedium',
                        render: (record: any) => {
                            //   const domain = this.props.project.domains.find((x: any) => x.id === record['TimelineSessions.domainId'])
                            //   if (domain) return domain.name
                            return record['TimelineSessions.sourceMedium']
                        }
                    },
                    {
                        title: 'Sessions',
                        key: 'sessions',
                        sorter: (a: any, b: any) => {
                            if (a['TimelineSessions.count'] < b['TimelineSessions.count']) { return -1 }
                            if (a['TimelineSessions.count'] > b['TimelineSessions.count']) { return 1 }
                            return 0
                        },
                        sortOrder: 'descend',
                        showSorterTooltip: false,
                        render: (record: any) => <span className="value">{N(record['TimelineSessions.count']).format('0,0')}</span>
                    },
                    {
                        title: t('conversion_rate', 'Conversion rate'),
                        key: 'conversionRate',
                        sorter: (a: any, b: any) => {
                            if (a['TimelineSessions.conversionRate'] < b['TimelineSessions.conversionRate']) { return -1 }
                            if (a['TimelineSessions.conversionRate'] > b['TimelineSessions.conversionRate']) { return 1 }
                            return 0
                        },
                        sortOrder: 'descend',
                        showSorterTooltip: false,
                        render: (record: any) => <span className="value">{N(record['TimelineSessions.conversionRate']).format('0,0.0')}%</span>
                    },
                    {
                        title: 'Contributions',
                        key: 'contributions',
                        sorter: (a: any, b: any) => {
                            if (a['TimelineSessions.contributions'] < b['TimelineSessions.contributions']) { return -1 }
                            if (a['TimelineSessions.contributions'] > b['TimelineSessions.contributions']) { return 1 }
                            return 0
                        },
                        sortOrder: 'descend',
                        showSorterTooltip: false,
                        render: (record: any) => <span className="value">{N(record['TimelineSessions.contributions']).format('0,0')}</span>
                    },
                    {
                        title: t('revenue_linear', "Revenue - linear attrib."),
                        key: 'revenue',
                        render: (record: any) => {
                            if (record.currencyRate !== 1 && record['TimelineSessions.attributionLinearRevenue'] > 0) {
                                const convertedValue = (record.currencyRate * record['TimelineSessions.attributionLinearRevenue']) / 100
                                return <Popover content={<div>{Formatters.currency(record.advertiserCurrency, record['TimelineSessions.attributionLinearRevenue'])}</div>}>
                                    {Formatters.currency(this.props.organization.currency, convertedValue)}
                                </Popover>
                            }
                            else return Formatters.currency(this.props.organization.currency, record['TimelineSessions.attributionLinearRevenue'])
                        }
                    },
                    {
                        title: t('avg_cart', "Avg. cart"),
                        key: 'averageCart',
                        render: (record: any) => {
                            if (record.currencyRate !== 1 && record['TimelineOrders.averageCart'] > 0) {
                                const convertedValue = (record.currencyRate * record['TimelineOrders.averageCart']) / 100
                                return <Popover content={<div>{Formatters.currency(record.advertiserCurrency, record['TimelineOrders.averageCart'])}</div>}>
                                    {Formatters.currency(this.props.organization.currency, convertedValue)}
                                </Popover>
                            }
                            else return Formatters.currency(this.props.organization.currency, record['TimelineOrders.averageCart'])
                        }
                    },
                    {
                        title: 'CPC',
                        key: 'cpc',
                        render: (record: any) => {
                            if (record.currencyRate !== 1 && record['TimelineSessions.cpc'] > 0) {
                                const convertedValue = (record.currencyRate * record['TimelineSessions.cpc']) / 100
                                return <Popover content={<div>{Formatters.currency(record.advertiserCurrency, record['TimelineSessions.cpc'])}</div>}>
                                    {Formatters.currency(this.props.organization.currency, convertedValue)}
                                </Popover>
                            }
                            else return Formatters.currency(this.props.organization.currency, record['TimelineSessions.cpc'])
                        }
                    },
                    {
                        title: 'CPA / CPL',
                        key: 'cpa',
                        render: (record: any) => {
                            if (record.currencyRate !== 1 && record['TimelineSessions.cpa'] > 0) {
                                const convertedValue = (record.currencyRate * record['TimelineSessions.cpa']) / 100
                                return <Popover content={<div>{Formatters.currency(record.advertiserCurrency, record['TimelineSessions.cpa'])}</div>}>
                                    {Formatters.currency(this.props.organization.currency, convertedValue)}
                                </Popover>
                            }
                            else return Formatters.currency(this.props.organization.currency, record['TimelineSessions.cpa'])
                        }
                    },
                    {
                        title: t('avg_commission_rate', "Avg. commission rate"),
                        key: 'commissionRate',
                        render: (record: any) => N(record['TimelineSessions.averageCommissionRate']).format('0.0') + '%'
                    },
                ]}
                pagination={false}
                size="middle"
            />
        </div>
    }
}

export default PartnershipsTable