import AppLayout from 'components/app/layout'
import React, { Component } from 'react'

import t from '../../utils/translate'
import DomainsAbout from './_about'
import DomainsTable from './_table'

class Domains extends Component<any, any> {

    componentDidMount() {
        if (window.cmAgent) {
            window.cmAgent.pageview({
                title: 'Domains (project: ' + this.props.projectLayout.project.id + ')',
                page: this.props.location.pathname,
                props: {
                    orgId: this.props.projectLayout.project.organizationId,
                    projectId: this.props.projectLayout.project.id
                }
            })
            window.cmAgent.dispatch()
        }
    }

    render() {
        return <AppLayout currentProject={this.props.projectLayout.project} currentOrganization={this.props.organizationLayout.organization} admin={this.props.app.state.admin} firebaseUser={this.props.app.state.firebaseUser} projects={this.props.organizationLayout.projects}>
            <h1>{t('domains', "Domains")}</h1>
            <DomainsAbout />
            <div className="padding-v-m"><DomainsTable {...this.props} /></div>
        </AppLayout>
    }
}

export default Domains