
import React, { Component } from 'react'

import t from '../../utils/translate'
import _ from 'lodash';
import { TagOutlined } from '@ant-design/icons';
import { Table, Popover, Tag, Tooltip } from 'antd';
import Moment from 'moment-timezone';
import GoogleMap from '../../utils/gmap'
import Formatters from '../../utils/formatters'
import Config from '../../config'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Numeral from 'numeral'
import OrderItemsTable from './_itemsTable'

class ShowOrderDetails extends Component<any, any> {

  render() {
    let client = _.find(this.props.user?.clients, c => c.id === this.props.order.clientId)
    // console.log(this.props);
    return (
      <React.Fragment>
        {this.props.order.items.length > 0 && <div className="block margin-v-m">
          <h2 className="mini">{t('cart', "Cart")}</h2>
          <OrderItemsTable currency={this.props.order.currency} projectLayout={this.props.projectLayout} items={this.props.order.items} />
        </div>}

        <div className="block">
          <h2 className="mini">{t('order_details', 'Order details')}</h2>
          <table className="table-attributes">
            <tbody>


              <tr>
                <td style={{ width: '50%' }}>
                  <span className="subsider-attribute-key">{t('external_id', "External ID")}</span>
                  <span className="subsider-attribute-value">{this.props.order.conversionExternalId}</span>
                </td>
                <td>
                  <span className="subsider-attribute-key">{t('domain', "Domain")}</span>
                  <span className="subsider-attribute-value">{this.props.order.domainId}</span>
                </td>
              </tr>
              <tr>
                <td style={{ width: '50%' }}>
                  <span className="subsider-attribute-key">{t('date', "Date")}</span>
                  <span className="subsider-attribute-value"><Tooltip title={Moment(this.props.order.createdAt).tz(this.props.app.state.admin.timezone).format('lll') + ' (' + this.props.app.state.admin.timezone + ')'}>{Moment(this.props.order.createdAt).fromNow()}</Tooltip></span>
                </td>
                <td>
                  <span className="subsider-attribute-key">{t('time_to_conversion', "Time to conversion")}</span>
                  <span className="subsider-attribute-value">{this.props.order.timeToConversion > 0 ? Formatters.duration(this.props.order.timeToConversion) : t('unknown', "unknown")}</span>
                </td>
              </tr>
              <tr>
                {client && <td>
                  <span className="subsider-attribute-key">{t('device', "Device")}</span>
                  <span className="subsider-attribute-value">
                    <Popover content={<div>
                      <div className="subsider-attribute">
                        <span className="subsider-attribute-key">OS</span>
                        <span className="subsider-attribute-value">{client.platform + ' - ' + client.os}</span>
                      </div>
                      <div className="subsider-attribute">
                        <span className="subsider-attribute-key">{t('resolution', "Resolution")}</span>
                        <span className="subsider-attribute-value">{client.resolution}</span>
                      </div>
                      <div className="subsider-attribute">
                        <span className="subsider-attribute-key">{t('ad_block', "Ad block")}</span>
                        <span className="subsider-attribute-value">{client.adBlock ? t('yes', "yes") : t('no', "no")}</span>
                      </div>
                      <div className="subsider-attribute">
                        <span className="subsider-attribute-key">{t('languages', "Languages")}</span>
                        <span className="subsider-attribute-value">{client.languages.replace(/~/g, ', ')}</span>
                      </div>
                      <div className="subsider-attribute">
                        <span className="subsider-attribute-key">{t('time_zone', "Time zone")}</span>
                        <span className="subsider-attribute-value">{client.timezone + ' (' + (client.timezoneOffset > 0 ? '-' + (client.timezoneOffset / 60) : '+' + (Math.abs(client.timezoneOffset) / 60)) + 'h)'}</span>
                      </div>
                    </div>}>{Formatters.deviceIcon(client.device)}&nbsp;&nbsp; {client.browser + ' ' + (client.browserVersionMajor ? client.browserVersionMajor : '') + (client.browserVersionMinor ? '.' + client.browserVersionMinor + '.' : '') + (client.browserVersionPatch ? '.' + client.browserVersionPatch : '')}</Popover>
                  </span>
                </td>}
                <td>
                  <span className="subsider-attribute-key">IP</span>
                  <span className="subsider-attribute-value">{this.props.order.ip}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="subsider-attribute-key">{t('financial_status', "Financial status")}</span>
                  <span className="subsider-attribute-value">{this.props.order.financialStatus}</span>
                </td>
                <td>
                  <span className="subsider-attribute-key">{t('fulfillment_status', "Fulfillment status")}</span>
                  <span className="subsider-attribute-value">{this.props.order.fulfillmentStatus}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="subsider-attribute-key">{t('closed', "Closed")}</span>
                  <span className="subsider-attribute-value">{this.props.order.closedAt ? <Tooltip title={Moment(this.props.order.closedAt).tz(this.props.app.state.admin.timezone).format('lll') + ' (' + this.props.app.state.admin.timezone + ')'}>{Moment(this.props.order.closedAt).fromNow()}</Tooltip> : '-'}</span>
                </td>
                <td>
                  <div>
                    <span className="subsider-attribute-key">{t('cancelled', "Cancelled")}</span>
                    <span className="subsider-attribute-value">{this.props.order.cancelledAt ? <Tooltip title={Moment(this.props.order.cancelledAt).tz(this.props.app.state.admin.timezone).format('lll') + ' (' + this.props.app.state.admin.timezone + ')'}>{Moment(this.props.order.cancelledAt).fromNow()}</Tooltip> : '-'}</span>
                  </div>
                  {this.props.order.cancelledAt && this.props.order.cancelReason}
                </td>
              </tr>
              <tr>
                <td>
                  <span className="subsider-attribute-key">{t('shipping', "Shipping")}</span>
                  <span className="subsider-attribute-value">{this.props.order.totalShipping ? Formatters.currency(this.props.projectLayout.project.currency, this.props.order.totalShipping, this.props.order.totalShippingSource, this.props.order.currency) : '-'}</span>
                </td>
                <td>
                  <span className="subsider-attribute-key">{t('tax', "Tax")}</span>
                  <span className="subsider-attribute-value">{this.props.order.totalTax ? Formatters.currency(this.props.projectLayout.project.currency, this.props.order.totalTax, this.props.order.totalTaxSource, this.props.order.currency) : '-'}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="subsider-attribute-key">{t('tip', "Tips")}</span>
                  <span className="subsider-attribute-value">{this.props.order.totalTips ? Formatters.currency(this.props.projectLayout.project.currency, this.props.order.totalTips, this.props.order.totalTipsSource, this.props.order.currency) : '-'}</span>
                </td>
                <td>
                  <span className="subsider-attribute-key">{t('tags', "Tags")}</span>
                  <span className="subsider-attribute-value">{this.props.order.tags && this.props.order.tags.length > 0 && this.props.order.tags.map((t: any) => <Tag className="no-pointer" key={t}>{t}</Tag>)}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <span className="subsider-attribute-key">{t('discounts', "Discounts")}</span>
                    <span className="subsider-attribute-value">
                      {this.props.order.totalDiscounts ? Formatters.currency(this.props.projectLayout.project.currency, this.props.order.totalDiscounts, this.props.order.totalDiscountsSource, this.props.order.currency) : '-'}
                    </span>
                  </div>
                  {this.props.order.discounts.length > 0 && <Table className="margin-v-m" pagination={false} showHeader={false} rowKey={(record) => record.kind + record.valueType + record.code + record.targetType} dataSource={this.props.order.discounts || []} size="small" columns={[
                    {
                      key: 'title',
                      className: 'size-12',
                      render: (record) => <span>
                        {record.code && <Tag className="no-pointer"><TagOutlined /> {record.code}</Tag>}
                        {record.title && record.title}
                        {record.kind !== 'discountCode' && '(' + t('discount_' + record.kind, record.kind) + ') '}
                        {t('discount_' + record.allocationMethod, record.allocationMethod) + ' ' + t('discount_' + record.targetSelection, record.targetSelection) + ' ' + t('discount_' + record.targetType, record.targetType)}
                      </span>
                    },
                    {
                      key: 'value',
                      className: 'size-12',
                      render: (record) => record.valueType === 'fixedAmount' ? Formatters.currency(this.props.order.currency || this.props.projectLayout.project.currency, record.value) : Numeral(record.value).format('0.00') + '%'
                    },
                  ]} />}
                </td>
                <td>
                  <span className="subsider-attribute-key padding-b-s" style={{ float: 'inherit' }}>{t('properties', "Properties")}</span>
                  <span className="subsider-attribute-value"><pre>{this.props.order.properties ? JSON.stringify(this.props.order.properties, null, 2) : '-'}</pre></span>
                </td>
              </tr>
            </tbody>
          </table>
          {_.get(this.props.order, 'location.coordinates[0]') &&
            <GoogleMap
              googleMapURL={"https://maps.googleapis.com/maps/api/js?key=" + Config().gmap_key}
              loadingElement={<div className="loadingElement" style={{ height: '150px' }} />}
              containerElement={<div className="containerElement" style={{ height: '150px' }} />}
              mapElement={<div className="mapElement" style={{ height: '100%' }} />}
              latitude={this.props.order.location.coordinates[1]}
              longitude={this.props.order.location.coordinates[0]}
            />
          }
        </div>
      </React.Fragment>
    );
  }
}

export default ShowOrderDetails