import { useState, useEffect, useRef, useCallback } from 'react'

import {
    Table,
    message,
    Spin,
    Tooltip,
    Button,
    Modal,
    Input,
    Space,
} from 'antd';
import { DownloadOutlined, SearchOutlined, } from '@ant-design/icons'
import t from '../../../utils/translate'
import Formatters from '../../../utils/formatters'
import Numeral from 'numeral'
import QS from 'qs'
import _ from 'lodash'
import cn from 'classnames'
import { ResultSet, Query, SqlQuery } from '@cubejs-client/core'
import removeLeadingDates from 'utils/removeLeadingDates'
import { useAnalyticsContext, AnalyticsContextValue } from './index'
import { arrayToCSV, downloadCSV } from 'utils/csv'
import { useProjectContext, ProjectContextValue } from 'components/projects'
import { useHistory } from 'react-router'

const defaultSortKey = 'TimelinePageviews.count'

const Report = ({ setExtraToolbar }) => {

    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState(false)
    const [loadingCSV, setLoadingCSV] = useState(false)
    const analyticsContext: AnalyticsContextValue = useAnalyticsContext()
    const searchNameInput = useRef(null)
    const projectCtx: ProjectContextValue = useProjectContext()
    const history = useHistory() as any
    // const didMount = useRef(false)
    const analyticsFingerprint = useRef('')

    // default sort order
    const sortKey = analyticsContext.sortKey || defaultSortKey
    const sortOrder = analyticsContext.sortOrder

    const generateQuery = useCallback((
        groupBy: string[],
        searchName: string | undefined,
        segmentId: string,
        domainId: string,
        dateFrom: string,
        dateTo: string,
        dateFromPrevious: string | null,
        dateToPrevious: string | null,
        dimensionFilters: any) => {

        const filters = [...dimensionFilters]

        if (searchName) {
            filters.push({
                'member': 'TimelinePageviews.pageviewPageId',
                'operator': 'contains',
                'values': [searchName]
            })
        }

        if (segmentId !== '_all') {
            filters.push({
                'member': 'UserSegments.segmentId',
                'operator': 'equals',
                'values': [segmentId]
            })
        }

        if (domainId !== '_all') {
            filters.push({
                'member': 'TimelinePageviews.domainId',
                'operator': 'equals',
                'values': [domainId]
            })
        } else {
            const webDomains = projectCtx.currentProject.domains.filter((d: any) => !d.deletedAt && d.kind === 'web')
            filters.push({
                'member': 'TimelinePageviews.domainId',
                'operator': 'in',
                'values': webDomains.map((x: any) => x.id)
            })
        }

        const compareDateRange: any = [[dateFrom, dateTo]]

        if (dateFromPrevious && dateToPrevious) {
            compareDateRange.push([dateFromPrevious, dateToPrevious])
        }

        const opts: Query = {
            measures: [
                'TimelinePageviews.uniqueUsers',
                'TimelineSessions.count',
                'TimelinePageviews.count',
                'TimelinePageviews.averagePageviewDuration',
                'TimelinePageviews.bounceRate',
                'TimelinePageviews.entrancesRate',
                'TimelinePageviews.exitsRate',
            ],
            filters: filters,
            timeDimensions: [
                {
                    "dimension": 'TimelinePageviews.truncCreatedAt',
                    "granularity": null,
                    "compareDateRange": compareDateRange
                }
            ],
            timezone: analyticsContext.timezone,
            order: {
                [sortKey]: sortOrder === 'descend' ? 'desc' : 'asc'
            },
            limit: 300
        }

        if (groupBy) {
            opts.dimensions = groupBy
        }

        return opts
    }, [analyticsContext.timezone, projectCtx.currentProject.domains, sortKey, sortOrder])

    const fetchData = useCallback((groupBy: string, opts: any) => {

        // if (noCache) {
        //   opts.renewQuery = true
        // }

        return new Promise((resolve, reject) => {
            analyticsContext.cubejsApi.load(opts).then((value: ResultSet) => {

                const [currentChannels, previousChannels] = value.decompose()

                const returned: any = []

                const lines = currentChannels.tablePivot()
                const previousLines = previousChannels.tablePivot()

                // remove leading dates in keys (2021-10-06T00:00:00.000 - 2021-11-04T23:59:59.999,TimelineOrders.averageCart: 3481.0652)
                removeLeadingDates(lines)
                removeLeadingDates(previousLines)

                lines.forEach((line: any) => {

                    const previousFound = previousLines.find((x: any, y: number) => {

                        if (groupBy !== '_all') {
                            return x[groupBy] === line[groupBy]
                        } else {
                            return true
                        }
                    })

                    const returnedLine: any = {
                        name: groupBy !== '_all' ? line[groupBy] : '_all',
                        current: line,
                        previous: previousFound || {},
                    }

                    returnedLine.id = returnedLine.name

                    returned.push(returnedLine)
                })

                resolve(returned)
                // return Analytics.CreateDataWithCurrentPrevious(currentChannelsTable, previousChannelsTable, groupBy)
            }).catch((error: any) => {

                console.log(error)

                let message = error.message

                if (error.response && error.response.status === 400) {
                    switch (error.response.data.message) {
                        default:
                            message = error.response.data.message
                    }
                }

                reject(message)
            })
        })
    }, [analyticsContext.cubejsApi])


    const loadRootData = useCallback((analyticsContext: AnalyticsContextValue) => {
        return new Promise((resolve, reject) => {

            setLoading(true)

            const filters: any = [{
                'member': 'TimelinePageviews.pageviewPageId',
                'operator': 'set',
            }]

            const totalsData = fetchData(
                '_all',
                generateQuery(
                    null,
                    analyticsContext.searchName,
                    analyticsContext.segment,
                    // analyticsContext.conversionType,
                    analyticsContext.domain,
                    analyticsContext.dateFrom,
                    analyticsContext.dateTo,
                    analyticsContext.dateFromPrevious,
                    analyticsContext.dateToPrevious,
                    filters))

            const query = generateQuery(
                ['TimelinePageviews.pageviewPageId'],
                analyticsContext.searchName,
                analyticsContext.segment,
                // analyticsContext.conversionType,
                analyticsContext.domain,
                analyticsContext.dateFrom,
                analyticsContext.dateTo,
                analyticsContext.dateFromPrevious,
                analyticsContext.dateToPrevious,
                filters)

            const linesData = fetchData('TimelinePageviews.pageviewPageId', query)

            const sqlQuery = analyticsContext.cubejsApi.sql(query)

            Promise.all([
                linesData,
                totalsData,
                sqlQuery,
            ]).then((values: any) => {

                const data = [...values[0], ...values[1]]

                // console.log('data', data)

                values[2].forEach((queryData: SqlQuery) => {
                    console.log(...queryData.rawQuery().sql)
                })


                setLoading(false)
                setData(data)
                resolve(data)

            }).catch(msg => {
                message.error(msg)
                setLoading(false)
                reject(msg)
            })
        })
    }, [fetchData, generateQuery])


    const onExportCSV = useCallback(() => {

        // console.log('analyticsContext', analyticsContext)

        setLoadingCSV(true)

        const csvColumns: string[] = [
            'id',
            'pageviews',
            'uniqueUsers',
            'sessions',
            'averagePageviewDuration',
            'averagePageviewDurationHumanized',
            'bounceRate',
            'entrancesRate',
            'exitsRate',
            'filterDateFrom',
            'filterDateTo',
            'filterTimezone',
            'filterUserSegment',
            'filterDomain',
        ]

        const csvArray: any[][] = [csvColumns]

        loadRootData(analyticsContext).then((data: any) => {

            // console.log('data', data)

            data.forEach((line: any) => {

                const csvLine: any[] = [
                    line.id,
                    line.current['TimelinePageviews.count'] || 0,
                    line.current['TimelinePageviews.uniqueUsers'] || 0,
                    line.current['TimelineSessions.count'] || 0,
                    parseInt(line.current['TimelinePageviews.averagePageviewDuration'] || 0),
                    Formatters.duration(parseInt(line.current['TimelinePageviews.averagePageviewDuration'] || 0)),
                    line.current['TimelinePageviews.bounceRate'] || 0,
                    line.current['TimelinePageviews.entrancesRate'] || 0,
                    line.current['TimelinePageviews.exitsRate'] || 0,
                    analyticsContext.dateFrom + ' 00:00:00',
                    analyticsContext.dateTo + ' 23:59:59',
                    analyticsContext.timezone,
                    analyticsContext.segment,
                    analyticsContext.domain,
                ]

                csvArray.push(csvLine)
            })

            const filename = 'topPages_' + analyticsContext.dateFrom + '_' + analyticsContext.dateTo + '.csv'
            downloadCSV(arrayToCSV(csvArray), filename)
            setLoadingCSV(false)
        }).catch(_err => {
            setLoadingCSV(false)
        })
    }, [analyticsContext, loadRootData])


    // onmount + onupdate
    useEffect(() => {


        // we need to update the toolbar otherwise onExportCSV doesnt pick the actual context values
        // to avoid infinite loop we compare analytics context values
        const fingerprint = JSON.stringify(analyticsContext)

        if (analyticsFingerprint.current === fingerprint) return
        analyticsFingerprint.current = fingerprint

        loadRootData(analyticsContext)

        setExtraToolbar(<Tooltip placement="topRight" title="Download CSV">
            <Button className="margin-l-s" size="small" onClick={() => onExportCSV()}><DownloadOutlined /></Button>
        </Tooltip>)
    }, [analyticsContext, loadRootData, onExportCSV, setExtraToolbar])

    const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
        // console.log('filters', filters);
        // console.log('pagination', pagination);
        // console.log('sorter', sorter);
        // const params: any = QS.parse(props.location.search, { ignoreQueryPrefix: true })

        const newParams = _.assign({
            sortKey: analyticsContext.sortKey || 'sessions',
            sortOrder: analyticsContext.sortOrder || 'descend',
        }, {
            sortKey: sorter.columnKey,
            sortOrder: sorter.order,
        })

        history.push('/organizations/' + projectCtx.currentOrganization.id + '/projects/' + projectCtx.currentProject.id + '/analytics/web?tab=top-pages&' + QS.stringify(newParams, { indices: false }));
    }

    const handleSearchPage = (selectedKeys, confirm) => {
        confirm();
        analyticsContext.updateParams([{ k: 'searchName', v: selectedKeys[0] }])
    }

    const handleReset = clearFilters => {
        clearFilters()
        analyticsContext.updateParams([{ k: 'searchName', v: undefined }])
    };

    // console.log(data)

    const columns: any = [
        {
            title: t('page', "Page"),
            className: 'table-border-right',
            width: 300,
            fixed: 'left',
            key: 'page',
            defaultFilteredValue: analyticsContext.searchName ? [analyticsContext.searchName] : undefined,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchNameInput}
                        placeholder={`Search page`}
                        defaultValue={analyticsContext.searchName}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearchPage(selectedKeys, confirm)}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearchPage(selectedKeys, confirm)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
            onFilter: (value, record) => {
                return record.name.toString().toLowerCase().includes(value.toLowerCase())
            },
            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                    setTimeout(() => searchNameInput.current.select(), 100);
                }
            },
            render: (record: any) => {
                if (record.id === '_all') return ''

                let page = record.id

                try {
                    const url = new URL(record.id)
                    page = url.pathname + url.search
                } catch (e: any) {

                }

                return <Tooltip title={record.title}>{page}</Tooltip>
            }
        },
        {
            title: t('users', "Users"),
            key: 'TimelinePageviews.uniqueUsers',
            sorter: (a: any, b: any) => {
                if (a.id === '_all' && sortOrder === 'descend') return 1
                if (a.id === '_all' && sortOrder === 'ascend') return -1
                if (b.id === '_all' && sortOrder === 'descend') return -1
                if (b.id === '_all' && sortOrder === 'ascend') return 1

                if (a.current['TimelinePageviews.uniqueUsers'] > b.current['TimelinePageviews.uniqueUsers']) return 1
                if (a.current['TimelinePageviews.uniqueUsers'] < b.current['TimelinePageviews.uniqueUsers']) return -1
                return 0
            },
            defaultSortOrder: 'descend',
            sortDirections: ['descend', 'ascend'],
            sortOrder: sortKey === 'TimelinePageviews.uniqueUsers' ? sortOrder : undefined,
            render: (record: any) => <div>
                {Numeral(record.current['TimelinePageviews.uniqueUsers']).format('0,0')} {record.id !== '_all' && <span className="size-12 color-grey padding-l-s"> ({Formatters.percentage((record.current['TimelinePageviews.uniqueUsers'] * 100) / _.get(data, "[0].current['TimelinePageviews.uniqueUsers']", 1))})</span>}
                <div className="size-10">{Formatters.growthRate(record.current['TimelinePageviews.uniqueUsers'], record.previous['TimelinePageviews.uniqueUsers'])}</div>
            </div>
        },
        {
            title: t('sessions', "Sessions"),
            width: 130,
            key: 'TimelineSessions.count',
            className: 'table-border-right',
            defaultSortOrder: 'descend',
            sorter: (a: any, b: any) => {
                if (a.name === 'loading') return 0

                if (a.name === '_all' && sortOrder === 'descend') return 1
                if (a.name === '_all' && sortOrder === 'ascend') return -1
                if (b.name === '_all' && sortOrder === 'descend') return -1
                if (b.name === '_all' && sortOrder === 'ascend') return 1

                if (a.current['TimelineSessions.count'] > b.current['TimelineSessions.count']) return 1
                if (a.current['TimelineSessions.count'] < b.current['TimelineSessions.count']) return -1
                return 0
            },
            sortDirections: ['descend', 'ascend'],
            sortOrder: sortKey === 'TimelineSessions.count' ? sortOrder : undefined,
            render: (record: any) => {
                if (record.name === 'loading') return <Spin size="small" />
                return <div>
                    {Numeral(record.current['TimelineSessions.count']).format('0,0')}
                    <div className="size-10">{Formatters.growthRate(record.current['TimelineSessions.count'], record.previous['TimelineSessions.count'])}</div>
                </div>
            }
        },
        {
            title: t('pageviews', "Pageviews"),
            key: 'TimelinePageviews.count',
            sorter: (a: any, b: any) => {
                if (a.id === '_all' && sortOrder === 'descend') return 1
                if (a.id === '_all' && sortOrder === 'ascend') return -1
                if (b.id === '_all' && sortOrder === 'descend') return -1
                if (b.id === '_all' && sortOrder === 'ascend') return 1

                if (a.current['TimelinePageviews.count'] > b.current['TimelinePageviews.count']) return 1
                if (a.current['TimelinePageviews.count'] < b.current['TimelinePageviews.count']) return -1
                return 0
            },
            defaultSortOrder: 'descend',
            sortDirections: ['descend', 'ascend'],
            sortOrder: sortKey === 'TimelinePageviews.count' ? sortOrder : undefined,
            render: (record: any) => <div>
                {Numeral(record.current['TimelinePageviews.count']).format('0,0')} {record.id !== '_all' && <span className="size-12 color-grey padding-l-s"> ({Formatters.percentage((record.current['TimelinePageviews.count'] * 100) / _.get(data, "[0].current['TimelinePageviews.count']", 1))})</span>}
                <div className="size-10">{Formatters.growthRate(record.current['TimelinePageviews.count'], record.previous['TimelinePageviews.count'])}</div>
            </div>
        },
        {
            title: t('avg_time_on_page', "Avg. time on page"),
            key: 'TimelinePageviews.averagePageviewDuration',
            className: 'table-border-right',
            sorter: (a: any, b: any) => {
                if (a.id === '_all' && sortOrder === 'descend') return 1
                if (a.id === '_all' && sortOrder === 'ascend') return -1
                if (b.id === '_all' && sortOrder === 'descend') return -1
                if (b.id === '_all' && sortOrder === 'ascend') return 1

                if (a.current['TimelinePageviews.averagePageviewDuration'] > b.current['TimelinePageviews.averagePageviewDuration']) return 1
                if (a.current['TimelinePageviews.averagePageviewDuration'] < b.current['TimelinePageviews.averagePageviewDuration']) return -1
                return 0
            },
            defaultSortOrder: 'descend',
            sortDirections: ['descend', 'ascend'],
            sortOrder: sortKey === 'TimelinePageviews.averagePageviewDuration' ? sortOrder : undefined,
            render: (record: any) => <div>
                {Formatters.duration(record.current['TimelinePageviews.averagePageviewDuration'])} {record.id !== '_all' && <span className="size-12 color-grey padding-l-s"> ({Formatters.percentage((record.current['TimelinePageviews.averagePageviewDuration'] * 100) / _.get(data, "[0].current['TimelinePageviews.averagePageviewDuration']", 1))})</span>}
                <div className="size-10">{Formatters.growthRate(record.current['TimelinePageviews.averagePageviewDuration'], record.previous['TimelinePageviews.averagePageviewDuration'])}</div>
            </div>
        },
        {
            title: t('entranceRate', "Entrances"),
            key: 'TimelinePageviews.entrancesRate',
            sorter: (a: any, b: any) => {
                if (a.id === '_all' && sortOrder === 'descend') return 1
                if (a.id === '_all' && sortOrder === 'ascend') return -1
                if (b.id === '_all' && sortOrder === 'descend') return -1
                if (b.id === '_all' && sortOrder === 'ascend') return 1

                if (a.current['TimelinePageviews.entrancesRate'] > b.current['TimelinePageviews.entrancesRate']) return 1
                if (a.current['TimelinePageviews.entrancesRate'] < b.current['TimelinePageviews.entrancesRate']) return -1
                return 0
            },
            defaultSortOrder: 'descend',
            sortDirections: ['descend', 'ascend'],
            sortOrder: sortKey === 'TimelinePageviews.entrancesRate' ? sortOrder : undefined,
            render: (record: any) => <div>
                {Numeral(record.current['TimelinePageviews.entrancesRate']).format('0,0')} {record.id !== '_all' && <span className="size-12 color-grey padding-l-s"> ({Formatters.percentage((record.current['TimelinePageviews.entrancesRate'] * 100) / _.get(data, "[0].current['TimelinePageviews.entrancesRate']", 1))})</span>}
                <div className="size-10">{Formatters.growthRate(record.current['TimelinePageviews.entrancesRate'], record.previous['TimelinePageviews.entrancesRate'])}</div>
            </div>
        },
        {
            title: t('bounce', "Bounce"),
            key: 'TimelinePageviews.bounceRate',
            sorter: (a: any, b: any) => {
                if (a.id === '_all' && sortOrder === 'descend') return 1
                if (a.id === '_all' && sortOrder === 'ascend') return -1
                if (b.id === '_all' && sortOrder === 'descend') return -1
                if (b.id === '_all' && sortOrder === 'ascend') return 1

                if (a.current['TimelinePageviews.bounceRate'] > b.current['TimelinePageviews.bounceRate']) return 1
                if (a.current['TimelinePageviews.bounceRate'] < b.current['TimelinePageviews.bounceRate']) return -1
                return 0
            },
            defaultSortOrder: 'descend',
            sortDirections: ['descend', 'ascend'],
            sortOrder: sortKey === 'TimelinePageviews.bounceRate' ? sortOrder : undefined,
            render: (record: any) => <div>
                {Formatters.percentage(record.current['TimelinePageviews.bounceRate'])}
                <div className="size-10">{Formatters.growthRate(record.current['TimelinePageviews.bounceRate'], record.previous['TimelinePageviews.bounceRate'], true)}</div>
            </div>
        },
        {
            title: t('exit_rate', "% Exit"),
            key: 'TimelinePageviews.exitsRate',
            sorter: (a: any, b: any) => {
                if (a.id === '_all' && sortOrder === 'descend') return 1
                if (a.id === '_all' && sortOrder === 'ascend') return -1
                if (b.id === '_all' && sortOrder === 'descend') return -1
                if (b.id === '_all' && sortOrder === 'ascend') return 1

                if (a.current['TimelinePageviews.exitsRate'] > b.current['TimelinePageviews.exitsRate']) return 1
                if (a.current['TimelinePageviews.exitsRate'] < b.current['TimelinePageviews.exitsRate']) return -1
                return 0
            },
            defaultSortOrder: 'descend',
            sortDirections: ['descend', 'ascend'],
            sortOrder: sortKey === 'TimelinePageviews.exitsRate' ? sortOrder : undefined,
            render: (record: any) => {
                return <div>
                    {Formatters.percentage(record.current['TimelinePageviews.exitsRate'])}
                    <div className="size-10">{Formatters.growthRate(record.current['TimelinePageviews.exitsRate'], record.previous['TimelinePageviews.exitsRate'], true)}</div>
                </div>
            }
        },
    ]

    return <div>
        {loadingCSV && <Modal
            title={null}
            centered
            closable={false}
            visible={true}
            footer={null}
            maskClosable={false}
            width={230}
            transitionName=""
            maskTransitionName=""
        >
            <div className="text-center padding-a-l"><Spin size="large" tip="Preparing CSV..." /></div>
        </Modal>}
        <Table
            dataSource={data}
            rowKey="id"
            size="middle"
            onChange={handleTableChange}
            loading={loading}
            pagination={{
                position: ['bottomCenter'],
                pageSize: 20,
                showSizeChanger: false
            }}
            className="block margin-t-l"
            rowClassName={record => cn({ 'table-all-total': record.name === '_all' })}
            columns={columns}
        />
    </div>
}
export default Report
