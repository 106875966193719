import React, { useState } from 'react'

import t from '../../utils/translate'
import { Button, Alert, Input, Drawer, Form } from 'antd'

const UserAliasButton = (props: any) => {

  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()


  const toggleDrawer = () => {
    setVisible(!visible)
  }

  const onFinish = (values: any) => {

    setLoading(true)

    props.app.ajaxRequest({
      method: 'post',
      url: '/data.import',
      data: {
        projectId: props.project.id,
        id: 'alias_'+new Date().getTime(),
        sync: true,
        items: [{
          id: 'alias_'+new Date().getTime(),
          kind: 'alias',
          alias: {
            externalUserId: props.user.externalId,
            toExternalUserId: values.toUserId,
          }
        }]
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        props.app.addMessage('error', errorMessage)
        setLoading(false)
        return
      }

      if (response.data.results?.success === false) {
        props.app.addMessage('error', response.data.results.messages[0].message)
        setLoading(false)
        return
      }

      props.app.addMessage('success', t('user_merged', 'This user has been successfully merged.'))

      setLoading(false)
      setVisible(false)

      form.resetFields()

      if (window.cmAgent) {
        window.cmAgent.event({
          label: 'userAlias',
          props: {
            orgId: props.project.organizationId,
            projectId: props.project.id
          }
        })
        window.cmAgent.dispatch()
      }

      if (props.onComplete) {
        props.onComplete(values.toUserId)
      }
    })
  }

  // console.log('state', this.state)
  // console.log('props', props)


  return <>
    <Button size={props.size} type={props.type || 'default'} style={props.style || {}} onClick={toggleDrawer}>{props.children}</Button>

    {visible && <Drawer
      title={t('merge_this_user', "Merge this user")}
      visible={true}
      className="drawer drawer-form"
      onClose={toggleDrawer}
      width={800}
    >
      <Form form={form} layout="horizontal" className="margin-a-m margin-b-xl" onFinish={onFinish}>

        <Alert className="margin-b-m" type="warning" showIcon message={t('warning_merge_user', "This current user is about to be merged into another one. All it's activity will be merged and it's profile won't be accessible anymore.")} />

        <Form.Item name="toUserId" label={t('merge_this_user_into', "Merge this user into")} rules={[{ required: true, message: t('field_required', "This field is required") }]}>
          <Input placeholder={t('target_user_id', "Target user ID")} />
        </Form.Item>

        <div className="ant-drawer-footer">
          <Button style={{ marginRight: 8 }} key="back" type="ghost" onClick={toggleDrawer}>{t('cancel', "Cancel")}</Button>
          <Button key="submit" type="primary" loading={loading} htmlType="submit">{t('confirm', "Confirm")}</Button>
        </div>
      </Form>
    </Drawer>}
  </>
}

export default UserAliasButton