import React, { Component } from 'react'

import t from '../../utils/translate'
import { ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import DomainsList from '../domains/_table'
import DomainsAbout from '../domains/_about'

class ProjectSetupDomains extends Component<any, any> {

  render() {

    return (
      <div>
        <DomainsAbout />

        <div className="padding-v-m"><DomainsList {...this.props} /></div>

        <div className="text-right padding-a-m">
          <Button type="primary" disabled={this.props.projectLayout.project.domains.length === 0} onClick={(e: any) => this.props.setStep('domains')}>{t('next', "Next")} <ArrowRightOutlined /></Button>
        </div>
      </div>
    );
  }
}

export default ProjectSetupDomains