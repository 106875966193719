import { useState, useEffect, useRef, useCallback } from 'react'
import {
    // Row,
    // Col,
    Alert,
    Table,
    Button,
    Modal,
    Spin,
    message,
} from 'antd'
import _ from 'lodash'
import t from '../../utils/translate'
import TemplateButton from './_templateButton'
import NotificationForm from './_notificationForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TestForm from './_testButton'
import {
    DeleteOutlined,
    ExclamationCircleOutlined
} from '@ant-design/icons'
import { useProjectContext, ProjectContextValue } from '../projects'
import { useLocation } from 'react-router-dom'


const NotificationsList = () => {

    const projectCtx: ProjectContextValue = useProjectContext()
    const location = useLocation() as any
    const [loading, setLoading] = useState(false)
    const prevLocationSearch = useRef(undefined)
    const didMount = useRef(false)

    useEffect(() => {
        if (didMount.current) return
        didMount.current = true

        if (window.cmAgent) {
            window.cmAgent.pageview({
                title: 'Notifications templates (project: ' + projectCtx.currentProject.id + ')',
                page: location.pathname,
                props: {
                    orgId: projectCtx.currentProject.organizationId,
                    projectId: projectCtx.currentProject.id
                }
            })
            window.cmAgent.dispatch()
        }
    }, [projectCtx.currentProject.id, projectCtx.currentProject.organizationId, location.pathname])


    const onComplete = useCallback(() => {
        setLoading(true)
        projectCtx.refreshNotificationsAndMacros().then(() => {
            setLoading(false)
        })
    }, [projectCtx])

    useEffect(() => {
        // check url params have changed
        if (prevLocationSearch.current === location.search) return
        prevLocationSearch.current = location.search

        onComplete()

    }, [prevLocationSearch, projectCtx, location.search, onComplete])


    return <div className="margin-v-l">
        <Spin spinning={loading}>
            <div className="text-right margin-b-l">
                <NotificationForm
                    ajaxRequest={projectCtx.ajaxRequest}
                    notification={undefined}
                    btnSize={undefined}
                    project={projectCtx.currentProject}
                    inDrawer={true}
                    onComplete={onComplete}
                    notifications={projectCtx.currentProject.notifications}
                />

                <Button className="margin-l-m" type="ghost" onClick={onComplete} disabled={loading}><FontAwesomeIcon icon="sync-alt" spin={loading} /></Button>
            </div>

            <Alert
                description={t('templates_info', "A notification defines which channels it can be sent to, and holds the templates for each channel/translation.")}
                message={undefined}
                type="info"
                showIcon
                className="margin-v-l"
            />

            {projectCtx.currentProject.notifications.map((notification: any) => <NotificationItem
                key={notification.id}
                project={projectCtx.currentProject}
                ajaxRequest={projectCtx.ajaxRequest}
                notification={notification}
                notifications={projectCtx.currentProject.notifications}
                macros={projectCtx.currentProject.macros}
                onComplete={onComplete}
                adminEmail={projectCtx.admin.primaryEmail}
            />)}
        </Spin>
    </div>
}

export default NotificationsList

const NotificationItem = ({ ajaxRequest, project, notification, notifications, macros, onComplete, adminEmail }) => {

    // console.log('NotificationItem projectLayout', projectLayout);
    // const [loading, setLoading] = useState(false);

    const deleteConfirm = () => {
        Modal.confirm({
            title: 'Delete this notification?',
            content: 'Do you really want to delete this notification?',
            icon: <ExclamationCircleOutlined />,
            okText: t('confirm', "Confirm"),
            cancelText: t('cancel', "Cancel"),
            okType: 'danger',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    ajaxRequest({
                        method: 'post',
                        url: '/notifications.delete',
                        data: {
                            projectId: project.id,
                            id: notification.id
                        }
                    }, (error: any, _data: any) => {

                        if (error) {
                            message.error(error)
                            return reject(error)
                        }

                        message.success('Your notification has been successfully deleted!')

                        // this.fetchNotifications()
                        return resolve(undefined)
                    })
                })
            },
        })
    }

    const columns: any = [{
        title: '',
        key: 'lang',
        render: (lang: any) => <span className="padding-l-s">{lang.value.toUpperCase()}</span>
    }]

    //   if (notification.withWidget) {
    //     columns.push({
    //       title: <span><BellOutlined className="padding-r-s" /> Widget</span>,
    //       key: 'widget',
    //       // align: 'right',
    //       width: 200,
    //       render: lang => {
    //         const tpl = _.find(notification.templates, x => x.channel === 'widget' && x.language === lang.value)

    //         return <TemplateButton macros={macros} app={app} projectLayout={projectLayout} template={tpl} notification={notification} channel="widget" channelTitle="Widget" language={lang.value} btnSize="small" onComplete={onComplete} />
    //       }
    //     })
    //   }

    if (notification.withEmail) {
        columns.push({
            title: <span><FontAwesomeIcon icon="envelope" className="margin-r-s" /> Email</span>,
            key: 'email',
            // align: 'right',
            width: 200,
            render: (lang: any) => {
                const tpl = _.find(notification.templates, x => x.channel === 'email' && x.language === lang.value)

                return <TemplateButton
                    project={project}
                    ajaxRequest={ajaxRequest}
                    visible={false}
                    macros={macros}
                    template={tpl}
                    notification={notification}
                    channel="email"
                    channelTitle="Email"
                    language={lang.value}
                    btnSize="small"
                    onComplete={onComplete}
                />
            }
        })
    }

    if (notification.withSMS) {
        columns.push({
            title: <span><FontAwesomeIcon icon="mobile" style={{ width: '14px' }} className="padding-r-s" /> SMS</span>,
            key: 'sms',
            // align: 'right',
            width: 200,
            render: (lang: any) => {
                const tpl = _.find(notification.templates, x => x.channel === 'sms' && x.language === lang.value)

                return <TemplateButton
                    project={project}
                    ajaxRequest={ajaxRequest}
                    visible={false}
                    macros={macros}
                    template={tpl}
                    notification={notification}
                    channel="sms"
                    channelTitle="SMS"
                    language={lang.value}
                    btnSize="small"
                    onComplete={onComplete}
                />
            }
        })
    }

    return <div className="block margin-b-l">
        <h2 className="no-margin">
            <span className="actions">
                <span className="color-grey size-12 padding-r-m">ID: {notification.id}</span>
                <Button.Group>
                    <Button type="ghost" size="small" onClick={deleteConfirm}><DeleteOutlined /></Button>
                    <NotificationForm
                        project={project}
                        ajaxRequest={ajaxRequest}
                        btnSize="small"
                        inDrawer={true}
                        notification={notification}
                        notifications={notifications}
                        onComplete={onComplete}
                    />
                    <TestForm
                        project={project}
                        ajaxRequest={ajaxRequest}
                        notification={notification}
                        btnSize="small"
                        adminEmail={adminEmail}
                        onComplete={() => { }}
                    />
                    {/* <TestForm app={app} projectLayout={projectLayout} notification={notification} btnSize="small" /> */}
                </Button.Group>
            </span>
            {notification.name}
        </h2>

        <Table
            className="no-header-bg"
            rowKey="key"
            size="middle"
            pagination={false}
            dataSource={notification.languages.map((lang: any) => { return { key: lang, value: lang } })}
            showHeader={true}
            columns={columns}
        />
    </div>
}
