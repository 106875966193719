import React, { useRef, ChangeEvent, ReactNode } from "react"
import { Item, FileProvider } from '../core/interfaces'
import {
    // Upload,
} from 'antd'


export interface UploadFileProps {
    fileProvider: FileProvider
    uploadQueue: Item[]
    setTree: Function
    setUploadQueue: Function
    isUploading: boolean
    // setIsUploading: Function
    path: string
    children: ReactNode
    onComplete: () => void
    onError: (error: any) => void
    accept?: string
    multiple?: boolean
}


const UploadFile = (props: UploadFileProps) => {

    const inputFileRef = useRef<HTMLInputElement>(null);
    // const [uploadingItems, setUploadingItems] = useState<Item[]>([])

    const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) return

        // launch worker if not already uploading
        if (props.isUploading) {
            return
        }

        /*Selected files data can be collected here.*/
        // console.log(e.target.files);

        for (var i = 0; i < e.target.files.length; i++) {

            const file = e.target.files.item(i) as File

            props.uploadQueue.push({
                id: 'string',
                path: props.path,
                name: file.name,
                isFolder: false,
                url: '',
                contentType: file.type,
                size: file.size,
                metadata: {},
                // width?: number
                // height?: number
                // persistedAt?: Date
                lastModifiedAt: file.lastModified,
                // uploadedAt: new Date(),
                uploadProgress: 0,
                uploadState: 'queued',
                // deletedAt: Date
                file: file
            } as Item)
        }

        props.setUploadQueue(props.uploadQueue)

        // props.setIsUploading(true)
        props.fileProvider.startUpload(props.uploadQueue).then(() => { }).catch(e => props.onError(e))
    }

    const onBtnClick = () => {
        if (inputFileRef.current) {
            inputFileRef.current.click();
        }
    }

    // props.fileProvider.createFolder(newFolder).then(() => {
    //     form.resetFields()
    //     setLoading(false)
    //     setModalVisible(false)
    //     props.onComplete()
    // })
    //     .catch(e => {
    //         setLoading(false)
    //         props.onError(e)
    //     });

    const inputProps: any = {
        accept: props.accept,
        multiple: 'multiple'
    }

    // if (props.multiple) {
    //     inputProps.multiple = 'multiple'
    // }

    return <span className="ant-upload" role="button" onClick={onBtnClick}>
        <input type="file" ref={inputFileRef} onChange={onFileChange} style={{ display: 'none' }} {...inputProps} />
        {props.children}
    </span>
}

export default UploadFile