import { useState, useCallback, useRef, useEffect } from 'react'

import { RightOutlined } from '@ant-design/icons';

import { Table, Tooltip, Tag, message, } from 'antd';
import t from 'utils/translate'
import Formatters from 'utils/formatters'
import Numeral from 'numeral'
import QS from 'qs'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Analytics from 'utils/analytics'
import removeLeadingDates from 'utils/removeLeadingDates'
import { useAnalyticsContext, AnalyticsContextValue } from './index'
import { ResultSet, Query, } from '@cubejs-client/core'
// import { arrayToCSV, downloadCSV } from 'utils/csv'
import { useProjectContext, ProjectContextValue } from 'components/projects'
import { useHistory } from 'react-router'

const defaultSortKey = 'TimelineOrders.count'
// const defaultSortOrder = 'descend'

const ReportsConversionPaths = () => {

  const [data, setData] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const analyticsContext: AnalyticsContextValue = useAnalyticsContext()
  const projectCtx: ProjectContextValue = useProjectContext()
  const history = useHistory() as any
  const didMount = useRef(false)

  const sortKey: any = analyticsContext.sortKey || defaultSortKey
  const sortOrder = analyticsContext.sortOrder

  const generateQuery = useCallback((
    groupBy: string[],
    segmentId: string,
    domainId: string,
    dateFrom: string,
    dateTo: string,
    dateFromPrevious: string | null,
    dateToPrevious: string | null,
    dimensionFilters: any) => {

    const filters = [...dimensionFilters]

    if (segmentId !== '_all') {
      filters.push({
        'member': 'UserSegments.segmentId',
        'operator': 'equals',
        'values': [segmentId]
      })
    }

    if (domainId !== '_all') {
      filters.push({
        'member': 'TimelinePageviews.domainId',
        'operator': 'equals',
        'values': [domainId]
      })
    } else {
      const webDomains = projectCtx.currentProject.domains.filter((d: any) => !d.deletedAt && d.kind === 'web')
      filters.push({
        'member': 'TimelinePageviews.domainId',
        'operator': 'in',
        'values': webDomains.map((x: any) => x.id)
      })
    }

    const compareDateRange: any = [[dateFrom, dateTo]]

    if (dateFromPrevious && dateToPrevious) {
      compareDateRange.push([dateFromPrevious, dateToPrevious])
    }

    const opts: Query = {
      measures: [
        'TimelineOrders.count',
        'TimelineOrders.revenue',
        'TimelineOrders.averageCart',
        'TimelineOrders.averageTimeToConversion'
      ],
      filters: filters,
      timeDimensions: [
        {
          "dimension": 'TimelinePageviews.truncCreatedAt',
          "granularity": null,
          "compareDateRange": compareDateRange
        }
      ],
      timezone: analyticsContext.timezone,
      order: {
        [sortKey]: sortOrder === 'descend' ? 'desc' : 'asc'
      },
      limit: 300
    }

    if (groupBy) {
      opts.dimensions = groupBy
    }

    return opts
  }, [analyticsContext.timezone, projectCtx.currentProject.domains, sortKey, sortOrder])

  const fetchData = useCallback((groupBy: string, opts: any) => {

    // if (noCache) {
    //   opts.renewQuery = true
    // }

    return new Promise((resolve, reject) => {
      analyticsContext.cubejsApi.load(opts).then((value: ResultSet) => {

        const [current, previous] = value.decompose()
        const blendKey = 'TimelineOrders.conversionFunnelHash'

        const currentLines = current.tablePivot()
        const previousLines = previous.tablePivot()

        removeLeadingDates(currentLines)
        removeLeadingDates(previousLines)

        const returned = Analytics.CreateDataWithCurrentPrevious(currentLines, previousLines, blendKey)

        resolve(returned)
        // return Analytics.CreateDataWithCurrentPrevious(currentChannelsTable, previousChannelsTable, groupBy)
      }).catch((error: any) => {

        console.log(error)

        let message = error.message

        if (error.response && error.response.status === 400) {
          switch (error.response.data.message) {
            default:
              message = error.response.data.message
          }
        }

        reject(message)
      })
    })
  }, [analyticsContext.cubejsApi])


  const loadRootData = useCallback((analyticsContext: AnalyticsContextValue) => {
    return new Promise((resolve, reject) => {

      setLoading(true)

      const filters: any = [{
        'member': 'TimelinePageviews.pageviewPageId',
        'operator': 'set',
      }]

      fetchData('TimelinePageviews.pageviewPageId', generateQuery(
        [
          'TimelineOrders.conversionFunnelHash',
          'TimelineOrders.conversionFunnel',
        ],
        analyticsContext.segment,
        // analyticsContext.conversionType,
        analyticsContext.domain,
        analyticsContext.dateFrom,
        analyticsContext.dateTo,
        analyticsContext.dateFromPrevious,
        analyticsContext.dateToPrevious,
        filters)).then((values: any) => {

          // console.log('data', data)

          setData(values)
          setLoading(false)
          resolve(values)

        }).catch(msg => {
          message.error(msg)
          setLoading(false)
          reject(msg)
        })
    })
  }, [fetchData, generateQuery])


  // onmount
  useEffect(() => {

    if (didMount.current === true) return
    didMount.current = true

    loadRootData(analyticsContext)

  }, [analyticsContext, loadRootData])

  // onupdate
  useEffect(() => {
    loadRootData(analyticsContext)
  }, [analyticsContext, loadRootData])


  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    // console.log('filters', filters);
    // console.log('pagination', pagination);
    // console.log('sorter', sorter);
    // const params: any = QS.parse(props.location.search, { ignoreQueryPrefix: true })

    const newParams = _.assign({
      sortKey: analyticsContext.sortKey || 'sessions',
      sortOrder: analyticsContext.sortOrder || 'descend',
    }, {
      sortKey: sorter.columnKey,
      sortOrder: sorter.order,
    })

    history.push('/organizations/' + projectCtx.currentOrganization.id + '/projects/' + projectCtx.currentProject.id + '/analytics/web?tab=conversion-paths&' + QS.stringify(newParams, { indices: false }));
  }


  // fetchReport(segmentId: string, conversionType: string, domainId: string, sortKey: string, sortOrder: string, dateFrom: string, dateTo: string, dateFromPrevious: string, dateToPrevious: string, noCache ?: boolean) {

  //   if (loading === true) {
  //     return
  //   }


  //   this.setState({ loading: true })


  //   const filters: any = []

  //   if (segmentId !== '_all') {
  //     filters.push({
  //       'member': 'UserSegments.segmentId',
  //       'operator': 'equals',
  //       'values': [segmentId]
  //     })
  //   }

  //   if (conversionType !== 'all') {
  //     filters.push({
  //       'member': 'TimelineOrders.isFirstConversion',
  //       'operator': 'equals',
  //       'values': [conversionType === 'acquisition' ? '1' : '0']
  //     })
  //   }

  //   if (domainId !== '_all') {
  //     filters.push({
  //       'member': 'TimelineOrders.domainId',
  //       'operator': 'equals',
  //       'values': [domainId]
  //     })
  //   } else {
  //     const webDomains = projectCtx.currentProject.domains.filter((d: any) => !d.deletedAt && d.kind === 'web')
  //     filters.push({
  //       'member': 'TimelineOrders.domainId',
  //       'operator': 'in',
  //       'values': webDomains.map((x: any) => x.id)
  //     })
  //   }

  //   const opts: any = {
  //     measures: [
  //       'TimelineOrders.count',
  //       'TimelineOrders.revenue',
  //       'TimelineOrders.averageCart',
  //       'TimelineOrders.averageTimeToConversion'
  //     ],
  //     dimensions: [
  //       'TimelineOrders.conversionFunnelHash',
  //       'TimelineOrders.conversionFunnel',
  //     ],
  //     filters: filters,
  //     timeDimensions: [
  //       {
  //         "dimension": 'TimelineOrders.truncCreatedAt',
  //         "granularity": null,
  //         "compareDateRange": [
  //           [dateFrom, dateTo],
  //           [dateFromPrevious, dateToPrevious]
  //         ]
  //       }
  //     ],
  //     timezone: this.props.timezone,
  //     order: {
  //       [sortKey]: sortOrder
  //     },
  //     limit: 100
  //   }

  //   if (noCache) {
  //     opts.renewQuery = true
  //   }

  //   // console.log('opts', opts)
  //   this.props.cubejsApi.load(opts).then((values: any) => {
  //     const [current, previous] = values.decompose()
  //     const blendKey = 'TimelineOrders.conversionFunnelHash'

  //     const currentLines = current.tablePivot()
  //     const previousLines = previous.tablePivot()

  //     removeLeadingDates(currentLines)
  //     removeLeadingDates(previousLines)

  //     const data = Analytics.CreateDataWithCurrentPrevious(currentLines, previousLines, blendKey)

  //     // console.log('data', data)
  //     if (this._isMounted) {
  //       this.setState({
  //         data: data,
  //         loading: false
  //       })
  //     }
  //   })
  // }


  const channelsMap = _.keyBy(projectCtx.currentProject.channels, 'id')

  channelsMap['not-mapped'] = {
    id: 'not-mapped',
    name: 'not-mapped',
    groupId: 'not-mapped',
    isDefault: true,
    sourceMediumPaths: [],
    voucherCodes: [],
    createdAt: new Date(),
    updatedAt: new Date(),
    isMediaPartner: false
  }

  return <div>
    <Table
      dataSource={data}
      rowKey="id"
      size="middle"
      className="edge-table block margin-t-l"
      onChange={handleTableChange}
      loading={loading}
      pagination={{
        position: ['bottomCenter'],
        pageSize: 20,
        showSizeChanger: false,
      }}
      columns={[
        {
          title: t('conversion_paths', "Conversions paths"),
          key: 'path',
          render: (record: any) => {
            if (!record.current['TimelineOrders.conversionFunnel']) {
              return t('conversion_path_not_recorded', "Conversion path not recorded")
            }

            try {
              const funnel = JSON.parse(record.current['TimelineOrders.conversionFunnel'])

              return funnel.map((p: any, i: number) => {
                const channelGroup = projectCtx.currentProject.channelGroups.find((x: any) => x.id === channelsMap[p.channelId].groupId)
                return <span key={i}>
                  <Tooltip title={p.sourceMedium}>
                    <span className="padding-r-s padding-b-xs">{channelsMap[p.channelId].name}</span>
                    <Tag className="xsmall" color={channelGroup.color}>{channelGroup.name}</Tag>
                    {p.count > 1 && <span className="size-12">x{p.count}</span>}
                  </Tooltip>
                  {i < (funnel.length - 1) && <span className="padding-h-s opacity-50"><RightOutlined /></span>}
                </span>
              });
            } catch (e: any) {
              console.error(e)
              return e
            }
          }
        },
        {
          title: <FontAwesomeIcon icon="shopping-cart" />,
          key: 'TimelineOrders.count',
          width: 160,
          sortOrder: sortKey === 'TimelineOrders.count' ? sortOrder : undefined,
          sorter: (a: any, b: any) => a.current['TimelineOrders.count'] - b.current['TimelineOrders.count'],
          sortDirections: ['descend', 'ascend'],
          render: (record: any) => <div>
            {Numeral(record.current['TimelineOrders.count']).format('0,0')}
            <span className="padding-l-s size-10">{Formatters.growthRate(record.current['TimelineOrders.count'], record.previous['TimelineOrders.count'])}</span>
          </div>
        },
        {
          title: t('revenue', "Revenue"),
          key: 'TimelineOrders.revenue',
          width: 200,
          sortOrder: sortKey === 'TimelineOrders.revenue' ? sortOrder : undefined,
          sorter: (a: any, b: any) => a.current['TimelineOrders.revenue'] - b.current['TimelineOrders.revenue'],
          sortDirections: ['descend', 'ascend'],
          render: (record: any) => <div>
            {Formatters.currency(projectCtx.currentProject.currency, record.current['TimelineOrders.revenue'], undefined, undefined, undefined, undefined, true)}
            <span className="padding-l-s size-10">{Formatters.growthRate(record.current['TimelineOrders.revenue'], record.previous['TimelineOrders.revenue'])}</span>
          </div>
        },
        {
          title: t('avg_cart', "Avg. cart"),
          key: 'TimelineOrders.averageCart',
          width: 200,
          sortOrder: sortKey === 'TimelineOrders.averageCart' ? sortOrder : undefined,
          sorter: (a: any, b: any) => a.current['TimelineOrders.averageCart'] - b.current['TimelineOrders.averageCart'],
          sortDirections: ['descend', 'ascend'],
          render: (record: any) => <div>
            {Formatters.currency(projectCtx.currentProject.currency, record.current['TimelineOrders.averageCart'], undefined, undefined, undefined, undefined, true)}
            <span className="padding-l-s size-10">{Formatters.growthRate(record.current['TimelineOrders.averageCart'], record.previous['TimelineOrders.averageCart'])}</span>
          </div>
        },
        {
          title: <Tooltip title={t('avg_time_to_conversion', "Average Time To Conversion")}>{t('avg_ttc', "Avg. TTC")}</Tooltip>,
          key: 'TimelineOrders.averageTimeToConversion',
          width: 150,
          sortOrder: sortKey === 'TimelineOrders.averageTimeToConversion' ? sortOrder : undefined,
          sorter: (a: any, b: any) => a.current['TimelineOrders.averageTimeToConversion'] - b.current['TimelineOrders.averageTimeToConversion'],
          sortDirections: ['descend', 'ascend'],
          render: (record: any) => <div>
            {Formatters.duration(record.current['TimelineOrders.averageTimeToConversion'])}
            <span className="padding-l-s size-10">{Formatters.growthRate(record.current['TimelineOrders.averageTimeToConversion'], record.previous['TimelineOrders.averageTimeToConversion'])}</span>
          </div>
        },
      ]}
    />
  </div>
}

export default ReportsConversionPaths


