import React, { Component } from 'react'

import {
  InputNumber,
} from 'antd';
import t from '../../utils/translate'

class DurationPicker extends Component<any, any> {

  static getDerivedStateFromProps(nextProps: any) {
    // Should be a controlled component.
    if ('value' in nextProps) {
      return {
        ...(nextProps.value || {}),
      }
    }
    return null
  }

  constructor(props: any) {
    super(props)

    let remainingTime = this.props.value
    let days = 0
    let hours = 0
    let minutes = 0

    // has days?
    if (remainingTime >= 60*24) {
      days = Math.floor(remainingTime/(60*24))
      remainingTime = remainingTime%(60*24)
    }

    // has hours
    if (remainingTime >= 60) {
      hours = Math.floor(remainingTime/60)
      remainingTime = remainingTime%60
    }

    minutes = remainingTime

    this.state = {
      days: days,
      hours: hours,
      minutes: minutes
    }

    this.change = this.change.bind(this)
  }

  change(kind: string, value: any) {
    const newState: any = Object.assign({}, this.state)
    newState[kind] = value
    this.setState(newState)
    // console.log('newState', newState);
    const daysInMinutes = newState.days > 0 ? newState.days*(60*24) : 0
    const hoursInMinutes = newState.hours > 0 ? newState.hours*60 : 0

    const total = daysInMinutes+hoursInMinutes+newState.minutes
    // console.log('total', total);
    this.props.onChange(total)
  }

  render() {
    return <React.Fragment>
      <InputNumber min={0} value={this.state.days} onChange={this.change.bind(null, 'days')} /> <span className="padding-h-s">{t('days_and', "days and")}</span>
      <InputNumber min={0} value={this.state.hours} onChange={this.change.bind(null, 'hours')} /> <span className="padding-h-s">{t('hours_and', "hours and")}</span>
      <InputNumber min={0} value={this.state.minutes} onChange={this.change.bind(null, 'minutes')} /> <span className="padding-l-s">{t('minutes', "minutes")}</span>
    </React.Fragment>
  }
}

export default DurationPicker



