import { useState, useRef, useEffect } from 'react'

import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  EditOutlined,
} from '@ant-design/icons'
import { Table, Button, Spin, Tag } from 'antd';
import t from '../../utils/translate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NotificationTopicButton from './_topicsFormButton'
import N from 'numeral'
import { useProjectContext, ProjectContextValue } from '../projects'
import { useLocation } from 'react-router-dom'

const NotificationTopicsList = () => {

  const projetCtx: ProjectContextValue = useProjectContext()
  const location = useLocation() as any
  const [loading, setLoading] = useState(false)
  const prevLocationSearch = useRef(undefined)
  const didMount = useRef(false)

  useEffect(() => {
    if (didMount.current) return
    didMount.current = true

    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'NotificationTopics (project: ' + projetCtx.currentProject.id + ')',
        page: location.pathname,
        props: {
          orgId: projetCtx.currentProject.organizationId,
          projectId: projetCtx.currentProject.id
        }
      })
      window.cmAgent.dispatch()
    }
  }, [projetCtx.currentProject.id, projetCtx.currentProject.organizationId, location.pathname])

  useEffect(() => {
    // check url params have changed
    if (prevLocationSearch.current !== location.search) {
      projetCtx.refreshNotificationTopics()
    }

  }, [prevLocationSearch, projetCtx, location.search, projetCtx.refreshNotificationTopics])

  // const deleteNotificationTopic = (workflowId: string) => {

  //   if (loading === true) {
  //     return
  //   }

  //   setLoading(true)

  //   projetCtx.ajaxRequest({
  //     method: 'post',
  //     url: '/notificationTopics.delete',
  //     data: {
  //       workflowId: workflowId,
  //       projectId: projetCtx.currentProject.id,
  //     }
  //   }, (errorMessage: any, response: any) => {

  //     if (errorMessage) {
  //       message.error(errorMessage)
  //       setLoading(false)
  //       return
  //     }

  //     message.success(t('workflow_deleted', "The workflow has been deleted!"))

  //     setLoading(false)
  //     projetCtx.refreshNotificationTopics()
  //   })
  // }

  const onComplete = () => {
    setLoading(true)
    projetCtx.refreshNotificationTopics().then(() => {
      setLoading(false)
    })
  }


  return <div className="margin-t-l">

    <div className="text-right margin-b-l">
      <NotificationTopicButton
        type="primary"
        title={t('new_notification_topic', "New notification topic")}
        app={projetCtx}
        project={projetCtx.currentProject}
        notifications={projetCtx.currentProject.notifications}
        notificationTopics={projetCtx.currentProject.notificationTopics}
        onComplete={onComplete}
      >
        {t('new_notification_topic', "New notification topic")}
      </NotificationTopicButton>

      <Button className="margin-l-m" type="ghost" onClick={onComplete} disabled={loading}><FontAwesomeIcon icon="sync-alt" spin={loading} /></Button>
    </div>

    {loading && <div className="text-center margin-a-l"><Spin size="large" /></div>}

    {projetCtx.currentProject.notificationTopics?.map((record: any) => <div className="block margin-b-m" key={record.id}>
      <h2>{record.name}
        <span className="actions">
          <span className="color-grey size-12 padding-r-m">ID: {record.id}</span>

          <Button.Group className="margin-l-m">

            {/* <Popconfirm placement="topRight" title={t('delete_workflow_confirm', "Do you really want to delete this workflow?")} onConfirm={() => this.deleteWorkflow(record.id)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                  <Button type="ghost"><DeleteOutlined /></Button>
                </Popconfirm> */}

            <NotificationTopicButton
              type="ghost"
              title={t('edit_notification_topic', "Edit notification topic")}
              app={projetCtx}
              project={projetCtx.currentProject}
              notifications={projetCtx.currentProject.notifications}
              notificationTopics={projetCtx.currentProject.notificationTopics}
              onComplete={onComplete}
              notificationTopic={record}
            >
              <EditOutlined /> {t('edit', "Edit")}
            </NotificationTopicButton>
          </Button.Group>
        </span>
      </h2>

      <Table
        showHeader={false}
        size="middle"
        rowKey="id"
        dataSource={[
          {
            id: 'kind',
            title: t('kind', "Kind"),
            content: <>
              {record.kind === 'broadcast' && <Tag color="purple">Broadcast</Tag>}
              {record.kind === 'collection' && <>
                <Tag color="magenta">Collection</Tag> <span>{record.collectionKind} - {record.collectionId}</span>
              </>}
            </>
          },
          {
            id: 'double_opt_in',
            title: t('double_opt_in', "Double Opt-in"),
            content: <>
              {record.doubleOptIn && <>
                <CheckCircleTwoTone twoToneColor="#52c41a" />
                <span className="padding-l-m">Notification: {projetCtx.currentProject.notifications.find(x => x.id === record.doubleOptInNotificationId)?.name || record.doubleOptInNotificationId}</span>
              </>}
              {!record.doubleOptIn && <CloseCircleTwoTone twoToneColor="#CFD8DC" />}
            </>
          },
          {
            id: 'channels',
            title: t('channels', "Channels"),
            content: <>
              <div><FontAwesomeIcon icon="user" style={{ opacity: '0.7' }} className="margin-r-s" /> {t('x_unique_subscribers', '{x} unique subscribers', { x: N(record.uniqueSubscribers || 0).format('0,0') })}</div>
              {record.withEmail && <div><FontAwesomeIcon icon="envelope" style={{ opacity: '0.7' }} className="margin-r-s" /> {N(record.emailSubscribers || 0).format('0,0')}</div>}
              {record.withSMS && <div><FontAwesomeIcon icon="mobile" style={{ width: '14px', opacity: '0.7' }} className="margin-r-s" /> {N(record.smsSubscribers || 0).format('0,0')}</div>}
            </>
          },
        ]}
        pagination={false}
        columns={[
          {
            title: '',
            key: 'title',
            width: '160px',
            render: (record: any) => <span className="subsider-attribute-key">{record.title}</span>
          },
          {
            title: '',
            key: 'content',
            render: (record: any) => record.content
          },
        ]} />
    </div>
    )}
  </div>
}

export default NotificationTopicsList