import { useCallback, useState, useEffect, createContext, useContext, useRef, MutableRefObject } from 'react'

import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
  useHistory,
} from 'react-router-dom'

import { Spin, notification, message, ConfigProvider } from 'antd'
import enUS from 'antd/lib/locale-provider/en_US'
import Config from '../../config'
import Axios from 'axios'
import Moment from 'moment-timezone'
import Numeral from 'numeral'
import { initializeApp, FirebaseApp } from '@firebase/app'
import { Auth, getAuth, signInWithCustomToken, User as FirebaseUser } from 'firebase/auth'
import Cookies from '../../utils/cookies'
import { get } from 'lodash'

import translations from '../../translations'
import Cache from '../../utils/cache'

import Splash from './_splash'
import Login from './login'
// import SignUp from './signUp'
import SignOut from './signOut'
import Invitation from './invitation'
import FirebaseAuthAction from './firebaseAuthAction'
// import CheckYourMailbox from '../admins/checkYourMailbox'

// import Onboarding from '../onboarding/index'
import OrganizationsList from '../organizations/list'
import CreateOrganization from '../organizations/create'
import OrganizationIndex from '../organizations'

import AmazonRedirectUri from '../integrations/_amazonRedirectUri'
import ShopifyRedirectUri from '../integrations/_shopifyRedirectUri'
import MailchimpRedirectUri from '../integrations/_mailchimpRedirectUri'
import LazadaRedirectUri from '../integrations/_lazadaRedirectUri'

import { Admin, Organization } from './interfaces'

require('numeral/locales/fr')

// GoogleMapsLoader.KEY = Config().gmap_key
// window.fapp = 

const navigatorLanguage = ((navigator.language || navigator.userLanguage).indexOf('fr') !== -1) ? 'fr' : 'en'

// global cache
window.cmCache = Cache()


const AppContext = createContext<AppContextValue | null>(null);

export function useAppContext(): AppContextValue {
  const appValue = useContext(AppContext);
  if (!appValue) {
    throw new Error("Missing AppContextProvider in its parent.");
  }
  return appValue;
}

export interface AppContextValue {
  firebaseApp: MutableRefObject<FirebaseApp>
  firebaseAuth: MutableRefObject<Auth>
  firebaseUser: FirebaseUser
  loadingUser: boolean
  ajaxRequest: (options: any, callback?: Function) => void
  signOut: Function,
  signIn: (user: FirebaseUser, redirectTo?: string, forceRefresh?) => void

  admin: Admin
  setAdmin: (admin: Admin) => void

  organizations: Organization[]
  setOrganizations: (organizations: Organization[]) => void
}


const App = () => {

  const firebaseApp: MutableRefObject<FirebaseApp> = useRef(initializeApp({
    apiKey: Config().firebase_web_api_key,
    authDomain: Config().firebase_auth_domain,
    databaseURL: Config().firebase_database_url,
    projectId: Config().firebase_project_id,
    storageBucket: Config().firebase_storage_bucket,
    messagingSenderId: Config().firebase_sender_id,
    appId: Config().firebase_app_id,
  }))
  const auth: MutableRefObject<Auth> = useRef(getAuth(firebaseApp.current))

  const [loadingUser, setLoadingUser] = useState(true)
  const [firebaseUser, setFirebaseUser] = useState<FirebaseUser | undefined>(undefined)
  const [admin, setAdmin] = useState<Admin | undefined>(undefined)
  const [organizations, setOrganizations] = useState<Organization[]>([])
  const [signingIn, setSigningIn] = useState(false)
  const [signOutLoading, setSignOutLoading] = useState(false)
  const listenUserAuth = useRef(false)

  const history = useHistory() as any
  const location = useLocation() as any
  // const prevPathname = useRef(undefined)

  const signIn = useCallback((user: FirebaseUser, redirectTo?: string, forceRefresh?: boolean) => {

    setSigningIn(true)
    // console.log('sign in user', FirebaseUser);
    // https://firebase.google.com/docs/reference/js/firebase.User#getIdToken
    user.getIdToken(forceRefresh).then((token: any) => {

      // log the server-side
      let data = {
        token: token,
        timezone: Moment.tz.guess(),
        language: navigatorLanguage
      }

      Axios({
        method: 'post',
        url: Config().api_endpoint + '/admins.login',
        data: data,
        // withCredentials: true
      })
        .then((response: any) => {
          setFirebaseUser(user)
          setAdmin(response.data.admin)
          setOrganizations(response.data.organizations || [])
          setLoadingUser(false)

          if (window.cmAgent) {
            window.cmAgent.setUserId(response.data.admin.id)
            window.cmAgent.event({
              label: 'login',
              props: {
                userId: response.data.admin.id,
                email: response.data.admin.primaryEmail,
              }
            })
            window.cmAgent.dispatch()
          }

          setSigningIn(false)

          // warmup cube
          Axios({
            method: 'get',
            url: Config().analytics_endpoint.replace('cubejs-api/v1', 'readyz'),
          }).catch((e: any) => console.error(e))

          // ONBOARDING REDIRECTIONS

          if (location.pathname.indexOf('/invitation') === -1) {

            // if (response.data.admin.emails[response.data.admin.primaryEmail] !== true && location.pathname.indexOf('check-your-mailbox') === -1) {
            //   return window.location.href = window.location.origin + '/check-your-mailbox'
            // }

            if (redirectTo) {
              return history.push(redirectTo)
            }
          }

          // const params: any = QS.parse(location.search, { ignoreQueryPrefix: true })

          // if (!params.token && findOnboardingRoute(response.data.organizations, response.data.advertiserPrograms)) {
          //   history.push('/onboarding')
          // }
        })
        .catch((error: any) => {
          console.error(error)
          let msg = error.message

          if (error.response && error.response.status <= 500) {
            msg = error.response.data.message
          }

          message.error(msg)
          setSigningIn(false)
        })

    })
      .catch((error: any) => {
        console.log(error)
        setSigningIn(false)
      })
  }, [history, location.pathname])


  // did mount
  useEffect(() => {
    // console.log('did munt app idnex')

    if (listenUserAuth.current === true) return
    listenUserAuth.current = true

    auth.current.onAuthStateChanged((firebaseUser: FirebaseUser) => {
      // console.log('firebaseUser', firebaseUser)

      const adminToken = Cookies.getItem('impersonateUser')
      if (adminToken) {
        signInWithCustomToken(auth.current, adminToken).then((userCredential) => {
          if (!signingIn) signIn(userCredential.user)
        })
          .catch((error: any) => {
            message.error(error.message + ' - code: ' + error.code)
            setLoadingUser(false)
          })
      } // Check if we are already signed-in Firebase with the correct user.
      else if (firebaseUser) {
        // only signin users with a name (name is set in sign up form asynchronously)
        if (!location.pathname.includes('/sign-out') && !signingIn) signIn(firebaseUser)
      } else {
        // User is signed out.
        setLoadingUser(false)
      }
    })

  }, [auth, signIn, location.pathname, signingIn])



  const ajaxRequest = useCallback((options: any, callback: any) => {

    firebaseUser.getIdToken().then((token: any) => {

      options.url = Config().api_endpoint + options.url
      options.headers = { Authorization: 'Bearer ' + token }
      options.withCredentials = true

      Axios(options).then((response: any) => {
        callback(null, response)
      })
        .catch((error: any) => {

          console.log(error)

          let message = error.message

          if (error.response && error.response.status === 400) {
            switch (error.response.data.message) {
              default:
                message = error.response.data.message
            }
          }

          callback(message)
        })
    })
      .catch((error: any) => {
        addNotification('error', 'Authentication error', error.message)
      })
  }, [firebaseUser])


  const signOut = () => {
    if (signOutLoading) return

    setSignOutLoading(true)

    // console.log('signout called')
    setFirebaseUser(undefined)
    setAdmin(undefined)
    setOrganizations([])

    auth.current.signOut().then(() => {
      // console.log('signout done')
      history.push('/')
    }).catch((error: any) => {
      console.error(error)
    })
  }

  const addNotification = (type: string, message: any, description: any, duration = 4.5) => {
    switch (type) {
      case 'warning':
        notification.warning({
          message: message,
          description: description,
          duration: duration
        })
        break
      case 'success':
        notification.success({
          message: message,
          description: description,
          duration: duration
        })
        break
      case 'error':
        notification.error({
          message: message,
          description: description,
          duration: duration
        })
        break
      default:
    }
  }

  const addMessage = (type: string, msg: any, duration = 4.5) => {
    switch (type) {
      case 'warning':
        message.warning(msg, duration)
        break
      case 'success':
        message.success(msg, duration)
        break
      case 'error':
        message.error(msg, duration)
        break
      default:
    }
  }


  // stopImpersonating() {
  //   const cookieHost = (window.location.host.includes('localhost')) ? 'localhost' : '.captainmetrics.com'

  //   Cookies.removeItem('impersonateUser', '/', cookieHost)
  //   document.location.reload()

  const AppProps = {
    firebaseApp: firebaseApp.current,
    ajaxRequest: ajaxRequest,
    addNotification: addNotification,
    addMessage: addMessage,
    state: {
      firebaseUser: firebaseUser,
      admin: admin,
      organizations: organizations,
    },
    signOut: signOut,
  }

  // console.log('AppProps', AppProps)
  // const currentOrganization = useMemo(() => organizations.find((x: Organization) => x.id === currentOrganizationId), [organizations, currentOrganizationId])
  // const currentProject = useMemo(() => projects.find((x: Project) => x.id === currentProjectId), [projects, currentProjectId])

  const appCtx: AppContextValue = {
    firebaseApp: firebaseApp,
    firebaseAuth: auth,
    firebaseUser: firebaseUser,
    loadingUser: loadingUser,
    signIn: signIn,
    signOut: signOut,
    ajaxRequest: ajaxRequest,

    admin: admin,
    setAdmin: setAdmin,

    organizations: organizations as Organization[],
    setOrganizations: setOrganizations,
  }

  // console.log('appCtx', appCtx);
  // console.log('location', location)

  let locale = get(admin, 'language', navigatorLanguage)

  // locale = 'en'
  Moment.locale(locale)
  Numeral.locale(locale)
  window.app = translations[locale]

  if (location.pathname.includes('/sign-out')) {
    return <ConfigProvider locale={enUS}>
      <AppContext.Provider value={appCtx}>
        <SignOut signOut={signOut} />
      </AppContext.Provider>
    </ConfigProvider>
  }

  if (loadingUser === true) {
    return <ConfigProvider locale={enUS}>
      <AppContext.Provider value={appCtx}>
        <Splash h1="Authenticating..."><Spin size="large" /></Splash>
      </AppContext.Provider>
    </ConfigProvider>
  }

  if (location.pathname.includes('/invitation')) {
    return <ConfigProvider locale={enUS}>
      <AppContext.Provider value={appCtx}>
        <Invitation />
      </AppContext.Provider>
    </ConfigProvider>
  }

  if (!admin && !location.pathname.includes('/_auth')) {
    return <ConfigProvider locale={enUS}>
      <AppContext.Provider value={appCtx}>
        <Spin spinning={signingIn}>
          <Login />
        </Spin>
      </AppContext.Provider>
    </ConfigProvider>
  }

  return <Router>
    <ConfigProvider locale={enUS}>
      <AppContext.Provider value={appCtx}>
        <Switch>
          <Route path="/invitation" render={() => <Invitation />} />
          <Route path="/_auth/action" render={() => <FirebaseAuthAction />} />
          {/* <Route path="/check-your-mailbox" render={() => <CheckYourMailbox />} /> */}
          {/* <Route path="/sign-out" render={() => <SignOut />} /> */}

          {admin && <>
            <Route exact path="/" render={() => <OrganizationsList />} />
            <Route path="/create-organization" render={(routerProps) => <CreateOrganization app={AppProps} {...routerProps} />} />
            <Route path="/organizations/:organizationId" render={(routerProps) => <OrganizationIndex app={AppProps} {...routerProps} />} />
            <Route path="/amazon_redirect_uri" render={() => <AmazonRedirectUri app={AppProps} location={location} history={history} addMessage={addMessage} />} />
            <Route path="/shopify_redirect_uri" render={() => <ShopifyRedirectUri />} />
            <Route path="/mailchimp_redirect_uri" render={() => <MailchimpRedirectUri app={AppProps} location={location} history={history} addMessage={addMessage} />} />
            <Route path="/lazada_redirect_uri" render={() => <LazadaRedirectUri app={AppProps} location={location} history={history} addMessage={addMessage} />} />
          </>}
          <Route render={() => <div>Route not found :(</div>} />
        </Switch>
      </AppContext.Provider>
    </ConfigProvider>
  </Router>
}

// const AdminSwitch = (props: any) => {
//   return <div>{props.children}
//     {Cookies.getItem('impersonateUser') && <Button danger={true} className="admin-switch" onClick={props.stopImpersonating}>Stop admin session</Button>}
//   </div>
// }

export default App