import { useState, ReactNode } from "react"
import { Organization, Project, Admin } from './interfaces'
import {
    Menu,
    Dropdown,
    Avatar,
    Select,
    Badge,
} from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DownOutlined, DollarCircleFilled } from '@ant-design/icons'
import { User as FirebaseUser } from 'firebase/auth'
import UserSettings from './_userSettings'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import cn from 'classnames'

export interface AppLayoutProps {
    admin: Admin
    firebaseUser: FirebaseUser
    projects: Project[]
    currentOrganization?: Organization
    currentProject?: Project
    children: ReactNode
}

const AppLayout = (props: AppLayoutProps) => {
    
    const [adminSettingsVisible, setAdminSettingsVisible] = useState(false)
    const history = useHistory() as any
    // const location = useLocation() as any

    const toggleAdminSettings = () => {
        setAdminSettingsVisible(!adminSettingsVisible)
    }

    return <div className="layout">
        <div className="layout-header">

            <div className="margin-h-l" style={{
                display: 'inline-block',
                verticalAlign: 'top',
                paddingTop: '12px',
                height: '60px',
                lineHeight: '60px'
            }}>
                <svg width="42px" height="30px" viewBox="0 0 42 30" version="1.1">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <path d="M27.8421659,3.68421053 L14.2631889,3.68421053 L21.0526774,10.4736842 L18.4210927,13.1052632 L14.2622975,8.94647697 L14.2622975,17.946477 L21.0526774,24.7368421 L27.8531213,17.936413 L27.8531213,8.93641301 L21.0526774,15.7368421 L18.4210927,13.1052632 L27.8421659,3.68421053 Z M10.5406748,3.68421053 L10.5263387,3.68421053 L5.26316935,8.94736842 L10.5406748,14.2248623 L10.5406748,3.68421053 L10.5406748,3.68421053 Z M31.5747441,3.68421053 L31.5747441,14.2147984 L36.8421855,8.94736842 L31.5790161,3.68421053 L31.5747441,3.68421053 L31.5747441,3.68421053 Z M21.0526774,30 L0,8.94736842 L8.9473879,0 L33.1579669,8.46197199e-15 L42.1053548,8.94736842 L21.0526774,30 Z" fill="#FFFFFF"></path>
                    </g>
                </svg>
            </div>

            {props.currentOrganization.kind === 'business' && <TopMenuBusiness {...props} />}
            {props.currentOrganization.kind === 'mediaPartner' && <TopMenuMediaPartner {...props} />}

            <div className="layout-header-user-container">
                {adminSettingsVisible && <UserSettings toggleSettings={toggleAdminSettings} />}
                <Dropdown overlay={<Menu>
                    <Menu.Item key="user-settings" onClick={toggleAdminSettings}><div className="margin-a-s"><FontAwesomeIcon icon="cog" className="margin-r-s" /> My settings</div></Menu.Item>
                    <Menu.Item key="logout"><div className="margin-a-s" onClick={() => { history.push('/sign-out') }}><FontAwesomeIcon icon="power-off" className="margin-r-s" /> Sign out</div></Menu.Item>
                </Menu>}>
                    <div className="layout-header-user">
                        <div className="layout-header-user-picture">
                            {props.firebaseUser.photoURL ?
                                <Avatar src={props.firebaseUser.photoURL} />
                                :
                                <span style={{ fontSize: '32px', verticalAlign: 'middle' }}><FontAwesomeIcon icon="user-circle" /></span>
                            }
                        </div>
                        <div className="layout-header-user-name">
                            <div className="layout-header-user-name-top">
                                {props.admin.firstName + ' ' + props.admin.lastName}<br />
                                <span className="layout-header-user-name-sub"><FontAwesomeIcon icon="clock" className="margin-r-s" />{props.admin.timezone}</span>
                            </div>
                        </div>
                        <div className="layout-header-user-button"><DownOutlined className="margin-l-s" /></div>
                    </div>
                </Dropdown>
            </div>
        </div>

        <div className="layout-aside">
            <div className="layout-main">
                <div className="layout-content">{props.children}</div>
            </div>
        </div>
    </div>
}

const TopMenuBusiness = (props: AppLayoutProps) => {

    const history = useHistory() as any
    const location = useLocation() as any
    const routeParams = useParams() as any

    const goTo = (params: any) => {
        let route = params.key
        // console.log(params)
        if (route.includes(':organizationId')) {
            route = route.replace(':organizationId', routeParams.organizationId)
        }
        if (route.includes(':projectId')) {
            route = route.replace(':projectId', routeParams.projectId)
        }
        history.push(route)
    }

    const path = location.pathname
    let selectedMenu: any
    let selectedItem: any

    // organization paths

    if (path.includes('/organizations/')) {
        selectedItem = '/organizations/:organizationId'
        selectedMenu = 'organization'
    }

    if (path.includes('/projects')) {
        selectedItem = '/organizations/:organizationId/projects'
        selectedMenu = 'organization'
    }

    if (path.includes('/admins')) {
        selectedItem = '/organizations/:organizationId/admins'
        selectedMenu = 'organization'
    }

    if (path.includes('/settings')) {
        selectedItem = '/organizations/:organizationId/settings'
        selectedMenu = 'organization'
    }

    if (path.includes('/global-stats')) {
        selectedItem = '/organizations/:organizationId/global-stats'
        selectedMenu = 'organization'
    }

    if (path.includes('/billing')) {
        selectedItem = '/organizations/:organizationId/billing'
        selectedMenu = 'organization'
    }

    // project paths

    if (path.includes('/projects/')) {
        selectedItem = '/organizations/:organizationId/projects/:projectId'
        selectedMenu = 'analytics'
    }

    if (/\/projects\/(.*)[^/]\/setup/.test(path) === true) {
        selectedItem = '/organizations/:organizationId/projects/:projectId/setup'
        selectedMenu = 'setup'
    }

    if (/\/projects\/(.*)[^/]\/settings/.test(path) === true) {
        selectedItem = '/organizations/:organizationId/projects/:projectId/settings'
        selectedMenu = 'system'
    }

    if (/\/projects\/(.*)[^/]\/media-partners/.test(path) === true) {
        selectedItem = '/organizations/:organizationId/projects/:projectId/media-partners'
        selectedMenu = 'media-partners'
    }

    if (/\/projects\/(.*)[^/]\/commissions/.test(path) === true) {
        selectedItem = '/organizations/:organizationId/projects/:projectId/commissions'
        selectedMenu = 'media-partners'
    }

    if (/\/projects\/(.*)[^/]\/commission-groups/.test(path) === true) {
        selectedItem = '/organizations/:organizationId/projects/:projectId/commission-groups'
        selectedMenu = 'media-partners'
    }

    if (/\/projects\/(.*)[^/]\/payments/.test(path) === true) {
        selectedItem = '/organizations/:organizationId/projects/:projectId/payments'
        selectedMenu = 'media-partners'
    }

    if (/\/projects\/(.*)[^/]\/integrations/.test(path) === true) {
        selectedItem = '/organizations/:organizationId/projects/:projectId/integrations'
        selectedMenu = 'system'
    }

    if (/\/projects\/(.*)[^/]\/tasks/.test(path) === true) {
        selectedItem = '/organizations/:organizationId/projects/:projectId/tasks'
        selectedMenu = 'system'
    }

    if (/\/projects\/(.*)[^/]\/domains/.test(path) === true) {
        selectedItem = '/organizations/:organizationId/projects/:projectId/domains'
        selectedMenu = 'system'
    }

    if (/\/projects\/(.*)[^/]\/channels/.test(path) === true) {
        selectedItem = '/organizations/:organizationId/projects/:projectId/channels'
        selectedMenu = 'system'
    }

    if (/\/projects\/(.*)[^/]\/conversion-rules/.test(path) === true) {
        selectedItem = '/organizations/:organizationId/projects/:projectId/conversion-rules'
        selectedMenu = 'system'
    }

    if (/\/projects\/(.*)[^/]\/logs/.test(path) === true) {
        selectedItem = '/organizations/:organizationId/projects/:projectId/logs'
        selectedMenu = 'system'
    }

    if (/\/projects\/(.*)[^/]\/users/.test(path) === true) {
        selectedItem = '/organizations/:organizationId/projects/:projectId/users'
        selectedMenu = 'data'
    }


    if (/\/projects\/(.*)[^/]\/companies/.test(path) === true) {
        selectedItem = '/organizations/:organizationId/projects/:projectId/companies'
        selectedMenu = 'data'
    }

    if (path.includes('/orders') === true) {
        selectedItem = '/organizations/:organizationId/projects/:projectId/orders'
        selectedMenu = 'data'
    }

    if (path.includes('/leads') === true) {
        selectedItem = '/organizations/:organizationId/projects/:projectId/leads'
        selectedMenu = 'data'
    }

    if (path.includes('/products') === true) {
        selectedItem = '/organizations/:organizationId/projects/:projectId/products'
        selectedMenu = 'data'
    }

    if (path.includes('/notifications') === true) {
        selectedItem = '/organizations/:organizationId/projects/:projectId/notifications'
        selectedMenu = 'data'
    }

    if (path.includes('/file-manager') === true) {
        selectedItem = '/organizations/:organizationId/projects/:projectId/file-manager'
        selectedMenu = 'data'
    }

    if (path.includes('/analytics/web') === true) {
        selectedItem = '/organizations/:organizationId/projects/:projectId/analytics/web'
        selectedMenu = 'analytics'
    }

    if (path.includes('/analytics/segments') === true) {
        selectedItem = '/organizations/:organizationId/projects/:projectId/analytics/segments'
        selectedMenu = 'analytics'
    }

    if (path.includes('/analytics/media-partners') === true) {
        selectedItem = '/organizations/:organizationId/projects/:projectId/analytics/media-partners'
        selectedMenu = 'analytics'
    }

    if (path.includes('/analytics/notifications') === true) {
        selectedItem = '/organizations/:organizationId/projects/:projectId/analytics/notifications'
        selectedMenu = 'analytics'
    }

    if (path.includes('/automation/workflows') === true) {
        selectedItem = '/organizations/:organizationId/projects/:projectId/automation/workflows'
        selectedMenu = 'automation'
    }

    if (path.includes('/automation/segments-sync') === true) {
        selectedItem = '/organizations/:organizationId/projects/:projectId/automation/segments-sync'
        selectedMenu = 'automation'
    }

    // console.log('selectedItem', selectedItem)

    // const currentProject = props.currentProject && location.pathname.includes('/' + props.currentProject.id) ? props.currentProject : undefined

    return <>
        <Dropdown overlay={<Menu selectedKeys={[selectedItem]}>
            <Menu.Item key="/organizations/:organizationId" onClick={goTo.bind(null, { key: '/organizations/:organizationId' })}><div className="margin-a-s">
                {/* <FontAwesomeIcon icon="folder-open" className="margin-r-s" />  */}
                Manage projects</div></Menu.Item>
            <Menu.Item key="/organizations/:organizationId/admins" onClick={goTo.bind(null, { key: '/organizations/:organizationId/admins' })}><div className="margin-a-s">
                {/* <FontAwesomeIcon icon="user" className="margin-r-s" />  */}
                Admins</div></Menu.Item>
            <Menu.Item key="/organizations/:organizationId/settings" onClick={goTo.bind(null, { key: '/organizations/:organizationId/settings' })}><div className="margin-a-s">
                {/* <FontAwesomeIcon icon="cog" className="margin-r-s" />  */}
                Settings</div></Menu.Item>
            <Menu.Item key="/organizations/:organizationId/global-stats" onClick={goTo.bind(null, { key: '/organizations/:organizationId/global-stats' })}><div className="margin-a-s">
                {/* <FontAwesomeIcon icon="chart-area" className="margin-r-s" />  */}
                Global stats</div></Menu.Item>
            <Menu.Item key="go-to-project" disabled>
                <div className="padding-v-xs">
                    <Select placeholder="Go to project..." style={{ width: '100%' }} value={undefined} onChange={(projectId) => {
                        history.push('/organizations/' + props.currentOrganization.id + '/projects/' + projectId)
                    }}>
                        {(props.projects.filter((p: any) => !p.deletedAt).map((pg: any) => <Select.Option key={pg.id} value={pg.id}>{pg.name}</Select.Option>))}
                    </Select>
                </div>
            </Menu.Item>
            <Menu.Divider key="divider" />
            <Menu.Item key="/organizations" onClick={() => history.push('/')}><div className="margin-a-s">Switch organization</div></Menu.Item>
        </Menu>}>
            <div className={cn('layout-header-item', { 'selected': selectedMenu === 'organization' })}>
                <div className="layout-header-item-name">
                    <div className="layout-header-item-name-top">
                        Organization › Project<br />
                        <span className="layout-header-item-name-sub">
                            {props.currentOrganization.name} {(props.currentProject) ? '› ' + props.currentProject.name : ''}
                        </span>
                    </div>
                </div>
                <div className="layout-header-item-icon"><DownOutlined className="margin-l-s" /></div>
            </div>
        </Dropdown>

        {(props.currentProject && props.currentProject.status === "setup") && <>
            <div className={cn('layout-header-item', { 'selected': selectedMenu === 'setup' })}>
                <div className="layout-header-item-name">
                    <div className="layout-header-item-name-top">Setup</div>
                </div>
            </div>
        </>}

        {(props.currentProject && props.currentProject.status === "active") && <>
            <Dropdown overlay={<Menu selectedKeys={[selectedItem]}>
                <Menu.Item key="/organizations/:organizationId/projects/:projectId" onClick={goTo.bind(null, { key: '/organizations/:organizationId/projects/:projectId' })}><div className="margin-a-s">Dashboard</div></Menu.Item>
                <Menu.Item key="/organizations/:organizationId/projects/:projectId/analytics/web" onClick={goTo.bind(null, { key: '/organizations/:organizationId/projects/:projectId/analytics/web' })}><div className="margin-a-s">Web</div></Menu.Item>
                <Menu.Item key="/organizations/:organizationId/projects/:projectId/analytics/segments" onClick={goTo.bind(null, { key: '/organizations/:organizationId/projects/:projectId/analytics/segments' })}><div className="margin-a-s">Segments</div></Menu.Item>
                <Menu.Item key="/organizations/:organizationId/projects/:projectId/analytics/media-partners" onClick={goTo.bind(null, { key: '/organizations/:organizationId/projects/:projectId/analytics/media-partners' })}><div className="margin-a-s">Media Partners</div></Menu.Item>
                <Menu.Item key="/organizations/:organizationId/projects/:projectId/analytics/notifications" onClick={goTo.bind(null, { key: '/organizations/:organizationId/projects/:projectId/analytics/notifications' })}><div className="margin-a-s">Notifications</div></Menu.Item>
            </Menu>}>
                <div className={cn('layout-header-item', { 'selected': selectedMenu === 'analytics' })}>
                    <div className="layout-header-item-name">
                        <div className="layout-header-item-name-top"><FontAwesomeIcon className="margin-r-s" icon="chart-area" /> Analytics</div>
                    </div>
                    <div className="layout-header-item-icon"><DownOutlined className="margin-l-s" /></div>
                </div>
            </Dropdown>

            <Dropdown overlay={<Menu selectedKeys={[selectedItem]}>
                <Menu.Item key="/organizations/:organizationId/projects/:projectId/users" onClick={goTo.bind(null, { key: '/organizations/:organizationId/projects/:projectId/users' })}><div className="margin-a-s">Users</div></Menu.Item>
                {props.currentProject.conversionRules.find((cr: any) => !cr.deletedAt && cr.kind === 'order') && <Menu.Item key="/organizations/:organizationId/projects/:projectId/orders" onClick={goTo.bind(null, { key: '/organizations/:organizationId/projects/:projectId/orders' })}><div className="margin-a-s">Orders</div></Menu.Item>}
                {props.currentProject.conversionRules.find((cr: any) => !cr.deletedAt && cr.kind === 'subscription') && <Menu.Item key="/organizations/:organizationId/projects/:projectId/subscriptions" onClick={goTo.bind(null, { key: '/organizations/:organizationId/projects/:projectId/subscriptions' })}><div className="margin-a-s">Subscriptions</div></Menu.Item>}
                {props.currentProject.conversionRules.find((cr: any) => !cr.deletedAt && cr.kind === 'lead') && <Menu.Item key="/organizations/:organizationId/projects/:projectId/leads" onClick={goTo.bind(null, { key: '/organizations/:organizationId/projects/:projectId/leads' })}><div className="margin-a-s">Leads</div></Menu.Item>}
                <Menu.Item key="/organizations/:organizationId/projects/:projectId/products" onClick={goTo.bind(null, { key: '/organizations/:organizationId/projects/:projectId/products' })}><div className="margin-a-s">Products</div></Menu.Item>
                <Menu.Item key="/organizations/:organizationId/projects/:projectId/notifications" onClick={goTo.bind(null, { key: '/organizations/:organizationId/projects/:projectId/notifications' })}><div className="margin-a-s">Notifications</div></Menu.Item>
                <Menu.Item key="/organizations/:organizationId/projects/:projectId/file-manager" onClick={goTo.bind(null, { key: '/organizations/:organizationId/projects/:projectId/file-manager' })}><div className="margin-a-s">File manager</div></Menu.Item>
            </Menu>}>
                <div className={cn('layout-header-item', { 'selected': selectedMenu === 'data' })}>
                    <div className="layout-header-item-name">
                        <div className="layout-header-item-name-top"><FontAwesomeIcon className="margin-r-s" icon="layer-group" /> Data &amp; Assets</div>
                    </div>
                    <div className="layout-header-item-icon"><DownOutlined className="margin-l-s" /></div>
                </div>
            </Dropdown>

            <Dropdown overlay={<Menu selectedKeys={[selectedItem]}>
                <Menu.Item key="/organizations/:organizationId/projects/:projectId/automation/workflows" onClick={goTo.bind(null, { key: '/organizations/:organizationId/projects/:projectId/automation/workflows' })}><div className="margin-a-s">Workflows</div></Menu.Item>
                <Menu.Item key="/organizations/:organizationId/projects/:projectId/automation/segments-sync" onClick={goTo.bind(null, { key: '/organizations/:organizationId/projects/:projectId/automation/segments-sync' })}><div className="margin-a-s">Segments sync</div></Menu.Item>
            </Menu>}>
                <div className={cn('layout-header-item', { 'selected': selectedMenu === 'automation' })}>
                    <div className="layout-header-item-name">
                        <div className="layout-header-item-name-top"><FontAwesomeIcon className="margin-r-s" icon="bolt" /> Automation</div>
                    </div>
                    <div className="layout-header-item-icon"><DownOutlined className="margin-l-s" /></div>
                </div>
            </Dropdown>

            <Dropdown overlay={<Menu selectedKeys={[selectedItem]}>
                <Menu.Item key="/organizations/:organizationId/projects/:projectId/media-partners" onClick={goTo.bind(null, { key: '/organizations/:organizationId/projects/:projectId/media-partners' })}><div className="margin-a-s">Media Partners</div></Menu.Item>
                <Menu.Item key="/organizations/:organizationId/projects/:projectId/commission-groups" onClick={goTo.bind(null, { key: '/organizations/:organizationId/projects/:projectId/commission-groups' })}><div className="margin-a-s">Commission groups</div></Menu.Item>
                <Menu.Item key="/organizations/:organizationId/projects/:projectId/commissions" onClick={goTo.bind(null, { key: '/organizations/:organizationId/projects/:projectId/commissions' })}><div className="margin-a-s">Commissions</div></Menu.Item>
                <Menu.Item key="/organizations/:organizationId/projects/:projectId/payments" onClick={goTo.bind(null, { key: '/organizations/:organizationId/projects/:projectId/payments' })}><div className="margin-a-s">Payments</div></Menu.Item>
            </Menu>}>
                <div className={cn('layout-header-item', { 'selected': selectedMenu === 'media-partners' })}>
                    <div className="layout-header-item-name">
                        <div className="layout-header-item-name-top"><FontAwesomeIcon className="margin-r-s" icon="exchange-alt" /> Partnerships</div>
                    </div>
                    <div className="layout-header-item-icon"><DownOutlined className="margin-l-s" /></div>
                </div>
            </Dropdown>

            <Dropdown overlay={<Menu selectedKeys={[selectedItem]}>
                <Menu.Item key="/organizations/:organizationId/projects/:projectId/channels" onClick={goTo.bind(null, { key: '/organizations/:organizationId/projects/:projectId/channels' })}><div className="margin-a-s">Channels</div></Menu.Item>
                <Menu.Item key="/organizations/:organizationId/projects/:projectId/integrations" onClick={goTo.bind(null, { key: '/organizations/:organizationId/projects/:projectId/integrations' })}><div className="margin-a-s">Integrations</div></Menu.Item>
                <Menu.Item key="/organizations/:organizationId/projects/:projectId/domains" onClick={goTo.bind(null, { key: '/organizations/:organizationId/projects/:projectId/domains' })}><div className="margin-a-s">Domains</div></Menu.Item>
                <Menu.Item key="/organizations/:organizationId/projects/:projectId/conversion-rules" onClick={goTo.bind(null, { key: '/organizations/:organizationId/projects/:projectId/conversion-rules' })}><div className="margin-a-s">Conversion rules</div></Menu.Item>
                <Menu.Item key="/organizations/:organizationId/projects/:projectId/tasks" onClick={goTo.bind(null, { key: '/organizations/:organizationId/projects/:projectId/tasks' })}><div className="margin-a-s">Tasks {props.currentProject.runningTasks?.length > 0 && <Badge status="processing" className="padding-l-s" />}</div></Menu.Item>
                <Menu.Item key="/organizations/:organizationId/projects/:projectId/logs" onClick={goTo.bind(null, { key: '/organizations/:organizationId/projects/:projectId/logs' })}><div className="margin-a-s">Logs</div></Menu.Item>
                <Menu.Item key="/organizations/:organizationId/projects/:projectId/settings" onClick={goTo.bind(null, { key: '/organizations/:organizationId/projects/:projectId/settings' })}><div className="margin-a-s">Settings</div></Menu.Item>
            </Menu>}>
                <div className={cn('layout-header-item', { 'selected': selectedMenu === 'system' })}>
                    <div className="layout-header-item-name">
                        <div className="layout-header-item-name-top"><FontAwesomeIcon className="margin-r-s" icon="cog" /> System</div>
                    </div>
                    <div className="layout-header-item-icon"><DownOutlined className="margin-l-s" /></div>
                </div>
            </Dropdown>
        </>}
    </>
}
const TopMenuMediaPartner = (props: AppLayoutProps) => {

    const history = useHistory() as any
    const location = useLocation() as any

    const goTo = (params: any) => {

        switch (params.key) {
            // organization
            case '/organizations/:organizationId': history.push(params.key.replace(':organizationId', props.currentOrganization.id)); break
            case '/organizations/:organizationId/admins': history.push(params.key.replace(':organizationId', props.currentOrganization.id)); break
            case '/organizations/:organizationId/settings': history.push(params.key.replace(':organizationId', props.currentOrganization.id)); break
            case '/organizations/:organizationId/partnerships': history.push(params.key.replace(':organizationId', props.currentOrganization.id)); break
            case '/organizations/:organizationId/commissions': history.push(params.key.replace(':organizationId', props.currentOrganization.id)); break
            case '/organizations/:organizationId/payments': history.push(params.key.replace(':organizationId', props.currentOrganization.id)); break

            default: alert('Unkown route :('); break
        }
    }

    const path = location.pathname
    let selectedMenu: any
    let selectedItem: any

    // organization paths

    if (path.includes('/organizations/')) {
        selectedItem = '/organizations/:organizationId'
        selectedMenu = 'dashboard'
    }

    if (path.includes('/admins')) {
        selectedItem = '/organizations/:organizationId/admins'
        selectedMenu = 'organization'
    }

    if (path.includes('/settings')) {
        selectedItem = '/organizations/:organizationId/settings'
        selectedMenu = 'settings'
    }

    if (path.includes('/partnerships')) {
        selectedItem = '/organizations/:organizationId/partnerships'
        selectedMenu = 'partnerships'
    }

    if (path.includes('/commissions')) {
        selectedItem = '/organizations/:organizationId/commissions'
        selectedMenu = 'commissions'
    }

    if (path.includes('/payments')) {
        selectedItem = '/organizations/:organizationId/payments'
        selectedMenu = 'payments'
    }

    // console.log('selectedItem', selectedItem)

    return <>
        <Dropdown overlay={<Menu selectedKeys={[selectedItem]}>
            <Menu.Item key="/organizations/:organizationId"><div className="margin-a-s" onClick={goTo.bind(null, { key: '/organizations/:organizationId' })}>Manage projects</div></Menu.Item>
            <Menu.Item key="/organizations/:organizationId/admins"><div className="margin-a-s" onClick={goTo.bind(null, { key: '/organizations/:organizationId/admins' })}>Admins</div></Menu.Item>
            <Menu.Item key="/organizations/:organizationId/settings"><div className="margin-a-s" onClick={goTo.bind(null, { key: '/organizations/:organizationId/settings' })}>Settings</div></Menu.Item>
            <Menu.Item key="go-to-project" disabled>
                <div className="padding-v-xs">
                    <Select placeholder="Go to project..." style={{ width: '100%' }} value={undefined} onChange={(projectId) => {
                        history.push('/organizations/' + props.currentOrganization.id + '/projects/' + projectId)
                    }}>
                        {(props.projects.filter((p: any) => !p.deletedAt).map((pg: any) => <Select.Option key={pg.id} value={pg.id}>{pg.name}</Select.Option>))}
                    </Select>
                </div>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="/organizations"><div className="margin-a-s" onClick={() => history.push('/')}>Switch organization</div></Menu.Item>
        </Menu>}>
            <div className={cn('layout-header-item', { 'selected': selectedMenu === 'organization' })}>
                <div className="layout-header-item-name">
                    <div className="layout-header-user-name-top">
                        Organization<br />
                        <span className="layout-header-user-name-sub">
                            {props.currentOrganization.name}
                        </span>
                    </div>
                </div>
                <div className="layout-header-item-icon"><DownOutlined className="margin-l-s" /></div>
            </div>
        </Dropdown>

        <div className={cn('layout-header-item', { 'selected': selectedMenu === 'dashboard' })} onClick={goTo.bind(null, { key: '/organizations/:organizationId' })}>
            <div className="layout-header-item-name">
                <div className="layout-header-item-name-top"><FontAwesomeIcon className="margin-r-s" icon="th-large" /> Dashboard</div>
            </div>
        </div>

        <div className={cn('layout-header-item', { 'selected': selectedMenu === 'partnerships' })} onClick={goTo.bind(null, { key: '/organizations/:organizationId/partnerships' })}>
            <div className="layout-header-item-name">
                <div className="layout-header-item-name-top"><FontAwesomeIcon className="margin-r-s" icon="exchange-alt" /> Partnerships</div>
            </div>
        </div>

        <div className={cn('layout-header-item', { 'selected': selectedMenu === 'commissions' })} onClick={goTo.bind(null, { key: '/organizations/:organizationId/commissions' })}>
            <div className="layout-header-item-name">
                <div className="layout-header-item-name-top"><FontAwesomeIcon className="margin-r-s" icon="check-double" /> Commissions</div>
            </div>
        </div>

        <div className={cn('layout-header-item', { 'selected': selectedMenu === 'payments' })} onClick={goTo.bind(null, { key: '/organizations/:organizationId/payments' })}>
            <div className="layout-header-item-name">
                <div className="layout-header-item-name-top"><DollarCircleFilled className="margin-r-s" /> Payments</div>
            </div>
        </div>
    </>
}

export default AppLayout