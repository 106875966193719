import React, { useState, Component } from 'react'
import {
  Table,
  Alert,
  Drawer,
  Form,
  Input,
  Button,
  InputNumber,
  Select,
  Radio,
  Divider,
  Row,
  Tag,
  Modal,
  Col,
  Switch,
  Spin,
  // Slider,
} from 'antd';
import { DeleteOutlined, PlusOutlined, SearchOutlined, CloseOutlined, TagFilled } from '@ant-design/icons';
import t from '../../utils/translate'
import _ from 'lodash'

const { Option } = Select;

class PartnershipRemoteSelect extends Component<any, any> {
  lastFetchId = 0;

  constructor(props: any) {
    super(props);

    this.state = {
      data: [],
      value: props.value,
      loading: false,
    }

    // console.log('props', props);
    this.fetchPartnership = _.debounce(this.fetchPartnership, 800);
  }


  fetchPartnership = (value: any) => {
    // console.log('fetching partnership', value);

    this.lastFetchId += 1;
    const fetchId = this.lastFetchId;
    this.setState({ data: [], loading: true });

    this.props.app.ajaxRequest({
      method: 'get',
      url: '/partnerships.list',
      params: {
        projectId: this.props.project.id,
        partnershipId: value,
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }

      if (fetchId !== this.lastFetchId) {
        // for fetch callback order
        return;
      }

      const data = response.data.partnerships.map((p: any) => p.id)

      this.setState({ data, loading: false });
    })
  };

  handleChange = (value: any) => {
    this.setState({
      value,
      data: [],
      loading: false,
    });

    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  render() {
    const { loading, data, value } = this.state;
    return (
      <Select
        value={value}
        placeholder="Search Media Partner ID"
        notFoundContent={loading ? <Spin size="small" /> : null}
        filterOption={false}
        showSearch
        suffixIcon={loading ? <Spin size="small" /> : <SearchOutlined />}
        onSearch={this.fetchPartnership}
        onChange={this.handleChange}
        style={{ width: '100%' }}
      >
        {data.map((value: any) => (
          <Option key={value} value={value}>{value}</Option>
        ))}
      </Select>
    );
  }
}


const AddSourceMediumButton = ({ channels, onComplete }: any) => {
  const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)

  const onClicked = () => {
    setModalVisible(true)
  }

  return <React.Fragment>
    <Button type="primary" size="small" block ghost onClick={onClicked}>{t('add', "Add")}</Button>

    <Modal
      visible={modalVisible}
      title={t('add_source_medium', "Add source / medium")}
      okText={t('confirm', "Confirm")}
      width={700}
      cancelText={t('cancel', "Cancel")}
      onCancel={() => { setModalVisible(false) }}
      onOk={() => {
        form.validateFields().then((values: any) => {
          // console.log('onComplete', values);
          form.resetFields()
          setModalVisible(false)
          onComplete(values)
        })
          .catch(info => { console.log('Validate Failed:', info); });
      }}
    >
      <Form
        form={form}
        name="form_add_source"
      >
        <Form.Item shouldUpdate>
          {(_funcs) => {
            // console.log('values', funcs.getFieldsValue());

            // const values = funcs.getFieldsValue()

            return <>
              <Form.Item noStyle label="Source / Medium" name="value" rules={[
                { required: true, type: 'string' },
                {
                  validator: (rule, value) => {
                    if (!value) return Promise.resolve(undefined);

                    const parts = value.split(' / ')
                    if (parts.length < 2 || parts[1] === '') {
                      return Promise.reject(t('medium_required', 'The "medium" field is required!'));
                    }
                    if (parts[0] === '') {
                      return Promise.reject(t('source_required', 'The "source" field is required!'));
                    }

                    // check if this source/medium is already in another channel
                    const foundChannel = _.find(channels, ch => ch.sourceMediumPaths?.find((p: any) => p.value === value))

                    if (foundChannel) {
                      return Promise.reject(t('source_medium_already_mapped', 'This "source / medium" is already mapped in the channel {channel}!', { channel: foundChannel.name }))
                    }

                    return Promise.resolve(undefined);
                  }
                },
              ]}>
                <SourceMediumInput />
              </Form.Item>

              {/* <Input.Group compact style={{ display: 'inline-block', width: '28%', marginLeft: '2%' }}>
                <Form.Item noStyle label="Campaign (optional)" name="campaign" rules={[
                  { required: false, type: 'string' },
                  {
                    validator: (rule, value) => {
                      if (!value) return Promise.resolve(undefined);

                      const parts = values.value
                      if (parts.length < 2 || parts[1] === '') {
                        return Promise.reject(t('medium_required', 'The "medium" field is required!'));
                      }
                      if (parts[0] === '') {
                        return Promise.reject(t('source_required', 'The "source" field is required!'));
                      }

                      // check if this source/medium is already in another channel
                      const foundChannel = _.find(channels, ch => ch.sourceMediumPaths?.find((p: any) => {
                        if (p.value !== values.value) {
                          return false
                        }

                        if (value && value !== '' && (p.campaign !== value)) {
                          return false
                        }

                        return true
                      }))

                      if (foundChannel) {
                        return Promise.reject(t('source_medium_already_mapped', 'This "source / medium" is already mapped in the channel {channel}!', { channel: foundChannel.name }))
                      }

                      return Promise.resolve(undefined);
                    }
                  },
                ]}>
                  <Input placeholder="Campaign (optional)" />
                </Form.Item>
              </Input.Group> */}
            </>
          }}
        </Form.Item>

      </Form>
    </Modal>
  </React.Fragment>
}


const AddVoucherButton = ({ sourceMediumPaths, onComplete }: any) => {
  const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)

  const onClicked = () => {
    setModalVisible(true)
  }

  return <React.Fragment>
    <Button type="primary" size="small" block ghost onClick={onClicked}>{t('add', "Add")}</Button>

    <Modal
      visible={modalVisible}
      title={t('add_voucher_code', "Add voucher code")}
      okText={t('confirm', "Confirm")}
      cancelText={t('cancel', "Cancel")}
      onCancel={() => { setModalVisible(false) }}
      onOk={() => {
        form.validateFields().then((values: any) => {
          // console.log('onComplete', values);
          form.resetFields()
          setModalVisible(false)
          onComplete(values)
        })
          .catch(info => { console.log('Validate Failed:', info); });
      }}
    >
      <Form form={form} name="form_add_voucher" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
      >
        <Form.Item label="Voucher code" name="code" rules={[{ required: true, type: 'string' }]}>
          <Input type="text" />
        </Form.Item>

        <Form.Item label={t('attribute_voucher_code_to', 'Attribute to')} name="sourceMediumPath" rules={[{ required: true, type: 'string' }]}>
          <Select>{sourceMediumPaths.map((path: any) => <Option key={path.value} value={path.value}>{path.value}</Option>)}</Select>
        </Form.Item>

        <Form.Item label="utm_campaign" name="campaignId" rules={[{ required: false, type: 'string' }]}>
          <Input type="text" />
        </Form.Item>

        <Form.Item label="utm_content" name="adId" rules={[{ required: false, type: 'string' }]}>
          <Input type="text" />
        </Form.Item>

        <Form.Item label="Description" name="description" rules={[{ required: false, type: 'string' }]}>
          <Input type="text" />
        </Form.Item>
      </Form>
    </Modal>
  </React.Fragment>
}


class SourceMediumInput extends Component<any, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      source: '',
      medium: '',
      campaign: '',
    }

    this.updateField = this.updateField.bind(this)
  }

  updateField(fieldName: string, e: any) {
    if (fieldName === 'source') {
      this.setState({ source: e.target.value })
      this.props.onChange(e.target.value + ' / ' + this.state.medium + ((this.state.campaign && this.state.campaign.length > 0) ? ' / ' + this.state.campaign : ''))
    }
    if (fieldName === 'medium') {
      this.setState({ medium: e.target.value })
      this.props.onChange(this.state.source + ' / ' + e.target.value + ((this.state.campaign && this.state.campaign.length > 0) ? ' / ' + this.state.campaign : ''))
    }
    if (fieldName === 'campaign') {
      this.setState({ campaign: e.target.value })
      this.props.onChange(this.state.source + ' / ' + this.state.medium + ' / ' + e.target.value)
    }
  }

  render() {
    return <Input.Group compact style={{ display: 'inline-block', width: '100%' }}>
      <Input required type="text" style={{ width: '28%' }} placeholder="source" value={this.state.source} onChange={this.updateField.bind(null, 'source')} />
      <Input style={{ width: '8%', borderLeft: 0, textAlign: 'center', pointerEvents: 'none', backgroundColor: '#fff' }} placeholder="/" disabled />
      <Input required type="text" style={{ width: '28%', borderLeft: 0 }} placeholder="medium" value={this.state.medium} onChange={this.updateField.bind(null, 'medium')} />
      <Input style={{ width: '8%', borderLeft: 0, textAlign: 'center', pointerEvents: 'none', backgroundColor: '#fff' }} placeholder="/" disabled />
      <Input type="text" style={{ width: '28%', borderLeft: 0 }} placeholder="optional campaign" value={this.state.campaign} onChange={this.updateField.bind(null, 'campaign')} />
    </Input.Group>
  }
}


const VoucherCodesInput = ({ sourceMediumPaths, value, onChange }: any) => {

  const removeVoucher = (index: number) => {
    let vouchers = value.slice()
    vouchers.splice(index, 1)
    onChange(vouchers)
  }

  return <div>
    {(value && value.length > 0) && <Table
      size="middle"
      bordered={false}
      pagination={false}
      rowKey="code"
      showHeader={false}
      className="margin-b-m"
      columns={[
        {
          title: '',
          key: 'code',
          render: (item: any) => <span><Tag><TagFilled /> {item.code}</Tag> &rarr; {item.sourceMediumPath}</span>
        },
        {
          title: '',
          key: 'description',
          render: (item: any) => item.description
        },
        {
          title: '',
          key: 'remove',
          render: (_text, _record:any , index: number) => {
            return (
              <div className="text-right">
                <Button type="dashed" size="small" onClick={removeVoucher.bind(null, index)}>
                  <CloseOutlined />
                </Button>
              </div>
            );
          }
        }
      ]}
      dataSource={value} />
    }

    <AddVoucherButton sourceMediumPaths={sourceMediumPaths} onComplete={(values: any) => {
      let vouchers = value.slice()
      if (!vouchers.find((v: any) => v.code === values.code)) {
        vouchers.push(values)
        onChange(vouchers)
      }
    }} />
  </div>
}

class SourceMediumPathsInput extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.removeSourceMediumPath = this.removeSourceMediumPath.bind(this)
  }

  removeSourceMediumPath(index: number) {
    let paths = this.props.value.slice()
    paths.splice(index, 1)
    this.props.onChange(paths)
  }

  render() {
    return <div>
      {(this.props.value && this.props.value.length > 0) && <Table
        size="middle"
        bordered={false}
        pagination={false}
        rowKey={r => r.value + (r.campaign || '')}
        showHeader={false}
        className="margin-b-m"
        columns={[{
          title: '',
          key: 'path',
          render: (item: any) => item.value
        }, {
          title: '',
          key: 'remove',
          render: (_text, _record:any , index: number) => {
            return (
              <div className="text-right">
                <Button type="dashed" size="small" onClick={this.removeSourceMediumPath.bind(null, index)}>
                  <CloseOutlined />
                </Button>
              </div>
            );
          }
        }]}
        dataSource={this.props.value} />
      }

      <AddSourceMediumButton channels={this.props.channels} onComplete={(values: any) => {
        let paths = this.props.value.slice()
        paths.push(values)
        this.props.onChange(paths)
      }} />
    </div>
  }
}


const TheForm = ({ btnType, btnSize, btnContent, project, app, organizationLayout, channel, onComplete }: any) => {
  const [form] = Form.useForm()
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const closeDrawer = () => {
    setDrawerVisible(false)
  }

  const addTier = (field: any) => {
    const values = form.getFieldsValue()
    const lastTier: any = _.last(values.customCommissionSystem.conversionRules[field.name].tiers)

    if (values.customCommissionSystem.conversionRules[field.name].commissionType === 'percentage') {
      values.customCommissionSystem.conversionRules[field.name].tiers.push({
        threshold: lastTier.threshold + 10,
        rate: lastTier.rate + 100,
      })
    } else {
      values.customCommissionSystem.conversionRules[field.name].tiers.push({
        threshold: lastTier.threshold + 10,
        amount: lastTier.amount + 100,
      })
    }

    form.setFieldsValue(values)
  }

  const generateId = (name: string, existingChannels: any, increment: number):string => {
    let id: string = _.kebabCase(name)

    if (_.find(existingChannels, x => x.id === id)) {
      increment = increment + 1

      id = name + increment

      // check with new increment
      if (_.find(existingChannels, x => x.id === id)) {
        return generateId(name, existingChannels, increment)
      }
    }

    return id
  }

  const onFinish = (values: any) => {
    // console.log('values', values);

    if (loading) return

    setLoading(true)

    if (channel) {
      values.channelId = channel.id
    } else {
      values.channelId = generateId(values.name, project.channels, 1)
    }

    values.projectId = project.id

    app.ajaxRequest({
      method: 'post',
      url: channel ? '/channels.update' : '/channels.create',
      data: values
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        app.addMessage('error', errorMessage)
        setLoading(false)
        return
      }

      if (channel) {
        app.addMessage('success', t('channel_updated', 'The channel has successfully been updated.'))
      } else {
        app.addMessage('success', t('channel_created', 'The channel has successfully been created.'))
        form.resetFields()
      }

      setLoading(false)

      if (window.cmAgent) {
        // window.cmAgent.event({label: commissionGroup ? 'update_domain' : 'add_domain'})
        // window.cmAgent.dispatch();
      }
      setDrawerVisible(false)

      let updatedProjects = _.filter(organizationLayout.projects, (p: any) => p.id !== project.id)
      updatedProjects.push(response.data.project)
      organizationLayout.setProjects(updatedProjects)

      if (onComplete) {
        onComplete()
      }

    })
  }


  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };

  // console.log('commissionGroup', commissionGroup);

  const initialValues = Object.assign({ sourceMediumPaths: [], voucherCodes: [] }, channel)

  if (!initialValues.customCommissionSystem) {
    initialValues.customCommissionSystem = {
      conversionRules: []
    }
  }

  if (!initialValues.sourceMediumPaths) initialValues.sourceMediumPaths = []
  if (!initialValues.voucherCodes) initialValues.voucherCodes = []

  // inject missing conversion rules
  project.conversionRules.forEach((cr: any) => {
    if (cr.deletedAt) return

    // add cr as non activated
    if (!_.find(initialValues.customCommissionSystem.conversionRules, x => x.conversionRuleId === cr.id)) {
      initialValues.customCommissionSystem.conversionRules.push({
        isActivated: false,
        conversionRuleId: cr.id,
        commissionType: 'percentage',
        tiers: [{ threshold: 0, rate: 500 }]
      })
    }
  })

  // console.log('initialValues', initialValues);

  return <React.Fragment>
    <Button type={btnType || 'primary'} size={btnSize || 'small'} onClick={() => setDrawerVisible(true)}>{btnContent}</Button>

    {drawerVisible && <Drawer
      title={channel ? t('update_channel', "Update channel") : t('create_new_channel', "Create a new channel")}
      width={900}
      visible={true}
      onClose={closeDrawer}
      bodyStyle={{ paddingBottom: 80 }}
      footer={<div style={{ textAlign: 'right' }}>
        <Button loading={loading} onClick={closeDrawer} style={{ marginRight: 8 }}>{t('cancel', "Cancel")}</Button>
        <Button loading={loading} onClick={() => {
          form.submit()
        }} type="primary">{t('confirm', "Confirm")}</Button>
      </div>}
    >
      <Form form={form} initialValues={initialValues} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} name="groupForm" onFinish={onFinish}>

        <Form.Item
          name="name"
          label={t('name', "Name")}
          rules={[{ required: true, type: 'string' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="groupId"
          label={t('group', "Group")}
          rules={[{ required: true, type: 'string' }]}
        >
          <Select>
            {project.channelGroups.filter((g: any) => g.id !== '_id' && g.id !== 'not-mapped' && !g.deletedAt).sort((a: any, b: any) => {
              if (a.createdAt) return -1
              if (b.createdAt) return -1
              return 0
            }).map((group: any) => <Select.Option key={group.id} value={group.id}>
              <Tag color={group.color} style={{ cursor: 'inherit' }}>{group.name}</Tag>
            </Select.Option>)}
          </Select>
        </Form.Item>

        <Divider plain>{t('traffic_mapping', "Traffic mapping")}</Divider>

        <Alert className="margin-b-m" message={t('traffic_mapping_info', 'Traffic coming from these sources will be mapped to this channel.')} type="info" />

        <Form.Item
          name="sourceMediumPaths"
          label={t('source_medium_paths', "Source / Medium paths")}
          rules={[{ required: true, type: 'array', min: 1 }]}
          shouldUpdate
        >
          <SourceMediumPathsInput channels={project.channels} />
        </Form.Item>

        <Divider plain>{t('voucher_code_attribution', "Voucher code attribution")}</Divider>

        <Alert className="margin-b-m" message={t('voucher_code_attribution_info', 'If a conversion contains one of the following voucher code, its last session will be attributed to this channel. This mecanism is used to attribute influencers voucher codes to conversions.')} type="info" />

        <Form.Item shouldUpdate noStyle>
          {(funcs) => {
            return <Form.Item
              name="voucherCodes"
              label={t('voucher_codes', "Voucher codes")}
              rules={[{ required: false, type: 'array', min: 0 }]}
              shouldUpdate
            >
              <VoucherCodesInput sourceMediumPaths={funcs.getFieldValue('sourceMediumPaths')} />
            </Form.Item>
          }}
        </Form.Item>

        {!initialValues.isDefault && <React.Fragment>

          <Divider plain>{t('media_partner', "Media Partner")}</Divider>

          <Form.Item
            name="isMediaPartner"
            label={t('is_media_partner', "Is media partner")}
            rules={[{ required: false, type: 'boolean' }]}
            shouldUpdate
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item shouldUpdate noStyle>
            {(funcs) => {
              if (funcs.getFieldValue('isMediaPartner') === true) {
                return <React.Fragment>
                  <Form.Item
                    name="partnershipId"
                    label={t('media_partner', "Media Partner")}
                    rules={[{ required: true, type: 'string' }]}
                  >
                    <PartnershipRemoteSelect app={app} project={project} />
                  </Form.Item>

                  <Form.Item
                    name="commissionGroupId"
                    label={t('commission_group', "Commission group")}
                    rules={[{ required: true, type: 'string' }]}
                  >
                    <Select>
                      {project.commissionGroups.map((cg: any) => <Select.Option key={cg.id} value={cg.id}><Tag color={cg.color}>{cg.name}</Tag></Select.Option>)}
                    </Select>
                  </Form.Item>

                  {/*<Form.Item 
                label={t('weight', "Weight")}
                name="weight"
                rules={[{ required: true, type: 'number', min: 1, max: 10, message: t('field_required', "This field is required!") }]}
              >
                  <Slider marks={{1: '1',2: '2',3: '3',4: '4',5: '5',6: '6',7: '7',8: '8',9: '9',10: '10'}} dots={true} min={1} max={10} step={1} />
              </Form.Item>*/}

                  <Form.Item
                    label={t('custom_commission', "Custom commission")}
                    name="hasCustomCommission"
                    validateFirst={true}
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>

                  <Form.Item shouldUpdate noStyle>
                    {(funcs) => {
                      if (funcs.getFieldValue('hasCustomCommission') === true) {
                        return <React.Fragment>

                          <Form.Item
                            label={t('has_cpc', "Has CPC")}
                            name={['customCommissionSystem', 'hasCPC']}
                            validateFirst={true}
                            valuePropName="checked"
                          >
                            <Switch />
                          </Form.Item>

                          <Form.Item shouldUpdate noStyle>
                            {(funcs) => {
                              if (funcs.getFieldValue(['customCommissionSystem', 'hasCPC']) === true) {
                                return <Form.Item
                                  labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                                  label="CPC"
                                  name={['customCommissionSystem', 'cpc']}
                                  validateFirst={true}
                                  rules={[{ required: true, type: 'number', min: 1, message: t('field_required', "This field is required") },]}
                                >
                                  <InputNumber style={{ width: 150 }} min={1} precision={2} formatter={(val) => project.currency + ' ' + (val ? val / 100 : 0)} parser={(displayValue: any) => displayValue ? Number(displayValue.replace(project.currency + ' ', '')) * 100 : 0} />
                                </Form.Item>
                              } else {
                                return ''
                              }
                            }}
                          </Form.Item>

                          <Divider plain>{t('conversion_rules', "Conversion rules")}</Divider>

                          <Form.List name={['customCommissionSystem', 'conversionRules']}>

                            {(fields) => <div>
                              {fields.map(field => {
                                const cr = project.conversionRules.find((x: any) => x.id === initialValues.customCommissionSystem.conversionRules[field.name].conversionRuleId)

                                return <div className="paper margin-b-l padding-a-l" key={field.name}>
                                  <Row>
                                    <Col span={12}><h3>{cr && cr.name}</h3></Col>
                                    <Col span={12} className="text-right">
                                      <span className="padding-r-m">{t('activate_commissions', "Activate commissions")}:</span>

                                      <Form.Item shouldUpdate noStyle {...field} valuePropName="checked" name={[field.name, 'isActivated']} fieldKey={[field.fieldKey, 'isActivated']}>
                                        <Switch />
                                      </Form.Item>
                                    </Col>
                                  </Row>

                                  <Form.Item shouldUpdate noStyle>
                                    {(funcs) => {
                                      if (funcs.getFieldValue(['customCommissionSystem', 'conversionRules', field.name, 'isActivated']) === true) {
                                        return <React.Fragment>
                                          <Form.Item {...field} name={[field.name, 'commissionType']} fieldKey={[field.fieldKey, 'commissionType']} noStyle>
                                            <Radio.Group className="margin-t-m">
                                              <Radio style={radioStyle} value="percentage">{t('percentage_of_revenue', "Percentage of revenue")}</Radio>
                                              <Radio style={radioStyle} value="flatAmount">{t('flat_amount', "Flat amount")}</Radio>
                                            </Radio.Group>
                                          </Form.Item>

                                          <Form.Item name={[field.name, 'conversionRuleId']} fieldKey={[field.fieldKey, 'conversionRuleId']} noStyle hidden={true}>
                                            <Input />
                                          </Form.Item>

                                          <Form.List name={[field.name, 'tiers']}>
                                            {(tierFields, tierFuncs) => {
                                              // console.log('tierFields', tierFields);

                                              return <div className="margin-t-m">
                                                <Table size="small" dataSource={tierFields} pagination={false}
                                                  columns={[
                                                    {
                                                      title: t('commission', "Commission"),
                                                      key: 'value',
                                                      render: tierField => {

                                                        return <Form.Item noStyle shouldUpdate>
                                                          {(funcs) => {
                                                            // console.log('values', funcs.getFieldsValue());
                                                            const payout = funcs.getFieldValue(['customCommissionSystem', 'conversionRules', field.fieldKey, 'commissionType'])

                                                            if (payout === 'percentage') {
                                                              return <span>
                                                                <Form.Item isListField={true}
                                                                  name={[tierField.name, 'rate']}
                                                                  fieldKey={[tierField.fieldKey, 'rate']}
                                                                  className="no-margin"
                                                                  rules={[{ required: true, type: 'number', min: 1, message: t('field_required', "This field is required") }]}
                                                                >
                                                                  <InputNumber min={1} style={{ width: 120 }} precision={2} formatter={(val) => (val ? val / 100 : 0) + ' %'} parser={(val: any) => Number(val ? val.replace(' %', '') : 0) * 100} />
                                                                </Form.Item>
                                                              </span>
                                                            } else {
                                                              return <div>
                                                                <Form.Item isListField={true}
                                                                  name={[tierField.name, 'amount']}
                                                                  fieldKey={[tierField.fieldKey, 'amount']}
                                                                  className="no-margin"
                                                                  rules={[{ required: true, type: 'number', min: 1, message: t('field_required', "This field is required") }]}
                                                                >
                                                                  <InputNumber min={1} style={{ width: 120 }} precision={2} formatter={(val) => val ? project.currency + ' ' + (val / 100) : ''} parser={(val: any) => Number(val.replace(project.currency + ' ')) * 100} />
                                                                </Form.Item>
                                                              </div>
                                                            }
                                                          }}
                                                        </Form.Item>
                                                      }
                                                    },
                                                    {
                                                      title: t('conversions_in_the_last_30_days', "Conversions in the last 30 days"),
                                                      key: 'threshold',
                                                      render: tierField => {
                                                        if (tierField.fieldKey === 0) {
                                                          return t('base_tier', "Base tier")
                                                        }
                                                        return <Form.Item isListField={true}
                                                          className="no-margin"
                                                          name={[tierField.name, 'threshold']}
                                                          fieldKey={[tierField.fieldKey, 'threshold']}
                                                          rules={[{ required: true, type: 'number', min: 1, message: t('field_required', "This field is required") }]}
                                                        >
                                                          <InputNumber step={1} min={1} />
                                                        </Form.Item>
                                                      }
                                                    },
                                                    {
                                                      title: '',
                                                      key: 'btn',
                                                      className: 'text-right',
                                                      render: tierField => {
                                                        if (tierField.fieldKey === 0) return ''
                                                        return <Button type="default" icon={<DeleteOutlined />} onClick={() => tierFuncs.remove(tierField.name)} />
                                                      }
                                                    }
                                                  ]}
                                                />

                                                <Button block className="margin-t-m" type="dashed" onClick={addTier.bind(null, field)}>
                                                  <PlusOutlined /> {t('add_tier', "Add tier")}
                                                </Button>
                                              </div>
                                            }}
                                          </Form.List>
                                        </React.Fragment>
                                      } else {
                                        return ''
                                      }
                                    }}
                                  </Form.Item>
                                </div>
                              })}
                            </div>}
                          </Form.List>

                        </React.Fragment>
                      } else {
                        return ''
                      }
                    }}
                  </Form.Item>
                </React.Fragment>
              } else {
                return ''
              }
            }}
          </Form.Item>


        </React.Fragment>}

      </Form>
    </Drawer>}
  </React.Fragment>
}

export default TheForm