import React, { Component } from 'react'

import t from '../../../utils/translate'
import { Table, Tooltip } from 'antd'
import Formatters from '../../../utils/formatters'
import N from 'numeral'
import _ from 'lodash'
import removeLeadingDates from 'utils/removeLeadingDates'

class TopLandingPages extends Component<any, any> {
    _isMounted = false;

    constructor(props: any) {
        super(props)

        this.state = {
            loading: true,
            current: [],
            previous: [],
        }

        this.fetch = this.fetch.bind(this)
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetch()
    }

    componentDidUpdate(prevProps: any) {
        // check url params have changed
        if (prevProps.refreshAt !== this.props.refreshAt) {
            window.setTimeout(() => this.fetch())
        } else if (prevProps.project.id !== this.props.project.id) {
            window.setTimeout(() => this.fetch())
        } else if (prevProps.filters !== this.props.filters) {
            window.setTimeout(() => this.fetch())
        }
    }

    fetch() {

        if (!this.state.loading) {
            this.setState({ loading: true })
        }

        const opts: any = {
            measures: [
                'TimelineSessions.count',
                'TimelineSessions.conversions',
                'TimelineOrders.revenue',
                'TimelineSessions.conversionRate',
            ],
            dimensions: [
                'TimelinePageviews.pageviewPageId',
            ],
            timeDimensions: [
                {
                    "dimension": 'TimelinePageviews.truncCreatedAt',
                    "granularity": null,
                    "compareDateRange": [
                        [this.props.dateFrom, this.props.dateTo],
                        [this.props.dateFromPrevious, this.props.dateToPrevious]
                    ]
                }
            ],
            timezone: this.props.timezone,
            limit: 30,
            order: {'TimelinePageviews.conversionRate': 'desc'}
        }

        if (this.props.filters) {
          opts.filters = this.props.filters
        }


        this.props.cubejsApi.load(opts).then((result: any) => {
            const [current, previous] = result.decompose()

            const currentLines = current.tablePivot()
            const previousLines = previous.tablePivot()

            removeLeadingDates(currentLines)
            removeLeadingDates(previousLines)

                // console.log('current', current.tablePivot())
            this.setState({
                loading: false,
                current: currentLines,
                previous: previousLines,
            })
        })
            .catch((error: any) => {

                console.log(error)

                let message = error.message

                if (error.response && error.response.status === 400) {
                    switch (error.response.data.message) {
                        default:
                            message = error.response.data.message
                    }
                }

                message.error(message)
                this.setState({ loading: false })
            })
    }

    render() {

        // console.log('state kpi', this.state);

        return <div className="padding-a-m dash-table-stats">
            <Table
                dataSource={this.state.current}
                loading={this.state.loading}
                rowKey="TimelinePageviews.pageviewPageId"
                pagination={{
                    pageSize: 10,
                    showSizeChanger: false,
                    size: 'small'
                }}
                columns={[
                    {
                        title: t('top_landing_pages', 'Top landing pages'),
                        key: 'id',
                        render: (record: any) => {
                            return <Tooltip title={'ID: '+record['TimelinePageviews.pageviewPageId']}>
                                <a href={record['TimelinePageviews.pageviewPageId']} target="_blank" rel="noopener noreferrer"><small>{_.truncate(record['TimelinePageviews.pageviewPageId'].replace('https://', '').replace('www.', ''), {length: 100, omission: '...'})}</small></a>
                            </Tooltip>
                        }
                    },
                    {
                        title: 'Sessions',
                        key: 'count',
                        render: (record: any) => {
                            return N(record['TimelineSessions.count']).format('0,0')
                        }
                    },
                    {
                        title: t('conversions', 'Conversions'),
                        key: 'conversions',
                        render: (record: any) => {
                            return N(record['TimelineSessions.conversions']).format('0,0')
                        }
                    },
                    {
                        title: t('conversion_rate', "Conversion rate"),
                        key: 'conversionRate',
                        sorter: (a: any, b: any) => {
                            if (a['OrdersItems.conversionRate'] < b['OrdersItems.conversionRate']) { return -1 }
                            if (a['OrdersItems.conversionRate'] > b['OrdersItems.conversionRate']) { return 1 }
                            return 0
                        },
                        sortOrder: 'descend',
                        showSorterTooltip: false,
                        render: (record: any) => N(record['TimelineSessions.conversionRate']).format('0,0')+'%'
                    },
                    {
                        title: t('revenue', "Revenue"),
                        key: 'conversionRevenue',
                        render: (record: any) => Formatters.currency(this.props.project.currency, record['TimelineOrders.revenue'])
                    },
                ]}
                size="middle"
            />
        </div>
    }
}

export default TopLandingPages