import React, { useState, useEffect } from 'react'

import t from '../../utils/translate'
import { PlusOutlined, TagFilled } from '@ant-design/icons';
import { Table, Tag, Tooltip, Tabs } from 'antd';
import ChannelButtons from '../channels/_actionButtons'
import ChannelGroupButtons from '../channels/_groupButtons'
import AddChannelButton from '../channels/_formButton'
import GroupFormButton from '../channels/_groupFormButton'
import QS from 'qs'
import AppLayout from 'components/app/layout';

const Channels = (props: any) => {

    useEffect(() => {
        if (window.cmAgent) {
            window.cmAgent.pageview({
                title: 'Channels (project: ' + props.projectLayout.project.id + ')',
                page: props.location.pathname,
                props: {
                    orgId: props.projectLayout.project.organizationId,
                    projectId: props.projectLayout.project.id
                }
            })
            window.cmAgent.dispatch()
        }
    }, [props.location.pathname, props.projectLayout.project.id, props.projectLayout.project.organizationId])

    const [expandedSourceMediumPaths, setExpandedSourceMediumPaths] = useState(null)

    const changeTab = (value: any) => {
        let params = QS.parse(props.location.search, { ignoreQueryPrefix: true })
        params.tab = value
        props.history.push('/organizations/' + props.match.params.organizationId + '/projects/' + props.match.params.projectId + '/channels?' + QS.stringify(params))
    }


    const params: any = QS.parse(props.location.search, { ignoreQueryPrefix: true })

    const tab = params.tab || 'mapping'

    let rightBtn = <AddChannelButton
        organizationLayout={props.organizationLayout}
        project={props.projectLayout.project}
        app={props.app}
        btnSize="default"
        btnType="primary"
        btnContent={<span><PlusOutlined /> {t('new_channel', "New channel")}</span>}
    />

    if (tab === 'groups') {
        rightBtn = <GroupFormButton
            organizationLayout={props.organizationLayout}
            project={props.projectLayout.project}
            app={props.app}
            btnSize="default"
            btnType="primary"
            btnContent={<span><PlusOutlined /> {t('new_group', "New group")}</span>}
        />
    }

    return <AppLayout currentProject={props.projectLayout.project} currentOrganization={props.organizationLayout.organization} admin={props.app.state.admin} firebaseUser={props.app.state.firebaseUser} projects={props.organizationLayout.projects}>
        <h1>{t('channels', "Channels")}</h1>

        <div className="padding-a-m">
            <p>{t('channel_about_1', "A channel is a source of traffic that is composed of one or many \"source / medium\" (i.e: google / organic).")}</p>
            <p>{t('channel_about_2', "For every conversion, Captain Metrics will show you all the channels that contributed to this conversion (= conversion funnel).")}</p>
        </div>

        <Tabs activeKey={tab} onChange={changeTab} tabBarExtraContent={{ right: rightBtn }}>
            <Tabs.TabPane tab="Channels mapping" key="mapping">
                {tab === 'mapping' &&
                    <Table dataSource={props.projectLayout.project.channels.filter((ch: any) => !ch.deletedAt).sort((a: any, b: any) => {
                        if (a.isDefault) return -1
                        if (b.isDefault) return -1
                        return 0
                    })}
                        rowKey="id"
                        className="edge-table block margin-t-l"
                        pagination={false}
                        size="middle"
                        columns={[{
                            key: 'name',
                            title: t('name', "Name"),
                            render: (item: any) => item.name
                        },
                        {
                            key: 'sourceMedium',
                            title: t('source_medium', "source / medium"),
                            render: (item: any) => {
                                if (!item.sourceMediumPaths) return ''

                                if (item.sourceMediumPaths.length <= 2 || expandedSourceMediumPaths === item.id) {
                                    return item.sourceMediumPaths.map((x: any) => <div key={x.value}>{x.value}</div>)
                                }

                                return <div>
                                    <div>{item.sourceMediumPaths[0].value}</div>
                                    <div>{item.sourceMediumPaths[1].value}</div>
                                    <span className="link size-13" onClick={() => setExpandedSourceMediumPaths(item.id)}>{t('view_more', "+ view more")}</span>
                                </div>
                            }
                        },
                        {
                            key: 'voucherCodes',
                            title: t('voucher_codes', "Voucher codes"),
                            render: (item: any) => {
                                if (!item.voucherCodes) return ''

                                return item.voucherCodes.map((x: any) => <div key={x.code}><Tooltip title={x.description}><Tag><TagFilled /> {x.code}</Tag> &rarr; {x.sourceMediumPath}</Tooltip></div>)
                            }
                        },
                        {
                            key: 'group',
                            title: t('group', "Group"),
                            render: (item: any) => {
                                const group = props.projectLayout.project.channelGroups.find((x: any) => x.id === item.groupId)
                                return <Tag color={group?.color} className="no-pointer">{group?.name}</Tag>
                            }
                        },
                        {
                            key: 'mediaPartner',
                            title: t('media_partner', "Media Partner"),
                            render: (item: any) => {

                                if (item.isMediaPartner === true) {
                                    return <span className="link" onClick={() => props.history.push('/organizations/' + props.projectLayout.project.organizationId + '/projects/' + props.projectLayout.project.id + '/partnerships?partnershipId=' + item.partnershipId)}>{item.partnershipId}</span>
                                }
                                return '-'
                                // return <AttachToMediaPartnerButton app={props.app} organizationLayout={props.organizationLayout} project={props.projectLayout.project} channel={item} btnType="ghost" btnContent={<span><LinkOutlined /> {t('attach', "Attach")}</span>} />
                            }
                        },
                        {
                            key: 'actions',
                            className: 'actions',
                            title: <span>&nbsp;</span>,
                            render: (item: any) => <ChannelButtons
                                organizationLayout={props.organizationLayout}
                                projectLayout={props.projectLayout}
                                app={props.app}
                                channel={item} />
                        }
                        ]}
                    />
                }
            </Tabs.TabPane>
            <Tabs.TabPane tab="Groups" key="groups">
                {tab === 'groups' && <Table dataSource={props.projectLayout.project.channelGroups.filter((x: any) => !x.deletedAt).sort((a: any, b: any) => {
                    if (a.createdAt) return -1
                    if (b.createdAt) return -1
                    return 0
                })}
                    rowKey="id"
                    className="edge-table block margin-t-l"
                    pagination={false}
                    size="middle"
                    columns={[
                        {
                            key: 'ID',
                            title: 'ID',
                            render: (item: any) => item.id
                        },
                        {
                            key: 'name',
                            title: t('name', "Name"),
                            render: (item: any) => <Tag color={item.color} className="no-pointer">{item.name}</Tag>
                        },
                        {
                            key: 'actions',
                            className: 'actions',
                            title: <span>&nbsp;</span>,
                            render: (item: any) => <ChannelGroupButtons
                                organizationLayout={props.organizationLayout}
                                projectLayout={props.projectLayout}
                                app={props.app}
                                channelGroup={item} />
                        }
                    ]}
                />
                }
            </Tabs.TabPane>
        </Tabs>
    </AppLayout>
}

export default Channels