import { useState, useEffect, useRef } from 'react'

import {
  DeleteOutlined,
  EditOutlined,
  // FacebookOutlined,
  // GoogleOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  ShrinkOutlined,
  ExpandAltOutlined,
  // MobileOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from '@ant-design/icons';

import {
  Menu,
  Dropdown,
  Alert,
  Row,
  Col,
  Tag,
  Drawer,
  Button,
  Select,
  Table,
  Input,
  Slider,
  // Radio,
  Switch,
  Modal,
  Form,
} from 'antd';
import t from '../../utils/translate'
import { v4 as uuidv4 } from 'uuid'
import Formatters from '../../utils/formatters'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import SelectMailchimpAudience from './_selectMailchimpAudience'
// import SelectGoogleAdsAudience from './_selectGoogleAdsAudience'
// import SelectFacebookCustomAudience from './_selectFacebookCustomAudience'
// import SelectEmailTemplate from './_selectEmailTemplate'
import SegmentConditions from '../segments/_conditions'
import DurationPicker from './_durationPicker'
import cn from 'classnames'

const NotificationTemplates = ({ notification }: any) => {

  const columns: any = [{
    title: 'Templates',
    key: 'lang',
    render: (lang: any) => <span className="padding-l-s">{lang.value.toUpperCase()}</span>
  }]

  if (notification.withEmail) {
    columns.push({
      title: <span><FontAwesomeIcon icon="envelope" className="margin-r-s" /> Email</span>,
      key: 'email',
      // align: 'right',
      width: 200,
      render: (lang: any) => {
        const tpl = _.find(notification.templates, x => x.channel === 'email' && x.language === lang.value)

        return tpl ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#CFD8DC" />
      }
    })
  }

  if (notification.withSMS) {
    columns.push({
      title: <span><FontAwesomeIcon icon="mobile" className="margin-r-s" /> SMS</span>,
      key: 'sms',
      // align: 'right',
      width: 200,
      render: (lang: any) => {
        const tpl = _.find(notification.templates, x => x.channel === 'sms' && x.language === lang.value)

        return tpl ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#CFD8DC" />
      }
    })
  }

  return <Table
    className="no-header-bg"
    rowKey="key"
    size="small"
    pagination={false}
    dataSource={notification.languages.map((lang: any) => { return { key: lang, value: lang } })}
    showHeader={true}
    columns={columns}
  />
}

const WorkflowScenarioEditor = ({ project, app, userCustomProperties, notifications, notificationTopics, value, onChange }: any) => {

  const didMount = useRef(false)

  const [expanded, setExpanded] = useState(false)
  const [showNodeDrawer, setShowNodeDrawer] = useState(false)
  const [nodeDrawer, setNodeDrawer] = useState<any>({})
  const [nodeDrawerPath, setNodeDrawerPath] = useState<any>(undefined)

  // convert tree to grid for display
  const [tree, setTree] = useState(value)
  const [columnsNextCount, setcolumnsNextCount] = useState(countNextColumns(tree, '', 1))
  const [columnsCount, setcolumnsCount] = useState(columnsNextCount + 1)

  // console.log('columnsCount', columnsCount);

  const [topLine, settopLine] = useState(generateTopLine(tree, '', []))
  const [grid, setgrid] = useState(generateGrid(1, topLine.length, tree, topLine[topLine.length - 1].path, [], columnsCount))

  // console.log('columnsCount', columnsCount);
  // console.log('topLine', topLine);
  // console.log('grid', grid);


  const drawEditor = (tree: any) => {
    // console.log('drawing')
    const newcolumnsNextCount = countNextColumns(tree, '', 1)
    setcolumnsNextCount(newcolumnsNextCount)

    const newcolumnsCount = newcolumnsNextCount + 1
    setcolumnsCount(newcolumnsCount)

    const newtopLine = generateTopLine(tree, '', [])
    settopLine(newtopLine)
    setgrid(generateGrid(1, newtopLine.length, tree, newtopLine[newtopLine.length - 1].path, [], newcolumnsCount))
  }

  // did mount
  useEffect(() => {
    
    if (didMount.current) return
    didMount.current = true

    drawEditor(tree)
  }, [didMount, tree]);



  const onAddNode = (key: string, path: string) => {
    switch (key) {
      case 'if':
        setNodeDrawer({
          id: uuidv4(),
          kind: 'operator',
          operator: {
            kind: 'if',
            conditions: {}
          },
          next: {
            id: uuidv4(),
            kind: 'add',
            createdAt: new Date(),
          },
          else: {
            id: uuidv4(),
            kind: 'add',
            createdAt: new Date(),
          }
        })
        setNodeDrawerPath(path)
        setShowNodeDrawer(true)
        break

      case 'split':
        setNodeDrawer({
          id: uuidv4(),
          kind: 'operator',
          operator: {
            kind: 'split',
            experimentId1: '',
            experimentId2: '',
            experimentPercentage1: 50
          },
          next: {
            id: uuidv4(),
            kind: 'add',
            createdAt: new Date(),
          },
          else: {
            id: uuidv4(),
            kind: 'add',
            createdAt: new Date(),
          }
        })
        setNodeDrawerPath(path)
        setShowNodeDrawer(true)
        break

      case 'wait':
        setNodeDrawer({
          id: uuidv4(),
          kind: 'operator',
          operator: {
            kind: 'wait',
            waitingMinutes: 60 * 24
          },
          next: {
            id: uuidv4(),
            kind: 'add',
            createdAt: new Date(),
          }
        })
        setNodeDrawerPath(path)
        setShowNodeDrawer(true)
        break

      case 'subscribeToNotificationTopic':
        setNodeDrawer({
          id: uuidv4(),
          kind: 'action',
          action: {
            kind: 'subscribeToNotificationTopic',
            name: t('subscribe_user_to_notification_topic', "Subscribe user to notification topic"),
            createdAt: new Date(),
          },
          next: {
            id: uuidv4(),
            kind: 'add',
            createdAt: new Date(),
          }
        })
        setNodeDrawerPath(path)
        setShowNodeDrawer(true)
        break

      case 'unsubscribeFromNotificationTopic':
        setNodeDrawer({
          id: uuidv4(),
          kind: 'action',
          action: {
            kind: 'unsubscribeFromNotificationTopic',
            name: t('unsubscribe_user_from_notification_topic', "Unsubcribe user from notification topic"),
            createdAt: new Date(),
          },
          next: {
            id: uuidv4(),
            kind: 'add',
            createdAt: new Date(),
          }
        })
        setNodeDrawerPath(path)
        setShowNodeDrawer(true)
        break

      case 'tagUser':
        setNodeDrawer({
          id: uuidv4(),
          kind: 'action',
          action: {
            kind: 'tagUser',
            name: t('add_tag_to_user', "Add tag to user"),
            createdAt: new Date(),
          },
          next: {
            id: uuidv4(),
            kind: 'add',
            createdAt: new Date(),
          }
        })
        setNodeDrawerPath(path)
        setShowNodeDrawer(true)
        break

      case 'untagUser':
        setNodeDrawer({
          id: uuidv4(),
          kind: 'action',
          action: {
            kind: 'untagUser',
            name: t('remove_tag_from_user', "Remove tag from user"),
            createdAt: new Date(),
          },
          next: {
            id: uuidv4(),
            kind: 'add',
            createdAt: new Date(),
          }
        })
        setNodeDrawerPath(path)
        setShowNodeDrawer(true)
        break

      case 'sendWebhook':
        setNodeDrawer({
          id: uuidv4(),
          kind: 'action',
          action: {
            kind: 'sendWebhook',
            name: t('send_to_a_webhook', "Send to a Webhook"),
            createdAt: new Date(),
          },
          next: {
            id: uuidv4(),
            kind: 'add',
            createdAt: new Date(),
          }
        })
        setNodeDrawerPath(path)
        setShowNodeDrawer(true)
        break

      case 'sendNotification':
        setNodeDrawer({
          id: uuidv4(),
          kind: 'action',
          action: {
            kind: 'sendNotification',
            name: t('send_a_notification', "Send a notification") + ' (email, SMS...)',
            createdAt: new Date(),
          },
          next: {
            id: uuidv4(),
            kind: 'add',
            createdAt: new Date(),
          }
        })
        setNodeDrawerPath(path)
        setShowNodeDrawer(true)
        break

      // case 'sendEmail':
      //   setNodeDrawer({
      //     id: uuidv4(),
      //     kind: 'action',
      //     action: {
      //       kind: 'sendEmail',
      //       name: t('send_an_email', "Send an email"),
      //       createdAt: new Date(),
      //     },
      //     next: {
      //       id: uuidv4(),
      //       kind: 'add',
      //       createdAt: new Date(),
      //     }
      //   })
      //   setNodeDrawerPath(path)
      //   setShowNodeDrawer(true)
      //   break

      // case 'sendSMS':
      //   setNodeDrawer({
      //     id: uuidv4(),
      //     kind: 'action',
      //     action: {
      //       kind: 'sendSMS',
      //       name: t('send_an_sms', "Send an SMS"),
      //       createdAt: new Date(),
      //     },
      //     next: {
      //       id: uuidv4(),
      //       kind: 'add',
      //       createdAt: new Date(),
      //     }
      //   })
      //   setNodeDrawerPath(path)
      //   setShowNodeDrawer(true)
      //   break

      // case 'addToGoogleAdsAudience':
      //   setNodeDrawer({
      //     id: uuidv4(),
      //     kind: 'action',
      //     action: {
      //       kind: 'addToGoogleAdsAudience',
      //       name: t('add_to_google_ads_audience', "Add to Google Ads audience"),
      //       createdAt: new Date(),
      //     },
      //     next: {
      //       id: uuidv4(),
      //       kind: 'add',
      //       createdAt: new Date(),
      //     }
      //   })
      //   setNodeDrawerPath(path)
      //   setShowNodeDrawer(true)
      //   break
      // case 'removeFromGoogleAdsAudience':
      //   setNodeDrawer({
      //     id: uuidv4(),
      //     kind: 'action',
      //     action: {
      //       kind: 'removeFromGoogleAdsAudience',
      //       name: t('remove_from_google_ads_audience', "Remove from Google Ads audience"),
      //       createdAt: new Date(),
      //     },
      //     next: {
      //       id: uuidv4(),
      //       kind: 'add',
      //       createdAt: new Date(),
      //     }
      //   })
      //   setNodeDrawerPath(path)
      //   setShowNodeDrawer(true)
      //   break

      // case 'addToMailchimpAudience':
      //   setNodeDrawer({
      //     id: uuidv4(),
      //     kind: 'action',
      //     action: {
      //       kind: 'addToMailchimpAudience',
      //       name: t('add_to_google_ads_audience', "Add to Mailchimp audience"),
      //       createdAt: new Date(),
      //     },
      //     next: {
      //       id: uuidv4(),
      //       kind: 'add',
      //       createdAt: new Date(),
      //     }
      //   })
      //   setNodeDrawerPath(path)
      //   setShowNodeDrawer(true)
      //   break
      // case 'removeFromMailchimpAudience':
      //   setNodeDrawer({
      //     id: uuidv4(),
      //     kind: 'action',
      //     action: {
      //       kind: 'removeFromMailchimpAudience',
      //       name: t('remove_from_google_ads_audience', "Remove from Mailchimp audience"),
      //       createdAt: new Date(),
      //     },
      //     next: {
      //       id: uuidv4(),
      //       kind: 'add',
      //       createdAt: new Date(),
      //     }
      //   })
      //   setNodeDrawerPath(path)
      //   setShowNodeDrawer(true)
      //   break

      // case 'addToFacebookAudience':
      //   setNodeDrawer({
      //     id: uuidv4(),
      //     kind: 'action',
      //     action: {
      //       kind: 'addToFacebookAudience',
      //       name: t('add_to_facebook_audience', "Add to Facebook audience"),
      //       createdAt: new Date(),
      //     },
      //     next: {
      //       id: uuidv4(),
      //       kind: 'add',
      //       createdAt: new Date(),
      //     }
      //   })
      //   setNodeDrawerPath(path)
      //   setShowNodeDrawer(true)
      //   break
      // case 'removeFromFacebookAudience':
      //   setNodeDrawer({
      //     id: uuidv4(),
      //     kind: 'action',
      //     action: {
      //       kind: 'removeFromFacebookAudience',
      //       name: t('remove_from_facebook_audience', "Remove from Facebook audience"),
      //       createdAt: new Date(),
      //     },
      //     next: {
      //       id: uuidv4(),
      //       kind: 'add',
      //       createdAt: new Date(),
      //     }
      //   })
      //   setNodeDrawerPath(path)
      //   setShowNodeDrawer(true)
      //   break
      default:
    }
  }

  const deleteNode = (path: string, node: any) => {

    let newTree = _.cloneDeep(tree)

    // replace the node by its next children
    // if its a IF condition, the else children are deleted
    if (path === '') {
      newTree = node.next
    } else {
      _.set(newTree, path, node.next)
    }

    setTree(newTree)
    drawEditor(newTree)
    onChange(newTree)
  }

  const toggleNodeDrawer = (path: string, node: any) => {
    setNodeDrawer(node)
    setNodeDrawerPath(path)
    setShowNodeDrawer(!showNodeDrawer)
  }


  const addNodeMenu = (path: string) => {

    return <Menu onClick={(e: any) => onAddNode(e.key, path)}>
      <Menu.ItemGroup title={t('operator', "Operator")}>
        <Menu.Item key="if"><FontAwesomeIcon className="margin-r-s" icon="question" /> If - Else</Menu.Item>
        <Menu.Item key="split"><FontAwesomeIcon className="margin-r-s" icon="divide" /> A/B Split</Menu.Item>
        <Menu.Item key="wait"><FontAwesomeIcon className="margin-r-s" icon="hourglass-half" /> Wait</Menu.Item>
      </Menu.ItemGroup>

      <Menu.ItemGroup title={t('action', "Action")}>

        <Menu.SubMenu key="tags" title={<span><FontAwesomeIcon icon="tag" className="margin-r-s" /> Tags</span>}>
          <Menu.Item key="tagUser"><FontAwesomeIcon icon="tag" className="margin-r-s" /> {t('add_tag_to_user', "Add a tag to user")}</Menu.Item>
          <Menu.Item key="untagUser"><FontAwesomeIcon icon="tag" className="margin-r-s" /> {t('remove_tag_from_user', "Remove a tag from user")}</Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu key="notificationTopics" title={<span><FontAwesomeIcon icon="bell" className="margin-r-s" /> Notification topics</span>}>
          <Menu.Item key="subscribeToNotificationTopic"><FontAwesomeIcon icon="bell" className="margin-r-s" /> {t('subscribe_user', "Subscribe user")}</Menu.Item>
          <Menu.Item key="unsubscribeFromNotificationTopic"><FontAwesomeIcon icon="bell-slash" className="margin-r-s" /> {t('unsubscribe_user', "Unsubscribe user")}</Menu.Item>
        </Menu.SubMenu>

        {/* <Menu.SubMenu key="audiences" title={<span><FontAwesomeIcon icon="users" className="margin-r-s" /> Audiences</span>}>
          <Menu.Item key="addToGoogleAdsAudience"><GoogleOutlined className="margin-r-s" /> {t('add_to_google_ads_audience', "Add to Google Ads audience")}</Menu.Item>
          <Menu.Item key="removeFromGoogleAdsAudience"><GoogleOutlined className="margin-r-s" /> {t('remove_to_google_ads_audience', "Remove from Google Ads audience")}</Menu.Item>

          <Menu.Item key="addToFacebookAudience"><FacebookOutlined className="margin-r-s" /> {t('add_to_facebook_audience', "Add to Facebook audience")}</Menu.Item>
          <Menu.Item key="removeFromFacebookAudience"><FacebookOutlined className="margin-r-s" /> {t('remove_to_facebook_audience', "Remove from Facebook audience")}</Menu.Item>

          <Menu.Item key="addToMailchimpAudience"><FontAwesomeIcon icon={['fab', 'mailchimp']} className="margin-r-s" /> {t('add_to_mailchimp_audience', "Add to Mailchimp audience")}</Menu.Item>
          <Menu.Item key="removeFromMailchimpAudience"><FontAwesomeIcon icon={['fab', 'mailchimp']} className="margin-r-s" /> {t('remove_to_mailchimp_audience', "Remove from Mailchimp audience")}</Menu.Item>
        </Menu.SubMenu> */}

        <Menu.Item key="sendWebhook"><FontAwesomeIcon className="margin-r-s" icon="link" />  {t('send_to_a_webhook', "Send to a Webhook")}</Menu.Item>
        <Menu.Item key="sendNotification"><FontAwesomeIcon className="margin-r-s" icon="envelope" /> {t('send_a_notification', "Send a notification")} (Email, SMS...)</Menu.Item>
        {/* <Menu.Item key="sendEmail"><FontAwesomeIcon className="margin-r-s" icon="envelope" /> {t('send_an_email', "Send an email")}</Menu.Item> */}
        {/* <Menu.Item key="sendSMS"><FontAwesomeIcon className="margin-r-s" icon="comment-dots" /> {t('send_an_sms', "Send an SMS")}</Menu.Item> */}
        {/* <Menu.Item key="sendMobilePush"><FontAwesomeIcon className="margin-r-s" icon="mobile-alt" /> {t('send_a_mobile_push', "Send a mobile push")}</Menu.Item> */}
      </Menu.ItemGroup>

      {/*<Menu.ItemGroup title={t('operator', "Operator")}>
            <Menu.Item key="and">{t('operator_and', "AND")}</Menu.Item>
            <Menu.Item key="or">{t('operator_or', "OR")}</Menu.Item>
          </Menu.ItemGroup>*/}
    </Menu>
  }

  const confirmDeleteNode = (path: string, node: any) => {
    Modal.confirm({
      title: t('confirm_delete_scenario_node', "Do you really want to delete this node?"),
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      okText: t('confim', 'Confirm'),
      okType: 'danger',
      cancelText: t('cancel', 'Cancel'),
      onOk() {
        deleteNode(path, node)
      }
    });
  }
  // console.log('props', props);

  // console.log('nodeDrawer', nodeDrawer)
  return <>
    {expanded && <div className="scenario-backdrop" onClick={() => setExpanded(false)}></div>}
    <div className={cn('scenario-editor', { expanded: expanded })}>
      <div className="scenario-editor-expand">
        <Button type="primary" ghost onClick={() => setExpanded(!expanded)}>{expanded ? <ShrinkOutlined /> : <ExpandAltOutlined />} {expanded ? t('minimize', 'Minimize') : t('Expand', 'Expand')}</Button>
      </div>

      <table className="scenario-editor-table" style={{ width: (200 * columnsCount) + 'px' }}>
        <tbody>
          {grid.map((line: any, lineIndex: number) => <tr key={lineIndex}>
            {line.map((row: any, rowIndex: number) => {
              // console.log('row', row);

              if (row.spacer === true) {
                return <td key={rowIndex} className="scenario-editor-node">
                  <div className="scenario-editor-spacer"></div>
                </td>
              }

              if (row.path === undefined) {
                return <td key={rowIndex} className="scenario-editor-node"></td>
              }

              const node = row.path === '' ? tree : _.get(tree, row.path)
              // console.log('node', node);
              const fromElse = row.path.substring(row.path.length - 5) === '.else' ? true : false

              return (
                <td key={rowIndex} className="scenario-editor-node">

                  {node.else && <div className="scenario-editor-bottom"></div>}
                  {fromElse && <div className="scenario-editor-top"></div>}
                  {!fromElse && node.kind !== 'start' && <div className="scenario-editor-left"></div>}
                  {['start', 'action', 'operator'].indexOf(node.kind) !== -1 && <div className="scenario-editor-right"></div>}

                  {node.kind === 'start' && <div className="scenario-editor-start">
                    <div className="scenario-editor-start-icon"><FontAwesomeIcon icon="play" /></div>
                  </div>}

                  {node.kind === 'add' && <div className={cn('scenario-editor-add', { 'from-else': fromElse })}>
                    <Dropdown trigger={['click']} className="scenario-editor-add-button" overlay={addNodeMenu(row.path)}>
                      <Button type="primary" shape="circle" size="large" icon={<PlusOutlined />} />
                    </Dropdown>
                  </div>}

                  {/* OPERATORS */}
                  {node.kind === 'operator' && <div className={cn('scenario-editor-operator', { 'from-else': fromElse })}>
                    <Dropdown overlay={<Menu>
                      <Menu.Item key="edit" onClick={toggleNodeDrawer.bind(null, row.path, node)}>
                        <EditOutlined className="margin-r-s" /> {t('edit', "Edit")}
                      </Menu.Item>
                      <Menu.Item key="delete" onClick={confirmDeleteNode.bind(null, row.path, node)}>
                        <DeleteOutlined className="margin-r-s" /> {t('delete', "Delete")}
                      </Menu.Item>
                    </Menu>}>
                      <div className="scenario-editor-operator-icon">
                        {Formatters.scenarioNodeIcon(node[node.kind].kind)}
                      </div>
                    </Dropdown>

                    {node[node.kind].kind === 'if' && <>
                      <FontAwesomeIcon icon="check-circle" className="scenario-editor-operator-if-yes" />
                      <FontAwesomeIcon icon="times-circle" className="scenario-editor-operator-if-no" />
                    </>}

                    {node[node.kind].kind === 'split' && <>
                      <span className="scenario-editor-operator-split-a">{node.operator.experimentPercentage1}% - {node.operator.experimentId1}</span>
                      <span className="scenario-editor-operator-split-b">{100 - node.operator.experimentPercentage1}% - {node.operator.experimentId2}</span>
                    </>}

                    {node[node.kind].kind === 'wait' && <div className="scenario-editor-operator-details">
                      {Formatters.duration(node.operator.waitingMinutes * 60)}
                    </div>}

                    {<div className="scenario-editor-operator-type"><span>{node.operator.name}</span></div>}
                  </div>}


                  {/* ACTIONS */}
                  {node.kind === 'action' && <div className={cn('scenario-editor-action', { 'from-else': fromElse })}>
                    <Dropdown overlay={<Menu>
                      {[
                        'addToGoogleAdsAudience',
                        'removeFromGoogleAdsAudience',
                        'addToFacebookAudience',
                        'removeFromFacebookAudience',
                        'subscribeToNotificationTopic',
                        'unsubscribeFromNotificationTopic',
                        'tagUser',
                        'untagUser',
                        'sendWebhook',
                        'sendNotification'
                        // 'sendEmail',
                        // 'sendSMS',
                      ].indexOf(node[node.kind].kind) !== -1 && <Menu.Item key="edit" onClick={toggleNodeDrawer.bind(null, row.path, node)}>
                          <EditOutlined className="margin-r-s" /> {t('edit', "Edit")}
                        </Menu.Item>}
                      <Menu.Item key="delete" onClick={confirmDeleteNode.bind(null, row.path, node)}>
                        <DeleteOutlined className="margin-r-s" /> {t('delete', "Delete")}
                      </Menu.Item>
                    </Menu>}>
                      <div className="scenario-editor-action-icon">
                        {Formatters.scenarioNodeIcon(node[node.kind].kind)}
                      </div>
                    </Dropdown>
                    <div className="scenario-editor-action-type"><span>{node.action.name}</span></div>
                    <div className="scenario-editor-action-details">
                      {[
                        'addToGoogleAdsAudience',
                        'removeFromGoogleAdsAudience',
                        'addToMailchimpAudience',
                        'removeFromMailchimpAudience',
                        'addToFacebookAudience',
                        'removeFromFacebookAudience'
                      ].indexOf(node[node.kind].kind) !== -1 && <div className="margin-v-s">Audience: {node.action.audience.name}</div>}

                      {['tagUser', 'untagUser'].indexOf(node[node.kind].kind) !== -1 && <Tag>{node.action.tag}</Tag>}

                      {['subscribeToNotificationTopic', 'unsubscribeFromNotificationTopic'].indexOf(node[node.kind].kind) !== -1 && <div>
                        {notificationTopics.find((x: any) => x.id === node.action.notificationTopic.id).name}
                        {node.action.notificationTopic.collectionItemExternalId && node.action.notificationTopic.collectionItemExternalId !== '' && <div>Item ID: {node.action.notificationTopic.collectionItemExternalId}</div>}
                        {node[node.kind].kind === 'subscribeToNotificationTopic' && <>
                          <div>Add email: {node.action.notificationTopic.withEmail ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#CFD8DC" />}</div>
                          <div>Add SMS: {node.action.notificationTopic.withSMS ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#CFD8DC" />}</div>
                          <div>{t('skip_double_opt_in', 'Skip double opt-in')}: {node.action.notificationTopic.skipDoubleOptIn ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#CFD8DC" />}</div>
                        </>}
                        {node[node.kind].kind === 'unsubscribeFromNotificationTopic' && <>
                          <div>Remove email: {node.action.notificationTopic.withEmail ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#CFD8DC" />}</div>
                          <div>Remove SMS: {node.action.notificationTopic.withSMS ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#CFD8DC" />}</div>
                        </>}
                      </div>}

                      {['sendWebhook'].indexOf(node[node.kind].kind) !== -1 && <div>
                        {project.integrations.find((x: any) => x.id === node.action.integrationId).name}<br />
                        {project.integrations.find((x: any) => x.id === node.action.integrationId).webhookSettings?.endpoint}
                      </div>}

                      {['sendNotification'].indexOf(node[node.kind].kind) !== -1 && <div>
                        {notifications.find((x: any) => x.id === node.action.notification.id).name}
                      </div>}
                      {/* {['sendEmail'].indexOf(node[node.kind].kind) !== -1 && <div>
                        {project.integrations.find((x: any) => x.id === node.action.integrationId).name}<br />
                        {node.action.email.template.name}
                      </div>}
                      {['sendSMS'].indexOf(node[node.kind].kind) !== -1 && <div>
                        {project.integrations.find((x: any) => x.id === node.action.integrationId).name}<br />
                      </div>} */}
                    </div>
                  </div>}
                </td>
              );
            })}
          </tr>)}
        </tbody>
      </table>

      {showNodeDrawer && <NodeDrawer
        toggleNodeDrawer={toggleNodeDrawer}
        project={project}
        app={app}
        userCustomProperties={userCustomProperties}
        notifications={notifications}
        notificationTopics={notificationTopics}
        tree={tree}
        nodeDrawer={nodeDrawer}
        onChange={(updatedNode: any) => {
          let newTree = _.cloneDeep(tree)

          // set the node at the path
          // let tree = value

          if (nodeDrawerPath === '') {
            newTree = updatedNode
          } else {
            _.set(newTree, nodeDrawerPath, updatedNode)
          }

          // console.log('newTree', newTree)
          setShowNodeDrawer(false)
          setNodeDrawer({})
          setNodeDrawerPath(undefined)
          setTree(newTree)
          drawEditor(newTree)
          onChange(newTree)
        }}
      />}
    </div>
  </>
}

const NodeDrawer = ({ nodeDrawer, toggleNodeDrawer, project, app, userCustomProperties, notifications, notificationTopics, onChange }: any) => {

  const [form] = Form.useForm();

  const onFinish = (values: any) => {

    // update node with values from form
    const updatedNode = _.cloneDeep(nodeDrawer)

    _.forEach(values, (value, key) => {
      updatedNode[updatedNode.kind][key] = value
    })

    // New
    if (!nodeDrawer.id) {
      // set the new node id
      updatedNode.id = uuidv4()
      updatedNode.createdAt = new Date()

      // add a "add" node if its a new node
      updatedNode.next = {
        id: uuidv4(),
        kind: 'add',
        createdAt: new Date(),
      }
    }

    form.resetFields()
    onChange(updatedNode)
  }

  return <Drawer
    title={nodeDrawer.kind}
    visible={true}
    onClose={toggleNodeDrawer}
    maskClosable={false}
    className="drawer drawer-form"
    width={600}
  >
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      initialValues={{
        // operators
        name: _.get(nodeDrawer, nodeDrawer.operator ? 'operator.name' : 'action.name', Formatters.translateNodeType(nodeDrawer[nodeDrawer.kind].kind)),
        // operators
        conditions: _.get(nodeDrawer, 'operator.conditions', {}),
        experimentId1: _.get(nodeDrawer, 'operator.experimentId1'),
        experimentId2: _.get(nodeDrawer, 'operator.experimentId2'),
        experimentPercentage1: _.get(nodeDrawer, 'operator.experimentPercentage1', 50),
        waitingMinutes: _.get(nodeDrawer, 'operator.waitingMinutes', 60 * 24),
        // actions
        tag: _.get(nodeDrawer, 'action.tag'),
        integrationId: _.get(nodeDrawer, 'action.integrationId'),
        notification: {
          id: _.get(nodeDrawer, 'action.notification.id'),
          withEmail: _.get(nodeDrawer, 'action.notification.withEmail', false),
          withSMS: _.get(nodeDrawer, 'action.notification.withSMS', false)
        },
        notificationTopic: {
          id: _.get(nodeDrawer, 'action.notificationTopic.id'),
          collectionItemExternalId: _.get(nodeDrawer, 'action.notificationTopic.collectionItemExternalId'),
          withEmail: _.get(nodeDrawer, 'action.notificationTopic.withEmail', false),
          withSMS: _.get(nodeDrawer, 'action.notificationTopic.withSMS', false),
          skipDoubleOptIn: _.get(nodeDrawer, 'action.notificationTopic.skipDoubleOptIn', false)
        },
        email: {
          'template': _.get(nodeDrawer, 'action.email.template', { id: undefined, name: undefined }),
          'from': _.get(nodeDrawer, 'action.email.from'),
          'replyTo': _.get(nodeDrawer, 'action.email.replyTo'),
          'cc': _.get(nodeDrawer, 'action.email.cc'),
          'bcc': _.get(nodeDrawer, 'action.email.bcc'),
          'trackOpens': _.get(nodeDrawer, 'action.email.trackOpens'),
          'trackLinks': _.get(nodeDrawer, 'action.email.trackLinks', 'none'),
          'variables': _.get(nodeDrawer, 'action.email.variables', []),
        },
        twilio: {
          'messagingServiceSid': _.get(nodeDrawer, 'action.twilio.messagingServiceSid'),
          'body': _.get(nodeDrawer, 'action.twilio.body', 'Hello {{ user.firstName }}'),
        },
        'audience': _.get(nodeDrawer, 'action.audience', { id: undefined, name: undefined }),
      }}
      className="margin-a-m margin-b-xl"
    >

      {/* OPERATORS */}
      {nodeDrawer.kind === 'operator' && <>

        <Form.Item name="name" label={t('name', "Name")} rules={[{ required: true, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
          <Input />
        </Form.Item>

        {/* IF */}
        {_.get(nodeDrawer, 'operator.kind', '') === 'if' && <Form.Item name="conditions" rules={[]}>
          <SegmentConditions app={app} project={project} userCustomProperties={userCustomProperties} notificationTopics={notificationTopics} />
        </Form.Item>}

        {/* SPLIT */}
        {_.get(nodeDrawer, 'operator.kind', '') === 'split' && <>
          <Form.Item name="experimentId1" label={t('experiment_id_1', "Experiment ID 1")} rules={[{ required: true, type: 'string', pattern: /^[a-zA-Z0-9]+(-[a-zA-Z0-9_-]+)*$/, message: t('value_should_be_written_like_this', "The value should-be-written-like-this") }]}>
            <Input placeholder="written-like-this [a-zA-Z0-9_-]" />
          </Form.Item>

          <Form.Item name="experimentId2" label={t('experiment_id_1', "Experiment ID 2")} rules={[{ required: true, type: 'string', pattern: /^[a-zA-Z0-9]+(-[a-zA-Z0-9_-]+)*$/, message: t('value_should_be_written_like_this', "The value should-be-written-like-this") }]}>
            <Input placeholder="written-like-this [a-zA-Z0-9_-]" />
          </Form.Item>

          <Form.Item name="experimentPercentage1" label={t('experiment_1_percentage', "Experiment 1 percentage")} rules={[{ required: true, type: 'number', message: t('field_invalid', "This field is not valid!") }]}>
            <Slider marks={{ 0: '0%', 25: '25%', 50: '50%', 75: '75%', 100: '100%' }} />
          </Form.Item>
        </>}

        {/* WAIT */}
        {_.get(nodeDrawer, 'operator.kind', '') === 'wait' && <Form.Item name="waitingMinutes" label={t('wait_for', "Wait for")} rules={[{ required: true, type: 'number', message: t('field_invalid', "This field is not valid!") }]}>
          <DurationPicker />
        </Form.Item>}

      </>}

      {/* ACTIONS */}
      {nodeDrawer.kind === 'action' && <>

        <Form.Item name="name" label={t('name', "Name")} rules={[{ required: true, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
          <Input />
        </Form.Item>

        {['tagUser', 'untagUser'].indexOf(nodeDrawer.action.kind) !== -1 && <Form.Item name="tag" label={t('tag', "Tag")} rules={[{ required: true, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
          <Input />
        </Form.Item>}

        {/* SEND EMAIL */}
        {/* {['sendEmail'].indexOf(_.get(nodeDrawer, 'action.kind', '')) !== -1 && <>
          <Form.Item name="integrationId" label={t('email_service_provider', "Email service provider")} rules={[{ required: true, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
            <Select>
              {project.integrations.filter(i => i.kind === 'postmark').map(i => <Select.Option key={i.id} value={i.id}>{i.name}</Select.Option>)}
            </Select>
          </Form.Item>

          <Form.Item noStyle shouldUpdate>
            {(funcs) => {
              if (funcs.getFieldValue('integrationId')) return <>

                <Form.Item name="email.template" label={t('email_template', "Email template")} rules={[{
                  required: true, type: 'object',
                  validator: (rule, value, callback) => {
                    if (!value.id || value.id === '') {
                      callback(t('field_invalid', "This field is not valid!"))
                      return
                    }
                    callback();
                  }, message: t('field_invalid', "This field is not valid!")
                }]}>
                  <SelectEmailTemplate app={app} project={project} integrationId={funcs.getFieldValue('integrationId')} />
                </Form.Item>

                <Form.Item name="email.from" label={t('from', "From")} rules={[{ required: true, type: 'email', message: t('field_invalid', "This field is not valid!") }]}>
                  <Input />
                </Form.Item>

                <Form.Item name="email.replyTo" label={t('reply_to', "Reply to")} rules={[{ required: false, type: 'email', message: t('field_invalid', "This field is not valid!") }]}>
                  <Input />
                </Form.Item>

                <Form.Item name="email.cc" label="Cc" rules={[{ required: false, type: 'email', message: t('field_invalid', "This field is not valid!") }]}>
                  <Input />
                </Form.Item>

                <Form.Item name="email.bcc" label="Bcc" rules={[{ required: false, type: 'email', message: t('field_invalid', "This field is not valid!") }]}>
                  <Input />
                </Form.Item>

                <Form.Item valuePropName="checked" name="email.trackOpens" label={t('track_opens', "Track opens")} rules={[{ required: false, type: 'boolean', message: t('field_invalid', "This field is not valid!") }]}>
                  <Switch />
                </Form.Item>

                <Form.Item name="email.trackLinks" label={t('track_links', "Track links")} rules={[{ required: true, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
                  <Radio.Group>
                    <Radio.Button value="none">{t('none', "None")}</Radio.Button>
                    <Radio.Button value="htmlAndText">{t('html_and_text', "HTML + text")}</Radio.Button>
                    <Radio.Button value="htmlOnly">{t('html_only', "HTML only")}</Radio.Button>
                    <Radio.Button value="textOnly">{t('text_only', "Text only")}</Radio.Button>
                  </Radio.Group>
                </Form.Item>

                <Form.Item name="email.variables" label={t('email_variables', "Email variables")} rules={[{ required: false, type: 'array', message: t('field_invalid', "This field is not valid!") }]}>
                  <Select mode="multiple">
                    <Select.Option value="user.externalId">user.externalId</Select.Option>
                    <Select.Option value="user.isAuthenticated">user.isAuthenticated</Select.Option>
                    <Select.Option value="user.signedUpAt">user.signedUpAt</Select.Option>
                    <Select.Option value="user.firstName">user.firstName</Select.Option>
                    <Select.Option value="user.lastName">user.lastName</Select.Option>
                    <Select.Option value="user.gender">user.gender</Select.Option>
                    <Select.Option value="user.birthday">user.birthday</Select.Option>
                    <Select.Option value="user.photoURL">user.photoURL</Select.Option>
                    <Select.Option value="user.email">user.email</Select.Option>
                    <Select.Option value="user.emailMd5">user.emailMd5</Select.Option>
                    <Select.Option value="user.emailSha1">user.emailSha1</Select.Option>
                    <Select.Option value="user.emailSha256">user.emailSha256</Select.Option>
                    <Select.Option value="user.telephone">user.telephone</Select.Option>
                    <Select.Option value="user.timezone">user.timezone</Select.Option>
                    <Select.Option value="user.language">user.language</Select.Option>
                    <Select.Option value="user.addressLine1">user.addressLine1</Select.Option>
                    <Select.Option value="user.addressLine2">user.addressLine2</Select.Option>
                    <Select.Option value="user.city">user.city</Select.Option>
                    <Select.Option value="user.region">user.region</Select.Option>
                    <Select.Option value="user.postalCode">user.postalCode</Select.Option>
                    <Select.Option value="user.state">user.state</Select.Option>
                    <Select.Option value="user.country">user.country</Select.Option>
                    <Select.Option value="user.latitude">user.latitude</Select.Option>
                    <Select.Option value="user.longitude">user.longitude</Select.Option>
                    <Select.Option value="user.websiteURL">user.websiteURL</Select.Option>
                    <Select.Option value="user.linkedInURL">user.linkedInURL</Select.Option>
                    <Select.Option value="user.twitterUsername">user.twitterUsername</Select.Option>
                    <Select.Option value="user.facebookUsername">user.facebookUsername</Select.Option>
                    <Select.Option value="user.facebookId">user.facebookId</Select.Option>
                    {_.map(project.userCustomAttributes, (type, val) => <Select.Option key={val} value={'user.attributes.' + val}>user.attributes.{val}</Select.Option>)}
                  </Select>
                </Form.Item>
              </>
            }}
          </Form.Item>

        </>} */}

        {/* SEND SMS */}
        {/* {['sendSMS'].indexOf(_.get(nodeDrawer, 'action.kind', '')) !== -1 && <>
  <Form.Item name="integrationId" label={t('sms_service_provider', "SMS service provider")} rules={[{ required: true, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
    <Select>
      {project.integrations.filter(i => i.kind === 'twilio').map(i => <Select.Option key={i.id} value={i.id}>{i.name}</Select.Option>)}
    </Select>
  </Form.Item>

  <Form.Item noStyle shouldUpdate>
    {(funcs) => {
      if (funcs.getFieldValue('integrationId')) return <>

        <Form.Item name="twilio.messagingServiceSid" label={t('twilio_messaging_service_id', "Twilio messaging service ID")} rules={[{ required: true, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
          <Input />
        </Form.Item>

        <Form.Item
          name="twilio.body"
          label={t('message_body', "Message body")}
          rules={[{ required: true, type: 'string', max: 1600, message: t('field_invalid', "This field is not valid!") }]}
          help={<a href="https://jinja.palletsprojects.com/en/2.10.x/templates/" target="_blank" rel="noopener noreferrer">{t('templating_example', "You can use Jinja2 syntax to inject variables like this: Hello {{ user.firstName }}")}</a>}
        >
          <Input.TextArea autoSize={{ minRows: 3 }} />
        </Form.Item>
      </>
    }}
  </Form.Item>
</>} */}

        {/* NOTIFICATION TOPIC */}
        {['subscribeToNotificationTopic', 'unsubscribeFromNotificationTopic'].indexOf(_.get(nodeDrawer, 'action.kind', '')) !== -1 && <>
          <Form.Item name={['notificationTopic', 'id']} label="Notification topic" rules={[{ required: true, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
            <Select>
              {notificationTopics.map((i: any) => <Select.Option key={i.id} value={i.id}>{i.name}</Select.Option>)}
            </Select>
          </Form.Item>

          <Form.Item noStyle shouldUpdate>
            {(funcs) => {
              const notificationTopicId = funcs.getFieldValue(['notificationTopic', 'id'])
              const notificationTopic = notificationTopics.find((x: any) => x.id === notificationTopicId)
              // console.log('notificationTopic', notificationTopic)
              if (notificationTopic) return <>

                {notificationTopic.kind === 'collection' && <>
                  <Form.Item name={['notificationTopic', 'collectionItemExternalId']} label={t('collection_item_external_id', 'Collection item external ID')} rules={[{ required: true, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
                    <Input />
                  </Form.Item>
                </>}

                {nodeDrawer.action.kind === 'subscribeToNotificationTopic' && <Form.Item
                  name={['notificationTopic', 'skipDoubleOptIn']}
                  label={t('skipDoubleOptIn', "Skip double opt-in")}
                  rules={[{ required: false, type: 'boolean' }]}
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>}

                <Row className="margin-v-l" gutter={24}>
                  <Col span={6}>
                    <Form.Item
                      name={['notificationTopic', 'withEmail']}
                      label={t('with_email', "With Email")}
                      rules={[{ required: false, type: 'boolean' }]}
                      valuePropName="checked"
                    >
                      <Switch disabled={(notificationTopic && notificationTopic.withEmail === false) ? true : false} />
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item
                      name={['notificationTopic', 'withSMS']}
                      label={t('with_sms', "With SMS")}
                      rules={[{ required: false, type: 'boolean' }]}
                      valuePropName="checked"
                    >
                      <Switch disabled={(notificationTopic && notificationTopic.withSMS === false) ? true : false} />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            }}
          </Form.Item>
        </>}

        {/* SEND NOTIFICATION */}
        {['sendNotification'].indexOf(_.get(nodeDrawer, 'action.kind', '')) !== -1 && <>
          <Form.Item name={['notification', 'id']} label="Notification" rules={[{ required: true, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
            <Select>
              {notifications.map((i: any) => <Select.Option key={i.id} value={i.id}>{i.name}</Select.Option>)}
            </Select>
          </Form.Item>

          <Form.Item noStyle shouldUpdate>
            {(funcs) => {
              const notificationId = funcs.getFieldValue(['notification', 'id'])
              const notification = notifications.find((x: any) => x.id === notificationId)

              if (notification) return <>

                {notification.templates.length === 0 && <Alert type="warning" showIcon message={t('this_notification_has_no_templates', 'This notification has no templates!')} />}

                {notification.templates.length > 0 && <NotificationTemplates notification={notification} />}

                <Row className="margin-v-l" gutter={24}>
                  <Col span={8}>
                    <Form.Item
                      name={['notification', 'withEmail']}
                      label={t('send_an_email', "Send an email")}
                      rules={[{ required: false, type: 'boolean' }]}
                      valuePropName="checked"
                    >
                      <Switch disabled={(notification && notification.withEmail === false) ? true : false} />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      name={['notification', 'withSMS']}
                      label={t('send_an_sms', "Send an SMS")}
                      rules={[{ required: false, type: 'boolean' }]}
                      valuePropName="checked"
                    >
                      <Switch disabled={(notification && notification.withSMS === false) ? true : false} />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            }}
          </Form.Item>
        </>}

        {/* WEBHOOK */}
        {['sendWebhook'].indexOf(_.get(nodeDrawer, 'action.kind', '')) !== -1 && <>
          <Form.Item name="integrationId" label={t('webhook_integration', "Webhook integration")} rules={[{ required: true, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
            <Select>
              {project.integrations.filter((i: any) => i.kind === 'webhook' && i.status === 'connected').map((i: any) => <Select.Option key={i.id} value={i.id}>{i.name + ': ' + i.webhookSettings?.endpoint}</Select.Option>)}
            </Select>
          </Form.Item>
        </>}

        {/* GOOGLE ADS */}
        {/* {['addToGoogleAdsAudience', 'removeFromGoogleAdsAudience'].indexOf(_.get(nodeDrawer, 'action.kind', '')) !== -1 && <>
          <Form.Item name="integrationId" label={t('google_ads_integration', "Google Ads integration")} rules={[{ required: true, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
            <Select>
              {project.integrations.filter(i => i.kind === 'googleAds').map(i => <Select.Option key={i.id} value={i.id}>{i.name}</Select.Option>)}
            </Select>
          </Form.Item>

          <Form.Item noStyle shouldUpdate>
            {(funcs) => {
              if (funcs.getFieldValue('integrationId')) return <>
                <Form.Item name="audience" label={t('google_ads_crm_audience', "Google Ads CRM audience")} rules={[{
                  required: true, type: 'object',
                  validator: (rule, value, callback) => {
                    if (!value.id || value.id === '') {
                      callback(t('field_invalid', "This field is not valid!"))
                      return
                    }
                    callback();
                  }, message: t('field_invalid', "This field is not valid!")
                }]}>
                  <SelectGoogleAdsAudience app={app} project={project} type="CRM_BASED" integrationId={funcs.getFieldValue('integrationId')} />
                </Form.Item>
              </>
            }}
          </Form.Item>
        </>} */}

        {/* MAILCHIMP */}
        {/* {['addToMailchimpAudience', 'removeFromMailchimpAudience'].indexOf(_.get(nodeDrawer, 'action.kind', '')) !== -1 && <>
          <Form.Item name="integrationId" label={t('mailchimp_integration', "Mailchimp integration")} rules={[{ required: true, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
            <Select>{project.integrations.filter(i => i.kind === 'mailchimp').map(i => <Select.Option key={i.id} value={i.id}>{i.name}</Select.Option>)}</Select>
          </Form.Item>

          <Form.Item noStyle shouldUpdate>
            {(funcs) => {
              if (funcs.getFieldValue('integrationId')) return <>
                <Form.Item name="audience" label={t('mailchimp_audience', "Mailchimp audience")} rules={[{
                  required: true, type: 'object',
                  validator: (rule, value, callback) => {
                    if (!value.id || value.id === '') {
                      callback(t('field_invalid', "This field is not valid!"))
                      return
                    }
                    callback();
                  }, message: t('field_invalid', "This field is not valid!")
                }]}>
                  <SelectMailchimpAudience app={app} project={project} integrationId={funcs.getFieldValue('integrationId')} />
                </Form.Item>
              </>
            }}
          </Form.Item>
        </>} */}


        {/* FACEBOOK ADS */}
        {/* {['addToFacebookAudience', 'removeFromFacebookAudience'].indexOf(nodeDrawer.action.kind) !== -1 && <>
          <Form.Item name="integrationId" label={t('facebook_integration', "Facebook integration")} rules={[{ required: true, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
            <Select>{project.integrations.filter(i => i.kind === 'facebook').map(i => <Select.Option key={i.id} value={i.id}>{i.name}</Select.Option>)}</Select>
          </Form.Item>

          <Form.Item noStyle shouldUpdate>
            {(funcs) => {
              if (funcs.getFieldValue('integrationId')) return <>
                <Form.Item name="audience" label={<span>
                  {nodeDrawer.action.kind === 'addToFacebookAudience' && <a href="https://business.facebook.com/adsmanager/audiences?tool=AUDIENCES" target="_blank" rel="noopener noreferrer" onClick={toggleNodeDrawer} className="link pull-right">
                    <PlusOutlined /> {t('create_a_custom_audience', "Create a custom audience")}
                  </a>}
                  {t('facebook_custom_audience', "Facebook custom audience")}</span>}
                  rules={[{
                    required: true, type: 'object',
                    validator: (rule, value, callback) => {
                      if (!value.id || value.id === '') {
                        callback(t('field_invalid', "This field is not valid!"))
                        return
                      }
                      callback();
                    }, message: t('field_invalid', "This field is not valid!")
                  }]}
                >
                  <SelectFacebookCustomAudience app={app} project={project} integrationId={funcs.getFieldValue('integrationId')} />
                </Form.Item>
              </>
            }}
          </Form.Item>
        </>} */}
      </>}


      <div className="ant-drawer-footer">
        <Button className="margin-r-m" onClick={toggleNodeDrawer}>{t('cancel', "Cancel")}</Button>
        <Button htmlType="submit" type="primary">{t('confirm', "Confirm")}</Button>
      </div>
    </Form>
  </Drawer>
}



// count how many next columns are required to draw all the node children
const countNextColumns = (node: any, currentPath: string, maxColumns: number, ignoreElse?: boolean) => {

  let path = currentPath + '.' + node.id
  let count = (path.match(/\.next/g) || []).length;

  if (count > maxColumns) {
    maxColumns = count
  }

  if (node.next) {
    const nextPath = path + '.next'
    let nextCount = countNextColumns(node.next, nextPath, maxColumns, ignoreElse)

    if (nextCount > maxColumns) {
      maxColumns = nextCount
    }
  }

  if (node.else && !ignoreElse) {
    const elsePath = path + '.else'
    let elseCount = countNextColumns(node.else, elsePath, maxColumns, ignoreElse)

    if (elseCount > maxColumns) {
      maxColumns = elseCount
    }
  }

  return maxColumns
}

// generate top path for a given node, used to draw the grid from top to bottom
const generateTopPath = (node: any, currentPath: string) => {

  let topPath = currentPath

  if (node.next) {
    let path = currentPath

    if (path === '') {
      path = 'next'
    } else {
      path = path + '.next'
    }

    topPath = generateTopPath(node.next, path)
  }

  return topPath
}

// generate top line is used to init the drawing of the grid
const generateTopLine = (node: any, currentPath: string, line: any) => {

  line.push({
    id: node.id,
    path: currentPath
  })

  let path = currentPath

  if (node.next) {
    if (path === '') {
      path = 'next'
    } else {
      path = path + '.next'
    }

    line = generateTopLine(node.next, path, line)
  }

  return line
}

// generateGrid(1, topLine.length, tree, topLine[topLine.length - 1].path, [], columnsCount))

// starts at the last node from the topLine
const generateGrid = (line: any, column: any, tree: any, currentPath: string, grid: any, columnsCount: number) => {
  // console.log('line', line)
  // console.log('column', column)
  // console.log('currentPath', currentPath)
  // console.log('columnsCount', columnsCount)
  // console.log('grid', _.cloneDeep(grid))
  const node = currentPath === '' ? tree : _.get(tree, currentPath, {})

  // init line

  let lineIndex = line - 1

  if (!grid[lineIndex]) {
    grid[lineIndex] = []
    for (let i = 0; i < columnsCount; i++) {
      grid[lineIndex].push({}) // init rows for this line
    }
    // console.log('grid line ' + lineIndex, _.cloneDeep(grid))
  }

  let columnIndex = column - 1

  grid[lineIndex][columnIndex] = {
    id: node.id,
    path: currentPath
  }

  // traverse "else"
  if (node.else) {
    // console.log('has else')
    const elseNextPath = generateTopPath(node.else, '')
    const elseTopPath = elseNextPath !== '' ? currentPath + '.else.' + elseNextPath : currentPath + '.else'

    // findout if the children nodes can fit on this line or not

    // find the maximum of required columns of all this node horizontal children
    const elseRequiredNextColumns = countNextColumns(node.else, '', 0, true)

    let freeLine = line + 1 // init

    // find from which line we should position this node
    if (elseRequiredNextColumns > 0) {
      let currentLineIndex = lineIndex + 1
      let isLineFree = false

      while (isLineFree === false) {

        freeLine = currentLineIndex + 1 // zero indexed

        // check if line exists
        if (!grid[currentLineIndex]) {

          grid[currentLineIndex] = []
          for (let x = 0; x < columnsCount; x++) {
            grid[currentLineIndex].push({}) // init rows for this line
          }

          isLineFree = true
          break
        }

        // check every column required
        let allColumnsAreFree = true

        for (let i = 0; i <= elseRequiredNextColumns; i++) {

          if (allColumnsAreFree === false) {
            continue
          }

          // check if next column is free
          if (grid[currentLineIndex][columnIndex + i].id !== undefined) {
            allColumnsAreFree = false
          }
        }

        if (allColumnsAreFree === true) {
          isLineFree = true
          break
        } else {

          // add a "spacer" node for vertical arrows

          grid[currentLineIndex][columnIndex] = {
            id: node.else.id + '-' + columnIndex,
            spacer: true
          }
        }

        currentLineIndex++
      }
    }

    grid = generateGrid(freeLine, column + elseRequiredNextColumns, tree, elseTopPath, grid, columnsCount)
  }

  // traverse parent
  if (_.endsWith(currentPath, 'next') === true) {
    let parentPath = currentPath.substr(0, currentPath.length - 4)

    // remove trailing . if not root
    if (_.endsWith(parentPath, '.') === true) {
      parentPath = parentPath.substr(0, parentPath.length - 1)
    }
    // console.log('parentPath', parentPath)

    // parent is at previous column if current path ends with next
    grid = generateGrid(line, column - 1, tree, parentPath, grid, columnsCount)
  }

  return grid
}


export default WorkflowScenarioEditor



