import React, { Component, useState } from 'react'

import { Button, Input, Badge, Popconfirm, Switch, Select, Tag, Form } from 'antd';
import t from '../../utils/translate'
import _ from 'lodash'
import Moment from 'moment-timezone'
import Languages from '../../utils/languages'
import logoWooCommerce from '../../images/integrations/woocommerce.png'
// import Doc from '../docs/_doc'

const ConnectWooCommerce = ({ app, projectLayout, organizationLayout, integration }: any) => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const onFinish = (values: any) => {
    setLoading(true)

    app.ajaxRequest({
      method: 'post',
      url: '/integrations.connect',
      data: {
        projectId: projectLayout.project.id,
        integrationId: integration.id,
        wooCommerce: values,
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        app.addMessage('error', errorMessage)
        setLoading(false)
        return
      }

      let projects = _.filter(organizationLayout.projects, (p: any) => p.id !== response.data.project.id)
      projects.push(response.data.project)
      organizationLayout.setProjects(projects)

      app.addMessage('success', t('woo_commerce_connect_success', "WooCommerce has successfully been connected!"))
      setLoading(false)
    })
  }

  return <div style={{ width: '500px', margin: '32px auto' }}>
    <div className="paper padding-a-xl text-center">
      <img src={logoWooCommerce} alt="" style={{ height: '50px' }} />

      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item name="endpoint" label={t('enter_your_shop_url', "Enter your WooCommerce shop URL") + ':'} rules={[{ required: true, type: 'url' }]}>
          <Input placeholder="https://your-shop.com" />
        </Form.Item>
        <Form.Item name="consumerKey" label={t('enter_your_api_consumer_key', "Enter your API consumer key") + ':'} rules={[{ required: true, type: 'string' }]}>
          <Input.Password />
        </Form.Item>
        <Form.Item name="consumerSecret" label={t('enter_your_api_secret_key', "Enter your API consumer secret") + ':'} rules={[{ required: true, type: 'string' }]}>
          <Input.Password />
        </Form.Item>

        <Button size="large" type="primary" htmlType="submit" loading={loading}>{t('confirm', "Confirm")}</Button>
      </Form>
    </div>
  </div>
}

class IntegrationsWooCommerce extends Component<any, any> {
  _isMounted = false;

  constructor(props: any) {
    super(props)

    this.state = {
      webhooks: {},
      loading: false,
    }

    this.syncCustomers = this.syncCustomers.bind(this)
    this.syncProducts = this.syncProducts.bind(this)
    this.listConnectedWebhooks = this.listConnectedWebhooks.bind(this)
    this.reconnectWebhooks = this.reconnectWebhooks.bind(this)
  }

  componentDidMount() {
    this._isMounted = true;

    if (this.props.integration.status === 'connected') {
      this.listConnectedWebhooks()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  listConnectedWebhooks() {
    this.setState({ loading: true })

    this.props.app.ajaxRequest({
      method: 'get',
      url: '/integrations.listWooCommerceWebhooks',
      params: {
        projectId: this.props.projectLayout.project.id,
        integrationId: this.props.integration.id
      }
    }, (errorMessage: any, response: any) => {

      if (!this._isMounted) return

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }

      const webhooks: any = {}

      _.forEach(response.data.webhooks, (hook) => {
        webhooks[hook.topic] = true
      })

      // console.log('response.data', response.data);
      this.setState({
        webhooks: webhooks,
        loading: false
      })
    })
  }

  reconnectWebhooks() {
    this.setState({ loading: true })

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/integrations.reconnectWooCommerceWebhooks',
      data: {
        projectId: this.props.projectLayout.project.id,
        integrationId: this.props.integration.id
      }
    }, (errorMessage: any, response: any) => {

      if (!this._isMounted) return

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }

      let projects = _.filter(this.props.organizationLayout.projects, (p: any) => p.id !== response.data.project.id)
      projects.push(response.data.project)
      this.props.organizationLayout.setProjects(projects)

      this.props.app.addMessage('success', t('reconnect_webhooks_success', "Webhooks have been successfully reconnected!"))
      this.setState({ loading: false })
      this.listConnectedWebhooks()
    })
  }

  syncCustomers() {
    if (this.state.loading) {
      return
    }

    this.setState({ loading: true })

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/integrations.syncWooCommerceCustomers',
      data: {
        projectId: this.props.projectLayout.project.id,
        integrationId: this.props.integration.id,
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }
      this.props.app.addMessage('success', t('task_launched', "Task launched!"))

      this.setState({ loading: false })

      this.props.history.push('/organizations/' + this.props.organizationLayout.organization.id + '/projects/' + this.props.projectLayout.project.id + '/tasks')
    })
  }

  syncProducts() {
    if (this.state.loading) {
      return
    }

    this.setState({ loading: true })

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/tasks.create',
      data: {
        projectId: this.props.projectLayout.project.id,
        task: 'syncWooCommerceProducts',
        integrationId: this.props.integration.id,
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }
      this.props.app.addMessage('success', t('task_launched', "Task launched!"))

      this.setState({ loading: false })

      this.props.history.push('/organizations/' + this.props.organizationLayout.organization.id + '/projects/' + this.props.projectLayout.project.id + '/tasks')
    })
  }

  render() {
    // const info = integrations.find((int) => int.id === this.props.integration)

    const timezones = Moment.tz.names()

    return <div>
      <h2 className="margin-t-m">{t('settings', "Settings")}</h2>
      {(this.props.integration.status === 'setup') || (this.props.integration.status === 'disconnected') ?
        <ConnectWooCommerce
          integration={this.props.integration}
          app={this.props.app}
          organizationLayout={this.props.organizationLayout}
          projectLayout={this.props.projectLayout}
        />
        :
        <div className="margin-a-m">
          <table className="key-value-table">
            <tbody>
              <tr>
                <th style={{ width: '400px' }}>{t('integration_id', "Integration ID")}</th>
                <td>{this.props.integration.id}</td>
              </tr>
              <tr>
                <th>{t('status', "Status")}</th>
                <td>{this.props.integration.status === 'connected' && <span>
                  <Badge status="success" /> {t('connected', "Connected")}
                  <Popconfirm placement="top" title={t('disconnect_woocommerce_confirm', "Do you really want to disconnect WooCommerce? your data won't be synced anymore!")} onConfirm={this.props.disconnect.bind(null, this.props.integration)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                    <Button className="margin-l-m" loading={this.state.loading} ghost size="small" danger={true}>{t('disconnect', "Disconnect")}</Button>
                  </Popconfirm>
                </span>}</td>
              </tr>
              <tr>
                <th>{t('shop_url', "Shop URL")}</th>
                <td>{this.props.integration.wooCommerceSettings.endpoint}</td>
              </tr>
              <tr>
                <th>{t('is_woocommerce_users_source', "Is WooCommerce your main source of users?")}</th>
                <td>
                  <Popconfirm placement="top" title={t('toggle_main_source', "Do you really want to change the status of this source of users?")} onConfirm={this.props.toggleMainSource.bind(null, this.props.integration)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                    <Switch checked={this.props.integration.wooCommerceSettings.isMainSource} loading={this.state.loading} />
                  </Popconfirm>
                </td>
              </tr>
              <tr>
                <th>{t('is_source_of_newsletter_consent', "Is it the source of consent to newsletter?")}</th>
                <td>
                  <Popconfirm placement="top" title={t('toggle_consent_source', "Do you really want to change the status of this source of consent?")} onConfirm={this.props.toggleNewsletterSource.bind(null, this.props.integration)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                    <Switch checked={this.props.integration.wooCommerceSettings.isNewsletterConsentSource} loading={this.state.loading} />
                  </Popconfirm>
                </td>
              </tr>
              <tr>
                <th>{t('is_source_of_remarketing_consent', "Is it the source of consent to remarketing?")}</th>
                <td>
                  <Popconfirm placement="top" title={t('toggle_consent_source', "Do you really want to change the status of this source of consent?")} onConfirm={this.props.toggleRemarketingSource.bind(null, this.props.integration)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                    <Switch checked={this.props.integration.wooCommerceSettings.isRemarketingConsentSource} loading={this.state.loading} />
                  </Popconfirm>
                </td>
              </tr>
              <tr>
                <th>{t('geocode_customers_addresses', "Geocode customers addresses?")}</th>
                <td>
                  <Switch checked={this.props.integration.wooCommerceSettings.geocodeAddresses} loading={this.state.loading} onChange={this.props.toggleGeocodeAddresses.bind(null, this.props.integration)} />
                </td>
              </tr>
              <tr>
                <th>{t('geocode_customers_time_zones', "Geocode customers time zones?")}</th>
                <td>
                  <Switch checked={this.props.integration.wooCommerceSettings.geocodeTimezones} loading={this.state.loading} onChange={this.props.toggleGeocodeTimezones.bind(null, this.props.integration)} />
                </td>
              </tr>
              <tr>
                <th>{t('orders_conversion_rule', "Orders conversion rule")}</th>
                <td>
                  <Select
                    style={{ width: '200px' }}
                    placeholder={t('select_a_value', "Select a value")}
                    allowClear={false}
                    showSearch={true}
                    value={this.props.integration.wooCommerceSettings.conversionRuleId}
                    disabled={this.state.loading}
                    onChange={this.props.updateConversionRule.bind(null, this.props.integration)}>
                    {this.props.projectLayout.project.conversionRules.filter((cr: any) => cr.kind === "order").map((cr: any) => {
                      return <Select.Option key={cr.id} value={cr.id}>{cr.name}</Select.Option>
                    })}
                  </Select>
                </td>
              </tr>
              <tr>
                <th>{t('orders_conversion_domain', "Orders conversion domain")}</th>
                <td>
                  <Select
                    style={{ width: '200px' }}
                    placeholder={t('select_a_value', "Select a value")}
                    allowClear={false}
                    value={this.props.integration.wooCommerceSettings.conversionDomainId}
                    disabled={this.state.loading}
                    onChange={this.props.updateConversionDomain.bind(null, this.props.integration)}>
                    {this.props.projectLayout.project.domains.filter((d: any) => !d.deletedAt).map((d: any) => {
                      return <Select.Option key={d.id} value={d.id}>{d.name} - {d.kind}</Select.Option>
                    })}
                  </Select>
                </td>
              </tr>
              <tr>
                <th>{t('default_timezone', "Default customers time zone")}</th>
                <td>
                  <Select
                    style={{ width: '200px' }}
                    placeholder={t('select_a_value', "Select a value")}
                    allowClear={false}
                    showSearch={true}
                    value={this.props.integration.wooCommerceSettings.defaultTimezone}
                    disabled={this.state.loading}
                    onChange={this.props.updateDefaultTimezone.bind(null, this.props.integration)}
                    filterOption={(searchText: any, option: any) => {
                      return searchText !== '' && option.key.toLowerCase().includes(searchText.toLowerCase())
                    }}>
                    {timezones.map((timezone) => {
                      return <Select.Option key={timezone} value={timezone}>{timezone}</Select.Option>
                    })}
                  </Select>
                </td>
              </tr>
              <tr>
                <th>{t('default_language', "Default customers language")}</th>
                <td>
                  <Select
                    style={{ width: '200px' }}
                    placeholder={t('select_a_value', "Select a value")}
                    allowClear={false}
                    showSearch={true}
                    value={this.props.integration.wooCommerceSettings.defaultLanguage}
                    disabled={this.state.loading}
                    onChange={this.props.updateDefaultLanguage.bind(null, this.props.integration)}
                    filterOption={(searchText: any, option: any) => {
                      return searchText !== '' && option.key.toLowerCase().includes(searchText.toLowerCase())
                    }}>
                    {Languages.map((language) => {
                      return <Select.Option key={language.alpha2} value={language.alpha2}>{language.alpha2 + ' - ' + language.name}</Select.Option>
                    })}
                  </Select>
                </td>
              </tr>
              <tr>
                <th>{t('webhooks_connected', "Webhooks connected")}</th>
                <td>
                  {_.size(this.state.webhooks) > 0 && <div>
                    <Tag color={this.state.webhooks['product.created'] ? 'green' : 'red'} className="margin-b-s">product.created</Tag>
                    <Tag color={this.state.webhooks['product.deleted'] ? 'green' : 'red'} className="margin-b-s">product.deleted</Tag>
                    <Tag color={this.state.webhooks['product.updated'] ? 'green' : 'red'} className="margin-b-s">product.updated</Tag>
                    <Tag color={this.state.webhooks['order.created'] ? 'green' : 'red'} className="margin-b-s">order.created</Tag>
                    <Tag color={this.state.webhooks['order.deleted'] ? 'green' : 'red'} className="margin-b-s">order.deleted</Tag>
                    <Tag color={this.state.webhooks['order.updated'] ? 'green' : 'red'} className="margin-b-s">order.updated</Tag>
                    <Tag color={this.state.webhooks['customer.created'] ? 'green' : 'red'} className="margin-b-s">customer.created</Tag>
                    <Tag color={this.state.webhooks['customer.deleted'] ? 'green' : 'red'} className="margin-b-s">customer.deleted</Tag>
                    <Tag color={this.state.webhooks['customer.updated'] ? 'green' : 'red'} className="margin-b-s">customer.updated</Tag>
                    <Tag color={this.state.webhooks['coupon.created'] ? 'green' : 'red'} className="margin-b-s">coupon.created</Tag>
                    <Tag color={this.state.webhooks['coupon.deleted'] ? 'green' : 'red'} className="margin-b-s">coupon.deleted</Tag>
                    <Tag color={this.state.webhooks['coupon.updated'] ? 'green' : 'red'} className="margin-b-s">coupon.updated</Tag>
                  </div>}
                </td>
              </tr>
              <tr>
                <th>{t('actions', "Actions")}</th>
                <td>
                  <p>
                    <Popconfirm placement="top" title={t('sync_customers_from_woocommerce_confirm', "Do you really want to sync all your customers & orders from WooCommerce?")} onConfirm={this.syncCustomers} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                      <Button loading={this.state.loading} type="primary">{t('sync_customers_and_orders', "Sync customers & orders")}</Button>
                    </Popconfirm>
                  </p>

                  {/*<p>
                    <Popconfirm placement="top" title={t('sync_products_from_woocommerce_confirm', "Do you really want to sync your products from WooCommerce?")} onConfirm={this.syncProducts} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                      <Button loading={this.state.loading} type="primary">{t('sync_products', "Sync products")}</Button>
                    </Popconfirm>
                  </p>*/}

                  <Button loading={this.state.loading} type="ghost" onClick={this.reconnectWebhooks}>{t('reconnect_webhooks', "Reconnect webhooks")}</Button>

                </td>
              </tr>
            </tbody>
          </table>
        </div>
      }

    </div>
  }
}

export default IntegrationsWooCommerce