import React from 'react'
// import t from './translate'
import { Tag } from 'antd';
import logoShopify from '../images/integrations/shopify.png'
import logoWooCommerce from '../images/integrations/woocommerce.png'
import logoGoogleAds from '../images/integrations/google-ads.png'
import logoFacebook from '../images/integrations/facebook.png'
import logoMailchimp from '../images/integrations/mailchimp.png'
// import logoHubspot from '../images/integrations/hubspot.png'
// import logoMonday from '../images/integrations/monday.png'
import logoPostmark from '../images/integrations/postmark.png'
import logoSparkPost from '../images/integrations/sparkpost.png'
import logoTwilio from '../images/integrations/twilio.png'
import logoAmazon from '../images/integrations/amazon.png'
// import logoLazada from '../images/integrations/lazada.png'
import logoWebhook from '../images/integrations/webhook.png'
import logoAdmo from '../images/integrations/admo.png'
import logoGamned from '../images/integrations/gamned.png'
import logoKlaviyo from '../images/integrations/klaviyo.png'
// import logoSylius from '../images/integrations/sylius.png'
// import logoIntercom from '../images/integrations/intercom.png'
// import logoMixpanel from '../images/integrations/mixpanel.png'

const integrations = [
  {
    kind: 'shopify',
    name: 'Shopify',
    description: 'integration_shopify',
    logo: logoShopify,
    tags: [<Tag className="no-pointer" color="cyan">eCommerce</Tag>]
  },
  {
    kind: 'googleAds',
    name: 'Google Ads',
    description: 'integration_google_ads',
    logo: logoGoogleAds,
    tags: [<Tag className="no-pointer" color="purple">marketing</Tag>]
  },
  {
    kind: 'facebook',
    name: 'Facebook Ads',
    description: 'integration_facebook',
    logo: logoFacebook,
    tags: [<Tag className="no-pointer" color="purple">marketing</Tag>]
  },
  {
    kind: 'amazon',
    name: 'Amazon',
    description: 'integration_amazon',
    logo: logoAmazon,
    tags: [<Tag className="no-pointer" color="gold">marketplace</Tag>]
  },
  {
    kind: 'webhook',
    name: 'Webhook',
    description: 'integration_webhook',
    logo: logoWebhook,
    tags: [<Tag className="no-pointer" color="blue">workflow</Tag>]
  },
  {
    kind: 'sparkPost',
    name: 'SparkPost',
    description: 'integration_sparkpost',
    logo: logoSparkPost,
    tags: [<Tag className="no-pointer" color="magenta">email</Tag>]
  },
  {
    kind: 'twilio',
    name: 'Twilio',
    description: 'integration_twilio',
    logo: logoTwilio,
    tags: [<Tag className="no-pointer" color="volcano">SMS</Tag>]
  },
  {
    kind: 'mailchimp',
    name: 'Mailchimp',
    description: 'integration_mailchimp',
    logo: logoMailchimp,
    tags: [<Tag className="no-pointer" color="purple">marketing</Tag>]
  },
  {
    kind: 'postmark',
    name: 'Postmark',
    description: 'integration_postmark',
    logo: logoPostmark,
    tags: [<Tag className="no-pointer" color="magenta">email</Tag>]
  },
  {
    kind: 'klaviyo',
    name: 'Klaviyo',
    description: 'integration_klaviyo',
    logo: logoKlaviyo,
    tags: [<Tag className="no-pointer" color="purple">marketing</Tag>]
  },
  {
    kind: 'wooCommerce',
    name: 'WooCommerce',
    description: 'integration_woo_commerce',
    logo: logoWooCommerce,
    tags: [<Tag className="no-pointer" color="cyan">eCommerce</Tag>]
  },
  {
    kind: 'admo',
    name: 'Admo',
    description: 'integration_admo',
    logo: logoAdmo,
    tags: [<Tag className="no-pointer" color="purple">marketing</Tag>]
  },
  {
    kind: 'gamned',
    name: 'Gamned',
    description: 'integration_gamned',
    logo: logoGamned,
    tags: [<Tag className="no-pointer" color="purple">marketing</Tag>]
  },
  // {
  //   kind: 'lazada',
  //   name: 'Lazada',
  //   description: 'integration_lazada',
  //   logo: logoLazada,
  //   tags: [<Tag className="no-pointer" color="gold">marketplace</Tag>],
  //   soon: true
  // },
  // {
  //   kind: 'sylius',
  //   name: 'Sylius',
  //   description: 'integration_sylius',
  //   logo: logoSylius,
  //   tags: [<Tag className="no-pointer" color="cyan">eCommerce</Tag>]
  // },
  // {
  //   kind: 'hubspot',
  //   name: 'Hubspot',
  //   description: 'integration_hubspot',
  //   logo: logoHubspot,
  //   tags: [<Tag className="no-pointer" color="green">CRM</Tag>],
  //   soon: true
  // },
  // {
  //   kind: 'monday',
  //   name: 'Monday',
  //   description: 'integration_monday',
  //   logo: logoMonday,
  //   tags: [<Tag className="no-pointer" color="blue">workflow</Tag>],
  //   soon: true
  // },
  // {
  //   kind: 'intercom',
  //   name: 'Intercom',
  //   description: t('integration_intercom', "Sync your users & interactions."),
  //   logo: logoIntercom,
  //   tags: [<Tag className="no-pointer" color="magenta">messaging</Tag>]
  // },
  // {
  //   kind: 'mixpanel',
  //   name: 'Mixpanel',
  //   description: t('integration_mixpanel', "Sync your users & interactions."),
  //   logo: logoMixpanel,
  //   tags: [<Tag className="no-pointer" color="green">CRM</Tag>]
  // },
]

export default integrations