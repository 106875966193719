import React, { Component } from 'react'

import { 
  Select,
  Tag,
} from 'antd';
import t from '../../utils/translate'

class SelectGoogleAdsAudience extends Component<any, any> {

  constructor(props: any) {
    super(props)

    this.state = {
      loading: true,
      audiences: [],
    }

    this.fetchAudiences = this.fetchAudiences.bind(this)
  }

  componentDidMount() {
    this.fetchAudiences()
  }

  fetchAudiences() {
    this.props.app.ajaxRequest({
      method: 'get',
      url: '/integrations.listGoogleAdsAudiences',
      params: {
        projectId: this.props.project.id,
        integrationId: this.props.integrationId,
        audienceType: 'RULE_BASED'
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({loading: false})
        return
      }
      
      // console.log('audiences', response.data);

      this.setState({
        loading: false,
        audiences: response.data.audiences || []
      })
    })
  }

  render() {
    return <Select 
      placeholder={t('select_a_value', "Select a value")}
      value={this.props.value ? this.props.value.id : undefined}
      onChange={(audienceId) => {
        const audience = this.state.audiences.find((x: any) => x.id === audienceId)
        this.props.onChange({
          id: audienceId, 
          name: audience ? audience.name : ''
        })
      }}
      loading={this.state.loading}
    >
      {this.state.audiences.map((audience: any) => {
        return <Select.Option key={audience.id} disabled={audience.isReadOnly || audience.status === 'CLOSED'} value={audience.id}>{audience.name} {audience.isReadOnly && <Tag>read-only</Tag>} {audience.status === 'CLOSED' && <Tag>CLOSED</Tag>}</Select.Option>
      })}
    </Select>
  }
}

export default SelectGoogleAdsAudience



