import _ from 'lodash'

const caching = () => {
  const cache: any = {
    // storage
    data: {}
  }


  cache.set = (key: string, value: any, ttl: number) => {
    if (!ttl) {
      ttl = 3600 // 1 hour
    }

    const expireTimestamp = new Date().getTime()+ttl

    cache.data[key] = {
      value: value,
      expiresAt: expireTimestamp
    }
  }

  cache.get = (key: string) => {

    if (!cache.data[key]) {
      return null
    }

    // expired, delete key and return null
    if (new Date().getTime() > cache.data[key].ttl) {
      delete cache.data[key]
      return null
    }

    return cache.data[key].value
  }

  cache.getFromMap = (map: any) => {
    return cache.get(_.map(map, (v, k) => v).join('_'))
  }

  cache.setFromMap = (map: any, value: any) => {
    return cache.set(_.map(map, (v, k) => v).join('_'), value)
  }

  return cache
}

export default caching