import React from 'react';
import t from '../../utils/translate'

const ConversionRulesAbout = () => (
  <div className="margin-a-m">
    {<p>{t('conversion_rules_p1', "In Captain Metrics, a Conversion Rule defines a way to track your conversions, it can be an order, a lead or a subscription for recurring payments.")}</p>}
    {<p>{t('conversion_rules_p2', "A B2B conversion rule aggregates your users activity (pageviews, conversions...) at the company level. Users can be added to one or many companies.")}</p>}
    {/* {<p>{t('conversion_rules_p3', 'You can also track the impact of previous conversions between your conversion rules. I.e: an eCommerce website could have a traditional "order" shop and a "subscription" box (like birchbox.com); in this case you will see how many existing "order" customers subscribed to the box.')}</p>} */}
  </div>
);

export default ConversionRulesAbout

