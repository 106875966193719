import React, { Component } from 'react'

import { DeleteOutlined, EditOutlined, AreaChartOutlined } from '@ant-design/icons';


import { Table, Button, Tooltip, Badge, Popconfirm, Input, Spin, Select, Row, Col } from 'antd';
import t from '../../utils/translate'
import Moment from 'moment-timezone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import QS from 'qs'
import _ from 'lodash'
import UpsertUserButton from './_upsertUserButton'
import SegmentsMenu from './_segmentsMenu'
import SegmentFormButton from '../segments/_formButton'
import SegmentStats from '../analytics/segments/_stats'
import Formatters from '../../utils/formatters'
import AppLayout from 'components/app/layout';

const defaultParams: any = {
  limit: 15,
  skip: 0,
  sortKey: 'signedUpAt',
  sortOrder: 'desc',
  searchKey: 'lastName',
  segment: undefined
}

class UsersList extends Component<any, any> {
  _isMounted = false;

  constructor(props: any) {
    super(props)

    this.state = {
      loadingUsers: true,
      users: [],
      usersCount: 0,
    }

    this.fetchUsers = this.fetchUsers.bind(this)
    this.handleTableChange = this.handleTableChange.bind(this)
    this.updateParams = this.updateParams.bind(this)
    this.deleteSegment = this.deleteSegment.bind(this)
    this.changeSegment = this.changeSegment.bind(this)
    this.onSearch = this.onSearch.bind(this)
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;

    this.fetchUsers()

    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Users (project: ' + this.props.projectLayout.project.id + ')',
        page: this.props.location.pathname,
        props: {
          orgId: this.props.projectLayout.project.organizationId,
          projectId: this.props.projectLayout.project.id
        }
      })
      window.cmAgent.dispatch()
    }
  }

  componentDidUpdate(prevProps: any) {
    // check url params have changed
    if (prevProps.location.search !== this.props.location.search) {

      const params: any = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })
      const prevParams = QS.parse(prevProps.location.search, { ignoreQueryPrefix: true })

      // dont refresh on toggle user
      if (params.showUser !== prevParams.showUser) {
        return
      }

      this.fetchUsers()
    }
  }

  deleteSegment(segment: any) {
    if (this.state.loading === true) {
      return
    }
    else {
      this.setState({ loading: true })
    }

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/segments.delete',
      data: { projectId: this.props.projectLayout.project.id, segmentId: segment.id }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }

      let segments = _.filter(this.props.projectLayout.segments, (x: any) => x.id !== response.data.segment.id)
      segments.push(response.data.segment)
      this.props.projectLayout.setSegments(segments)

      this.props.app.addMessage('success', t('segment_deleted', "Your segment has successfully been removed!"))

      this.setState({ loading: false })
      this.props.history.push('/organizations/' + this.props.organizationLayout.organization.id + '/projects/' + this.props.projectLayout.project.id + '/users');
      this.props.projectLayout.refreshSegments()
    })
  }

  handleTableChange(pagination: any, filters: any, sorter: any) {
    // console.log('filters', filters);
    // console.log('pagination', pagination);
    // console.log('sorter', sorter);
    const params: any = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })

    const newParams = _.assign({
      limit: params.limit || defaultParams.limit,
      skip: params.skip || defaultParams.skip,
      sortKey: params.sortKey || defaultParams.sortKey,
      sortOrder: params.sortOrder || defaultParams.sortOrder,
      searchKey: params.searchKey || defaultParams.searchKey,
      searchValue: params.searchValue,
      segment: params.segment || defaultParams.segment,
    }, {
      limit: pagination.pageSize,
      skip: (pagination.current * pagination.pageSize) - pagination.pageSize,
      sortKey: sorter.columnKey,
      sortOrder: sorter.order,
      // segment: (filters.segments) ? filters.segments.join(',') : undefined,
    })

    this.props.history.push(window.location.pathname + '?' + QS.stringify(newParams, { indices: false }));
  }

  onSearch(value: any) {
    this.updateParams([{ k: 'searchValue', v: value }])
  }

  updateParams(updatedParams: any) {
    let params = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })
    updatedParams.forEach((up: any) => {
      params[up.k] = up.v
    })
    this.props.history.push(window.location.pathname + '?' + QS.stringify(params))
  }

  changeSegment(segmentId: string) {
    this.updateParams([
      { k: 'segment', v: segmentId },
      { k: 'limit', v: defaultParams.limit },
      { k: 'skip', v: defaultParams.skip },
    ])
  }

  fetchUsers() {

    this.setState({ loadingUsers: true })
    this.props.projectLayout.refreshSegments()

    const params: any = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })

    let query = {
      projectId: this.props.projectLayout.project.id,
      limit: params.limit || defaultParams.limit,
      skip: params.skip || defaultParams.skip,
      sortKey: params.sortKey || defaultParams.sortKey,
      sortOrder: params.sortOrder || defaultParams.sortOrder,
      searchKey: params.searchKey || defaultParams.searchKey,
      searchValue: params.searchValue || '',
      segment: params.segment || defaultParams.segment,
    }

    this.props.app.ajaxRequest({
      method: 'get',
      url: '/users.list',
      params: query
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        // this.setState({loadingUsers: false})
        return
      }

      // console.log('res', response.data);

      if (this._isMounted) {
        this.setState({
          users: response.data.users,
          usersCount: response.data.usersCount,
          loadingUsers: false
        })
      }
    })
  }

  render() {

    const segmentsMap = _.keyBy(this.props.projectLayout.segments.filter((s: any) => !s.forCompanies), (s) => s.id)

    const params: any = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })
    const currentSegmentId = params.segment || '_all'
    let currentSegment = segmentsMap[currentSegmentId]

    // console.log('segmentsMap', segmentsMap)
    // console.log('currentSegment', currentSegment)
    
    if (this.state.loadingSegments === false && !currentSegment) {
      return 'Segment not found...'
    }

    const skip = params.skip || defaultParams.skip
    const limit = params.limit || defaultParams.limit

    const page = (skip === 0) ? 1 : (skip / limit) + 1

    const SearchKey = <Select defaultValue={defaultParams.searchKey} onChange={(value: any) => this.updateParams([{ k: 'searchKey', v: value }])} style={{ width: 130 }} dropdownMatchSelectWidth={false}>
      <Select.Option value="externalId">ID</Select.Option>
      <Select.Option value="firstName">{t('first_name', "First name")}</Select.Option>
      <Select.Option value="lastName">{t('last_name', "Last name")}</Select.Option>
      <Select.Option value="email">Email</Select.Option>
      <Select.Option value="telephone">{t('telephone', "Telephone")}</Select.Option>
      <Select.Option value="twitterUsername">{t('twitter_username', "Twitter username")}</Select.Option>
      <Select.Option value="facebookUsername">{t('facebook_username', "Facebook username")}</Select.Option>
      <Select.Option value="websiteURL">{t('website_url', "Website URL")}</Select.Option>
      <Select.Option value="linkedInURL">{t('linkedin_url', "LinkedIn URL")}</Select.Option>
    </Select>

    return <AppLayout currentProject={this.props.projectLayout.project} currentOrganization={this.props.organizationLayout.organization} admin={this.props.app.state.admin} firebaseUser={this.props.app.state.firebaseUser} projects={this.props.organizationLayout.projects}>
      <Row gutter={24}>
        <Col span={5}>
          <h1>{t('segments', "Segments")}</h1>

          <div className="block padding-v-m margin-b-l">
            <SegmentsMenu loading={this.state.loadingSegments} changeSegment={this.changeSegment} currentSegment={currentSegment} segments={this.props.projectLayout.segments} />
          </div>

          <UpsertUserButton
            block
            type="ghost"
            app={this.props.app}
            project={this.props.projectLayout.project}
            onComplete={() => { this.fetchUsers(); this.props.projectLayout.refreshSegments() }}
            title={t('new_user', "New user")}
          >{t('new_user', "New user")}</UpsertUserButton>

          <div className="margin-t-m">
            <SegmentFormButton
              block
              type="ghost"
              app={this.props.app}
              kind="user"
              organizationLayout={this.props.organizationLayout}
              projectLayout={this.props.projectLayout}
              onComplete={() => this.props.projectLayout.refreshSegments()}
              title={t('new_segment', "New segment")}
            >{t('new_segment', "New segment")}</SegmentFormButton>
          </div>
        </Col>

        <Col span={19}>

          <h1>
            {this.state.loadingSegments ? <Spin /> : <React.Fragment>
              <div className="actions">
                <Input.Search style={{ width: '400px' }} allowClear placeholder={t('search', "Search")} defaultValue={params.searchValue} onSearch={this.onSearch} addonBefore={SearchKey} />

                {currentSegment.id !== 'anon' && currentSegment.id !== '_all' && <Button.Group style={{ display: 'inline-block' }} className="margin-l-m">
                  {!currentSegment.isDefault && <Popconfirm title={t('delete_segment', "Do you really want to delete this segment?")} onConfirm={this.deleteSegment.bind(null, currentSegment)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                    <Button type="ghost"><DeleteOutlined /></Button>
                  </Popconfirm>}
                  {!currentSegment.isDefault && <SegmentFormButton
                    app={this.props.app}
                    kind="user"
                    segment={currentSegment}
                    organizationLayout={this.props.organizationLayout}
                    projectLayout={this.props.projectLayout}
                    onComplete={() => this.props.projectLayout.refreshSegments()}
                    title={t('edit_segment', "Edit segment")}
                    type="ghost"
                  ><EditOutlined /></SegmentFormButton>}
                  <SegmentStats btnType="ghost" segment={currentSegment} app={this.props.app} projectLayout={this.props.projectLayout}><AreaChartOutlined /></SegmentStats>
                </Button.Group>}
              </div>

              {currentSegment.name}
              {(currentSegment.status === "refreshing") && <span className="padding-l-s"><Badge status="processing" text={t('refreshing', "refreshing")} /></span>}
              {(currentSegment.status === "queued") && <span className="padding-l-s"><Badge status="warning" text={t('refreshing_soon', "refreshing soon")} /></span>}
            </React.Fragment>}
          </h1>

          <Table
            dataSource={this.state.users}
            rowKey="id"
            size="middle"
            className="edge-table block"
            onChange={this.handleTableChange}
            loading={this.state.loadingUsers || this.state.loadingSegments}
            pagination={{
              position: ['bottomCenter'],
              pageSize: Number(params.limit || defaultParams.limit),
              total: currentSegment ? currentSegment.count : 0,
              current: page
            }}
            columns={[
              {
                key: 'id',
                title: 'ID',
                render: user => {
                  if (user.externalId.length > 15) {
                    return <Tooltip title={user.externalId}><span className="size-12">{_.truncate(user.externalId, { length: 16, omission: '...' })}</span></Tooltip>
                  }
                  return <span className="size-12">{user.externalId}</span>
                }
              },
              {
                key: 'name',
                title: t('name', "Name"),
                render: user => <div>
                  {user.photoURL ? <img className="user-picture" src={user.photoURL} alt="" /> : <span className="user-picture-icon"><FontAwesomeIcon icon="user-circle" /></span>}
                  <b className="padding-l-s">{(user.firstName || '') + ' ' + (user.lastName || '')}</b>
                </div>
              },
              {
                key: 'gender',
                title: '',
                width: '6px',
                render: user => <div>
                  {user.gender && <FontAwesomeIcon icon={user.gender} style={{ color: user.gender === 'male' ? 'rgba(178,235,242 ,1)' : 'rgba(248,187,208 ,1)' }} />}
                </div>
              },
              {
                key: 'segments',
                title: t('segments', "Segments"),
                render: user => user.segments.filter((s: any) => !s.exitAt && !s.deletedAt).map((s: any) => {
                  if (!segmentsMap[s.segmentId]) return null
                  return <div key={s.segmentId}>
                    <Tooltip title={Moment(s.enterAt).fromNow()}>
                      {Formatters.segment(this.props.projectLayout.segments, s.segmentId, 'xsmall', true)}
                      {/* <Tag className="margin-b-s no-pointer xsmall" color={segmentsMap[s.segmentId].color !== 'default' && segmentsMap[s.segmentId].color}>{segmentsMap[s.segmentId].name}</Tag> */}
                    </Tooltip>
                  </div>
                })
              },
              {
                key: 'contact',
                title: t('details', "Details"),
                render: user => <div>
                  {user.email && <div className="padding-b-xs"><FontAwesomeIcon className="table-icon" icon="envelope" /> <a href={'mailto:' + user.email}>{user.email}</a></div>}
                  {user.telephone && <div className="padding-b-xs"><FontAwesomeIcon className="table-icon" icon="phone" /> <a href={'tel:' + user.telephone}>{user.telephone}</a></div>}
                  {user.twitterUsername && <div className="padding-b-xs"><FontAwesomeIcon className="table-icon" icon={["fab", "twitter"]} /> <a href={'https://twitter.com/' + user.twitterUsername} target="_blank" rel="noopener noreferrer">{user.twitterUsername}</a></div>}
                  {(user.facebookUsername || user.facebookId) && <div><FontAwesomeIcon className="table-icon" icon={["fab", "facebook"]} /> <a href={'https://web.facebook.com/' + (user.facebookUsername || user.facebookId)} target="_blank" rel="noopener noreferrer">{user.facebookUsername || user.facebookId}</a></div>}
                </div>
              },
              {
                key: 'signedUp',
                title: t('signed_up', "Signed up"),
                render: user => <span className="size-12">{user.signedUpAt ? Moment(user.signedUpAt).fromNow() : ''}</span>
              },
              {
                key: 'createdAt',
                title: t('created_at', "Created at"),
                render: user => <span className="size-12">{Moment(user.createdAt).fromNow()}</span>
              },
              {
                key: 'from',
                title: <FontAwesomeIcon icon="location-arrow" style={{ color: 'rgba(0,0,0, 0.5)' }} />,
                render: user => user.country || ''
              },
              {
                key: 'actions',
                title: <span className="pull-right"><Button type="ghost" size="small" onClick={this.fetchUsers} disabled={this.state.loading}><FontAwesomeIcon icon="sync-alt" spin={this.state.loading} /></Button></span>,
                className: 'text-right',
                render: user => <Button.Group>
                  <UpsertUserButton app={this.props.app} project={this.props.projectLayout.project} type="ghost" size="small" title={t('edit_profile', "Edit profile")} user={user} onComplete={this.fetchUsers}><EditOutlined /></UpsertUserButton>
                  <Button size="small" type="ghost" onClick={this.props.projectLayout.viewUser.bind(null, user.externalId)}><FontAwesomeIcon icon="search" style={{ color: 'rgba(0,0,0, 0.5)' }} /></Button>
                </Button.Group>
              },
            ]}
          />
        </Col>
      </Row>
    </AppLayout>
  }
}

export default UsersList