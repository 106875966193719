import React from 'react'
import _ from 'lodash'
import DataSet from '@antv/data-set'
import mapData from '../../utils/worldMap2'
import Countries from '../../utils/countries'
import Formatters from '../../utils/formatters'
import {
  Chart,
  Geom,
  Tooltip,
  View,
  Legend,
  // Coordinate,
  Axis,
} from "bizcharts"

// create a view from data-set, with the GeoJSON connector
const worldMapView: any = new DataSet.View().source(mapData, { type: 'GeoJSON' })
const countriesMap = _.keyBy(Countries, 'code')


const GraphMap = (props: any) => {

  let width = 200

  const containerRef = document.getElementById('mapContainerRef')

  if (containerRef) {
    width = containerRef.offsetWidth * 0.66
  }

  const data: any = []

  props.data.forEach((x: any) => {
    data.push({
      key: x.key,
      percentage: x.percentage * 100,
    })
  })

  // create another view with user data
  // converts the user data to a geo map, by mapping the field "name"
  const userDataView = new DataSet.View()
    .source(props.data)
    .transform({
      geoDataView: worldMapView,
      field: 'key', // mapping of user data with worldMapView
      type: 'geo.region',
      as: ['longitude', 'latitude']
    })

  const chartUserView: any = new DataSet.View().source(userDataView)

  return <Chart
    height={width}
    scale={{
      longitude: { sync: true },
      latitude: { sync: true },
    }}
    autoFit
    padding={[0, 0, 0, 0]}
  >
    {/* <Coordinate /> */}
    <Axis visible={false} />
    <Legend visible={false} />
    <Tooltip showTitle={false} />
    <View data={worldMapView}>
      <Geom
        type="polygon"
        position="longitude*latitude"
        style={{
          fill: '#FFFFFF',
          stroke: '#CFD8DC',
          lineWidth: 1
        }}
        tooltip={false}
      />
      <Tooltip visible={false} />
    </View>
    <View data={chartUserView}>
      <Geom
        type="polygon"
        position="longitude*latitude"
        color={['percentage', p => 'rgba(78,108,255,'+p+')']}
        // opacity="percentage"
        tooltip={['key*percentage', (key, percentage) => {
          // console.log('percentage', percentage)
          return {
            name: countriesMap[key].name,
            value: Formatters.percentage(percentage)
          }
        }]}
        animate={{
          leave: {
            animation: 'fadeOut'
          }
        }}
      />
    </View>
  </Chart>
}

export default GraphMap