import { useEffect } from 'react'
import { Tabs } from 'antd';
import t from '../../utils/translate'
import QS from 'qs'
import Topics from './topics'
import Templates from './templates'
import Macros from './macros'
import { useProjectContext, ProjectContextValue } from '../projects'
import { useHistory, useLocation } from 'react-router-dom'
import AppLayout from 'components/app/layout';

const Notifications = () => {

    const projectCtx: ProjectContextValue = useProjectContext()
    const history = useHistory() as any
    const location = useLocation() as any

    useEffect(() => {
        if (window.cmAgent) {
            window.cmAgent.pageview({
                title: 'Notifications (project: ' + projectCtx.currentProject.id + ')',
                page: location.pathname,
                props: {
                    orgId: projectCtx.currentProject.organizationId,
                    projectId: projectCtx.currentProject.id
                }
            })
            window.cmAgent.dispatch()
        }
    }, [projectCtx.currentProject.id, projectCtx.currentProject.organizationId, location.pathname])


    const changeTab = (value: any) => {
        let params = QS.parse(location.search, { ignoreQueryPrefix: true })
        params.tab = value
        history.push('/organizations/' + projectCtx.currentOrganization.id + '/projects/' + projectCtx.currentProject.id + '/notifications?' + QS.stringify(params))
    }


    const params: any = QS.parse(location.search, { ignoreQueryPrefix: true })

    const tab = params.tab || 'templates'

    return <AppLayout currentProject={projectCtx.currentProject} currentOrganization={projectCtx.currentOrganization} admin={projectCtx.admin} firebaseUser={projectCtx.firebaseUser} projects={projectCtx.projects}>

        <h1>Notifications</h1>

        <Tabs activeKey={tab} onChange={changeTab}>
            <Tabs.TabPane tab="Templates" key="templates">
                {tab === 'templates' && <Templates />}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Macros" key="macros">
                {tab === 'macros' && <Macros />}
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('topics', "Topics")} key="topics">
                {tab === 'topics' && <Topics />}
            </Tabs.TabPane>
        </Tabs>
    </AppLayout>
}

export default Notifications