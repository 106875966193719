import { useState } from 'react'

import { LockOutlined, UserOutlined } from '@ant-design/icons';

import { Input, Button, Row, Col, Modal, Form, message } from 'antd';
import t from '../../utils/translate'
import SignUpForm from './_signUpForm'
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, sendPasswordResetEmail } from 'firebase/auth'
import { useAppContext, AppContextValue } from '../app'
import {
  useLocation,
} from 'react-router-dom'

const LoginForm = () => {

  const [loading, setLoading] = useState(false)
  const [showSignUp, setShowSignUp] = useState(false)
  const [form] = Form.useForm()
  const appCtx: AppContextValue = useAppContext()
  const location = useLocation()

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider()

    // the change observer will trigger as soon as the user is logged in
    signInWithPopup(appCtx.firebaseAuth.current, provider).catch((error: any) => {
      console.error(error)
    })
  }

  const onFinish = (values: any) => {

    if (loading === true) {
      return
    } else {
      setLoading(true)
    }

    form.resetFields()

    signInWithEmailAndPassword(appCtx.firebaseAuth.current, values.email, values.password).catch((error: any) => {
      // var errorCode = error.code;
      // var errorMessage = error.message;
      console.error('sign in form error', error)

      if (error.code === "auth/user-not-found") {
        error.message = t('user_not_found', "User not found.")
      }

      if (error.code === "auth/wrong-password") {
        error.message = t('wrong_password', "The password is invalid.")
      }

      message.error(error.message)
      setLoading(false)
    })
  }

  if (showSignUp === true) {
    return <SignUpForm />
  }

  return <div>
    <Button type="primary" ghost size="large" onClick={signInWithGoogle}>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48" className="google-svg"><g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path><path fill="none" d="M0 0h48v48H0z"></path></g></svg>
      {t('sign_in_with_google', "Sign in with Google")}
    </Button>

    <div className="text-center margin-v-l">{t('or_sign_in_with_credentials', "Or sign in with credentials")}:</div>

    <Row>
      <Col span={16} offset={4}>
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Form.Item
            name="email"
            rules={[{ type: 'email', required: true, message: t('field_required', "This field is required") }]}
          >
            <Input size="large" prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={t('email', "Email")} />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: t('field_required', "This field is required!") }]}
          >
            <Input size="large" prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder={t('password', "Password")} />
          </Form.Item>

          <Form.Item>
            <Button type="primary" loading={loading} size="large" htmlType="submit" style={{ width: '100%' }}>{t('sign_in', "Sign in")}</Button>

            <ForgotPassword />
          </Form.Item>
        </Form>

        {location.pathname.includes('/invitation') && <>
          {t('dont_have_account', "Don't have an account?")} <span className="link" onClick={() => setShowSignUp(true)}>{t('sign_up_for_free', "Sign up for free")}</span>
        </>}
      </Col>
    </Row>
  </div>
}

export default LoginForm

const ForgotPassword = () => {

  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [form] = Form.useForm()
  const appCtx: AppContextValue = useAppContext()

  const toggleModal = () => {
    setShow(!show)
  }

  const onFinish = (values: any) => {

    if (loading === true) {
      return
    } else {
      setLoading(true)
    }

    form.resetFields()

    // console.log(appCtx.firebaseAuth.current)
    sendPasswordResetEmail(appCtx.firebaseAuth.current, values.email).then(() => {
      setLoading(false)
      setShow(false)
      message.success(t('reset_password_sent', "An email containing instructions to reset your password has been sent to {email}!", { email: values.email }))
    })
      .catch((error: any) => {
        // var errorCode = error.code;
        // var errorMessage = error.message;
        console.error('reset password error', error)

        if (error.code === "auth/user-not-found") {
          error.message = t('user_not_found', "User not found.")
        }

        message.error(error.message)
        setLoading(false)
      })
  }

  if (!show) {
    return <div className="link margin-v-m" onClick={toggleModal}>{t('forgot_password', "Forgot password?")}</div>
  }

  return <Modal
    title={t('forgot_password', "Forgot password?")}
    visible={true}
    onCancel={toggleModal}
    footer={false}
  >
    <Form form={form} onFinish={onFinish}>
      <Form.Item
        name="email"
        rules={[{ type: 'email', required: true, message: t('field_required', "This field is required") }]}
      >
        <Input size="large" prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={t('email', "Email")} />
      </Form.Item>

      <Form.Item>
        <Button type="primary" loading={loading} size="large" htmlType="submit" style={{ width: '100%' }}>{t('send_me_a_new_password', "Send me a new password")}</Button>
      </Form.Item>
    </Form>
  </Modal>
}