import { useEffect } from 'react'

import t from '../../utils/translate'
import Splash from './_splash'
import LoginForm from './_loginForm'

const Login = () => {

  useEffect(() => {
    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Login',
        page: '/login' // this.props.location.pathname
      })
      window.cmAgent.dispatch()
    }
  }, [])

  return <Splash h1={t('sign_in', "Sign in")}>
    <LoginForm />
  </Splash>
}

export default Login