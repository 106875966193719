import { useState, useRef, useEffect, useCallback } from 'react'

import { LoadingOutlined } from '@ant-design/icons';

import { Spin, Button, Badge, Popconfirm, Tooltip, TreeSelect, Modal, message, DatePicker } from 'antd';
import t from '../../utils/translate'
import { forEach, size, map } from 'lodash'
import Config from '../../config'
// import Doc from '../docs/_doc'
import logoGoogleAds from '../../images/integrations/google-ads.png'
import Moment from 'moment-timezone'
import { useProjectContext, ProjectContextValue } from 'components/projects'

const IntegrationsGoogleAds = ({ integration, disconnect }: any) => {

  const [ready, setReady] = useState(false)
  const [loadingConnect, setLoadingConnect] = useState(false)
  const [loadingAccounts, setLoadingAccounts] = useState(true)
  const [accounts, setAccounts] = useState(undefined)

  const didMount = useRef(undefined)

  const projectCtx: ProjectContextValue = useProjectContext()


  const fetchGoogleAdsAccounts = useCallback(() => {
    projectCtx.ajaxRequest({
      method: 'get',
      url: '/integrations.listGoogleAdsAccounts',
      params: {
        projectId: projectCtx.currentProject.id,
        integrationId: integration.id
      }
    }, (errorMessage: any, response: any) => {

      setLoadingAccounts(false)

      if (errorMessage) {
        message.error(errorMessage)
        return
      }
      // console.log('accounts', response.data);

      setAccounts(response.data.accounts)
    })
  }, [integration.id, projectCtx])

  const openPopup = () => {
    window.gapi.auth2.getAuthInstance().grantOfflineAccess({
      scope: 'https://www.googleapis.com/auth/adwords',
      include_granted_scopes: true,
      access_type: 'offline',
      response_type: 'code',
      prompt: 'consent' // forces to get a refresh token again
    }).then((resp: any) => {
      // console.log('resp', resp)
      setLoadingConnect(true)

      projectCtx.ajaxRequest({
        method: 'post',
        url: '/integrations.getGoogleAdsRefreshToken',
        data: {
          projectId: projectCtx.currentProject.id,
          integrationId: integration.id,
          code: resp.code,
        }
      }, (errorMessage: any, response: any) => {

        // console.log('res data', response.data);

        if (errorMessage) {
          message.error(errorMessage)
          setLoadingConnect(false)
          return
        }

        projectCtx.ajaxRequest({
          method: 'post',
          url: '/integrations.connect',
          data: {
            projectId: projectCtx.currentProject.id,
            integrationId: integration.id,
            googleAds: {
              accessToken: response.data.accessToken,
              accessTokenExpiresAt: Moment().add(response.data.expiresIn - 60, 's').toISOString(),
              refreshToken: response.data.refreshToken,
              scope: response.data.scope
            }
          }
        }, (errorMessage: any, response: any) => {

          setLoadingConnect(false)

          if (errorMessage) {
            message.error(errorMessage)
            return
          }

          projectCtx.updateProject(response.data.project)

          message.success(t('google_ads_connect_success', "Google Ads has successfully been connected!"))
        })
      })

    }).catch((e: any) => {
      console.error(e)
      message.error(e.message)
    })
  }

  const onSetAccount = (accountId: string, label: any) => {
    // console.log('set account', accountId);

    let managerAccount: any = null
    let account: any = null

    forEach(accounts, acc => {
      if (acc.id === accountId) {
        account = acc
      } else if (size(acc.subCustomers) > 0) {
        forEach(acc.subCustomers, subAcc => {
          if (subAcc.id === accountId) {
            managerAccount = acc
            account = subAcc
          }
        })
      }
    })

    if (!account) {
      console.error('Google Ads account not found?')
      return
    }

    Modal.confirm({
      title: t('are_you_sure', "Are you sure?"),
      content: t('confirm_set_google_ads_account', "You are about to link your Google Ads account {name} with this projet.", { name: label }),
      okText: t('confirm', "Confirm"),
      cancelText: t('cancel', "Cancel"),
      onOk: () => {
        setLoadingAccounts(true)

        projectCtx.ajaxRequest({
          method: 'post',
          url: '/integrations.setGoogleAdsAccount',
          data: {
            projectId: projectCtx.currentProject.id,
            integrationId: integration.id,
            accountId: account.id,
            accountName: account.descriptiveName,
            timezone: account.timeZone,
            currency: account.currencyCode,
            managerAccountId: managerAccount ? managerAccount.id : '',
            managerAccountName: managerAccount ? managerAccount.descriptiveName : '',
          }
        }, (errorMessage: any, response: any) => {

          setLoadingAccounts(false)

          if (errorMessage) {
            message.error(errorMessage)
            return
          }

          projectCtx.updateProject(response.data.project)

          message.success(t('google_ads_set_account_success', "Your Google Ads account has successfully been linked to your project!"))
        })
      }
    })
  }


  useEffect(() => {
    if (didMount.current) return
    didMount.current = true

    // window.gapi.auth2.init(params)
    window.gapi.load('client', () => {
      window.gapi.client.init({
        apiKey: Config().google_api_key,
        clientId: Config().google_client_id,
        scope: 'https://www.googleapis.com/auth/adwords',
        include_granted_scopes: true,
        access_type: 'offline',
        response_type: 'code'
      })
      setReady(true)
    })

  }, [])

  useEffect(() => {
    // load accounts tree

    if (!accounts && integration.status === 'connected') {
      fetchGoogleAdsAccounts()
    }

  }, [fetchGoogleAdsAccounts, accounts, integration.status])


  if (ready === false) {
    return <div className="text-center margin-a-xl">
      <Spin size="large" />
    </div>
  }

  const accountsTree = map(accounts, acc => {
    return {
      title: acc.descriptiveName + ' - ' + acc.id,
      value: acc.id,
      selectable: !acc.manager,
      children: size(acc.subCustomers) > 0 ? map(acc.subCustomers, subAcc => {
        return {
          title: subAcc.descriptiveName + ' - ' + subAcc.id,
          value: subAcc.id
        }
      }) : undefined
    }
  })

  return <div>
    <h2 className="margin-t-m">{t('settings', "Settings")}</h2>
    {(integration.status === 'setup') || (integration.status === 'disconnected') ?
      <div style={{ width: '500px', margin: '32px auto' }}>
        <div className="paper padding-a-xl text-center">
          <img src={logoGoogleAds} alt="" />
          <Button type="primary" size="large" loading={loadingConnect} onClick={openPopup}>{t('connect_my_google_ads', "Connect my Google Ads")}</Button>
        </div>
      </div>
      :
      <div className="margin-a-m">
        <table className="key-value-table">
          <tbody>
            <tr>
              <th style={{ width: '300px' }}>{t('integration_id', "Integration ID")}</th>
              <td>{integration.id}</td>
            </tr>
            <tr>
              <th>{t('status', "Status")}</th>
              <td>{integration.status === 'connected' && <span>
                <Badge status="success" /> {t('connected', "Connected")}
                <Popconfirm placement="top" title={t('disconnect_google_ads_confirm', "Do you really want to disconnect Google Ads? your data won't be synced anymore!")} onConfirm={disconnect.bind(null, integration)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                  <Button className="margin-l-m" ghost size="small" danger={true}>{t('disconnect', "Disconnect")}</Button>
                </Popconfirm>
              </span>}
              </td>
            </tr>
            <tr>
              <th>API scope</th>
              <td>{integration.googleAdsSettings.scope.split(' ').map((s: any, i: number) => <div key={i}>{s}</div>)}</td>
            </tr>
            {integration.googleAdsSettings.clicksSyncedAt && <tr>
              <th>{t('last_gclids_sync', "Last Google click Ids sync")}</th>
              <td><Tooltip title={Moment(integration.googleAdsSettings.clicksSyncedAt).tz(projectCtx.admin.timezone).format('lll') + ' (' + projectCtx.admin.timezone + ')'}>{Moment(integration.googleAdsSettings.clicksSyncedAt).fromNow()}</Tooltip></td>
            </tr>}
            <tr>
              <th>{t('account', "Account")}</th>
              <td>
                <TreeSelect
                  style={{ width: 350 }}
                  value={integration.googleAdsSettings.accountId}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  placeholder={loadingAccounts ? "Loading..." : t('please_select_an_account', "Please select an account")}
                  treeData={accountsTree}
                  treeDefaultExpandAll
                  disabled={loadingAccounts}
                  suffixIcon={loadingAccounts ? <LoadingOutlined /> : undefined}
                  onChange={onSetAccount}
                />
              </td>
            </tr>
            {integration.googleAdsSettings.lastDateProcessed && <tr>
              <th>Last date processed</th>
              <td>{integration.googleAdsSettings.lastDateProcessed}</td>
            </tr>}
            <tr>
              <th>{t('actions', "Actions")}</th>
              <td>
                <SyncModal integration={integration} />
              </td>
            </tr>
          </tbody>
        </table>
        {/*<h2 className="margin-t-m">{t('instructions', "Instructions")}</h2>
            <Doc className="margin-h-m" id="integration_google_ads_instructions_en" />*/}
      </div>
    }
  </div>
}


const SyncModal = ({ integration }: any) => {
  // console.log('int', JSON.stringify(integration))
  const [loading, setLoading] = useState(false)
  // const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [syncFrom, setSyncFrom] = useState(Moment.utc().subtract(30, 'days'))
  const projectCtx: ProjectContextValue = useProjectContext()
  const threeMonthsAgo = Moment().subtract(90, 'day')
  const yesterday = Moment().tz(integration.googleAdsSettings.timezone || 'UTC').subtract(25, 'hours')

  return <>
    <Button loading={loading} onClick={() => setModalVisible(true)}>Resync GCLIDs</Button>

    <Modal
      title="Resync GCLIDs"
      okText="Start"
      okButtonProps={{loading: loading}}
      cancelButtonProps={{loading: loading}}
      visible={modalVisible}
      onCancel={() => setModalVisible(false)}
      onOk={() => {
        if (loading) return

        setLoading(true)

        projectCtx.ajaxRequest({
          method: 'post',
          url: '/integrations.syncGCLIDs',
          data: {
            projectId: projectCtx.currentProject.id,
            integrationId: integration.id,
            fromDate: syncFrom.toISOString(),
          }
        }, (errorMessage: any, _response: any) => {

          if (errorMessage) {
            message.error(errorMessage)
            setLoading(false)
            return
          }

          message.success(t('sync_launched', 'The sync has been launched!'))
          setLoading(false)
          setModalVisible(false)
        })
      }}
    >
      <p>From date:</p>
      <div className="margin-b-m">
        <DatePicker
          value={syncFrom}
          allowClear={false}
          autoFocus={true}
          onChange={(date: Moment.Moment) => setSyncFrom(date)}
          disabledDate={(date: Moment.Moment) => {
            return date.isBefore(threeMonthsAgo) || date.isAfter(yesterday)
          }}
        /> UTC
      </div>
    </Modal>
  </>
}

export default IntegrationsGoogleAds