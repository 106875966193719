import React, { useState, MouseEvent } from "react"
import { Item } from '../core/interfaces'
import {
    Dropdown,
    Menu,
    message,
    Modal,
} from 'antd'
import { MoreOutlined, DeleteOutlined, ExclamationCircleOutlined, ExportOutlined } from '@ant-design/icons'


export interface ItemMenuProps {
    // fileProvider: FileProvider
    item: Item
    resolveFileURL: (item: Item) => string
    deleteNode: (node: Item) => Promise<any>
}

const ItemMenu = (props: ItemMenuProps) => {

    const [loading, setLoading] = useState(false)

    const onDelete = () => {
        Modal.confirm({
            title: 'Do you want to delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deleting "' + props.item.name + '" will remove it from the list but keep it online.',
            okButtonProps: { danger: true, loading: loading },
            cancelButtonProps: { loading: loading },
            okText: "Delete",
            closable: !loading,
            onOk() {
                setLoading(true)
                props.deleteNode(props.item).then(() => {
                    setLoading(false)
                    
                }).catch(e => {
                    setLoading(false)
                    message.error(e)
                })
            },
        })
    }

    return <span onClick={(e: MouseEvent) => e.stopPropagation()}>
        <Dropdown className="fm-table-item-icon" trigger={['click']} overlay={<Menu>
            <Menu.Item key="open" icon={<ExportOutlined />}><a href={props.resolveFileURL(props.item)} target="_blank" className="fm-action-icon" rel="noreferrer">Open in a window</a></Menu.Item>
            <Menu.Item key="delete" icon={<DeleteOutlined />} danger onClick={onDelete}>Delete</Menu.Item>
        </Menu>}>
            <MoreOutlined />
        </Dropdown></span>
}

export default ItemMenu