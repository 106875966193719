import React, { Component } from 'react'

import { Table, Spin, Button, Tooltip, Popconfirm, Switch, Badge } from 'antd';
import {
  // ClockCircleFilled,
  // ThunderboltFilled,
  // PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  CaretRightOutlined,
  FacebookOutlined,
  GoogleOutlined,
} from '@ant-design/icons';
import t from '../../utils/translate'
import Formatters from '../../utils/formatters'
import Moment from 'moment-timezone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SegmentSyncButton from './_syncDrawer'
import AppLayout from 'components/app/layout';

class SegmentsSync extends Component<any, any> {
  _isMounted = false;

  constructor(props: any) {
    super(props)

    this.state = {
      loading: false,
      list: [],
    }

    this.fetchList = this.fetchList.bind(this)
    this.activateSegmentSync = this.activateSegmentSync.bind(this)
    this.pauseSegmentSync = this.pauseSegmentSync.bind(this)
    this.deleteSegmentSync = this.deleteSegmentSync.bind(this)
    this.syncNow = this.syncNow.bind(this)
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchList()

    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Segments Sync (project: ' + this.props.projectLayout.project.id + ')',
        page: this.props.location.pathname,
        props: {
          orgId: this.props.projectLayout.project.organizationId,
          projectId: this.props.projectLayout.project.id
        }
      })
      window.cmAgent.dispatch()
    }
  }

  componentDidUpdate(prevProps: any) {
    // check url params have changed
    if (prevProps.location.search !== this.props.location.search) {
      this.fetchList()
    }
  }

  activateSegmentSync(segmentSyncId: any) {

    if (this.state.loading === true) {
      return
    }

    this.setState({ loading: true })

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/segmentSyncs.activate',
      data: {
        segmentSyncId: segmentSyncId,
        projectId: this.props.projectLayout.project.id,
      }
    }, (errorMessage: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }

      this.props.app.addMessage('success', t('segment_sync_activated', "The segment sync has been activated!"))

      if (this._isMounted) {
        this.setState({ loading: false })
        this.fetchList()
      }
    })
  }

  pauseSegmentSync(segmentSyncId: any) {

    if (this.state.loading === true) {
      return
    }

    this.setState({ loading: true })

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/segmentSyncs.pause',
      data: {
        segmentSyncId: segmentSyncId,
        projectId: this.props.projectLayout.project.id,
      }
    }, (errorMessage: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }

      this.props.app.addMessage('success', t('segment_sync_paused', "The segment sync has been paused!"))

      if (this._isMounted) {
        this.setState({ loading: false })
        this.fetchList()
      }
    })
  }

  deleteSegmentSync(segmentSyncId: any) {

    if (this.state.loading === true) {
      return
    }

    this.setState({ loading: true })

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/segmentSyncs.delete',
      data: {
        segmentSyncId: segmentSyncId,
        projectId: this.props.projectLayout.project.id,
      }
    }, (errorMessage: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }

      this.props.app.addMessage('success', t('segment_sync_deleted', "The segment sync has been deleted!"))

      if (this._isMounted) {
        this.setState({ loading: false })
        this.fetchList()
      }
    })
  }

  syncNow(segmentSyncId: any) {

    if (this.state.loading === true) {
      return
    }

    this.setState({ loading: true })

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/segmentSyncs.syncNow',
      data: {
        segmentSyncId: segmentSyncId,
        projectId: this.props.projectLayout.project.id,
      }
    }, (errorMessage: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }

      this.props.app.addMessage('success', t('segment_sync_started', "The segment sync has been started!"))

      if (this._isMounted) {
        this.setState({ loading: false })
        this.fetchList()
      }
    })
  }

  fetchList() {

    if (this.state.loading === true) {
      return
    }

    this.setState({ loading: true })

    this.props.app.ajaxRequest({
      method: 'get',
      url: '/segmentSyncs.list',
      params: {
        projectId: this.props.projectLayout.project.id,
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        // this.setState({loading: false})
        return
      }

      // console.log('res', response.data);

      if (this._isMounted) {
        this.setState({
          list: response.data.segmentSyncs,
          loading: false
        })
      }
    })
  }

  render() {

    return <AppLayout currentProject={this.props.projectLayout.project} currentOrganization={this.props.organizationLayout.organization} admin={this.props.app.state.admin} firebaseUser={this.props.app.state.firebaseUser} projects={this.props.organizationLayout.projects}>

      <h1>
        <span className="actions">
          {this.state.list.length > 0 && <SegmentSyncButton
            type="primary"
            title={t('new_segment_sync', "New segment sync")}
            app={this.props.app}
            project={this.props.projectLayout.project}
            segments={this.props.projectLayout.segments}
            onComplete={this.fetchList}
          >
            {t('new_segment_sync', "New segment sync")}
          </SegmentSyncButton>}

          <Button className="margin-l-m" type="ghost" onClick={this.fetchList} disabled={this.state.loading}><FontAwesomeIcon icon="sync-alt" spin={this.state.loading} /></Button>
        </span>

        {t('segments_sync', "Segments sync")}
      </h1>

      {this.state.loading && <div className="text-center margin-a-l"><Spin size="large" /></div>}

      {!this.state.loading && !this.state.list.length && <div className="text-center margin-v-l">
        <SegmentSyncButton
          type="primary"
          size="large"
          title={t('new_segment_sync', "New segment sync")}
          app={this.props.app}
          project={this.props.projectLayout.project}
          segments={this.props.projectLayout.segments}
          onComplete={this.fetchList}
        >
          {t('new_segment_sync', "New segment sync")}
        </SegmentSyncButton>
      </div>}

      {!this.state.loading && this.state.list.map((record: any) => {
        const lines = [
          {
            id: 'segment',
            title: t('segment', "Segment"),
            content: Formatters.segment(this.props.projectLayout.segments, record.segmentId, 'small', true),
          },
          {
            id: 'integration',
            title: t('integration', "Integration"),
            content: <>
              {record.integrationKind === 'googleAds' && <GoogleOutlined className="margin-r-s" />}
              {record.integrationKind === 'facebook' && <FacebookOutlined className="margin-r-s" />}
              {this.props.projectLayout.project.integrations.find((x: any) => x.id === record.integrationId)?.name}
              <span className="padding-l-s">&rarr; {record.integrationAudienceName}</span>
            </>,
          },
          {
            id: 'sync',
            title: 'Sync',
            content: <>
              {t('every_x_mins', 'Every {x}', { x: Moment.duration(record.durationBetweenSyncs, 'minute').humanize() })} &bull; <Tooltip title={Moment(record.nextSyncAt).tz(this.props.app.state.admin.timezone).format('lll')+' in '+this.props.app.state.admin.timezone}><span>{t('next_sync', 'Next sync')}: {Moment(record.nextSyncAt).fromNow()}</span></Tooltip>
            </>
          }
        ]

        if (record.lastSyncAt) {
          lines.push({
            id: 'last',
            title: t('last_sync', 'Last sync'),
            content: <span>
              <Tooltip title={Moment(record.lastSyncAt).tz(this.props.app.state.admin.timezone).format('lll')+' in '+this.props.app.state.admin.timezone}>{Moment(record.lastSyncAt).fromNow()}</Tooltip>
              {record.syncOutput && record.syncOutput !== '' && <span className="size-10">{record.syncOutput}</span>}
            </span>
          })
        }

        return <div className="block margin-b-m" key={record.id}>
          <h2>{record.name}
            <span className="padding-l-m size-12">
              {record.status === 'active' && <Badge status="success" />}
              {record.status === 'paused' && <Badge status="default" />}
              {record.status === 'syncing' && <Badge status="processing" />}
            </span>

            <span className="actions">
              {record.status === 'active' && <Popconfirm title={t('pause_segment_sync_confirm', "Do you really want to pause this segment sync?")} onConfirm={this.pauseSegmentSync.bind(null, record.id)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                <Switch checked={true} />
              </Popconfirm>}

              {record.status === 'paused' && <Popconfirm title={t('activate_segment_sync_confirm', "Do you really want to activate this segment sync?")} onConfirm={this.activateSegmentSync.bind(null, record.id)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                <Switch checked={false} />
              </Popconfirm>}

              <Button.Group className="margin-l-m">

              {record.status === 'paused' && <Popconfirm placement="topRight" title={t('delete_segment_sync_confirm', "Do you really want to delete this segment sync?")} onConfirm={() => this.deleteSegmentSync(record.id)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                  <Button type="ghost"><DeleteOutlined /></Button>
                </Popconfirm>}

                <SegmentSyncButton
                  type="ghost"
                  title={t('edit_segment_sync', "Edit segment sync")}
                  app={this.props.app}
                  project={this.props.projectLayout.project}
                  segments={this.props.projectLayout.segments}
                  onComplete={this.fetchList}
                  segmentSync={record}
                >
                  <EditOutlined />
                </SegmentSyncButton>

                {record.status === 'active' && <Popconfirm placement="topRight" title={t('trigger_sync_now_confirm', "Do you really want to trigger the sync now?")} onConfirm={() => this.syncNow(record.id)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                  <Button type="ghost" icon={<CaretRightOutlined />}>{t('sync_now', "Sync now")}</Button>
                </Popconfirm>}
              </Button.Group>
            </span>
          </h2>

          <Table
            showHeader={false}
            size="middle"
            rowKey="id"
            dataSource={lines}
            pagination={false}
            columns={[
              {
                title: '',
                key: 'title',
                width: '160px',
                render: (record: any) => <span className="subsider-attribute-key">{record.title}</span>
              },
              {
                title: '',
                key: 'content',
                render: (record: any) => <div className="size-12">{record.content}</div>
              },
            ]} />
        </div>
      })}
    </AppLayout>
  }
}

export default SegmentsSync