import React, { useEffect, useState } from 'react'

import { Row, Col, Form } from 'antd';
import t from '../../utils/translate'
import Splash from '../app/_splash'
import { CreateForm } from './_createModal'

const CreateOrganization = ({ app, history }: any) => {

  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    // code to run on component mount
    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Create organization',
        page: '/create-organization' // this.props.location.pathname
      })
      window.cmAgent.dispatch()
    }
  }, [])

  return <Splash h1={t('create_an_organization', "Create a new organization")}>
    <Row>
      <Col span={16} offset={4}>
        <CreateForm form={form} kind="business" showSubmit={true} app={app} loading={loading} setLoading={setLoading} onComplete={(org: any) => {
          history.push('/organizations/'+org.id)
        }} />
      </Col>
    </Row>
  </Splash>
}

export default CreateOrganization