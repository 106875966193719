import React from 'react';
import { Select } from 'antd'
import t from '../../../utils/translate'

const PartnershipSelect = ({ partnerships, value, onChange }: any) => {

    if (partnerships.length === 0) return null

    const options = [{label: t('all_partnerships', 'All partnerships'), value: '_all'}]
    partnerships.forEach((x: any) => {
        options.push({
            label: x.advertiserProjectName+' - '+x.partnershipId,
            value: x.advertiserProjectId+'_'+x.partnershipId,
        })
    })

    return <Select
        size="small"
        dropdownMatchSelectWidth={false}
        className="size-12"
        options={options}
        onChange={onChange}
        value={value || '_all'}
        placeholder="Partnership"
    />
}

export default PartnershipSelect