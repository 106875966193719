import React, { useState } from 'react'

import t from '../../utils/translate'
import { CloseOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Table,
  Radio,
  Input,
  Switch,
  InputNumber,
  Drawer,
  Select,
  Tag,
  Popconfirm,
  Row,
  Col,
  DatePicker,
  Form,
} from 'antd';
import _ from 'lodash'
import Moment from 'moment-timezone'
import Languages from '../../utils/languages'
import Countries from '../../utils/countries'
import GetPropertyType from '../../utils/getPropertyType'
import PropertyButton from './_propertyButton'

const UpsertUser = (props: any) => {

  let defaultProperties = Object.assign({}, _.get(props, 'user.properties', {}))

  _.forEach(defaultProperties, (val, key) => {
    defaultProperties[key] = {
      type: GetPropertyType(val),
      key: key,
      value: val
    }
  })

  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [properties, setProperties] = useState(defaultProperties)
  const [form] = Form.useForm()

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  const onFinish = (values: any) => {

    if (loading === true) return

    // console.log('values', values);

    const externalId = values.externalId
    const isAuthenticated = values.isAuthenticated
    delete values.externalId
    delete values.isAuthenticated

    let set: any = {}
    let addTags: any = []
    let removeTags: any = []
    let addProperties: any = {}
    let removeProperties: any = []

    // build query
    _.forEach(values, (value, key) => {

      if (key === 'externalId' || value === undefined || value === null || value === '') {
        return
      }

      switch (key) {
        case 'tags':
            _.forEach(value, (tag: any) => {
              if ((props.user && props.user.tags.indexOf(tag) === -1) || !props.user) {
                addTags.push(tag)
              }
            })
          break;

        case 'properties':
          // atomic update
          _.forEach(value, (propValue, propKey) => {
            if (_.has(props, 'user.properties.' + propKey)) {
              if (_.isEqual(props.user.properties[propKey], propValue)) {
                return
              }
            }

            addProperties[propKey] = propValue
          })
          break;

        default:

          if ((key === 'latitude' || key === 'longitude') && value === 0) {
            return
          }

          if (key === 'birthday') {
            value = value.format('YYYY-MM-DD')
          }

          if (_.has(props, 'user.' + key)) {
            if (_.isEqual(props.user[key], value)) {
              return
            }
          }

          set[key] = value
      }
    })

    let unset: any = []

    // atomic fields to remove
    if (props.user) {
      _.forEach(values, (value, key) => {

        if (key !== 'properties') {
          if (values[key] === undefined || values[key] === null || values[key] === '') {
            if (_.isEqual(props.user[key], values[key]) === false) {
              unset.push(key)
            }
          }
        }

        if ((key === 'latitude' || key === 'longitude') && value === 0) {
          unset.push('latitude')
          unset.push('longitude')
        }
      })
    }

    // atomic update
    if (props.user) {

      // compare removed properties to user properties
      _.forEach(props.user.properties, (propValue, propKey) => {
        if (values.properties && (values.properties[propKey] === undefined || values.properties[propKey] === null || values.properties[propKey] === '')) {
          if (_.isEqual(values.properties[propKey], propValue) === false) {
            removeProperties.push(propKey)
          }
        }
      })

      // remove tags
      _.forEach(props.user.tags, (tag: any) => {
        if (values.tags && values.tags.indexOf(tag) === -1) {
          removeTags.push(tag)
        }
      })
    }

    const update: any = {}

    if (_.size(set) > 0) {
      update["set"] = set
    }

    if (_.size(unset) > 0) {
      update["unset"] = unset
    }

    if (_.size(addTags) > 0) {
      update["addTags"] = addTags
    }

    if (_.size(removeTags) > 0) {
      update["removeTags"] = removeTags
    }

    if (_.size(addProperties) > 0) {
      update["addProperties"] = addProperties
    }

    if (_.size(removeProperties) > 0) {
      update["removeProperties"] = removeProperties
    }

    // console.log('update', update);

    if (_.size(update) === 0) {
      setShowModal(false)
      return
    }

    setLoading(true)

    props.app.ajaxRequest({
      method: 'post',
      url: '/data.import',
      data: {
        projectId: props.project.id,
        id: 'upsert_user_'+new Date().getTime(),
        sync: true,
        items: [{
          id: 'upsert_user_'+new Date().getTime(),
          kind: 'user',
          user: {
            externalId: externalId,
            isAuthenticated: isAuthenticated,
            createdAt: props.user ? props.user.createdAt : new Date().toISOString(),
            profile: update
          }
        }]
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        props.app.addMessage('error', errorMessage)
        setLoading(false)
        return
      }

      if (response.data.results?.success === false) {
        props.app.addMessage('error', response.data.results.messages[0].message)
        setLoading(false)
        return
      }

      if (props.user) {
        props.app.addMessage('success', t('user_profile_updated', 'The user profile has been updated.'))
      } else {
        form.resetFields()
        props.app.addMessage('success', t('user_profile_created', 'The user profile has been created.'))
      }

      setLoading(false)
      setShowModal(false)

      if (window.cmAgent) {
        window.cmAgent.event({
          label: 'upsertUser',
          props: {
            orgId: props.project.organizationId,
            projectId: props.project.id,
          }
        })
        window.cmAgent.dispatch()
      }

      if (props.onComplete) {
        props.onComplete()
      }
    })
  }

  const setProperty = (prop: any) => {
    // console.log('new prop', prop)
    const formProperties = _.cloneDeep(form.getFieldsValue(['properties']))
    formProperties[prop.key] = prop.value
    form.setFieldsValue({properties: formProperties})

    let newProperties = _.cloneDeep(properties)
    newProperties[prop.key] = prop
    setProperties(newProperties)
  }

  const removeProperty = (key: any) => {
    let newProperties = _.cloneDeep(properties)
    delete newProperties[key]
    setProperties(newProperties)
  }

  const timezones = Moment.tz.names()
  const propertiesAsArray = _.toArray(properties)
  // console.log('propertiesAsArray', propertiesAsArray)

  let initialValues: any = {
    newsletterConsent: false,
    remarketingConsent: false,
    isAuthenticated: true,
  }

  if (props.user) {
    initialValues = { ...props.user }

    // convert date to moment
    if (props.user.birthday) {
      initialValues.birthday = Moment(props.user.birthday, 'YYYY-MM-DD')
    }
  }

  return <>
    <Button block={props.block || false} type={props.type || "default"} style={props.style || {}} size={props.size} onClick={toggleModal}>{props.children}</Button>

    {showModal && <Drawer
      title={props.title}
      visible={true}
      onClose={toggleModal}
      className="drawer drawer-form"
      width={950}>
      <Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} initialValues={initialValues} layout="horizontal" className="margin-a-m margin-b-xl" onFinish={onFinish}>

        <Row>
          <Col span={12}>

            <Form.Item name="externalId" label="ID" rules={[{ required: true, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
              <Input placeholder="External id" disabled={props.user ? true : false} />
            </Form.Item>

            <Form.Item name="firstName" label={t('first_name', "First name")} rules={[{ required: false, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
              <Input placeholder="First name" />
            </Form.Item>

            <Form.Item name="lastName" label={t('last_name', "Last name")} rules={[{ required: false, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
              <Input placeholder="Last name" />
            </Form.Item>

            <Form.Item name="gender" label={t('gender', "Gender")} rules={[{ required: false, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
              <Radio.Group buttonStyle="solid">
                <Radio.Button value="male">{t('male', "Male")}</Radio.Button>
                <Radio.Button value="female">{t('female', "Female")}</Radio.Button>
              </Radio.Group>
            </Form.Item>

            <Form.Item name="birthday" label={t('birthday', "Birthday")} rules={[{ required: false, message: t('field_invalid', "This field is not valid!") }]}>
              <DatePicker style={{ width: '100%' }} disabledDate={(date: any) => date.isAfter(Moment())} format={"YYYY-MM-DD"} />
            </Form.Item>

            <Form.Item name="photoURL" label={t('photo_url', "Photo URL")} rules={[{ required: false, type: 'url', message: t('url_invalid', "This is not a valid URL!") }]}>
              <Input placeholder="http://..." />
            </Form.Item>

            <Form.Item name="email" label={t('email', "Email")} rules={[{ required: false, type: 'email', message: t('field_invalid', "This field is not valid!") }]}>
              <Input placeholder="xxx@xxx.com" />
            </Form.Item>

            <Form.Item name="telephone" label={t('telephone', "Telephone")} rules={[{ required: false, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
              <Input />
            </Form.Item>

            <Form.Item name="websiteURL" label={t('website_url', "Website URL")} rules={[{ required: false, type: 'url', message: t('field_invalid', "This field is not valid!") }]}>
              <Input placeholder="https://www..." />
            </Form.Item>

            <Form.Item name="linkedInURL" label={t('linkedin_profile', "LinkedIn profile")} rules={[{ required: false, type: 'url', message: t('field_invalid', "This field is not valid!") }]}>
              <Input placeholder="https://www.linkedin.com/in/.../" />
            </Form.Item>

            <Form.Item name="twitterUsername" label={t('twitter_username', "Twitter username")} rules={[{ required: false, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
              <Input addonBefore="@" placeholder="username" />
            </Form.Item>

            <Form.Item name="facebookUsername" label={t('facebook_username', "Facebook username")} rules={[{ required: false, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
              <Input addonBefore="facebook.com/" placeholder="username" />
            </Form.Item>

            <Form.Item name="facebookId" label={t('facebook_id', "Facebook ID")} rules={[{ required: false, type: 'integer', message: t('field_invalid', "This field is not valid!") }]}>
              <InputNumber placeholder="123456789" />
            </Form.Item>

            <Form.Item valuePropName="checked" name="newsletterConsent" label={t('accepts_newsletter', "Accepts newsletter?")} rules={[{ required: false, type: 'boolean', message: t('field_invalid', "This field is not valid!") }]}>
              <Switch />
            </Form.Item>
          </Col>

          <Col span={12}>

            <Form.Item valuePropName="checked" name="isAuthenticated" label={t('is_authenticated', "Is authenticated?")} rules={[{ required: false, type: 'boolean', message: t('field_invalid', "This field is not valid!") }]}>
              <Switch disabled={props.user ? true : false} />
            </Form.Item>

            <Form.Item name="timezone" label={t('time_zone', "Time zone")} rules={[{ required: false, type: 'enum', enum: timezones, message: 'This time zone is not valid.' }]}>
              <Select
                placeholder={t('select_a_value', "Select a value")}
                allowClear={false}
                showSearch={true}
                filterOption={(searchText: any, option: any) => {
                  return searchText !== '' && option.key.toLowerCase().includes(searchText.toLowerCase())
                }}>
                {timezones.map((timezone) => {
                  return <Select.Option key={timezone} value={timezone}>{timezone}</Select.Option>
                })}
              </Select>
            </Form.Item>

            <Form.Item name="language" label={t('language', "Language")} rules={[{ required: false, type: 'enum', enum: Languages.map((language) => language.alpha2), message: 'This language is not valid.' }]}>
              <Select
                placeholder={t('select_a_value', "Select a value")}
                allowClear={true}
                showSearch={true}
                filterOption={(searchText: any, option: any) => {
                  return searchText !== '' && option.key.toLowerCase().includes(searchText.toLowerCase())
                }}>
                {Languages.map((language) => {
                  return <Select.Option key={language.alpha2} value={language.alpha2}>{language.alpha2 + ' - ' + language.name}</Select.Option>
                })}
              </Select>
            </Form.Item>

            <Form.Item name="addressLine1" label={t('address_line_1', "Address line 1")} rules={[{ required: false, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
              <Input placeholder="" />
            </Form.Item>

            <Form.Item name="addressLine2" label={t('address_line_1', "Address line 2")} rules={[{ required: false, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
              <Input placeholder="" />
            </Form.Item>

            <Form.Item name="city" label={t('city', "City")} rules={[{ required: false, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
              <Input placeholder="" />
            </Form.Item>

            <Form.Item name="postalCode" label={t('postalCode', "Postal code")} rules={[{ required: false, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
              <Input placeholder="" />
            </Form.Item>

            <Form.Item name="state" label={t('state', "State")} rules={[{ required: false, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
              <Input placeholder="" />
            </Form.Item>

            <Form.Item name="region" label={t('region', "Region")} rules={[{ required: false, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
              <Input placeholder="" />
            </Form.Item>

            <Form.Item name="country" label={t('country', "Country")} rules={[{ required: false, type: 'enum', enum: Countries.map((country) => country.code), message: 'This country is not valid.' }]}>
              <Select
                placeholder={t('select_a_value', "Select a value")}
                allowClear={false}
                showSearch={true}
                filterOption={(searchText: any, option: any) => {
                  return searchText !== '' && option.key.toLowerCase().includes(searchText.toLowerCase())
                }}>
                {Countries.map((country) => {
                  return <Select.Option key={country.code} value={country.code}>{country.code + ' - ' + country.name}</Select.Option>
                })}
              </Select>
            </Form.Item>

            <Form.Item name="latitude" label={t('latitude', "Latitude")} rules={[{ required: false, type: 'number', message: t('field_invalid', "This field is not valid!") }]}>
              <InputNumber style={{ width: '100%' }} placeholder="Latitude" />
            </Form.Item>

            <Form.Item name="longitude" label={t('longitude', "Longitude")} rules={[{ required: false, type: 'number', message: t('field_invalid', "This field is not valid!") }]}>
              <InputNumber style={{ width: '100%' }} placeholder="Longitude" />
            </Form.Item>

            <Form.Item name="tags" label={t('tags', "Tags")} rules={[{ required: false, type: 'array', message: t('field_invalid', "This field is not valid!") }]}>
              <Select
                mode="tags"
                style={{ width: '100%' }}
                placeholder={t('press_enter_to_add_value', "Press enter to add a value")}
              ></Select>
            </Form.Item>

            <Form.Item valuePropName="checked" name="remarketingConsent" label={t('accepts_remarketing', "Accepts Remarketing?")} rules={[{ required: false, type: 'boolean', message: t('field_invalid', "This field is not valid!") }]}>
              <Switch />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item labelCol={{ xs: { span: 24 }, sm: { span: 4 } }} wrapperCol={{ xs: { span: 20 }, sm: { span: 19 } }} label={t('custom_properties', "Custom properties")}>
          {propertiesAsArray.length === 0 ?
            <PropertyButton
              app={props.app}
              onComplete={(property: any) => setProperty(property)}
              userTimezone={props.app.state.admin.timezone}
              type="primary"
              size="small"
            >
              <PlusOutlined />{t('add', "Add")}
            </PropertyButton>
            : <Table
              size="middle"
              bordered={false}
              pagination={false}
              rowKey={(item) => item.type + item.key}
              dataSource={propertiesAsArray}
              showHeader={true}
              className="margin-b-m"
              columns={[{
                title: 'Type',
                key: 'type',
                width: 80,
                render: (item: any) => <div>
                  <Form.Item noStyle name={['properties', item.key]}>
                    <Input type="hidden" value={item.value} />
                  </Form.Item>
                  {item.type === 'string' && <Tag color="cyan" className="no-pointer">{t('type_string', "String")}</Tag>}
                  {item.type === 'number' && <Tag color="green" className="no-pointer">{t('type_number', "Number")}</Tag>}
                  {item.type === 'boolean' && <Tag color="purple" className="no-pointer">{t('type_boolean', "True / false")}</Tag>}
                  {item.type === 'date' && <Tag color="magenta" className="no-pointer">{t('type_date', "Date")}</Tag>}
                  {item.type === 'list' && <Tag color="blue" className="no-pointer">{t('type_list', "List")}</Tag>}
                </div>
              }, {
                title: t('key', "Key"),
                key: 'key',
                render: (item: any) => item.key
              }, {
                title: t('value', "Value"),
                key: 'value',
                render: (item: any) => {
                  return <div>
                    {(item.type === 'string') && item.value}
                    {(item.type === 'number') && item.value}
                    {(item.type === 'date') && <div>
                      {Moment.tz(item.value['$date'] ? item.value['$date'] : item.value, item.value['$timezone'] ? item.value['$timezone'] : 'UTC').format('lll') + ' (in ' + (item.value['$timezone'] || 'UTC') + ')'}
                    </div>}
                    {(item.type === 'boolean') && ((item.value === true) ? "true" : "false")}
                    {(item.type === 'list') && item.value.map((x: any) => <span key={x}><Tag className="no-pointer margin-r-s xsmall">{x}</Tag></span>)}
                  </div>
                }
              }, {
                title: <div className="text-right">
                  <PropertyButton
                    app={props.app}
                    onComplete={(property: any) => setProperty(property)}
                    userTimezone={props.app.state.admin.timezone}
                    type="primary"
                    size="small"
                  >
                    <PlusOutlined />{t('add', "Add")}
                  </PropertyButton>
                </div>,
                key: 'remove',
                render: (item: any) => {
                  return (
                    <div className="text-right">
                      <Button.Group>
                        <Popconfirm placement="left" title={t('confirm_delete_attribute', "Do you really want to delete this attribute?")} onConfirm={removeProperty.bind(null, item.key)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                          <Button type="default" size="small">
                            <CloseOutlined />
                          </Button>
                        </Popconfirm>
                        <PropertyButton
                          app={props.app}
                          onComplete={(property: any) => setProperty(property)}
                          userTimezone={props.app.state.admin.timezone}
                          propertyKey={item.key}
                          propertyValue={item.value}
                          type="default"
                          size="small">
                          <EditOutlined />
                        </PropertyButton>
                      </Button.Group>
                    </div>
                  );
                }
              }]}
            />}
        </Form.Item>
        <div className="ant-drawer-footer">
          <Button style={{ marginRight: 8 }} onClick={toggleModal}>{t('cancel', "Cancel")}</Button>
          <Button htmlType="submit" type="primary" loading={loading}>{t('confirm', "Confirm")}</Button>
        </div>
      </Form>
    </Drawer>}
  </>
}

export default UpsertUser