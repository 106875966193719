import { useEffect, useState, useRef } from 'react'

import { ReloadOutlined } from '@ant-design/icons';

import {
  Button,
  Tooltip,
  Row,
  Col,
} from 'antd';
import t from '../../../utils/translate'
import Moment from 'moment-timezone'
import QS from 'qs'
import DateRange from '../../projects/_dateRange'
import PartnershipSelect from './_partnershipSelect'
import CombinedKPI from './_combinedKpi'
import PerPartnership from './_partnershipsTable'
import TopProducts from './_topProducts'
import TopLandingPages from './_topLandingPages'
import cubejs from '@cubejs-client/core';
import Config from '../../../config'
import AppLayout from 'components/app/layout'
import { useOrganizationContext, OrganizationContextValue } from 'components/organizations'
import { useHistory, useLocation } from 'react-router';

const MediaPartnersDashboard = () => {

  // const [loading, setLoading] = useState(false)
  const orgCtx: OrganizationContextValue = useOrganizationContext()
  const history = useHistory() as any
  const location = useLocation() as any
  const prevOrganizationId = useRef(undefined)

  const [dateRangeVs, setDateRangeVs] = useState('previous_period')
  const [dateRangeLabel, setDateRangeLabel] = useState('last_30_days')
  const [dateRangeValue, setDateRangeValue] = useState([Moment().tz(orgCtx.admin.timezone).subtract(30, 'day').startOf('day'), Moment().tz(orgCtx.admin.timezone).endOf('day')])
  const [refreshAt, setRefreshAt] = useState(Moment().unix())


  useEffect(() => {

    if (prevOrganizationId.current === orgCtx.currentOrganization.id) return
    prevOrganizationId.current = orgCtx.currentOrganization.id

    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Media Partners Dashboard',
        page: location.pathname,
        props: {
          orgId: orgCtx.currentOrganization.id,
        }
      })
      window.cmAgent.dispatch()
    }
  }, [history, location.pathname, orgCtx.currentOrganization, orgCtx.projects])


  const refresh = () => {
    setRefreshAt(Moment().unix())
  }

  const setDateRange = (label: string, range: any) => {
    setDateRangeValue(range)
    setDateRangeLabel(label)
    refresh()
  }

  const setDateRangeVsAndRefresh = (value) => {
    setDateRangeVs(value)
    refresh()
  }

  const updateParams = (updatedParams: any) => {
    let params = QS.parse(location.search, { ignoreQueryPrefix: true })
    updatedParams.forEach((up: any) => {
      params[up.k] = up.v
    })
    history.push('/organizations/' + orgCtx.currentOrganization.id + '?' + QS.stringify(params))
  }

  const setPartnership = (partnership: any) => {
    updateParams([{ k: 'partnership', v: partnership }])
  }


  // console.log('props', this.props)

  const params: any = QS.parse(location.search, { ignoreQueryPrefix: true })

  const dateFrom = Moment(dateRangeValue[0])
  const dateTo = Moment(dateRangeValue[1])

  let dateFromPrevious: any = Moment(dateFrom)
  let dateToPrevious: any = Moment(dateTo)

  if (dateRangeVs === "previous_period") {

    // add one day to the diff to avoid days overlaping between the to ranges
    const diff = dateTo.diff(dateFrom, 'days') + 1
    // console.log('diff is', diff);

    dateToPrevious = dateToPrevious.subtract(diff, 'days')
    dateFromPrevious = dateFromPrevious.subtract(diff, 'days')
  }

  if (dateRangeVs === "previous_year") {
    dateToPrevious = dateToPrevious.subtract(1, 'years')
    dateFromPrevious = dateFromPrevious.subtract(1, 'years')
  }

  dateFromPrevious = dateFromPrevious.format('YYYY-MM-DD')
  dateToPrevious = dateToPrevious.format('YYYY-MM-DD')

  const filterPartnership = params.partnership || '_all'

  const kpiPartnerships = orgCtx.currentOrganization.partnerships.filter((p: any) => filterPartnership === '_all' || filterPartnership === p.advertiserProjectId + '_' + p.partnershipId).map((p: any) => {
    return {
      cubejs: cubejs(p.analyticsToken, { apiUrl: Config().analytics_endpoint }),
      advertiserCurrency: p.advertiserCurrency,
      advertiserProjectName: p.advertiserProjectName,
      currencyRate: p.currencyRate,
      partnershipId: p.partnershipId
    }
  })

  // console.log('orgCtx.currentOrganization.partnerships', orgCtx.currentOrganization.partnerships)

  return <AppLayout currentOrganization={orgCtx.currentOrganization} admin={orgCtx.admin} firebaseUser={orgCtx.firebaseUser} projects={orgCtx.projects}>

    <h1>
      <span className="actions">
        <span className="padding-l-s">
          <PartnershipSelect partnerships={orgCtx.currentOrganization.partnerships} value={params.partnership} onChange={setPartnership} />
        </span>
        <span className="padding-l-s">
          <DateRange 
            timezone={orgCtx.admin.timezone}
            refreshAt={refreshAt}
            vs={dateRangeVs}
            dateRange={dateRangeValue}
            labelKey={dateRangeLabel}
            onVsChange={setDateRangeVsAndRefresh}
            onChange={setDateRange} />
          <Tooltip placement="left" title={t('last_refresh_at', "Refreshed {at}", { at: Moment.unix(refreshAt).fromNow() })}>
            <Button className="margin-l-s" size="small" type="default" shape="circle" icon={<ReloadOutlined />} onClick={refresh} />
          </Tooltip>
        </span>
      </span>
      Media Partners Dashboard
    </h1>

    <div className="block">
      <div className="dash-kpi-container border-bottom-1">
        <CombinedKPI
          title="Sessions"
          measure="TimelineSessions.count"
          timeDimension="TimelineSessions.truncCreatedAt"
          partnershipId={filterPartnership}
          color="cyan"
          organizationId={orgCtx.currentOrganization.id}
          kpiPartnerships={kpiPartnerships}
          timezone={orgCtx.admin.timezone}
          refreshAt={refreshAt}
          dateFrom={dateFrom.format('YYYY-MM-DD')}
          dateTo={dateTo.format('YYYY-MM-DD')}
          dateFromPrevious={dateFromPrevious}
          dateToPrevious={dateToPrevious}
        />
        <CombinedKPI
          title={t('users', 'Users')}
          measure="TimelineSessions.uniqueUsers"
          timeDimension="TimelineSessions.truncCreatedAt"
          partnershipId={filterPartnership}
          color="cyan"
          organizationId={orgCtx.currentOrganization.id}
          kpiPartnerships={kpiPartnerships}
          timezone={orgCtx.admin.timezone}
          refreshAt={refreshAt}
          dateFrom={dateFrom.format('YYYY-MM-DD')}
          dateTo={dateTo.format('YYYY-MM-DD')}
          dateFromPrevious={dateFromPrevious}
          dateToPrevious={dateToPrevious}
        />
        <CombinedKPI
          title="Contributions"
          measure="TimelineSessions.contributions"
          timeDimension="TimelineSessions.truncCreatedAt"
          partnershipId={filterPartnership}
          color="cyan"
          organizationId={orgCtx.currentOrganization.id}
          kpiPartnerships={kpiPartnerships}
          timezone={orgCtx.admin.timezone}
          refreshAt={refreshAt}
          dateFrom={dateFrom.format('YYYY-MM-DD')}
          dateTo={dateTo.format('YYYY-MM-DD')}
          dateFromPrevious={dateFromPrevious}
          dateToPrevious={dateToPrevious}
        />
        <CombinedKPI
          title={t('unique_conversions', 'Unique conversions')}
          measure="TimelineOrders.count"
          timeDimension="TimelineOrders.truncCreatedAt"
          partnershipId={filterPartnership}
          color="cyan"
          organizationId={orgCtx.currentOrganization.id}
          kpiPartnerships={kpiPartnerships}
          timezone={orgCtx.admin.timezone}
          refreshAt={refreshAt}
          dateFrom={dateFrom.format('YYYY-MM-DD')}
          dateTo={dateTo.format('YYYY-MM-DD')}
          dateFromPrevious={dateFromPrevious}
          dateToPrevious={dateToPrevious}
        />
        <CombinedKPI
          title={t('conversion_rate', 'Conversion rate')}
          measure="TimelineSessions.conversionRate"
          timeDimension="TimelineSessions.truncCreatedAt"
          partnershipId={filterPartnership}
          kind="percentage"
          color="cyan"
          organizationId={orgCtx.currentOrganization.id}
          kpiPartnerships={kpiPartnerships}
          timezone={orgCtx.admin.timezone}
          refreshAt={refreshAt}
          dateFrom={dateFrom.format('YYYY-MM-DD')}
          dateTo={dateTo.format('YYYY-MM-DD')}
          dateFromPrevious={dateFromPrevious}
          dateToPrevious={dateToPrevious}
        />
      </div>

      <div className="dash-kpi-container">
        <CombinedKPI
          title={t('revenue_linear', "Revenue - linear attrib.")}
          measure="TimelineSessions.attributionLinearRevenue"
          timeDimension="TimelineSessions.truncCreatedAt"
          partnershipId={filterPartnership}
          color="cyan"
          kind="currency"
          currency={orgCtx.currentOrganization.currency}
          organizationId={orgCtx.currentOrganization.id}
          kpiPartnerships={kpiPartnerships}
          timezone={orgCtx.admin.timezone}
          refreshAt={refreshAt}
          dateFrom={dateFrom.format('YYYY-MM-DD')}
          dateTo={dateTo.format('YYYY-MM-DD')}
          dateFromPrevious={dateFromPrevious}
          dateToPrevious={dateToPrevious}
        />
        <CombinedKPI
          title={t('avg_cart', "Avg. cart")}
          measure="TimelineOrders.averageCart"
          timeDimension="TimelineOrders.truncCreatedAt"
          partnershipId={filterPartnership}
          color="cyan"
          kind="currency"
          currency={orgCtx.currentOrganization.currency}
          organizationId={orgCtx.currentOrganization.id}
          kpiPartnerships={kpiPartnerships}
          timezone={orgCtx.admin.timezone}
          refreshAt={refreshAt}
          dateFrom={dateFrom.format('YYYY-MM-DD')}
          dateTo={dateTo.format('YYYY-MM-DD')}
          dateFromPrevious={dateFromPrevious}
          dateToPrevious={dateToPrevious}
        />
        <CombinedKPI
          title="CPC"
          measure="TimelineSessions.cpc"
          timeDimension="TimelineSessions.truncCreatedAt"
          kind="currency"
          currency={orgCtx.currentOrganization.currency}
          partnershipId={filterPartnership}
          color="cyan"
          organizationId={orgCtx.currentOrganization.id}
          kpiPartnerships={kpiPartnerships}
          timezone={orgCtx.admin.timezone}
          refreshAt={refreshAt}
          dateFrom={dateFrom.format('YYYY-MM-DD')}
          dateTo={dateTo.format('YYYY-MM-DD')}
          dateFromPrevious={dateFromPrevious}
          dateToPrevious={dateToPrevious}
        />
        <CombinedKPI
          title="CPA / CPL"
          measure="TimelineSessions.cpa"
          timeDimension="TimelineSessions.truncCreatedAt"
          kind="currency"
          partnershipId={filterPartnership}
          color="cyan"
          currency={orgCtx.currentOrganization.currency}
          organizationId={orgCtx.currentOrganization.id}
          kpiPartnerships={kpiPartnerships}
          timezone={orgCtx.admin.timezone}
          refreshAt={refreshAt}
          dateFrom={dateFrom.format('YYYY-MM-DD')}
          dateTo={dateTo.format('YYYY-MM-DD')}
          dateFromPrevious={dateFromPrevious}
          dateToPrevious={dateToPrevious}
        />
        <CombinedKPI
          title={t('avg_commission_rate', "Avg. comm. rate")}
          measure="TimelineSessions.averageCommissionRate"
          timeDimension="TimelineSessions.truncCreatedAt"
          kind="percentage"
          partnershipId={filterPartnership}
          color="cyan"
          organizationId={orgCtx.currentOrganization.id}
          kpiPartnerships={kpiPartnerships}
          timezone={orgCtx.admin.timezone}
          refreshAt={refreshAt}
          dateFrom={dateFrom.format('YYYY-MM-DD')}
          dateTo={dateTo.format('YYYY-MM-DD')}
          dateFromPrevious={dateFromPrevious}
          dateToPrevious={dateToPrevious}
          isLast={true}
        />
      </div>
    </div>

    <div className="block margin-t-l">
      <h2 className="mini">{t('performance_per_partnership', 'Performance per partnership')}</h2>
      <PerPartnership
        organization={orgCtx.currentOrganization}
        kpiPartnerships={kpiPartnerships}
        partnershipId={filterPartnership}
        timezone={orgCtx.admin.timezone}
        refreshAt={refreshAt}
        dateFrom={dateFrom.format('YYYY-MM-DD')}
        dateTo={dateTo.format('YYYY-MM-DD')}
        dateFromPrevious={dateFromPrevious}
        dateToPrevious={dateToPrevious}
      />
    </div>

    <Row className="margin-t-l" gutter={24}>
      <Col span={12}>
        <div className="block">
          <h2 className="mini">{t('top_products', 'Top products')}</h2>
          <TopProducts
            organization={orgCtx.currentOrganization}
            kpiPartnerships={kpiPartnerships}
            partnershipId={filterPartnership}
            timezone={orgCtx.admin.timezone}
            refreshAt={refreshAt}
            dateFrom={dateFrom.format('YYYY-MM-DD')}
            dateTo={dateTo.format('YYYY-MM-DD')}
            dateFromPrevious={dateFromPrevious}
            dateToPrevious={dateToPrevious}
          />
        </div>
      </Col>

      <Col span={12}>
        <div className="block">
          <h2 className="mini">{t('top_landing_pages', 'Top landing pages')}</h2>
          <TopLandingPages
            organization={orgCtx.currentOrganization}
            kpiPartnerships={kpiPartnerships}
            partnershipId={filterPartnership}
            timezone={orgCtx.admin.timezone}
            refreshAt={refreshAt}
            dateFrom={dateFrom.format('YYYY-MM-DD')}
            dateTo={dateTo.format('YYYY-MM-DD')}
            dateFromPrevious={dateFromPrevious}
            dateToPrevious={dateToPrevious}
          />
        </div>
      </Col>
    </Row>
  </AppLayout>
}

export default MediaPartnersDashboard