import { useState, useEffect, useCallback } from 'react'

import {
    Table,
    Tooltip as AntTooltip,
    Spin,
    Row, Col,
    message,
} from 'antd';
import t from '../../../utils/translate'
import Formatters from '../../../utils/formatters'
import Numeral from 'numeral'
import QS from 'qs'
import _ from 'lodash'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    Chart,
    Interval,
    Label,
    Coordinate,
    Legend,
    Tooltip,
} from "bizcharts"
import KPI from '../../dashboard/kpi'
import removeLeadingDates from 'utils/removeLeadingDates'
import { useAnalyticsContext, AnalyticsContextValue } from './index'
import { useProjectContext, ProjectContextValue } from 'components/projects'
import { useHistory } from 'react-router'

const defaultSortKey = 'TimelineOrders.count'

const Report = () => {

    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState(false)
    const [filters, setFilters] = useState([])

  const analyticsContext: AnalyticsContextValue = useAnalyticsContext()
  const projectCtx: ProjectContextValue = useProjectContext()
  const history = useHistory() as any

    // default sort order
    const sortKey = analyticsContext.sortKey || defaultSortKey
    const sortOrder = analyticsContext.sortOrder


    const fetchData = useCallback((
        segmentId: string,
        conversionType: string,
        // domainId,
        dateFrom: string,
        dateTo: string,
        dateFromPrevious: string,
        dateToPrevious: string) => {

        // filter empty funnels
        const newFilters: any = [{
            'member': 'TimelineOrders.conversionDomainsFunnel',
            'operator': 'notEquals',
            'values': ['']
        }]

        if (segmentId !== '_all') {
            newFilters.push({
                'member': 'UserSegments.segmentId',
                'operator': 'equals',
                'values': [segmentId]
            })
        }

        if (conversionType !== 'all') {
            newFilters.push({
                'member': 'TimelineOrders.isFirstConversion',
                'operator': 'equals',
                'values': [conversionType === 'acquisition' ? '1' : '0']
            })
        }

        // if (domainId !== '_all') {
        //     newFilters.push({
        //         'member': 'TimelineOrders.domainId',
        //         'operator': 'equals',
        //         'values': [domainId]
        //     })
        // } else {
        //     const webDomains = projectCtx.currentProject.domains.filter((d: any) => !d.deletedAt && d.kind === 'web')
        //     newFilters.push({
        //         'member': 'TimelineOrders.domainId',
        //         'operator': 'in',
        //         'values': webDomains.map((x: any) => x.id)
        //     })
        // }

        const groupBy = 'TimelineOrders.conversionDomainsFunnel'

        const opts: any = {
            measures: [
                'TimelineOrders.count',
                'TimelineOrders.revenue',
                'TimelineOrders.averageCart',
                'TimelineOrders.averageTimeToConversion'
            ],
            dimensions: [groupBy],
            filters: newFilters,
            timeDimensions: [
                {
                    "dimension": 'TimelineOrders.truncCreatedAt',
                    "granularity": null,
                    "compareDateRange": [
                        [dateFrom, dateTo],
                        [dateFromPrevious, dateToPrevious]
                    ]
                }
            ],
            timezone: analyticsContext.timezone,
            order: {
                [sortKey]: sortOrder === 'descend' ? 'desc' : 'asc'
            },
            limit: 100
        }

        setFilters(newFilters)

        // if (noCache) {
        //   opts.renewQuery = true
        // }

        return new Promise((resolve, reject) => {
            projectCtx.cubejsApi.load(opts).then((value: any) => {

                const [currentPeriod, previousPeriod] = value.decompose()

                const returned: any = []

                const lines = currentPeriod.tablePivot()
                const previousLines = previousPeriod.tablePivot()

                removeLeadingDates(lines)
                removeLeadingDates(previousLines)

                lines.forEach((line: any) => {
                    const previousFound = previousLines.find((x: any) => x[groupBy] === line[groupBy])

                    const returnedLine: any = {
                        id: line[groupBy],
                        name: line[groupBy],
                        current: line,
                        previous: previousFound || {},
                    }

                    returned.push(returnedLine)
                })

                // console.log('returned', returned)
                resolve(returned)

            }).catch((error: any) => {

                console.log(error)

                let message = error.message

                if (error.response && error.response.status === 400) {
                    switch (error.response.data.message) {
                        default:
                            message = error.response.data.message
                    }
                }

                reject(message)
            })
        })
    }, [projectCtx.cubejsApi, analyticsContext.timezone, sortKey, sortOrder])

    useEffect(() => {

        setLoading(true)

        fetchData(
            analyticsContext.segment,
            analyticsContext.conversionType,
            // analyticsContext.domainId,
            analyticsContext.dateFrom,
            analyticsContext.dateTo,
            analyticsContext.dateFromPrevious,
            analyticsContext.dateToPrevious,
        ).then(data => {
            setLoading(false)
            setData(data)
        }).catch(msg => {
            message.error(msg)
            setLoading(false)
        })

    }, [
        analyticsContext.segment,
        analyticsContext.conversionType,
        // analyticsContext.domainId,
        analyticsContext.dateFrom,
        analyticsContext.dateTo,
        analyticsContext.dateFromPrevious,
        analyticsContext.dateToPrevious,
        analyticsContext.refreshAt,
        fetchData,
    ])

    const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
        // console.log('filters', filters);
        // console.log('pagination', pagination);
        // console.log('sorter', sorter);
        // const params: any = QS.parse(props.location.search, { ignoreQueryPrefix: true })

        const newParams = _.assign({
            sortKey: sortKey || 'sessions',
            sortOrder: sortOrder || 'descend',
        }, {
            sortKey: sorter.columnKey,
            sortOrder: sorter.order,
        })

        history.push('/organizations/' + projectCtx.currentOrganization.id + '/projects/' + projectCtx.currentProject.id + '/analytics/web?tab=cross-domains&' + QS.stringify(newParams, { indices: false }));
    }


    return <div>
        <TopChart filters={filters} />

        <Table
            dataSource={data}
            rowKey="id"
            size="middle"
            onChange={handleTableChange}
            loading={loading}
            pagination={false}
            className="block margin-t-l"
            rowClassName={record => cn({ 'table-all-total': record.name === '_all' })}
            columns={[
                {
                    title: 'Domains',
                    className: 'table-border-right bg-white',
                    fixed: 'left',
                    key: 'domains',
                    render: (record: any) => {
                        const domains = record.current['TimelineOrders.conversionDomainsFunnel'].split('~')
                        return domains.map((dom: any, i: number) => {
                            return <span style={{ fontSize: '16px' }} key={i}>
                                {dom}
                                {i < domains.length - 1 && <span className="margin-h-m">&rarr;</span>}
                            </span>
                        })
                    }
                },
                {
                    title: <FontAwesomeIcon icon="shopping-cart" />,
                    key: 'TimelineOrders.count',
                    width: 160,
                    sortOrder: sortKey === 'TimelineOrders.count' ? sortOrder : undefined,
                    sorter: (a: any, b: any) => a.current['TimelineOrders.count'] - b.current['TimelineOrders.count'],
                    sortDirections: ['descend', 'ascend'],
                    render: (record: any) => <div>
                        {Numeral(record.current['TimelineOrders.count']).format('0,0')}
                        <span className="padding-l-s size-10">{Formatters.growthRate(record.current['TimelineOrders.count'], record.previous['TimelineOrders.count'])}</span>
                    </div>
                },
                {
                    title: t('revenue', "Revenue"),
                    key: 'TimelineOrders.revenue',
                    width: 200,
                    sortOrder: sortKey === 'TimelineOrders.revenue' ? sortOrder : undefined,
                    sorter: (a: any, b: any) => a.current['TimelineOrders.revenue'] - b.current['TimelineOrders.revenue'],
                    sortDirections: ['descend', 'ascend'],
                    render: (record: any) => <div>
                        {Formatters.currency(projectCtx.currentProject.currency, record.current['TimelineOrders.revenue'], undefined, undefined, undefined, undefined, true)}
                        <span className="padding-l-s size-10">{Formatters.growthRate(record.current['TimelineOrders.revenue'], record.previous['TimelineOrders.revenue'])}</span>
                    </div>
                },
                {
                    title: t('avg_cart', "Avg. cart"),
                    key: 'TimelineOrders.averageCart',
                    width: 200,
                    sortOrder: sortKey === 'TimelineOrders.averageCart' ? sortOrder : undefined,
                    sorter: (a: any, b: any) => a.current['TimelineOrders.averageCart'] - b.current['TimelineOrders.averageCart'],
                    sortDirections: ['descend', 'ascend'],
                    render: (record: any) => <div>
                        {Formatters.currency(projectCtx.currentProject.currency, record.current['TimelineOrders.averageCart'], undefined, undefined, undefined, undefined, true)}
                        <span className="padding-l-s size-10">{Formatters.growthRate(record.current['TimelineOrders.averageCart'], record.previous['TimelineOrders.averageCart'])}</span>
                    </div>
                },
                {
                    title: <AntTooltip title={t('avg_time_to_conversion', "Average Time To Conversion")}>{t('avg_ttc', "Avg. TTC")}</AntTooltip>,
                    key: 'TimelineOrders.averageTimeToConversion',
                    width: 150,
                    sortOrder: sortKey === 'TimelineOrders.averageTimeToConversion' ? sortOrder : undefined,
                    sorter: (a: any, b: any) => a.current['TimelineOrders.averageTimeToConversion'] - b.current['TimelineOrders.averageTimeToConversion'],
                    sortDirections: ['descend', 'ascend'],
                    render: (record: any) => <div>
                        {Formatters.duration(record.current['TimelineOrders.averageTimeToConversion'])}
                        <span className="padding-l-s size-10">{Formatters.growthRate(record.current['TimelineOrders.averageTimeToConversion'], record.previous['TimelineOrders.averageTimeToConversion'])}</span>
                    </div>
                },

            ]}
        />
    </div>
}
export default Report


const TopChart = ({ filters }) => {

    const analyticsContext: AnalyticsContextValue = useAnalyticsContext()
    const projectCtx: ProjectContextValue = useProjectContext()

    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState(false)

    const fetchData = useCallback((
        filters: any,
        dateFrom: string,
        dateTo: string,
        dateFromPrevious: string,
        dateToPrevious: string) => {


        const groupBy = 'TimelineOrders.conversionDomainsTypeFunnelSimplified'

        const opts: any = {
            measures: [
                'TimelineOrders.count',
            ],
            dimensions: [groupBy],
            filters: filters,
            timeDimensions: [
                {
                    "dimension": 'TimelineOrders.truncCreatedAt',
                    "granularity": null,
                    "compareDateRange": [
                        [dateFrom, dateTo],
                        [dateFromPrevious, dateToPrevious]
                    ]
                }
            ],
            timezone: analyticsContext.timezone,
            order: {
                'TimelineOrders.count': 'desc'
            },
            limit: 100
        }

        return new Promise((resolve, reject) => {
            projectCtx.cubejsApi.load(opts).then((value: any) => {

                const [currentPeriod, previousPeriod] = value.decompose()

                const returned: any = []

                const lines = currentPeriod.tablePivot()
                const previousLines = previousPeriod.tablePivot()

                removeLeadingDates(lines)
                removeLeadingDates(previousLines)

                // console.log('currentDomains', currentDomainsTable);

                // compute totals
                const currentDomainsTotal = lines.reduce((acc: any, x: any) => acc + x['TimelineOrders.count'], 0)
                const previousDomainsTotal = previousLines.reduce((acc: any, x: any) => acc + x['TimelineOrders.count'], 0)


                lines.forEach((line: any) => {

                    // compute percentage of total
                    line.percentage = currentDomainsTotal > 0 ? (line['TimelineOrders.count'] * 100) / currentDomainsTotal : 0

                    let previousFound = previousLines.find((x: any) => x[groupBy] === line[groupBy])

                    if (!previousFound) {
                        previousFound = {
                            id: line[groupBy],
                            name: line[groupBy],
                            'TimelineOrders.count': 0,
                            percentage: 0,
                        }
                    } else {
                        previousFound.percentage = previousDomainsTotal > 0 ? (previousFound['TimelineOrders.count'] * 100) / previousDomainsTotal : 0
                    }

                    const returnedLine: any = {
                        id: line[groupBy],
                        name: line[groupBy],
                        current: line,
                        previous: previousFound || {},
                    }

                    returned.push(returnedLine)
                })

                // console.log('returned pie', returned)
                resolve(returned)

            }).catch((error: any) => {

                console.log(error)

                let message = error.message

                if (error.response && error.response.status === 400) {
                    switch (error.response.data.message) {
                        default:
                            message = error.response.data.message
                    }
                }

                reject(message)
            })
        })
    }, [projectCtx.cubejsApi, analyticsContext.timezone])

    useEffect(() => {

        setLoading(true)

        fetchData(
            filters,
            analyticsContext.dateFrom,
            analyticsContext.dateTo,
            analyticsContext.dateFromPrevious,
            analyticsContext.dateToPrevious,
        ).then(data => {
            setLoading(false)
            setData(data)
        }).catch(msg => {
            message.error(msg)
            setLoading(false)
        })

    }, [
        filters,
        analyticsContext.dateFrom,
        analyticsContext.dateTo,
        analyticsContext.dateFromPrevious,
        analyticsContext.dateToPrevious,
        analyticsContext.refreshAt,
        fetchData,
    ])

    return <div className="block margin-t-l">
        <h2 className="mini">Orders per domain</h2>
        <div className="padding-a-m dash-table-stats">
            <Row gutter={24}>
                <Col span={6}>
                    {loading && <div className="padding-a-l text-center"><Spin size="large" /></div>}
                    {!loading && <div className="">
                        <Chart data={
                            data.map((x: any) => {
                                return {
                                    name: x.name !== 'Cross-Domain' ? x.name + ' only' : x.name,
                                    value: x.current.percentage
                                }
                            })} autoFit height={120} padding={[20, 30, 20, 30]}>
                            <Coordinate type="theta" innerRadius={0.7} />
                            <Tooltip showTitle={false} />
                            <Legend visible={false} />
                            <Interval
                                adjust="stack"
                                position="value"
                                color="name"
                                tooltip={['name*value', (name, value) => {
                                    return {
                                        name: name,
                                        value: value.toFixed(2) + '%'
                                    }
                                }]}
                            >
                                <Label content="name" />
                            </Interval>
                        </Chart>
                    </div>}
                </Col>

                <Col span={18}>
                    <div className="dash-kpi-container">
                        <KPI
                            title="Cross-Domain"
                            kind="percentage"
                            measure="TimelineOrders.crossDomainRatio"
                            timeDimension="TimelineOrders.createdAt"
                            filters={filters}
                            color="cyan"
                            projectId={projectCtx.currentProject.id}
                            cubejsApi={projectCtx.cubejsApi}
                            timezone={projectCtx.admin.timezone}
                            refreshAt={analyticsContext.refreshAt}
                            dateFrom={analyticsContext.dateFrom}
                            dateTo={analyticsContext.dateTo}
                            dateFromPrevious={analyticsContext.dateFromPrevious}
                            dateToPrevious={analyticsContext.dateToPrevious}
                        />
                        <KPI
                            title="Web to Retail"
                            kind="percentage"
                            measure="TimelineOrders.webToRetailDomainRatio"
                            timeDimension="TimelineOrders.truncCreatedAt"
                            filters={filters}
                            color="cyan"
                            projectId={projectCtx.currentProject.id}
                            cubejsApi={projectCtx.cubejsApi}
                            timezone={projectCtx.admin.timezone}
                            refreshAt={analyticsContext.refreshAt}
                            dateFrom={analyticsContext.dateFrom}
                            dateTo={analyticsContext.dateTo}
                            dateFromPrevious={analyticsContext.dateFromPrevious}
                            dateToPrevious={analyticsContext.dateToPrevious}
                        />
                        <KPI
                            title="Retail to Web"
                            kind="percentage"
                            measure="TimelineOrders.retailToWebDomainRatio"
                            timeDimension="TimelineOrders.truncCreatedAt"
                            filters={filters}
                            color="cyan"
                            projectId={projectCtx.currentProject.id}
                            cubejsApi={projectCtx.cubejsApi}
                            timezone={projectCtx.admin.timezone}
                            refreshAt={analyticsContext.refreshAt}
                            dateFrom={analyticsContext.dateFrom}
                            dateTo={analyticsContext.dateTo}
                            dateFromPrevious={analyticsContext.dateFromPrevious}
                            dateToPrevious={analyticsContext.dateToPrevious}
                            isLast={true}
                        />
                    </div>
                </Col>
            </Row>
            <div className="dash-kpi-container border-top-1">
                <KPI
                    title="Web only"
                    kind="percentage"
                    measure="TimelineOrders.webDomainRatio"
                    timeDimension="TimelineOrders.truncCreatedAt"
                    filters={filters}
                    color="cyan"
                    projectId={projectCtx.currentProject.id}
                    cubejsApi={projectCtx.cubejsApi}
                    timezone={projectCtx.admin.timezone}
                    refreshAt={analyticsContext.refreshAt}
                    dateFrom={analyticsContext.dateFrom}
                    dateTo={analyticsContext.dateTo}
                    dateFromPrevious={analyticsContext.dateFromPrevious}
                    dateToPrevious={analyticsContext.dateToPrevious}
                />
                <KPI
                    title="Retail only"
                    kind="percentage"
                    measure="TimelineOrders.retailDomainRatio"
                    timeDimension="TimelineOrders.truncCreatedAt"
                    filters={filters}
                    color="cyan"
                    projectId={projectCtx.currentProject.id}
                    cubejsApi={projectCtx.cubejsApi}
                    timezone={projectCtx.admin.timezone}
                    refreshAt={analyticsContext.refreshAt}
                    dateFrom={analyticsContext.dateFrom}
                    dateTo={analyticsContext.dateTo}
                    dateFromPrevious={analyticsContext.dateFromPrevious}
                    dateToPrevious={analyticsContext.dateToPrevious}
                />
                <KPI
                    title="App only"
                    kind="percentage"
                    measure="TimelineOrders.appDomainRatio"
                    timeDimension="TimelineOrders.createdAt"
                    filters={filters}
                    color="cyan"
                    projectId={projectCtx.currentProject.id}
                    cubejsApi={projectCtx.cubejsApi}
                    timezone={projectCtx.admin.timezone}
                    refreshAt={analyticsContext.refreshAt}
                    dateFrom={analyticsContext.dateFrom}
                    dateTo={analyticsContext.dateTo}
                    dateFromPrevious={analyticsContext.dateFromPrevious}
                    dateToPrevious={analyticsContext.dateToPrevious}
                />
                <KPI
                    title="Marketplace only"
                    kind="percentage"
                    measure="TimelineOrders.marketplaceDomainRatio"
                    timeDimension="TimelineOrders.truncCreatedAt"
                    filters={filters}
                    color="cyan"
                    projectId={projectCtx.currentProject.id}
                    cubejsApi={projectCtx.cubejsApi}
                    timezone={projectCtx.admin.timezone}
                    refreshAt={analyticsContext.refreshAt}
                    dateFrom={analyticsContext.dateFrom}
                    dateTo={analyticsContext.dateTo}
                    dateFromPrevious={analyticsContext.dateFromPrevious}
                    dateToPrevious={analyticsContext.dateToPrevious}
                />
                <KPI
                    title="Telephone only"
                    kind="percentage"
                    measure="TimelineOrders.telephoneDomainRatio"
                    timeDimension="TimelineOrders.truncCreatedAt"
                    filters={filters}
                    color="cyan"
                    projectId={projectCtx.currentProject.id}
                    cubejsApi={projectCtx.cubejsApi}
                    timezone={projectCtx.admin.timezone}
                    refreshAt={analyticsContext.refreshAt}
                    dateFrom={analyticsContext.dateFrom}
                    dateTo={analyticsContext.dateTo}
                    dateFromPrevious={analyticsContext.dateFromPrevious}
                    dateToPrevious={analyticsContext.dateToPrevious}
                    isLast={true}
                />
            </div>
        </div>
    </div>
}
