import { Component } from 'react'

import { DeleteOutlined, EditOutlined, CaretRightOutlined } from '@ant-design/icons';


import { Tag, Spin, Button, Popconfirm, Switch, Table } from 'antd';
import t from '../../utils/translate'
import Formatters from '../../utils/formatters'
import WorkflowButton from './_drawer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AppLayout from 'components/app/layout';

class WorkflowsList extends Component<any, any> {
  _isMounted = false;

  constructor(props: any) {
    super(props)

    this.state = {
      workflows: [],
      loading: false,
    }

    this.fetchWorkflows = this.fetchWorkflows.bind(this)
    this.activateWorkflow = this.activateWorkflow.bind(this)
    this.pauseWorkflow = this.pauseWorkflow.bind(this)
    this.deleteWorkflow = this.deleteWorkflow.bind(this)
    this.executeWorkflowManually = this.executeWorkflowManually.bind(this)
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;

    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Workflows (project: ' + this.props.projectLayout.project.id + ')',
        page: this.props.location.pathname,
        props: {
          orgId: this.props.projectLayout.project.organizationId,
          projectId: this.props.projectLayout.project.id
        }
      })
      window.cmAgent.dispatch()
    }

    this.fetchWorkflows()
  }

  componentDidUpdate(prevProps: any) {
    // check url params have changed
    if (prevProps.location.search !== this.props.location.search) {
      this.fetchWorkflows()
    }
  }

  fetchWorkflows() {

    if (this.state.loading === true) {
      return
    }

    this.setState({ loading: true })

    this.props.app.ajaxRequest({
      method: 'get',
      url: '/workflows.list',
      params: {
        projectId: this.props.projectLayout.project.id,
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }

      if (this._isMounted) {
        this.setState({
          workflows: response.data.workflows,
          loading: false
        })
      }
    })
  }

  activateWorkflow(workflowId: string) {

    if (this.state.loading === true) {
      return
    }

    this.setState({ loading: true })

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/workflows.activate',
      data: {
        workflowId: workflowId,
        projectId: this.props.projectLayout.project.id,
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }

      this.props.app.addMessage('success', t('workflow_activated', "The workflow has been activated!"))
      this.setState({ loading: false })

      if (this._isMounted) {
        this.fetchWorkflows()
      }
    })
  }

  executeWorkflowManually(workflowId: string) {

    if (this.state.loading === true) {
      return
    }

    this.setState({ loading: true })

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/workflows.executeManually',
      data: {
        workflowId: workflowId,
        projectId: this.props.projectLayout.project.id,
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }

      this.props.app.addMessage('success', t('workflow_executed', "The workflow has been launched!"))
      this.setState({ loading: false })

      if (this._isMounted) {
        this.fetchWorkflows()
      }
    })
  }

  pauseWorkflow(workflowId: string) {

    if (this.state.loading === true) {
      return
    }

    this.setState({ loading: true })

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/workflows.pause',
      data: {
        workflowId: workflowId,
        projectId: this.props.projectLayout.project.id,
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }

      this.props.app.addMessage('success', t('workflow_paused', "The workflow has been paused!"))
      this.setState({ loading: false })

      if (this._isMounted) {
        this.fetchWorkflows()
      }
    })
  }

  deleteWorkflow(workflowId: string) {

    if (this.state.loading === true) {
      return
    }

    this.setState({ loading: true })

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/workflows.delete',
      data: {
        workflowId: workflowId,
        projectId: this.props.projectLayout.project.id,
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }

      this.props.app.addMessage('success', t('workflow_deleted', "The workflow has been deleted!"))

      if (this._isMounted) {
        this.setState({ loading: false })
        this.fetchWorkflows()
      }
    })
  }

  render() {

    return <AppLayout currentProject={this.props.projectLayout.project} currentOrganization={this.props.organizationLayout.organization} admin={this.props.app.state.admin} firebaseUser={this.props.app.state.firebaseUser} projects={this.props.organizationLayout.projects}>

      <h1>
        <span className="actions">
          {this.state.workflows.length > 0 && <WorkflowButton
            type="primary"
            title={t('new_workflow', "New workflow")}
            app={this.props.app}
            project={this.props.projectLayout.project}
            userCustomProperties={this.props.projectLayout.userCustomProperties}
            notifications={this.props.projectLayout.notifications}
            notificationTopics={this.props.projectLayout.notificationTopics}
            segments={this.props.projectLayout.segments}
            onComplete={this.fetchWorkflows}
          >
            {t('new_workflow', "New workflow")}
          </WorkflowButton>}

          <Button className="margin-l-m" type="ghost" onClick={this.fetchWorkflows} disabled={this.state.loading}><FontAwesomeIcon icon="sync-alt" spin={this.state.loading} /></Button>
        </span>
        {t('workflows', "Workflows")}
      </h1>
      
      {this.state.loading && <div className="text-center margin-a-l"><Spin size="large" /></div>}

      {!this.state.loading && !this.state.workflows.length && <div className="text-center margin-v-l">
        <WorkflowButton
          size="large"
          type="primary"
          title={t('new_workflow', "New workflow")}
          app={this.props.app}
          project={this.props.projectLayout.project}
          userCustomProperties={this.props.projectLayout.userCustomProperties}
          notifications={this.props.projectLayout.notifications}
          notificationTopics={this.props.projectLayout.notificationTopics}
          segments={this.props.projectLayout.segments}
          onComplete={this.fetchWorkflows}
        >
          {t('new_workflow', "New workflow")}
        </WorkflowButton>
      </div>}

      {this.state.workflows.map((record: any) => {
        const tableData = [{
          id: 'triggers',
          title: t('triggers', "Triggers"),
          content: record.triggers.map((trigger: any) => <div key={trigger.id}>{Formatters.workflowTrigger(record.kind, trigger, this.props.projectLayout.segments)}</div>)
        }]

        if (record.limitExecPerUser) {
          tableData.push({
            id: 'exec',
            title: t('limit_exec_per_user', "Limit exec per user"),
            content: <span>
              {record.limitExecPerUser === 'once' && t('once', "Once")}
              {record.limitExecPerUser === 'oncePerDay' && t('once_per_day', "Once per day")}
              {record.limitExecPerUser === 'oncePerWeek' && t('once_per_week', "Once per week")}
              {record.limitExecPerUser === 'oncePerMonth' && t('once_per_month', "Once per month")}
            </span>
          })
        }
        if (record.kind !== 'userEvent') {

          tableData.push({
            id: 'segment',
            title: t('segment', "Segment"),
            content: Formatters.segment(this.props.projectLayout.segments, record.segmentId, undefined, true)
          })

          if (record.notificationTopicId) {
            tableData.push({
              id: 'topic',
              title: t('notification_topic', "Notification topic"),
              content: <Tag className="small">{this.props.projectLayout.notificationTopics.find((x: any) => x.id === record.notificationTopicId)?.name}</Tag>
            })
          }
        }

        return <div className="block margin-b-m" key={record.id}>
          <h2>{record.name}
            <span className="actions">
              {record.isActivated && <Popconfirm title={t('pause_workflow_confirm', "Do you really want to pause this workflow?")} onConfirm={this.pauseWorkflow.bind(null, record.id)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                <Switch checked={record.isActivated} />
              </Popconfirm>}

              {!record.isActivated && <Popconfirm title={t('activate_workflow_confirm', "Do you really want to activate this workflow?")} onConfirm={this.activateWorkflow.bind(null, record.id)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                <Switch checked={record.isActivated} />
              </Popconfirm>}

              <Button.Group className="margin-l-m">

                <Popconfirm placement="topRight" title={t('delete_workflow_confirm', "Do you really want to delete this workflow?")} onConfirm={() => this.deleteWorkflow(record.id)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                  <Button type="ghost"><DeleteOutlined /></Button>
                </Popconfirm>

                <WorkflowButton
                  type="ghost"
                  title={t('edit_workflow', "Edit workflow")}
                  app={this.props.app}
                  project={this.props.projectLayout.project}
                  segments={this.props.projectLayout.segments}
                  userCustomProperties={this.props.projectLayout.userCustomProperties}
                  notifications={this.props.projectLayout.notifications}
                  notificationTopics={this.props.projectLayout.notificationTopics}
                  onComplete={this.fetchWorkflows}
                  workflow={record}
                >
                  <EditOutlined /> {t('edit', "Edit")}
                </WorkflowButton>

                {record.triggers.find((x: any) => x.kind === 'onManualExecution') && record.isActivated && <Popconfirm placement="topRight" title={t('manually_execute_workflow_confirm', "Do you really want to execute this workflow now?")} onConfirm={() => this.executeWorkflowManually(record.id)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                  <Button type="ghost" icon={<CaretRightOutlined />}>{t('run', "Run")}</Button>
                </Popconfirm>}

              </Button.Group>
            </span>
          </h2>

          <Table 
            showHeader={false}
            size="middle"
            rowKey="id"
            dataSource={tableData}
            pagination={false}
            columns={[
            {
              title: '',
              key: 'title',
              width: '160px',
              render: (record: any) => <span className="subsider-attribute-key">{record.title}</span>
            },
            {
              title: '',
              key: 'content',
              render: (record: any) => record.content
            },
          ]} />
        </div>
      })}
    </AppLayout>
  }
}

export default WorkflowsList