import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Tag, Tooltip } from 'antd';
import t from '../../utils/translate'
import { toArray } from 'lodash'


const SegmentsSelect = ({ forCompanies, onChange, value, segments }) => {

  const setSegment = (e: any) => {
    onChange(e.key)
  }

  const segmentId = value || '_all'

  const segmentsArray = toArray(segments).filter(s => {
    if (s.id === '_all') return true
    // if (s.id === 'anon' || s.deletedAt)  return false
    if (forCompanies) {
      if (forCompanies && !s.forCompanies) return false
      if (!forCompanies && s.forCompanies) return false
    }
    return true
  })

  const nestSegments = (segments: any[], currentParentId?: string) => {
    const result: any = []

    segments.forEach((s: any) => {

      // exclude children who dont belong to this parent
      if (currentParentId && s.parentId !== currentParentId) {
        return
      }

      // exclude non root when not a child
      if (!currentParentId && s.parentId) {
        return
      }

      s.children = nestSegments(segments, s.id)
      result.push(s)
    })

    return result
  }

  const nestedSegments = nestSegments(segmentsArray)


  const flattenNestedSegments = ((segs: any, items: any, parents: any) => {

    segs.forEach((s: any) => {

      items.push({ segment: s, parents: parents })

      if (s.children && s.children.length > 0) {
        const parentsCopy = parents.slice()
        parentsCopy.push(s)
        flattenNestedSegments(s.children, items, parentsCopy)
      }
    })
  })

  const segmentsWithParents: any = []
  flattenNestedSegments(nestedSegments, segmentsWithParents, [])

  const activeSegment = segmentsWithParents.find((s: any) => segmentId === s.segment.id)
  
  if (segmentsWithParents.length > 0) {
    return (
      <Tooltip title={t('select_segment', "Select segment")}>
        <Dropdown trigger={['click']} overlay={<Menu onClick={setSegment}>
          {segmentsWithParents.map((s: any) => <Menu.Item key={s.segment.id}>
            {s.parents && s.parents.map((p: any) => <span key={p.id}><Tag className="no-pointer" color={p.color !== 'default' && p.color}>{p.name}</Tag> &gt; </span>)}
            <Tag className="no-pointer" color={s.segment.color !== 'default' && s.segment.color}>{s.segment.name}</Tag>
          </Menu.Item>)}
        </Menu>}>
          <Tag style={{ cursor: 'pointer' }} key={activeSegment.segment.id} color={activeSegment.segment.color !== 'default' && activeSegment.segment.color}>
            {activeSegment.parents && activeSegment.parents.map((p: any) => <span key={p.id}>{p.name} &gt; </span>)}
            {activeSegment.segment.name}
            <span className="padding-l-s" style={{ color: 'rgba(0, 0, 0, 0.25)' }}><DownOutlined /></span>
          </Tag>
        </Dropdown>
      </Tooltip>
    );
  }

  return <Tag key={activeSegment.segment.id} color={activeSegment.segment.color !== 'default' && activeSegment.segment.color}>
    {activeSegment.parents && activeSegment.parents.map((p: any) => <span key={p.id}>{p.name} &gt; </span>)}
    {activeSegment.segment.name}
  </Tag>
}
export default SegmentsSelect
