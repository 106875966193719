import{ useState } from 'react'
import { SelectedBlockButtonsProp } from '../Editor'
import { Tooltip, Popconfirm, Modal, Form, Button, Spin, Input, message, Select } from 'antd'
import { DragOutlined, DeleteOutlined, CopyOutlined, SaveOutlined } from '@ant-design/icons'
import { useProjectContext, ProjectContextValue } from 'components/projects'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faGripHorizontal, faHandRock, faArrowsAlt } from '@fortawesome/free-solid-svg-icons'

// interface SelectedBlockButtonsPropExtended extends SelectedBlockButtonsProp {
    // organizationLayout: any
    // project: any
    // app: any
// }

const SelectedBlockButtons = (props: SelectedBlockButtonsProp) => {

    const [saveVisible, setSaveVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const projectCtx: ProjectContextValue = useProjectContext()

    const [form] = Form.useForm();


    const toggleModal = () => {
        // reset on hide
        if (saveVisible === true) {
            form.resetFields()
        }
        setSaveVisible(!saveVisible)
    }

    const onSubmit = () => {

        form.validateFields().then((values: any) => {
            setLoading(true)

            const data: any = {
                projectId: projectCtx.currentProject.id,
                block: JSON.stringify(props.block),
                name: values.name,
            }

            if (values.operation === 'update') {
                data.id = values.id
            }

            projectCtx.ajaxRequest({
                method: 'post',
                url: '/emailBlocks.' + values.operation,
                data: data
            }, (errorMessage: any, response: any) => {

                if (errorMessage) {
                    message.error(errorMessage)
                    setLoading(false)
                    return
                }

                if (window.cmAgent) {
                    window.cmAgent.event({
                        label: 'saveEmailBlock',
                        props: {
                            orgId: projectCtx.currentOrganization.id,
                        }
                    })
                    window.cmAgent.dispatch()
                }

                if (values.operation === 'create') {
                    message.success('The block has been saved!')
                } else {
                    message.success('The block has been updated!')
                }

                // update current project
                let projects = projectCtx.projects.filter((p: any) => p.id !== response.data.project.id)
                projects.push(response.data.project)
                projectCtx.setProjects(projects)

                setLoading(false)
                setSaveVisible(false)
                form.resetFields()
            })
        }).catch((e: any) => {
            console.error(e)
        })
    }

    // console.log('props', props)
    const isDraggable = props.blockDefinitions[props.block.kind].isDraggable
    const isDeletable = props.blockDefinitions[props.block.kind].isDeletable

    return <div className="cmeditor-selected-block-buttons">
        {isDraggable === true && <Tooltip placement="left" title="Move">
            <div className="cmeditor-selected-block-button">
                <DragOutlined style={{ verticalAlign: 'middle', cursor: 'grab' }} />
            </div>
        </Tooltip>}

        {isDraggable === true && <Tooltip placement="left" title="Clone">
            <div className="cmeditor-selected-block-button" onClick={props.cloneBlock.bind(null, props.block)}>
                <CopyOutlined style={{ verticalAlign: 'middle' }} />
            </div>
        </Tooltip>}

        {isDraggable === true && <Tooltip placement="left" title="Save">
            <div className="cmeditor-selected-block-button" onClick={toggleModal}>
                <SaveOutlined style={{ verticalAlign: 'middle' }} />
            </div>
        </Tooltip>}

        {isDeletable === true && <Tooltip placement="left" title="Delete">
            <Popconfirm
                title="Are you sure to delete this element?"
                onConfirm={props.deleteBlock.bind(null, props.block)}
                okText="Yes"
                cancelText="No"
            >
                <div className="cmeditor-selected-block-button">
                    <DeleteOutlined style={{ verticalAlign: 'middle' }} />
                </div>
            </Popconfirm>
        </Tooltip>}

        {saveVisible && <Modal
            title="Save block"
            wrapClassName="vertical-center-modal"
            visible={true}
            onCancel={toggleModal}
            footer={[
                <Button key="back" type="ghost" loading={loading} onClick={toggleModal}>Cancel</Button>,
                <Button key="submit" type="primary" loading={loading} onClick={onSubmit}>Confirm</Button>,
            ]}>

            <Spin tip="Loading..." spinning={loading}>
                <Form form={form} initialValues={{ operation: 'create' }} layout="vertical">

                    <Form.Item
                        name="operation"
                        label="Operation"
                        rules={[{ required: true, message: "This field is required!" }]}
                    >
                        <Select options={[
                            { label: 'Save as new block', value: 'create' },
                            { label: 'Update existing block', value: 'update' },
                        ]} />
                    </Form.Item>

                    {/* <Radio.Group optionType="button" value={operation} onChange={(val) => console.log(val)}>
                            <Radio.Button onClick={(e: any) => console.log('hit', e)} onChange={(val) => console.log(val)} value="create">Save as new block</Radio.Button>
                            <Radio.Button onClick={(e: any) => console.log('hit', e)} onChange={(val) => console.log(val)} value="update">Update existing block</Radio.Button>
                        </Radio.Group> */}
                    {/* </Form.Item> */}


                    <Form.Item noStyle shouldUpdate={true}>
                        {({ getFieldValue }: any) => {

                            return <>
                                {getFieldValue('operation') === 'update' && <Form.Item
                                    name="id"
                                    label="Block"
                                    rules={[{ required: true, message: "This field is required!" }]}
                                >
                                    <Select onChange={(val: any) => {
                                        form.setFieldsValue({name: projectCtx.currentProject.emailBlocks.find((x: any) => x.id === val).name})
                                    }} options={projectCtx.currentProject.emailBlocks.map((b: any) => {
                                        return {
                                            label: b.name,
                                            value: b.id
                                        }
                                    })} />
                                </Form.Item>}

                                {(getFieldValue('operation') === 'create' || getFieldValue('id')) && <Form.Item
                                    name="name"
                                    label="Name"
                                    rules={[{ required: true, message: "This field is required!" }]}
                                >
                                    <Input />
                                </Form.Item>}
                            </>
                        }}
                    </Form.Item>


                </Form>
            </Spin>
        </Modal>}

        {/* <span style={{fontSize: '10px'}}>kind: {props.block.kind}, {isDraggable && <>draggable into: {props.blockDefinitions[props.block.kind].draggableIntoGroup}</>}</span> */}
    </div>
}

export default SelectedBlockButtons