import { useRef } from 'react'
import { message, Tooltip, Badge } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { FileManager } from '../fileManager/core/fileManager'
import { ItemFilter, Item, TreeNode } from '../fileManager/core/interfaces'
import FirestoreFileProvider from '../fileManager/providers/firestoreFileProvider'
import { Layout } from '../fileManager/ui/layout'
import { useProjectContext, ProjectContextValue } from '.'
import short from 'short-uuid'
import Config from 'config'
import AppLayout from 'components/app/layout'

const FileManagerPage = () => {
    const projectCtx: ProjectContextValue = useProjectContext()
    const filters: ItemFilter[] = []

    const fsProvider = useRef(new FirestoreFileProvider({
        FirebaseApp: projectCtx.firebaseApp.current,
        resolveRootNodeID: (): string => {
            return projectCtx.currentProject.id
        },
        resolveUploadPath: (item: Item): string => {
            item.id = short.uuid()
            const uploadPath = '/' + projectCtx.currentProject.id + item.path.replace(new RegExp('~', 'g'), '/') + '/' + item.id
            // console.log('uploadPath', uploadPath)
            return uploadPath
        },
        // collectionsPrefix: 'fs_',
        getNodeMetadata: (_node: TreeNode): any => {
            return {
                userId: projectCtx.firebaseUser.uid,
                organizationId: projectCtx.currentOrganization.id,
                projectId: projectCtx.currentProject.id,
            }
        },
        // filterKey: 'projectId',
        // filterValue: 'test',
    }))

    return <AppLayout currentProject={projectCtx.currentProject} currentOrganization={projectCtx.currentOrganization} admin={projectCtx.admin} firebaseUser={projectCtx.firebaseUser} projects={projectCtx.projects}>

        <h1>File Manager</h1>

        <p>CDN connected: {projectCtx.currentProject.hasCDN ? <Badge status="success" text={projectCtx.currentProject.cdnEndpoint} /> : <Tooltip title={'Actual endpoint: https://storage.googleapis.com/' + Config().firebase_storage_bucket}><Badge status="warning" text={<span>no <InfoCircleOutlined /></span>} /></Tooltip>}</p>

        <FileManager
            itemFilters={filters}
            onError={(error) => {
                alert('error')
                console.error(error)
                message.error(error)
            }}
            fileProvider={fsProvider.current}
        >
            <Layout {...{
                visible: true,
                height: 500,
                acceptFileType: 'images/*',
                onError: (e) => message.error(e),
                onSelect: (_items: Item[]) => {
                },
                withSelection: false,
                multiple: false,
                acceptItem: (item: Item) => {
                    return item.contentType.includes('image')
                }
            }} />
        </FileManager>
    </AppLayout>
}

export default FileManagerPage