import React, { Component } from 'react'
import Prism from 'prismjs'
import "prismjs/themes/prism-okaidia.css";

class PrismCode extends Component<any, any> {
  myRef: any

  constructor(props: any) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    Prism.highlightElement(this.myRef.current)
    // Prism.highlightAll();
  }

  render() {
    return <pre className="code">
      <code
        ref={this.myRef}
        style={{ wordBreak: 'break-all', wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}
        className={'language-' + (this.props.language || 'javascript')}
      >
        {this.props.children}
      </code>
    </pre>
  }
}

export default PrismCode