import React, { Component } from 'react'

import t from '../../utils/translate'
import { CalendarOutlined, DownOutlined } from '@ant-design/icons';
import { DatePicker, Button, Menu, Dropdown } from 'antd';
import Moment from 'moment-timezone';
const { RangePicker } = DatePicker;

class DateRange extends Component<any, any> {
  presets: any = [
    {
      key: 'last_30_days',
      range: [
        Moment().tz(this.props.timezone).subtract(31, 'day').startOf('day').format('YYYY-MM-DD'),
        Moment().tz(this.props.timezone).subtract(1, 'day').endOf('day').format('YYYY-MM-DD'),
      ],
      label: t('last_30_days', "Last 30 days")
    },
    {
      key: 'last_7_days',
      range: [
        Moment().tz(this.props.timezone).subtract(8, 'day').startOf('day').format('YYYY-MM-DD'),
        Moment().tz(this.props.timezone).subtract(1, 'day').endOf('day').format('YYYY-MM-DD'),
      ],
      label: t('last_7_days', "Last 7 days")
    },
    {
      key: 'last_14_days',
      range: [
        Moment().tz(this.props.timezone).subtract(15, 'day').startOf('day').format('YYYY-MM-DD'),
        Moment().tz(this.props.timezone).subtract(1, 'day').endOf('day').format('YYYY-MM-DD'),
      ],
      label: t('last_14_days', "Last 14 days")
    },
    {
      key: 'last_week',
      range: [
        Moment().tz(this.props.timezone).startOf('week').subtract(1, 'week').format('YYYY-MM-DD'),
        Moment().tz(this.props.timezone).startOf('week').subtract(1, 'second').format('YYYY-MM-DD'),
      ],
      label: t('last_week', "Last week")
    },
    {
      key: 'last_month',
      range: [
        Moment().tz(this.props.timezone).startOf('month').subtract(1, 'month').format('YYYY-MM-DD'),
        Moment().tz(this.props.timezone).startOf('month').subtract(1, 'second').format('YYYY-MM-DD'),
      ],
      label: t('last_month', "Last month")
    },
    // {
    //   key: 'yesterday',
    //   range: [
    //     Moment().tz(this.props.timezone).startOf('day').subtract(1, 'day').format('YYYY-MM-DD'),
    //     Moment().tz(this.props.timezone).startOf('day').subtract(1, 'second').format('YYYY-MM-DD'),
    //   ],
    //   label: t('yesterday', "Yesterday")
    // },
    // {
    //   key: 'today',
    //   range: [
    //       Moment().tz(this.props.timezone).startOf('day').format('YYYY-MM-DD'),
    //       Moment().tz(this.props.timezone).endOf('day').format('YYYY-MM-DD'),
    //   ],
    //   label: t('today', "Today")
    // },
    {
      key: 'custom',
      range: [],
      label: t('custom', "Custom")
    },
  ]

  constructor(props: any) {
    super(props)

    this.state = {
      visible: false,
      vsVisible: false,
      openCustom: false,
      customValue: this.props.dateRange
    }


    this.handleClick = this.handleClick.bind(this)
    this.handleClickVs = this.handleClickVs.bind(this)
  }

  handleClick(e: any) {

    const range = this.presets.find((x: any) => x.key === e.key)

    if (e.key === "custom") {
      this.setState({ openCustom: true })
    } else if (range) {
      this.props.onChange(e.key, range.range)
    } else {
      console.error('date range not implemented', e.key)
    }

    this.setState({ visible: false })
  }

  handleClickVs(e: any) {
    this.props.onVsChange(e.key)
    this.setState({ vsVisible: false })
  }

  render() {

    let label = 'unknown period'

    if (this.props.labelKey === 'custom') {
      label = Moment(this.props.dateRange[0]).format('ll') + ' ~ ' + Moment(this.props.dateRange[1]).format('ll')
    }
    else {
      const range = this.presets.find((x: any) => x.key === this.props.labelKey)
      if (range) {
        label = range.label
      }
    }

    let vsLabel = t('previous_period', "Previous period")

    if (this.props.vs === 'previous_year') {
      vsLabel = t('previous_year', "Previous year")
    }

    // console.log("this.state.customValue", this.state.customValue)

    return (
      <span>
        <Dropdown placement="bottomRight" trigger={['click']} overlay={<Menu onClick={this.handleClick}>{this.presets.map((x: any) => <Menu.Item key={x.key}>{this.props.labelKey === x.key && "•"} {x.label}</Menu.Item>)}</Menu>} visible={this.state.visible} onVisibleChange={(visible) => this.setState({ visible: visible })}>
          <Button size="small">
            <CalendarOutlined /> <span className="size-12">{label}</span> <DownOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />
            {this.state.openCustom === true &&
              <RangePicker
                open={true}
                onOpenChange={(status) => this.setState({ openCustom: status })}
                value={this.state.customValue}
                style={{ position: 'absolute', opacity: '0' }}
                onCalendarChange={(values, formatString, info) => {
                  // console.log('values', values)
                  // console.log('formatString', formatString)
                  // console.log('info', info)
                  if (info.range === 'end') {
                    this.props.onChange('custom', formatString)
                    this.setState({ openCustom: false, visible: false })
                  }
                }}
                disabledDate={(date: any) => Moment.tz(date, this.props.timezone).endOf('day').isAfter(Moment().tz(this.props.timezone).endOf('day'))}
              />}
          </Button>
        </Dropdown>

        {!this.props.noCompare && <>
          <span className="padding-h-s">vs</span>

          <Dropdown placement="bottomRight" trigger={['click']} visible={this.state.vsVisible} onVisibleChange={(visible) => this.setState({ vsVisible: visible })} overlay={<Menu onClick={this.handleClickVs}>
            <Menu.Item key="previous_period">{this.props.vs === "previous_period" && "•"} {t('previous_period', "Previous period")}</Menu.Item>
            <Menu.Item key="previous_year">{this.props.vs === "previous_year" && "•"} {t('previous_year', "Previous year")}</Menu.Item>
          </Menu>}>
            <Button size="small"><span className="size-12">{vsLabel}</span> <DownOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} /></Button>
          </Dropdown>
        </>}

      </span>
    );
  }
}

export default DateRange

