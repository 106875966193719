import React, { createContext, useContext, useState, useEffect, useMemo, useCallback } from "react"
import { FileManagerProps, Folder, ListOptions, Item, TreeNode, FileProvider } from './interfaces'


const FileManagerContext = createContext<FileManagerContextValue | null>(null);

export function useFileManagerContext(): FileManagerContextValue {
  const fmValue = useContext(FileManagerContext);
  if (!fmValue) {
    throw new Error("Missing FileManagerContextProvider in its parent.");
  }
  return fmValue;
}

export interface FileManagerContextValue {
  initializating: boolean
  tree: Folder | undefined
  setTree: (newTree: Folder) => void
  treeLoading: boolean
  // setTreeLoading: (value: boolean) => void
  isUpdatingItems: boolean
  // setIsUpdatingItems: (value: boolean) => void
  currentPath: string
  // setCurrentPath: (newPath: string) => void
  currentItems: Item[]
  uploadQueue: Item[]
  setUploadQueue: (items: Item[]) => void
  isUploading: boolean
  // setIsUploading: (value: boolean) => void
  listOptions: ListOptions
  setListOptions: (opts: ListOptions) => void
  fileProvider: FileProvider
  goToFolder: (path: string, reload?: boolean) => void
  deleteNode: (node: TreeNode) => Promise<void>
  loadNodesAtPath: (path: string, reload?: boolean) => Promise<void>
  // utils
  getParentPath: (path: string) => string
  getFolderAtPath: (node: Folder, path: string) => Folder | undefined
  getItemsAtPath: (node: Folder | undefined, path: string) => Item[]
}



export const getItemsAtPath = (node: Folder | undefined, path: string): Item[] => {
  if (!node) return []
  const folder = getFolderAtPath(node, path) as Folder | undefined
  if (!folder) return []
  return folder.children.filter(x => !x.isFolder) as Item[]
}

export const getParentPath = (path: string): string => {
  const bits = path.split('~')
  if (bits.length > 2) bits.pop()
  return bits.join('~')
}

export const getFolderAtPath = (node: Folder, path: string): Folder | undefined => {
  if (!node.isFolder) return undefined
  if (node.path === path) return node
  if (node.children.length === 0) return undefined
  let found: Folder | undefined
  node.children.some(sub => {
    if (sub.isFolder) {
      found = getFolderAtPath(sub as Folder, path)
      if (found) return true
    }
    return false
  })
  return found
}

export const FileManager = (props: FileManagerProps) => {

  const [initializating, setInitializating] = useState(true)
  const [tree, setTree] = useState<Folder | undefined>(undefined)
  const [treeLoading, setTreeLoading] = useState(false)
  const [currentPath, setCurrentPath] = useState<string>('~')
  // const [itemList, setItemList] = useState<ItemList>({ items: [] })
  // const [itemListLoading, setItemListLoading] = useState(false)
  const [uploadQueue, setUploadQueue] = useState<Item[]>([])
  const [isUploading, setIsUploading] = useState(false)
  const [isUpdatingItems, setIsUpdatingItems] = useState(false)
  const [listOptions, setListOptions] = useState<ListOptions>({
    // filters: 
    // skip:
    // limit:
    sortKey: 'createdAt',
    sortOrder: 'desc',
  })
  // const [view, _setView] = useState<string>('table')


  // go to folder and load subfolders + items
  const goToFolder = useCallback((path: string, reload?: boolean) => {
    props.fileProvider.loadNodesAtPath(path, reload).then(() => {
      setCurrentPath(path)
    }).catch(props.onError)
  }, [props.fileProvider, props.onError])

  // load foldersList on mount
  const init = useCallback(() => {
    props.fileProvider.init({
      setTree: setTree,
      goToFolder: goToFolder,
      setIsUploading: setIsUploading,
      setUploadQueue: setUploadQueue,
    }).then((tree: Folder) => {
      setInitializating(false)
      goToFolder(tree.path)
    }).catch(props.onError)
  }, [goToFolder, props.fileProvider, props.onError])

  useEffect(() => {
    init()
    return function cleanup() {
      props.fileProvider.cleanUp()
    }
  }, [init, props.fileProvider])


  // reload items after upload or update item
  useEffect(() => {
    if (isUploading === false && isUpdatingItems === false) {
      props.fileProvider.loadNodesAtPath(currentPath, listOptions)
    }
  }, [isUploading, listOptions, isUpdatingItems, props.fileProvider, currentPath])




  // load items at path with filters
  // const loadItems = (path: string, options: ListOptions) => {
  //   return new Promise<void>((resolve, reject) => {
  //     setItemListLoading(true)
  //     props.fileProvider.listItems(path, options).then(itemList => {
  //       setItemList(itemList)
  //       setItemListLoading(false)
  //       resolve(undefined)
  //     }).catch((e: any) => {
  //       props.onError(e)
  //       setItemListLoading(false)
  //       reject(e)
  //     })
  //   })
  // }


  // refresh parent folder tree and go to new folder
  // const onFolderCreated = (newFolder: Folder) => {
  //   loadNodesAtPath(getParentPath(newFolder.path)).then(() => {
  //     goToFolder(newFolder.path)
  //   }).catch(props.onError)
  // }


  // go to parent folder after delete
  // const onFolderDelete = (deletedFolder: Folder) => {
  //   goToFolder(getParentPath(deletedFolder.path), true)
  // }

  // const onFolderRename = (_renamedFolder: Folder) => {
  //   // reload tree
  //   // TODO
  //   // loadNodesAtPath(renamedFolder.path)
  //   // init()
  // }

  const deleteNode = (node: TreeNode): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      if (!node.isFolder) setIsUpdatingItems(true)
      props.fileProvider.deleteNode(node).then(() => {
        if (!node.isFolder) setIsUpdatingItems(false)
        resolve(undefined)
      }).catch(e => {
        props.onError(e)
        if (!node.isFolder) setIsUpdatingItems(false)
        reject(e)
      })
    })
  }

  // fetch children folders at given path, aborts if children are already loaded
  const loadNodesAtPath = (path: string, reload?: boolean): Promise<void> => {
    // console.log('loadNodesAtPath', path)
    return new Promise<void>((resolve, reject) => {
      setTreeLoading(true)

      props.fileProvider.loadNodesAtPath(path, {}, reload).then(() => {
        // setTree(newTree)
        setTreeLoading(false)
        resolve(undefined)

      }).catch(e => {
        setTreeLoading(false)
        props.onError(e)
        reject(e)
      })
    })
  }


  const currentItems = useMemo(() => getItemsAtPath(tree as Folder, currentPath), [tree, currentPath]);

  // console.log('isUploading', isUploading)
  // console.log('uploadQueue', uploadQueue)
  // console.log('currentPath', currentPath)
  // console.log('tree', tree)
  // console.log('currentItems', currentItems)
  const fmProps: FileManagerContextValue = {
    initializating: initializating,
    tree: tree,
    setTree: setTree,
    treeLoading: treeLoading,
    // setTreeLoading: setTreeLoading,
    isUpdatingItems: isUpdatingItems,
    currentPath: currentPath,
    // setCurrentPath: (newPath: string) => void
    currentItems: currentItems,
    uploadQueue: uploadQueue,
    setUploadQueue: setUploadQueue,
    isUploading: isUploading,
    // setIsUploading: setIsUploading,
    listOptions: listOptions,
    setListOptions: setListOptions,
    fileProvider: props.fileProvider,
    goToFolder: goToFolder,
    deleteNode: deleteNode,
    loadNodesAtPath: loadNodesAtPath,
    // utils
    getParentPath: getParentPath,
    getFolderAtPath: getFolderAtPath,
    getItemsAtPath: getItemsAtPath,
  }

  return <FileManagerContext.Provider value={fmProps}>{props.children}</FileManagerContext.Provider>
}