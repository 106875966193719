import { useRef, useState, useEffect } from 'react'
import {
    Alert,
    Spin,
    Button,
    Modal,
    message,
} from 'antd'
import MacroForm from './_macroForm'
import t from '../../utils/translate'
import { DeleteOutlined, ExclamationCircleOutlined, ArrowsAltOutlined, ShrinkOutlined } from '@ant-design/icons';
import PrismCode from '../../utils/prism'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useProjectContext, ProjectContextValue } from '../projects'
import { useLocation } from 'react-router-dom'

const ProjectMacros = () => {

    const projectCtx: ProjectContextValue = useProjectContext()
    const location = useLocation() as any
    const [loading, setLoading] = useState(false)
    const didMount = useRef(false)

    useEffect(() => {
        if (didMount.current) return
        didMount.current = true

        if (window.cmAgent) {
            window.cmAgent.pageview({
                title: 'Macros pages (project: ' + projectCtx.currentProject.id + ')',
                page: location.pathname,
                props: {
                    orgId: projectCtx.currentProject.organizationId,
                    projectId: projectCtx.currentProject.id
                }
            })
            window.cmAgent.dispatch()
        }
    }, [projectCtx.currentProject.id, projectCtx.currentProject.organizationId, location.pathname])

    const onComplete = () => {
        setLoading(true)
        projectCtx.refreshNotificationsAndMacros().then(() => {
            setLoading(false)
        })
    }

    return <div className="margin-t-l">

        <div className="text-right margin-b-l">
            <MacroForm
                project={projectCtx.currentProject}
                ajaxRequest={projectCtx.ajaxRequest}
                macro={undefined}
                btnSize={undefined}
                inDrawer={true}
                onComplete={onComplete}
            />
            <Button className="margin-l-m" type="ghost" onClick={onComplete} disabled={loading}><FontAwesomeIcon icon="sync-alt" spin={loading} /></Button>
        </div>

        <Alert
            description={t('macros_page_info', "A macros page is the place where you can declare useful templating macros that can be used in your notifications templates (i.e: your HTML email layout).")}
            message={undefined}
            type="info"
            showIcon
            className="margin-b-l"
        />

        {loading && <div className="text-center"><Spin spinning={true} /></div>}

        {!loading && projectCtx.currentProject.macros.map(macro => <MacroItem
            project={projectCtx.currentProject}
            ajaxRequest={projectCtx.ajaxRequest}
            key={macro.id}
            onComplete={onComplete}
            macro={macro}
        />
        )}
    </div>
}

export default ProjectMacros

const MacroItem = ({ macro, onComplete, project, ajaxRequest }) => {

    // console.log('MacroItem projectLayout', projectLayout);
    const [expanded, setExpanded] = useState(false);

    // console.log('form', form);

    const deleteConfirm = () => {
        Modal.confirm({
            title: 'Delete this macros page?',
            content: 'Do you really want to delete this macros page?',
            icon: <ExclamationCircleOutlined />,
            okText: t('confirm', "Confirm"),
            cancelText: t('cancel', "Cancel"),
            okType: 'danger',
            onOk: () => {
                return new Promise((resolve, reject) => {

                    ajaxRequest({
                        method: 'post',
                        url: '/macros.delete',
                        data: {
                            projectId: project.id,
                            id: macro.id
                        }
                    }, (error: any, data: any) => {

                        if (error) {
                            message.error(error)
                            return reject(error)
                        }

                        message.success('Your macros page has been successfully deleted!')

                        onComplete()
                        return resolve(undefined)
                    })
                })
            },
        })
    }

    const toggleExpand = () => {
        setExpanded(!expanded)
    }

    return <div className="block margin-b-l">
        <h2 className="no-margin">
            <span className="actions">
                <Button.Group>
                    <Button type="ghost" size="small" onClick={deleteConfirm}><DeleteOutlined /></Button>
                    <MacroForm
                        project={project}
                        ajaxRequest={ajaxRequest}
                        btnSize="small"
                        inDrawer={true}
                        macro={macro}
                        onComplete={onComplete}
                    />
                </Button.Group>
            </span>
            {macro.name}
        </h2>

        <div className={cn('macro-content', { expanded: expanded })}>
            <PrismCode language="javascript">{macro.content}</PrismCode>
        </div>
        <div className={cn({ 'text-center': !expanded, 'macro-content-expand-button': expanded })}>
            <Button type={expanded ? 'default' : 'primary'} className="margin-v-s" size="small" ghost onClick={toggleExpand}>
                {!expanded && <><ArrowsAltOutlined /> {t('expand', 'Expand')}</>}
                {expanded && <><ShrinkOutlined /> {t('minimize', 'Minimize')}</>}
            </Button>
        </div>
    </div>
}
