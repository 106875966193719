import { useCallback, useEffect, useState } from 'react'

import {
    Button,
    Modal,
    Spin,
    Table,
    Badge,
    Popconfirm,
    Input,
    Select,
    Form,
    // DatePicker,
} from 'antd';
import t from '../../utils/translate'
import _ from 'lodash'
import logoKlaviyo from '../../images/integrations/klaviyo.png'
import { DeleteOutlined } from '@ant-design/icons';
// import Moment from 'moment-timezone'

const IntegrationsKlaviyo = ({ integration, disconnect, projectLayout, organizationLayout, app }: any) => {
    const [loading, setLoading] = useState(false)
    const [lists, setLists] = useState([])
    const [listsLoaded, setListsLoaded] = useState(false)

    const connectKlaviyo = (values: any) => {
        setLoading(true)

        app.ajaxRequest({
            method: 'post',
            url: '/integrations.connect',
            data: {
                projectId: projectLayout.project.id,
                integrationId: integration.id,
                klaviyo: values
            }
        }, (errorMessage: any, response: any) => {

            if (errorMessage) {
                app.addMessage('error', errorMessage)
                setLoading(false)
                return
            }

            let projects = _.filter(organizationLayout.projects, (p: any) => p.id !== response.data.project.id)
            projects.push(response.data.project)
            organizationLayout.setProjects(projects)

            app.addMessage('success', t('klaviyo_connect_success', "Klaviyo has successfully been connected!"))
            setLoading(false)
            fetchLists()
        })
    }

    const fetchLists = useCallback(() => {

        app.ajaxRequest({
            method: 'get',
            url: '/integrations.listKlaviyoLists',
            params: {
                projectId: projectLayout.project.id,
                integrationId: integration.id,
            }
        }, (errorMessage: any, response: any) => {
            if (errorMessage) {
                app.addMessage('error', errorMessage)
                return
            }
            setLists(response.data.lists)
            setListsLoaded(true)
        })
    }, [app, integration.id, projectLayout.project.id])

    // on mount
    useEffect(() => {
        if (integration.status === 'connected') fetchLists()
    }, [fetchLists, integration.status])

    const syncNow = () => {
        setLoading(true)
        app.ajaxRequest({
            method: 'get',
            url: '/integrations.syncKlaviyoLists',
            params: {
                projectId: projectLayout.project.id,
            }
        }, (errorMessage: any, _response: any) => {
            setLoading(false)
            if (errorMessage) {
                app.addMessage('error', errorMessage)
                return
            }
            app.addMessage('success', 'Your lists are syncing!')
        })
    }

    const disconnectList = (id: string) => {
        if (loading) return

        setLoading(true)
        app.ajaxRequest({
            method: 'post',
            url: '/integrations.disconnectKlaviyoList',
            data: {
                projectId: projectLayout.project.id,
                integrationId: integration.id,
                id: id,
            }
        }, (errorMessage: any, response: any) => {
            if (errorMessage) {
                app.addMessage('error', errorMessage)
                setLoading(false)
                return
            }

            let projects = _.filter(organizationLayout.projects, (p: any) => p.id !== response.data.project.id)
            projects.push(response.data.project)
            organizationLayout.setProjects(projects)

            setLoading(false)
        })
    }

    // console.log('integration', integration)

    return <div>
        <h2 className="margin-t-m">{t('settings', "Settings")}</h2>
        {(integration.status === 'setup') || (integration.status === 'disconnected') ?
            <div style={{ width: '500px', margin: '32px auto' }}>
                <div className="paper padding-h-xl padding-b-xl text-center">
                    <img src={logoKlaviyo} alt="" style={{ height: '200px' }} />
                    <Form layout="vertical" onFinish={connectKlaviyo}>
                        <Form.Item label="API key" name="apiKey" rules={[{ required: true, type: 'string' }]}>
                            <Input />
                        </Form.Item>
                        <Button size="large" type="primary" htmlType="submit" loading={loading}>{t('connect', "Connect")}</Button>
                    </Form>
                </div>
            </div>
            :
            <div className="margin-a-m">
                <table className="key-value-table">
                    <tbody>
                        <tr>
                            <th style={{ width: '300px' }}>{t('integration_id', "Integration ID")}</th>
                            <td>{integration.id}</td>
                        </tr>
                        <tr>
                            <th>{t('status', "Status")}</th>
                            <td>
                                {integration.status === 'connected' && <span>
                                    <Badge status="success" /> {t('connected', "Connected")}
                                    <Popconfirm placement="top" title={t('disconnect_klaviyo_confirm', "Do you really want to disconnect Klaviyo?")} onConfirm={disconnect.bind(null, integration)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                                        <Button className="margin-l-m" loading={loading} ghost size="small" danger={true}>{t('disconnect', "Disconnect")}</Button>
                                    </Popconfirm>
                                </span>}
                            </td>
                        </tr>
                        <tr>
                            <th>Connected Klaviyo lists</th>
                            <td>
                                {listsLoaded === false && <Spin />}
                                {listsLoaded && <>
                                    {integration.klaviyoSettings.connectedLists && integration.klaviyoSettings.connectedLists.length > 0 && <Table
                                        dataSource={integration.klaviyoSettings.connectedLists || []}
                                        rowKey="id"
                                        pagination={false}
                                        className="margin-b-m"
                                        columns={[
                                            {
                                                key: 'name',
                                                title: 'Klaviyo list',
                                                render: x => {
                                                    const list: any = lists.find((l: any) => l.list_id === x.listId)
                                                    if (!list) return '(list not found)'
                                                    return list.list_name
                                                },
                                            },
                                            {
                                                key: 'notificationTopic',
                                                title: 'Notification topic',
                                                render: (item: any) => {
                                                    const topic = projectLayout.notificationTopics.find((x: any) => x.id === item.notificationTopicId)
                                                    if (!topic) return '(topic not found)'
                                                    return topic.name
                                                },
                                            },
                                            {
                                                key: 'isSourceOfTruth',
                                                title: 'Is Klaviyo the source of truth for subscriptions?',
                                                render: (item: any) => item.isSourceOfTruth ? 'yes' : 'no'
                                            },
                                            {
                                                key: 'actions',
                                                title: '',
                                                render: (item: any) => <div>
                                                    <Popconfirm placement="top" title="Do you really want to disconnect this list?" onConfirm={disconnectList.bind(null, item.id)} okText="confirm" cancelText="cancel">
                                                        <Button loading={loading} ghost size="small" danger={true}><DeleteOutlined /></Button>
                                                    </Popconfirm>
                                                </div>
                                            },
                                        ]}
                                    />}
                                    <ConnectModal
                                        lists={lists}
                                        app={app}
                                        projectLayout={projectLayout}
                                        organizationLayout={organizationLayout}
                                        integration={integration}
                                    />
                                </>}
                            </td>
                        </tr>
                        <tr>
                            <th>{t('actions', "Actions")}</th>
                            <td>
                                <Popconfirm placement="top" title="Do you really want to sync your lists now?" onConfirm={syncNow} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                                    <Button loading={loading}>Sync now</Button>
                                </Popconfirm>
                            </td>
                        </tr>
                        {/* {integration.klaviyoSettings.lastDateProcessed && <tr>
                                <th>Last date processed</th>
                                <td>{integration.klaviyoSettings.lastDateProcessed}</td>
                            </tr>}
                            <tr>
                                <th>{t('actions', "Actions")}</th>
                                <td>
                                    <SyncModal app={app} projectLayout={projectLayout} integration={integration} />
                                </td>
                            </tr> */}
                    </tbody>
                </table>
            </div>
        }
    </div>
}

const ConnectModal = ({ app, projectLayout, integration, lists, organizationLayout }: any) => {
    const [loading, setLoading] = useState(false)
    const [modalVisible, setModalVisible] = useState(false)
    const [form] = Form.useForm();

    return <>
        <Button block type="primary" ghost onClick={() => setModalVisible(true)}>Connect a list</Button>

        <Modal
            title="Connect a list"
            okText="Connect"
            visible={modalVisible}
            onCancel={() => setModalVisible(false)}
            cancelButtonProps={{ loading: loading }}
            okButtonProps={{ loading: loading }}
            onOk={() => {
                if (loading) return

                form.validateFields().then((values: any) => {
                    setLoading(true)

                    app.ajaxRequest({
                        method: 'post',
                        url: '/integrations.connectKlaviyoList',
                        data: {
                            projectId: projectLayout.project.id,
                            integrationId: integration.id,
                            listId: values.listId,
                            notificationTopicId: values.notificationTopicId,
                            isSourceOfTruth: true,
                            // isSourceOfTruth: values.isSourceOfTruth || false,
                        }
                    }, (errorMessage: any, response: any) => {

                        form.resetFields()

                        if (errorMessage) {
                            app.addMessage('error', errorMessage)
                            setLoading(false)
                            return
                        }

                        let projects = _.filter(organizationLayout.projects, (p: any) => p.id !== response.data.project.id)
                        projects.push(response.data.project)
                        organizationLayout.setProjects(projects)

                        app.addMessage('success', 'The list has been connected!')
                        setLoading(false)
                        setModalVisible(false)

                    })
                }).catch(info => {
                    console.log('Validate Failed:', info);
                });
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
            >
                <Form.Item
                    name="listId"
                    label="Select a list"
                    rules={[{ required: true, type: 'string' }]}
                >
                    <Select
                        placeholder="Select a Klaviyo list"
                        options={lists.map((l: any) => {
                            return {
                                label: l.list_name,
                                value: l.list_id,
                            }
                        })}
                    />
                </Form.Item>

                <Form.Item
                    name="notificationTopicId"
                    label="Select a Notification Topic"
                    rules={[{ required: true, type: 'string' }]}
                >
                    <Select
                        placeholder="Select a Notification Topic"
                        options={projectLayout.notificationTopics.filter((x: any) => x.kind === 'broadcast').map((nt: any) => {
                            return {
                                label: nt.name,
                                value: nt.id,
                            }
                        })}
                    />
                </Form.Item>

                {/* <Form.Item
                    name="isSourceOfTruth"
                    label="Is Klaviyo the source of truth for newsletter consent?"
                    rules={[{ required: false, type: 'boolean' }]}
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item> */}
            </Form>
        </Modal>
    </>
}

export default IntegrationsKlaviyo