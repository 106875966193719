import React, { Component } from 'react'

import t from '../../utils/translate'
import { CrownOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';

class AdminsChangeOwnerButton extends Component<any, any> {
  _isMounted = false;

  constructor(props: any) {
    super(props);

    this.state = {
      ajaxLoading: false
    };

    this.showConfirm = this.showConfirm.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  showConfirm() {
    Modal.confirm({
      title: t('transfer_ownsership', "Transfer ownership"),
      content: t('transfer_ownsership_content', "Do you really want to transfer the ownership of this organization?"),
      onOk: this.submit,
      okText: t('confirm', "Confirm"),
      cancelText: t('cancel', "Cancel"),
      onCancel() {},
    });
  }

  submit() {    
    this.setState({ajaxLoading: true});

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/organizations.transferOwnership',
      data: {
        organizationId: this.props.organization.id,
        adminId: this.props.admin.id
      }
    }, (errorMessage: any) => {

      if (errorMessage) {
        this.props.app.addNotification('error', 'Invitation error', errorMessage);
        this.setState({ajaxLoading: false});
        return;
      }

      if (this._isMounted) {
        this.setState({ajaxLoading: false});
      }
      
      this.props.app.addMessage('success', t('ownership_transfered', 'The ownership of your organization has been transfered!'))

      if (window.cmAgent) {
        window.cmAgent.event({
          label: 'transferOwnership',
          props: {
            orgId: this.props.organization.id,
          }
        })
        window.cmAgent.dispatch()
      }
    });
  }

  render() {
    return (
      <Button
        loading={this.state.ajaxLoading}
        size={this.props.size}
        type={this.props.type || 'default'}
        style={this.props.style || {}}
        onClick={this.showConfirm}>
          <CrownOutlined />
      </Button>
    );
  }
}

export default AdminsChangeOwnerButton