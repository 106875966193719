import { Component } from 'react'

// import t from '../../utils/translate'
import { Spin, Tooltip, message } from 'antd'
import Formatters from '../../utils/formatters'
import N from 'numeral'
import Colors from '../../utils/colors'
import cn from 'classnames'
import {
  Chart,
  Geom,
  Axis,
  Legend,
  Tooltip as ChartTooltip,
} from "bizcharts"

class KPI extends Component<any, any> {
  _isMounted = false;

  constructor(props: any) {
    super(props)
    
    this.state = {
      loading: true,
      data: [],
      total: 0,
      dataPrevious: [],
      totalPrevious: 0
    }

    this.fetchAll = this.fetchAll.bind(this)
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchAll()
  }

  componentDidUpdate(prevProps: any) {
    // check url params have changed
    if (prevProps.refreshAt !== this.props.refreshAt) {
      this.fetchAll()
    } else if (prevProps.projectId !== this.props.projectId) {
      this.fetchAll()
    } else if (prevProps.organizationId !== this.props.organizationId) {
      this.fetchAll()
    } else if (JSON.stringify(prevProps.filters) !== JSON.stringify(this.props.filters)) {
      this.fetchAll()
    }
  }

  fetchAll() {

    if (!this.state.loading) {
      this.setState({loading: true})
    }
    const opts: any = {
      measures: [this.props.measure],
      dimensions: this.props.dimensions || [],
      timeDimensions: [
        {
          dimension: this.props.timeDimension,
          granularity: null,
          compareDateRange: [
            [this.props.dateFrom,this.props.dateTo],
            [this.props.dateFromPrevious,this.props.dateToPrevious]
          ]
        }
      ],
      timezone: this.props.timezone
    }

    const optsPerDay: any = {
      measures: [this.props.measure],
      dimensions: this.props.dimensions || [],
      timeDimensions: [
        {
          dimension: this.props.timeDimension,
          granularity: 'day',
          compareDateRange: [
            [this.props.dateFrom,this.props.dateTo],
            [this.props.dateFromPrevious,this.props.dateToPrevious]
          ]
        }
      ],
      timezone: this.props.timezone
    }

    if (this.props.segments) {
      opts.segments = this.props.segments
      optsPerDay.segments = this.props.segments
    }

    if (this.props.filters) {
      opts.filters = this.props.filters
      optsPerDay.filters = this.props.filters
    }

    // this.props.cubejsApi.load(optsPerDay).then((resultSet: any) => {
    //   console.log('got', resultSet.series());
    // })


    Promise.all([this.props.cubejsApi.load(opts), this.props.cubejsApi.load(optsPerDay)]).then((values: any) => {
      // console.log('values', values);

      const [currentTotal, previousTotal] = values[0].decompose()
      const [currentDays, previousDays] = values[1].decompose()

      // if (this.props.measure === 'TimelineSessions.conversionRate') {
      //   console.log('currentTotal', currentTotal);
      //   console.log('currentDays', currentDays);
      // }
      // console.log('totals', currentTotal.series());
      // console.log('currentDays', currentDays.series());

      this.setState({
        loading: false,
        total: currentTotal.series()[0]?.series[0].value || 0,
        totalPrevious: previousTotal.series()[0]?.series[0].value || 0,
        data: currentDays.series()[0]?.series || [],
        dataPrevious: previousDays.series()[0]?.series || [],
      })
    })
    .catch((error: any) => {

      console.log(error)

      let msg = error.message

      if (error.response && error.response.status === 400) {
        switch(error.response.data.message) {
          default: 
          msg = error.response.data.message
        }
      }

      message.error(msg)
      this.setState({loading: false})
    })
  }

  render() {

    // console.log('state kpi', this.state);

    let value: any = 0
    let graphData: any = []

    if (this.state.loading === false) {

      switch (this.props.kind) {
        case 'currency': value = Formatters.currency(this.props.currency, this.state.total); break
        case 'percentage': value = Formatters.percentage(this.state.total); break
        case 'duration': value = Formatters.duration(this.state.total); break
        case 'ratio': value = Formatters.ratio(this.state.total); break
        case 'float': value = Formatters.float(this.state.total); break
        default: value = N(this.state.total).format('0,0[.]00')
      }
      // console.log('value', value);


      this.state.data.forEach((item: any, index: number) => {

        graphData.push({
          date: item.x,
          value: item.value,
          kind: this.props.measure+'_current'
        })
        graphData.push({
          date: item.x,
          value: this.state.dataPrevious[index]?.value || 0,
          kind: this.props.measure+'_previous'
        })
      })

      // console.log('graphData is', graphData);
    }


    return <div className={cn('dash-kpi', {'border-right-1': this.props.isLast !== true}, {'active': this.props.activeColumn === this.props.column})}>
    <div className="widget-title size-13">{this.props.tooltip ? <Tooltip title={this.props.tooltip}>{this.props.title}</Tooltip> : this.props.title}</div>
    <div className="dash-kpi-value">{this.state.loading ? <Spin /> : value}</div>
    <div className="dash-kpi-graph">
      <Chart
        height={30}
        data={graphData}
        padding={[0,0,0,0]}
        scale={{
          date: {
            type: 'time',
            mask: 'yyyy-mm-dd',
            min: this.props.fromDate,
            max: this.props.toDate
          },
          value: {
            alias: 'Count'
          }
        }}
        animate
        autoFit
      >
        <ChartTooltip visible={false} />
        <Legend visible={false} />
        <Axis name="date" title={null} label={null} line={{
          style: {
            lineWidth: 1,
            stroke: '#CFD8DC',
          }
        }} />
        <Axis name="value" visible={false} />
        <Geom
          type="line"
          position="date*value"
          color={['kind', [Colors[this.props.color], Colors[this.props.color+'Light']]]}
          size={1}
        />
      </Chart>
    </div>
    <div className="dash-kpi-variation">{this.state.loading === true ? '-' : Formatters.growthRate(this.state.total, this.state.totalPrevious, this.props.reverseGrowthRate)}</div>
  </div>
  }
}

export default KPI