import React, { useState } from 'react'

import {
    Button,
    Modal,
    Badge,
    Popconfirm,
    Input,
    Select,
    Form,
    DatePicker,
} from 'antd';
import t from '../../utils/translate'
import _ from 'lodash'
import logoGamned from '../../images/integrations/gamned.png'
import Moment from 'moment-timezone'

const IntegrationsGamned = ({ integration, disconnect, projectLayout, organizationLayout, app }: any) => {
    const [loading, setLoading] = useState(false)

    const connectGamned = (values: any) => {
        setLoading(true)

        app.ajaxRequest({
            method: 'post',
            url: '/integrations.connect',
            data: {
                projectId: projectLayout.project.id,
                integrationId: integration.id,
                gamned: values
            }
        }, (errorMessage: any, response: any) => {

            if (errorMessage) {
                app.addMessage('error', errorMessage)
                setLoading(false)
                return
            }

            let projects = _.filter(organizationLayout.projects, (p: any) => p.id !== response.data.project.id)
            projects.push(response.data.project)
            organizationLayout.setProjects(projects)

            app.addMessage('success', t('gamned_connect_success', "Gamned has successfully been connected!"))
            setLoading(false)
        })
    }


    // console.log('integration', integration)

    return <div>
        <h2 className="margin-t-m">{t('settings', "Settings")}</h2>
        {(integration.status === 'setup') || (integration.status === 'disconnected') ?
            <div style={{ width: '500px', margin: '32px auto' }}>
                <div className="paper padding-h-xl padding-b-xl text-center">
                    <img src={logoGamned} alt="" style={{ height: '200px' }} />
                    <Form initialValues={{channelSource: 'gamned', channelMedium: 'postview', postviewAtribution: 'first'}} layout="vertical" onFinish={connectGamned}>
                    <Form.Item label="Data bucket name" name="bucketName" rules={[{ required: true, type: 'string' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Channel source" name="channelSource" rules={[{ required: true, type: 'string' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Channel medium" name="channelMedium" rules={[{ required: true, type: 'string' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Postview attribution" name="postviewAtribution" rules={[{ required: true, type: 'string' }]}>
                            <Select>
                                <Select.Option value="first">First postview of the customer journey</Select.Option>
                                <Select.Option value="last">Last postview of the customer journey</Select.Option>
                                <Select.Option value="all">All postviews of the customer journey</Select.Option>
                                <Select.Option value="no">Don't attribute postviews</Select.Option>
                            </Select>
                        </Form.Item>
                        {/* <Form.Item label="Impression domain" name="domainId" rules={[{ required: true, type: 'string' }]}>
                            <Select options={projectLayout.project.domains.map(dom => {
                                return {
                                    label: dom.name,
                                    value: dom.id,
                                    key: dom.id,
                                }
                            })} />
                        </Form.Item> */}
                        <Button size="large" type="primary" htmlType="submit" loading={loading}>{t('connect', "Connect")}</Button>
                    </Form>
                </div>
            </div>
            :
            <div className="margin-a-m">
                <table className="key-value-table">
                    <tbody>
                        <tr>
                            <th style={{ width: '300px' }}>{t('integration_id', "Integration ID")}</th>
                            <td>{integration.id}</td>
                        </tr>
                        <tr>
                            <th>Bucket name</th>
                            <td>{integration.gamnedSettings.bucketName}</td>
                        </tr>
                        <tr>
                            <th>Impressions Source / Medium</th>
                            <td>{integration.gamnedSettings.channelSource} / {integration.gamnedSettings.channelMedium}</td>
                        </tr>
                        <tr>
                            <th>Postview attribution</th>
                            <td>{integration.gamnedSettings.postviewAtribution}</td>
                        </tr>
                        <tr>
                            <th>{t('status', "Status")}</th>
                            <td>
                                {integration.status === 'connected' && <span>
                                    <Badge status="success" /> {t('connected', "Connected")}
                                    <Popconfirm placement="top" title={t('disconnect_gamned_confirm', "Do you really want to disconnect Gamned?")} onConfirm={disconnect.bind(null, integration)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                                        <Button className="margin-l-m" loading={loading} ghost size="small" danger={true}>{t('disconnect', "Disconnect")}</Button>
                                    </Popconfirm>
                                </span>}
                            </td>
                        </tr>
                        {integration.gamnedSettings.lastDateProcessed && <tr>
                            <th>Last date processed</th>
                            <td>{integration.gamnedSettings.lastDateProcessed}</td>
                        </tr>}
                        <tr>
                            <th>{t('actions', "Actions")}</th>
                            <td>
                                <SyncModal app={app} projectLayout={projectLayout} integration={integration} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        }
    </div>
}

const SyncModal = ({ app, projectLayout, integration }: any) => {
    const [loading, setLoading] = useState(false)
    // const [form] = Form.useForm()
    const [modalVisible, setModalVisible] = useState(false)
    const [syncFrom, setSyncFrom] = useState(Moment.tz('2021-07-01', 'UTC'))

    return <>
        <Button loading={loading} onClick={() => setModalVisible(true)}>Resync</Button>

        <Modal
            title="Resync"
            okText="Start"
            visible={modalVisible}
            onCancel={() => setModalVisible(false)}
            onOk={() => {
                if (loading) return

                setLoading(true)

                app.ajaxRequest({
                    method: 'post',
                    url: '/integrations.syncGamned',
                    data: {
                        projectId: projectLayout.project.id,
                        integrationId: integration.id,
                        fromDate: syncFrom.toISOString(),
                    }
                }, (errorMessage: any, _response: any) => {

                    if (errorMessage) {
                        app.addMessage('error', errorMessage)
                        setLoading(false)
                        return
                    }

                    app.addMessage('success', t('sync_launched', 'The sync has been launched!'))
                    setLoading(false)
                    setModalVisible(false)
                })
            }}
        >
            <p>From date:</p>
            <div className="margin-b-m">
                <DatePicker value={syncFrom} allowClear={false} autoFocus={true} onChange={(date: any) => setSyncFrom(date)} /> UTC
            </div>
        </Modal>
    </>
}

export default IntegrationsGamned