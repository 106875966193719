import React, { useEffect, useState } from 'react'

import t from '../../utils/translate'
import { Spin, Alert, Button, Select, Row, Col, message } from 'antd'
import LoginForm from './_loginForm'
import Config from '../../config'
import Axios from 'axios'
import QS from 'qs'
import _ from 'lodash'
import CreateButton from '../organizations/_createModal'
import { useAppContext, AppContextValue } from '../app'
import Splash from './_splash'
import { Link, useLocation } from 'react-router-dom'

const AdminInvitation = () => {

  const appCtx: AppContextValue = useAppContext()
  const [loading, setLoading] = useState(true)
  const [invitation, setInvitation] = useState<any>(undefined)
  const [isTokenValid, setIsTokenValid] = useState<boolean | undefined>(undefined)
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<string | undefined>(undefined)
  const [ajaxLoading, setAjaxLoading] = useState(false)
  const location = useLocation() as any
  const params: any = QS.parse(location.search, { ignoreQueryPrefix: true })

  useEffect(() => {

    Axios({
      method: 'get',
      url: Config().api_endpoint + '/organizations.checkInvitation',
      headers: { Authorization: 'Bearer ' + params.token },
    }).then((response: any) => {
      setInvitation(response.data.invitation)
      setIsTokenValid((response.data.invitation.consumedAt) ? false : true)
      setLoading(false)
    })
      .catch((error: any) => {

        let msg = error.message

        if (error.response && error.response.status === 400) {
          switch (error.response.data.message) {
            default:
              msg = error.response.data.message
              break
          }
        }

        message.error(msg)
        setLoading(false)
        setIsTokenValid(false)
      })

    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Invitation',
        page: '/invitation' // this.props.location.pathname
      })
      window.cmAgent.dispatch()
    }

  }, [location.search, params.token])

  const selectOrganization = (value: string | undefined) => {
    setSelectedOrganizationId(value)
  }

  const consumeInvitation = () => {
    if (ajaxLoading === true) return

    setAjaxLoading(true)

    appCtx.ajaxRequest({
      method: 'post',
      url: '/organizations.consumeInvitation',
      data: {
        token: params.token,
        mediaPartnerOrganizationId: selectedOrganizationId
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        if (errorMessage === 'user.alreadyExists') {
          message.error('You are already member of this organization.')
        }
        else {
          message.error('Error: ' + errorMessage)
        }

        setAjaxLoading(false)
        return
      }

      // console.log('dash', response.data)

      message.success('Your invitation has been accepted!')

      if (window.cmAgent) {
        if (invitation.asMediaPartner) {
          window.cmAgent.addUserToCompany(selectedOrganizationId, { role: 'admin' })
        } else {
          window.cmAgent.addUserToCompany(invitation.organizationId, { role: 'admin' })
        }

        window.cmAgent.event({
          label: 'consumeInvitation',
          props: {
            userId: appCtx.admin.id,
            email: appCtx.admin.primaryEmail,
          }
        })
        window.cmAgent.dispatch()
      }

      // refresh token with new claim
      appCtx.firebaseUser.getIdToken(true).then(() => {
        // use a window reload to make sure to retrigger the users.login at init
        // and get organization in app state
        if (invitation.asMediaPartner) {
          window.location.href = window.location.origin + '/organizations/' + selectedOrganizationId
        } else {
          window.location.href = window.location.origin + '/organizations/' + invitation.organizationId
          // this.props.history.push()
        }
      })
    })
  }

  // console.log('props', this.props)
  const mediaPartnerOrganizations = _.filter(appCtx.organizations, (org) => org.kind === 'mediaPartner');

  return <Splash h1={t('welcome', "Welcome")}>

    <Spin spinning={ajaxLoading}>

      {loading ?
        <div className="margin-a-l">
          <Spin spinning={true} />
        </div>
        : <>

          {isTokenValid === false &&
            <Alert type="error" message="Your invitation token has already been consumed or is not valid!" />
          }

          {isTokenValid === true && !invitation.asMediaPartner && <div>
            <Alert type="info" className="margin-b-m" message={t('x_invited_you_to_join_its_organization', "{x} invited you to join it's organization!", { x: invitation.organizationName })} />

            {(appCtx.admin) ?
              <div>
                <Button size="large" type="primary" onClick={consumeInvitation}>{t('accept_invitation_and_join_x', "Accept invitation and join {organization}", { organization: invitation.organizationName })}</Button>

                <div className="margin-t-m">
                  {t('or', "or")} <Link to="/sign-out" className="link">{t('sign_out', "sign out")}</Link>
                </div>
              </div>
              :
              <LoginForm />
            }
          </div>}

          {isTokenValid === true && invitation.asMediaPartner && <div>
            <Alert type="info" className="margin-b-m" message={t('advertiser_media_partner_invitation', 'The advertiser "{name}" invited you to join his network "{project}" as a Media Partner.', { "name": invitation.organizationName, "project": invitation.projectName })} />

            {(appCtx.admin) ?

              <div>
                {(mediaPartnerOrganizations.length > 0) ?
                  <div>
                    <p>{t('choose_media_partner_organization', 'Please choose which of your organizations will become Media Partner of "{name}"', { "name": invitation.organizationName })}:</p>

                    <Row gutter={12}>
                      <Col span={11}>
                        <Select
                          onChange={selectOrganization}
                          value={selectedOrganizationId}
                          style={{ width: '100%' }}
                          placeholder={t('select_an_organization', "Select an organization")}
                        >
                          {mediaPartnerOrganizations.map((org) => {
                            return <Select.Option key={org.id} value={org.id}>{org.name}</Select.Option>
                          })}
                        </Select>
                      </Col>
                      <Col span={1} className="text-center padding-t-xs">{t('or', "or")}</Col>
                      <Col span={10}>
                        <CreateButton app={appCtx}
                          // history={this.props.history}
                          btnSize="normal"
                          btnType={(mediaPartnerOrganizations.length > 0) ? 'default' : 'primary'}
                          btnContent={t('create_a_media_partner_organization', "Create a Media Partner organization")}
                          onComplete={(organization: any) => setSelectedOrganizationId(organization.id)}
                          kind="mediaPartner"
                        />
                      </Col>
                    </Row>

                    {selectedOrganizationId && <div className="margin-t-l">
                      <Button size="large" type="primary" onClick={consumeInvitation}>{t('continue', "Continue")}</Button>
                    </div>
                    }
                  </div>
                  :
                  <div>
                    <p>{t('create_media_partner_organization_required', "Because your account doesn't belong to any Media Partners organization yet, you need to create one.")}</p>
                    <div className="margin-b-l margin-t-m">
                      <CreateButton app={appCtx}
                        // history={this.props.history}
                        btnSize="normal"
                        btnType={(mediaPartnerOrganizations.length > 0) ? 'default' : 'primary'}
                        btnContent={t('create_a_media_partner_organization', "Create a Media Partner organization")}
                        onComplete={(organization: any) => setSelectedOrganizationId(organization.id)}
                        kind="mediaPartner"
                      />
                    </div>
                  </div>
                }
              </div>
              :
              <LoginForm />
            }
          </div>}
        </>
      }
    </Spin>
  </Splash>
}

// AdminInvitation.contextTypes = {
//   router: React.PropTypes.object.isRequired
// }

export default AdminInvitation