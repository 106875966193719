import { useState, useEffect } from 'react'

import t from '../../utils/translate'
import { CaretUpFilled, CaretDownFilled, DeleteOutlined, EditFilled } from '@ant-design/icons';
import {
  Button, Input, Radio,
  Select, Col,
  Table,
  Tag, Divider, Form,
  Modal,
  message,
  Popconfirm,
} from 'antd';
import { kebabCase } from 'lodash'

const ProjectConversionRuleForm = (props: any) => {

  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const onFinish = (values: any) => {

    // console.log('values', values);

    if (loading === true) {
      return
    }
    else {
      setLoading(true)
    }

    values.projectId = props.projectLayout.project.id

    if (props.edit === true) {
      values.conversionRuleId = props.conversionRule.id
    }

    props.app.ajaxRequest({
      method: 'post',
      url: props.edit ? '/conversionRules.update' : '/conversionRules.create',
      data: values
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        props.app.addMessage('error', errorMessage)
        setLoading(false)
        return
      }

      let projects = props.organizationLayout.projects.filter((p: any) => p.id !== response.data.project.id)
      projects.push(response.data.project)
      props.organizationLayout.setProjects(projects)

      if (props.edit === true) {
        message.success(t('conversion_rule_updated', "Your conversion rule has successfully been updated!"))
      } else {
        message.success(t('conversion_rule_created', "Your conversion rule has successfully been created!"))
        form.resetFields()
      }

      if (window.cmAgent) {
        window.cmAgent.event({
          label: props.edit ? 'createConversionRule' : 'updateConversionRule',
          props: {
            orgId: props.projectLayout.project.organizationId,
            projectId: props.projectLayout.project.id
          }
        })
        window.cmAgent.dispatch()
      }

      if (props.onComplete) {
        props.onComplete()
      }
    })
  }

  // console.log('state', this.state);

  // const crossConversionRules = props.projectLayout.project.conversionRules.filter((r) => !r.deletedAt && r.id !== _.get(props, 'conversionRule.id'))

  let initialValues = {
    kind: 'order',
    forCompanies: false,
    leadStages: [
      { id: 'new', label: 'New', status: 'open', color: 'cyan' },
      { id: 'converted', label: 'Converted', status: 'converted', color: 'green' },
      { id: 'lost', label: 'Lost', status: 'lost', color: 'grey' },
    ],
    // 'acquisitionXCR.isEnabled': false,
    // 'acquisitionXCR.kind': 'first',
    // 'retentionXCR.isEnabled': false,
    // 'retentionXCR.kind': 'first',
  }

  if (props.conversionRule) {
    initialValues = { ...props.conversionRule }
  }

  return <div>
    <Form form={form} initialValues={initialValues} labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} layout="horizontal" onFinish={onFinish} className="padding-b-l">

      <Form.Item name="kind" label={t('kind', "Kind")} rules={[{ required: true, message: t('field_required', "This field is required!") }]}>
        <Radio.Group style={{ width: '100%' }} disabled={props.edit === true}>
          <Radio.Button style={{ width: '33.33%', textAlign: 'center' }} value="order">Orders</Radio.Button>
          <Radio.Button style={{ width: '33.33%', textAlign: 'center' }} value="lead">Leads</Radio.Button>
          <Radio.Button disabled style={{ width: '33.33%', textAlign: 'center' }} value="subscription">{t('subscription', "Subscription")}</Radio.Button>
        </Radio.Group>
      </Form.Item>

      <Form.Item name="forCompanies" label={t('record_conversion_for', "Record conversions for")} rules={[{ required: true, type: 'boolean', message: t('field_required', "This field is required!") }]}>
        <Radio.Group style={{ width: '100%' }} disabled={props.edit === true}>
          <Radio.Button style={{ width: '50%', textAlign: 'center' }} value={false}>{t('users_b2c', "Users B2C")}</Radio.Button>
          <Radio.Button style={{ width: '50%', textAlign: 'center' }} value={true}>{t('companies_b2b', "Companies B2B")}</Radio.Button>
        </Radio.Group>
      </Form.Item>

      <Form.Item name="name" label={t('conversion_rule_name', "Name of your conversion rule")} rules={[{ required: true, message: t('field_required', "This field is required!") }]}>
        <Input
          placeholder="i.e: sales / leads / subscriptions"
          onChange={(e: any) => {
            if (props.edit !== true) {
              form.setFieldsValue({ id: kebabCase(e.target.value) })
            }
          }}
        />
      </Form.Item>

      <Form.Item name="id" label={t('conversion_rule_id', "ID of your conversion rule")} extra={t('used_to_configure_the_agent', "Used to configure the agent, written-like-this")} rules={[{ required: true, type: 'string', pattern: /^[a-z0-9]+(-[a-z0-9]+)*$/, message: t('value_should_be_written_like_this', "The value should-be-written-like-this") }]}>
        <Input placeholder="" disabled={props.edit === true} />
      </Form.Item>

      <Form.Item noStyle shouldUpdate>
        {(funcs) => {
          if (funcs.getFieldValue('kind') === 'lead') {
            return <Form.Item
              name="leadStages"
              label="Stages"
              rules={[{ required: true, type: 'array', min: 2 }]}
              shouldUpdate
            // noStyle
            >
              <LeadsStageForm />
            </Form.Item>
          }
        }}
      </Form.Item>

      {/* <Form.Item noStyle shouldUpdate>
        {(funcs) => {
          return <legend>{funcs.getFieldValue('kind') === 'order' ? t('acquisition_xcr_attribution', "Attribution for acquisition cross-conversion rule") : t('xcr', "Cross-conversion rule")}</legend>
        }}
      </Form.Item>


      <Form.Item valuePropName="checked" name="acquisitionXCR.isEnabled" label={t('enable_cross_conversion_rules', "Enable cross conversion rules")} extra={(crossConversionRules.length === 0) ? t('cross_conversion_rules_disabled_info', "You need to create at least two conversion rules to activate this option.") : ''} rules={[{ type: 'boolean', required: false, message: t('field_required', "This field is required!") }]}>
        <Switch disabled={crossConversionRules.length === 0} />
      </Form.Item>

      <Form.Item noStyle shouldUpdate>
        {(funcs) => {
          if (funcs.getFieldValue('acquisitionXCR.isEnabled') === true) {
            return <Form.Item name="acquisitionXCR.kind" label={t('attribute_credits_to', "Attribute credits to")} rules={[{ required: true, message: t('field_required', "This field is required!") }]}>
              <Radio.Group style={{ width: '100%' }}>
                <Radio style={{ display: 'block', height: '32px', lineHeight: '32px' }} value="first">{t('the_first_conversion_rule_ever', "The first conversion rule ever")}</Radio>
                <Radio style={{ display: 'block', height: '32px', lineHeight: '32px' }} value="last">{t('the_most_recent_conversion_rule', "The most recent conversion rule")}</Radio>
              </Radio.Group>
            </Form.Item>
          }
        }}
      </Form.Item>

      <Form.Item noStyle shouldUpdate>
        {(funcs) => {
          if (funcs.getFieldValue('kind') === 'order') {
            return <div>
              <legend>{t('retention_xcr_attribution', "Retention cross-conversion rule")}</legend>

              <Form.Item valuePropName="checked" name="retentionXCR.isEnabled" label={t('enable_cross_conversion_rules', "Enable cross conversion rules")} extra={(crossConversionRules.length === 0) ? t('cross_conversion_rules_disabled_info', "You need to create at least two conversion rules to activate this option.") : ''} rules={[{ type: 'boolean', required: false, message: t('field_required', "This field is required!") }]}>
                <Switch disabled={crossConversionRules.length === 0} />
              </Form.Item>

              {funcs.getFieldValue('retentionXCR.isEnabled') === true && <Form.Item name="retentionXCR.kind" label={t('attribute_credits_to', "Attribute credits to")} rules={[{ required: true, message: t('field_required', "This field is required!") }]}>
                <Radio.Group style={{ width: '100%' }}>
                  <Radio style={{ display: 'block', height: '32px', lineHeight: '32px' }} value="first">{t('the_first_conversion_rule_ever', "The first conversion rule ever")}</Radio>
                  <Radio style={{ display: 'block', height: '32px', lineHeight: '32px' }} value="last">{t('the_most_recent_conversion_rule', "The most recent conversion rule")}</Radio>
                </Radio.Group>
              </Form.Item>}
            </div>
          }
        }}
      </Form.Item> */}

      {props.isDrawer === true ?
        <div className="ant-drawer-footer">
          <Button style={{ marginRight: 8 }} onClick={props.toggleDrawer}>{t('cancel', "Cancel")}</Button>
          <Button type="primary" loading={loading} htmlType="submit">{t('confirm', "Confirm")}</Button>
        </div>
        :
        <Form.Item noStyle>
          <Col xs={22} sm={22} className="text-right">
            <Button type="primary" loading={loading} htmlType="submit">{t('confirm', "Confirm")}</Button>
          </Col>
        </Form.Item>
      }
    </Form>
  </div>
}

export default ProjectConversionRuleForm

const LeadsStageForm = ({ value, onChange }: any) => {

  const [stages, setStages] = useState(value)

  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(stages)) {
      setStages(value)
    }
  }, [stages, value])

  // ne fonctionne pas pour move down
  const moveStage = (stage, fromIndex, toIndex) => {
    const updatedStages = [...stages]

    if (toIndex >= updatedStages.length) {
      var k = toIndex - updatedStages.length + 1
      while (k--) {
        updatedStages.push(undefined)
      }
    }
    updatedStages.splice(toIndex, 0, updatedStages.splice(fromIndex, 1)[0])

    setStages(updatedStages)
    onChange(updatedStages)
  }

  const addStage = (stage: any) => {
    if (stages.find(x => x.id === stage.id)) {
      message.error('This stage ID already exists')
      return
    }

    const updatedStages = [...stages, stage]

    setStages(updatedStages)
    onChange(updatedStages)
  }

  const updateStage = (stage: any) => {
    const stageIndex = stages.findIndex(x => x.id === stage.id)
    const updatedStages = [...stages]
    updatedStages[stageIndex] = stage

    setStages(updatedStages)
    onChange(updatedStages)
  }

  // const generateStageId = (stages: any, id: string, inc: number): string => {
  //   if (stages.find((s: any) => s.id === id)) {
  //     return generateStageId(stages, id + inc, inc + 1)
  //   }
  //   return id
  // }

  const deleteUnsavedStage = (stage: any) => {
    let updatedStages = stages.filter((s: any) => stage.id !== s.id)

    setStages(updatedStages)
    onChange(updatedStages)
  }

  const activeStages = stages.filter(s => !s.deletedAt)
  const deletedStages = stages.filter(x => x.deletedAt)

  // console.log('deletedStages', deletedStages)
  return <>
    <Table
      size="middle"
      bordered={false}
      pagination={false}
      rowKey="id"
      // showHeader={false}
      className="margin-b-m"
      columns={[
        {
          title: 'ID',
          key: 'id',
          render: x => x.id
        },
        {
          title: 'Label',
          key: 'label',
          render: x => <Tag color={x.color !== 'grey' ? x.color : undefined}>{x.label}</Tag>
        },
        {
          title: 'Status',
          key: 'status',
          render: x => x.status
        },
        {
          title: '',
          key: 'remove',
          render: (x, _record, i) => {
            return (
              <div className="text-right">
                <Button.Group className="margin-r-m">
                  {i !== 0 && <Button type="dashed" size="small" onClick={moveStage.bind(null, x, i, i - 1)}><CaretUpFilled /></Button>}
                  {i !== (activeStages.length - 1) && <Button type="dashed" size="small" onClick={moveStage.bind(null, x, i, i + 1)}><CaretDownFilled /></Button>}
                </Button.Group>

                <Button.Group>
                  <AddOrUpdateStageModal onComplete={updateStage} stage={x}>
                    <Button type="dashed" size="small"><EditFilled /></Button>
                  </AddOrUpdateStageModal>

                  {!x.createdAt && <Popconfirm onConfirm={deleteUnsavedStage.bind(null, x)} title="Do you want to remove this stage?">
                    <Button type="dashed" size="small"><DeleteOutlined /></Button>
                  </Popconfirm>}

                  {x.createdAt && <DeleteSavedStage stage={x} stages={stages} onComplete={updateStage}>
                    <Button type="dashed" size="small"><DeleteOutlined /></Button>
                  </DeleteSavedStage>}
                </Button.Group>
              </div>
            );
          }
        }]}
      dataSource={activeStages} />

    <AddOrUpdateStageModal onComplete={addStage}><Button type="primary" size="small" block ghost>Add a stage</Button></AddOrUpdateStageModal>

    {deletedStages.length > 0 && <>
    <Divider orientation="left" plain>Deleted stages</Divider>

      <Table
        size="middle"
        bordered={false}
        pagination={false}
        rowKey="id"
        className="margin-b-m"
        columns={[
          {
            title: 'ID',
            key: 'id',
            render: x => x.id
          },
          {
            title: 'Label',
            key: 'label',
            render: x => <Tag color={x.color !== 'grey' ? x.color : undefined}>{x.label}</Tag>
          },
          {
            title: 'Migrated to stage',
            key: 'status',
            render: x => x.migrateToId
          },
          // {
          //   title: '',
          //   key: 'remove',
          //   render: x => <div className="text-right">
          //     <Popconfirm onConfirm={deleteUnsavedStage.bind(null, x)} title="Do you want to remove this stage?">
          //       <Button type="dashed" size="small"><DeleteOutlined /></Button>
          //     </Popconfirm>
          //   </div>
          // }
        ]}
        dataSource={deletedStages} />
    </>}
  </>
}


const AddOrUpdateStageModal = ({ stage, onComplete, children }: any) => {
  const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)

  const onClicked = () => {
    if (stage) {
      form.setFieldsValue({
        label: stage.label,
        id: stage.id,
        status: stage.status,
        color: stage.color,
      })
    }
    setModalVisible(true)
  }

  return <>
    <span onClick={onClicked}>{children}</span>

    {modalVisible && <Modal
      visible={modalVisible}
      title={stage ? 'Update stage' : 'Add a stage'}
      okText={t('confirm', "Confirm")}
      cancelText={t('cancel', "Cancel")}
      onCancel={() => { setModalVisible(false) }}
      width={700}
      onOk={() => {
        form.validateFields().then((values: any) => {
          // console.log('onComplete', values);
          form.resetFields()
          setModalVisible(false)
          if (stage) {
            stage.label = values.label
            stage.color = values.color
            onComplete(stage)
          } else {
            onComplete(values)
          }
        })
          .catch(info => { console.log('Validate Failed:', info); });
      }}
    >
      <Form form={form} name="form_add_stage" labelCol={{ span: 10 }} wrapperCol={{ span: 14 }} layout="horizontal">

        <Form.Item name="label" label="Label" rules={[{ required: true, message: t('field_required', "This field is required!") }]}>
          <Input placeholder="i.e: New, Lost, Won, Signed..."
            onChange={(e: any) => {
              if (!stage) form.setFieldsValue({ id: kebabCase(e.target.value) })
            }} />
        </Form.Item>

        <Form.Item name="id" label="Stage ID" rules={[{ required: true, type: 'string', pattern: /^[a-z0-9]+(-[a-z0-9]+)*$/, message: t('value_should_be_written_like_this', "The value should-be-written-like-this") }]}>
          <Input placeholder="i.e: web" disabled={stage && stage.createdAt} />
        </Form.Item>

        <Form.Item name="status" label="Consider this stage as" rules={[{ required: true, message: "This field is required" }]}>
          <Radio.Group style={{ width: '100%' }} disabled={stage && stage.createdAt}>
            <Radio.Button style={{ width: '33.33%', textAlign: 'center' }} value="open">Open</Radio.Button>
            <Radio.Button style={{ width: '33.33%', textAlign: 'center' }} value="converted">Converted</Radio.Button>
            <Radio.Button style={{ width: '33.33%', textAlign: 'center' }} value="lost">Lost</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item name="color" label="Color" rules={[{ required: true, message: t('field_required', "This field is required!") }]}>
          <Select>
            <Select.Option value="magenta"><Tag color="magenta">magenta</Tag></Select.Option>
            <Select.Option value="red"><Tag color="red">red</Tag></Select.Option>
            <Select.Option value="volcano"><Tag color="volcano">volcano</Tag></Select.Option>
            <Select.Option value="orange"><Tag color="orange">orange</Tag></Select.Option>
            <Select.Option value="gold"><Tag color="gold">gold</Tag></Select.Option>
            <Select.Option value="lime"><Tag color="lime">lime</Tag></Select.Option>
            <Select.Option value="green"><Tag color="green">green</Tag></Select.Option>
            <Select.Option value="cyan"><Tag color="cyan">cyan</Tag></Select.Option>
            <Select.Option value="blue"><Tag color="blue">blue</Tag></Select.Option>
            <Select.Option value="geekblue"><Tag color="geekblue">geekblue</Tag></Select.Option>
            <Select.Option value="purple"><Tag color="purple">purple</Tag></Select.Option>
            <Select.Option value="grey"><Tag>grey</Tag></Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>}
  </>
}


const DeleteSavedStage = ({ stage, stages, onComplete, children }: any) => {
  const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)

  const onClicked = () => {
    if (stage) {
      form.setFieldsValue({
        label: stage.label,
        id: stage.id,
        status: stage.status,
        color: stage.color,
        migrateToId: stage.migrateToId,
      })
    }
    setModalVisible(true)
  }

  return <>
    <span onClick={onClicked}>{children}</span>

    {modalVisible && <Modal
      visible={modalVisible}
      title="Delete stage"
      okText={t('confirm', "Confirm")}
      cancelText={t('cancel', "Cancel")}
      onCancel={() => { setModalVisible(false) }}
      width={700}
      onOk={() => {
        form.validateFields().then((values: any) => {
          // console.log('onComplete', values);
          form.resetFields()
          setModalVisible(false)
          stage.migrateToId = values.migrateToId

          if (!stage.deletedAt) {
            stage.deletedAt = new Date().toISOString()
          }
          onComplete(stage)
        })
          .catch(info => { console.log('Validate Failed:', info); });
      }}
    >
      <Form form={form} name="form_remove_stage" labelCol={{ span: 10 }} wrapperCol={{ span: 14 }} layout="horizontal">

        <Form.Item name="label" label="Label" rules={[{ required: true, message: t('field_required', "This field is required!") }]}>
          <Input placeholder="i.e: New, Lost, Won, Signed..."
            onChange={(e: any) => {
              form.setFieldsValue({ id: kebabCase(e.target.value) })
            }} />
        </Form.Item>

        <Form.Item name="id" label="Stage ID" rules={[{ required: true, type: 'string', pattern: /^[a-z0-9]+(-[a-z0-9]+)*$/, message: t('value_should_be_written_like_this', "The value should-be-written-like-this") }]}>
          <Input placeholder="i.e: web" disabled />
        </Form.Item>

        <Form.Item name="migrateToId" label="Migrate leads to stage" rules={[{ required: true, message: t('field_required', "This field is required!") }]}>
          <Select>
            {stages.filter((s: any) => !s.deletedAt && s.id !== stage.id).map((s: any) => <Select.Option value={s.id} key={s.id}><Tag color={(s.color !== 'grey') ? s.color : undefined}>{s.label}</Tag></Select.Option>)}
          </Select>
        </Form.Item>

        <Form.Item name="status" label="Consider this stage as" rules={[{ required: true, message: "This field is required" }]}>
          <Radio.Group style={{ width: '100%' }} disabled={stage && stage.createdAt}>
            <Radio.Button style={{ width: '33.33%', textAlign: 'center' }} value="open">Open</Radio.Button>
            <Radio.Button style={{ width: '33.33%', textAlign: 'center' }} value="converted">Converted</Radio.Button>
            <Radio.Button style={{ width: '33.33%', textAlign: 'center' }} value="lost">Lost</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item name="color" label="Color" rules={[{ required: true, message: t('field_required', "This field is required!") }]}>
          <Select>
            <Select.Option value="magenta"><Tag color="magenta">magenta</Tag></Select.Option>
            <Select.Option value="red"><Tag color="red">red</Tag></Select.Option>
            <Select.Option value="volcano"><Tag color="volcano">volcano</Tag></Select.Option>
            <Select.Option value="orange"><Tag color="orange">orange</Tag></Select.Option>
            <Select.Option value="gold"><Tag color="gold">gold</Tag></Select.Option>
            <Select.Option value="lime"><Tag color="lime">lime</Tag></Select.Option>
            <Select.Option value="green"><Tag color="green">green</Tag></Select.Option>
            <Select.Option value="cyan"><Tag color="cyan">cyan</Tag></Select.Option>
            <Select.Option value="blue"><Tag color="blue">blue</Tag></Select.Option>
            <Select.Option value="geekblue"><Tag color="geekblue">geekblue</Tag></Select.Option>
            <Select.Option value="purple"><Tag color="purple">purple</Tag></Select.Option>
            <Select.Option value="grey"><Tag>grey</Tag></Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>}
  </>
}
