import { useState } from 'react'

import t from '../../utils/translate'
import {
  Button,
  Modal,
  message,
} from 'antd'

const ReattributeConversionsButton = ({ ajaxRequest, projectId, organizationId, toggleModal, onComplete }) => {

  const [loading, setLoading] = useState(false)

  const handleSubmit = () => {
    if (loading === true) {
      return
    }

    setLoading(true)

    ajaxRequest({
      method: 'post',
      url: '/tasks.create',
      data: {
        projectId: projectId,
        kind: "reattributeUserConversions",
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        message.error(errorMessage)
        setLoading(false)
        return
      }

      message.success(t('reattribute_conversions_success', 'Your conversions are being re-attributed.'))


      if (window.cmAgent) {
        window.cmAgent.event({
          label: 'createTaskManually',
          props: {
            orgId: organizationId,
            projectId: projectId,
            task: 'reattributeUserConversions'
          }
        })
        window.cmAgent.dispatch()
      }

      setLoading(false)
      onComplete()
    })
  }


  return <Modal
    title={t('reattribute_conversions', 'Re-attribute conversions?')}
    wrapClassName="vertical-center-modal"
    visible={true}
    onCancel={toggleModal}
    width={500}
    footer={[
      <Button key="back" type="ghost" onClick={toggleModal}>{t('later', "Later")}</Button>,
      <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>{t('confirm', "Confirm")}</Button>,
    ]}>
    <div>
      <p>{t('reattribute_conversions_info', "Would you really want to re-attribute your users conversions?")}</p>
    </div>
  </Modal>
}

export default ReattributeConversionsButton