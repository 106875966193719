import React, { useState } from 'react'

import { LockOutlined, MailOutlined } from '@ant-design/icons';

import { Input, Button, Checkbox, Row, Col, Form, message } from 'antd';
import t from '../../utils/translate'
import Config from '../../config'
import Axios from 'axios'
import Moment from 'moment-timezone'
import { signInWithCustomToken, signInWithPopup, GoogleAuthProvider, sendEmailVerification } from 'firebase/auth'
import { useAppContext, AppContextValue } from '../app'

const SignUp = () => {

  const [checkbox, setCheckbox] = useState(false)
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const appCtx: AppContextValue = useAppContext()

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider()

    signInWithPopup(appCtx.firebaseAuth.current, provider).catch((error: any) => {
      console.error(error)
    })
  }

  const onFinish = (values: any) => {

    if (checkbox !== true) {
      message.error(t('checkbox_terms_required', "Accepting the Terms and Conditions is mandatory to create an account."))
      return
    }

    if (loading === true) {
      return
    }
    else {
      setLoading(true)
    }

    Axios({
      method: 'post',
      url: Config().api_endpoint + '/admins.create',
      data: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        timezone: Moment.tz.guess(),
        language: ((navigator.language || navigator.userLanguage).indexOf('fr') !== -1) ? 'fr' : 'en'
      }
    }).then((response: any) => {

      // console.log('res', response.data);
      signInWithCustomToken(appCtx.firebaseAuth.current, response.data.firebaseToken).then(() => {

        sendEmailVerification(appCtx.firebaseAuth.current.currentUser).catch((error: any) => {
          console.error('send email verification', error)
        })
      }).catch((error: any) => {
        console.error(error)
        setLoading(false)
      })

    }).catch((error: any) => {
      console.error(error)
      let msg = error.message

      if (error.response && error.response.status <= 500) {
        msg = error.response.data.message
      }

      message.error(msg)
      setLoading(false)
    })
  }

  return <div>

    <Button type="primary" ghost size="large" onClick={signInWithGoogle}>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48" className="google-svg"><g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path><path fill="none" d="M0 0h48v48H0z"></path></g></svg>
      {t('sign_in_with_google', "Sign in with Google")}
    </Button>

    <div className="text-center margin-v-l">{t('or_sign_up_with_credentials', "Or sign up with credentials")}:</div>

    <Row>
      <Col span={16} offset={4}>

        <Form form={form} onFinish={onFinish}>
          <Row>
            <Col span={12} className="padding-r-s">
              <Form.Item name="firstName" rules={[{ required: true, type: 'string', message: t('field_required', "This field is required!") }]}>
                <Input size="large" placeholder={t('firstName', "First name")} />
              </Form.Item>
            </Col>
            <Col span={12} className="padding-l-s">
              <Form.Item name="lastName" rules={[{ required: true, type: 'string', message: t('field_required', "This field is required!") }]}>
                <Input size="large" placeholder={t('lastName', "Last name")} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item name="email" rules={[{ required: true, type: 'email', message: t('field_required', "This field is required!") }]}>
            <Input size="large" prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={t('email', "Email")} />
          </Form.Item>

          <Form.Item name="password" rules={[{ required: true, min: 6, message: t('password_format_invalid', "The password should contain at least 6 characters.") }]}>
            <Input size="large" prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder={t('password', "Password")} />
          </Form.Item>
          <Form.Item>
            <Checkbox style={{ lineHeight: '1.8' }} onChange={(e: any) => { setCheckbox(e.target.checked) }}><span dangerouslySetInnerHTML={{ __html: t('create_account_terms_checkbox', 'By creating an account I agree to the <a href="https://captainmetrics.com/terms" target="blank">Terms and Conditions &nearr;</a> and <a href="https://captainmetrics.com/privacy-policy" target="blank">Privacy policy &nearr;</a>.') }} /></Checkbox>
          </Form.Item>
          <Form.Item>
            <Button type="primary" loading={loading} size="large" htmlType="submit" style={{ width: '100%' }}>{t('confirm', "Confirm")}</Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  </div>
}

export default SignUp