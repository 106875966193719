import React, { useState } from 'react'

import { Input, Button, Drawer, Select, Form } from 'antd';
import {
    PlusOutlined,
    FacebookOutlined,
    GoogleOutlined,
} from '@ant-design/icons';
import t from '../../utils/translate'
import _ from 'lodash'
import SegmentsCascader from '../segments/_cascader'
import InfoRadioGroup from '../common/infoRadio'
// import DurationInput from '../common/durationInput'
import SelectGoogleAdsAudience from './_selectGoogleAdsAudience'
import SelectFacebookCustomAudience from './_selectFacebookCustomAudience'


const SegmentSyncDrawer = (props: any) => {

    const [showDrawer, setShowDrawer] = useState(false)
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();

    const toggleDrawer = () => {
        // reset on close
        if (showDrawer === true) {
            form.resetFields()
        }

        setShowDrawer(!showDrawer)

    }

    // console.log('drawer props', props)
    const onFinish = (values: any) => {

        values.projectId = props.project.id

        if (props.segmentSync) {
            values.segmentSyncId = props.segmentSync.id
        }

        if (values.audience) {
            values.integrationAudienceId = values.audience.id
            values.integrationAudienceName = values.audience.name
        }

        setLoading(true)

        props.app.ajaxRequest({
            method: 'post',
            url: props.segmentSync ? '/segmentSyncs.update' : '/segmentSyncs.create',
            data: values
        }, (errorMessage: any, response: any) => {

            if (errorMessage) {
                props.app.addMessage('error', errorMessage)
                setLoading(false)
                return
            }


            if (props.segmentSync) {
                props.app.addMessage('success', t('segment_sync_updated', 'The segment sync has been updated.'))
            } else {
                props.app.addMessage('success', t('segment_sync_created', 'The segmentsync has been created.'))
            }

            // only reset new workflow drawer, not for update
            if (!props.segmentSync) {
                form.resetFields()
            }

            setLoading(false)
            setShowDrawer(false)

            if (window.cmAgent) {
                window.cmAgent.event({
                    label: props.segmentSync ? 'updateSegmentSync' : 'createSegmentSync',
                    props: {
                        orgId: props.project.organizationId,
                        projectId: props.project.id,
                    }
                })
                window.cmAgent.dispatch()
            }

            if (props.onComplete) {
                props.onComplete()
            }
        })
    }


    if (!showDrawer) {
        return <Button type={props.type || "default"} style={props.style || {}} size={props.size} onClick={toggleDrawer}>{props.children}</Button>
    }

    // console.log('props', props);
    const initialValues = {
        name: _.get(props, 'segmentSync.name'),
        segmentId: _.get(props, 'segmentSync.segmentId'),
        integrationKind: _.get(props, 'segmentSync.integrationKind'),
        integrationId: _.get(props, 'segmentSync.integrationId'),
        audience: props.segmentSync ? { id: props.segmentSync.integrationAudienceId, name: props.segmentSync.integrationAudienceName } : undefined,
        durationBetweenSyncs: _.get(props, 'segmentSync.durationBetweenSyncs', 60*12), // in mins
    }

    return <Drawer
        title={props.title}
        visible={true}
        onClose={toggleDrawer}
        maskClosable={false}
        className="drawer drawer-form"
        width={720}>

        <Form form={form} initialValues={initialValues} onFinish={onFinish} labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} className="margin-h-m margin-v-xl">

            <Form.Item name="name" label={t('name', "Name")} rules={[{ required: true, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
                <Input placeholder="Name" />
            </Form.Item>

            <Form.Item name="segmentId" label={t('segment', "Segment")} rules={[{ required: true, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
                <SegmentsCascader cascaderProps={{disabled: props.segmentSync ? true : false}} segments={props.segments} excludeSegments={['_all', 'anon']} />
            </Form.Item>

            <Form.Item name="integrationKind" label={t('sync_to', 'Sync to')} rules={[{ required: true, type: 'string' }]}>
                <InfoRadioGroup layout="horizontal" disabled={props.segmentSync ? true : false} options={[
                    {
                        key: 'googleAds',
                        title: 'Google Ads',
                        icon: <GoogleOutlined />,
                        content: t('sync_segment_to_google_ads', 'Sync a segment to a Google Ads "Customer List".'),
                    },
                    {
                        key: 'facebook',
                        title: 'Facebook Ads',
                        icon: <FacebookOutlined />,
                        content: t('sync_segment_to_facebook_ads', 'Sync a segment to a Facebook Ads "Custom Audience".'),
                    },
                ]} />
            </Form.Item>

            <Form.Item noStyle shouldUpdate>
                {(funcs) => {
                    const integrationKind = funcs.getFieldValue('integrationKind')
                    const integrationId = funcs.getFieldValue('integrationId')

                    if (!integrationKind) return

                    return <>

                        {integrationKind === 'facebook' && <>
                            <Form.Item name="integrationId" label={t('facebook_integration', "Facebook integration")} rules={[{ required: true, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
                                <Select>
                                    {props.project.integrations.filter((i: any) => i.kind === integrationKind).map((i: any) => <Select.Option key={i.id} value={i.id}>{i.name}</Select.Option>)}
                                </Select>
                            </Form.Item>

                            {integrationId && <>
                                <Form.Item name="audience" label={t('facebook_custom_audience', "Facebook custom audience")}
                                    extra={<a href="https://business.facebook.com/adsmanager/audiences?tool=AUDIENCES" target="_blank" rel="noopener noreferrer" onClick={() => funcs.setFieldsValue({ integrationId: undefined })} className="link pull-right">
                                        <PlusOutlined /> {t('create_a_custom_audience', "Create a custom audience")}
                                    </a>}
                                    rules={[{
                                        required: true, type: 'object',
                                        validator: (_rule, value) => {
                                            if (!value.id || value.id === '') {
                                                return Promise.reject(t('field_invalid', "This field is not valid!"))
                                            }
                                            return Promise.resolve(undefined)
                                        }, message: t('field_invalid', "This field is not valid!")
                                    }]}
                                >
                                    <SelectFacebookCustomAudience app={props.app} project={props.project} integrationId={integrationId} />
                                </Form.Item>
                            </>}
                        </>}

                        {integrationKind === 'googleAds' && <>
                            <Form.Item name="integrationId" label={t('google_ads_integration', "Google Ads integration")} rules={[{ required: true, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
                                <Select>
                                    {props.project.integrations.filter((i: any) => i.kind === integrationKind).map((i: any) => <Select.Option key={i.id} value={i.id}>{i.name}</Select.Option>)}
                                </Select>
                            </Form.Item>

                            {integrationId && <>
                                <Form.Item name="audience" label={t('google_ads_crm_audience', "Google Ads CRM audience")} rules={[{
                                    required: true, type: 'object',
                                    validator: (_rule, value) => {
                                        if (!value.id || value.id === '') {
                                            return Promise.reject(t('field_invalid', "This field is not valid!"))
                                        }
                                        return Promise.resolve(undefined)
                                    }, message: t('field_invalid', "This field is not valid!")
                                }]}>
                                    <SelectGoogleAdsAudience app={props.app} project={props.project} type="CRM_BASED" integrationId={integrationId} />
                                </Form.Item>
                            </>}
                        </>}

                        {funcs.getFieldValue('audience') && <>
                            <Form.Item name="durationBetweenSyncs" label={t('duration_between_syncs', "Duration between syncs")} rules={[{ required: true, type: 'integer', min: 15, max: 86400, message: t('field_invalid', "This field is not valid!") }]}>
                                <Select options={[
                                    {label: '15min', value: 15},
                                    {label: '30min', value: 30},
                                    {label: '1h', value: 60},
                                    {label: '2h', value: 60*2},
                                    {label: '4h', value: 60*4},
                                    {label: '6h', value: 60*6},
                                    {label: '12h', value: 60*12},
                                    {label: '24h', value: 60*24},
                                ]} />
                            </Form.Item>
                        </>
                        }
                    </>
                }}
            </Form.Item>


            <div className="ant-drawer-footer">
                <Button className="margin-r-m" onClick={toggleDrawer}>{t('cancel', "Cancel")}</Button>
                <Button type="primary" className="margin-r-m" loading={loading} htmlType="submit">{t('save', "Save")}</Button>
            </div>
        </Form>
    </Drawer >
}

export default SegmentSyncDrawer
