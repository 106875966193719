import { useEffect, useState, useRef } from 'react'

import { ArrowRightOutlined, DeleteOutlined, ReloadOutlined } from '@ant-design/icons';

import { Table, Button, Badge, Popconfirm, message } from 'antd';
import t from '../../utils/translate'
import AppLayout from 'components/app/layout';
import { useOrganizationContext, OrganizationContextValue } from 'components/organizations'
import { useHistory, useLocation } from 'react-router';

const ProjectsList = () => {

  const [loading, setLoading] = useState(false)
  const orgCtx: OrganizationContextValue = useOrganizationContext()
  const history = useHistory() as any
  const location = useLocation() as any
  const prevOrganizationId = useRef(undefined)

  useEffect(() => {

    if (prevOrganizationId.current === orgCtx.currentOrganization.id) return
    prevOrganizationId.current = orgCtx.currentOrganization.id

    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Projects (org: ' + orgCtx.currentOrganization.id + ')',
        page: location.pathname,
        props: {
          orgId: orgCtx.currentOrganization.id,
        }
      })
      window.cmAgent.dispatch()
    }
  }, [history, location.pathname, orgCtx.currentOrganization, orgCtx.projects])

  const resetDemo = (projectId: string) => {
    if (loading === true) return

    setLoading(true)

    orgCtx.ajaxRequest({
      method: 'post',
      url: '/projects.resetDemo',
      data: {
        organizationId: orgCtx.currentOrganization.id,
        projectId: projectId,
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        message.error(errorMessage)
        setLoading(false)
        return
      }

      // remove the previous project and add the new one
      let projects = orgCtx.projects.filter(p => p.id !== projectId)
      projects.push(response.data.project)
      orgCtx.setProjects(projects)

      message.success(t('reset_demo_success', 'The demo has been reset with fresh data!'))

      setLoading(false)
      history.push('/organizations/' + orgCtx.currentOrganization.id + '/projects/' + response.data.project.id)
    })
  }


  const deleteDemo = (projectId: string) => {
    if (loading === true) return

    setLoading(true)

    orgCtx.ajaxRequest({
      method: 'post',
      url: '/projects.deleteDemo',
      data: {
        organizationId: orgCtx.currentOrganization.id,
        projectId: projectId,
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        message.error(errorMessage)
        setLoading(false)
        return
      }

      let projects = orgCtx.projects.filter(p => p.id !== projectId)
      orgCtx.setProjects(projects)

      message.success(t('delete_demo_success', 'The demo has been deleted!'))

      setLoading(false)
    })
  }


    return <AppLayout currentOrganization={orgCtx.currentOrganization} admin={orgCtx.admin} firebaseUser={orgCtx.firebaseUser} projects={orgCtx.projects}>

      <h1 className="margin-b-l">
        {t('projects', "Projects")}
        <span className="actions">
          <Button type="ghost" onClick={() => history.push('/organizations/' + orgCtx.currentOrganization.id + '/create-project')}>{t('create_a_new_project', "Create a new project")}</Button>
        </span>
      </h1>

      <Table
        showHeader={false}
        pagination={false}
        dataSource={orgCtx.projects.filter((x: any) => !x.deletedAt)}
        rowKey="id"
        className="block"
        columns={[
          {
            key: 'name',
            render: (p: any) => <div>
              <div><b>{p.name}</b></div>
              <small><a href={p.websiteUrl} rel="noreferrer noopener" target="blank">{p.websiteUrl}</a></small>
            </div>
          },
          {
            key: 'status',
            render: (p: any) => <div>
              {p.status === "setup" && <div>
                <Badge status="processing" />
                {t('status_setup', "Setup")}
              </div>}
            </div>
          },
          {
            key: 'actions',
            render: (p: any) => <div className="text-right">
              {p.demoKind && <Popconfirm placement="topRight" title={t('reset_demo_confirm', "Do you really want to reset the demo with fresh data?")} onConfirm={resetDemo.bind(null, p.id)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                <Button type="default" className="margin-r-s" icon={<ReloadOutlined />} loading={loading}>{t('reset_demo', "Reset demo")}</Button>
              </Popconfirm>}
              {p.demoKind && <Popconfirm placement="topRight" title={t('delete_demo_confirm', "Do you really want to delete this demo?")} onConfirm={deleteDemo.bind(null, p.id)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                <Button type="default" className="margin-r-s" icon={<DeleteOutlined />} loading={loading} />
              </Popconfirm>}
              {p.status === "setup" && <Button type="primary" onClick={() => history.push('/organizations/' + orgCtx.currentOrganization.id + '/projects/' + p.id + '/setup')}>{t('setup', "Setup")} <ArrowRightOutlined /></Button>}
              {p.status === "active" && <Button type="primary" onClick={() => history.push('/organizations/' + orgCtx.currentOrganization.id + '/projects/' + p.id)}>{t('view', "View")} <ArrowRightOutlined /></Button>}
            </div>
          }
        ]}
      />
    </AppLayout>
}

export default ProjectsList