import React, { useState, MouseEvent } from "react"
import { FileProvider, Folder } from '../core/interfaces'
import {
    Dropdown,
    Menu,
    Modal,
} from 'antd'
import { MoreOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'


export interface FolderMenuProps {
    fileProvider: FileProvider
    folder: Folder
    onDelete: (deletedFolder: Folder) => void
    onRename: (newFolder: Folder) => void
    onError: (error: any) => void
}

const FolderMenu = (props: FolderMenuProps) => {

    const [loading, setLoading] = useState(false)

    const onDelete = () => {
        Modal.confirm({
            title: 'Do you want to delete this folder?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deleting the "' + props.folder.name + '" folder will also delete its files!',
            okButtonProps: { danger: true, loading: loading },
            cancelButtonProps: { loading: loading },
            okText: "Delete",
            closable: !loading,
            onOk() {
                setLoading(true)
                props.fileProvider.deleteNode(props.folder).then(() => {
                    setLoading(false)
                    props.onDelete(props.folder)
                }).catch(e => {
                    setLoading(false)
                    props.onError(e)
                })
            },
        })
    }

    return <span onClick={(e: MouseEvent) => e.stopPropagation()}>
        <Dropdown className="fm-tree-item-icon" trigger={['click']} overlay={<Menu>
            {/* <Menu.Item icon={<DownOutlined />}>
                Rename
            </Menu.Item> */}
            <Menu.Item key="delete" icon={<DeleteOutlined />} danger onClick={onDelete}>Delete</Menu.Item>
        </Menu>}>
            <MoreOutlined />
        </Dropdown></span>
}

export default FolderMenu