
export const escapeCSV = (str: any): string => {
    return '"' + str + '"'
}

export const downloadCSV = (content, fileName) => {
    var a = document.createElement('a');

    if (URL && 'download' in a) { //html5 A[download]
        a.href = URL.createObjectURL(new Blob([content], {
            type: 'application/octet-stream'
        }))
        a.setAttribute('download', fileName)
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    } else {
        const loc = new Location()
        loc.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
    }
}

export const arrayToCSV = (arr: any[][]): string => {
    let result = ''
    
    arr.forEach((line: any[]) => {
        result += line.map((row: any) => '"' + row + '"').join(',')+'\r\n'
    })

    return result
}