import { Component } from 'react'

// import t from '../../utils/translate'
import { Table, message } from 'antd'
import Formatters from '../../utils/formatters'
import N from 'numeral'
import removeLeadingDates from 'utils/removeLeadingDates'

class TrafficSources extends Component<any, any> {
  _isMounted = false;

  constructor(props: any) {
    super(props)
    
    this.state = {
      loading: true,
      currentSourceMedium: [],
      previousSourceMedium: [],
    }

    this.fetch = this.fetch.bind(this)
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetch()
  }

  componentDidUpdate(prevProps: any) {
    // check url params have changed
    if (prevProps.refreshAt !== this.props.refreshAt) {
      window.setTimeout(() => this.fetch())
    } else if (prevProps.project.id !== this.props.project.id) {
      window.setTimeout(() => this.fetch())
    }
  }

  fetch() {

    if (!this.state.loading) {
      this.setState({loading: true})
    }

    const opts: any = {
      measures: [
        'TimelineSessions.uniqueUsers',
        'TimelineOrders.count',
        'TimelineOrders.revenue',
      ],
      dimensions: ['TimelineSessions.sourceMedium'],
      timeDimensions: [
        {
          "dimension": 'TimelineSessions.truncCreatedAt',
          "granularity": null,
          "compareDateRange": [
            [this.props.dateFrom,this.props.dateTo],
            [this.props.dateFromPrevious,this.props.dateToPrevious]
          ]
        }
      ],
      timezone: this.props.timezone,
      order: {'TimelineSessions.uniqueUsers': 'desc'},
      limit: 100
    }


    this.props.cubejsApi.load(opts).then((resultSet: any) => {
      const [currentSourceMedium, previousSourceMedium] = resultSet.decompose()

      const currentSourceMediumTable = currentSourceMedium.tablePivot()
      const previousSourceMediumTable = previousSourceMedium.tablePivot()

      removeLeadingDates(currentSourceMediumTable)
      removeLeadingDates(previousSourceMediumTable)

      // console.log('currentSourceMedium', currentSourceMediumTable);

      // // compute totals
      // const currentSourceMediumTotal = currentSourceMediumTable.reduce((acc: any, x: any) => acc+x['ActiveUsers.count'], 0)
      // const previousSourceMediumTotal = previousSourceMediumTable.reduce((acc: any, x: any) => acc+x['ActiveUsers.count'], 0)

      // // compute percentage of total
      // currentSourceMediumTable.forEach((x: any, index: any) => {
      //   currentSourceMediumTable[index].key = x['ActiveUsers.sessionDevice']
      //   currentSourceMediumTable[index].percentage = currentSourceMediumTotal > 0 ? (x['ActiveUsers.count']*100)/currentSourceMediumTotal : 0
      // })
      // previousSourceMediumTable.forEach((x: any, index: any) => {
      //   previousSourceMediumTable[index].key = x['ActiveUsers.sessionDevice']
      //   previousSourceMediumTable[index].percentage = previousSourceMediumTotal > 0 ? (x['ActiveUsers.count']*100)/previousSourceMediumTotal : 0
      // })

      // console.log('currentSourceMediumTotal', currentSourceMediumTotal);
      // console.log('previousSourceMediumTotal', previousSourceMediumTotal);

      this.setState({
        loading: false,
        currentSourceMedium: currentSourceMediumTable,
        previousSourceMedium: previousSourceMediumTable,
      })
    })
    .catch((error: any) => {

      console.log(error)

      let msg = error.message

      if (error.response && error.response.status === 400) {
        switch(error.response.data.message) {
          default: 
          msg = error.response.data.message
        }
      }

      message.error(msg)
      this.setState({loading: false})
    })
  }

  render() {

    // console.log('state kpi', this.state);
    // console.log('this.state.currentSourceMedium', this.state.currentSourceMedium);

    return <div className="padding-a-m dash-table-stats">
      {/*<div className="widget-title margin-b-m">{t('traffic_sources', "Traffic sources")}</div>*/}

      <Table rowKey="TimelineSessions.sourceMedium"
        size="middle"
        loading={this.state.loading}
        dataSource={this.state.currentSourceMedium}
        pagination={{pageSize: 20, hideOnSinglePage: true, showSizeChanger: false}}
        columns={[
          {
            title: 'Traffic Source / Medium',
            key: 'sourceMedium',
            render: (x: any) => <span>{x['TimelineSessions.sourceMedium']}</span>
          },
          {
            title: 'Users',
            key: 'users',
            sorter: (a: any, b: any) => {
              if (a['TimelineSessions.uniqueUsers'] < b['TimelineSessions.uniqueUsers']) { return -1 }
              if (a['TimelineSessions.uniqueUsers'] > b['TimelineSessions.uniqueUsers']) { return 1 }
              return 0
            },
            sortOrder: 'descend',
            showSorterTooltip: false,
            render: (x: any) =>  <span className="value">{N(x['TimelineSessions.uniqueUsers']).format('0,0')}</span>
          },
          {
            title: 'Conversions',
            key: 'conversions',
            render: (x: any) =>  <span>{N(x['TimelineOrders.count']).format('0,0')}</span>
          },
          {
            title: 'Revenue',
            key: 'revenue',
            render: (x: any) =>  <span>{Formatters.currency(this.props.currency, x['TimelineOrders.revenue'])}</span>
          },
        ]}
      />
    </div>
  }
}

export default TrafficSources