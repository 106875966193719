import React, { Component } from 'react'

import t from '../../utils/translate'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import ConversionRuleForm from '../conversionRules/_form'
import ConversionRulesTable from '../conversionRules/_table'
import ConversionRulesAbout from '../conversionRules/_about'


class ProjectSetupConversionRules extends Component<any, any> {

  constructor(props: any) {
    super(props)
    
    this.state = {
      loading: false,
    }

    this.setLoading = this.setLoading.bind(this)
  }

  setLoading(val: any) {
    this.setState({loading: val})
  }

  render() {

    return (
      <div>
        <ConversionRulesAbout />

        {(this.props.projectLayout.project.conversionRules.length === 0) ?
          <div className="block margin-h-m padding-a-m"><ConversionRuleForm {...this.props} edit={false} /></div>
        :
          <div className="padding-v-m"><ConversionRulesTable {...this.props} setParentLoading={this.setLoading} /></div>
        }

        <div className="padding-a-m">
          <span className="pull-right">
            <Button type="primary" loading={this.state.loading} disabled={this.props.projectLayout.project.conversionRules.length === 0} onClick={(e: any) => this.props.setStep('conversionRules')}>{t('next', "Next")} <ArrowRightOutlined /></Button>
          </span>
          <Button type="ghost" loading={this.state.loading} onClick={(e: any) => this.props.setStep('start')}><ArrowLeftOutlined /> {t('previous', "Previous")}</Button>
        </div>
      </div>
    );
  }
}

export default ProjectSetupConversionRules