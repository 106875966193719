import React, { useState } from 'react'
import {
    Button,
    // Tag,
    Drawer,
    Tooltip,
    Row,
    Col,
} from 'antd'
import t from '../../../utils/translate'
import Formatters from '../../../utils/formatters'
import DateRange from '../../projects/_dateRange'
import Moment from 'moment-timezone'
import { ReloadOutlined } from '@ant-design/icons';
import KPI from '../../dashboard/kpi'

const SegmentStats = ({ segment, app, projectLayout, btnSize, btnType, btnGhost, children }: any) => {

    const [dateRangeVs, setDateRangeVs] = useState('previous_period')
    const [dateRangeLabel, setDateRangeLabel] = useState('last_30_days')
    const [dateRangeValue, setDateRangeValue] = useState([Moment().tz(app.state.admin.timezone).subtract(30, 'day').startOf('day'), Moment().tz(app.state.admin.timezone).endOf('day')])
    const [refreshAt, setRefreshAt] = useState(Moment().unix())
    const [shown, setShown] = useState(false)
    // const [loading, setLoading] = useState(true)

    const setDateRange = (label: string, range: any) => {
        setDateRangeValue(range)
        setDateRangeLabel(label)
        setRefreshAt(Moment().unix())
    }

    const filters = [{
        'member': 'UserSegments.segmentId',
        'operator': 'equals',
        'values': [segment.id]
    }]

    const dateFrom = Moment(dateRangeValue[0])
    const dateTo = Moment(dateRangeValue[1])

    let dateFromPrevious: any = Moment(dateFrom)
    let dateToPrevious: any = Moment(dateTo)

    if (dateRangeVs === "previous_period") {

        // add one day to the diff to avoid days overlaping between the to ranges
        const diff = dateTo.diff(dateFrom, 'days') + 1
        // console.log('diff is', diff);

        dateToPrevious = dateToPrevious.subtract(diff, 'days')
        dateFromPrevious = dateFromPrevious.subtract(diff, 'days')
    }

    if (dateRangeVs === "previous_year") {
        dateToPrevious = dateToPrevious.subtract(1, 'years')
        dateFromPrevious = dateFromPrevious.subtract(1, 'years')
    }

    dateFromPrevious = dateFromPrevious.format('YYYY-MM-DD')
    dateToPrevious = dateToPrevious.format('YYYY-MM-DD')

    return <>
        <Button size={btnSize} type={btnType} ghost={btnGhost} onClick={setShown.bind(null, !shown)}>{children}</Button>
        {shown && <Drawer
            title={t('segment_stats', 'Segment stats')}
            width="90%"
            visible={true}
            onClose={setShown.bind(null, false)}
            className="drawer"
        >
            <Row className="padding-a-m dashed-bottom-1">
                <Col span={12}>{Formatters.segment(projectLayout.segments, segment.id, undefined, true)}</Col>
                <Col span={12} className="text-right">
                    <DateRange
                        timezone={app.state.admin.timezone}
                        vs={dateRangeVs}
                        dateRange={dateRangeValue}
                        labelKey={dateRangeLabel}
                        onVsChange={(value: any) => {
                            setDateRangeVs(value)
                            setRefreshAt(Moment().unix())
                        }}
                        onChange={setDateRange} />
                    <Tooltip placement="left" title={t('last_refresh_at', "Refreshed {at}", { at: Moment.unix(refreshAt).fromNow() })}>
                        <Button className="margin-l-s" size="small" type="default" shape="circle" icon={<ReloadOutlined />} onClick={setRefreshAt.bind(null, Moment().unix())} />
                    </Tooltip>
                </Col>
            </Row>

            <div className="dash-kpi-container dashed-bottom-1">
                <KPI
                    title={t('users_entering', 'Users entering')}
                    measure="UserSegments.users"
                    timeDimension="UserSegments.enterAt"
                    filters={filters}
                    color="cyan"
                    projectId={projectLayout.project.id}
                    cubejsApi={projectLayout.cubejsApi}
                    timezone={app.state.admin.timezone}
                    refreshAt={refreshAt}
                    dateFrom={dateFrom.format('YYYY-MM-DD')}
                    dateTo={dateTo.format('YYYY-MM-DD')}
                    dateFromPrevious={dateFromPrevious}
                    dateToPrevious={dateToPrevious}
                    addMessage={app.addMessage}
                />
                <KPI
                    title={t('users_exiting', 'Users exiting')}
                    measure="UserSegments.exitingUsers"
                    timeDimension="UserSegments.exitAt"
                    filters={filters}
                    color="cyan"
                    projectId={projectLayout.project.id}
                    cubejsApi={projectLayout.cubejsApi}
                    timezone={app.state.admin.timezone}
                    refreshAt={refreshAt}
                    dateFrom={dateFrom.format('YYYY-MM-DD')}
                    dateTo={dateTo.format('YYYY-MM-DD')}
                    dateFromPrevious={dateFromPrevious}
                    dateToPrevious={dateToPrevious}
                    addMessage={app.addMessage}
                />
                <KPI
                    title={t('revenue', "Revenue")}
                    measure="TimelineOrders.revenue"
                    kind="currency"
                    currency={projectLayout.project.currency}
                    timeDimension="TimelineOrders.truncCreatedAt"
                    filters={filters}
                    color="cyan"
                    projectId={projectLayout.project.id}
                    cubejsApi={projectLayout.cubejsApi}
                    timezone={app.state.admin.timezone}
                    refreshAt={refreshAt}
                    dateFrom={dateFrom.format('YYYY-MM-DD')}
                    dateTo={dateTo.format('YYYY-MM-DD')}
                    dateFromPrevious={dateFromPrevious}
                    dateToPrevious={dateToPrevious}
                    addMessage={app.addMessage}
                />
                <KPI
                    title={t('orders', "Orders")}
                    measure="TimelineOrders.count"
                    timeDimension="TimelineOrders.truncCreatedAt"
                    filters={filters}
                    color="cyan"
                    projectId={projectLayout.project.id}
                    cubejsApi={projectLayout.cubejsApi}
                    timezone={app.state.admin.timezone}
                    refreshAt={refreshAt}
                    dateFrom={dateFrom.format('YYYY-MM-DD')}
                    dateTo={dateTo.format('YYYY-MM-DD')}
                    dateFromPrevious={dateFromPrevious}
                    dateToPrevious={dateToPrevious}
                    addMessage={app.addMessage}
                />
                <KPI
                    title={t('revenue_per_user', 'Revenue / user')}
                    measure="TimelineOrders.revenuePerUser"
                    kind="currency"
                    currency={projectLayout.project.currency}
                    timeDimension="TimelineOrders.truncCreatedAt"
                    filters={filters}
                    color="cyan"
                    projectId={projectLayout.project.id}
                    cubejsApi={projectLayout.cubejsApi}
                    timezone={app.state.admin.timezone}
                    refreshAt={refreshAt}
                    dateFrom={dateFrom.format('YYYY-MM-DD')}
                    dateTo={dateTo.format('YYYY-MM-DD')}
                    dateFromPrevious={dateFromPrevious}
                    dateToPrevious={dateToPrevious}
                    addMessage={app.addMessage}
                />
                <KPI
                    title={t('conversions_per_user', 'Conversions / user')}
                    measure="TimelineOrders.conversionsPerUser"
                    timeDimension="TimelineOrders.truncCreatedAt"
                    filters={filters}
                    color="cyan"
                    projectId={projectLayout.project.id}
                    cubejsApi={projectLayout.cubejsApi}
                    timezone={app.state.admin.timezone}
                    refreshAt={refreshAt}
                    dateFrom={dateFrom.format('YYYY-MM-DD')}
                    dateTo={dateTo.format('YYYY-MM-DD')}
                    dateFromPrevious={dateFromPrevious}
                    dateToPrevious={dateToPrevious}
                    addMessage={app.addMessage}
                    isLast={true}
                />
            </div>

            <div className="text-center padding-a-m subtitle color-kpi-pink">Acquisition</div>
            <div className="dash-kpi-container dashed-bottom-1 dashed-top-1">
                <KPI
                    title={t('orders', 'Orders')}
                    measure="TimelineOrders.acquisitionCount"
                    timeDimension="TimelineOrders.truncCreatedAt"
                    filters={filters}
                    color="magenta"
                    projectId={projectLayout.project.id}
                    cubejsApi={projectLayout.cubejsApi}
                    timezone={app.state.admin.timezone}
                    refreshAt={refreshAt}
                    dateFrom={dateFrom.format('YYYY-MM-DD')}
                    dateTo={dateTo.format('YYYY-MM-DD')}
                    dateFromPrevious={dateFromPrevious}
                    dateToPrevious={dateToPrevious}
                    addMessage={app.addMessage}
                />
                <KPI
                    title={t('revenue', 'Revenue')}
                    kind="currency"
                    currency={projectLayout.project.currency}
                    measure="TimelineOrders.acquisitionRevenue"
                    timeDimension="TimelineOrders.truncCreatedAt"
                    filters={filters}
                    color="magenta"
                    projectId={projectLayout.project.id}
                    cubejsApi={projectLayout.cubejsApi}
                    timezone={app.state.admin.timezone}
                    refreshAt={refreshAt}
                    dateFrom={dateFrom.format('YYYY-MM-DD')}
                    dateTo={dateTo.format('YYYY-MM-DD')}
                    dateFromPrevious={dateFromPrevious}
                    dateToPrevious={dateToPrevious}
                    addMessage={app.addMessage}
                />
                <KPI
                    title={t('avg_cart', 'Avg. cart')}
                    kind="currency"
                    currency={projectLayout.project.currency}
                    measure="TimelineOrders.acquisitionAverageCart"
                    timeDimension="TimelineOrders.truncCreatedAt"
                    filters={filters}
                    color="magenta"
                    projectId={projectLayout.project.id}
                    cubejsApi={projectLayout.cubejsApi}
                    timezone={app.state.admin.timezone}
                    refreshAt={refreshAt}
                    dateFrom={dateFrom.format('YYYY-MM-DD')}
                    dateTo={dateTo.format('YYYY-MM-DD')}
                    dateFromPrevious={dateFromPrevious}
                    dateToPrevious={dateToPrevious}
                    addMessage={app.addMessage}
                />
                <KPI
                    title={<Tooltip title={t('avg_time_to_conversion', "Average Time To Conversion")}>{t('avg_ttc', "Avg. TTC")}</Tooltip>}
                    kind="duration"
                    measure="TimelineOrders.acquisitionTimeToConversion"
                    timeDimension="TimelineOrders.truncCreatedAt"
                    filters={filters}
                    color="magenta"
                    projectId={projectLayout.project.id}
                    cubejsApi={projectLayout.cubejsApi}
                    timezone={app.state.admin.timezone}
                    refreshAt={refreshAt}
                    dateFrom={dateFrom.format('YYYY-MM-DD')}
                    dateTo={dateTo.format('YYYY-MM-DD')}
                    dateFromPrevious={dateFromPrevious}
                    dateToPrevious={dateToPrevious}
                    addMessage={app.addMessage}
                />
            </div>

            <div className="text-center padding-a-m subtitle color-kpi-indigo">Retention</div>
            <div className="dash-kpi-container dashed-bottom-1 dashed-top-1">
                <KPI
                    title={t('orders', 'Orders')}
                    measure="TimelineOrders.retentionCount"
                    timeDimension="TimelineOrders.truncCreatedAt"
                    filters={filters}
                    color="indigo"
                    projectId={projectLayout.project.id}
                    cubejsApi={projectLayout.cubejsApi}
                    timezone={app.state.admin.timezone}
                    refreshAt={refreshAt}
                    dateFrom={dateFrom.format('YYYY-MM-DD')}
                    dateTo={dateTo.format('YYYY-MM-DD')}
                    dateFromPrevious={dateFromPrevious}
                    dateToPrevious={dateToPrevious}
                    addMessage={app.addMessage}
                />
                <KPI
                    title={t('revenue', 'Revenue')}
                    kind="currency"
                    currency={projectLayout.project.currency}
                    measure="TimelineOrders.retentionRevenue"
                    timeDimension="TimelineOrders.truncCreatedAt"
                    filters={filters}
                    color="indigo"
                    projectId={projectLayout.project.id}
                    cubejsApi={projectLayout.cubejsApi}
                    timezone={app.state.admin.timezone}
                    refreshAt={refreshAt}
                    dateFrom={dateFrom.format('YYYY-MM-DD')}
                    dateTo={dateTo.format('YYYY-MM-DD')}
                    dateFromPrevious={dateFromPrevious}
                    dateToPrevious={dateToPrevious}
                    addMessage={app.addMessage}
                />
                <KPI
                    title={t('avg_cart', 'Avg. cart')}
                    kind="currency"
                    currency={projectLayout.project.currency}
                    measure="TimelineOrders.retentionAverageCart"
                    timeDimension="TimelineOrders.truncCreatedAt"
                    filters={filters}
                    color="indigo"
                    projectId={projectLayout.project.id}
                    cubejsApi={projectLayout.cubejsApi}
                    timezone={app.state.admin.timezone}
                    refreshAt={refreshAt}
                    dateFrom={dateFrom.format('YYYY-MM-DD')}
                    dateTo={dateTo.format('YYYY-MM-DD')}
                    dateFromPrevious={dateFromPrevious}
                    dateToPrevious={dateToPrevious}
                    addMessage={app.addMessage}
                />
                <KPI
                    title={<Tooltip title={t('avg_time_to_conversion', "Average Time To Conversion")}>{t('avg_ttc', "Avg. TTC")}</Tooltip>}
                    kind="duration"
                    measure="TimelineOrders.retentionTimeToConversion"
                    timeDimension="TimelineOrders.truncCreatedAt"
                    filters={filters}
                    color="indigo"
                    projectId={projectLayout.project.id}
                    cubejsApi={projectLayout.cubejsApi}
                    timezone={app.state.admin.timezone}
                    refreshAt={refreshAt}
                    dateFrom={dateFrom.format('YYYY-MM-DD')}
                    dateTo={dateTo.format('YYYY-MM-DD')}
                    dateFromPrevious={dateFromPrevious}
                    dateToPrevious={dateToPrevious}
                    addMessage={app.addMessage}
                    isLast={true}
                />
            </div>
        </Drawer>}
    </>
}

export default SegmentStats