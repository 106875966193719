import { MutableRefObject, useMemo, useContext, createContext, useState, useEffect, useCallback, useRef } from 'react'

import { Alert, message, Modal, Progress } from 'antd'
import {
  Route,
  Switch,
  useParams,
  useHistory,
  useLocation,
} from 'react-router-dom'
import ProjectsDashboard from 'components/dashboard/dashboard'
import { Spin } from 'antd'
// import ProjectsTest from './test'
import ProjectsSetup from './setup'
import ProjectsSettings from './settings'
import TasksList from 'components/tasks/list'
import LogsList from 'components/logs/list'
import UsersList from 'components/users/list'
// import CompaniesList from 'components/companies/list'
// import CompaniesShow from 'components/companies/show'
import AnalyticsWeb from 'components/analytics/web/index'
import AnalyticsSegments from 'components/analytics/segments/index'
import AnalyticsNotifications from 'components/analytics/notifications/index'
import AnalyticsMediaPartners from 'components/analytics/mediaPartners/index'
import OrdersList from 'components/orders/list'
import LeadsList from 'components/leads/list'
import ProductsList from 'components/products/list'
import IntegrationsList from 'components/integrations/list'
import MediaPartners from 'components/partnerships/mediaPartners'
import Commissions from 'components/partnerships/commissions'
import Payments from 'components/partnerships/payments'
import WorkflowsList from 'components/workflows/list'
import SegmentsSync from 'components/segments/sync'
import ConversionRulesList from 'components/conversionRules/list'
import ChannelsList from 'components/channels/list'
import DomainsList from 'components/domains/list'
import CommissionGroups from 'components/commissionGroups/list'
import Test from './test'
import ShowUser from 'components/users/_show'
import ShowOrder from 'components/orders/_show'
import ShowLead from 'components/leads/_show'
import QS from 'qs'
import t from 'utils/translate'
import Cache from 'utils/cache'
import cubejs, { CubejsApi } from '@cubejs-client/core';
import Config from 'config'
import Notifications from 'components/notifications/index'
import FileManagerPage from './fileManagerPage'
import { FirebaseApp } from '@firebase/app'
import { Auth, User as FirebaseUser } from 'firebase/auth'
import { Unsubscribe } from '@firebase/util';
import { getFirestore, collection, query, where, onSnapshot } from 'firebase/firestore'
import { useOrganizationContext, OrganizationContextValue } from 'components/organizations'
import { Admin, Project, Organization, FirestoreRunningTask, Segment, Macro, Notification, NotificationTopic } from 'components/app/interfaces'
import AppLayout from 'components/app/layout'


const ProjectContext = createContext<ProjectContextValue | null>(null);

export function useProjectContext(): ProjectContextValue {
  const projectValue = useContext(ProjectContext);
  if (!projectValue) {
    throw new Error("Missing ProjectContextProvider in its parent.");
  }
  return projectValue;
}

export interface ProjectContextValue {
  // APP CTX
  firebaseApp: MutableRefObject<FirebaseApp>
  firebaseAuth: MutableRefObject<Auth>
  firebaseUser: FirebaseUser
  loadingUser: boolean
  admin: Admin
  setAdmin: (admin: Admin) => void
  ajaxRequest: (options: any, callback?: Function) => void
  signOut: Function,
  signIn: (user: FirebaseUser, redirectTo?: string, forceRefresh?) => void
  organizations: Organization[]
  setOrganizations: (organizations: Organization[]) => void

  // ORG CTX
  setCurrentOrganizationId: (id: string) => void
  currentOrganization?: Organization
  updateOrganization: (updatedOrganization: Organization) => void
  projects: Project[]
  setProjects: (projects: Project[]) => void

  // PROJECT CTX
  setCurrentProjectId: (id: string) => void
  currentProject?: Project
  updateProject: (updatedProject: Project) => void
  refreshNotificationsAndMacros: () => Promise<any>
  refreshNotificationTopics: () => Promise<any>
  refreshSegments: () => Promise<any>
  // runningTasks?: FirestoreRunningTask[]
  demoTask?: FirestoreRunningTask
  cubejsApi: CubejsApi
  viewUser: (userId: string) => void,
  viewOrder: (orderId: string, conversionRuleId: string) => void,
  viewLead: (leadId: string, conversionRuleId: string) => void,
  // viewCompany: () => void,
  viewProduct: (productId: string) => void,
}

const ProjectIndex = (props): any => {

  const orgCtx: OrganizationContextValue = useOrganizationContext()
  const [demoTask, setDemoTask] = useState<FirestoreRunningTask | undefined>(undefined)
  const routeParams = useParams() as any
  const history = useHistory() as any
  const location = useLocation() as any
  const unsubscribe = useRef<Unsubscribe | undefined>(undefined)
  const [runningTasks, setRunningTasks] = useState<FirestoreRunningTask[]>([])
  const runningTasksTimeout = useRef<number | undefined>(undefined)
  const prevRouteProjectId = useRef(undefined)
  const [currentProjectId, setCurrentProjectId] = useState<string | undefined>(undefined)
  const [notifications, setNotifications] = useState<Notification[]>([])
  const [macros, setMacros] = useState<Macro[]>([])
  const [notificationTopics, setNotificationTopics] = useState<NotificationTopic[]>([])
  const [segments, setSegments] = useState<Segment[]>([])
  const [userCustomProperties, setUserCustomProperties] = useState<any>({})

  // this.state = {
  //   macros: [],
  //   notifications: [],
  //   notificationTopics: [],
  //   notificationsLoaded: false,
  //   segments: [],
  //   segmentsLoaded: false,
  //   userCustomProperties: {},
  //   userCustomPropertiesLoaded: false,
  //   runningTasks: [],
  //   demoTask: undefined
  // }

  const updateProject = useCallback((updatedProject: Project) => {
    const newProjects = [...orgCtx.projects.filter(p => p.id !== updatedProject.id)]

    // // add joined data if missing
    // const updatedProjectIndex = orgCtx.projects.findIndex(x => x.id === updatedProject.id)
    // if (updatedProjectIndex !== 1 && orgCtx.projects[updatedProjectIndex].fullyLoaded === true && !updatedProject.fullyLoaded) {
    //   updatedProject.fullyLoaded = true
    //   updatedProject.notificationTopics = orgCtx.projects[updatedProjectIndex].notificationTopics
    //   updatedProject.notifications = orgCtx.projects[updatedProjectIndex].notifications
    //   updatedProject.macros = orgCtx.projects[updatedProjectIndex].macros
    //   updatedProject.segments = orgCtx.projects[updatedProjectIndex].segments
    //   updatedProject.userCustomProperties = orgCtx.projects[updatedProjectIndex].userCustomProperties
    // }

    newProjects.push(updatedProject)

    // console.log('updatedProject', updatedProject)

    orgCtx.setProjects(newProjects)
  }, [orgCtx])


  const loadUserCustomProperties = useCallback((projectId: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      orgCtx.ajaxRequest({
        method: 'get',
        url: '/users.listCustomProperties',
        params: {
          projectId: projectId
        }
      }, (errorMessage: any, response: any) => {

        if (errorMessage) {
          message.error(errorMessage)
          return reject(errorMessage)
        }

        setUserCustomProperties(response.data.customProperties)

        // project.userCustomProperties = response.data.customProperties

        resolve(undefined)
      })
    })
  }, [orgCtx])

  const loadSegments = useCallback((projectId: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      orgCtx.ajaxRequest({
        method: 'get',
        url: '/segments.list',
        params: {
          projectId: projectId,
          kind: 'user'
        }
      }, (errorMessage: any, response: any) => {

        if (errorMessage) {
          message.error(errorMessage)
          return
        }
        // console.log('segments', response.data);
        setSegments(response.data.segments)
        // projectToUpdate.segments = response.data.segments

        resolve(undefined)
      })
    })
  }, [orgCtx])


  // const refreshSegments = useCallback((): Promise<void> => {
  //   return new Promise((resolve, reject) => {
  //     const currentProject = orgCtx.projects.find(x => x.id === currentProjectId)
  //     if (!currentProject) return reject('project not found for refreshSegments')
  //     const updatedProject = { ...currentProject }
  //     loadSegments(updatedProject).then(() => {
  //       updateProject(updatedProject)
  //       resolve(undefined)
  //     }).catch(reject)
  //   })
  // }, [loadSegments, currentProjectId, orgCtx, updateProject])


  const loadNotificationsAndMacros = useCallback((projectId: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      orgCtx.ajaxRequest({
        method: 'get',
        url: '/notifications.list',
        params: {
          projectId: projectId,
        }
      }, (errorMessage: any, response: any) => {

        if (errorMessage) {
          message.error(errorMessage)
          return reject(errorMessage)
        }

        setNotifications(response.data.notifications)
        setMacros(response.data.macros)
        // projectToUpdate.notifications = response.data.notifications
        // projectToUpdate.macros = response.data.macros

        resolve(undefined)
      })
    })
  }, [orgCtx])

  const loadNotificationTopics = useCallback((projectId: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      orgCtx.ajaxRequest({
        method: 'get',
        url: '/notificationTopics.list',
        params: {
          projectId: projectId,
        }
      }, (errorMessage: any, response: any) => {

        if (errorMessage) {
          message.error(errorMessage)
          return reject(errorMessage)
        }

        setNotificationTopics(response.data.notificationTopics)
        // projectToUpdate.notificationTopics = response.data.notificationTopics
        resolve(undefined)
      })
    })
  }, [orgCtx])


  const watchRunningTasks = useCallback((projectId: string) => {

    // console.log('watchRunningTasks')
    // reset sub
    // if (unsubscribe.current) {
    //   console.log('unsubscribe')
    //   unsubscribe.current()
    // }

    const db = getFirestore(orgCtx.firebaseApp.current)

    const q = query(collection(db, "running_tasks"), where("projectId", "==", projectId));

    unsubscribe.current = onSnapshot(q, (querySnapshot: any) => {

      const newTasks: FirestoreRunningTask[] = []
      querySnapshot.forEach((doc: any) => newTasks.push(doc.data()))

      let shouldFetchSegments = false

      querySnapshot.docChanges().forEach((change: any) => {

        const runningTask = change.doc.data() as FirestoreRunningTask

        if (runningTask.kind === "refreshSegment" && (change.type === "added" || change.type === "removed")) {
          shouldFetchSegments = true
        }
      })

      // use a timeout to batch changes before refresh segments list

      if (runningTasksTimeout.current) {
        clearTimeout(runningTasksTimeout.current)
      }

      runningTasksTimeout.current = window.setTimeout(() => {

        setRunningTasks(newTasks)
        // // update current project
        // const currentProject = orgCtx.projects.find(x => x.id === projectId)
        // console.log('AAA', currentProject)
        // if (!currentProject) return

        // const updatedProject = { ...currentProject }
        // updatedProject.runningTasks = runningTasks
        // updateProject(updatedProject)

        const demoTaskFound = newTasks.find(t => t.kind === 'generateDemo')

        // loading demo started
        if (demoTaskFound && !demoTask) {
          setDemoTask(demoTaskFound)
        }

        // loading demo ended
        if (!demoTaskFound && demoTask) {
          window.cmCache = Cache()
          document.location.reload()
          return
        }

        // console.log('shouldFetchSegments', shouldFetchSegments);

        if (shouldFetchSegments === true) {
          loadSegments(projectId)
        }
      }, 500)

    },
      (error: any) => {
        message.error(error)
        console.error(error)
      }
    )
  }, [orgCtx.firebaseApp, demoTask, loadSegments])


  useEffect(() => {

    const loadProject = (project: Project): Promise<any> => {
      return new Promise((resolve, reject) => {

        if (project.fullyLoaded) {
          return resolve(undefined)
        }

        const projectToUpdate = { ...project }

        Promise.all([
          loadNotificationsAndMacros(project.id),
          loadNotificationTopics(project.id),
          loadSegments(project.id),
          loadUserCustomProperties(project.id),
        ]).then(() => {
          projectToUpdate.fullyLoaded = true
          updateProject(projectToUpdate)

          // console.log('loaded', projectToUpdate)

          if (projectToUpdate.status === "setup" && location.pathname.indexOf('/setup') === -1) {
            history.push('/organizations/' + projectToUpdate.organizationId + '/projects/' + projectToUpdate.id + '/setup')
          }

          resolve(undefined)
        }).catch(reject)
      })
    }


    if (prevRouteProjectId.current !== routeParams.projectId) {
      prevRouteProjectId.current = routeParams.projectId
      const project = orgCtx.projects.find(x => x.id === routeParams.projectId)
      if (!project) return
      setCurrentProjectId(project.id)
      loadProject(project).then(() => {
        window.setTimeout(() => {
          watchRunningTasks(project.id)
        }, 0)
      })
    }

    return function cleanUp() {
      if (unsubscribe.current) {
        unsubscribe.current()
      }
    }
  }, [
    orgCtx,
    history,
    location.pathname,
    unsubscribe,
    watchRunningTasks,
    routeParams.projectId,
    loadNotificationsAndMacros,
    loadNotificationTopics,
    loadUserCustomProperties,
    loadSegments,
    updateProject,
  ])

  // const setSegments = (updatedProject: Project, segments: Segment[]) => {
  //   updatedProject.segments = segments
  //   updateProject(updatedProject)
  // }

  const viewUser = (userId: any) => {
    let params = QS.parse(location.search, { ignoreQueryPrefix: true })
    params['showUser'] = userId
    history.push(window.location.pathname + '?' + QS.stringify(params))
  }

  const viewOrder = (orderId: any, conversionRuleId: any) => {
    let params = QS.parse(location.search, { ignoreQueryPrefix: true })
    params['showOrder'] = conversionRuleId + '~' + orderId
    history.push(window.location.pathname + '?' + QS.stringify(params))
  }

  const viewLead = (leadId: any, conversionRuleId: any) => {
    let params = QS.parse(location.search, { ignoreQueryPrefix: true })
    params['showLead'] = conversionRuleId + '~' + leadId
    history.push(window.location.pathname + '?' + QS.stringify(params))
  }

  const viewProduct = (productId: any) => {
    let params = QS.parse(location.search, { ignoreQueryPrefix: true })
    params['showProduct'] = productId
    history.push(window.location.pathname + '?' + QS.stringify(params))
  }

  // atttach client side stuff
  const currentProject = useMemo(() => {
    const p = orgCtx.projects.find((x: Project) => x.id === currentProjectId)
    if (!p) return undefined

    p.segments = segments
    p.notifications = notifications
    p.macros = macros
    p.notificationTopics = notificationTopics
    p.userCustomProperties = userCustomProperties
    p.runningTasks = runningTasks
    return p

  }, [
    orgCtx.projects,
    currentProjectId,
    segments,
    notifications,
    macros,
    notificationTopics,
    userCustomProperties,
    runningTasks,
  ])

  if (!currentProject) {
    return <AppLayout currentOrganization={orgCtx.currentOrganization} admin={orgCtx.admin} firebaseUser={orgCtx.firebaseUser} projects={orgCtx.projects}>
      <Alert type="error" message="Project not found :(" className="margin-a-l" />
    </AppLayout>
  }

  if (currentProject.fullyLoaded === false) {
    return <AppLayout currentOrganization={orgCtx.currentOrganization} admin={orgCtx.admin} firebaseUser={orgCtx.firebaseUser} projects={orgCtx.projects}>
      <div className="margin-t-xl text-center"><Spin size="large" tip={t('loading_project', 'Loading project')} /></div>
    </AppLayout>
  } else {
    if (currentProject.status === "setup" && location.pathname.indexOf('/setup') === -1) {
      history.push('/organizations/' + orgCtx.currentOrganization.id + '/projects/' + currentProject.id + '/setup')
      return <div></div>
    }
  }

  // console.log('project', project)

  const cubejsApi = cubejs(currentProject.analyticsToken, { apiUrl: Config().analytics_endpoint })

  const params: any = QS.parse(location.search, { ignoreQueryPrefix: true })


  const projectCtx: ProjectContextValue = {
    firebaseApp: orgCtx.firebaseApp,
    firebaseAuth: orgCtx.firebaseAuth,
    firebaseUser: orgCtx.firebaseUser,
    loadingUser: orgCtx.loadingUser,
    signIn: orgCtx.signIn,
    signOut: orgCtx.signOut,
    ajaxRequest: orgCtx.ajaxRequest,

    admin: orgCtx.admin,
    setAdmin: orgCtx.setAdmin,

    organizations: orgCtx.organizations as Organization[],
    setOrganizations: orgCtx.setOrganizations,
    setCurrentOrganizationId: orgCtx.setCurrentOrganizationId,
    currentOrganization: orgCtx.currentOrganization,
    updateOrganization: orgCtx.updateOrganization,
    projects: orgCtx.projects as Project[],
    // setProjects: orgCtx.setProjects,
    setProjects: (projects: Project[]) => {
      // add current project joined data if missing
      projects.forEach(p => {
        if (currentProject && currentProject.id === p.id && currentProject.fullyLoaded === true && !p.fullyLoaded) {
          p.fullyLoaded = true
          p.notificationTopics = currentProject.notificationTopics
          p.notifications = currentProject.notifications
          p.macros = currentProject.macros
          p.segments = currentProject.segments
          p.userCustomProperties = currentProject.userCustomProperties
          // console.log('updated ', p)
        }
      })
      // console.log('projects are', projects)
      orgCtx.setProjects(projects)
    },


    setCurrentProjectId: setCurrentProjectId,
    currentProject: currentProject,
    updateProject: updateProject,
    refreshNotificationsAndMacros: loadNotificationsAndMacros.bind(null, currentProjectId),
    refreshNotificationTopics: loadNotificationTopics.bind(null, currentProjectId),
    refreshSegments: loadSegments.bind(null, currentProjectId),
    demoTask: demoTask,
    cubejsApi: cubejsApi,

    viewUser: viewUser,
    viewOrder: viewOrder,
    viewLead: viewLead,
    // viewCompany: viewCompany,
    viewProduct: viewProduct,
  }

  const projectLayout = {
    project: currentProject,
    userCustomProperties: currentProject.userCustomProperties,
    macros: currentProject.macros,
    notifications: currentProject.notifications,
    refreshNotificationsAndMacros: loadNotificationsAndMacros.bind(null, currentProjectId),
    // refreshNotificationsAndMacros: () => {
    //   return loadNotificationsAndMacros({ ...currentProject })
    // },
    notificationTopics: currentProject.notificationTopics,
    refreshNotificationTopics: loadNotificationTopics.bind(null, currentProjectId),
    // refreshNotificationTopics: () => {
    //   loadNotificationTopics({ ...currentProject })
    // },
    segments: currentProject.segments,
    refreshSegments: loadSegments.bind(null, currentProjectId),
    // refreshSegments: refreshSegments,
    setSegments: setSegments,
    viewUser: viewUser,
    viewOrder: viewOrder,
    viewLead: viewLead,
    // viewCompany: viewCompany,
    viewProduct: viewProduct,
    cubejsApi: cubejsApi
  }

  let externalOrderId, externalLeadId, conversionRuleId

  if (params.showOrder) {
    const bits = params.showOrder.split('~')
    conversionRuleId = bits[0] || undefined
    externalOrderId = bits[1] || undefined
  }

  if (params.showLead) {
    const bits = params.showLead.split('~')
    conversionRuleId = bits[0] || undefined
    externalLeadId = bits[1] || undefined
  }

  return <ProjectContext.Provider value={projectCtx}>
    {params.showUser && params.showUser !== '' && <ShowUser
      app={props.app}
      userId={params.showUser}
      projectLayout={projectLayout}
      history={history}
      location={location}
    />}

    {externalOrderId && conversionRuleId && <ShowOrder
      app={props.app}
      externalId={externalOrderId}
      conversionRuleId={conversionRuleId}
      projectLayout={projectLayout}
      history={history}
      location={location}
    />}

    {externalLeadId && conversionRuleId && <ShowLead
      app={props.app}
      externalId={externalLeadId}
      conversionRuleId={conversionRuleId}
      projectLayout={projectLayout}
      history={history}
      location={location}
    />}

    {demoTask && <Modal
      title={t('generating_demo', "Generating demo...")}
      centered={true}
      closable={false}
      footer={null}
      maskClosable={false}
      visible={true}
    >
      <p>{t('generating_demo_info', "Please wait while we generate a complete demo environment, this operation can take about two minutes...")}</p>
      <Progress percent={demoTask.offset ? Math.round((demoTask.offset * 100) / 60) : 2} status="active" />
    </Modal>}

    <Switch>
      <Route path="/organizations/:organizationId/projects/:projectId/test" render={(routerProps) => <Test app={props.app} {...routerProps} organizationLayout={props.organizationLayout} projectLayout={projectLayout} />} />

      <Route exact path="/organizations/:organizationId/projects/:projectId" render={() => <ProjectsDashboard />} />
      <Route path="/organizations/:organizationId/projects/:projectId/analytics/web" render={() => <AnalyticsWeb />} />
      <Route path="/organizations/:organizationId/projects/:projectId/analytics/segments" render={(routerProps) => <AnalyticsSegments app={props.app} {...routerProps} organizationLayout={props.organizationLayout} projectLayout={projectLayout} />} />
      <Route path="/organizations/:organizationId/projects/:projectId/analytics/notifications" render={(routerProps) => <AnalyticsNotifications app={props.app} {...routerProps} organizationLayout={props.organizationLayout} projectLayout={projectLayout} />} />
      <Route path="/organizations/:organizationId/projects/:projectId/analytics/media-partners" render={(routerProps) => <AnalyticsMediaPartners app={props.app} {...routerProps} organizationLayout={props.organizationLayout} projectLayout={projectLayout} />} />

      <Route path="/organizations/:organizationId/projects/:projectId/users" render={(routerProps) => <UsersList app={props.app} {...routerProps} organizationLayout={props.organizationLayout} projectLayout={projectLayout} />} />
      {/* <Route path="/organizations/:organizationId/projects/:projectId/companies/:companyId" render={(routerProps) => <CompaniesShow app={props.app} {...routerProps} organizationLayout={props.organizationLayout} projectLayout={projectLayout} />} /> */}
      {/* <Route path="/organizations/:organizationId/projects/:projectId/companies" render={(routerProps) => <CompaniesList app={props.app} {...routerProps} organizationLayout={props.organizationLayout} projectLayout={projectLayout} />} /> */}
      <Route path="/organizations/:organizationId/projects/:projectId/orders" render={(routerProps) => <OrdersList app={props.app} {...routerProps} organizationLayout={props.organizationLayout} projectLayout={projectLayout} />} />
      <Route path="/organizations/:organizationId/projects/:projectId/leads" render={() => <LeadsList />} />
      <Route path="/organizations/:organizationId/projects/:projectId/products" render={(routerProps) => <ProductsList app={props.app} {...routerProps} organizationLayout={props.organizationLayout} projectLayout={projectLayout} />} />
      <Route path="/organizations/:organizationId/projects/:projectId/notifications" render={(routerProps) => <Notifications />} />
      <Route path="/organizations/:organizationId/projects/:projectId/file-manager" render={(_routerProps) => <FileManagerPage />} />

      <Route path="/organizations/:organizationId/projects/:projectId/automation/workflows" render={(routerProps) => <WorkflowsList app={props.app} {...routerProps} organizationLayout={props.organizationLayout} projectLayout={projectLayout} />} />
      <Route path="/organizations/:organizationId/projects/:projectId/automation/segments-sync" render={(routerProps) => <SegmentsSync app={props.app} {...routerProps} organizationLayout={props.organizationLayout} projectLayout={projectLayout} />} />

      <Route path="/organizations/:organizationId/projects/:projectId/media-partners" render={(routerProps) => <MediaPartners app={props.app} {...routerProps} organizationLayout={props.organizationLayout} projectLayout={projectLayout} />} />
      <Route path="/organizations/:organizationId/projects/:projectId/commission-groups" render={(routerProps) => <CommissionGroups app={props.app} {...routerProps} organizationLayout={props.organizationLayout} projectLayout={projectLayout} />} />
      <Route path="/organizations/:organizationId/projects/:projectId/commissions" render={(routerProps) => <Commissions app={props.app} {...routerProps} organizationLayout={props.organizationLayout} projectLayout={projectLayout} />} />
      <Route path="/organizations/:organizationId/projects/:projectId/payments" render={(routerProps) => <Payments app={props.app} {...routerProps} organizationLayout={props.organizationLayout} projectLayout={projectLayout} />} />

      <Route path="/organizations/:organizationId/projects/:projectId/channels" render={(routerProps) => <ChannelsList app={props.app} {...routerProps} organizationLayout={props.organizationLayout} projectLayout={projectLayout} />} />
      <Route path="/organizations/:organizationId/projects/:projectId/domains" render={(routerProps) => <DomainsList app={props.app} {...routerProps} organizationLayout={props.organizationLayout} projectLayout={projectLayout} />} />
      <Route path="/organizations/:organizationId/projects/:projectId/setup" render={(routerProps) => <ProjectsSetup app={props.app} {...routerProps} organizationLayout={props.organizationLayout} projectLayout={projectLayout} />} />
      <Route path="/organizations/:organizationId/projects/:projectId/integrations" render={(routerProps) => <IntegrationsList app={props.app} {...routerProps} organizationLayout={props.organizationLayout} projectLayout={projectLayout} />} />
      <Route path="/organizations/:organizationId/projects/:projectId/conversion-rules" render={(routerProps) => <ConversionRulesList app={props.app} {...routerProps} organizationLayout={props.organizationLayout} projectLayout={projectLayout} />} />
      <Route path="/organizations/:organizationId/projects/:projectId/settings" render={(routerProps) => <ProjectsSettings app={props.app} {...routerProps} organizationLayout={props.organizationLayout} projectLayout={projectLayout} />} />
      <Route path="/organizations/:organizationId/projects/:projectId/tasks" render={() => <TasksList />} />
      <Route path="/organizations/:organizationId/projects/:projectId/logs" render={(routerProps) => <LogsList app={props.app} {...routerProps} organizationLayout={props.organizationLayout} projectLayout={projectLayout} />} />

      <Route render={() => <div>Route not found :(</div>} />
    </Switch>
  </ProjectContext.Provider>
}

export default ProjectIndex