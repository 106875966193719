import React, { Component } from 'react'

// import { Table, Tag, Button, Tooltip, DatePicker, Input } from 'antd';
import t from '../../utils/translate'
// import Formatters from '../../utils/formatters'
// import Moment from 'moment-timezone'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import QS from 'qs'
import _ from 'lodash'
import AppLayout from 'components/app/layout';

const defaultParams = {
    limit: 30,
    skip: 0,
    sortKey: 'createdAt',
    sortOrder: 'descend',
}

class MediaPartnersPayments extends Component<any, any> {
    _isMounted = false;

    constructor(props: any) {
        super(props)

        this.state = {
            loading: false,
            payments: [],
            paymentsCount: 0,

            filterConversionIdVisible: false,
            filterPartnershipIdVisible: false,
            filterDateVisible: false,
        }

        this.fetchPayments = this.fetchPayments.bind(this)
        this.handleTableChange = this.handleTableChange.bind(this)
        this.resetFilters = this.resetFilters.bind(this)
        this.onChangeExternalID = this.onChangeExternalID.bind(this)
        this.onChangePartnershipKey = this.onChangePartnershipKey.bind(this)
        this.viewSession = this.viewSession.bind(this)
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetchPayments()

        if (window.cmAgent) {
            window.cmAgent.pageview({
                title: 'Media Partners Payments (project: ' + this.props.projectLayout.project.id + ')',
                page: this.props.location.pathname,
                props: {
                    orgId: this.props.projectLayout.project.organizationId,
                    projectId: this.props.projectLayout.project.id
                }
            })
            window.cmAgent.dispatch()
        }
    }

    componentDidUpdate(prevProps: any) {
        // check url params have changed
        if (prevProps.location.search !== this.props.location.search) {

            const params: any = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })
            const prevParams = QS.parse(prevProps.location.search, { ignoreQueryPrefix: true })

            // dont refresh on toggle order
            if ((!prevParams.showOrder && params.showOrder) || (params.showOrder !== prevParams.showOrder)) {
                return
            }

            this.fetchPayments()
        }
    }

    handleTableChange(pagination: any, filters: any, sorter: any) {
        // console.log('filters', filters);
        // console.log('pagination', pagination);
        // console.log('sorter', sorter);
        const params: any = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })

        const newParams = Object.assign({
            limit: params.limit || defaultParams.limit,
            skip: params.skip || defaultParams.skip,
            sortKey: params.sortKey || defaultParams.sortKey,
            sortOrder: params.sortOrder || defaultParams.sortOrder,
            partnershipKey: params.partnershipKey || undefined,
            dateFrom: params.dateFrom || undefined,
            dateTo: params.dateTo || undefined,
        }, {
            limit: pagination.pageSize,
            skip: (pagination.current * pagination.pageSize) - pagination.pageSize,
            sortKey: sorter.columnKey,
            sortOrder: sorter.order,
            partnershipKey: _.get(filters, 'partnershipKey[0]'),
            dateFrom: _.get(filters, 'createdAt[0]'),
            dateTo: _.get(filters, 'createdAt[1]'),
        })

        this.updateQuery(newParams)

        // this.props.history.push('/organizations/'+this.props.organizationLayout.organization.id+'/projects/'+this.props.projectLayout.project.id+'/payments?'+QS.stringify(newParams, { indices: false }));
    }

    onChangeExternalID(value: string) {
        this.updateQuery({
            skip: 0,
            externalId: (value === '') ? undefined : value
        })
        this.setState({ filterConversionIdVisible: false })
    }

    onChangePartnershipKey(value: string) {
        this.updateQuery({
            skip: 0,
            partnershipKey: (value === '') ? undefined : value
        })
        this.setState({ filterPartnershipIdVisible: false })
    }

    resetFilters() {
        this.props.history.push('/organizations/' + this.props.organizationLayout.organization.id + '/payments?' + QS.stringify(defaultParams, { indices: false }));
    }

    updateQuery(update: any) {
        const params: any = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })

        let query = {
            limit: params.limit || defaultParams.limit,
            skip: params.skip || defaultParams.skip,
            dateFrom: params.dateFrom || undefined,
            dateTo: params.dateTo || undefined,
            externalId: params.externalId || undefined,
            channelId: params.channelId || undefined,
            partnershipKey: params.partnershipKey || undefined,
            sortKey: params.sortKey || defaultParams.sortKey,
            sortOrder: params.sortOrder || defaultParams.sortOrder,
        }

        this.props.history.push('/organizations/' + this.props.organizationLayout.organization.id + '/payments?' + QS.stringify(_.assign(query, update), { indices: false }));
    }

    fetchPayments() {

        // if (this.state.loading === true) {
        //     return
        // }

        // this.setState({ loading: true })

        // const params: any = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })

        // let query = {
        //     organizationId: this.props.organizationLayout.organization.id,
        //     externalId: params.externalId || undefined,
        //     channelId: params.channelId || undefined,
        //     partnershipKey: params.partnershipKey || undefined,
        //     dateFrom: params.dateFrom || undefined,
        //     dateTo: params.dateTo || undefined,
        //     limit: params.limit || defaultParams.limit,
        //     skip: params.skip || defaultParams.skip,
        //     sortKey: params.sortKey || defaultParams.sortKey,
        //     sortOrder: params.sortOrder || defaultParams.sortOrder,
        //     timezone: this.props.app.state.admin.timezone,
        // }

        // this.props.app.ajaxRequest({
        //     method: 'get',
        //     url: '/mediaPartners.payments',
        //     params: query
        // }, (errorMessage: any, response: any) => {

        //     if (errorMessage) {
        //         this.props.app.addMessage('error', errorMessage)
        //         // this.setState({loading: false})
        //         return
        //     }

        //     // console.log('res', response.data);

        //     if (this._isMounted) {
        //         this.setState({
        //             payments: response.data.payments || [],
        //             paymentsCount: response.data.paymentsCount,
        //             loading: false
        //         })
        //     }
        // })
    }

    viewSession(record: any) {
        // TODO
    }

    render() {
        // const params: any = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })

        // join partnership with hit
        // this.state.payments.forEach((x: any, index: any) => {
        //     this.state.payments[index].partnership = this.props.organizationLayout.organization.partnerships.find((y: any) => y.partnershipId === x.paymentPartnershipId && x.projectId === y.advertiserProjectId)
        //     this.state.payments[index].channel = this.state.payments[index].partnership.channels.find((y: any) => y.id === x.paymentChannelId)
        // })

        return <AppLayout currentOrganization={this.props.organizationLayout.organization} admin={this.props.app.state.admin} firebaseUser={this.props.app.state.firebaseUser} projects={this.props.organizationLayout.projects}>

            <h1>{t('payments', "Payments")}</h1>

            Coming soon...
            {/* <Table
                    dataSource={this.state.payments}
                    rowKey="id"
                    className="edge-table block"
                    onChange={this.handleTableChange}
                    loading={this.state.loading}
                    pagination={{
                        position: ['bottomCenter'],
                        pageSize: defaultParams.limit,
                        total: this.state.paymentsCount
                    }}
                    locale={{
                        'emptyText': <span>
                            {t('no_data', "No data")}
                            {(params.externalId || params.channelId || params.dateFrom || params.dateTo) && <span className="padding-l-s">&bull; <span className="padding-l-s link" onClick={this.resetFilters}>
                                {t('reset_filters', "Reset filters")}
                            </span></span>}
                        </span>
                    }}
                    columns={[
                        {
                            title: 'Session ID',
                            key: 'sessionId',
                            render: (record: any) => {
                                if (record.id.length > 15) {
                                    return <Tooltip title={record.id}><span className="size-12">{_.truncate(record.id, { length: 16, omission: '...' })}</span></Tooltip>
                                }
                                return <span className="size-12">{record.id}</span>
                            }
                        },
                        {
                            title: t('partnership', 'Partnership'),
                            key: 'partnershipKey',
                            filters: this.props.organizationLayout.organization.partnerships.map((p: any) => {
                              return {
                                text: p.advertiserProjectName+' - '+p.partnershipId,
                                value: p.advertiserProjectId+'~'+p.partnershipId
                              }
                            }),
                            filterMultiple: false,
                            onFilter: (value, record) => record.projectId + '_' + record.paymentPartnershipId === value,
                            filteredValue: (params.partnershipKey && params.partnershipKey !== '') ? [params.partnershipKey] : [],
                            render: (record: any) => record.partnership.advertiserProjectName + ' ' + record.paymentPartnershipId
                        },
                        {
                            title: t('channel', "Channel"),
                            key: 'channelId',
                            // sortOrder: params.sortKey === 'channelId' ? params.sortOrder : undefined,
                            // sorter: (a: any, b: any) => {
                            //   if (a.paymentPartnershipId < b.paymentPartnershipId) { return -1 }
                            //   if (a.paymentPartnershipId > b.paymentPartnershipId) { return 1 }
                            //   return 0
                            // },
                            // filterDropdown: (
                            //   <div className="custom-filter-dropdown">
                            //     <Input.Search
                            //       placeholder="Search partnership ID"
                            //       defaultValue={params.channelId}
                            //       enterButton
                            //       allowClear
                            //       onSearch={this.onChangePartnershipKey}
                            //     />
                            //   </div>
                            // ),
                            // filterDropdownVisible: this.state.filterPartnershipIdVisible,
                            // onFilterDropdownVisibleChange: visibl(e: any) => this.setState({ filterPartnershipIdVisible: visible }),
                            // filteredValue: (params.channelId && params.channelId !== '') ? [params.channelId] : [],
                            render: (record: any) => record.channel ? record.channel.name : '-'
                        },
                        {
                            title: 'Conversion',
                            key: 'externalId',
                            sortOrder: params.sortKey === 'externalId' ? params.sortOrder : undefined,
                            sorter: (a: any, b: any) => {
                                if (a.conversionExternalId < b.conversionExternalId) { return -1 }
                                if (a.conversionExternalId > b.conversionExternalId) { return 1 }
                                return 0
                            },
                            filterDropdown: (
                                <div className="custom-filter-dropdown">
                                    <Input.Search
                                        placeholder="Search conversion ID"
                                        defaultValue={params.externalId}
                                        enterButton
                                        allowClear
                                        onSearch={this.onChangeExternalID}
                                    />
                                </div>
                            ),
                            filterDropdownVisible: this.state.filterConversionIdVisible,
                            onFilterDropdownVisibleChange: visibl(e: any) => this.setState({ filterConversionIdVisible: visible }),
                            filteredValue: (params.externalId && params.externalId !== '') ? [params.externalId] : [],
                            render: (record: any) => {
                                if (!record.conversionExternalId) return ''
                                console.log('record', record);
                                let crName = ''
                                if (record.channel?.customCommissionSystem) {
                                    const cr = record.channel?.customCommissionSystem.conversionRules.find((x: any) => x.conversionRuleId === record.conversionRuleId)
                                    if (cr) crName = cr.conversionRuleName
                                }
                                if (record.channel?.paymentGroup.paymentSystem) {
                                    const cr = record.channel?.paymentGroup.paymentSystem.conversionRules.find((x: any) => x.conversionRuleId === record.conversionRuleId)
                                    if (cr) crName = cr.conversionRuleName
                                }
                                return <span>
                                    <div className="link" onClick={this.viewSession.bind(null, record)}>{record.conversionExternalId.length > 15 ?
                                        <Tooltip title={record.conversionExternalId}>{_.truncate(record.conversionExternalId, { length: 16, omission: '...' })}</Tooltip>
                                        : record.conversionExternalId
                                    }
                                    </div>
                                    <small>{crName} - {record.conversionRevenue && <span>{Formatters.currency(record.partnership.advertiserCurrency, record.conversionRevenue)}</span>}</small>
                                </span>
                            }
                        },
                        {
                            title: 'CPC',
                            key: 'cpc',
                            render: (record: any) => <span className="color-green">{Formatters.currency(record.partnership.advertiserCurrency, record.paymentCpc)}</span>
                        },
                        {
                            title: 'CPA / CPL',
                            key: 'cpa',
                            render: (record: any) => <span className="color-green">{Formatters.currency(record.partnership.advertiserCurrency, record.paymentAmount)}</span>
                        },
                        {
                            title: 'Date',
                            key: 'createdAt',
                            filterDropdown: (
                                <div className="custom-filter-dropdown">
                                    <DatePicker
                                        style={{ width: '100%', marginBottom: '7px' }}
                                        defaultValue={(params.dateFrom) ? Moment(params.dateFrom, 'YYYY-MM-DD') : undefined}
                                        disabledDate={(date: any) => {
                                            return date && date.isAfter(Moment())
                                        }}
                                        format="MMM Do YYYY"
                                        placeholder="Start date"
                                        onChange={(momentDate) => {
                                            this.updateQuery({
                                                dateFrom: (momentDate) ? momentDate.format('YYYY-MM-DD') : undefined
                                            })
                                        }} />

                                    <DatePicker
                                        style={{ width: '100%' }}
                                        defaultValue={(params.dateTo) ? Moment(params.dateTo, 'YYYY-MM-DD') : undefined}
                                        disabledDate={(date: any) => {
                                            return date && date.isAfter(Moment())
                                        }}
                                        format="MMM Do YYYY"
                                        placeholder="End date"
                                        onChange={(momentDate) => {
                                            this.updateQuery({
                                                dateTo: (momentDate) ? momentDate.format('YYYY-MM-DD') : undefined
                                            })
                                        }} />
                                </div>
                            ),
                            filterDropdownVisible: this.state.filterDateVisible,
                            onFilterDropdownVisibleChange: visibl(e: any) => this.setState({ filterDateVisible: visible }),
                            filteredValue: (params.dateFrom || params.dateTo) ? [params.dateFrom, params.dateTo] : [],
                            sortOrder: params.sortKey === 'createdAt' ? params.sortOrder : undefined,
                            sorter: (a: any, b: any) => {
                                if (a.createdAt < b.createdAt) { return -1 }
                                if (a.createdAt > b.createdAt) { return 1 }
                                return 0
                            },
                            render: (record: any) => <small>{Moment(record.createdAt).tz('UTC').format('lll')}</small>
                        },
                        {
                            title: t('status', "Status"),
                            key: 'status',
                            render: (record: any) => {
                                // console.log('record', record);
                                if (record.paymentPaymentId) {
                                    return <Tag color="purple">{t('paid', "Paid")}</Tag>
                                }
                                let label = <Tag color="green">{t('approved', "Approved")}</Tag>

                                if (record.paymentStatus === 'waiting') {
                                    label = <Tag color="orange">{t('waiting', "Waiting")}</Tag>
                                }
                                if (record.paymentStatus === 'cancelled') {
                                    label = <Tooltip title={record.paymentCancelledFor}><Tag color="red">{t('cancelled', "Cancelled")}</Tag></Tooltip>
                                }

                                if (record.paymentLockedAt && Moment(record.paymentLockedAt).tz('UTC').isAfter(Moment())) {
                                    return <span>{label} <div><small>{Moment(record.paymentLockedAt).tz('UTC').fromNow()}</small></div></span>
                                }

                                return label
                            }
                        },
                        {
                            key: 'actions',
                            title: <span className="pull-right"><Button type="ghost" size="small" onClick={this.fetchPayments} disabled={this.state.loading}><FontAwesomeIcon icon="sync-alt" spin={this.state.loading} /></Button></span>,
                            className: 'text-right',
                            render: (record: any) => <Button.Group>
                            </Button.Group>
                        },
                    ]}
                /> */}
        </AppLayout>
    }
}

export default MediaPartnersPayments