import React, { Component } from 'react'

import { Spin } from 'antd';
import t from '../../utils/translate'
import Cookies from '../../utils/cookies'
import QS from 'qs'


class IntegrationsAmazonRedirectUri extends Component<any, any> {

    componentDidMount() {
        const params: any = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })

        // retrive state from cookie
        let state: any = Cookies.getItem('amazon_oauth_state')

        if (!state) {
            this.props.addMessage('error', 'Invalid OAuth state, please reconnect.')
            return
        }

        state = JSON.parse(state)

        if (params.state !== '' + state.ts) {
            this.props.addMessage('error', 'Invalid OAuth state, please reconnect.')
            return
        }

        this.props.app.ajaxRequest({
            method: 'post',
            url: '/integrations.connect',
            data: {
                projectId: state.pid,
                integrationId: state.iid,
                amazon: {
                    endpoint: state.end,
                    oauthCode: params.spapi_oauth_code,
                    sellingPartnerId: params.selling_partner_id,
                }
            }
        }, (errorMessage: any) => {

            if (errorMessage) {
                this.props.app.addMessage('error', errorMessage)
                return
            }

            Cookies.removeItem('amazon_oauth_state', '/', undefined)

            this.props.app.addMessage('success', t('amazon_connect_success', "Amazon has successfully been connected!"))
            this.props.history.push('/organizations/' + state.oid + '/projects/' + state.pid + '/integrations?tab=' + state.iid)
        })
    }

    handleSubmit() {

        if (this.state.loading) {
            return
        }

        this.setState({ loading: true })

    }

    render() {

        return <div className="splash">
            <img src={process.env.PUBLIC_URL + '/images/logo_h_white.png'} alt="Captain Metrics" className="splash-logo" />

            <div className="splash-content text-center">
                <h1 className="padding-b-l">{t('loading', "Loading")}</h1>
                <Spin size="large"></Spin>
            </div>
        </div>
    }
}

export default IntegrationsAmazonRedirectUri