import { useState, useEffect, useCallback } from 'react'

import { Button, Badge, Popconfirm, Input, Form, Select, Tag, InputNumber } from 'antd';
import t from '../../utils/translate'
import _ from 'lodash'
import logoSparkPost from '../../images/integrations/sparkpost.png'

const ConnectSparkPost = ({ app, projectLayout, organizationLayout, integration }: any) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()

    const onFinish = (values: any) => {
        setLoading(true)

        app.ajaxRequest({
            method: 'post',
            url: '/integrations.connect',
            data: {
                projectId: projectLayout.project.id,
                integrationId: integration.id,
                sparkPost: values,
            }
        }, (errorMessage: any, response: any) => {

            if (errorMessage) {
                app.addMessage('error', errorMessage)
                setLoading(false)
                return
            }

            let projects = _.filter(organizationLayout.projects, (p: any) => p.id !== response.data.project.id)
            projects.push(response.data.project)
            organizationLayout.setProjects(projects)

            app.addMessage('success', t('sparkpost_connect_success', "SparkPost has successfully been connected!"))
            setLoading(false)
        })
    }

    return <div style={{ width: '500px', margin: '32px auto' }}>
        <div className="paper padding-a-xl text-center">
            <img src={logoSparkPost} alt="" style={{ height: '150px' }} />

            <Form form={form} initialValues={{ endpoint: 'https://api.sparkpost.com' }} layout="vertical" onFinish={onFinish}>
                <Form.Item name="endpoint" label={t('api_endpoint', "API endpoint")} rules={[{ required: true, type: 'string' }]}>
                    <Select>
                        <Select.Option value="https://api.sparkpost.com">https://api.sparkpost.com</Select.Option>
                        <Select.Option value="https://api.eu.sparkpost.com">https://api.eu.sparkpost.com</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item name="apiKey" label={t('enter_your_api_key', "Enter your API key")} rules={[{ required: true, type: 'string' }]}>
                    <Input.Password placeholder="" />
                </Form.Item>

                <Form.Item name="subaccountId" label={t('subaccount_id', "Subaccount ID")} rules={[{ required: false, type: 'number' }]}>
                    <InputNumber style={{ width: '100%' }} />
                </Form.Item>

                <Button size="large" type="primary" htmlType="submit" loading={loading}>{t('confirm', "Confirm")}</Button>
            </Form>
        </div>
    </div>
}

const IntegrationsSparkPost = ({ app, organizationLayout, projectLayout, integration, disconnect }: any) => {

    const [webhooks, setWebhooks] = useState<any>({})

    const [loading, setLoading] = useState(false)

    const listConnectedWebhooks = useCallback(() => {
        setLoading(true)

        app.ajaxRequest({
            method: 'get',
            url: '/integrations.listSparkPostWebhooks',
            params: {
                projectId: projectLayout.project.id,
                integrationId: integration.id
            }
        }, (errorMessage: any, response: any) => {

            if (errorMessage) {
                app.addMessage('error', errorMessage)
                setLoading(false)
                return
            }

            const webhooks = {
                bounce: false,
                delivery: false,
                spam_complaint: false,
                out_of_band: false,
                policy_rejection: false,
                click: false,
                open: false,
                initial_open: false,
                amp_click: false,
                amp_open: false,
                amp_initial_open: false,
                list_unsubscribe: false,
                link_unsubscribe: false,
            }

            _.forEach(response.data.webhooks, (hook) => {
                if (hook.events.includes('bounce')) webhooks.bounce = true
                if (hook.events.includes('delivery')) webhooks.delivery = true
                if (hook.events.includes('spam_complaint')) webhooks.spam_complaint = true
                if (hook.events.includes('out_of_band')) webhooks.out_of_band = true
                if (hook.events.includes('policy_rejection')) webhooks.policy_rejection = true
                if (hook.events.includes('click')) webhooks.click = true
                if (hook.events.includes('open')) webhooks.open = true
                if (hook.events.includes('initial_open')) webhooks.initial_open = true
                if (hook.events.includes('amp_click')) webhooks.amp_click = true
                if (hook.events.includes('amp_open')) webhooks.amp_open = true
                if (hook.events.includes('amp_initial_open')) webhooks.amp_initial_open = true
                if (hook.events.includes('list_unsubscribe')) webhooks.list_unsubscribe = true
                if (hook.events.includes('link_unsubscribe')) webhooks.link_unsubscribe = true
            })

            setWebhooks(webhooks)
            setLoading(false)
        })
    }, [app, integration.id, projectLayout.project.id])

    // did mount
    useEffect(() => {
        listConnectedWebhooks()
    }, [listConnectedWebhooks]);

    const reconnectWebhooks = () => {
        setLoading(true)

        app.ajaxRequest({
            method: 'post',
            url: '/integrations.reconnectSparkPostWebhooks',
            data: {
                projectId: projectLayout.project.id,
                integrationId: integration.id
            }
        }, (errorMessage: any, _response: any) => {

            if (errorMessage) {
                app.addMessage('error', errorMessage)
                setLoading(false)
                return
            }

            app.addMessage('success', t('reconnect_webhooks_success', "Webhooks have been successfully reconnected!"))

            setLoading(false)
            listConnectedWebhooks()
        })
    }

    return <div>
        <h2 className="margin-t-m">{t('settings', "Settings")}</h2>
        {(integration.status === 'setup') || (integration.status === 'disconnected') ?
            <ConnectSparkPost
                integration={integration}
                app={app}
                organizationLayout={organizationLayout}
                projectLayout={projectLayout}
            />
            :
            <div className="margin-a-m">
                <table className="key-value-table">
                    <tbody>
                        <tr>
                            <th style={{ width: '300px' }}>{t('integration_id', "Integration ID")}</th>
                            <td>{integration.id}</td>
                        </tr>
                        <tr>
                            <th>{t('status', "Status")}</th>
                            <td>
                                {integration.status === 'connected' && <span>
                                    <Badge status="success" /> {t('connected', "Connected")}
                                    <Popconfirm placement="top" title={t('disconnect_sparkPost_confirm', "Do you really want to disconnect SparkPost? your data won't be synced anymore!")} onConfirm={disconnect.bind(null, integration)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                                        <Button className="margin-l-m" ghost size="small" danger={true}>{t('disconnect', "Disconnect")}</Button>
                                    </Popconfirm>
                                </span>}
                            </td>
                        </tr>
                        <tr>
                            <th>{t('api_endpoint', "API endpoint")}</th>
                            <td>{integration.sparkPostSettings.endpoint}</td>
                        </tr>
                        <tr>
                            <th>{t('webhooks_connected', "Webhooks connected")}</th>
                            <td>
                                {!loading && <>
                                    <Tag color={webhooks['bounce'] ? 'green' : 'red'} className="margin-b-s">bounce</Tag>
                                    <Tag color={webhooks['delivery'] ? 'green' : 'red'} className="margin-b-s">delivery</Tag>
                                    <Tag color={webhooks['spam_complaint'] ? 'green' : 'red'} className="margin-b-s">spam_complaint</Tag>
                                    <Tag color={webhooks['out_of_band'] ? 'green' : 'red'} className="margin-b-s">out_of_band</Tag>
                                    <Tag color={webhooks['policy_rejection'] ? 'green' : 'red'} className="margin-b-s">policy_rejection</Tag>
                                    <Tag color={webhooks['click'] ? 'green' : 'red'} className="margin-b-s">click</Tag>
                                    <Tag color={webhooks['open'] ? 'green' : 'red'} className="margin-b-s">open</Tag>
                                    <Tag color={webhooks['initial_open'] ? 'green' : 'red'} className="margin-b-s">initial_open</Tag>
                                    <Tag color={webhooks['amp_click'] ? 'green' : 'red'} className="margin-b-s">amp_click</Tag>
                                    <Tag color={webhooks['amp_open'] ? 'green' : 'red'} className="margin-b-s">amp_open</Tag>
                                    <Tag color={webhooks['amp_initial_open'] ? 'green' : 'red'} className="margin-b-s">amp_initial_open</Tag>
                                    <Tag color={webhooks['list_unsubscribe'] ? 'green' : 'red'} className="margin-b-s">list_unsubscribe</Tag>
                                    <Tag color={webhooks['link_unsubscribe'] ? 'green' : 'red'} className="margin-b-s">link_unsubscribe</Tag>
                                </>}
                            </td>
                        </tr>
                        <tr>
                            <th>{t('actions', "Actions")}</th>
                            <td>
                                <Button loading={loading} type="ghost" onClick={reconnectWebhooks}>{t('reconnect_webhooks', "Reconnect webhooks")}</Button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        }
    </div>
}

export default IntegrationsSparkPost