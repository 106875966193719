import React, { Component } from 'react'

import { LoadingOutlined } from '@ant-design/icons';

import { Button, Badge, Popconfirm, Select, Modal } from 'antd';
import t from '../../utils/translate'
import _ from 'lodash'
// import Doc from '../docs/_doc'


class IntegrationsFacebook extends Component<any, any> {
  _isMounted = false;

  constructor(props: any) {
    super(props)

    this.state = {
      loadingConnect: false,
      loadingAccounts: true,
      accounts: [] 
    }

    this.openPopup = this.openPopup.bind(this)
    this.onSetAccount = this.onSetAccount.bind(this)
    this.fetchFacebookAccounts = this.fetchFacebookAccounts.bind(this)
    // this.checkLoginState = this.checkLoginState.bind(this)
  }

  componentDidMount() {
    this._isMounted = true;
    // load accounts tree
    if (this.props.integration.status === 'connected') {
      this.fetchFacebookAccounts()
    }
  }

  componentDidUpdate(prevProps: any){
    if (prevProps.integration.status !== this.props.integration.status &&  this.props.integration.status === 'connected') {
      this.fetchFacebookAccounts()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
    // window.FB.Event.unsubscribe('auth.authResponseChange', this.checkLoginState)
  }

  // checkLoginState(event) {
  //   if (event.authResponse) {
  //     console.log('authResponse', event.authResponse);
  //     // event.authResponse.accessToken);
  //   }
  // }

  fetchFacebookAccounts() {
    this.props.app.ajaxRequest({
      method: 'get',
      url: '/integrations.listFacebookAccounts',
      params: {
        projectId: this.props.projectLayout.project.id,
        integrationId: this.props.integration.id
      }
    }, (errorMessage: any, response: any) => {

      this.setState({loadingAccounts: false})

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        return
      }
      // console.log('accounts', response.data);

      if (this._isMounted === true) {
        this.setState({accounts: response.data.accounts})
      }
    })
  }

  openPopup() {
    window.FB.login((e: any) => {
      if (e.authResponse && e.authResponse.accessToken) {
        const accessToken = e.authResponse.accessToken

        // console.log('accessToken', accessToken);
        this.setState({loadingConnect: true})

        this.props.app.ajaxRequest({
          method: 'post',
          url: '/integrations.getFacebookLongLivedToken',
          data: {
            projectId: this.props.projectLayout.project.id,
            integrationId: this.props.integration.id,
            accessToken: accessToken,
          }
        }, (errorMessage: any, response: any) => {

            // console.log('res data', response.data);

          if (errorMessage) {
            this.props.app.addMessage('error', errorMessage)
            this.setState({loadingConnect: false})
            return
          }

          this.props.app.ajaxRequest({
            method: 'post',
            url: '/integrations.connect',
            data: {
              projectId: this.props.projectLayout.project.id,
              integrationId: this.props.integration.id,
              facebook: {
                accessToken: response.data.accessToken
              }
            }
          }, (errorMessage: any, response: any) => {

            this.setState({loadingConnect: false})

            if (errorMessage) {
              this.props.app.addMessage('error', errorMessage)
              return
            }

            let projects = _.filter(this.props.organizationLayout.projects, (p: any) => p.id !== response.data.project.id)
            projects.push(response.data.project)
            this.props.organizationLayout.setProjects(projects)

            this.props.app.addMessage('success', t('facebook_connect_success', "Facebook has successfully been connected!"))
          })
        })        
      }
    }, {scope: 'ads_management', auth_type: 'rerequest'})
    // ads_management: Ads Management, Business Manager API, Audience Management
    // ads_read: Insights API
  }

  onSetAccount(accountId: string) {
    // console.log('set account', accountId);
    const account = this.state.accounts.find((x: any) => x.account_id === accountId)

    if (!account) {
      console.error('Facebook account not found?')
      return
    }

    Modal.confirm({
      title: t('are_you_sure', "Are you sure?"),
      content: t('confirm_set_facebook_account', "You are about to link your Facebook Ad account {name} with this projet.", {name: account.name}),
      okText: t('confirm', "Confirm"),
      cancelText: t('cancel', "Cancel"),
      onOk: () => {
        this.setState({loadingAccounts: true})

        this.props.app.ajaxRequest({
          method: 'post',
          url: '/integrations.setFacebookAccount',
          data: {
            projectId: this.props.projectLayout.project.id,
            integrationId: this.props.integration.id,
            accountId: account.account_id,
            accountName: account.name,
            timezone: account.timezone_name,
            currency: account.currency,
          }
        }, (errorMessage: any, response: any) => {

          this.setState({loadingAccounts: false})

          if (errorMessage) {
            this.props.app.addMessage('error', errorMessage)
            return
          }

          let projects = _.filter(this.props.organizationLayout.projects, (p: any) => p.id !== response.data.project.id)
          projects.push(response.data.project)
          this.props.organizationLayout.setProjects(projects)

          this.props.app.addMessage('success', t('facebook_set_account_success', "Your Facebook account has successfully been linked to your project!"))
        })
      }
    })
  }


  render() {

    return (
      <div>
        <h2 className="margin-t-m">{t('settings', "Settings")}</h2>
        {(this.props.integration.status === 'setup') || (this.props.integration.status === 'disconnected') ? 
          <div style={{width: '500px', margin: '32px auto'}}>
            <div className="paper padding-a-xl text-center">
              <Button type="primary" size="large" style={{backgroundColor: '#4267b2'}} loading={this.state.loadingConnect} onClick={this.openPopup}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 216 216" style={{verticalAlign: 'middle', marginBottom: '4px'}} width="24px" height="24px" className="margin-r-m" color="#FFFFFF"><path fill="#FFFFFF" d="
                  M204.1 0H11.9C5.3 0 0 5.3 0 11.9v192.2c0 6.6 5.3 11.9 11.9
                  11.9h103.5v-83.6H87.2V99.8h28.1v-24c0-27.9 17-43.1 41.9-43.1
                  11.9 0 22.2.9 25.2 1.3v29.2h-17.3c-13.5 0-16.2 6.4-16.2
                  15.9v20.8h32.3l-4.2 32.6h-28V216h55c6.6 0 11.9-5.3
                  11.9-11.9V11.9C216 5.3 210.7 0 204.1 0z"></path></svg>
            {t('continue_with_facebook', "Continue with Facebook")}</Button>
            </div>
          </div>
          :
          <div className="margin-a-m">
            <table className="key-value-table">
              <tbody>
                <tr>
                  <th style={{width: '300px'}}>{t('integration_id', "Integration ID")}</th>
                  <td>{this.props.integration.id}</td>
                </tr>
                <tr>
                  <th>{t('status', "Status")}</th>
                  <td>{this.props.integration.status === 'connected' && <span>
                    <Badge status="success" /> {t('connected', "Connected")}
                    <Popconfirm placement="top" title={t('disconnect_facebook_confirm', "Do you really want to disconnect Facebook? your data won't be synced anymore!")} onConfirm={this.props.disconnect.bind(null, this.props.integration)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                      <Button className="margin-l-m" loading={this.state.loading} ghost size="small" danger={true}>{t('disconnect', "Disconnect")}</Button>
                    </Popconfirm>
                  </span>}</td>
                </tr>
                <tr>
                  <th>{t('user', "User")}</th>
                  <td>{this.props.integration.status === 'connected' && <span>{this.props.integration.facebookSettings.user.name+' - '+this.props.integration.facebookSettings.user.id}</span>}</td>
                </tr>
                <tr>
                  <th>API scopes</th>
                  <td>{this.props.integration.facebookSettings.scopes.map((s: any, i: number) => <div key={i}>{s}</div>)}</td>
                </tr>
                <tr>
                  <th>{t('account', "Account")}</th>
                  <td>
                    <Select
                      style={{ width: 350 }}
                      value={this.props.integration.facebookSettings.accountId}
                      placeholder={this.state.loadingAccounts ? "Loading..." : t('please_select_an_account', "Please select an account")}
                      disabled={this.state.loadingAccounts}
                      suffixIcon={this.state.loadingAccounts ? <LoadingOutlined /> : undefined}
                      onChange={this.onSetAccount}
                    >
                      {this.state.accounts.map((item: any) => <Select.Option key={item.account_id} value={item.account_id}>{item.name+' - '+item.account_id}</Select.Option>)}
                    </Select>
                   </td>
                </tr>
              </tbody>
            </table>
          </div>
        }
      </div>
    );
  }
}

export default IntegrationsFacebook