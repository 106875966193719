import React, { useCallback, useState, useMemo, useRef, useEffect, PropsWithChildren } from 'react'
// import ReactDOM from 'react-dom'
import { BaseEditor, BaseRange, Node } from 'slate'
import { Slate, Editable, ReactEditor, withReact, useSlate } from 'slate-react'
import {
  Editor,
  Transforms,
  Text,
  createEditor,
  Element as SlateElement,
  Descendant,
} from 'slate'
import cn from 'classnames'
import { withHistory, HistoryEditor } from 'slate-history'
import { BoldOutlined, ItalicOutlined, UnderlineOutlined } from '@ant-design/icons';
import { Select } from 'antd'
import { ColorPickerLight } from './ColorPicker'


const LIST_TYPES = ['numbered-list', 'bulleted-list']

interface BaseProps {
  className: string
  [key: string]: unknown
}
// type OrNull<T> = T | null

export interface MyEditorProps {
  isFocused: boolean
  onChange: (data: any) => void
  styles: any
  value: any
  // type: string
  toolbarButtons: string[]
}

interface CustomEditor extends ReactEditor {
  savedSelection: BaseRange
}

type FormattedText = {
  text: string
  bold?: boolean
  italic?: boolean
  underline?: boolean
  fontSize?: number
  fontFamily?: string
  fontColor?: string
}

type CustomText = FormattedText

type ParagraphElement = {
  type: 'paragraph'
  children: CustomText[]
}

type H1Element = {
  type: 'h1'
  children: CustomText[]
}

type H2Element = {
  type: 'h2'
  children: CustomText[]
}

type H3Element = {
  type: 'h3'
  children: CustomText[]
}

type CustomElement = ParagraphElement | H1Element | H2Element | H3Element

declare module 'slate' {
  interface CustomTypes {
    Editor: CustomEditor & BaseEditor & ReactEditor & HistoryEditor
    Element: CustomElement
    Text: CustomText
  }
}

const MyEditor = (props: MyEditorProps) => {

  const [value, setValue] = useState<Descendant[]>(props.value)
  const editor = useMemo(() => withHistory(withReact(createEditor())), [])

  const renderElement = useCallback(cbProps => <Element {...cbProps} styles={props.styles} />, [props.styles])
  const renderLeaf = useCallback(cbProps => <Leaf {...cbProps} styles={props.styles} />, [props.styles])

  // set value when using history
  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  // console.log('editor', editor)
  return (
    <Slate editor={editor} value={value} onChange={value => {
      setValue(value)
    }}>
      <MyToolbar toolbarButtons={props.toolbarButtons} isFocused={props.isFocused} />
      {/* <HoveringToolbar /> */}
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        // renderElement={props => <Element {...props} />}
        // renderLeaf={props => <Leaf {...props} />}
        placeholder="Enter some text..."
        spellCheck
        onBlur={() => {
          props.onChange(value)
        }}
      />
    </Slate>
  )
}

const clearFormat = (editor, format) => {
  // console.log('clear format', format)
  Transforms.setNodes(
    editor,
    { [format]: null },
    { match: Text.isText, split: true }
  )
}

const setFontProperty = (editor, key, value) => {
  // const isActive = isFormatActive(editor, format)
  Transforms.setNodes(
    editor,
    { [key]: value },
    { match: Text.isText, split: true }
  )
}

const toggleFormat = (editor, format) => {
  const isActive = isFormatActive(editor, format)
  Transforms.setNodes(
    editor,
    { [format]: isActive ? null : true },
    { match: Text.isText, split: true }
  )
}

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: (n: Node) =>
      LIST_TYPES.includes(
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type
      ),
    split: true,
  })

  const newProperties: Partial<SlateElement> = {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  }

  Transforms.setNodes(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const isFormatActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: n => n[format] === true,
    mode: 'all',
  })
  return !!match
}

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: n =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  })

  return !!match
}

const Element = ({ attributes, children, element, styles }) => {

  const elementStyle = {
    lineHeight: 1, // match with mjml
    ...styles[element.type],
  }

  if (elementStyle.paddingControl === 'all') {
    delete elementStyle.paddingTop
    delete elementStyle.paddingRight
    delete elementStyle.paddingBottom
    delete elementStyle.paddingLeft
  }

  switch (element.type) {
    case 'h1':
      return <div {...attributes} style={elementStyle}>{children}</div>
    case 'h2':
      return <div {...attributes} style={elementStyle}>{children}</div>
    case 'h3':
      return <div {...attributes} style={elementStyle}>{children}</div>
    // case 'block-quote':
    //   return <blockquote {...attributes}>{children}</blockquote>
    // case 'bulleted-list':
    //   return <ul {...attributes} style={elementStyle}>{children}</ul>
    // case 'list-item':
    //   return <li {...attributes} style={elementStyle}>{children}</li>
    // case 'numbered-list':
    //   return <ol {...attributes} style={elementStyle}>{children}</ol>
    default:
      return <p {...attributes} style={elementStyle}>{children}</p>
  }
}

const Leaf = ({ attributes, children, leaf, styles }) => {
  // console.log('leaf', leaf)
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underlined) {
    children = <u>{children}</u>
  }

  if (leaf.fontSize) {
    children = <span style={{ fontSize: leaf.fontSize }}>{children}</span>
  }

  if (leaf.fontFamily) {
    children = <span style={{ fontFamily: leaf.fontFamily }}>{children}</span>
  }

  if (leaf.fontColor) {
    children = <span style={{ color: leaf.fontColor }}>{children}</span>
  }

  return <span {...attributes}>{children}</span>
}


const fontFamilies = [
  { label: "Arial, sans-serif", value: "Arial, sans-serif" },
  { label: "Verdana, sans-serif", value: "Verdana, sans-serif" },
  { label: "Helvetica, sans-serif", value: "Helvetica, sans-serif" },
  { label: "Georgia, serif", value: "Georgia, serif" },
  { label: "Tahoma, sans-serif", value: "Tahoma, sans-serif" },
  { label: "Lucida, sans-serif", value: "Lucida, sans-serif" },
  { label: "Trebuchet MS, sans-serif", value: "Trebuchet MS, sans-serif" },
  { label: "Times New Roman, serif", value: "Times New Roman, serif" },
]

// const fontWeights = [
//   { label: <span style={{ fontWeight: 100 }}>100</span>, value: 100 },
//   { label: <span style={{ fontWeight: 200 }}>200</span>, value: 200 },
//   { label: <span style={{ fontWeight: 300 }}>300</span>, value: 300 },
//   { label: <span style={{ fontWeight: 400 }}>400</span>, value: 400 },
//   { label: <span style={{ fontWeight: 500 }}>500</span>, value: 500 },
//   { label: <span style={{ fontWeight: 600 }}>600</span>, value: 600 },
//   { label: <span style={{ fontWeight: 700 }}>700</span>, value: 700 },
//   { label: <span style={{ fontWeight: 800 }}>800</span>, value: 800 },
//   { label: <span style={{ fontWeight: 900 }}>900</span>, value: 900 },
// ]

const MyToolbar = (props: any) => {
  const ref = useRef<HTMLDivElement | null>()
  const editor = useSlate()

  const sizes = []

  for (var i = 6; i <= 48; i++) { sizes.push({ label: i + 'px', value: i + 'px' }) }

  useEffect(() => {
    const el = ref.current

    if (!el) {
      return
    }

    if (!props.isFocused) {
      el.removeAttribute('style')
      return
    }

    // if (!ReactEditor.isFocused(editor)) {
    //   el.removeAttribute('style')
    //   return
    // }

    el.style.display = 'block'
  })

  // find if current selection has font size / family applied
  let fontSizeValue = undefined
  let fontFamilyValue = undefined
  let fontColorValue = undefined

  const [matchFontSize] = Editor.nodes(editor, {
    match: n => (Text.isText(n) && n.fontSize) ? true : false,
    mode: 'all',
  })

  const [matchFontFamily] = Editor.nodes(editor, {
    match: n => (Text.isText(n) && n.fontFamily) ? true : false,
    mode: 'all',
  })

  const [matchFontColor] = Editor.nodes(editor, {
    match: n => (Text.isText(n) && n.fontColor) ? true : false,
    mode: 'all',
  })

  if (matchFontSize && matchFontSize[0] && Text.isText(matchFontSize[0]) && matchFontSize[0].fontSize) fontSizeValue = matchFontSize[0].fontSize
  if (matchFontFamily && matchFontFamily[0] && Text.isText(matchFontFamily[0]) && matchFontFamily[0].fontFamily) fontFamilyValue = matchFontFamily[0].fontFamily
  if (matchFontColor && matchFontColor[0] && Text.isText(matchFontColor[0]) && matchFontColor[0].fontColor) fontColorValue = matchFontColor[0].fontColor

  return <div ref={ref} className="cmeditor-toolbar">
    {props.toolbarButtons.includes('bold') && <FormatButton format="bold" icon={<BoldOutlined />} />}
    {props.toolbarButtons.includes('italic') && <FormatButton format="italic" icon={<ItalicOutlined />} />}
    {props.toolbarButtons.includes('underlined') && <FormatButton format="underlined" icon={<UnderlineOutlined />} />}
    {props.toolbarButtons.includes('h1') && <BlockButton format="h1" icon={<span style={{ fontSize: '15px' }}>H1</span>} />}
    {props.toolbarButtons.includes('h2') && <BlockButton format="h2" icon={<span style={{ fontSize: '15px' }}>H2</span>} />}
    {props.toolbarButtons.includes('h3') && <BlockButton format="h3" icon={<span style={{ fontSize: '15px' }}>H3</span>} />}
    {props.toolbarButtons.includes('fonts') && <>
      <Select
        style={{ width: '120px' }}
        placeholder="Font size"
        dropdownMatchSelectWidth={true}
        defaultActiveFirstOption={false}
        autoFocus={false}
        allowClear={true}
        value={fontSizeValue}
        onMouseDown={() => {
          // save last known selection
          if (!editor.savedSelection) editor.savedSelection = editor.selection
          // console.log('down')
        }}
        onBlur={() => {
          // reset selection on exit
          editor.savedSelection = undefined
        }}
        onClear={() => {
          // use editor.selection instead of editor.savedSelection because
          // onClear is triggered before onMouseDown
          if (editor.selection) {
            Transforms.select(editor, editor.selection);
            clearFormat(editor, 'fontSize')
            editor.savedSelection = undefined
            ReactEditor.focus(editor);
          }
        }}
        onChange={val => {
          if (!val) return // abort onClear
          // console.log('val', val)
          if (editor.savedSelection) {
            // console.log('editor.savedSelection', editor.savedSelection)
            Transforms.select(editor, editor.savedSelection);
            if (!val) clearFormat(editor, 'fontSize')
            else setFontProperty(editor, 'fontSize', val)
            editor.savedSelection = undefined
            ReactEditor.focus(editor);
          } else {
            // console.log('no selection')
          }
        }}
        size="small"
        options={sizes} />
      <Select
        style={{ width: '150px' }}
        placeholder="Font family"
        dropdownMatchSelectWidth={false}
        defaultActiveFirstOption={false}
        autoFocus={false}
        allowClear={true}
        value={fontFamilyValue}
        onMouseDown={() => {
          // save last known selection
          if (!editor.savedSelection) editor.savedSelection = editor.selection
        }}
        onClear={() => {
          if (editor.selection) {
            Transforms.select(editor, editor.selection);
            clearFormat(editor, 'fontFamily')
            editor.savedSelection = undefined
            ReactEditor.focus(editor);
          }
        }}
        onBlur={() => {
          // reset selection on exit
          editor.savedSelection = undefined
        }}
        onChange={val => {
          if (!val) return // abort onClear
          // console.log('change', val)
          if (editor.savedSelection) {
            Transforms.select(editor, editor.savedSelection);
            setFontProperty(editor, 'fontFamily', val)
            editor.savedSelection = undefined
            ReactEditor.focus(editor);
          }
        }}
        size="small"
        options={fontFamilies} />
      <ColorPickerLight
        className="cmeditor-toolbar-color"
        size="small"
        value={fontColorValue}
        onMouseDown={() => {
          // save last known selection
          if (!editor.savedSelection) editor.savedSelection = editor.selection
        }}
        onBlur={() => {
          // reset selection on exit
          editor.savedSelection = undefined
        }}
        onChange={(newColor) => {
          // console.log('newColor', newColor)
          if (editor.savedSelection) {
            Transforms.select(editor, editor.savedSelection);
            setFontProperty(editor, 'fontColor', newColor)
            // console.log('newColor', newColor)
            ReactEditor.focus(editor);
          }
        }} />
    </>}
    <div className="cmeditor-toolbar-overlay"></div>
  </div>
}

const FormatButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      className="cmeditor-toolbar-button"
      reversed
      active={isFormatActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleFormat(editor, format)
      }}
    >{icon}</Button>
  )
}

const BlockButton = ({ format, icon }) => {
  const editor = useSlate()

  return (
    <Button
      className="cmeditor-toolbar-button"
      reversed
      active={isBlockActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >{icon}</Button>
  )
}

const Button = React.forwardRef(
  (
    {
      className,
      active,
      reversed,
      ...props
    }: PropsWithChildren<
      {
        active: boolean
        reversed: boolean
      } & BaseProps
    >,
    ref: any
    // ref: Ref<OrNull<HTMLSpanElement>>
  ) => (
    <div
      {...props}
      ref={ref}
      className={cn('cmeditor-toolbar-button', { 'active': active })}
    />
  )
)

export default MyEditor



const renderElementInReact = (key, element, styles) => {

  let children = element.children ? element.children.map((child, k) => renderElementInReact(k, child, styles)) : (element.text || '')

  // is root elemnt
  if (element.type) {

    const elementStyle = {
      lineHeight: 1, // match with mjml automatic line height
      ...styles[element.type]
    }

    if (elementStyle.paddingControl === 'all') {
      delete elementStyle.paddingTop
      delete elementStyle.paddingRight
      delete elementStyle.paddingBottom
      delete elementStyle.paddingLeft
    }

    switch (element.type) {
      // case 'block-quote':
      //   return <blockquote {...attributes}>{children}</blockquote>
      case 'bulleted-list':
        return <ul key={key} style={elementStyle}>{children}</ul>
      case 'h1':
        return <div key={key} style={elementStyle}>{children}</div>
      case 'h2':
        return <div key={key} style={elementStyle}>{children}</div>
      case 'h3':
        return <div key={key} style={elementStyle}>{children}</div>
      case 'list-item':
        return <li key={key} style={elementStyle}>{children}</li>
      case 'numbered-list':
        return <ol key={key} style={elementStyle}>{children}</ol>
      default:
        return <p key={key} style={elementStyle}>{children}</p>
    }

  } else {
    // is text leaf
    if (element.bold) {
      children = <strong key={key}>{children}</strong>
    }

    if (element.italic) {
      children = <em key={key}>{children}</em>
    }

    if (element.underlined) {
      children = <u key={key}>{children}</u>
    }

    if (element.fontSize) {
      children = <span style={{ fontSize: element.fontSize }} key={key}>{children}</span>
    }

    if (element.fontFamily) {
      children = <span style={{ fontFamily: element.fontFamily }} key={key}>{children}</span>
    }

    if (element.fontColor) {
      children = <span style={{ color: element.fontColor }} key={key}>{children}</span>
    }

    return <span key={key}>{children}</span>
  }
}

export const EditorDataToReact = (data, styles) => {
  return data.map((el, i) => <div key={i}>{renderElementInReact(0, el, styles)}</div>)
}