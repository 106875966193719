import { Component } from 'react'

import { CheckOutlined, ExclamationCircleOutlined, PauseOutlined, DollarCircleFilled, InfoCircleFilled, TagFilled } from '@ant-design/icons';
import { Table, Popconfirm, Modal, Button, Tooltip, Badge, Tag, Popover } from 'antd';
import t from '../../utils/translate'
import Formatters from '../../utils/formatters'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import QS from 'qs'
import _ from 'lodash'
import CreateButton from './_createButton'
import InviteButton from './_inviteButton'
import CommissionSystemPopover from '../commissionGroups/_popover'
import AppLayout from 'components/app/layout';

const defaultParams = {
  limit: 30,
  skip: 0,
  sortKey: 'createdAt',
  sortOrder: 'descend',
}

const ChannelPopover = ({ channel, children }: any) => {
  return <Popover content={<div>
    <div className="subtitle padding-b-s">{t('traffic_mapping', "Traffic mapping")}</div>
    {channel.sourceMediumPaths.map((srcMed: any) => <div key={srcMed.value}>{srcMed.value}</div>)}
    <div className="subtitle padding-b-s padding-t-m">{t('voucher_code_attribution', "Voucher code attribution")}</div>
    {(!channel.voucherCodes || channel.voucherCodes.length === 0) && <div>none</div>}
    {channel.voucherCodes && channel.voucherCodes.map((voucher: any) => <div key={voucher.code}><Tag><TagFilled /> {voucher.code}</Tag> &rarr; {voucher.sourceMediumPath} {voucher.description && '(' + voucher.description + ')'}</div>)}
  </div>}>{children}</Popover>
}

class MediaPartnersList extends Component<any, any> {
  _isMounted = false;

  constructor(props: any) {
    super(props)

    this.state = {
      loading: false,
      partnerships: [],
      partnershipsCount: 0,
    }

    this.fetchPartnerships = this.fetchPartnerships.bind(this)
    this.handleTableChange = this.handleTableChange.bind(this)
    this.resetFilters = this.resetFilters.bind(this)
    this.confirmPause = this.confirmPause.bind(this)
    this.changeStatus = this.changeStatus.bind(this)
    this.initPaymentConfirm = this.initPaymentConfirm.bind(this)
    this.initPaymentRequest = this.initPaymentRequest.bind(this)
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchPartnerships()

    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Media Partners (project: ' + this.props.projectLayout.project.id + ')',
        page: this.props.location.pathname,
        props: {
          orgId: this.props.projectLayout.project.organizationId,
          projectId: this.props.projectLayout.project.id
        }
      })
      window.cmAgent.dispatch()
    }
  }

  componentDidUpdate(prevProps: any) {
    // check url params have changed
    if (prevProps.location.search !== this.props.location.search) {
      this.fetchPartnerships()
    }
  }

  handleTableChange(pagination: any, filters: any, sorter: any) {
    // console.log('filters', filters);
    // console.log('pagination', pagination);
    // console.log('sorter', sorter);
    const params: any = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })

    const newParams = _.assign({
      limit: params.limit || defaultParams.limit,
      skip: params.skip || defaultParams.skip,
      sortKey: params.sortKey || defaultParams.sortKey,
      sortOrder: params.sortOrder || defaultParams.sortOrder,
      commissionGroup: params.commissionGroup || undefined,
      // partnershipId: params.partnershipId || undefined,
      status: params.status || undefined,
    }, {
      limit: pagination.pageSize,
      skip: (pagination.current * pagination.pageSize) - pagination.pageSize,
      sortKey: sorter.columnKey,
      sortOrder: sorter.order,
      // commissionGroup: _.get(filters, 'commissionGroup[0]'),
      partnershipId: _.get(filters, 'partnershipId[0]'),
      status: _.get(filters, 'status[0]'),
    })

    this.updateQuery(newParams)

    // this.props.history.push('/organizations/'+this.props.organizationLayout.organization.id+'/projects/'+this.props.projectLayout.project.id+'/partnerships?'+QS.stringify(newParams, { indices: false }));
  }

  resetFilters() {
    this.props.history.push('/organizations/' + this.props.organizationLayout.organization.id + '/projects/' + this.props.projectLayout.project.id + '/partnerships?' + QS.stringify(defaultParams, { indices: false }));
  }

  updateQuery(update: any) {
    const params: any = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })

    let query = {
      limit: params.limit || defaultParams.limit,
      skip: params.skip || defaultParams.skip,
      sortKey: params.sortKey || defaultParams.sortKey,
      sortOrder: params.sortOrder || defaultParams.sortOrder,
      partnershipId: params.partnershipId || undefined,
      // commissionGroup: params.commissionGroup || undefined,
      status: params.status || undefined,
    }

    this.props.history.push('/organizations/' + this.props.organizationLayout.organization.id + '/projects/' + this.props.projectLayout.project.id + '/partnerships?' + QS.stringify(_.assign(query, update), { indices: false }));
  }

  fetchPartnerships() {

    if (this.state.loading === true) {
      return
    }

    this.setState({ loading: true })

    const params: any = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })

    let query: any = {
      projectId: this.props.projectLayout.project.id,
      limit: params.limit || defaultParams.limit,
      skip: params.skip || defaultParams.skip,
      sortKey: params.sortKey || defaultParams.sortKey,
      sortOrder: params.sortOrder || defaultParams.sortOrder,
    }

    if (params.partnershipId) {
      query.partnershipId = params.partnershipId
    }

    // if (params.commissionGroup) {
    //   query.commissionGroupId = params.commissionGroup
    // }

    if (params.status) {
      query.status = params.status
    }

    this.props.app.ajaxRequest({
      method: 'get',
      url: '/partnerships.list',
      params: query
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        // this.setState({loading: false})
        return
      }

      // console.log('res', response.data);

      if (this._isMounted) {
        this.setState({
          partnerships: response.data.partnerships,
          partnershipsCount: response.data.partnershipsCount,
          loading: false
        })
      }
    })
  }

  initPaymentConfirm(partnershipId: any) {
    Modal.confirm({
      title: t('new_payment_request', "New payment request"),
      content: t('new_payment_request_content', "A new payment request will be generated for “{mediaPartner}“, would you like to continue?", { mediaPartner: partnershipId }),
      onOk: this.initPaymentRequest.bind(null, partnershipId),
      okText: t('confirm', "Confirm"),
      cancelText: t('cancel', "Cancel"),
      onCancel() { },
    })
  }

  initPaymentRequest(partnershipId: any) {
    return new Promise((resolve, reject) => {

      this.props.app.ajaxRequest({
        method: 'post',
        url: '/partnershipPayments.create',
        data: {
          projectId: this.props.projectLayout.project.id,
          partnershipId: partnershipId
        }
      }, (errorMessage: any, response: any) => {

        if (errorMessage) {
          this.props.app.addMessage('error', errorMessage)
          return reject(errorMessage)
        }

        this.props.app.addMessage('success', t('payment_request_created', 'The payment request has been created!'))

        // console.log('res', response.data);

        if (this._isMounted) {
          this.setState({ loading: false })
          this.fetchPartnerships()
        }

        resolve(undefined)
      })

    }).catch((e: any) => console.log(e));
  }

  changeStatus(partnershipId: string, status: string) {
    return new Promise((resolve, reject) => {

      this.props.app.ajaxRequest({
        method: 'post',
        url: '/partnerships.changeStatus',
        data: {
          projectId: this.props.projectLayout.project.id,
          partnershipId: partnershipId,
          status: status
        }
      }, (errorMessage: any, response: any) => {

        if (errorMessage) {
          this.props.app.addMessage('error', errorMessage)
          return reject(errorMessage)
        }

        if (status === 'paused') this.props.app.addMessage('success', t('partnership_paused', 'The partnership has been paused!'))
        if (status === 'activated') this.props.app.addMessage('success', t('partnership_activated', 'The partnership has been activated!'))

        // console.log('res', response.data);

        if (this._isMounted) {
          this.setState({ loading: false })
          this.fetchPartnerships()
        }

        resolve(undefined)
      })

    }).catch((e: any) => console.log(e));
  }

  confirmPause(partnershipId: any) {

    Modal.confirm({
      title: t('confirm_pause_partnership', "Do you really want to pause this partnership?"),
      icon: <ExclamationCircleOutlined />,
      content: t('pause_partnership_info', "The Media Partner won't be attributed any new commissions"),
      okText: t('confirm', "Confirm"),
      onOk: () => {
        return this.changeStatus(partnershipId, 'paused')
      }
    })
  }

  render() {

    return <AppLayout currentProject={this.props.projectLayout.project} currentOrganization={this.props.organizationLayout.organization} admin={this.props.app.state.admin} firebaseUser={this.props.app.state.firebaseUser} projects={this.props.organizationLayout.projects}>
      <h1>
        {t('media_partners', "Media Partners")}
        <div className="actions">
          <CreateButton app={this.props.app}
            history={this.props.history}
            project={this.props.projectLayout.project}
            btnType="primary"
            btnSize="normal"
            btnContent={t('create_media_partner', "Create media partner")}
            onComplete={() => this.fetchPartnerships()}
          />
        </div>
      </h1>

      <Table
        dataSource={this.state.partnerships}
        rowKey="id"
        className="edge-table block"
        onChange={this.handleTableChange}
        loading={this.state.loading}
        pagination={{
          position: ['bottomCenter'],
          total: this.state.partnershipsCount
        }}
        columns={[
          {
            title: 'ID',
            key: 'id',
            render: (record: any) => {
              return record.id
            }
          },
          {
            title: t('media_partner', "Media partner"),
            key: 'mediaPartner',
            render: (record: any) => {
              if (record.mediaPartnerOrganizationId) return record.mediaPartnerOrganizationId
              if (record.invitationEmail) return record.invitationEmail
              return <InviteButton app={this.props.app}
                history={this.props.history}
                project={this.props.projectLayout.project}
                btnSize="small"
                btnType="primary"
                btnContent={t('invite_media_partner', "Invite media partner")}
                onComplete={() => this.fetchPartnerships()}
                partnershipId={record.id}
              />
            }
          },
          {
            title: t('status', "Status"),
            key: 'status',
            render: (record: any) => {
              if (record.status === 'activated') {
                return <Badge color="green" text={t('activated', "Activated")} />
              }
              if (record.status === 'paused') {
                return <Badge color="orange" text={t('paused', "Paused")} />
              }
              if (record.status === 'deleted') {
                return <Badge status="default" text={t('deleted', "Deleted")} />
              }
            }
          },
          {
            title: t('channels', "Channels") + ' - ' + t('commission_group', "Commission group"),
            key: 'channels',
            render: (record: any) => {
              // console.log('record', record);
              const channels = this.props.projectLayout.project.channels.filter((x: any) => x.partnershipId === record.id)

              if (channels.length === 0) return '-'

              return channels.map((ch: any) => {

                const cg = this.props.projectLayout.project.commissionGroups.find((x: any) => x.id === ch.commissionGroupId)
                const channelGroup = this.props.projectLayout.project.channelGroups.find((x: any) => x.id === ch.groupId)

                return <div key={ch.id} className="size-12 padding-b-s">
                  <ChannelPopover channel={ch}>{ch.name} <Tag className="small" color={channelGroup.color}>{channelGroup.name}</Tag></ChannelPopover> <span className="padding-r-s">-</span>
                  <CommissionSystemPopover commissionSystem={ch.hasCustomCommission ? ch.customCommissionSystem : cg.commissionSystem} currency={this.props.projectLayout.project.currency}>
                    {ch.hasCustomCommission === true && <span className="link"><InfoCircleFilled /> {t('customized', "Customized")}</span>}
                    {!ch.hasCustomCommission && <span>{!cg ? '(no group)' : <Tag className="small" color={cg.color}>{cg.name}</Tag>}</span>}
                  </CommissionSystemPopover>
                </div>
              })
            }
          },
          {
            title: t('waiting', "Waiting"),
            key: 'waiting',
            render: (record: any) => {
              return <span className="color-orange">{Formatters.currency(this.props.projectLayout.project.currency, record.commissionsWaiting || 0)}</span>
            }
          },
          {
            title: t('approved', "Approved"),
            key: 'approved',
            render: (record: any) => {
              return <span className="color-green">{Formatters.currency(this.props.projectLayout.project.currency, record.commissionsApproved || 0)}</span>
            }
          },
          {
            title: t('paid', "Paid"),
            key: 'paid',
            render: (record: any) => {
              return <span className="color-grey">{Formatters.currency(this.props.projectLayout.project.currency, record.commissionsPaid || 0)}</span>
            }
          },
          // {
          //   title: 'ID',
          //   key: 'externalId',
          //   sortOrder: params.sortKey === 'externalId' ? params.sortOrder : undefined,
          //   sorter: (a: any, b: any) => {
          //     if (a.order.conversionExternalId < b.order.conversionExternalId) { return -1 }
          //     if (a.order.conversionExternalId > b.order.conversionExternalId) { return 1 }
          //     return 0
          //   },
          //   filterDropdown: (
          //     <div className="custom-filter-dropdown">
          //       <Input.Search
          //         placeholder="Search conversion ID"
          //         defaultValue={params.externalId}
          //         enterButton
          //         allowClear
          //         onSearch={this.onChangeExternalID}
          //       />
          //     </div>
          //   ),
          //   filterDropdownVisible: this.state.filterConversionIdVisible,
          //   onFilterDropdownVisibleChange: visibl(e: any) => this.setState({ filterConversionIdVisible: visible }),
          //   filteredValue: (params.externalId && params.externalId !== '') ? [params.externalId] : [],
          //   render: (record: any) => {
          //     if (record.conversionExternalId.length > 15) {
          //       return <Tooltip title={record.conversionExternalId}>{_.truncate(record.conversionExternalId, {length: 16, omission: '...'})}</Tooltip>
          //     }
          //     return record.conversionExternalId
          //   }
          // },
          {
            key: 'actions',
            title: <span className="pull-right"><Button type="ghost" size="small" onClick={this.fetchPartnerships} disabled={this.state.loading}><FontAwesomeIcon icon="sync-alt" spin={this.state.loading} /></Button></span>,
            className: 'text-right',
            render: (record: any) => <Button.Group>
              {record.status === 'activated' && <Tooltip title={t('pause_partnership', "Pause partnership")}><Button size="small" onClick={this.confirmPause.bind(null, record.id)}><PauseOutlined /></Button></Tooltip>}
              {record.status === 'paused' && <Popconfirm title={t('confirm_activate_partnership', "Do you really want to activate this partnership?")} onConfirm={() => {
                this.setState({ loading: true });
                this.changeStatus(record.id, 'activated').then(() => this.setState({ loading: false })).catch(() => this.setState({ loading: false }))
              }} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                <Button size="small" loading={this.state.loading}><CheckOutlined /></Button>
              </Popconfirm>}
              {(record.commissionsApproved > 0) && <Tooltip title={t('generate_a_payment', "Generate a payment")}><Button size="small" onClick={this.initPaymentConfirm.bind(null, record.id)}><DollarCircleFilled /></Button></Tooltip>}
            </Button.Group>
          },
        ]}
      />

    </AppLayout>
  }
}

export default MediaPartnersList