import React, { useState } from 'react'

import t from '../../utils/translate'
import { Button, Input, Drawer, Form, Select, Switch, Divider, message } from 'antd';
import _ from 'lodash'
import { NotificationFilled, UnorderedListOutlined } from '@ant-design/icons';
import InfoRadioGroup from '../common/infoRadio'

const idRegex = /^[a-z0-9]+(-[a-z0-9]+)*$/

const SuscriptionTopicFormButton = (props: any) => {

    const [showDrawer, setShowDrawer] = useState(false)
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()

    const toggleDrawer = () => {
        setShowDrawer(!showDrawer)
    }

    const onFinish = (values: any) => {

        if (loading) return

        values.projectId = props.project.id

        setLoading(true)

        props.app.ajaxRequest({
            method: 'post',
            url: props.notificationTopic ? '/notificationTopics.update' : '/notificationTopics.create',
            data: values
        }, (errorMessage: any, _response: any) => {

            if (errorMessage) {
                message.error(errorMessage)
                setLoading(false)
                return
            }

            if (props.notificationTopic) {
                props.app.addMessage('success', t('notification_topic_updated', 'The notification topic has been updated.'))
            } else {
                form.resetFields()
                props.app.addMessage('success', t('notification_topic_created', 'The notification topic has been created.'))
            }

            setLoading(false)
            setShowDrawer(false)

            // console.log('response', response.data);

            if (window.cmAgent) {
                window.cmAgent.event({
                    label: props.segment ? 'updateNotificationTopic' : 'createNotificationTopic',
                    props: {
                        orgId: props.organizationLayout.organization.id,
                        projectId: props.project.id,
                    }
                })
                window.cmAgent.dispatch()
            }

            if (props.onComplete) {
                props.onComplete()
            }
        })
    }


    if (!showDrawer) {
        return <Button block={props.block || false} type={props.type || "default"} style={props.style || {}} size={props.size} onClick={toggleDrawer}>{props.children}</Button>
    }

    let initialValues = {}

    if (props.notificationTopic) {
        initialValues = { ...props.notificationTopic }
    }

    return <Drawer
        title={props.title}
        visible={true}
        onClose={toggleDrawer}
        className="drawer drawer-form"
        width={960}>
        <Form form={form} initialValues={initialValues} labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} layout="horizontal" className="margin-a-m margin-b-xl" onFinish={onFinish}>

            <Form.Item name="name" label={t('name', "Name")} rules={[{ required: true, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
                <Input
                    placeholder="i.e: Newsletter, Back in stock..."
                    onChange={(e: any) => {
                        if (!props.notificationTopic) {
                            form.setFieldsValue({ notificationTopicId: _.kebabCase(e.target.value) })
                        }
                    }}
                />
            </Form.Item>

            <Form.Item name="id" label="ID" rules={[{
                required: true,
                type: 'string',
                validator: (_rule, value) => {
                    if (props.notificationTopic) return Promise.resolve(undefined)
                    if (!value.match(idRegex)) {
                        return Promise.reject(t('value_should_be_written_like_this', "The value should-be-written-like-this"))
                    }
                    if (props.notificationTopics.find((x: any) => x.id === value)) {
                        return Promise.reject('This id is already used')
                    } else {
                        return Promise.resolve(undefined)
                    }
                },
            }]}>
                <Input disabled={props.notificationTopic ? true : false} placeholder="i.e: newsletter, backinstock..." />
            </Form.Item>

            <Form.Item name="kind" label={t('type_of_notifications', 'Type of notifications')} rules={[{ required: true, type: 'string' }]}>
                <InfoRadioGroup disabled={props.notificationTopic ? true : false} span={12} options={[
                    {
                        key: 'broadcast',
                        title: 'Broadcast',
                        icon: <NotificationFilled />,
                        content: <span>{t('notification_topic_broadcast_info', 'Users subscribe to a generic notification (i.e: newsletters).')}</span>,
                    },
                    {
                        key: 'collection',
                        title: t('collection_items', 'Collection items'),
                        icon: <UnorderedListOutlined />,
                        content: <span>{t('notification_topic_collection_info', 'Users subscribe to specific items in a "collection". Example: product back in stock, price drop alert...')}</span>,
                    },
                ]} />
            </Form.Item>

            <Form.Item noStyle shouldUpdate>
                {(funcs) => {
                    if (funcs.getFieldValue('kind') === 'collection') {
                        return <>
                            <Form.Item name="collectionKind" label={t('collection_type', 'Collection type')} rules={[{ required: true, type: 'string' }]}>
                                <Select disabled={props.notificationTopic ? true : false}>
                                    <Select.Option key="productCatalog" value="productCatalog">{t('product_catalog', 'Product catalog')}</Select.Option>
                                </Select>
                            </Form.Item>
                            {funcs.getFieldValue('collectionKind') === 'productCatalog' && <Form.Item name="collectionId" label={t('collection_id', 'Collection ID')} rules={[{ required: true, type: 'string' }]}>
                                <Select disabled={props.notificationTopic ? true : false}>
                                    <Select.Option key="default" value="default">default</Select.Option>
                                </Select>
                            </Form.Item>}
                            {/* 
                            {funcs.getFieldValue('collectionId') && <Form.Item
                                name="collectionEvents"
                                label={t('trigger_events', 'Trigger events')}
                                rules={[{ required: true, type: 'array' }]}
                                extra={t('trigger_collection_events_infos', 'These events can trigger an Automation Workflow automatically to notify the users who subscribed to this topic.')}>
                                <Select mode="tags" placeholder={t('press_enter_to_add_custom_value', 'Press enter to add a custom value')}>
                                    <Select.Option key="backInStock" value="backInStock">backInStock</Select.Option>
                                    <Select.Option key="priceDrop" value="priceDrop">priceDrop</Select.Option>
                                </Select>
                            </Form.Item>} */}
                        </>
                    }
                }}
            </Form.Item>

            <Form.Item
                name="doubleOptIn"
                label={t('double_opt_in', "Double Opt-in")}
                rules={[{ required: false, type: 'boolean' }]}
                valuePropName="checked"
            >
                <Switch />
            </Form.Item>

            <Form.Item noStyle shouldUpdate>
                {(funcs) => {
                    if (funcs.getFieldValue('doubleOptIn') === true) {
                        return <Form.Item
                            name="doubleOptInNotificationId"
                            label="Double Opt-in notification"
                            rules={[{ required: true, type: 'string' }]}
                        >
                            <Select>
                                {props.notifications.map((x: any) => <Select.Option key={x.id} value={x.id}>{x.name}</Select.Option>)}
                            </Select>
                        </Form.Item>
                    }
                }}
            </Form.Item>


            <Divider plain>{t('channels', "Channels")}</Divider>

            <Form.Item
                name="withEmail"
                label={t('email', "Email")}
                rules={[{ required: false, type: 'boolean' }]}
                valuePropName="checked"
            >
                <Switch />
            </Form.Item>

            <Form.Item
                name="withSMS"
                label={t('sms', "SMS")}
                rules={[{ required: false, type: 'boolean' }]}
                valuePropName="checked"
            >
                <Switch />
            </Form.Item>

            <div className="ant-drawer-footer">
                <Button style={{ marginRight: 8 }} onClick={toggleDrawer}>{t('cancel', "Cancel")}</Button>
                <Button type="primary" loading={loading} htmlType="submit">{t('confirm', "Confirm")}</Button>
            </div>
        </Form>
    </Drawer>
}


export default SuscriptionTopicFormButton


