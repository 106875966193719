import React, { Component } from 'react'

import t from '../../utils/translate'
import {
  // ArrowRightOutlined,
  // CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button, Table, Drawer, Tag, // Modal,
  Popconfirm
} from 'antd';
import ConversionRuleForm from '../conversionRules/_form'
import _ from 'lodash'


class ConversionRulesTable extends Component<any, any> {

  constructor(props: any) {
    super(props)

    this.state = {
      loading: false,
      drawerVisible: false,
      conversionRuleToEdit: undefined
    }

    this.toggleDrawer = this.toggleDrawer.bind(this)
    this.editConversionRule = this.editConversionRule.bind(this)
    this.deleteConversionRule = this.deleteConversionRule.bind(this)
  }

  editConversionRule(conversionRule: any) {
    this.setState({ drawerVisible: true, conversionRuleToEdit: conversionRule })
  }

  toggleDrawer() {
    this.setState({ drawerVisible: !this.state.drawerVisible, conversionRuleToEdit: null })
  }

  deleteConversionRule(conversionRule: any) {
    if (this.state.loading === true) {
      return
    }
    else {
      this.setState({ loading: true })
      if (this.props.setParentLoading) {
        this.props.setParentLoading(true)
      }
    }

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/conversionRules.delete',
      data: { projectId: this.props.projectLayout.project.id, id: conversionRule.id }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        if (this.props.setParentLoading) {
          this.props.setParentLoading(false)
        }
        return
      }

      let projects = _.filter(this.props.organizationLayout.projects, (p: any) => p.id !== response.data.project.id)
      projects.push(response.data.project)
      this.props.organizationLayout.setProjects(projects)

      this.props.app.addMessage('success', t('conversion_rule_deleted', "Your conversion rule has successfully been removed!"))

      if (window.cmAgent) {
        window.cmAgent.event({
          label: 'deleteConversionRule',
          props: {
            orgId: this.props.organizationLayout.organization.id,
            projectId: this.props.projectLayout.project.id
          }
        })
        window.cmAgent.dispatch()
      }

      this.setState({ loading: false })
      if (this.props.setParentLoading) {
        this.props.setParentLoading(false)
      }
    })
  }

  render() {

    // const xcrValue = (rule, kind) => {
    //   if (rule['xcr'+kind+'Enabled'] === false) {
    //     return <CloseOutlined />;
    //   }

    //   if (rule['xcr'+kind+'Kind'] === "first") {
    //     return t('the_first_conversion_rule_ever', "The first conversion rule ever")
    //   } else {
    //     return t('the_most_recent_conversion_rule', "The most recent conversion rule")
    //   }
    // }

    return (
      <div>
        {this.state.drawerVisible === true && <Drawer
          title={this.state.conversionRuleToEdit ? t('edit_conversion_rule', "Edit conversion rule") : t('new_conversion_rule', "New conversion rule")}
          visible={true}
          width={950}>
          <ConversionRuleForm {...this.props} isDrawer={true} toggleDrawer={this.toggleDrawer} edit={this.state.conversionRuleToEdit ? true : false} conversionRule={this.state.conversionRuleToEdit} onComplete={this.toggleDrawer} />
        </Drawer>}

        <Table
          pagination={false}
          dataSource={this.props.projectLayout.project.conversionRules.filter((x: any) => !x.deletedAt)}
          rowKey="id"
          className="block"
          loading={this.state.loading}
          columns={[
            {
              title: t('conversion_rules', "Conversion rules"),
              key: 'name',
              render: (rule: any) => <div>
                <div className="margin-a-m">
                  <div className="_margin-b-l">
                    <span className="size-16 semibold padding-r-m">{rule.name}</span>
                    <span className="color-grey">{rule.kind} {rule.forCompanies === true ? 'B2B' : 'B2C'}</span>
                  </div>

                  {rule.kind === 'lead' && <div>

                    <Table
                      size="small"
                      bordered={false}
                      pagination={false}
                      rowKey="id"
                      // showHeader={false}
                      className="margin-t-l"
                      columns={[
                        {
                          title: 'Stage ID',
                          key: 'id',
                          render: x => x.id
                        },
                        {
                          title: 'Label',
                          key: 'label',
                          render: x => <Tag color={x.color !== 'grey' ? x.color : undefined}>{x.label}</Tag>
                        },
                        {
                          title: 'Consider leads as',
                          key: 'status',
                          render: x => x.status
                        },
                        {
                          title: '',
                          key: 'deleted',
                          render: x => {
                            if (!x.deletedAt) return ''
                            return <span>Migrated to stage: {x.migrateToId}</span>
                          }
                        },
                      ]}
                      dataSource={rule.leadStages} />

                  </div>}

                  {/* 
                  {rule.kind !== 'lead' && <div>
                    <p style={{color: 'rgba(0,0,0,.45)'}}>
                      {rule.kind === 'order' ? t('acquisition_xcr', "Acquisition cross-conversion rules") : t('xcr', "Cross-conversion rules")}: {xcrValue(rule, 'Acquisition')}
                    </p>
                  </div>}

                  {rule.kind === 'order' && <div>
                    <p style={{color: 'rgba(0,0,0,.45)'}}>
                      {t('retention_xcr', "Retention cross-conversion rules")}: {xcrValue(rule, 'Retention')}
                    </p>                      
                  </div>}

                  {rule.kind === 'lead' && <div>
                    <p style={{color: 'rgba(0,0,0,.45)'}}>
                      {rule.kind === 'order' ? t('acquisition_xcr', "Acquisition cross-conversion rules") : t('xcr', "Cross-conversion rules")}: {xcrValue(rule, 'Acquisition')}
                    </p>
                  </div>} */}
                </div>
              </div>
            },
            {
              title: <Button type="primary" size="small" onClick={this.toggleDrawer}><PlusOutlined /> {t('new_conversion_rule', "New conversion rule")}</Button>,
              key: 'actions',
              className: 'actions',
              render: (rule: any) => <Button.Group>
                <Popconfirm placement="topRight" title={t('delete_conversion_rule_confirm', "Do you really want to delete this conversion rule?")} onConfirm={this.deleteConversionRule.bind(null, rule)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}><Button type="ghost" size="small" icon={<DeleteOutlined />} /></Popconfirm>
                <Button type="ghost" size="small" onClick={this.editConversionRule.bind(null, rule)}><EditOutlined /></Button>
              </Button.Group>
            }
          ]}
        />
      </div>
    );
  }
}

export default ConversionRulesTable