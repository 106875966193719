import {
    FileProvider,
    Item,
    Folder,
    TreeNode,
    InitProps,
} from '../core/interfaces'
import { getFolderAtPath, getParentPath } from '../core/fileManager'
import * as Firebase from "firebase/app";
import {
    // QueryConstraint,
    getFirestore,
    collection,
    // getDocs,
    FirestoreError,
    onSnapshot,
    getDoc,
    setDoc,
    doc,
    Firestore,
    CollectionReference,
    DocumentSnapshot,
    // query,
    // where,
    // limit,
    updateDoc,
    // deleteDoc,
    // runTransaction,
} from "firebase/firestore";
import Moment from 'moment-timezone'
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"

export interface FirestoreFileProviderSettings {
    FirebaseApp: Firebase.FirebaseApp
    resolveRootNodeID: () => string
    resolveUploadPath: (node: Item) => string
    collectionName?: string
    getNodeMetadata?: (node: TreeNode) => any
}

const computeHasSubFolders = (node: Folder) => {

    // init if not set
    if (node.isFolder && !node.children) {
        node.children = []
        return
    }

    node.children.forEach((sub: TreeNode) => {
        if (sub.isFolder) computeHasSubFolders(sub as Folder)
    })
}

// const getParentPath = (path: string): string => {
//     const bits = path.split('~')
//     bits.pop()
//     return bits.join('~')
// }

const addItemToFolder = (node: Folder, item: Item) => {
    if (node.path === item.path && node.isFolder) {
        if (!node.children) {
            node.children = []
        }
        node.children.push(item)
        node.childrenLoaded = true
    }
    node.children.filter(sub => sub.isFolder).forEach(sub => addItemToFolder(sub as Folder, item))
}

class FirestoreFileProvider implements FileProvider {

    firebaseApp: Firebase.FirebaseApp
    db: Firestore
    fmPath: string
    fmCollection: CollectionReference
    tree: Folder = {} as Folder
    treeLoaded = false
    setTree: Function
    goToFolder: Function
    setUploadQueue: Function
    setIsUploading: Function
    resolveRootNodeID
    resolveUploadPath
    getNodeMetadata
    isUploading = false
    unsubListener: Function

    constructor(settings: FirestoreFileProviderSettings) {
        this.firebaseApp = settings.FirebaseApp
        this.db = getFirestore(this.firebaseApp)
        this.fmPath = settings.collectionName || 'file_managers'
        this.fmCollection = collection(this.db, this.fmPath)
        this.resolveRootNodeID = settings.resolveRootNodeID
        this.resolveUploadPath = settings.resolveUploadPath
        this.getNodeMetadata = settings.getNodeMetadata
    }

    async init(props: InitProps): Promise<Folder> {

        // console.log('INIT')
        this.setTree = props.setTree
        this.goToFolder = props.goToFolder
        this.setUploadQueue = props.setUploadQueue
        this.setIsUploading = props.setIsUploading

        let resolved = false

        const rootId = this.resolveRootNodeID()
        const rootRef = doc(this.db, this.fmPath, rootId)

        return new Promise<Folder>((resolve, reject) => {

            this.unsubListener = onSnapshot(rootRef, (doc) => {
                console.log('doc exists', doc.exists())
                // console.log("updated tree: ", doc.data());

                // create home
                if (!doc.exists()) {

                    const tree: Folder = {
                        id: rootId,
                        name: 'home',
                        path: '~home',
                        isFolder: true,
                        metadata: {},
                        children: [],
                        childrenLoaded: true,
                    }

                    if (this.getNodeMetadata) {
                        tree.metadata = this.getNodeMetadata(tree)
                    }

                    setDoc(rootRef, tree).then(() => {
                        if (!resolved) {
                            resolved = true
                            resolve(tree)
                        }
                    }).catch(e => {
                        if (e.message.includes('permission')) {
                            this.unsubListener()
                        }
                        if (!resolved) {
                            resolved = true
                            return reject(e)
                        }
                    })

                } else {
                    // push the update back in the event loop, to let react updating its
                    // components on delete/update nodes
                    window.setTimeout(() => {
                        this.tree = doc.data() as Folder
                        this.setTree(this.tree)

                        if (!resolved) {
                            resolved = true
                            resolve(this.tree)
                        }
                    }, 1)
                }

            }, (e: FirestoreError) => {
                if (!resolved) {
                    resolved = true
                    reject(e)
                }
            })

            // this.loadTree().then(() => {
            //     resolve(this.tree)
            // }).catch(e => {
            //     if (e === 'no-root') {
            //         const id = this.resolveRootNodeID()

            //         const tree: Folder = {
            //             id: id,
            //             name: 'home',
            //             path: '~home',
            //             isFolder: true,
            //             metadata: {},
            //             children: [],
            //             childrenLoaded: true,
            //         }

            //         if (this.getNodeMetadata) {
            //             tree.metadata = this.getNodeMetadata(tree)
            //         }

            //         setDoc(doc(this.db, this.fmPath, tree.id), tree).then(() => {
            //             this.tree = tree
            //             this.treeLoaded = true
            //             resolve(tree)
            //         }).catch(reject)
            //     } else {
            //         reject(e)
            //     }
            // })
        })
    }

    cleanUp() {
        // unsub
        if (this.unsubListener) this.unsubListener()
    }

    async loadTree(refresh?: boolean): Promise<TreeNode> {
        return new Promise<TreeNode>((resolve, reject) => {

            // abort if folders are already loaded
            if (this.treeLoaded === true && refresh !== true) {
                resolve(this.tree)
                return
            }

            this.treeLoaded = false

            // fetch root folder

            getDoc(doc(this.db, this.fmPath, this.resolveRootNodeID())).then((docSnapshot: DocumentSnapshot) => {
                const tree = docSnapshot.data() as Folder
                if (!tree) return reject('no-root')

                computeHasSubFolders(tree)
                this.tree = tree
                this.treeLoaded = true
                resolve(this.tree)
            }).catch(e => reject(e))
        })
    }

    // load sub nodes
    async loadNodesAtPath(_path: string, _options: any, refresh?: boolean): Promise<void> {
        // console.log('listSubFolders at '+path, refresh)
        // with firestore all nodes are already loaded
        // return this.loadTree(refresh)
        return
    }

    // create folder
    async createFolder(parentPath: string, folder: Folder): Promise<void> {

        if (this.getNodeMetadata) {
            this.getNodeMetadata(folder)
        }

        folder.children = []
        folder.childrenLoaded = true

        // console.log('create', folder)

        return new Promise<void>((resolve, reject) => {
            const newTree = { ...this.tree } as Folder
            const parent = getFolderAtPath(newTree as Folder, parentPath) as Folder | undefined

            if (!parent) {
                reject('parent not found')
            } else {
                parent?.children.push(folder)
                this.saveTree(newTree).then(resolve).catch(reject)
            }
        })
    }

    async uploadFromIndex(index: number, uploadQueue: Item[]): Promise<void> {

        // console.log('uploading index ' + index, uploadQueue)
        return new Promise<void>((resolve, reject) => {

            const uploadPath = this.resolveUploadPath(uploadQueue[index])

            const storageRef = ref(getStorage(), uploadPath);

            const uploadTask = uploadBytesResumable(storageRef, uploadQueue[index].file as File);

            uploadTask.on('state_changed',
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    // console.log('Upload is ' + progress + '% done');

                    uploadQueue[index].uploadProgress = progress

                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            // console.log('Upload is running');
                            uploadQueue[index].uploadState = 'Uploading...'
                            break;
                        default:
                            uploadQueue[index].uploadState = snapshot.state
                            break
                    }
                    this.setUploadQueue([...uploadQueue])
                },
                (error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                        case 'storage/unauthorized':
                            // User doesn't have permission to access the object
                            break;
                        case 'storage/canceled':
                            // User canceled the upload
                            break;

                        // ...

                        case 'storage/unknown':
                            // Unknown error occurred, inspect error.serverResponse
                            break;
                    }

                    uploadQueue[index].uploadState = error.code
                    uploadQueue[index].uploadProgress = 0
                    this.setUploadQueue([...uploadQueue])

                    this.setIsUploading(false)
                    return reject(error.code)
                },
                () => {
                    uploadQueue[index].uploadState = 'Generating URL...'
                    uploadQueue[index].uploadProgress = 100
                    this.setUploadQueue([...uploadQueue])

                    // Upload completed successfully, now we can get the download URL
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        // console.log('File available at', downloadURL);
                        uploadQueue[index].url = downloadURL
                        uploadQueue[index].uploadedAt = Moment().unix()

                        if (this.getNodeMetadata) {
                            uploadQueue[index].metadata = this.getNodeMetadata(uploadQueue[index])
                        }

                        const itemStored = { ...uploadQueue[index] }

                        delete itemStored.file
                        delete itemStored.uploadProgress
                        delete itemStored.uploadState

                        uploadQueue[index].uploadState = 'Saving item...'
                        this.setUploadQueue([...uploadQueue])

                        const newTree = { ...this.tree } as Folder
                        addItemToFolder(newTree as Folder, itemStored)

                        // console.log('nezTr', newTree)
                        // console.log('itemStored', itemStored)

                        this.saveTree(newTree).then(() => {
                            uploadQueue[index].uploadState = 'Done'
                            this.setUploadQueue([...uploadQueue])
                            this.setTree(newTree)

                            if (uploadQueue[index + 1]) {
                                return this.uploadFromIndex(index + 1, uploadQueue)
                            } else {
                                this.setIsUploading(false)
                                this.setUploadQueue([])
                                return resolve(undefined)
                            }
                        }).catch(reject)
                    })
                }
            )
        })
    }

    async saveTree(newTree: Folder): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            // console.log('save', newTree)
            updateDoc(doc(this.db, this.fmPath, newTree.id), newTree as any).then(resolve).catch(reject)
        })
    }

    async startUpload(uploadQueue: Item[]): Promise<void> {

        // find item to upload
        const index = uploadQueue.findIndex((item: Item) => item.uploadProgress === 0)
        if (index === -1) {
            return
        }
        return this.uploadFromIndex(index, uploadQueue)
    }

    // async deleteItems(items: Item[]): Promise<any> {
    //     return Promise.all(items.map((item: Item): Promise<any> => {
    //         return deleteDoc(doc(this.db, this.itemsPath, item.id));
    //     }))
    // }

    async deleteNode(node: TreeNode): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const newTree = { ...this.tree } as Folder

            const parent = getFolderAtPath(newTree, node.isFolder ? getParentPath(node.path) : node.path)
            if (!parent) return reject('folder not found')

            parent.children = parent.children.filter(x => x.id !== node.id)
            this.saveTree(newTree).then(resolve).catch(reject)
        })
    }

    updateNode: (node: TreeNode) => Promise<void>
}

export default FirestoreFileProvider