import React, { useState, useEffect } from 'react'

import QS from 'qs'
import { LockOutlined } from '@ant-design/icons';
import { Input, Button, Alert, Spin, Row, Col, Form, message } from 'antd';
import t from '../../utils/translate'
import { checkActionCode, confirmPasswordReset } from 'firebase/auth'
import _ from 'lodash'
import Config from '../../config'
import Axios from 'axios'
import { useAppContext, AppContextValue } from '../app'
import { useHistory, useLocation } from 'react-router-dom'
import Splash from './_splash'

const FirebaseAuthAction = () => {

  // console.log('params', params);

  const [loading, setLoading] = useState(false)
  const [init, setInit] = useState(true)
  const [error, setError] = useState<any>(undefined)
  const [codeInfo, setCodeInfo] = useState(undefined)
  const [form] = Form.useForm()
  const appCtx: AppContextValue = useAppContext()
  const location = useLocation() as any
  const history = useHistory() as any
  const params: any = QS.parse(location.search, { ignoreQueryPrefix: true })

  const mode = params.mode
  const oobCode = params.oobCode


  useEffect(() => {

    // check code
    checkActionCode(appCtx.firebaseAuth.current, oobCode).then((codeInfo: any) => {

      setCodeInfo(codeInfo)
      setInit((codeInfo.operation === 'VERIFY_EMAIL') ? true : false)
      setError(undefined)

      // console.log('codeInfo', codeInfo)

      if (codeInfo.operation === 'VERIFY_EMAIL') {

        Axios({
          method: 'post',
          url: Config().api_endpoint + '/admins.verifyEmail',
          data: {
            oobCode: oobCode
          },
          // withCredentials: true
        })
          .then((_response: any) => {

            message.success(t('email_verified', "Your email address has successfully been verified!"))

            if (appCtx.firebaseAuth.current.currentUser) {
              // force refresh token in case the email verification linked the admin to an organization (invitation signup)
              appCtx.signIn(appCtx.firebaseAuth.current.currentUser, '/', true)
            } else {
              history.push('/')
            }
          })
          .catch((error: any) => {
            console.error(error)
            let message = error.message

            if (error.response && error.response.status <= 500) {
              message = error.response.data.message
            }

            setInit(false)
            setError(message)
          })

      }

      // if (codeInfo.operation === 'RECOVER_EMAIL') {
      //   Firebase.auth().applyActionCode(oobCode).then(() => {
      //     addMessage('success', t('email_recovered', "Your email address has successfully been recovered!"))
      //     history.push('/')
      //   }).catch((error: any) => {
      //     console.error(error)
      //     this.setState({error: error, init: false})
      //   })
      // }
    }).catch((error: any) => {
      console.error(error)
      setError(error)
      setInit(false)
    })
  }, [appCtx, oobCode, history]);

  const onFinish = (values: any) => {
    if (loading === true) {
      return
    }
    else {
      setLoading(true)
    }

    confirmPasswordReset(appCtx.firebaseAuth.current, oobCode, values.password).then(() => {
      form.resetFields()
      message.success(t('password_reset_success', "Your password has successfully been updated!"))
      history.push('/')
    }).catch((error: any) => {
      // var errorCode = error.code;
      // var errorMessage = error.message;
      console.error('reset password error', error)

      setError(error.message)
      setLoading(false)
    })
  }

  let h1 = mode || "Invalid request"

  switch (mode) {
    case 'verifyEmail':
      h1 = t('verify_your_email_address', "Verify your email address")
      break
    case 'resetPassword':
      h1 = t('reset_your_password', "Reset your password")
      break
    default:
  }

  return <Splash h1={h1}>

    {init && <Spin size="large" />}

    {error && <Alert type="error" message={error.message} />}

    {mode === 'resetPassword' && _.get(codeInfo, 'data.email', null) && <div>
      <p>{t('for_x_email', "For {email}", { email: _.get(codeInfo, 'data.email') })}</p>

      <Row>
        <Col span="16" offset="4">
          <Form form={form} onFinish={onFinish}>
            <Form.Item name="password" rules={[{ required: true, message: t('field_required', "This field is required!") }]}>
              <Input size="large" prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder={t('new_password', "New password")} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" loading={loading} size="large" htmlType="submit" style={{ width: '100%' }}>{t('confirm', "Confirm")}</Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>}
  </Splash>
}

export default FirebaseAuthAction