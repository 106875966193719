import { MutableRefObject, useContext, createContext, useEffect, useState, useRef, useCallback } from 'react'

import { message, Spin } from 'antd'
import {
  Route,
  Switch,
  useHistory,
  useParams,
} from 'react-router-dom'

import AdminsList from 'components/admins/list'
import ProjectsList from 'components/projects/list'
import ProjectsCreate from 'components/projects/create'
import ProjectsIndex from 'components/projects'
import OrganizationsSettings from './settings'
import OrganizationsGlobalStats from './globalStats'
import MediaPartnerDashboard from 'components/mediaPartners/dashboard/index'
import MediaPartnersPartnerships from 'components/mediaPartners/partnerships'
import MediaPartnersCommissions from 'components/mediaPartners/commissions'
import MediaPartnersPayments from 'components/mediaPartners/payments'
import { FirebaseApp } from '@firebase/app'
import { Auth, User as FirebaseUser } from 'firebase/auth'
import { getFirestore, doc, onSnapshot } from 'firebase/firestore'
import { useAppContext, AppContextValue } from 'components/app'
import { Project, Organization, Admin } from 'components/app/interfaces'
import { Unsubscribe } from '@firebase/util'
import Splash from 'components/app/_splash'


const OrganizationContext = createContext<OrganizationContextValue | null>(null);

export function useOrganizationContext(): OrganizationContextValue {
  const projectValue = useContext(OrganizationContext);
  if (!projectValue) {
    throw new Error("Missing OrganizationContextProvider in its parent.");
  }
  return projectValue;
}

export interface OrganizationContextValue {
  // APP CTX
  firebaseApp: MutableRefObject<FirebaseApp>
  firebaseAuth: MutableRefObject<Auth>
  firebaseUser: FirebaseUser
  loadingUser: boolean
  admin: Admin
  setAdmin: (admin: Admin) => void
  ajaxRequest: (options: any, callback?: Function) => void
  signOut: Function,
  signIn: (user: FirebaseUser, redirectTo?: string, forceRefresh?) => void
  setOrganizations: (organizations: Organization[]) => void
  organizations: Organization[]

  // ORG CTX
  setCurrentOrganizationId: (id: string) => void
  currentOrganization?: Organization
  updateOrganization: (updatedOrganization: Organization) => void
  projects: Project[]
  setProjects: (projects: Project[]) => void
}

const OrganizationsIndex = (props) => {

  const appCtx: AppContextValue = useAppContext()
  const [loading, setLoading] = useState(true)
  const routeParams = useParams() as any
  const history = useHistory() as any
  const [projects, setProjects] = useState<Project[]>([])
  const [currentOrganizationId, setCurrentOrganizationId] = useState<string | undefined>(undefined)

  let unsubscribe = useRef<Unsubscribe | undefined>(undefined)
  const prevOrganizationId = useRef(undefined)


  const updateOrganization = useCallback((updatedOrganization: Organization) => {
    const newOrganizations = [...appCtx.organizations.filter(p => p.id !== updatedOrganization.id)]

    // add joined data if missing
    const updatedOrganizationIndex = appCtx.organizations.findIndex(x => x.id === updatedOrganization.id)
    if (updatedOrganizationIndex !== 1 && appCtx.organizations[updatedOrganizationIndex].fullyLoaded === true && !updatedOrganization.fullyLoaded) {
      updatedOrganization.fullyLoaded = true
      updatedOrganization.partnerships = appCtx.organizations[updatedOrganizationIndex].partnerships
    }

    newOrganizations.push(updatedOrganization)
    appCtx.setOrganizations(newOrganizations)
  }, [appCtx])

  // for 'mediaPartner' org
  const loadPartnerships = useCallback((org: any) => {
    appCtx.ajaxRequest({
      method: 'get',
      url: '/mediaPartners.partnerships',
      params: {
        organizationId: org.id,
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        message.error(errorMessage)
        return
      }

      // update org with partnerships
      const updatedOrg = { ...org }
      updatedOrg.partnerships = response.data.partnerships

      // finish loading after setting partnerships
      updatedOrg.fullyLoaded = true
      updateOrganization(updatedOrg)

      setLoading(false)
    })
  }, [appCtx, updateOrganization])

  // for 'business' org
  const fetchProjects = useCallback((org: any) => {
    appCtx.ajaxRequest({
      method: 'get',
      url: '/projects.list',
      params: {
        organizationId: org.id
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        message.error(errorMessage)
        // this.setState({loading: false})
        return
      }

      response.data.projects.forEach((p: Project) => {
        p.fullyLoaded = false
        p.macros = []
        p.notifications = []
        p.notificationTopics = []
        // p.notificationsLoaded = false
        p.segments = []
        // p.segmentsLoaded = : boolean,
        p.userCustomProperties = {} as Map<string, any>
        // p.userCustomPropertiesLoaded = : boolean,
        p.runningTasks = []
      })
      setProjects(response.data.projects)
      // console.log('set projects', response.data.projects)

      // finish loading after setting projects
      const updatedOrg = { ...org }
      updatedOrg.fullyLoaded = true
      updateOrganization(updatedOrg)

      setLoading(false)

      if (updatedOrg.kind !== 'mediaPartner' && response.data.projects.length === 0) {
        return history.push('/organizations/' + updatedOrg.id + '/create-project')
      }

    })
  }, [appCtx, updateOrganization, history])


  useEffect(() => {

    // console.log('routeParams.organizationId', routeParams.organizationId)
    if (prevOrganizationId.current === routeParams.organizationId) return
    prevOrganizationId.current = routeParams.organizationId

    if (window.cmAgent) {
      window.cmAgent.setCompanyId(routeParams.organizationId)
    }

    // console.log('effect', routeParams.organizationId)

    const fetchOrganization = (id: string) => {

      setCurrentOrganizationId(id)

      const org = appCtx.organizations.find(x => x.id === id)
      if (!org) return

      // console.log(currentOrganization)
      // console.log('fetch org', id)
      setLoading(true)

      const db = getFirestore(appCtx.firebaseApp.current)

      unsubscribe.current = onSnapshot(doc(db, "organizations", id), (doc: any) => {

        const org = doc.data()

        if (!org) {
          message.error("Couldn't load organization " + id)
          return
        }
        // console.log('found', org)

        if (org.kind === 'mediaPartner') {
          loadPartnerships(org)
        } else {
          fetchProjects(org)
        }

        // update app state if org is missing after invitation
        if (!appCtx.organizations.find((x: any) => x.id === org.id)) {
          const orgs = appCtx.organizations.slice()
          orgs.push(org)
          appCtx.setOrganizations(orgs)
        }

        // console.log('got org', org);

        if (window.cmAgent) {
          window.cmAgent.setCompanyId(id)
        }
      }, (e) => {
        message.error(e)
      })
    }

    if (unsubscribe.current) {
      unsubscribe.current()
    }

    // unsubscribe takes time, we need to wait a bit before subscribing to new org
    window.setTimeout(() => {
      fetchOrganization(routeParams.organizationId)
    }, 0)

    return function cleanup() {
      if (unsubscribe.current) {
        unsubscribe.current()
      }
    }
  }, [
    routeParams.organizationId,
    unsubscribe,
    appCtx,
    loadPartnerships,
    fetchProjects,
  ])


  const currentOrganization = appCtx.organizations.find(org => org.id === currentOrganizationId)

  if (loading || !currentOrganization) return <Splash h1="Loading organization...">
    <Spin size="large" />
  </Splash>


  const orgCtx: OrganizationContextValue = {
    // APP CTX
    firebaseApp: appCtx.firebaseApp,
    firebaseAuth: appCtx.firebaseAuth,
    firebaseUser: appCtx.firebaseUser,
    loadingUser: appCtx.loadingUser,
    signIn: appCtx.signIn,
    signOut: appCtx.signOut,
    ajaxRequest: appCtx.ajaxRequest,
    admin: appCtx.admin,
    setAdmin: appCtx.setAdmin,
    organizations: appCtx.organizations as Organization[],
    setOrganizations: appCtx.setOrganizations,

    // ORG CTX
    setCurrentOrganizationId: setCurrentOrganizationId,
    currentOrganization: currentOrganization,
    updateOrganization: updateOrganization,
    projects: projects as Project[],
    setProjects: setProjects,
  }

  const organizationLayout = {
    organization: currentOrganization,
    projects: currentOrganization ? projects.filter((pg) => pg.organizationId === currentOrganization.id) : [],
    setProjects: setProjects,
    refreshPartnerships: () => {
      loadPartnerships(currentOrganization)
    }
  }
  // console.log(organizationLayout)

  return <OrganizationContext.Provider value={orgCtx}>
    <Switch>
      {currentOrganization.kind !== 'mediaPartner' && <Route exact path="/organizations/:organizationId" render={() => <ProjectsList />} />}
      {currentOrganization.kind === 'mediaPartner' && <Route exact path="/organizations/:organizationId" render={() => <MediaPartnerDashboard />} />}
      <Route path="/organizations/:organizationId/admins" render={(routerProps) => <AdminsList app={props.app} {...routerProps} organizationLayout={organizationLayout} />} />
      <Route path="/organizations/:organizationId/settings" render={(routerProps) => <OrganizationsSettings app={props.app} {...routerProps} organizationLayout={organizationLayout} />} />
      <Route path="/organizations/:organizationId/global-stats" render={() => <OrganizationsGlobalStats />} />
      {currentOrganization.kind !== 'mediaPartner' && <Route path="/organizations/:organizationId/create-project" render={() => <ProjectsCreate />} />}
      {currentOrganization.kind !== 'mediaPartner' && <Route path="/organizations/:organizationId/projects/:projectId" render={(routerProps) => <ProjectsIndex app={props.app} {...routerProps} organizationLayout={organizationLayout} />} />}
      {currentOrganization.kind === 'mediaPartner' && <Route path="/organizations/:organizationId/partnerships" render={() => <MediaPartnersPartnerships />} />}
      {currentOrganization.kind === 'mediaPartner' && <Route path="/organizations/:organizationId/commissions" render={() => <MediaPartnersCommissions />} />}
      {currentOrganization.kind === 'mediaPartner' && <Route path="/organizations/:organizationId/payments" render={() => <MediaPartnersPayments />} />}
      <Route render={() => <div>Route not found :(</div>} />
    </Switch>
  </OrganizationContext.Provider>
}

export default OrganizationsIndex