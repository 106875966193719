
const translations: any = {
  'en': {
    'desktop': 'Desktop',
    'computer': 'Computer',
    'phone': 'Phone',
    'tablet': 'Tablet',
    'console': 'Console',
    'wearable': 'Wearable',
    'tv': 'TV',
    'users_current': 'Users',
    'users_previous': 'Previous period',
    'newSignups_current': 'New signups',
    'newSignups_previous': 'Previous period',
    'sessions_current': 'Sessions',
    'sessions_previous': 'Previous period',
    'bounceRate_current': 'Bounce rate',
    'bounceRate_previous': 'Previous period',
    'avgSessionDuration_current': 'Session duration',
    'avgSessionDuration_previous': 'Previous period',
    'pageviewsPerSession_current': 'Pages / session',
    'pageviewsPerSession_previous': 'Previous period',
    'revenue_current': 'Revenue',
    'revenue_previous': 'Previous period',
    'orders_current': 'Orders',
    'orders_previous': 'Previous period',
    'conversions_current': 'Conversions',
    'conversions_previous': 'Previous period',
    'conversionRate_current': 'Conversion rate',
    'conversionRate_previous': 'Previous period',
    'avgTimeToConversion_current': 'Average time to conversion',
    'avgTimeToConversion_previous': 'Previous period',
    'avgCart_current': 'Average cart',
    'avgCart_previous': 'Previous period',
    'abandonedCartsRate_current': 'Abandoned carts rate',
    'abandonedCartsRate_previous': 'Previous period',
    'dataDriven': 'Data driven',
    'timeDecay': 'Time decay',
    'positionBased': 'Position-based',
    'firstClick': 'First click',
    'lastClick': 'Last click',
    'task_initReattributeOrders': 'Reattribute orders',
    'task_initSyncShopifyCustomers': 'Sync Shopify customers & orders',
    'task_initRefreshSegment': 'Refresh segment',
    'boolean': 'True / false',
    'integration_google_ads': "Sync your marketing spendings & decode auto-tagged clicks (gclid).",
    'integration_facebook': "Sync your marketing spendings, create Custom Audiences from your segments & create campaigns.",
    'integration_shopify': "Sync your customers, products & transactions in real-time.",
    'integration_sylius': "Sync your customers, products & transactions in real-time.",
    'integration_woo_commerce': "Sync your customers, products & transactions in real-time.",
    'integration_mailchimp': "Sync your subscribers with Captain Metrics.",
    'integration_klaviyo': "Sync your contacts & create mailing lists from your segments.",
    'integration_hubspot': "Sync your contacts & interactions.",
    'integration_postmark': "Send transactional emails.",
    'integration_sparkpost': "Send transactional & marketing emails.",
    'integration_twilio': "Send SMS.",
    'integration_monday': "Inject your items updates into your users timelines.",
    'integration_amazon': "Sync your Amazon orders & marketing performance.",
    'integration_lazada': "Sync your Lazada orders & marketing performance.",
    'integration_shopee': "Sync your Shopee orders & marketing performance.",
    'integration_webhook': "Push events in real-time to your own systems.",
    'integration_admo': "Reconciliate Admo TV postviews with web-sessions.",
    'integration_gamned': "Reconciliate Gamned postviews with conversions.",
    'accept_invitation_and_join_x': "Accepter l'invitation et rejoindre {organization}",
    'gender_male': 'Male',
    'gender_female': 'Female',
  },
  'fr': {
    'sign_in': 'Se connecter',
    'sign_in_with_google': 'Se connecter avec Google',
    'or_sign_in_with_credentials': 'Ou se connecter avec mot de passe',
    'email_address': 'Adresse email',
    'password': 'Mot de passe',
    'forgot_password': 'Mot de passe oublié?',
    'send_me_a_new_password': 'Envoyez-moi un nouveau mot de passe',
    'or_cancel': 'ou annuler',
    'field_required': 'Ce champ est requis!',
    'password_format_invalid': 'Le mot de passe doit contenir au moins 6 caractères.',
    'dont_have_account': "Vous n'avez pas d'accès?",
    'sign_up_for_free': 'Créez un compte gratuitement',
    'user_not_found': 'Utilisateur inconnu.',
    'or_sign_up_with_credentials': 'Ou créer un compte avec mot de passe',
    'confirm': 'Confirmer',
    'already_have_an_account': 'Vous avez déjà un compte?',
    'email_already_in_use': 'Cette adresse email est déjà utilisée par un autre compte.',
    'wrong_password': "Le mot de passe est invalide.",
    'create_account_terms_checkbox': 'En créant un compte j\'accepte les <a href="https://captainmetrics.com/terms" target="blank">CGU</a> & <a href="https://captainmetrics.com/privacy-policy" target="blank">Politique de confidentialité</a>.', 
    'checkbox_terms_required': 'Vous devez accepter les CGU pour pouvoir créer votre compte.',
    'loading': 'Chargement...',
    'reset_password_sent': 'Un email contenant les instructions pour changer votre mot de passe a été envoyé à {email}!',
    'verify_your_email_address': 'Vérifiez votre adresse email',
    'reset_your_password': 'Réinitialisez votre mot de passe',
    'new_password': 'Nouveau mot de passe',
    'password_reset_success': 'Votre mot de passe a été mis à jour!',
    'email_verified': 'Votre adresse email a bien été vérifiée, merci!',
    'email_recovered': 'Votre ancienne adresse email a bien été récupérée!',
    'first_name': 'Prénom',
    'last_name': 'Nom',
    'preferred_language': 'Langue préférée',
    'time_zone': 'Fuseau horaire',
    'complete_your_profile': 'Complétez votre profil',
    'user_profile_updated': 'Votre profile a été mis à jour!',
    'select_an_organization': 'Sélectionnez une organisation',
    'or': 'ou',
    'create_an_organization': 'Créer une nouvelle organisation',
    'organization_created': 'Votre organisation a été créée',
    'select_a_currency': 'Sélectionnez une devise',
    'currency': 'Devise',
    'organization_name': "Nom de l'organisation",
    'welcome': 'Bienvenue !',
    'check_your_mailbox': "Veuillez suivre le lien envoyé sur votre email pour vérifier votre compte.",
    'country': 'Pays',
    'select_a_country': 'Sélectionnez un pays',
    'valid_url_required': 'Une URL valide est requise!',
    'project_name': 'Nom du projet',
    'privacy_policy_url': 'URL de votre Politique de Confidentialité',
    'website_url': 'URL de votre site',
    'project_created': 'Votre nouveau projet a été créé!',
    'status_setup': 'Setup',
    'native_app': 'App native',
    'new_domain': 'Nouveau domaine',
    'domain_name': 'Nom de domaine',
    'hostname_invalid': 'La valeur doit être un nom de domaine valide!',
    'press_enter_to_insert_a_new_value': 'Appuyez sur Entrée pour insérer une nouvelle valeur.',
    'url_params_whitelist': 'Liste des paramètres d\'URL à conserver',
    'physical_store': 'Boutique physique',
    'homepages_urls': "Chemins des pages d'accueil",
    'homepages_paths_info': "Ces chemins servent à détecter les visites provenant de vos mot-clés de marques SEO.",
    'cancel': 'Annuler',
    'delete_domain_confirm': 'Voulez-vous vraiment supprimer ce domaine?',
    'edit_domain': 'Editer un domaine',
    'native_app_name': 'Nom de votre app native',
    'domains': 'Domaines',
    'conversion_rules': 'Règles de conversion',
    'my_settings': 'Mes paramètres',
    'sign_out': 'Se déconnecter',
    'projects': 'Projets',
    'create_a_new_project': 'Créer un nouveau projet',
    'users': 'Utilisateurs',
    'settings': 'Paramètres',
    'go_to_project': 'Aller au projet...',
    'industry': 'Industrie',
    'next': 'Suivant',
    'privacy_policy_url_info': "Les traitements des données relatives à vos visiteurs, collectées par votre Organisation via Captain Metrics (cookies, emails, transactions...), doivent clairement être explicités dans votre Politique de Confidentialité. Votre projet Captain Metrics pourra être suspendu à tout moment si votre Politique de Confidentialité n'est pas conformes aux régulations en vigeurs (ex: GDPR...).",
    'arts-entertainment': 'Arts & Divertissement',
    'automotive': 'Automobile',
    'beauty-fitness': 'Beauté & Fitness',
    'books-literature': 'Livres & Literrature',
    'business-industrial-markets': 'Business & Marchés industriels',
    'computer-electronics': 'Informatique & Electronique',
    'finance': 'Finance',
    'food-drink': 'Alimentation & boisson',
    'games': 'Jeux',
    'healthcare': 'Santé',
    'hobbies-leisure': 'Activités de loisirs',
    'home-garden': 'Maison & Jardin',
    'internet-telecom': 'Internet & Télécommunication',
    'jobs-education': 'Emploi & Education',
    'law-government': 'Loi & Gouvernement',
    'news': 'Actualité',
    'online-communities': 'Communautés en ligne',
    'people-society': 'Peuple & Société',
    'pets-animals': 'Animaux',
    'real-estate': 'Immobilier',
    'science': 'Science',
    'shopping': 'Shopping',
    'sports': 'Sport',
    'travel': 'Voyage',
    'other': 'Autre...',
    'project_domain_created': 'Votre domaine a été créé!',
    'project_domain_updated': 'Votre domaine a été mis à jour!',
    'project_domain_deleted': 'Votre domaine a été supprimé!',
    'domains_p1': "Dans Captain Metrics, un Domaine représente un moyen d'accèder à votre business, il peut être un site web, une app native ou une boutique physique.",
    'domains_p2': "Comme vous le savez probablement, les cookies web sont stockés par nom de domaine (ex: mon-site.com). Si votre site web utilise des sous-domaines (ex: blog.mon-site.com), l'agent de collecte perdra la trace de vos visiteurs. Déclarer vos domaines & sous-domaines activera la collecte \"cross-domain\".",
    'domains_p3': "Tracker une page web unique est parfois difficile car vos URLs peuvent contenir des paramètres variables (ex: mon-site.com/?sessionId=xxx). Dans ce cas, nous devons filtrer les URLs de votre site pour retirer ces paramètres indésirables. Dans une app native, cette étape n'est pas utile car il n'y a pas d'URL.",
    'name': 'Nom',
    'domain': 'Domaine',
    'parameters': 'Paramètres',
    'new_conversion_rule': 'Nouvelle règle de conversion',
    'edit_conversion_rule': 'Modifier une règle de conversion',
    'subscriptions': 'Souscriptions',
    'conversion_rule_created': 'La règle de conversion a été créée !',
    'conversion_rule_updated': 'La règle de conversion a été mise à jour !',
    'attribution_model': "Modèle d'attribution",
    'acquisition_attribution_model': "Modèle d'attribution pour nouveaux clients",
    'retention_attribution_model': "Modèle d'attribution pour clients existants",
    'customize': 'personnaliser',
    'default_currency': 'Devise par défaut',
    'value_should_be_written_like_this': "La valeur doit-etre-ecrite-comme-ceci",
    'used_to_configure_the_tracker': "Utilisée pour configurer le tracker; doit-etre-ecrite-comme-ceci",
    'use_upper_case_to_separate_words': 'Utilisez des majuscules pour séparer les mots.',
    'leads_labels': 'Labels des leads',
    'add_a_label': 'Ajouter un label',
    'subscription': 'Souscription',
    'labels_should_contain_defaults': "Les labels doivent au moins contenir new/converted/lost.",
    'label_value_invalid': "Une valeur de label n'est pas valide!",
    'label_color_required': "Veuillez choisir une couleur de label!",
    'conversion_rules_p1': "Avec Captain Metrics, une Règle de Conversion permet de définir quels types de conversions vous souhaitez tracker, cela peut être une transaction eCommerce, un lead ou une souscription pour paiements récurrents.",
    'conversion_rules_p2': "Puisque de nos jours les parcours clients implique plusieurs devices et sessions, il est crucial de mesurer la performance de vos canaux marketing avec un modèle d'attribution multi-canal.",
    'conversion_rules_p3': "Les canaux marketing utilisés pour acquérir de nouveaux clients sont souvent très différents de ceux utilisés pour les fidéliser. Avec Captain Metrics vous pouvez choisir un modèle d'attribution pour chaque scénario.",
    'delete_conversion_rule_confirm': 'Souhaitez-vous vraiment supprimer cette règle de conversion?',
    'delete_order_confirm': 'Souhaitez-vous vraiment supprimer cette commande?',
    'deleted_labels_migration': 'Migration des labels supprimés',
    'integration': 'Intégration',
    'project_settings_updated': 'Les paramètres de votre projet ont été mis à jour!',
    'cancel_invitation': 'Annuler l\'invitation',
    'cancel_invitation_content': 'Voulez-vous vraiment annuler l\'invitation envoyée à {email} ?',
    'invite_a_colleague': 'Inviter un collègue',
    'remove_admin': 'Supprimer un admin',
    'remove_admin_content': 'Voulez-vous vraiment supprimer cet admin de votre organisation ?',
    'transfer_ownsership': 'Transférer la propriété',
    'transfer_ownsership_content': 'Voulez-vous vraiment transférer votre organisation à un collègue ?',
    'check_your_mailbox_info': 'Merci pour votre inscription, veuillez valider la création de votre compte en cliquant sur le lien qui vient de vous être envoyé par email.',
    'admin_invitation_sent': 'Une invitation a été envoyée à votre collègue.',
    'invitation_sent': 'invitation envoyée',
    'ownership_transfered': 'Votre organisation a bien été transférée.',
    'invitation_cancelled': "L'invitation a été annulée",
    'admin_removed': "L'admin a été supprimé de votre organisation",
    'delete_a_channel': 'Supprimer un canal',
    'confirm_delete_channel': 'Voulez-vous vraiment supprimer ce canal ?',
    'channel_deleted': 'Le canal a été supprimé.',
    'source_medium_already_mapped': 'Ce "source / medium" est déjà mappé au canal {channel} !',
    'channel_updated': 'Le canal a été mis à jour.',
    'channel_created': 'Le canal a été créé.',
    'update_channel': 'Modifier le canal',
    'create_new_channel': 'Créer un nouveau canal',
    'channel_name': 'Nom du canal',
    'group': 'Groupe',
    'companies': 'Sociétés',
    'edit_profile': 'Modifier le profil',
    'profile': 'Profil',
    'last_seen': 'Actif le',
    'signed_up': 'Inscrit le',
    'size': 'Taille',
    'language': 'Langue',
    'address': 'Adresse',
    'address_line_2': 'Adresse line 2',
    'city': 'Ville',
    'postal_code': 'Code postal',
    'state': 'Etat',
    'custom_properties': 'Attributs custom',
    'joined_at': 'Rejoint le',
    'deleted_at': 'Supprimé le',
    'revenue': 'Chiffre d\'affaire',
    'revenue_involved': 'CA concerné',
    'orders': 'Commandes',
    'conversion_rate': 'Taux de conversion',
    'avg_time_to_conversion': 'Temps de conversion moy.',
    'avg_ttc': 'Temps moy.',
    'average_cart': 'Panier moy.',
    'abandoned_cart': 'Abandon de panier',
    'campaign': 'Campagne',
    'creative': 'Créa.',
    'keyword': 'Mot clé',
    'record_conversion_for': 'Remonter les conversions pour',
    'users_b2c': 'Les utilisateurs B2C',
    'companies_b2b': 'Les sociétés B2B',
    'conversion_rule_name': 'Nom de la règle de conversion',
    'conversion_rule_id': 'ID de la règle de conversion',
    'acquisition_xcr_attribution': 'Attribution des règles de conversion croisées en acquisition',
    'xcr': 'Règle de conversion croisée',
    'enable_cross_conversion_rules': 'Activer les règles de conversion croisées',
    'attribute_credits_to': 'Attribuer les performances à',
    'the_first_conversion_rule_ever': 'La toute première règle qui a converti',
    'the_most_recent_conversion_rule': 'La règle qui a converti en dernier',
    'retention_xcr_attribution': 'Attribution des règles de conversion croisées en rétention',
    'add_a_status': 'Ajouter un statut',
    'leads_status': 'Statuts des leads',
    'deleted_status_migration': 'Migration des statuts supprimés',
    'conversion_rule_deleted': 'La rège de conversion a été supprimée',
    'order_deleted': 'La commande a été supprimée',
    'statuses': 'Statuts',
    'deleted_statuses': 'Statuts supprimés',
    'acquisition_xcr': 'Règles de conversion croisées en Acquisition',
    'retention_xcr': 'Règles de conversion croisées en Rétention',
    'google_ads_connect_success': 'Google Ads a été connecté !',
    'project_google_ads_disconnected': "L'intégration de Google Ads a bien été déconnectée !",
    'connect_my_google_ads': 'Connecter Google Ads',
    'integration_id': 'Intégration ID',
    'connected': 'Connecté',
    'disconnect_google_ads_confirm': 'Voulez-vous vraiment déconnecter Google Ads ? Les données ne seront plus synchronisées.',
    'disconnect': 'Déconnecter',
    'instructions': 'Instructions',
    'project_shopify_disconnected': "L'intégration de Shopify a bien été déconnectée !",
    'task_launched': 'Tâche lancée !',
    'settings_updated': 'Paramètres mis à jour !',
    'project_integration_added': "L'intégration a été ajoutée !",
    'enter_your_shop_id': "Entrez l'ID de votre boutique Shopify",
    'is_shopify_users_source': 'Est-ce que Shopify est votre source principale d\'utilisateurs ?',
    'toggle_main_source': "Voulez-vous vraiment changer le statut de cette source d'utilisateurs ?",
    'geocode_customers_addresses': 'Géocoder les adresses des utilisateurs & des commandes ?',
    'geocode_customers_time_zones': 'Géocoder les fuseaux horaires des utilisateurs & des commandes ?',
    'orders_conversion_rule': 'Règle de conversion des commandes',
    'default_timezone': 'Fuseau horaire par défaut',
    'default_language': 'Langue des utilisateurs par défaut',
    'webhooks_connected': 'Webhooks connectés',
    'sync_customers_from_shopify_confirm': 'Voulez-vous vraiment synchroniser vos utilisateurs et commandes depuis Shopify ?',
    'sync_customers_and_orders': 'Synchroniser les utilisateurs et commandes',
    'disconnect_shopify_confirm': "Voulez-vous vraiment déconnecter Shopify ? les données ne seront plus synchronisées !",
    'shopify_connect_success': 'Shopify a bien été connecté !',
    'new_integration': 'Nouvelle intégration',
    'add_integration_confirm': 'Souhaitez-vous vraiment ajouter cette intégration ?',
    'add': 'Ajouter',
    'converting_external_traffic': 'Trafic extérieur ayant converti',
    'non_converting_traffic': "Trafic n'ayant pas converti",
    'not_mapped': 'Non-mappé',
    'role_alone': 'Tout seul',
    'role_init': 'Initiateur',
    'role_assist': 'Assistant',
    'role_closer': 'Closer',
    'attr_revenue': 'CA attribué',
    'avg_cart': 'Panier moy.',
    'average_time_to_conversion': 'Durée de conversion moy.',
    'non_converting_anonymous_traffic': "Trafic anonyme n'ayant pas converti",
    'non_converting_authenticated_traffic': "Trafic authentifié n'ayant pas converti",
    'session_duration': 'Durée de session',
    'dependencies': 'Dépendances',
    'channels': 'Canaux',
    'channels_dependencies_tooltip': "{percent} des contributions de {channel} sont suivies par une contribution de {target}.",
    'channels_dependencies_first_tooltip': "{percent} des premières contributions proviennent de {channel}.",
    'channels_dependencies_last_tooltip': "{percent} des dernières contributions proviennent de {channel}.",
    'conversion_paths': 'Tunnels de conversion',
    'external_id': 'ID externe',
    'device': 'Terminal',
    'languages': 'Langues',
    'financial_status': 'Statut financier',
    'fulfillment_status': 'Statut de commande',
    'closed': 'Fermé',
    'cancelled': 'Annulé',
    'shipping': 'Livraison',
    'tip': 'Pourboire',
    'discounts': 'Réductions',
    'props': 'Attributs',
    'cart': 'Panier',
    'order_status_url': 'Voir le statut de la commande',
    'add_segment': 'Ajouter un segment',
    'last_refresh_at': 'Rafraichi {at}',
    'channels_dependencies': 'Dépendances des canaux',
    'campaigns': 'Campagnes',
    'reset_filters': 'Réinitialiser les filtres',
    'customer': 'Client',
    'funnel': 'Tunnel',
    'view_conversion_funnel': 'Voir le tunnel de conversion', 
    'more': 'de plus',
    'organizations': 'Organisations',
    'tasks': 'Tâches',
    'user_profile': 'Profil utilisateur',
    'your_profile_has_been_updated': 'Votre profil a été mis à jour !',   
    'in_the_last_5_minutes': 'Durant les 5 dernières minutes',
    'users_online': 'Utilisateurs en ligne',
    'users_in_last_24h': 'Utilisateurs en 24h',
    'top_pages_screens': 'Top pages / screens',
    'avg_time': 'Durée moy.',
    'new_users': 'Nouveaux utilisateurs',
    'bounce': 'Rebond',
    'bounce_rate': 'Taux de rebond',
    'users_current': 'Utilisateurs',
    'users_previous': 'Période précedente',
    'newUsers_current': 'Nouveaux utilisateurs',
    'newUsers_previous': 'Période précédente',
    'sessions_current': 'Sessions',
    'sessions_previous': 'Période précédente',
    'bounceRate_current': 'Taux de rebond',
    'bounceRate_previous': 'Période précédente',
    'avgSessionDuration_current': 'Durée de session',
    'avgSessionDuration_previous': 'Période précédente',
    'pageviewsPerSession_current': 'Pages / session',
    'pageviewsPerSession_previous': 'Période précédente',
    'revenue_current': "Chiffre d'affaire",
    'revenue_previous': 'Période précédente',
    'orders_current': 'Commandes',
    'orders_previous': 'Période précédente',
    'conversions_current': 'Conversions',
    'conversions_previous': 'Période précédente',
    'conversionRate_current': 'Taux de conversion',
    'conversionRate_previous': 'Période précédente',
    'avgTimeToConversion_current': 'Durée de cycle moyenne',
    'avgTimeToConversion_previous': 'Période précédente',
    'avgCart_current': 'Panier moyen',
    'avgCart_previous': 'Période précédente',
    'abandonedCartsRate_current': "Taux d'abandon de panier",
    'abandonedCartsRate_previous': 'Période précédente',
    'in_the_last_30_days': 'Durant les 30 derniers jours',
    'in_the_last_7_days': 'Durant les 7 derniers jours',
    'in_the_last_week': 'Durant la dernière semaine',
    'in_the_last_month': 'Durant le dernier mois',
    'yesterday': 'Hier',
    'today': "Aujourd'hui",
    'custom': 'Personnalisé',
    'current_period': 'Période actuelle',
    'previous_period': 'Période précédente',
    'previous_year': 'Année précédente',
    'session_timeout': 'Durée de session',
    'session_timeout_help': "Lorsque vous recevez une nouvelle visite sur votre site/app, une nouvelle session commence. Par défaut, une session expire après 30 minutes d'inactivité (= 1800 secs), ou bien si l'utilisateur revient via une autre source de trafic.",
    'bounce_threshold': 'Seuil de rebond',
    'bounce_threshold_help': "Un rebond ne sera pas comptabilisé si le visiteur passe plus de x secondes sur une page ou s'il fait plusieurs interactions.",
    'brand_keywords_as_direct': 'Mots-clés de marque en Trafic Direct',
    'consider_brand_keyword_as_direct_info': "Lorsque vos utilisateurs vous connaissent déjà et tapent le nom de votre marque dans un moteur de recherche pour visiter votre site (ex: via Adwords...), nous vous recommandons de considérer ces sessions comme Trafic Direct.",
    'brand_keywords': 'Mots-clés de votre marque',
    'brand_keywords_help': "Vos mots-clés de marque sont utilisés par les modèles d'attribution pour détecter le Trafic Direct provenant du SEO. Appuyez sur Entrée pour insérer une valeur.",
    'enter_brank_keywords': 'Entrez vos mots-clés de marque',
    'secure_web_hits': 'Sécuriser les hits web',
    'secure_web_hits_with_hmac_info': "Signer les IDs utilisateurs envoyés par l'agent de collecte web (avec HMAC256) pour éviter une corruption malicieuse des données.",
    'none': 'Aucun',
    'authenticated_users': 'Utilisateurs authentifiés',
    'all_users': 'Tous les utilisateurs',
    'create_demo_project': 'Créer un projet de démo',
    'select_demo_project': "Quel type de démo vous intéresse ?",
    'orders_asos': 'E-commerce (ex: ASOS)',
    'leads_b2c': 'Leads B2C (ex: HSBC)',
    'leads_b2b': 'Leads B2B (ex: SAP)',
    'subscription_b2c': 'Souscription B2C (ex: Birchbox)',
    'subscription_b2b': 'Souscription B2B (ex: Salesforce)',
    'demo_project': 'Projet de démo',
    'demo_project_info': 'Générer un projet de démo avec des données factices.',
    'real_project': 'Projet réel',
    'real_project_info': 'Démarrer un nouveau projet réel pour votre business.',
    'unknown': 'inconnu...',
    'users_by_device': 'Visiteurs par terminal',
    'users_by_country': 'Visiteurs par pays',
    'users_by_language': 'Visiteurs par langue',
    'reset_demo_success': 'La démo a été réinitialisée avec de nouvelles données !',
    'reset_demo_confirm': 'Souhaitez-vous vraiment réinitialiser la démo avec de nouvelles données ?',   
    'reset_demo': 'Réinitialiser la démo',
    'view': 'Voir',
    'dpo_info': "Le Data Protection Officer est responsable de la supervisation de la stratégie de protection des données et de sa conformité avec les exigences du GDPR.",
    'project_settings': 'Paramètres du projet',
    'channels_mapping': 'Mapping des canaux',
    'channel_about_1': "Un canal est une source de trafic composée d'un ou plusieurs \"source / medium\" (ex: google / organic).",
    'channel_about_2': "Pour chaque conversion, Captain Metrics vous montre tous les canaux ayant contribué à cette conversion (= tunnel de conversion).",
    'new_channel': 'Nouveau canal',   
    'general_settings': 'Paramètres globaux',
    'parent_segment': 'Segment parent',
    'match_user_attributes': "Matcher les attributs d'utilisateur",
    'all_fields_required': 'Tous les champs sont requis',
    'delete_segment_condition_group_confirm': 'Souhaitez-vous vraiment supprimer ce groupe ?',
    'profile_attribute': 'Attribut de profil',
    'group_operators': "Opérateurs",
    'operator_and': 'ET',
    'operator_or': 'OU',
    'operator_all': 'TOUTES',
    'operator_any': "L'UNE",
    'of_the_following': "les conditions suivantes doivent matcher :",
    'select_an_attribute': 'Sélectionnez un attribut',
    'select_a_value': 'Sélectionnez une valeur',
    'enter_a_value': 'Entrez une valeur',
    'and': 'et',
    'is_in': 'au',
    'segment_users_who_match': 'Segmenter les utilisateurs qui valident...',
    'user': 'Utilisateur',
    'that': 'qui',
    'contains': 'contient',
    'does_not_contain': 'ne contient pas',
    'is_true': 'est vrai',
    'is_false': 'est faux',
    'is_greater_than': 'est supérieur à',
    'is_less_than': 'est inférieur à',
    'is_before': 'est avant',
    'is_after': 'est après',
    'is_in_between': 'est entre',
    'is_equal_to': 'est égal à',
    'is_not_equal_to': "n'est pas égal à",
    'exists': 'existe',
    'does_not_exist': "n'existe pas",
    'reattribute_conversions_success': 'Les conversions sont en cours de ré-attribution.',
    'reattribute_conversions': 'Ré-attribuer les conversions',
    'later': 'Plus tard',
    'recompute_attribution_info': "Vos canaux ont été mis à jour, souhaitez-vous ré-attribuer les conversions avec ce nouveau mapping ?",
    'reattribute_since': 'Ré-attribuer depuis :',
    'details': 'Détails',
    'created_at': 'Créé le',
    'status': 'Statut',
    'started': 'Démarré',
    'done': 'Terminé',
    'failed': 'Echoué',
    'queued': "File d'attente",
    'task_initReattributeOrders': "Ré-attribuer les commandes",
    'task_initSyncShopifyCustomers': 'Synchro des clients & commandes Shopify',
    'task_initRefreshSegment': "Rafraichir un segment",
    'collapse_all': 'Tout replier',
    'expand_all': 'Tout développer',
    'actions_view_more': 'actions, voir plus...',
    'brand': 'Marque',
    'variant': 'Variante',
    'category': 'Catégorie',
    'price': 'Prix',
    'time_spent': 'Temps passé',
    'properties': 'Attributs',
    'tag_product': 'Produit consulté',
    'author': 'Auteur',
    'published_at': 'Publié le',
    'modified_at': 'Modifié le',
    'tag_article': 'Article consulté',
    'tag_pageview': 'Page consultée',
    'tag_event': 'Evénement',
    'tag_setCart': 'Panier modifié',
    'tag_setWishList': "Liste d'envies modifiée",
    'tag_enterSegment': "Entre dans le segment",
    'tag_exitSegment': "Quitte le segment",
    'signup': 'Inscription',
    'tag_addUserToCompany': "Utilisateur ajouté à la société",
    'tag_order': 'Commande',
    'channel': 'Canal',   
    'last_order': 'Dernier achat',
    'update_an_attribute': 'Modifier un attribut',
    'new_attribute': 'Nouvel attribut',
    'value': 'Valeur',
    'press_enter_to_add_value': 'Appuyez sur Entrée pour insérer une valeur',
    'key': 'Clé',   
    'user_profile_created': 'Le profil utilisateur a été créé !',
    'male': 'Homme',
    'female': 'Femme',
    'mobile_number': 'Téléphone portable',
    'linkedin_profile': 'Profil LinkedIn',
    'twitter_username': 'Pseudonyme Twitter',
    'facebook_username': 'Pseudonyme Facebook',
    'facebook_id': 'ID Facebook',
    'is_authenticated': 'Est authentifié ?',
    'address_line_1': 'Adresse ligne 1',
    'confirm_delete_attribute': 'Souhaitez-vous vraiment supprimer cet attribut ?',
    'type_string': 'texte',
    'type_number': 'nombre',
    'type_boolean': 'vrai / faux',
    'type_date': 'date', 
    'type_list': 'liste',
    'segment_deleted': 'Le segment a bien été supprimé !',
    'new_user': 'Nouvel utilisateur',
    'new_segment': 'Nouveau segment',
    'delete_segment': 'Souhaitez-vous vraiment supprimer ce segment ?',
    'edit_segment': 'Modifier le segment',
    'refreshing': 'Rafraichissement en cours',
    'refreshing_soon': 'Bientôt rafraichit',
    'shopping_cart': 'Panier',
    'wish_list': "Liste d'envies",
    'devices': 'Terminaux',
    'time_to_conversion': 'Temps de conversion',
    'orders_frequency': "Fréquence d'achat",
    'integration_google_ads': "Synchronisez vos dépenses marketing, créez des Audiences à partir de vos segments & decodez les clics auto-taggués (gclid).",
    'integration_facebook': "Synchronisez vos dépenses marketing, créez des Audiences à partir de vos segments & préparez des campagnes.",
    'integration_shopify': "Collectez les historiques de navigation, synchronisez vos clients, produits & commandes en temps-réel.",
    'integration_sylius': "Collectez les historiques de navigation, synchronisez vos clients, produits & commandes en temps-réel.",
    'integration_woo_commerce': "Collectez les historiques de navigation, synchronisez vos clients, produits & commandes en temps-réel.",    
    'integration_mailchimp': "Synchronisez vos contacts & listes d'envoi à partir de vos segments.",
    'integration_hubspot': "Synchronisez vos contacts & interactions.",
    'integration_postmark': "Envoyez des emails transactionnels.",
    'integration_sparkpost': "Envoyez des emails transactionnels & marketing.",
    'integration_twilio': "Envoyez des SMS.",
    'integration_monday': "Injectez les mises à jours de vos items dans les timelines utilisateurs.",
    'integration_amazon': "Synchronisez vos commandes Amazon & performances marketing.",
    'integration_lazada': "Synchronisez vos commandes Lazada & performances marketing.",
    'integration_shopee': "Synchronisez vos commandes Shopee & performances marketing.",
    'integration_webhook': "Recevez des événements en temps-réel sur vos propres systèmes.",
    'integration_admo': "Réconciliez les affichages TV avec les web-sessions.",
    'integration_gamned': "Réconciliez les affichages Gamned avec les conversions.",
    'orders_conversion_domain': 'Domaine de conversion des commandes',
    'loading_demo': 'Votre démo est en cours de chargement... cette opération peut prendre une minute...',
    'no_data': 'Pas de data...',
    'gender_male': 'Homme',
    'gender_female': 'Femme',
  } 
}

export default translations