import React, { ReactNode, useState } from "react"
import {
  Button,
  Spin,
  Table,
  Row,
  Col,
  // Space,
  Progress,
  Popover,
  Tooltip,
  // message,
} from 'antd'
import { Folder, Item } from '../core/interfaces'
import CreateFolder from './createFolder'
import FolderMenu from './folderMenu'
import ItemMenu from './itemMenu'
import Uploader from './uploader'
import './filemanager.css'
import { FolderOpenTwoTone, FolderTwoTone, UploadOutlined } from '@ant-design/icons'
import Moment from 'moment-timezone'
import filesize from 'filesize'
import { useFileManagerContext, FileManagerContextValue } from '../core/fileManager'
import { useProjectContext, ProjectContextValue } from '../../projects'
import Config from '../../../config'

const isImage = (contentType: string): boolean => {
  return contentType.includes('image')
}

const CenteredBlock = (props: any) => {
  return <div className="fm-centered-block">
    <div>
      {props.children}
    </div>
  </div>
}

export interface LayoutProps {
  visible: boolean
  height: number
  withSelection?: boolean
  onError: (err: any) => void
  onSelect: (items: Item[]) => void
  acceptFileType: string
  acceptItem: (item: Item) => boolean
  multiple?: boolean
}

export const Layout = (props: LayoutProps) => {


  const fileManager: FileManagerContextValue = useFileManagerContext()
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const projectCtx: ProjectContextValue = useProjectContext()

  // refresh parent folder tree and go to new folder
  const onFolderCreated = (newFolder: Folder) => {
    fileManager.loadNodesAtPath(fileManager.getParentPath(newFolder.path)).then(() => {
      fileManager.goToFolder(newFolder.path)
    }).catch(props.onError)
  }


  // go to parent folder after delete
  const onFolderDelete = (deletedFolder: Folder) => {
    fileManager.goToFolder(fileManager.getParentPath(deletedFolder.path), true)
  }

  const onFolderRename = (_renamedFolder: Folder) => {
    // reload tree
    // TODO
    // loadNodesAtPath(renamedFolder.path)
    // init()
  }

  const resolveFileURL = (item: Item): string => {
    // const url = new URL(item.url)
    // const bits = 
    const urlPath = item.metadata['projectId']+item.path.replaceAll('~', '/')+'/'+item.id
    // console.log('url', item.url)
    // console.log('urlPath', urlPath)
    if (projectCtx.currentProject.hasCDN && projectCtx.currentProject.cdnEndpoint) {
      return 'https://'+projectCtx.currentProject.cdnEndpoint+'/'+urlPath
    } 

    return 'https://storage.googleapis.com/'+Config().firebase_storage_bucket+'/'+urlPath
    // return item.url
  }

  const toggleSelectionForItem = (item: Item) => {

    // ignore items not accepted
    if (!props.acceptItem(item)) return

    if (props.multiple) {
      let newKeys = [...selectedRowKeys]
      // remove if exists
      if (newKeys.includes(item.id)) {
        newKeys = selectedRowKeys.filter(k => k !== item.id)
      } else {
        newKeys.push(item.id)
      }
      setSelectedRowKeys(newKeys)
      props.onSelect(fileManager.currentItems.filter(x => newKeys.includes(x.id)))
    } else {
      setSelectedRowKeys([item.id])
      props.onSelect([item])
    }
  }

  const renderTree = (folder: Folder, path: string, level: number): ReactNode => {
    const selected = folder.path === path

    return <div key={folder.path}>
      <div
        onClick={() => {
          // reset selection
          setSelectedRowKeys([])
          props.onSelect([])
          fileManager.goToFolder(folder.path)
        }}
        className={'fm-tree-item' + (selected ? ' selected' : '')}
        style={{ paddingLeft: (16 * level) + 'px' }}
      >
        {level > 0 && <FolderMenu
          fileProvider={fileManager.fileProvider}
          folder={folder}
          onError={props.onError}
          onDelete={onFolderDelete}
          onRename={onFolderRename}
        />}
        <span className="fm-tree-item-folder">{selected ? <FolderOpenTwoTone /> : <FolderTwoTone />}</span> {folder.name}
      </div>

      {folder.children.filter(sub => sub.isFolder).map(sub => renderTree(sub as Folder, path, level + 1))}
    </div>
  }

  if (props.visible === false) return null

  // console.log('treeData', treeData)
  // console.log('currentPath', currentPath)
  // console.log('uploadQueue', uploadQueue)

  let totalProgress = fileManager.uploadQueue.length * 100
  let actualProgress = 0
  let currentUploadedItem: Item | undefined
  let currentUploadedCount = 0

  fileManager.uploadQueue.forEach((item: Item, i: number) => {
    if ((!currentUploadedItem || currentUploadedItem.uploadState === 'Done') && item.uploadProgress) {
      currentUploadedItem = item
      currentUploadedCount = i + 1
    }

    actualProgress += item.uploadProgress || 1
  })
  const currentProgress = (actualProgress * 100) / totalProgress

  // console.log('isUploading', isUploading)
  // console.log('uploadQueue', uploadQueue)
  // console.log('currentPath', currentPath)
  // console.log('tree', tree)
  // console.log('selectedRowKeys', selectedRowKeys)

  return <div className="fm-container" style={{ height: props.height, verticalAlign: 'top' }}>

    {!fileManager.initializating && <div className="fm-topbar">
      <div className="fm-topbar-left">
        <CreateFolder fileProvider={fileManager.fileProvider} path={fileManager.currentPath} onError={props.onError} onComplete={onFolderCreated}>
          <Button type="primary" ghost block>New folder</Button>
        </CreateFolder>
      </div>

      <div className="fm-topbar-right">
        <Row>
          <Col span={8}>
            {/* <DeleteFolder fileProvider={props.fileProvider} path={currentPath} onError={props.onError} onComplete={onFolderDelete}><Button type="primary" ghost block><DeleteOutlined /></Button></DeleteFolder> */}
            {fileManager.uploadQueue.length > 0 &&
              <Progress
                style={{ width: '200px' }}
                strokeColor={{
                  '0%': '#4A00E0',
                  '100%': '#8E2DE2',
                }}
                size="small"
                trailColor="#FFF"
                percent={currentProgress}
                format={(percent: number) => '' + currentUploadedCount + '/' + fileManager.uploadQueue.length + ' - ' + percent.toFixed(0) + '%'}
              />}
          </Col>
          <Col span={12}>
            {fileManager.uploadQueue.length > 0 && <div className="fm-upload-progress">
              {currentUploadedItem && <div>{currentUploadedItem.name} - {currentUploadedItem.uploadState}</div>}
            </div>}
          </Col>
          <Col span={4}>
            <div className="fm-topbar-right-buttons">
              <Uploader
                fileProvider={fileManager.fileProvider}
                uploadQueue={fileManager.uploadQueue}
                setUploadQueue={fileManager.setUploadQueue}
                setTree={fileManager.setTree}
                isUploading={fileManager.isUploading}
                // setIsUploading={fileManager.setIsUploading}
                path={fileManager.currentPath}
                onError={props.onError}
                onComplete={() => fileManager.loadNodesAtPath(fileManager.currentPath)}
                multiple={props.multiple || false}
                accept={props.acceptFileType}
              >
                <Button type="primary" loading={fileManager.isUploading} icon={<UploadOutlined />}>Upload file</Button>
              </Uploader>

              {/* <div>
                <Radio.Group value={view} onChange={(e: RadioChangeEvent) => setView(e.target.value)}>
                  <Radio.Button value="table"><MenuOutlined /></Radio.Button>
                  <Radio.Button value="grid"><AppstoreFilled /></Radio.Button>
                </Radio.Group>
              </div> */}
            </div>
          </Col>
        </Row>

      </div>
    </div>}

    {fileManager.initializating && <CenteredBlock><Spin size="large" tip="Initializing..." /></CenteredBlock>}

    {!fileManager.initializating && fileManager.tree && <div style={{ display: 'flex', gap: '24px' }}>
      <div className="fm-topbar-left fm-block">
        {fileManager.treeLoading === true && <div style={{ textAlign: 'center' }}><Spin tip="Loading folders..." /></div>}
        <Spin spinning={fileManager.treeLoading}>
          <div className="fm-tree">{renderTree(fileManager.tree as Folder, fileManager.currentPath, 0)}</div>
        </Spin>
      </div>

      <div className="fm-topbar-right">
        <Table
          className="fm-block"
          style={{ height: props.height - 40, verticalAlign: 'middle' }}
          dataSource={fileManager.currentItems}
          loading={fileManager.treeLoading || fileManager.isUpdatingItems}
          pagination={false}
          scroll={{ y: props.height - 80 }}
          size="small"
          rowKey="id"
          locale={{ emptyText: 'No files found' }}
          rowSelection={props.withSelection ? {
            type: (props.multiple) ? 'checkbox' : 'radio',
            selectedRowKeys: selectedRowKeys,
            onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
              // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
              setSelectedRowKeys(selectedRowKeys)
              props.onSelect(selectedRows)
            },
            getCheckboxProps: (record: any) => ({
              disabled: !props.acceptItem(record as Item)
              // name: record.name,
            }),
          } : undefined}
          columns={[
            {
              title: '',
              key: 'preview',
              width: 70,
              className: 'valign-middle',
              render: item => <div style={{minHeight: 30}} onClick={toggleSelectionForItem.bind(null, item)}>
                {isImage(item.contentType) && <Popover placement="right" content={<img alt="" src={resolveFileURL(item)} style={{maxHeight: 400, maxWidth: 400}} />}><img alt="" src={resolveFileURL(item)} style={{maxHeight: 30, maxWidth: 50}} /></Popover>}
              </div>
            },
            {
              title: 'Name',
              key: 'name',
              className: 'valign-middle',
              render: item => {
                return <div className="fm-table-content" onClick={toggleSelectionForItem.bind(null, item)}>{item.name}</div>
              }
            },
            {
              title: 'Size',
              key: 'size',
              width: 80,
              className: 'valign-middle',
              render: item => {
                return <div className="fm-table-content" onClick={toggleSelectionForItem.bind(null, item)}>{filesize(item.size, { round: 0 })}</div>
              }
            },
            {
              title: 'Uploaded at',
              key: 'uploaded',
              width: 120,
              className: 'valign-middle',
              render: item => {
                return <Tooltip title={Moment.unix(item.uploadedAt).format('llll')}>
                  <div className="fm-table-content" onClick={toggleSelectionForItem.bind(null, item)}>{Moment.unix(item.uploadedAt).format('ll')}</div>
                </Tooltip>
              }
            },
            {
              title: 'Modified at',
              key: 'lastModified',
              width: 120,
              className: 'valign-middle',
              render: item => {
                return <Tooltip title={Moment.unix(item.lastModifiedAt).format('llll')}>
                  <div className="fm-table-content" onClick={toggleSelectionForItem.bind(null, item)}>{Moment.unix(item.lastModifiedAt).format('ll')}</div>
                </Tooltip>
              }
            },
            {
              title: '',
              key: 'actions',
              width: 50,
              className: 'valign-middle',
              render: item => {
                return <div style={{ textAlign: 'right' }}>
                  <ItemMenu
                    item={item}
                    resolveFileURL={resolveFileURL}
                    deleteNode={fileManager.deleteNode}
                  />
                </div>
              }
            },
          ]}
        />
      </div>
    </div>
    }
  </div>
}