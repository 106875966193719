import React, { useState } from 'react'

import { Button, Badge, Popconfirm, Input, Form } from 'antd';
import t from '../../utils/translate'
import _ from 'lodash'
import logoWebhook from '../../images/integrations/webhook.png'

const IntegrationsWebhook = ({ integration, disconnect, projectLayout, organizationLayout, app }: any) => {
    const [loading, setLoading] = useState(false)

    const connectWebhook = (values: any) => {
        setLoading(true)

        app.ajaxRequest({
            method: 'post',
            url: '/integrations.connect',
            data: {
                projectId: projectLayout.project.id,
                integrationId: integration.id,
                webhook: {
                    endpoint: values.endpoint,
                }
            }
        }, (errorMessage: any, response: any) => {

            if (errorMessage) {
                app.addMessage('error', errorMessage)
                setLoading(false)
                return
            }

            let projects = _.filter(organizationLayout.projects, (p: any) => p.id !== response.data.project.id)
            projects.push(response.data.project)
            organizationLayout.setProjects(projects)

            app.addMessage('success', t('webhook_connect_success', "Webhook has successfully been connected!"))
            setLoading(false)
        })
    }

    return <div>
        <h2 className="margin-t-m">{t('settings', "Settings")}</h2>
        {(integration.status === 'setup') || (integration.status === 'disconnected') ?
            <div style={{ width: '500px', margin: '32px auto' }}>
                <div className="paper padding-a-xl text-center">
                    <img src={logoWebhook} alt="" style={{ height: '200px' }} />
                    <Form layout="vertical" onFinish={connectWebhook}>
                        <Form.Item label="URL endpoint" name="endpoint" rules={[{required: true, type: 'url'}]}>
                            <Input placeholder="https://..." />
                        </Form.Item>
                        <Button size="large" type="primary" htmlType="submit" loading={loading}>{t('confirm', "Confirm")}</Button>
                    </Form>
                </div>
            </div>
            :
            <div className="margin-a-m">
                <table className="key-value-table">
                    <tbody>
                        <tr>
                            <th style={{ width: '300px' }}>{t('integration_id', "Integration ID")}</th>
                            <td>{integration.id}</td>
                        </tr>
                        <tr>
                            <th>{t('status', "Status")}</th>
                            <td>
                                {integration.status === 'connected' && <span>
                                    <Badge status="success" /> {t('connected', "Connected")}
                                    <Popconfirm placement="top" title={t('disconnect_webhook_confirm', "Do you really want to disconnect Webhook?")} onConfirm={disconnect.bind(null, integration)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                                        <Button className="margin-l-m" loading={loading} ghost size="small" danger={true}>{t('disconnect', "Disconnect")}</Button>
                                    </Popconfirm>
                                </span>}
                            </td>
                        </tr>
                        <tr>
                            <th>URL endpoint</th>
                            <td>
                                {integration.webhookSettings.endpoint}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        }
    </div>
}

export default IntegrationsWebhook