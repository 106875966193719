// import { Component } from 'react'

import {
  Table,
  Tooltip,
  Tag,
} from 'antd';
import t from '../../../utils/translate'
import Formatters from '../../../utils/formatters'
import Numeral from 'numeral'
import { forEach, toArray } from 'lodash'

const OrderChannelsDependenciesTable = ({ data, project }) => {


  const currentNodesMap: any = {}

  // gather links for each node
  forEach(data.currentPeriod.nodes, (node: any) => {
    node.total = 0
    node.targets = {}
    currentNodesMap[node.id] = node
  })

  forEach(data.currentPeriod.links, link => {
    currentNodesMap[link.source].total += link.sourceWeight
    currentNodesMap[link.source].targets[link.target] = link.targetWeight
  })

  const previousNodesMap: any = {}

  // gather links for each node
  forEach(data.previousPeriod.nodes, (node: any) => {
    node.total = 0
    node.targets = {}
    previousNodesMap[node.id] = node
  })

  forEach(data.previousPeriod.links, link => {
    previousNodesMap[link.source].total += link.sourceWeight
    previousNodesMap[link.source].targets[link.target] = link.targetWeight
  })

  // console.log('currentNodesMap', currentNodesMap);
  // console.log('previousNodesMap', previousNodesMap);

  // compute cohort max value
  let maxValue = 0

  forEach(currentNodesMap, (node, k) => {
    forEach(node.targets, (targetWeight, targetK) => {
      const percent = (node.total > 0 && targetWeight > 0) ? (targetWeight * 100) / node.total : 0
      if (percent > maxValue) {
        maxValue = percent
      }
    })
  })

  const tenthMaxValue = maxValue > 0 ? maxValue / 10 : 0

  const firstSessionName = t('first_session', "First session")
  const lastSessionName = t('last_session', "Last session")

  const nodeName = (name: string) => {
    if (name === '_first_touch') {
      return firstSessionName
    }
    if (name === '_last_touch') {
      return lastSessionName
    }
    return name
  }


  const columns: any[] = [{
    title: <div className="size-12 opacity-60">
      <div className="padding-b-s">{t('next_step', "Next step")} →</div>
      ↓ {t('current_step', "Current step")}
    </div>,
    key: 'name',
    width: 150,
    fixed: 'left',
    render: (record: any) => {
      let hasGroupName = true
      let name = record.name
      if (record.name === "_first_touch") {
        name = firstSessionName
        hasGroupName = false
      }
      if (record.name === "_last_touch") {
        name = lastSessionName
        hasGroupName = false
      }

      // console.log(name, hasGroupName);

      const channelGroup = project.channelGroups.find((x: any) => x.id === record.groupId)

      return <div>
        <div className="padding-b-s size-12">
          {name}
        </div>
        {hasGroupName === true && <Tag className="xsmall" color={channelGroup.color}>{channelGroup.name}</Tag>}
      </div>
    }
  }]

  const currentNodesMapArray = toArray(currentNodesMap)

  currentNodesMapArray.forEach((nextStepNode: any) => {

    // dont put _first_touch and _last_touch in columns
    if (nextStepNode.name === '_first_touch' || nextStepNode.name === '_last_touch') {
      return
    }

    const channelGroup = project.channelGroups.find((x: any) => x.id === nextStepNode.groupId)

    columns.push({
      title: <div><div className="padding-b-s size-12">{nextStepNode.name}</div><Tag className="xsmall" color={channelGroup.color}>{channelGroup.name}</Tag></div>,
      key: nextStepNode.name,
      width: 150,
      render: (currentNode: any) => {

        // same node can have data
        // if (currentNode.id === nextStepNode.id) {
        //   return <div className="ant-cohort">
        //     <div className="ant-cohort-table">
        //       <div className="value">-</div>
        //     </div>
        //   </div>
        // }

        const previousRecord = previousNodesMap[currentNode.id]

        // console.log('channel', channel);
        // console.log('currentNode', currentNode);
        // console.log('previousRecord', previousRecord);
        let percent = 0
        let previousPercent = 0

        // if has link
        if (currentNode.targets[nextStepNode.id]) {
          percent = (currentNode.targets[nextStepNode.id] * 100) / currentNode.total
        }

        if (previousRecord && previousRecord.targets[nextStepNode.id] && previousRecord.total) {
          previousPercent = (previousRecord.targets[nextStepNode.id] * 100) / previousRecord.total
        }

        let opacity = 0

        if (maxValue > 0) {
          if (percent >= tenthMaxValue) opacity = 0.2
          if (percent >= tenthMaxValue * 2) opacity = 0.3
          if (percent >= tenthMaxValue * 3) opacity = 0.4
          if (percent >= tenthMaxValue * 4) opacity = 0.5
          if (percent >= tenthMaxValue * 5) opacity = 0.6
          if (percent >= tenthMaxValue * 6) opacity = 0.7
          if (percent >= tenthMaxValue * 7) opacity = 0.8
          if (percent >= tenthMaxValue * 8) opacity = 0.9
          if (percent >= tenthMaxValue * 9) opacity = 1
        }

        const percentFormatted = Numeral(percent).format('0[.]0') + '%'

        return <div className="ant-cohort" style={{ backgroundColor: 'rgba(129,212,250,' + opacity + ')' }}>
          {currentNode.name === '_first_touch' && <Tooltip title={t('channels_dependencies_first_touch_tooltip', "{percent} of the first contribution come from {channel}.", { channel: nodeName(nextStepNode.name), percent: percentFormatted })}>
            <div className="ant-cohort-table">
              <div className="value">{percentFormatted} <span className="padding-l-s size-10">{Formatters.growthRate(percent, previousPercent || 0)}</span></div>
            </div>
          </Tooltip>}

          {currentNode.name === '_last_touch' && <Tooltip title={t('channels_dependencies_last_touch_tooltip', "{percent} of the last contribution come from {channel}.", { channel: nodeName(nextStepNode.name), percent: percentFormatted })}>
            <div className="ant-cohort-table">
              <div className="value">{percentFormatted} <span className="padding-l-s size-10">{Formatters.growthRate(percent, previousPercent || 0)}</span></div>
            </div>
          </Tooltip>}


          {currentNode.name !== '_first_touch' && currentNode.name !== '_last_touch' && <Tooltip title={t('channels_dependencies_tooltip', "{percent} of the contributions from {channel} are followed by a contribution from {target}.", { channel: nodeName(currentNode.name), percent: percentFormatted, target: nodeName(nextStepNode.name) })}>
            <div className="ant-cohort-table">
              <div className="value">{percentFormatted} <span className="padding-l-s size-10">{Formatters.growthRate(percent, previousPercent || 0)}</span></div>
            </div>
          </Tooltip>}
        </div>
      }
    })
  })

  return <div>
    <Table
      dataSource={currentNodesMapArray}
      rowKey="id"
      className="edge-table block"
      pagination={false}
      columns={columns}
      scroll={{ y: window.screen.availHeight - 60 * 70 - 45, x: (currentNodesMapArray.length * 150) + 150 }}
    />
  </div>
}

export default OrderChannelsDependenciesTable


