import { useState, useEffect } from 'react'

import { ArrowRightOutlined } from '@ant-design/icons';

import { Input, Button, Row, Col, Select, Modal, Radio, Form, message } from 'antd';
import t from 'utils/translate'
import _ from 'lodash'
import Industries from 'utils/industries'
import Currencies from 'utils/currencies'
import { useOrganizationContext, OrganizationContextValue } from 'components/organizations'
import { useHistory, useLocation } from 'react-router';
import AppLayout from 'components/app/layout';

const CreateProject = () => {

  const [loading, setLoading] = useState(false)
  const [demoModalVisible, setDemoModalVisible] = useState(false)
  const [demoValue, setDemoValue] = useState('order')
  const [isRealProject, setIsRealProject] = useState(false)
  const [form] = Form.useForm()
  const orgCtx: OrganizationContextValue = useOrganizationContext()
  const history = useHistory() as any
  const location = useLocation() as any

  useEffect(() => {
    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Create project (org: ' + orgCtx.currentOrganization.id + ')',
        page: location.pathname,
        props: {
          orgId: orgCtx.currentOrganization.id,
        }
      })
      window.cmAgent.dispatch()
    }
  }, [orgCtx.currentOrganization.id, location.pathname])

  const selectProjectType = (type: any) => {
    if (type === 'demo') {
      setDemoModalVisible(true)
    } else {
      setIsRealProject(true)
    }
  }

  const cancelDemo = () => {
    if (loading === true) {
      return false
    }
    setDemoModalVisible(false)
  }

  const createDemoProject = () => {
    if (loading === true) {
      return
    }
    else {
      setLoading(true)
    }

    orgCtx.ajaxRequest({
      method: 'post',
      url: '/projects.createDemo',
      data: {
        organizationId: orgCtx.currentOrganization.id,
        kind: demoValue
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        message.error(errorMessage)
        setLoading(false)
        return
      }

      let projects = orgCtx.projects
      projects.push(response.data.project)
      orgCtx.setProjects(projects)

      message.success(t('project_created', "Your new project has successfully been created!"))

      if (window.cmAgent) {
        window.cmAgent.event({
          label: 'createDemoProject',
          props: {
            orgId: response.data.project.organizationId,
            projectId: response.data.project.id,
          }
        })
        window.cmAgent.dispatch()
      }

      history.push('/organizations/' + orgCtx.currentOrganization.id + '/projects/' + response.data.project.id)
    })
  }

  const onFinish = (values: any) => {

    if (loading === true) {
      return
    }
    else {
      setLoading(true)
    }

    values.organizationId = orgCtx.currentOrganization.id
    // set default
    values.defaultUserTimezone = orgCtx.admin.timezone
    values.defaultUserLanguage = orgCtx.admin.language
    values.defaultUserCountry = 'FR'

    orgCtx.ajaxRequest({
      method: 'post',
      url: '/projects.create',
      data: values
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        message.error(errorMessage)
        setLoading(false)
        return
      }

      let projects = orgCtx.projects
      projects.push(response.data.project)
      orgCtx.setProjects(projects)

      message.success(t('project_created', "Your new project has successfully been created!"))


      if (window.cmAgent) {
        window.cmAgent.event({
          label: 'createProject',
          props: {
            orgId: orgCtx.currentOrganization.id,
            projectId: response.data.project.id,
          }
        })
        window.cmAgent.dispatch()
      }

      history.push('/organizations/' + orgCtx.currentOrganization.id + '/projects/' + response.data.project.id + '/setup')
    })
  }

  // console.log(props);

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  }

  const maxIdLength = 25

  return <AppLayout currentOrganization={orgCtx.currentOrganization} admin={orgCtx.admin} firebaseUser={orgCtx.firebaseUser} projects={orgCtx.projects}>
    <h1 className="margin-b-l">{t('create_a_new_project', "Create a new project")}</h1>


    {demoModalVisible === true && <Modal
      title={t('create_demo_project', "Create a demo project")}
      visible={true}
      confirmLoading={loading}
      closable={!loading}
      maskClosable={!loading}
      onOk={createDemoProject}
      onCancel={cancelDemo}
      footer={[
        <Button key="back" disabled={loading} onClick={cancelDemo}>{t('cancel', "Cancel")}</Button>,
        <Button key="submit" type="primary" loading={loading} onClick={createDemoProject}>{t('confirm', "Confirm")}</Button>,
      ]}
    >
      <p>{t('select_demo_project', "What type of demo project are you interested in?")}</p>
      <Radio.Group onChange={(e: any) => setDemoValue(e.target.value)} value={demoValue}>
        <Radio style={radioStyle} value="order">{t('orders_asos', "Orders (i.e: ASOS)")}</Radio>
        <Radio disabled style={radioStyle} value="leadsB2C">{t('leads_b2c', "Leads B2C (i.e: HSBC)")}</Radio>
        <Radio disabled style={radioStyle} value="leadsB2B">{t('leads_b2b', "Leads B2B (i.e: SAP)")}</Radio>
        <Radio disabled style={radioStyle} value="subscriptionB2C">{t('subscription_b2c', "Subscription B2C (i.e: Birchbox)")}</Radio>
        <Radio disabled style={radioStyle} value="subscriptionB2B">{t('subscription_b2b', "Subscription B2B (i.e: Salesforce)")}</Radio>
      </Radio.Group>
    </Modal>}

    {isRealProject === false ?
      <Row gutter={24} className="margin-a-m">
        <Col span={12}>
          <div className="block-bordered" onClick={selectProjectType.bind(null, 'demo')}>
            <div className="block-bordered-title">{t('demo_project', "Demo project")}</div>
            <div className="text-center">{t('demo_project_info', "Generate a demo project filled with dummy data.")}</div>
          </div>
        </Col>
        <Col span={12}>
          <div className="block-bordered" onClick={selectProjectType.bind(null, 'real')}>
            <div className="block-bordered-title">{t('real_project', "Real project")}</div>
            <div className="text-center">{t('real_project_info', "Setup a real project for your business.")}</div>
          </div>
        </Col>
      </Row>
      : <div className="block padding-a-l">
        <Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }} layout="horizontal" onFinish={onFinish} style={{ textAlign: 'left' }}>

          <Form.Item name="name" label={t('project_name', "Project name")} rules={[{ required: true, message: t('field_required', "This field is required!") }]}>
            <Input placeholder="i.e: My app / website" onChange={(e: any) => {
              const name = e.target.value
              if (name) {
                let newId = _.truncate(_.camelCase(name).toLowerCase(), { length: maxIdLength, omission: '' })
                if (newId !== '') {
                  form.setFieldsValue({ 'id': newId })
                }
              }
            }} />
          </Form.Item>

          <Form.Item name="id" label={t('project_id', "Project ID")} rules={[{ required: true, type: 'string', max: maxIdLength, pattern: /^[a-z0-9]+$/, message: t('only_x_alphanumeric_characters', "Only {x} alphanumeric & lower case characters allowed!", { x: maxIdLength }) }]}>
            <Input addonBefore={orgCtx.currentOrganization.id + '_'} placeholder="i.e: mywebsite" />
          </Form.Item>

          <Form.Item name="websiteUrl" initialValue="https://" label={t('website_url', "Website URL")} rules={[{ required: true, type: 'url', message: t('valid_url_required', "A valid URL is required!") }]}>
            <Input />
          </Form.Item>

          <Form.Item name="privacyPolicyUrl" label={t('privacy_policy_url', "URL of your Privacy Policy")} extra={t('privacy_policy_url_info', "The purpose of the users-related data collected by your Organization via Captain Metrics (cookies, emails, orders...), should be clearly disclosed in your Privacy Policy. Your Captain Metrics project can be suspended at anytime if your Privacy Policy doesn't comply with global regulations (i.e: GDPR...).")} rules={[{ required: true, type: 'url', message: t('valid_url_required', "A valid URL is required!") }]}>
            <Input />
          </Form.Item>

          <Form.Item name="dataProtectionOfficerId" initialValue={_.toArray(orgCtx.currentOrganization.admins)[0].id} label={t('data_protection_officer', "Data Protection Officer")} extra={t('dpo_info', "The Data Protection Officer is responsible for overseeing data protection strategy and implementation to ensure compliance with GDPR requirements.")} rules={[{ required: true, message: t('field_required', "This field is required!") }]}>
            <Select
              showSearch
              placeholder={t('select_a_value', "Select a value")}
            >
              {_.toArray(orgCtx.currentOrganization.admins).map(admin => <Select.Option key={admin.id} value={admin.id}>{admin.firstName + ' ' + admin.lastName}</Select.Option>)}
            </Select>
          </Form.Item>

          <Form.Item name="currency" initialValue={orgCtx.currentOrganization.currency} label={t('main_currency', "Main currency")} rules={[{ required: true, message: t('field_required', "This field is required!") }]}>
            <Select
              showSearch
              placeholder={t('select_a_currency', "Select a currency")}
              optionFilterProp="children"
              filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {Currencies.map((c) => <Select.Option key={c.code} value={c.code}>{c.code + ' - ' + c.currency}</Select.Option>)}
            </Select>
          </Form.Item>

          <Form.Item name="industry" label={t('industry', "Industry")} rules={[{ required: true, message: t('field_required', "This field is required!") }]}>
            <Select>
              {Industries.map((i: any) => <Select.Option key={i.id} value={i.id}>{t(i.id, i.name)}</Select.Option>)}
            </Select>
          </Form.Item>

          <Col xs={20} sm={20} className="text-right">
            <Button type="primary" loading={loading} htmlType="submit">{t('next', "Next")} <ArrowRightOutlined /></Button>
          </Col>
        </Form>
      </div>}
  </AppLayout>
}

export default CreateProject