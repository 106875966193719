import { Component } from 'react'

import t from '../../utils/translate'
import { Table, Tooltip, message } from 'antd'
import Formatters from '../../utils/formatters'
import N from 'numeral'
import removeLeadingDates from 'utils/removeLeadingDates'

class OrdersPerDomain extends Component<any, any> {
  _isMounted = false;

  constructor(props: any) {
    super(props)
    
    this.state = {
      loading: true,
      current: [],
      previous: [],
    }

    this.fetch = this.fetch.bind(this)
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetch()
  }

  componentDidUpdate(prevProps: any) {
    // check url params have changed
    if (prevProps.refreshAt !== this.props.refreshAt) {
      window.setTimeout(() => this.fetch())
    } else if (prevProps.project.id !== this.props.project.id) {
      window.setTimeout(() => this.fetch())
    }
  }

  fetch() {

    if (!this.state.loading) {
      this.setState({loading: true})
    }

    const opts: any = {
      measures: ['TimelineOrders.count', 'TimelineOrders.revenue', 'TimelineOrders.averageCart', 'TimelineOrders.retentionRatio'],
      dimensions: ['TimelineOrders.domainId'],
      timeDimensions: [
        {
          "dimension": 'TimelineOrders.truncCreatedAt',
          "granularity": null,
          "compareDateRange": [
            [this.props.dateFrom,this.props.dateTo],
            [this.props.dateFromPrevious,this.props.dateToPrevious]
          ]
        }
      ],
      timezone: this.props.timezone
    }


    this.props.cubejsApi.load(opts).then((result: any) => {
      const [current, previous] = result.decompose()

      const currentLines = current.tablePivot()
      const previousLines = previous.tablePivot()

      removeLeadingDates(currentLines)
      removeLeadingDates(previousLines)

      this.setState({
        loading: false,
        current: currentLines,
        previous: previousLines,
      })
    })
    .catch((error: any) => {

      console.log(error)

      let msg = error.message

      if (error.response && error.response.status === 400) {
        switch(error.response.data.message) {
          default: 
          msg = error.response.data.message
        }
      }

      message.error(msg)
      this.setState({loading: false})
    })
  }

  render() {

    // console.log('state kpi', this.state);

    return <div className="padding-a-m dash-table-stats">
      <Table 
        dataSource={this.state.current}
        loading={this.state.loading}
        rowKey="TimelineOrders.domainId"
        columns={[
          {
            title: t('domain', "Domain"),
            key: 'domain',
            render: (record: any) => {
              const domain = this.props.project.domains.find((x: any) => x.id === record['TimelineOrders.domainId'])
              if (domain) return domain.name
              return record['TimelineOrders.domainId']
            }
          },
          {
            title: t('orders', "Orders"),
            key: 'orders',
            sorter: (a: any, b: any) => {
              if (a['TimelineOrders.count'] < b['TimelineOrders.count']) { return -1 }
              if (a['TimelineOrders.count'] > b['TimelineOrders.count']) { return 1 }
              return 0
            },
            sortOrder: 'descend',
            showSorterTooltip: false,
            render: (record: any) => <span className="value">{N(record['TimelineOrders.count']).format('0,0')}</span>
          },
          {
            title: t('revenue', "Revenue"),
            key: 'revenue',
            render: (record: any) => Formatters.currency(this.props.project.currency, record['TimelineOrders.revenue'])
          },
          {
            title: t('avg_cart', "Avg. cart"),
            key: 'averageCart',
            render: (record: any) => Formatters.currency(this.props.project.currency, record['TimelineOrders.averageCart'])
          },
          {
            title: <Tooltip title="Retention %">Ret.</Tooltip>,
            key: 'retention',
            render: (record: any) => N(record['TimelineOrders.retentionRatio']).format('0[.]0')+'%',
          },
        ]}
        pagination={false}
        size="middle"
      />
    </div>
  }
}

export default OrdersPerDomain