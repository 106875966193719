import { useEffect, useState, createContext, useContext, ReactNode, useMemo, useRef } from 'react'

import { ReloadOutlined } from '@ant-design/icons'

import {
  Button,
  Tooltip,
  Select,
  Tabs,
} from 'antd';
import t from '../../../utils/translate'
import _ from 'lodash'
import Moment from 'moment-timezone'
import QS from 'qs'
import DateRange from '../../projects/_dateRange'
import Channels from './channels'
import Campaigns from './campaigns'
import Postviews from './postviews'
import LandingPages from './landingPages'
import TopPages from './topPages'
import Referrers from './referrers'
import ConversionPaths from './conversionPaths'
import ChannelsDependencies from './channelsDependencies'
import CrossDevices from './crossDevices'
import CrossDomains from './crossDomains'
import SegmentsSelect from '../../segments/_select'
import AppLayout from 'components/app/layout';
import { ConversionRule } from 'components/app/interfaces'
import { useProjectContext, ProjectContextValue } from 'components/projects'
import { useHistory, useLocation } from 'react-router'

const AnalyticsContext = createContext<AnalyticsContextValue | null>(null);

export function useAnalyticsContext(): AnalyticsContextValue {
  const contextValue = useContext(AnalyticsContext);
  if (!contextValue) {
    throw new Error("Missing AnalyticsContextProvider in its parent.");
  }
  return contextValue;
}

export interface AnalyticsContextValue {
  dateFrom: string
  dateTo: string
  // dateLabel: string
  dateRangeVs: 'previous_period' | 'previous_year'
  dateFromPrevious: string
  dateToPrevious: string
  attribution: 'linear' | 'contributive'
  timezone: string,
  refreshAt: string
  sortKey: string | undefined
  sortOrder: 'ascend' | 'descend'
  conversionRule?: ConversionRule
  conversionType?: 'all' | 'acquisition' | 'retention'
  domain?: string
  segment?: string
  searchName?: string
  // setExtraToolbar: (node: ReactNode | undefined) => void
  updateParams: (params: any[]) => void
  cubejsApi: any
}

const AnalyticsWeb = () => {

  const projectCtx: ProjectContextValue = useProjectContext()
  const history = useHistory() as any
  const location = useLocation() as any

  const loadedAt = useRef(Moment().unix())
  const [extraToolbar, setExtraToolbar] = useState<ReactNode | undefined>(undefined)
  const prevProjectId = useRef(undefined)

  const conversionsOptions = useMemo(() => {
    const result = []
    projectCtx.currentProject.conversionRules.forEach((cr: any) => {
      if (cr.deletedAt) return
      result.push({
        key: cr.id + '/all',
        conversionRuleId: cr.id,
        name: cr.name + ': ' + t('all', "All"),
        conversionType: 'all'
      })
      result.push({
        key: cr.id + '/acquisition',
        conversionRuleId: cr.id,
        name: cr.name + ': Acquisition',
        conversionType: 'acquisition'
      })
      result.push({
        key: cr.id + '/retention',
        conversionRuleId: cr.id,
        name: cr.name + ': Retention',
        conversionType: 'retention'
      })
    })
    return result
  }, [projectCtx.currentProject.conversionRules])


  useEffect(() => {

    if (prevProjectId.current === projectCtx.currentOrganization.id) return
    prevProjectId.current = projectCtx.currentOrganization.id

    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Reports (project: ' + projectCtx.currentProject.id + ')',
        page: location.pathname,
        props: {
          orgId: projectCtx.currentOrganization.id,
          projectId: projectCtx.currentProject.id
        }
      })
      window.cmAgent.dispatch()
    }
  }, [history, location.pathname, projectCtx.currentOrganization.id, projectCtx.currentProject.id, projectCtx.projects])


  const refresh = () => {
    // this.setState({ refreshAt: Moment().unix() })
    updateParams([
      { k: 'refreshAt', v: Moment().unix() },
    ])
  }

  const setConversion = (value: any) => {
    const bits = value.split('/')
    updateParams([
      { k: 'conversionRule', v: bits[0] },
      { k: 'conversionType', v: bits[1] },
    ])
  }

  const setAttribution = (value: any) => {
    updateParams([{ k: 'attribution', v: value }])
  }

  const setDateRange = (label: string, range: any) => {
    // this.setState({ dateRangeValue: range, dateLabel: label })
    // this.refresh()
    updateParams([
      { k: 'dateFrom', v: range[0] },
      { k: 'dateTo', v: range[1] },
      { k: 'dateLabel', v: label },
    ])
  }

  const setDateRangeVs = (value: any) => {
    // this.setState({ dateRangeVs: value })
    // this.refresh()
    updateParams([{ k: 'dateRangeVs', v: value }])
  }

  const updateParams = (updatedParams: any[]) => {
    let params = QS.parse(location.search, { ignoreQueryPrefix: true })
    updatedParams.forEach((up: any) => {
      params[up.k] = up.v
    })
    history.push('/organizations/' + projectCtx.currentOrganization.id + '/projects/' + projectCtx.currentProject.id + '/analytics/web?' + QS.stringify(params))
  }

  const setSegment = (segment: any) => {
    updateParams([{ k: 'segment', v: segment }])
  }

  const changeTab = (value: any) => {
    setExtraToolbar(undefined)
    let params = QS.parse(location.search, { ignoreQueryPrefix: true })
    params.tab = value
    history.push('/organizations/' + projectCtx.currentOrganization.id + '/projects/' + projectCtx.currentProject.id + '/analytics/web?' + QS.stringify(params))
  }


  const params: any = QS.parse(location.search, { ignoreQueryPrefix: true })

  const tab = params.tab || 'channels'

  const sortKey = params.sortKey
  const sortOrder = params.sortOrder === 'ascend' ? 'ascend' : 'descend'

  const refreshAt = params.refreshAt || loadedAt

  const currentDomain = params.domain || '_all'

  const segmentId = params.segment || '_all'

  const attribution = params.attribution || 'contributive'

  const conversionRuleId = params.conversionRule || _.get(conversionsOptions, '[0].conversionRuleId')
  const conversionType = params.conversionType || 'all'

  const conversionRule = useMemo(() => {
    return projectCtx.currentProject.conversionRules.find((cr: any) => cr.id === conversionRuleId)
  }, [conversionRuleId, projectCtx.currentProject.conversionRules])

  const webDomains = projectCtx.currentProject.domains.filter((d: any) => !d.deletedAt && d.kind === 'web')

  const dateRangeVs = params.dateRangeVs || 'previous_period'
  const dateLabel = params.dateLabel || 'last_30_days'
  const dateFrom = params.dateFrom ? Moment(params.dateFrom).tz(projectCtx.admin.timezone) : Moment().tz(projectCtx.admin.timezone).subtract(30, 'day').startOf('day')
  const dateTo = params.dateTo ? Moment(params.dateTo).tz(projectCtx.admin.timezone) : Moment().tz(projectCtx.admin.timezone).subtract(1, 'day').endOf('day')

  let dateFromPrevious: any = Moment(dateFrom)
  let dateToPrevious: any = Moment(dateTo)


  if (dateRangeVs === "previous_period") {

    // add one day to the diff to avoid days overlaping between the to ranges
    const diff = dateTo.diff(dateFrom, 'days') + 1
    // console.log('diff is', diff);

    dateToPrevious = dateToPrevious.subtract(diff, 'days')
    dateFromPrevious = dateFromPrevious.subtract(diff, 'days')
  }

  if (dateRangeVs === "previous_year") {
    dateToPrevious = dateToPrevious.subtract(1, 'years')
    dateFromPrevious = dateFromPrevious.subtract(1, 'years')
  }

  dateFromPrevious = dateFromPrevious.format('YYYY-MM-DD')
  dateToPrevious = dateToPrevious.format('YYYY-MM-DD')

  // const reportProps = {
  //   updateParams: updateParams,
  //   conversionRule: conversionRule,
  //   refreshAt: refreshAt,
  //   dateRangeVs: dateRangeVs as string,
  //   cubejsApi: projectCtx.cubejsApi,
  //   timezone: projectCtx.admin.timezone as string,
  //   // new props for shouldUpdate
  //   segmentId: segmentId,
  //   domainId: currentDomain,
  //   conversionType: conversionType,
  //   attribution: attribution,
  //   dateFrom: dateFrom.format('YYYY-MM-DD'),
  //   dateTo: dateTo.format('YYYY-MM-DD'),
  //   dateFromPrevious: dateFromPrevious,
  //   dateToPrevious: dateToPrevious,
  // }

  const ctx: AnalyticsContextValue = {
    dateFrom: dateFrom.format('YYYY-MM-DD'),
    dateTo: dateTo.format('YYYY-MM-DD'),
    dateRangeVs: dateRangeVs,
    dateFromPrevious: dateFromPrevious,
    dateToPrevious: dateToPrevious,
    attribution: attribution,
    timezone: projectCtx.admin.timezone,
    refreshAt: refreshAt,
    sortKey: sortKey,
    sortOrder: sortOrder,
    conversionRule: conversionRule,
    conversionType: conversionType,
    domain: currentDomain,
    segment: segmentId,
    searchName: params.searchName,
    updateParams: updateParams,
    cubejsApi: projectCtx.cubejsApi,
  }

  // console.log('test', JSON.stringify(ctx))
  // console.log('rerender with ctx', ctx)
  // console.log('reportProps', reportProps)


  return <AppLayout currentProject={projectCtx.currentProject} currentOrganization={projectCtx.currentOrganization} admin={projectCtx.admin} firebaseUser={projectCtx.firebaseUser} projects={projectCtx.projects}>
    <h1>
      <span className="actions">
        <span className="padding-l-s">
          <SegmentsSelect value={segmentId} onChange={setSegment} segments={projectCtx.currentProject.segments} forCompanies={conversionRule && conversionRule.forCompanies} />
        </span>

        {tab !== 'cross-domains' && <Select size="small" dropdownMatchSelectWidth={false} className="size-12" onChange={(value: any) => updateParams([{ k: 'domain', v: value }])} value={currentDomain} placeholder={t('domain', "Domain")}>
          <Select.Option key="_all" value="_all">{t('all_domains', "All domains")}</Select.Option>
          {webDomains.map((d: any) => <Select.Option key={d.id} value={d.id}>{d.name}</Select.Option>)}
        </Select>}

        {tab !== 'top-pages' && tab !== 'conversion-paths' && <span className="padding-l-s">
          <Select size="small" dropdownMatchSelectWidth={false} className="size-12" onChange={(value: any) => setConversion(value)} value={conversionRuleId + '/' + conversionType} placeholder={t('conversion', "Conversion")}>
            {conversionsOptions.map((d: any) => <Select.Option key={d.key} value={d.key}>{d.name}</Select.Option>)}
          </Select>
        </span>}

        {tab !== 'top-pages' && tab !== 'conversion-paths' && <span className="padding-l-s">
          <Select size="small" dropdownMatchSelectWidth={false} className="size-12" onChange={setAttribution} value={attribution} placeholder="Attribution">
            <Select.Option value="contributive">Attribution: contributive</Select.Option>
            <Select.Option value="linear">Attribution: linear</Select.Option>
          </Select>
        </span>}

        <span className="padding-l-s">
          <DateRange
            timezone={projectCtx.admin.timezone}
            vs={dateRangeVs}
            dateRange={[dateFrom, dateTo]}
            labelKey={dateLabel}
            onVsChange={setDateRangeVs}
            onChange={setDateRange} />


          <Tooltip placement="topRight" title={t('last_refresh_at', "Refreshed {at}", { at: Moment.unix(refreshAt).fromNow() })}>
            <Button className="margin-l-s" size="small" type="default" shape="circle" icon={<ReloadOutlined />} onClick={refresh} />
          </Tooltip>

          {extraToolbar}
        </span>
      </span>
      Web Analytics
    </h1>

    <AnalyticsContext.Provider value={ctx}>
      <Tabs activeKey={tab} onChange={changeTab} className="margin-t-m">
        <Tabs.TabPane tab={t('channels', "Channels")} key="channels">
          {tab === 'channels' && <Channels setExtraToolbar={setExtraToolbar} />}
        </Tabs.TabPane>

        {projectCtx.currentProject.integrations.find((x: any) => x.kind === 'gamned') && <Tabs.TabPane tab="Postviews" key="postviews">
          {tab === 'postviews' && <Postviews setExtraToolbar={setExtraToolbar} />}
        </Tabs.TabPane>}

        <Tabs.TabPane tab={t('campaigns', "Campaigns")} key="campaigns">
          {tab === 'campaigns' && <Campaigns setExtraToolbar={setExtraToolbar} />}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Referrers" key="referrers">
          {tab === 'referrers' && <Referrers />}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Landing pages" key="landing-pages">
          {tab === 'landing-pages' && <LandingPages setExtraToolbar={setExtraToolbar} />}
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('top_pages', "Top pages")} key="top-pages">
          {tab === 'top-pages' && <TopPages setExtraToolbar={setExtraToolbar} />}
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('conversion_paths', "Conversion paths")} key="conversion-paths">
          {tab === 'conversion-paths' && <ConversionPaths />}
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('channels_dependencies', "Channels dependencies")} key="channels-dependencies">
          {tab === 'channels-dependencies' && <ChannelsDependencies />}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Cross-devices" key="cross-devices">
          {tab === 'cross-devices' && <CrossDevices />}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Cross-domains" key="cross-domains">
          {tab === 'cross-domains' && <CrossDomains />}
        </Tabs.TabPane>
      </Tabs>
    </AnalyticsContext.Provider>
  </AppLayout>
}

export default AnalyticsWeb