import { useEffect, useState, useRef, useCallback } from 'react'

import { Spin, Tooltip, message } from 'antd';
import t from '../../utils/translate'
import N from 'numeral'

const DashboardOnlineNow = ({ ajaxRequest, projectId, refreshAt, locationSearch }) => {

  const [loading, setLoading] = useState(true)
  const [report, setReport] = useState(undefined)
  const timeout = useRef(undefined)
  const didMount = useRef(false)

  const fetchReport = useCallback(() => {

    setLoading(true)

    ajaxRequest({
      method: 'get',
      url: '/reports.usersOnline',
      params: { projectId: projectId }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        message.error(errorMessage)
        return
      }
      // console.log('online now report', response.data);

      setReport(response.data.report)
      setLoading(false)
    })
  }, [projectId, ajaxRequest])

  // did mount
  useEffect(() => {

    if (didMount.current) return
    didMount.current = true

    timeout.current = window.setInterval(() => {
      fetchReport()
    }, 60000)
    return function () {
      if (timeout.current) {
        window.clearTimeout(timeout.current)
      }
    }
  }, [timeout, fetchReport])


  // fetch on search / refresh
  useEffect(() => {
    fetchReport()
  }, [locationSearch, refreshAt, projectId, fetchReport])


  if (loading) {
    return <span><Spin spinning={true} /></span>
  }

  return <span>
    <Tooltip title={t('in_the_last_5_minutes', "In the last 5 minutes")}>
      <span className="size-15 semibold">{N(report.last5mins).format('0,0')}</span> <span className="widget-title padding-r-l">{t('users_online', "users online")}</span>
    </Tooltip>
    <span className="size-15 semibold">{N(report.last24hours).format('0,0')}</span> <span className="widget-title">{t('users_in_last_24h', "users in the last 24h")}</span>
  </span>
}

export default DashboardOnlineNow