import React, { Component } from 'react'

// import t from '../../utils/translate'
import { Spin, Tooltip, Popover } from 'antd'
import Formatters from '../../../utils/formatters'
import N from 'numeral'
import Colors from '../../../utils/colors'
import _ from 'lodash'
import cn from 'classnames'
import {
    Chart,
    Geom,
    Axis,
    // Tooltip as ChartTooltip,
} from "bizcharts"

class CombinedKPI extends Component<any, any> {
    _isMounted = false;

    constructor(props: any) {
        super(props)

        this.state = {
            loading: true,
            data: [],
            total: 0,
            dataPrevious: [],
            totalPrevious: 0,
            currencies: {}
        }

        this.fetchAll = this.fetchAll.bind(this)
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetchAll()
    }

    componentDidUpdate(prevProps: any) {
        // check url params have changed
        if (prevProps.refreshAt !== this.props.refreshAt) {
            this.fetchAll()
        } else if (prevProps.partnershipId !== this.props.partnershipId) {
            this.fetchAll()
        } else if (prevProps.organizationId !== this.props.organizationId) {
            this.fetchAll()
        } else if (JSON.stringify(prevProps.filters) !== JSON.stringify(this.props.filters)) {
            this.fetchAll()
        }
    }

    fetchAll() {
        if (!this.state.loading) {
            this.setState({ loading: true })
        }
        const opts: any = {
            measures: [this.props.measure],
            dimensions: this.props.dimensions || [],
            timeDimensions: [
                {
                    dimension: this.props.timeDimension,
                    granularity: null,
                    compareDateRange: [
                        [this.props.dateFrom, this.props.dateTo],
                        [this.props.dateFromPrevious, this.props.dateToPrevious]
                    ]
                }
            ],
            timezone: this.props.timezone
        }

        const optsPerDay: any = {
            measures: [this.props.measure],
            dimensions: this.props.dimensions || [],
            timeDimensions: [
                {
                    dimension: this.props.timeDimension,
                    granularity: 'day',
                    compareDateRange: [
                        [this.props.dateFrom, this.props.dateTo],
                        [this.props.dateFromPrevious, this.props.dateToPrevious]
                    ]
                }
            ],
            timezone: this.props.timezone
        }

        if (this.props.segments) {
            opts.segments = this.props.segments
            optsPerDay.segments = this.props.segments
        }

        if (this.props.filters) {
            opts.filters = this.props.filters
            optsPerDay.filters = this.props.filters
        }

        // if (this.props.measure === 'TimelineSessions.conversionRate') {
        //     console.log(opts)
        // }
        const tasks: any = []

        this.props.kpiPartnerships.forEach((p: any) => {
            tasks.push(p.cubejs.load(opts))
            tasks.push(p.cubejs.load(optsPerDay))
        })

        if (tasks.length === 0) {
            if (this._isMounted) {
                this.setState({
                    loading: false,
                    data: [],
                    total: 0,
                    dataPrevious: [],
                    totalPrevious: 0
                })
            }
            return
        }

        Promise.all(tasks).then((values: any) => {
            // console.log('values', values);

            let currentTotal = 0
            let previousTotal = 0
            const currentDays: any = []
            const previousDays: any = []
            const currencies: any = {}

            values.forEach((val: any, i: number) => {
                const [current, previous] = val.decompose()
                const currentSeries = current.series()[0]
                const previousSeries = previous.series()[0]
                if (!currentSeries) {
                    return
                }
                const { advertiserCurrency, currencyRate } = this.props.kpiPartnerships[Math.floor(i / 2)]

                if (i % 2 === 0) {
                    // convert currency
                    let currentTotalValue = currentSeries?.series[0].value || 0
                    let previousTotalValue = previousSeries?.series[0].value || 0

                    if (!currencies[advertiserCurrency]) {
                        currencies[advertiserCurrency] = 0
                    }

                    if (this.props.kind === 'currency' && currencyRate !== 1 && currentTotalValue > 0) {
                        currencies[advertiserCurrency] += currentTotalValue
                        currentTotalValue = (currencyRate * currentTotalValue) / 100
                    }
                    if (this.props.kind === 'currency' && currencyRate !== 1 && previousTotalValue > 0) {
                        previousTotalValue = (currencyRate * previousTotalValue) / 100
                    }

                    // totals
                    if (this.props.kind === 'percentage') {
                        currentTotal = i === 0 ? currentTotalValue : (currentTotal + currentTotalValue) / 2
                        previousTotal = i === 0 ? previousTotalValue : (previousTotal + previousTotalValue) / 2
                    } else {
                        currentTotal += currentTotal + currentTotalValue
                        previousTotal += previousTotal + previousTotalValue
                    }
                } else {
                    // days
                    if (currentDays.length === 0) currentDays.push(...currentSeries.series)
                    else {
                        currentSeries.series.forEach((s: any, y: number) => {
                            switch (this.props.kind) {
                                case 'currency':
                                    currentDays[y].value += (currencyRate !== 1 && s.value > 0) ? (currencyRate * s.value) / 100 : s.value
                                    break
                                case 'percentage':
                                    currentDays[y].value = y === 0 ? s.value : (currentDays[y].value + s.value) / 2
                                    break;
                                default:
                                    currentDays[y].value += s.value
                            }
                        })
                    }
                    if (previousDays.length === 0) previousDays.push(...previousSeries.series)
                    else {
                        previousSeries.series.forEach((s: any, y: number) => {
                            switch (this.props.kind) {
                                case 'currency':
                                    previousDays[y].value += (currencyRate !== 1 && s.value > 0) ? (currencyRate * s.value) / 100 : s.value
                                    break
                                case 'percentage':
                                    previousDays[y].value = y === 0 ? s.value : (previousDays[y].value + s.value) / 2
                                    break;
                                default:
                                    previousDays[y].value += s.value
                            }
                        })
                    }
                    // console.log('currentSeries', currentSeries)
                    // console.log('previousSeries', previousSeries)
                }
            })

            // console.log('currentTotal', currentTotal)
            // console.log('previousTotal', previousTotal)
            // console.log('currentDays', currentDays)
            // console.log('previousDays', previousDays)

            if (this._isMounted) {
                this.setState({
                    loading: false,
                    currencies,
                    total: currentTotal,
                    totalPrevious: previousTotal,
                    data: currentDays,
                    dataPrevious: previousDays,
                })
            }
        })
            .catch((error: any) => {

                console.log(error)

                let message = error.message

                if (error.response && error.response.status === 400) {
                    switch (error.response.data.message) {
                        default:
                            message = error.response.data.message
                    }
                }

                message.error(message)
                if (this._isMounted) {
                    this.setState({ loading: false })
                }
            })
    }

    render() {

        // console.log('state kpi', this.state);

        let value: any = 0
        let graphData: any = []

        if (this.state.loading === false) {

            switch (this.props.kind) {
                case 'currency': value = Formatters.currency(this.props.currency, this.state.total); break
                case 'percentage': value = Formatters.percentage(this.state.total); break
                case 'duration': value = Formatters.duration(this.state.total); break
                case 'ratio': value = Formatters.ratio(this.state.total); break
                case 'float': value = Formatters.float(this.state.total); break
                default: value = N(this.state.total).format('0,0[.]00')
            }
            // console.log('value', value);


            this.state.data.forEach((item: any, index: number) => {
                graphData.push({
                    date: item.x,
                    value: item.value,
                    kind: this.props.measure + '_current'
                })
                graphData.push({
                    date: item.x,
                    value: this.state.dataPrevious[index]?.value || 0,
                    kind: this.props.measure + '_previous'
                })
            })

            // console.log('graphData is', graphData);
        }

        // console.log(this.state)

        let valueContent = value

        if (this.props.kind === 'currency') {
            valueContent = <Popover content={<div>
                {_.map(this.state.currencies, (val, cur) => <div key={cur}>{Formatters.currency(cur, val)}</div>)}
            </div>}>{value}</Popover>
        }

        return <div className={cn('dash-kpi', { 'dashed-right-1': this.props.isLast !== true }, { 'active': this.props.activeColumn === this.props.column })}>
            <div className="widget-title size-13">{this.props.tooltip ? <Tooltip title={this.props.tooltip}>{this.props.title}</Tooltip> : this.props.title}</div>
            <div className="dash-kpi-value">{this.state.loading ? <Spin /> : valueContent}</div>
            <div className="dash-kpi-graph">
                <Chart
                    height={30}
                    data={graphData}
                    padding={[0, 0, 0, 0]}
                    scale={{
                        date: {
                            type: 'time',
                            mask: 'yyyy-mm-dd',
                            min: this.props.fromDate,
                            max: this.props.toDate
                        },
                        value: {
                            alias: 'Count'
                        }
                    }}
                    animate
                    autoFit
                >
                    <Axis name="date" title={null} label={null} line={{
                        style: {
                            lineWidth: 1,
                            stroke: '#CFD8DC',
                        }
                    }} />
                    <Axis name="value" visible={false} />
                    <Geom
                        type="line"
                        position="date*value"
                        color={['kind', [Colors[this.props.color], Colors[this.props.color + 'Light']]]}
                        size={1}
                    />
                </Chart>
            </div>
            <div className="dash-kpi-variation">{this.state.loading === true ? '-' : Formatters.growthRate(this.state.total, this.state.totalPrevious, this.props.reverseGrowthRate)}</div>
        </div>
    }
}

export default CombinedKPI