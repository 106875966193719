import React from 'react';
import t from '../../utils/translate'

const DomainsAbout = () => (
  <div className="margin-a-m">
    <p>{t('domains_p1', "In Captain Metrics, a Domain represents a way to access your business, it can be a web browser, a native app or a physical store.")}</p>
    <p>{t('domains_p2', "As you may know, web cookies are stored per domain name (i.e: my-website.com). If your website uses sub-domains (i.e: blog.my-website.com), the web agent won't be able to identify your users. Declaring your domains & sub-domains will activate \"cross-domain\" tracking.")}</p>
    <p>{t('domains_p3', "Tracking a unique web page is sometimes difficult because your URLs may hold variable parameters (i.e: my-website.com/?sessionId=xxx). In such case, we need to filter the URLs of your website to remove unecessary parameters. In a native app, this is not required as there is no URLs.")}</p>
  </div>
);

export default DomainsAbout

