import React, { Component } from 'react'

import { PlusCircleOutlined } from '@ant-design/icons';

import { Table, Button, Tabs, Popconfirm } from 'antd';
import t from '../../utils/translate'
import integrations from '../../utils/integrations'
import _ from 'lodash'
// import Moment from 'moment-timezone'
import QS from 'qs'
import Shopify from './_shopify'
import WooCommerce from './_wooCommerce'
import GoogleAds from './_googleAds'
// import Monday from './_monday'
import Facebook from './_facebook'
import Postmark from './_postmark'
import SparkPost from './_sparkPost'
import Twilio from './_twilio'
import Mailchimp from './_mailchimp'
// import Lazada from './_lazada'
import Webhook from './_webhook'
import Admo from './_admo'
import Amazon from './_amazon'
import Gamned from './_gamned'
import Klaviyo from './_klaviyo'
import AppLayout from 'components/app/layout';
// import Sylius from './_sylius'

class IntegrationsList extends Component<any, any> {

  constructor(props: any) {
    super(props)

    this.state = {
      loading: false,
    }

    this.switchTab = this.switchTab.bind(this)
    this.addIntegration = this.addIntegration.bind(this)
    this.connect = this.connect.bind(this)
    this.disconnect = this.disconnect.bind(this)
    this.toggleMainSource = this.toggleMainSource.bind(this)
    this.toggleNewsletterSource = this.toggleNewsletterSource.bind(this)
    this.toggleRemarketingSource = this.toggleRemarketingSource.bind(this)
    this.toggleGeocodeAddresses = this.toggleGeocodeAddresses.bind(this)
    this.toggleGeocodeTimezones = this.toggleGeocodeTimezones.bind(this)
    this.updateDefaultTimezone = this.updateDefaultTimezone.bind(this)
    this.updateDefaultLanguage = this.updateDefaultLanguage.bind(this)
    this.updateConversionRule = this.updateConversionRule.bind(this)
    this.updateConversionDomain = this.updateConversionDomain.bind(this)
    this.updateSettings = this.updateSettings.bind(this)
  }

  componentDidMount() {
    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Integrations (project: ' + this.props.projectLayout.project.id + ')',
        page: this.props.location.pathname,
        props: {
          orgId: this.props.projectLayout.project.organizationId,
          projectId: this.props.projectLayout.project.id
        }
      })
      window.cmAgent.dispatch()
    }
  }

  addIntegration(kind: string) {

    if (this.state.loading) {
      return
    }

    this.setState({ loading: true })

    const info: any = integrations.find((int: any) => int.kind === kind)

    const existingIds = this.props.projectLayout.project.integrations.filter((int: any) => int.kind === kind)
    let integrationId = kind
    let integrationName = info.name

    if (existingIds.length > 0) {
      const count = existingIds.length + 1
      integrationId = integrationId + count
      integrationName = integrationName + ' ' + count
    }

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/integrations.add',
      data: {
        projectId: this.props.projectLayout.project.id,
        kind: kind,
        id: integrationId,
        name: integrationName
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }

      let projects = _.filter(this.props.organizationLayout.projects, (p: any) => p.id !== response.data.project.id)
      projects.push(response.data.project)
      this.props.organizationLayout.setProjects(projects)

      this.props.app.addMessage('success', t('project_integration_added', "The integration has been added!"))

      if (window.cmAgent) {
        window.cmAgent.event({
          label: 'addIntegration',
          props: {
            orgId: this.props.organizationLayout.organization.id,
            projectId: this.props.projectLayout.project.id,
            integrationKind: kind
          }
        })
        window.cmAgent.dispatch()
      }

      this.setState({ loading: false })
      this.switchTab(integrationId)
    })
  }

  connect(integration: any, payload: any) {
    if (this.state.loading) {
      return
    }

    this.setState({ loading: true })

    const data: any = {
      projectId: this.props.projectLayout.project.id,
      integrationId: integration.id
    }

    data[integration.kind] = payload

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/integrations.connect',
      data: data
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }

      let projects = _.filter(this.props.organizationLayout.projects, (p: any) => p.id !== response.data.project.id)
      projects.push(response.data.project)
      this.props.organizationLayout.setProjects(projects)

      this.props.app.addMessage('success', t('integration_connected', "Your integration has been connected!"))

      this.setState({ loading: false })

      if (window.cmAgent) {
        window.cmAgent.event({
          label: 'connectIntegration',
          props: {
            orgId: this.props.organizationLayout.organization.id,
            projectId: this.props.projectLayout.project.id,
            integrationKind: integration.kind
          }
        })
        window.cmAgent.dispatch()
      }
    })
  }


  disconnect(integration: any) {
    if (this.state.loading) {
      return
    }

    this.setState({ loading: true })

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/integrations.remove',
      data: {
        projectId: this.props.projectLayout.project.id,
        integrationId: integration.id
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }

      let projects = _.filter(this.props.organizationLayout.projects, (p: any) => p.id !== response.data.project.id)
      projects.push(response.data.project)
      this.props.organizationLayout.setProjects(projects)

      this.props.app.addMessage('success', t('integration_disconnected', "Your integration has been disconnected!"))

      this.setState({ loading: false })

      if (window.cmAgent) {
        window.cmAgent.event({
          label: 'disconnectIntegration',
          props: {
            orgId: this.props.organizationLayout.organization.id,
            projectId: this.props.projectLayout.project.id,
            integrationKind: integration.kind
          }
        })
        window.cmAgent.dispatch()
      }
    })
  }


  toggleMainSource(integration: any) {
    if (this.state.loading) {
      return
    }

    this.setState({ loading: true })

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/integrations.toggleMainSource',
      data: {
        projectId: this.props.projectLayout.project.id,
        integrationId: integration.id,
        isMainSource: !integration[integration.kind + 'Settings'].isMainSource
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }

      let projects = _.filter(this.props.organizationLayout.projects, (p: any) => p.id !== response.data.project.id)
      projects.push(response.data.project)
      this.props.organizationLayout.setProjects(projects)


      this.props.app.addMessage('success', t('settings_updated', "Settings updated!"))

      this.setState({ loading: false })
    })
  }

  toggleNewsletterSource(integration: any) {
    if (this.state.loading) {
      return
    }

    this.setState({ loading: true })

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/integrations.toggleNewsletterConsentSource',
      data: {
        projectId: this.props.projectLayout.project.id,
        integrationId: integration.id,
        isNewsletterConsentSource: !integration[integration.kind + 'Settings'].isNewsletterConsentSource
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }

      let projects = _.filter(this.props.organizationLayout.projects, (p: any) => p.id !== response.data.project.id)
      projects.push(response.data.project)
      this.props.organizationLayout.setProjects(projects)


      this.props.app.addMessage('success', t('settings_updated', "Settings updated!"))

      this.setState({ loading: false })
    })
  }

  toggleRemarketingSource(integration: any) {
    if (this.state.loading) {
      return
    }

    this.setState({ loading: true })

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/integrations.toggleRemarketingConsentSource',
      data: {
        projectId: this.props.projectLayout.project.id,
        integrationId: integration.id,
        isRemarketingConsentSource: !integration[integration.kind + 'Settings'].isRemarketingConsentSource
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }

      let projects = _.filter(this.props.organizationLayout.projects, (p: any) => p.id !== response.data.project.id)
      projects.push(response.data.project)
      this.props.organizationLayout.setProjects(projects)

      this.props.app.addMessage('success', t('settings_updated', "Settings updated!"))

      this.setState({ loading: false })
    })
  }

  toggleGeocodeAddresses(integration: any) {
    if (this.state.loading) {
      return
    }

    this.setState({ loading: true })

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/integrations.toggleGeocodeAddresses',
      data: {
        projectId: this.props.projectLayout.project.id,
        integrationId: integration.id,
        geocodeAddresses: !integration[integration.kind + 'Settings'].geocodeAddresses
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }

      let projects = _.filter(this.props.organizationLayout.projects, (p: any) => p.id !== response.data.project.id)
      projects.push(response.data.project)
      this.props.organizationLayout.setProjects(projects)

      this.props.app.addMessage('success', t('settings_updated', "Settings updated!"))

      this.setState({ loading: false })
    })
  }


  toggleGeocodeTimezones(integration: any) {
    if (this.state.loading) {
      return
    }

    this.setState({ loading: true })

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/integrations.toggleGeocodeTimezones',
      data: {
        projectId: this.props.projectLayout.project.id,
        integrationId: integration.id,
        geocodeTimezones: !integration[integration.kind + 'Settings'].geocodeTimezones
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }

      let projects = _.filter(this.props.organizationLayout.projects, (p: any) => p.id !== response.data.project.id)
      projects.push(response.data.project)
      this.props.organizationLayout.setProjects(projects)

      this.props.app.addMessage('success', t('settings_updated', "Settings updated!"))

      this.setState({ loading: false })
    })
  }

  updateDefaultTimezone(integration: any, timezone: string) {
    if (this.state.loading) {
      return
    }

    this.setState({ loading: true })

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/integrations.updateDefaultTimezone',
      data: {
        projectId: this.props.projectLayout.project.id,
        integrationId: integration.id,
        timezone: timezone,
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }

      let projects = _.filter(this.props.organizationLayout.projects, (p: any) => p.id !== response.data.project.id)
      projects.push(response.data.project)
      this.props.organizationLayout.setProjects(projects)

      this.props.app.addMessage('success', t('settings_updated', "Settings updated!"))

      this.setState({ loading: false })
    })
  }

  updateDefaultLanguage(integration: any, language: any) {
    if (this.state.loading) {
      return
    }

    this.setState({ loading: true })

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/integrations.updateDefaultLanguage',
      data: {
        projectId: this.props.projectLayout.project.id,
        integrationId: integration.id,
        language: language,
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }

      let projects = _.filter(this.props.organizationLayout.projects, (p: any) => p.id !== response.data.project.id)
      projects.push(response.data.project)
      this.props.organizationLayout.setProjects(projects)

      this.props.app.addMessage('success', t('settings_updated', "Settings updated!"))

      this.setState({ loading: false })
    })
  }

  updateConversionDomain(integration: any, domainId: any) {
    if (this.state.loading) {
      return
    }

    this.setState({ loading: true })

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/integrations.updateConversionDomain',
      data: {
        projectId: this.props.projectLayout.project.id,
        integrationId: integration.id,
        domainId: domainId,
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }

      let projects = _.filter(this.props.organizationLayout.projects, (p: any) => p.id !== response.data.project.id)
      projects.push(response.data.project)
      this.props.organizationLayout.setProjects(projects)

      this.props.app.addMessage('success', t('settings_updated', "Settings updated!"))

      this.setState({ loading: false })
    })
  }


  updateConversionRule(integration: any, conversionRuleId: any) {
    if (this.state.loading) {
      return
    }

    this.setState({ loading: true })

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/integrations.updateConversionRule',
      data: {
        projectId: this.props.projectLayout.project.id,
        integrationId: integration.id,
        conversionRuleId: conversionRuleId,
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }

      let projects = _.filter(this.props.organizationLayout.projects, (p: any) => p.id !== response.data.project.id)
      projects.push(response.data.project)
      this.props.organizationLayout.setProjects(projects)

      this.props.app.addMessage('success', t('settings_updated', "Settings updated!"))

      this.setState({ loading: false })
    })
  }

  updateSettings(integration: any, settings: any) {
    if (this.state.loading) {
      return
    }

    this.setState({ loading: true })

    const data: any = {
      projectId: this.props.projectLayout.project.id,
      integrationId: integration.id,
    }

    data[integration.kind] = settings

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/integrations.updateSettings',
      data: data
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }

      let projects = _.filter(this.props.organizationLayout.projects, (p: any) => p.id !== response.data.project.id)
      projects.push(response.data.project)
      this.props.organizationLayout.setProjects(projects)

      this.props.app.addMessage('success', t('settings_updated', "Settings updated!"))

      this.setState({ loading: false })
    })
  }

  switchTab(value: any) {
    let params = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })
    params.tab = value
    this.props.history.push('/organizations/' + this.props.match.params.organizationId + '/projects/' + this.props.match.params.projectId + '/integrations?' + QS.stringify(params))
  }

  render() {
    const params: any = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })

    const defaultTab = _.get(this.props, 'projectLayout.project.integrations[0].id', 'new')

    const integrationsProps = {
      connect: this.connect,
      disconnect: this.disconnect,
      toggleMainSource: this.toggleMainSource,
      toggleNewsletterSource: this.toggleNewsletterSource,
      toggleRemarketingSource: this.toggleRemarketingSource,
      toggleGeocodeAddresses: this.toggleGeocodeAddresses,
      toggleGeocodeTimezones: this.toggleGeocodeTimezones,
      updateDefaultTimezone: this.updateDefaultTimezone,
      updateDefaultLanguage: this.updateDefaultLanguage,
      updateConversionRule: this.updateConversionRule,
      updateConversionDomain: this.updateConversionDomain,
      updateSettings: this.updateSettings
    }

    return <AppLayout currentProject={this.props.projectLayout.project} currentOrganization={this.props.organizationLayout.organization} admin={this.props.app.state.admin} firebaseUser={this.props.app.state.firebaseUser} projects={this.props.organizationLayout.projects}>

        <h1>{t('integrations', "Integrations")}</h1>

        <Tabs activeKey={params.tab || defaultTab} onChange={this.switchTab}>

          {this.props.projectLayout.project.integrations.map((integration: any) =>
            <Tabs.TabPane tab={integration.name} key={integration.id}>
              <div className="block margin-t-l padding-b-m">
                {integration.kind === 'shopify' && <Shopify {...this.props} {...integrationsProps} integration={integration} />}
                {integration.kind === 'wooCommerce' && <WooCommerce {...this.props} {...integrationsProps} integration={integration} />}
                {integration.kind === 'googleAds' && <GoogleAds {...this.props} {...integrationsProps} integration={integration} />}
                {/* {integration.kind === 'monday' && <Monday {...this.props} {...integrationsProps} integration={integration} />} */}
                {integration.kind === 'facebook' && <Facebook {...this.props} {...integrationsProps} integration={integration} />}
                {integration.kind === 'sparkPost' && <SparkPost {...this.props} {...integrationsProps} integration={integration} />}
                {integration.kind === 'postmark' && <Postmark {...this.props} {...integrationsProps} integration={integration} />}
                {integration.kind === 'twilio' && <Twilio {...this.props} {...integrationsProps} integration={integration} />}
                {integration.kind === 'mailchimp' && <Mailchimp {...this.props} {...integrationsProps} integration={integration} />}
                {/* {integration.kind === 'lazada' && <Lazada {...this.props} {...integrationsProps} integration={integration} />} */}
                {integration.kind === 'webhook' && <Webhook {...this.props} {...integrationsProps} integration={integration} />}
                {integration.kind === 'admo' && <Admo {...this.props} {...integrationsProps} integration={integration} />}
                {integration.kind === 'amazon' && <Amazon {...this.props} {...integrationsProps} integration={integration} />}
                {integration.kind === 'gamned' && <Gamned {...this.props} {...integrationsProps} integration={integration} />}
                {integration.kind === 'klaviyo' && <Klaviyo {...this.props} {...integrationsProps} integration={integration} />}
                {/* {integration.kind === 'sylius' && <Sylius {...this.props} {...integrationsProps} integration={integration} />} */}
              </div>
            </Tabs.TabPane>)}

          <Tabs.TabPane tab={<span>{t('new_integration', "New integration")}</span>} key="new">
            <Table
              showHeader={false}
              dataSource={integrations}
              rowKey="kind"
              pagination={false}
              className="edge-table block margin-t-l"
              columns={[
                {
                  key: 'logo',
                  title: '',
                  width: 80,
                  render: (record: any) => <img src={record.logo} alt={record.name} className="integration-logo" />
                },
                {
                  key: 'name',
                  title: '',
                  render: (record: any) => <div>
                    <div><span className="integration-name">{record.name}</span>
                      {record.tags.map((tag: any, i: number) => <span key={i}>{tag}</span>)}
                    </div>
                    {t(record.description, record.description)}
                  </div>
                },
                {
                  key: 'actions',
                  title: '',
                  className: 'vertical-center',
                  align: 'right',
                  render: (record: any) => <Button.Group>
                    <Popconfirm placement="topRight" title={t('add_integration_confirm', "Do you really want to add this integration to your project?")} onConfirm={this.addIntegration.bind(null, record.kind)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                      <Button type="primary" loading={this.state.loading} disabled={record.soon}>{record.soon ? t('coming_soon', "coming soon...") : <span><PlusCircleOutlined /> {t('add', "Add")}</span>}</Button>
                    </Popconfirm>
                  </Button.Group>
                },
              ]}
            />
          </Tabs.TabPane>
        </Tabs>
      </AppLayout>
  }
}

export default IntegrationsList