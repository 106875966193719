import React, { Component } from 'react'

import t from '../../utils/translate'
import _ from 'lodash'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import UpdateChannelGroupButton from './_groupFormButton'

class ChannelGroupButtons extends Component<any, any> {

  constructor(props: any) {
    super(props)

    this.state = {
      ajaxLoading: false
    }

    this.showDeleteConfirm = this.showDeleteConfirm.bind(this)
    this.deleteChannelGroup = this.deleteChannelGroup.bind(this)
  }

  showDeleteConfirm() {

    // // check if user is owner of this organization
    // if (!_.find(this.props.organizationLayout.organization.users, (u) => (u.id === this.props.app.state.user.id && u.isOwner === true) )) {
    //   message.error(t('only_the_owner_can_delete_channel', 'Only the owner of "{name}" can delete a channel!', {name: this.props.organizationLayout.organization.name}), 5)
    //   return
    // }

    Modal.confirm({
      title: t('delete_a_channel_group', 'Delete a channel group'),
      content: t('confirm_delete_channel_group', "Do you really want to delete this channel group?"),
      onOk: this.deleteChannelGroup,
      okText: t('confirm', "Confirm"),
      cancelText: t('cancel', "Cancel"),
      onCancel() {},
    })
  }

  deleteChannelGroup() {

    if (this.state.ajaxLoading) {
      return
    }
      
    this.setState({ajaxLoading: true})

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/channelGroups.delete',
      data: {
        projectId: this.props.projectLayout.project.id,
        channelGroupId: this.props.channelGroup.id,
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addNotification('error', 'Update status error', errorMessage)
        this.setState({ajaxLoading: false})
        return
      }

      let updatedProjects = _.filter(this.props.organizationLayout.projects, (p: any) => p.id !== this.props.projectLayout.project.id)
      updatedProjects.push(response.data.project)
      this.props.organizationLayout.setProjects(updatedProjects)
      
      this.setState({ajaxLoading: false})
      
      this.props.app.addMessage('success', t('channel_group_deleted', 'Your channel group has been deleted.'))

      if (window.cmAgent) {
        window.cmAgent.event({
          label: 'deleteChannelGroup',
          props: {
            orgId: this.props.projectLayout.project.organizationId,
            projectId: this.props.projectLayout.project.id
          }
        })
        window.cmAgent.dispatch()
      }
    })
  }

  render() {
    return (
      <Button.Group>
        {!this.props.channelGroup.isDefault && <Button loading={this.state.ajaxLoading} size="small" type="ghost" onClick={this.showDeleteConfirm}><DeleteOutlined /></Button>}

        <UpdateChannelGroupButton
          organizationLayout={this.props.organizationLayout}
          project={this.props.projectLayout.project}
          app={this.props.app}
          channelGroup={this.props.channelGroup}
          btnSize="small"
          btnType="ghost"
          btnContent={<EditOutlined />}
          onComplete={this.props.toggleReattribute}
        />
      </Button.Group>
    );
  }
}

export default ChannelGroupButtons