import React, { useState } from 'react'
import { 
  Modal,
  Form,
  Input,
  Button,
} from 'antd';
import t from '../../utils/translate'

const TheButton = ({btnType, btnSize, btnContent, project, app, organizationLayout, history, onComplete}: any) => {
  const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const onClicked = () => {
    setModalVisible(true)
  }

  const onFinish = (values: any) => {
    // console.log('values', values);
    
    if (loading) return

    setLoading(true)

    values.projectId = project.id

    app.ajaxRequest({
      method: 'post',
      url: '/partnerships.create',
      data: values
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        app.addMessage('error', errorMessage)
        setLoading(false)
        return
      }

      app.addMessage('success', t('media_partner_created', 'Your new Media Partner has been created.'))

      setLoading(false)

      if (window.cmAgent) {
        // window.cmAgent.event({label: commissionGroup ? 'update_domain' : 'add_domain'})
        // window.cmAgent.dispatch();
      }

      if (onComplete) {
        onComplete()
      }

      form.resetFields()
      setModalVisible(false)
    })
  }

  return <React.Fragment>
    <Button type={btnType || 'primary'} size={btnSize || 'small'} onClick={onClicked}>{btnContent}</Button>

    <Modal
      visible={modalVisible}
      title={t('create_media_partner', "Create media partner")}
      okText={t('create', "Create")}
      okButtonProps={{loading: loading}}
      cancelButtonProps={{loading: loading}}
      cancelText={t('cancel', "Cancel")}
      onCancel={() => { setModalVisible(false) }}
      onOk={() => {
        form.validateFields().then((values: any) => {
            form.resetFields();
            onFinish(values);
          })
          .catch(info => { console.log('Validate Failed:', info); });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{language: app.state.admin.language}}
      >
        <Form.Item
          name="partnershipId"
          label="Media Partner ID"
          rules={[
            { required: true, type: 'string', pattern: /^[a-z0-9]+$/, message: t('only_lower_case_alphanumeric_characters', "Only lower case alphanumeric characters allowed (a-z0-9)") }
          ]}
          help={t('the_id_cant_be_changed_later', "The ID can't be changed in the future.")}
        >
          <Input placeholder="i.e: partner123" />
        </Form.Item>
      </Form>
    </Modal>
  </React.Fragment>
}

export default TheButton