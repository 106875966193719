import React, { Component } from 'react'

import { CloseOutlined, DeleteOutlined, PlusOutlined, SyncOutlined } from '@ant-design/icons';

import {
  Button, // Form,
  Badge, Popconfirm, Switch, Select, Tag, Table, Row, Col
} from 'antd';
import t from '../../utils/translate'
// import integrations from '../../utils/integrations'
import Cookies from '../../utils/cookies'
import Config from '../../config'
import _ from 'lodash'
// import Moment from 'moment-timezone'
import logoMailchimp from '../../images/integrations/mailchimp.png'

class MailchimpAudience extends Component<any, any> {
  _isMounted = false;

  constructor(props: any) {
    super(props)

    this.state = {
      mergeTagId: undefined,
      subKey: undefined,
      userProperty: undefined,
      addMergeTagVisible: false,
      mergeTags: []
    }

    this.importContacts = this.importContacts.bind(this)
    this.disconnectAudience = this.disconnectAudience.bind(this)
    this.addMergeTag = this.addMergeTag.bind(this)
    this.removeMergeTag = this.removeMergeTag.bind(this)
    this.fetchMergeTags = this.fetchMergeTags.bind(this)
    this.toggleNewsletterSource = this.toggleNewsletterSource.bind(this)
    this.toggleRemarketingSource = this.toggleRemarketingSource.bind(this)
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.integration.status === 'connected') {
      this.fetchMergeTags()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  fetchMergeTags() {
    this.setState({ loading: true })

    this.props.app.ajaxRequest({
      method: 'get',
      url: '/integrations.listMailchimpAudienceMergeTags',
      params: {
        projectId: this.props.projectLayout.project.id,
        integrationId: this.props.integration.id,
        audienceId: this.props.audience.id
      }
    }, (errorMessage: any, response: any) => {

      if (!this._isMounted) return

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }
      // console.log('accounts', response.data);

      this.setState({
        loading: false,
        mergeTags: response.data.mergeTags
      })
    })
  }

  importContacts(audienceId: string) {
    if (this.state.loading) {
      return
    }

    this.setState({ loading: true })

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/integrations.importMailchimpContacts',
      data: {
        projectId: this.props.projectLayout.project.id,
        integrationId: this.props.integration.id,
        connectedAudienceId: audienceId
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }
      this.props.app.addMessage('success', t('task_launched', "Task launched!"))

      this.setState({ loading: false })

      this.props.history.push('/organizations/' + this.props.organizationLayout.organization.id + '/projects/' + this.props.projectLayout.project.id + '/tasks')
    })
  }

  addMergeTag() {

    const audience = Object.assign({}, this.props.audience)

    // init map if empty
    if (!audience.mergeTags) {
      audience.mergeTags = []
    }

    const selectedMergeTag = _.find(this.state.mergeTags, t => t.merge_id === this.state.mergeTagId)

    if (!selectedMergeTag) return

    // add tag to user propert
    audience.mergeTags.push({
      mergeId: this.state.mergeTagId,
      subKey: this.state.subKey,
      name: selectedMergeTag.name,
      tag: selectedMergeTag.tag,
      userProperty: this.state.userProperty,
      kind: selectedMergeTag.type,
      defaultValue: selectedMergeTag.default_value,
      phoneFormat: _.get(selectedMergeTag, 'options.phone_format'),
      dateFormat: _.get(selectedMergeTag, 'options.date_format')
    })


    this.setState({
      mergeTagId: undefined,
      subKey: undefined,
      userProperty: undefined,
      addMergeTagVisible: false,
    })

    this.props.audienceUpdated(audience)
  }

  removeMergeTag(record: any) {

    const audience = Object.assign({}, this.props.audience)

    audience.mergeTags = audience.mergeTags.filter((x: any) => x.mergeId !== record.mergeId)

    this.props.audienceUpdated(audience)
  }

  toggleNewsletterSource() {

    const audience = Object.assign({}, this.props.audience)

    audience.isNewsletterConsentSource = !audience.isNewsletterConsentSource

    this.props.audienceUpdated(audience)
  }

  toggleRemarketingSource() {

    const audience = Object.assign({}, this.props.audience)

    audience.isRemarketingConsentSource = !audience.isRemarketingConsentSource

    this.props.audienceUpdated(audience)
  }

  disconnectAudience() {
    if (this.state.loading) {
      return
    }

    this.setState({ loading: true })

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/integrations.disconnectMailchimpAudience',
      data: {
        projectId: this.props.projectLayout.project.id,
        integrationId: this.props.integration.id,
        audienceId: this.props.audience.id,
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }

      let projects = _.filter(this.props.organizationLayout.projects, (p: any) => p.id !== response.data.project.id)
      projects.push(response.data.project)
      this.props.organizationLayout.setProjects(projects)

      this.props.app.addMessage('success', t('settings_updated', "Settings updated!"))

      this.setState({ loading: false })
    })
  }

  render() {

    const userCustomProperties = [
      // {key: "signedUpAt", label: "Signed up at", type: "datetime"},
      // {key: "createdAt", label: "Created at", type: "datetime"},
      { key: "firstName", label: "First name", type: "string" },
      { key: "lastName", label: "Last name", type: "string" },
      { key: "gender", label: "Gender", type: "string" },
      { key: "birthday", label: "Birthday", type: "date" },
      { key: "photoURL", label: "Photo URL", type: "string" },
      { key: "email", label: "Email", type: "string" },
      { key: "telephone", label: "Telephone", type: "string" },
      { key: "timezone", label: "Time zone", type: "string" },
      { key: "language", label: "Language", type: "string" },
      { key: "addressLine1", label: "Address line 1", type: "string" },
      { key: "addressLine2", label: "Address line 2", type: "string" },
      { key: "city", label: "City", type: "string" },
      { key: "region", label: "Region", type: "string" },
      { key: "postalCode", label: "Postal code", type: "string" },
      { key: "state", label: "State", type: "string" },
      { key: "country", label: "Country", type: "string" },
      { key: "websiteURL", label: "Website URL", type: "string" },
      { key: "linkedInURL", label: "LinkedIn URL", type: "string" },
      { key: "twitterUsername", label: "Twitter username", type: "string" },
      { key: "facebookUsername", label: "Facebook username", type: "string" },
      { key: "facebookId", label: "Facebook Id", type: "string" },
    ]

    // add custom attributes
    _.forEach(this.props.customProperties, (type, key) => {
      userCustomProperties.push({ key: 'properties.' + key, label: 'Custom: ' + key, type: type })
    })

    let selectedMergeTag: any

    if (this.state.mergeTagId) {
      // console.log(this.state);
      selectedMergeTag = _.find(this.state.mergeTags, t => t.merge_id === this.state.mergeTagId)
    }


    return (
      <div>
        <h2 className="margin-t-m">Audience: {this.props.audience.name}</h2>
        <table className="key-value-table">
          <tbody>
            <tr>
              <th style={{ width: '400px' }}>Audience</th>
              <td>{this.props.audience.name} - {this.props.audience.webId}
                <Popconfirm placement="top" title={t('disconnect_audience_confirm', "Do you really want to disconnect this audience?")} onConfirm={this.disconnectAudience.bind(null, this.props.audience.id)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                  <Button className="margin-l-m" ghost danger={true} size="small" loading={this.state.loading}>{t('disconnect', "Disconnect")}</Button>
                </Popconfirm>
              </td>
            </tr>
            {/*<tr>
              <th>{t('is_audience_users_source', "Is this audience your main source of users?")}</th>
              <td>
                <Popconfirm placement="top" title={t('toggle_main_source', "Do you really want to change the status of this source of users?")} onConfirm={this.props.toggleMainSource.bind(null, this.props.integration)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                  <Switch checked={this.props.integration.mailchimpSettings.isMainSource} loading={this.state.loading} />
                </Popconfirm>
              </td>
            </tr>*/}
            <tr>
              <th>{t('is_source_of_newsletter_consent', "Is it the source of consent to newsletter?")}</th>
              <td>
                <Popconfirm placement="top" title={t('toggle_consent_source', "Do you really want to change the status of this source of consent?")} onConfirm={this.toggleNewsletterSource} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                  <Switch checked={this.props.audience.isNewsletterConsentSource} loading={this.state.loading} />
                </Popconfirm>
              </td>
            </tr>
            <tr>
              <th>{t('is_source_of_remarketing_consent', "Is it the source of consent to remarketing?")}</th>
              <td>
                <Popconfirm placement="top" title={t('toggle_consent_source', "Do you really want to change the status of this source of consent?")} onConfirm={this.toggleRemarketingSource} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                  <Switch checked={this.props.audience.isRemarketingConsentSource} loading={this.state.loading} />
                </Popconfirm>
              </td>
            </tr>
            <tr>
              <th>Webhooks</th>
              <td>
                {_.map(this.props.audience.webhooks, (x, hook) => <Tag className="margin-r-s" color="green" key={hook}>{hook}</Tag>)}
              </td>
            </tr>
            {/*<tr>
              <th>{t('default_timezone', "Default users time zone")}</th>
              <td>
                <Select 
                  style={{width: '200px'}}
                  placeholder={t('select_a_value', "Select a value")}
                  allowClear={false}
                  showSearch={true}
                  value={this.props.integration.mailchimpSettings.defaultTimezone}
                  loading={this.state.loading}
                  onChange={this.props.updateDefaultTimezone.bind(null, this.props.integration)}
                  filterOption={(searchText: any, option: any) => {
                    return searchText !== '' && option.key.toLowerCase().includes(searchText.toLowerCase())
                  }}>
                    {timezones.map((timezone) => {
                      return <Select.Option key={timezone} value={timezone}>{timezone}</Select.Option>
                    })}
                </Select>
              </td>
            </tr>
            <tr>
              <th>{t('default_language', "Default users language")}</th>
              <td>
                <Select 
                  style={{width: '200px'}}
                  placeholder={t('select_a_value', "Select a value")}
                  allowClear={false}
                  showSearch={true}
                  value={this.props.integration.mailchimpSettings.defaultLanguage}
                  loading={this.state.loading}
                  onChange={this.props.updateDefaultLanguage.bind(null, this.props.integration)}
                  filterOption={(searchText: any, option: any) => {
                    return searchText !== '' && option.key.toLowerCase().includes(searchText.toLowerCase())
                  }}>
                    {Languages.map((language) => {
                      return <Select.Option key={language.alpha2} value={language.alpha2}>{language.alpha2+' - '+language.name}</Select.Option>
                    })}
                </Select>
              </td>
            </tr>*/}
            <tr>
              <th>{t('import_merge_tags', "Import merge tags")}</th>
              <td>
                {this.props.audience.mergeTags && this.props.audience.mergeTags.length > 0 && <Table
                  size="middle"
                  bordered={false}
                  pagination={false}
                  dataSource={this.props.audience.mergeTags}
                  rowKey={record => '' + record.mergeId + record.subKey}
                  className="margin-b-m"
                  style={{ width: '300px' }}
                  columns={[
                    {
                      title: 'Merge Tag',
                      key: 'mergeId',
                      render: (record: any) => record.name + (record.subKey ? ' (' + record.subKey + ')' : '')
                    },
                    {
                      title: '',
                      key: 'to',
                      render: (record: any) => '→'
                    },
                    {
                      title: t('user_property', "User property"),
                      key: 'cm',
                      render: (record: any) => record.userProperty
                    },
                    {
                      title: '',
                      key: 'delete',
                      render: (record: any) => <Popconfirm placement="top" title={t('remove_entry_confirm', "Do you really want to remove this entry?")} onConfirm={this.removeMergeTag.bind(null, this.props.audience.id, record)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                        <Button size="small" type="dashed" icon={<DeleteOutlined />} />
                      </Popconfirm>
                    },
                  ]}
                />}
                {!this.state.addMergeTagVisible && <Button type="primary" ghost onClick={() => this.setState({ addMergeTagVisible: true })}><PlusOutlined /> {t('add', "Add")}</Button>}
                {this.state.addMergeTagVisible && <Row className="margin-b-m" style={{ width: '750px' }}>
                  <Col span={6}>
                    <Select placeholder={t('select_a_value', "Select a value")} dropdownMatchSelectWidth={false} style={{ width: '100%' }} value={this.state.mergeTagId} onChange={(value: any) => this.setState({ mergeTagId: value })}>
                      {this.state.mergeTags.map((tag: any) => <Select.Option value={tag.merge_id} key={tag.merge_id}>{tag.name} - {tag.tag}</Select.Option>)}
                    </Select>
                  </Col>
                  {selectedMergeTag && selectedMergeTag.type === 'address' && <Col span={6} className="padding-l-m">
                    <Select placeholder={t('select_a_value', "Select a value")} dropdownMatchSelectWidth={false} style={{ width: '100%' }} value={this.state.subKey} onChange={(value: any) => this.setState({ subKey: value })}>
                      <Select.Option value="addr1" key="addr1">{t('address', "Address")}</Select.Option>
                      <Select.Option value="addr2" key="addr2">{t('address_line_2', "Address line 2")}</Select.Option>
                      <Select.Option value="city" key="city">{t('city', "City")}</Select.Option>
                      <Select.Option value="state" key="state">{t('state', "State")}</Select.Option>
                      <Select.Option value="zip" key="zip">{t('postal_code', "Postal code")}</Select.Option>
                      <Select.Option value="country" key="country">{t('country', "Country")}</Select.Option>
                    </Select>
                  </Col>}
                  {selectedMergeTag && (selectedMergeTag.type === 'address' ? this.state.subKey : true) && <React.Fragment>
                    <Col span={1}><div className="text-center padding-t-xs">→</div></Col>
                    <Col span={6}>
                      <Select placeholder={t('select_a_value', "Select a value")} dropdownMatchSelectWidth={false} style={{ width: '100%' }} value={this.state.userProperty} onChange={(value: any) => this.setState({ userProperty: value })}>
                        {userCustomProperties.filter((userProp: any) => {
                          // only keep user properties for this merge type
                          if (selectedMergeTag.type === 'date') {
                            return userProp.type === 'date'
                          }
                          else if (selectedMergeTag.type === 'birthday') {
                            return userProp.type === 'date'
                          }
                          else if (selectedMergeTag.type === 'number') {
                            return userProp.type === 'number'
                          } else {
                            return userProp.type === 'string'
                          }
                        }).map(attr => <Select.Option value={attr.key} key={attr.key}>{attr.label}</Select.Option>)}
                      </Select>
                    </Col>
                  </React.Fragment>}
                  <Col span={5} className="padding-l-m">
                    <Button type="primary" onClick={this.addMergeTag} disabled={!this.state.userProperty}>{t('add', "Add")}</Button>
                    <Button className="margin-l-s" type="dashed" onClick={() => this.setState({ addMergeTagVisible: false })} icon={<CloseOutlined />} />
                  </Col>
                </Row>}
              </td>
            </tr>
            <tr>
              <th>{t('actions', "Actions")}</th>
              <td>
                <Popconfirm placement="top" title={t('sync_contacts_from_mailchimp_confirm', "Do you really want to import your contacts from Mailchimp?")} onConfirm={this.importContacts.bind(null, this.props.audience.id)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                  <Button loading={this.state.loading} type="primary"><SyncOutlined /> {t('import_contacts', "Import contacts")}</Button>
                </Popconfirm>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
class IntegrationsMailchimp extends Component<any, any> {
  _isMounted = false;

  constructor(props: any) {
    super(props)

    this.state = {
      // webhooks: {},
      loading: false,
      selectedAudienceId: undefined,
      audiences: [],
      customProperties: {},
    }

    this.goToOauth = this.goToOauth.bind(this)
    this.listAudiences = this.listAudiences.bind(this)
    this.listCustomProperties = this.listCustomProperties.bind(this)
    this.connectAudience = this.connectAudience.bind(this)
    this.updateAudiencesSettings = this.updateAudiencesSettings.bind(this)
    this.audienceUpdated = this.audienceUpdated.bind(this)
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.integration.status === 'connected') {
      this.listAudiences()
      this.listCustomProperties()
    }
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.integration.status !== this.props.integration.status && this.props.integration.status !== 'disconnected') {
      this.listAudiences()
      this.listCustomProperties()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  goToOauth() {

    const state = {
      ts: new Date().getTime(),
      oid: this.props.projectLayout.project.organizationId,
      pid: this.props.projectLayout.project.id,
      iid: this.props.integration.id
    }

    // mailchimp code is valid 30 secs only
    Cookies.setItem('mailchimp_oauth_state', JSON.stringify(state), 60, '/', null, true)

    let redirect_uri = 'https://console.captainmetrics.com/mailchimp_redirect_uri'

    if (Config().env === 'test') {
      redirect_uri = 'https://console.test.captainmetrics.com/mailchimp_redirect_uri'
    }

    if (Config().env === 'development') {
      Cookies.setItem('mailchimp_oauth_state_dev', JSON.stringify(state), 60, '/', null, false)
    }

    window.setTimeout(() => {
      window.location.href = 'https://login.mailchimp.com/oauth2/authorize?response_type=code&client_id=' + Config().mailchimp_client_id + '&redirect_uri=' + redirect_uri
    }, 50)
  }

  listAudiences() {
    this.setState({ loading: true })

    this.props.app.ajaxRequest({
      method: 'get',
      url: '/integrations.listMailchimpAudiences',
      params: {
        projectId: this.props.projectLayout.project.id,
        integrationId: this.props.integration.id
      }
    }, (errorMessage: any, response: any) => {

      if (!this._isMounted) return

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }
      // console.log('accounts', response.data);

      this.setState({
        loading: false,
        audiences: response.data.audiences
      })
    })
  }

  listCustomProperties() {
    this.setState({ loading: true })

    this.props.app.ajaxRequest({
      method: 'get',
      url: '/users.listCustomProperties',
      params: {
        projectId: this.props.projectLayout.project.id
      }
    }, (errorMessage: any, response: any) => {

      if (!this._isMounted) return

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }
      // console.log('accounts', response.data);

      this.setState({
        loading: false,
        customProperties: response.data.customProperties
      })
    })
  }

  connectAudience() {
    if (this.state.loading || !this.state.selectedAudienceId) {
      return
    }

    this.setState({ loading: true })

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/integrations.connectMailchimpAudience',
      data: {
        projectId: this.props.projectLayout.project.id,
        integrationId: this.props.integration.id,
        audienceId: this.state.selectedAudienceId,
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }

      let projects = _.filter(this.props.organizationLayout.projects, (p: any) => p.id !== response.data.project.id)
      projects.push(response.data.project)
      this.props.organizationLayout.setProjects(projects)

      this.props.app.addMessage('success', t('settings_updated', "Settings updated!"))

      this.setState({ loading: false })
    })
  }

  updateAudiencesSettings(connectedAudiences: any) {
    if (this.state.loading) {
      return
    }

    this.setState({ loading: true })

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/integrations.updateMailchimpConnectedAudiences',
      data: {
        projectId: this.props.projectLayout.project.id,
        integrationId: this.props.integration.id,
        connectedAudiences: connectedAudiences,
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({ loading: false })
        return
      }

      let projects = _.filter(this.props.organizationLayout.projects, (p: any) => p.id !== response.data.project.id)
      projects.push(response.data.project)
      this.props.organizationLayout.setProjects(projects)


      this.props.app.addMessage('success', t('settings_updated', "Settings updated!"))

      this.setState({ loading: false })
    })
  }

  audienceUpdated(audience: any) {

    const connectedAudiences = _.get(this.props.integration, 'mailchimpSettings.connectedAudiences', []).slice(0)

    connectedAudiences.forEach((aud: any, index: number) => {
      if (aud.id === audience.id) {
        connectedAudiences[index] = audience
      }
    })

    this.updateAudiencesSettings(connectedAudiences)
  }

  render() {
    if (this.props.integration.status === 'setup' || this.props.integration.status === 'disconnected') {
      return <div style={{ width: '500px', margin: '32px auto' }}>
        <div className="paper padding-a-xl text-center">
          <img src={logoMailchimp} alt="" />
          <Button type="primary" size="large" loading={this.state.loadingConnect} onClick={this.goToOauth}>
            {t('connect_mailchimp', "Connect Mailchimp")}
          </Button>
        </div>
      </div>
    }

    // const timezones = Moment.tz.names()
    let nonConnectedAudiences = []

    if (this.props.integration.status === 'connected') {
      nonConnectedAudiences = this.state.audiences.filter((audience: any) => {
        return !_.get(this.props.integration, 'mailchimpSettings.connectedAudiences', []).find((aud: any) => aud.id === audience.id)
      })
    }

    // console.log('connectedAudiences', connectedAudiences);
    // console.log('nonConnectedAudiences', nonConnectedAudiences);

    // console.log('props', this.props);

    return <div>
      <h2 className="margin-t-m">{t('settings', "Settings")}</h2>
      <table className="key-value-table">
        <tbody>
          <tr>
            <th style={{ width: '400px' }}>{t('integration_id', "Integration ID")}</th>
            <td>{this.props.integration.id}</td>
          </tr>
          <tr>
            <th>{t('status', "Status")}</th>
            <td>
              {this.props.integration.status === 'connected' && <span>
                <Badge status="success" /> {t('connected', "Connected")}

                <Popconfirm placement="top" title={t('disconnect_mailchimp_confirm', "Do you really want to disconnect Mailchimp? your data won't be synced anymore!")} onConfirm={this.props.disconnect.bind(null, this.props.integration)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                  <Button className="margin-l-m" loading={this.state.loading} ghost size="small" danger={true}>{t('disconnect', "Disconnect")}</Button>
                </Popconfirm>
              </span>}
            </td>
          </tr>
          <tr>
            <th>{t('account', "Account")}</th>
            <td>{this.props.integration.mailchimpSettings.accountName} - {this.props.integration.mailchimpSettings.accountRole}</td>
          </tr>

          {nonConnectedAudiences.length > 0 && <tr>
            <th>{t('connect_an_audience', "Connect an audience")}</th>
            <td>
              <Select
                style={{ width: '200px' }}
                placeholder={t('select_an_audience', "Select an audience")}
                allowClear={false}
                showSearch={true}
                dropdownMatchSelectWidth={false}
                value={this.state.selectedAudienceId}
                loading={this.state.loading}
                onChange={audienceId => this.setState({ selectedAudienceId: audienceId })}
                filterOption={(searchText: any, option: any) => {
                  return searchText !== '' && option.key.toLowerCase().includes(searchText.toLowerCase())
                }}>
                {nonConnectedAudiences.map((audience: any) => {
                  return <Select.Option key={audience.id} value={audience.id}>{audience.name}</Select.Option>
                })}
              </Select>
              {this.state.selectedAudienceId && <Button type="primary" className="margin-l-s" onClick={this.connectAudience}>{t('connect_audience', "Connect audience")}</Button>}
            </td>
          </tr>}
        </tbody>
      </table>

      {_.get(this.props.integration, 'mailchimpSettings.connectedAudiences', []).map((aud: any) => <MailchimpAudience key={aud.id} audienceUpdated={this.audienceUpdated} customProperties={this.state.customProperties} audience={aud} {...this.props} />)}

    </div>
  }
}

export default IntegrationsMailchimp