import { Component } from 'react'

// import t from '../../utils/translate'
import { Table, message, Tag } from 'antd'
// import Formatters from '../../utils/formatters'
import N from 'numeral'
import removeLeadingDates from 'utils/removeLeadingDates'

class LeadsStages extends Component<any, any> {
  _isMounted = false;

  constructor(props: any) {
    super(props)
    
    this.state = {
      loading: true,
      current: [],
      previous: [],
    }

    this.fetch = this.fetch.bind(this)
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetch()
  }

  componentDidUpdate(prevProps: any) {
    // check url params have changed
    if (prevProps.refreshAt !== this.props.refreshAt) {
      window.setTimeout(() => this.fetch())
    } else if (prevProps.project.id !== this.props.project.id) {
      window.setTimeout(() => this.fetch())
    }
  }

  fetch() {

    if (!this.state.loading) {
      this.setState({loading: true})
    }

    const opts: any = {
      measures: ['TimelineLeads.count'],
      dimensions: ['TimelineLeads.leadStageId'],
      timeDimensions: [
        {
          "dimension": 'TimelineLeads.truncCreatedAt',
          "granularity": null,
          "compareDateRange": [
            [this.props.dateFrom,this.props.dateTo],
            [this.props.dateFromPrevious,this.props.dateToPrevious]
          ]
        }
      ],
      timezone: this.props.timezone,
      filters: [{
          member: 'TimelineLeads.conversionRuleId',
          operator: 'equals',
          values: [this.props.conversionRule.id],
      }]
    }


    this.props.cubejsApi.load(opts).then((result: any) => {
      const [current, previous] = result.decompose()

      const currentLines = current.tablePivot()
      const previousLines = previous.tablePivot()

      removeLeadingDates(currentLines)
      removeLeadingDates(previousLines)

      this.setState({
        loading: false,
        current: currentLines,
        previous: previousLines,
      })
    })
    .catch((error: any) => {

      console.log(error)

      let msg = error.message

      if (error.response && error.response.status === 400) {
        switch(error.response.data.message) {
          default: 
          msg = error.response.data.message
        }
      }

      message.error(msg)
      this.setState({loading: false})
    })
  }

  render() {

    // console.log('state kpi', this.state);

    return <div className="padding-a-m dash-table-stats">
      <Table 
        dataSource={this.state.current}
        loading={this.state.loading}
        rowKey="TimelineLeads.leadStageId"
        columns={[
          {
            title: 'Stage',
            key: 'stage',
            render: (record: any) => {
              const stage = this.props.conversionRule.leadStages.find((x: any) => x.id === record['TimelineLeads.leadStageId'])
              if (stage) return <Tag color={stage.color}>{stage.label}</Tag>
              return <Tag color="default">{record['TimelineLeads.leadStageId']}</Tag>
            }
          },
          {
            title: 'Total',
            key: 'count',
            sorter: (a: any, b: any) => {
              if (a['TimelineLeads.count'] < b['TimelineLeads.count']) { return -1 }
              if (a['TimelineLeads.count'] > b['TimelineLeads.count']) { return 1 }
              return 0
            },
            sortOrder: 'descend',
            showSorterTooltip: false,
            render: (record: any) => <span className="value">{N(record['TimelineLeads.count']).format('0,0')}</span>
          },
        ]}
        pagination={false}
        size="middle"
      />
    </div>
  }
}

export default LeadsStages