import { useRef, useState, useEffect, useCallback } from 'react'

import t from 'utils/translate'
// import { ReloadOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Tooltip, message, Table, Tag } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import DateRange from 'components/projects/_dateRange'
import Moment from 'moment-timezone'
import AppLayout from 'components/app/layout'
import { useProjectContext, ProjectContextValue } from 'components/projects'
import { useHistory, useLocation } from 'react-router'
import { TimelineLead } from 'components/app/interfaces';
import Formatters from 'utils/formatters'
import QS from 'qs'
import { get, truncate, keyBy } from 'lodash'

const defaultParams = {
  limit: 30,
  skip: 0,
  sortKey: 'createdAt',
  sortOrder: 'descend',
}

const LeadsList = () => {

  const [loading, setLoading] = useState(false)
  const [leads, setLeads] = useState<TimelineLead[]>([])
  const [leadsCount, setLeadsCount] = useState(0)
  const projectCtx: ProjectContextValue = useProjectContext()
  const history = useHistory() as any
  const location = useLocation() as any
  const prevProjectId = useRef(undefined)
  const prevLocationSearch = useRef(location.search)

  const fetchLeads = useCallback((): Promise<void> => {
    return new Promise((resolve, reject) => {
      const params: any = QS.parse(location.search, { ignoreQueryPrefix: true })

      let query = {
        projectId: projectCtx.currentProject.id,
        stageId: params.stageId || undefined,
        limit: params.limit || defaultParams.limit,
        skip: params.skip || defaultParams.skip,
        sortKey: params.sortKey || defaultParams.sortKey,
        sortOrder: params.sortOrder || defaultParams.sortOrder,
      }

      projectCtx.ajaxRequest({
        method: 'get',
        url: '/leads.list',
        params: query
      }, (errorMessage: any, response: any) => {

        if (errorMessage) {
          return reject(errorMessage)
        }

        // console.log('res', response.data);

        setLeads(response.data.leads)
        setLeadsCount(response.data.leadsCount)
        return resolve(undefined)
      })
    })
  }, [location.search, projectCtx])


  // on mount
  useEffect(() => {

    if (prevProjectId.current === projectCtx.currentProject.id) return
    prevProjectId.current = projectCtx.currentProject.id

    setLoading(true)
    fetchLeads().catch(e => message.error(e)).finally(() => setLoading(false))

    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Tasks (project: ' + projectCtx.currentProject.id + ')',
        page: location.pathname,
        props: {
          orgId: projectCtx.currentOrganization.id,
          projectId: projectCtx.currentProject.id,
        }
      })
      window.cmAgent.dispatch()
    }
  }, [history, location.pathname, projectCtx.currentProject.id, projectCtx.currentOrganization, projectCtx.projects, fetchLeads])

  // on update
  useEffect(() => {

    if (prevLocationSearch.current === location.search) return
    prevLocationSearch.current = location.search

    setLoading(true)
    fetchLeads().catch(e => message.error(e)).finally(() => setLoading(false))

  }, [location.search, prevLocationSearch, fetchLeads])


  const resetFilters = () => {
    history.push('/organizations/' + projectCtx.currentOrganization.id + '/projects/' + projectCtx.currentProject.id + '/leads?' + QS.stringify(defaultParams, { indices: false }));
  }


  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    // console.log('filters', filters);
    // console.log('pagination', pagination);
    // console.log('sorter', sorter);
    const params: any = QS.parse(location.search, { ignoreQueryPrefix: true })

    const newParams = Object.assign({
      limit: params.limit || defaultParams.limit,
      skip: params.skip || defaultParams.skip,
      sortKey: params.sortKey || defaultParams.sortKey,
      sortOrder: params.sortOrder || defaultParams.sortOrder,
      conversionRule: params.conversionRule,
      domainId: params.domainId,
    }, {
      limit: pagination.pageSize,
      skip: (pagination.current * pagination.pageSize) - pagination.pageSize,
      sortKey: sorter.columnKey,
      sortOrder: sorter.order,
      conversionRule: get(filters, 'conversionRule[0]'),
      domainId: get(filters, 'domainId[0]'),
    })

    updateQuery(newParams)
  }

  const updateQuery = (update: any) => {
    const params: any = QS.parse(location.search, { ignoreQueryPrefix: true })

    let query = {
      limit: params.limit || defaultParams.limit,
      skip: params.skip || defaultParams.skip,
      dateFrom: params.dateFrom || undefined,
      dateTo: params.dateTo || undefined,
      externalId: params.externalId || undefined,
      channelId: params.channelId || undefined,
      sortKey: params.sortKey || defaultParams.sortKey,
      sortOrder: params.sortOrder || defaultParams.sortOrder,
      conversionRule: params.conversionRule || undefined,
      domainId: params.domainId || undefined,
    }

    history.push('/organizations/' + projectCtx.currentOrganization.id + '/projects/' + projectCtx.currentProject.id + '/leads?' + QS.stringify(Object.assign(query, update), { indices: false }));
  }

  // const channelsMap = keyBy(projectCtx.currentProject.channels, 'id');
  const conversionRulesMap = keyBy(projectCtx.currentProject.conversionRules, 'id')
  const domainsMap = keyBy(projectCtx.currentProject.domains, 'id')

  const channelsFilter = []

  projectCtx.currentProject.channelGroups.filter((x: any) => !x.deletedAt).forEach((group: any) => {

    let filter = {
      label: <span className={'channel-group ' + group.id} key={group.id}>{group.name}</span>,
      value: group.id,
      children: projectCtx.currentProject.channels.filter((ch: any) => ch.groupId === group.id && !ch.deletedAt).map((ch: any) => {
        return { label: ch.name, value: ch.id }
      })
    }

    if (filter.children.length > 0) {
      channelsFilter.push(filter)
    }
  })

  const params: any = QS.parse(location.search, { ignoreQueryPrefix: true })

  const leadConversionRules = projectCtx.currentProject.conversionRules.filter(x => x.kind === 'lead')

  const columns = [
    {
      title: 'ID',
      key: 'externalId',
      sortOrder: params.sortKey === 'externalId' ? params.sortOrder : undefined,
      sorter: (a: any, b: any) => {
        if (a.order.conversionExternalId < b.order.conversionExternalId) { return -1 }
        if (a.order.conversionExternalId > b.order.conversionExternalId) { return 1 }
        return 0
      },
      // filterDropdown: (
      //   <div className="custom-filter-dropdown">
      //     {/* <Input.Search
      //                   placeholder="Search conversion ID"
      //                   defaultValue={params.externalId}
      //                   enterButton
      //                   allowClear
      //                   onSearch={onChangeExternalID}
      //               /> */}
      //   </div>
      // ),
      // // filterDropdownVisible: filterConversionIdVisible,
      // // onFilterDropdownVisibleChange: (visible: any) => this.setState({ filterConversionIdVisible: visible }),
      // filteredValue: (params.externalId && params.externalId !== '') ? [params.externalId] : [],
      render: (record: any) => {
        if (record.conversionExternalId.length > 15) {
          return <Tooltip title={record.conversionExternalId}>{truncate(record.conversionExternalId, { length: 16, omission: '...' })}</Tooltip>
        }
        return record.conversionExternalId
      }
    },
    {
      key: 'customer',
      title: t('customer', "Customer"),
      render: t => {
        if (t.company && t.company.id) {
          return <div>
            {t.company.photoURL ? <img className="user-picture" src={t.company.photoURL} alt="" /> : <span className="opacity-30"><FontAwesomeIcon icon="building" /></span>}
            {/* <span className="padding-l-s link" onClick={projectCtx.viewCompany.bind(null, t.company.externalId)}>{(t.company.name || '')}</span> */}
          </div>
        } else {
          let userName = t.user?.externalId

          if (t.user?.firstName && t.user?.firstName !== '') {
            userName = t.user.firstName
          }

          if (t.user?.lastName && t.user?.lastName !== '') {
            userName += ' ' + t.user.lastName
          }

          return <div>
            {t.user?.photoURL ? <img className="user-picture" src={t.user?.photoURL} alt="" /> : <span className="user-picture-icon"><FontAwesomeIcon icon="user-circle" /></span>}
            <span className="padding-l-s link" onClick={projectCtx.viewUser.bind(null, t.user?.externalId)}>{userName}</span>
          </div>
        }
      }
    },
    {
      key: 'stage',
      title: 'Stage',
      render: t => {
        const stage = conversionRulesMap[t.conversionRuleId].leadStages.find(x => x.id === t.stageId)
        if (!stage) return t.stageId
        return <Tag color={stage.color}>{stage.label}</Tag>
      }
    },
  ]

  if (leadConversionRules.length > 1) {
    columns.push(
      {
        title: t('conversion_rule', "Conversion rule"),
        key: 'conversionRule',
        // filters: projectCtx.currentProject.conversionRules.map(cr => {
        //     return {
        //         text: cr.name,
        //         value: cr.id
        //     }
        // }),
        // filterMultiple: false,
        // onFilter: (value, record) => record.conversionRuleId === value,
        // filteredValue: params.conversionRule ? [params.conversionRule] : null,
        render: (record: any) => conversionRulesMap[record.conversionRuleId].name
      })
  }

  columns.push(
    {
      title: 'Stage date',
      key: 'createdAt',
      // filterDropdown: (
      //   <div className="custom-filter-dropdown">
      //     <DatePicker
      //                   style={{ width: '100%', marginBottom: '7px' }}
      //                   defaultValue={(params.dateFrom) ? Moment(params.dateFrom, 'YYYY-MM-DD') : undefined}
      //                   disabledDate={(date: any) => {
      //                       return date && date.isAfter(Moment())
      //                   }}
      //                   format="MMM Do YYYY"
      //                   placeholder="Start date"
      //                   onChange={(momentDate) => {
      //                       this.updateQuery({
      //                           dateFrom: (momentDate) ? momentDate.format('YYYY-MM-DD') : undefined
      //                       })
      //                   }} />

      //               <DatePicker
      //                   style={{ width: '100%' }}
      //                   defaultValue={(params.dateTo) ? Moment(params.dateTo, 'YYYY-MM-DD') : undefined}
      //                   disabledDate={(date: any) => {
      //                       return date && date.isAfter(Moment())
      //                   }}
      //                   format="MMM Do YYYY"
      //                   placeholder="End date"
      //                   onChange={(momentDate) => {
      //                       this.updateQuery({
      //                           dateTo: (momentDate) ? momentDate.format('YYYY-MM-DD') : undefined
      //                       })
      //                   }} />
      //   </div>
      // ),
      // filterDropdownVisible: filterDateVisible,
      // onFilterDropdownVisibleChange: (visible: any) => this.setState({filterDateVisible: visible }),
      // filteredValue: (params.dateFrom || params.dateTo) ? [params.dateFrom, params.dateTo] : [],
      sortOrder: params.sortKey === 'createdAt' ? params.sortOrder : undefined,
      // sorter: (a: any, b: any) => {
      //   if (a.createdAt < b.createdAt) { return -1 }
      //   if (a.createdAt > b.createdAt) { return 1 }
      //   return 0
      // },
      render: lead => <small>{Moment(lead.createdAt).format('llll')}</small>
    },

    {
      title: t('domain', "Domain"),
      key: 'domainId',
      // filters: projectCtx.currentProject.domains.map((cr: any) => {
      //   return {
      //     text: cr.name,
      //     value: cr.id
      //   }
      // }),
      // filterMultiple: false,
      // onFilter: (value, record) => record.domainId === value,
      // filteredValue: params.domainId ? [params.domainId] : null,
      render: (record: any) => domainsMap[record.domainId].name
    },
    {
      key: 'revenue',
      title: t('revenue', "Revenue"),
      render: t => Formatters.currency(projectCtx.currentProject.currency, t.revenue, t.revenueSource, t.currency, 'color-kpi-purple')
    },
    // {
    //     title: t('funnel', "Funnel"),
    //     key: 'funnel',
    //     // filterDropdown: (
    //     //   <div className="custom-filter-dropdown">
    //     //     <Cascader 
    //     //       options={channelsFilter}
    //     //       placeholder="Channel"
    //     //       defaultValue={cascaderDefaultValue}
    //     //       expandTrigger="hover"
    //     //       style={{width: '100%'}}
    //     //       displayRender={(label, selectedOption) => label[1]}
    //     //       popupClassName="channels-cascader"
    //     //       allowClear={true}
    //     //       showSearch={{
    //     //         filter: (searchText, path) => {
    //     //           return searchText !== '' && path[1].value.indexOf(searchText.toLowerCase()) === 0
    //     //         },
    //     //         sort: (a,b, inputValue) => {
    //     //           const callback = (elem) => {
    //     //             const label = (typeof elem.label === 'object') ? elem.label.props.children : elem.label
    //     //             return label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    //     //           }
    //     //           return a.findIndex(callback) - b.findIndex(callback)
    //     //         },
    //     //         render: (inputValue, path, prefixCls) => {
    //     //           return path.map((_ref:any , index: number) => {
    //     //             return index === 0 ? _ref.label : [' / ', _ref.label]
    //     //           })
    //     //         },
    //     //         matchInputWidth: false
    //     //       }}
    //     //       onChange={(values: any) => {
    //     //         this.updateQuery({channelId: values[1] || undefined})
    //     //       }} />
    //     //   </div>
    //     // ),
    //     // filterDropdownVisible: filterChannelVisible,
    //     // onFilterDropdownVisibleChange: visibl(e: any) => this.setState({ filterChannelVisible: visible }),
    //     // filteredValue: (params.channelId && params.channelId !== '') ? cascaderDefaultValue : [],
    //     render: (record) => {
    //         // console.log('record', record);

    //         // group identical channelId if funnel has different source/medium
    //         let funnel: any = []

    //         forEach(record.funnel, f => {

    //             if (!f.count) {
    //                 f.count = 0
    //             }

    //             // find channel
    //             var hitChannel = projectCtx.currentProject.channels.find((x: any) => x.id === f.channelId)
    //             // console.log('hitChannel', hitChannel);

    //             if (!hitChannel) {
    //                 hitChannel = { 
    //                     id: 'not-mapped', 
    //                     name: t('not_mapped', "Not mapped"),
    //                     isDefault: true,
    //                     sourceMediumPaths: [],
    //                     voucherCodes: [],
    //                     groupId: 'not-mapped',
    //                     createdAt: new Date(),
    //                     updatedAt: new Date(),
    //                     isMediaPartner: false
    //                 }
    //             }

    //             f.channelId = hitChannel.id
    //             f.channelName = hitChannel.name

    //             if (funnel.length === 0) {
    //                 funnel.push(f)
    //             } else {
    //                 if (funnel[funnel.length - 1].channelId === f.channelId) {
    //                     funnel[funnel.length - 1].count += f.count
    //                     if (funnel[funnel.length - 1].role < f.role) {
    //                         funnel[funnel.length - 1].role = f.role
    //                     }
    //                 } else {
    //                     funnel.push(f)
    //                 }
    //             }
    //         })

    //         return funnel.map((contribution: any, i: number) => {

    //             if (i === 2) return <Popover key={i} content={<div>
    //                 {funnel.map((c: any, k: number) => <div key={k}>{(c.channelId === 'not-mapped') ? c.source + ' / ' + c.medium : channelsMap[c.channelId].name}</div>)}</div>}>
    //                 <small className="link">+{funnel.length - 2} {t('more', "more")}</small>
    //             </Popover>
    //             if (i > 2) return null
    //             return <div key={i}><small>{(contribution.channelId === 'not-mapped') ? contribution.source + ' / ' + contribution.medium : channelsMap[contribution.channelId].name}</small></div>
    //         })
    //     }
    // },
    {
      key: 'actions',
      title: <span className="pull-right"><Button type="ghost" size="small" onClick={() => {
        setLoading(true)
        fetchLeads().finally(() => setLoading(false))
      }} disabled={loading}><FontAwesomeIcon icon="sync-alt" spin={loading} /></Button></span>,
      // className: 'text-right',
      render: (record: any) => <div className="text-right">
        {/* <Button.Group>
          <DeleteOrderButton {...this.props} order={record} onComplete={this.fetchLeads} />

          <Tooltip placement="left" title={t('view_conversion_funnel', "View conversion funnel")}>
            <Button type="ghost" icon={<EyeOutlined />} onClick={projectCtx.viewLead.bind(null, record.conversionExternalId, record.conversionRuleId)} />
          </Tooltip>
        </Button.Group> */}
      </div>
    })

  return <AppLayout currentProject={projectCtx.currentProject} currentOrganization={projectCtx.currentOrganization} admin={projectCtx.admin} firebaseUser={projectCtx.firebaseUser} projects={projectCtx.projects}>

    <h1>
      <div className="actions">
        {/* <Button.Group>
          <Button type="ghost" onClick={toggleReattribute}>{t('reattribute_conversions', "Reattribute conversions")}</Button>
          <Button type="ghost" onClick={toggleRefreshSegment}>{t('refresh_segment', "Refresh segment")}</Button>
        </Button.Group> */}
      </div>
      Leads
    </h1>

    <Table
      dataSource={leads}
      rowKey="id"
      className="edge-table block"
      size="middle"
      onChange={handleTableChange}
      loading={loading}
      pagination={{
        position: ['bottomCenter'],
        total: leadsCount
      }}
      locale={{
        'emptyText': <span>
          {t('no_data', "No data")}
          {(params.externalId || params.channelId || params.dateFrom || params.dateTo) && <span className="padding-l-s">&bull; <span className="padding-l-s link" onClick={resetFilters}>
            {t('reset_filters', "Reset filters")}
          </span></span>}
        </span>
      }}
      columns={columns}
    />
  </AppLayout>
}

export default LeadsList