import React from 'react'
import N from 'numeral'
import t from './translate'
import { FacebookOutlined, GoogleOutlined, InfoCircleOutlined, SkinOutlined } from '@ant-design/icons';
import { Tag, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Moment from 'moment-timezone'
import Numeral from 'numeral'
import _ from 'lodash'

const graphCurrency = (currencyCode: string, amount: number) => {
  let str

  let amountFormatted = N(amount / 100).format('0,0[.]00')

  switch (currencyCode) {
    case 'ARS': str = '$' + amountFormatted; break
    case 'AUD': str = '$' + amountFormatted; break
    case 'BRL': str = 'R$' + amountFormatted; break
    case 'CAD': str = '$' + amountFormatted; break
    case 'COP': str = '$' + amountFormatted; break
    case 'CZK': str = 'Kč' + amountFormatted; break
    case 'DKK': str = 'kr' + amountFormatted; break
    case 'EUR': str = amountFormatted + '€'; break
    case 'HKD': str = '$' + amountFormatted; break
    case 'IDR': str = 'Rp' + amountFormatted; break
    case 'ILS': str = '₪' + amountFormatted; break
    case 'JPY': str = '¥' + amountFormatted; break
    case 'KRW': str = '₩' + amountFormatted; break
    case 'MYR': str = 'RM' + amountFormatted; break
    case 'MXN': str = '$' + amountFormatted; break
    case 'NZD': str = '$' + amountFormatted; break
    case 'PKR': str = '₨' + amountFormatted; break
    case 'PHP': str = '₱' + amountFormatted; break
    case 'SGD': str = '$' + amountFormatted; break
    case 'ZAR': str = 'R' + amountFormatted; break
    case 'TWD': str = 'NT$' + amountFormatted; break
    case 'GBP': str = '£' + amountFormatted; break
    case 'USD': str = '$' + amountFormatted; break
    default: str = currencyCode + amountFormatted
  }

  return str
}

const currencyFormatter = (currencyCode: any, amount: number, originalAmount?: number, originalCurrency?: number, className?: string, classNameOriginal?: string, light?: boolean) => {
  let str

  let amountFormatted = N(amount / 100).format(light ? '0,0' : '0,0[.]00')

  switch (currencyCode) {
    case 'ARS': str = '$' + amountFormatted; break
    case 'AUD': str = '$' + amountFormatted; break
    case 'BRL': str = 'R$' + amountFormatted; break
    case 'CAD': str = '$' + amountFormatted; break
    case 'COP': str = '$' + amountFormatted; break
    case 'CZK': str = 'Kč' + amountFormatted; break
    case 'DKK': str = 'kr' + amountFormatted; break
    case 'EUR': str = amountFormatted + '€'; break
    case 'HKD': str = '$' + amountFormatted; break
    case 'IDR': str = 'Rp' + amountFormatted; break
    case 'ILS': str = '₪' + amountFormatted; break
    case 'JPY': str = '¥' + amountFormatted; break
    case 'KRW': str = '₩' + amountFormatted; break
    case 'MYR': str = 'RM' + amountFormatted; break
    case 'MXN': str = '$' + amountFormatted; break
    case 'NZD': str = '$' + amountFormatted; break
    case 'PKR': str = '₨' + amountFormatted; break
    case 'PHP': str = '₱' + amountFormatted; break
    case 'SGD': str = '$' + amountFormatted; break
    case 'ZAR': str = 'R' + amountFormatted; break
    case 'TWD': str = 'NT$' + amountFormatted; break
    case 'GBP': str = '£' + amountFormatted; break
    case 'USD': str = '$' + amountFormatted; break
    default: str = currencyCode + amountFormatted
  }

  if (originalCurrency && originalCurrency !== currencyCode && originalAmount && originalAmount > 0) {
    return <span><span className={className || ''}>{str}</span> <span className={classNameOriginal || 'size-12'}>({currencyFormatter(originalCurrency, originalAmount)})</span></span>
  } else {
    return <span className={className || ''}>{str}</span>
  }
}

const exported = {
  funnelRole: (position: number) => {
    switch (position) {
      case 0: return <Tag color="cyan" className="no-pointer">{t('role_alone', "Alone")}</Tag>
      case 1: return <Tag color="geekblue" className="no-pointer">{t('role_init', "Initiating")}</Tag>
      case 2: return <Tag color="gold" className="no-pointer">{t('role_assist', "Assisting")}</Tag>
      case 3: return <Tag color="volcano" className="no-pointer">{t('role_closer', "Closer")}</Tag>
      default: return ''
    }
  },
  actionTagColor: (action: string) => {
    switch (action) {
      case 'signup': return 'green'
      case 'pageview': return 'blue'
      case 'event': return 'purple'
      case 'cart': return 'magenta'
      case 'wishList': return 'magenta'
      case 'order': return 'volcano'
      case 'enterSegment': return 'green'
      case 'exitSegment': return 'red'
      case 'workflow': return 'cyan'
      case 'notification': return 'purple'
      case 'notificationTopic': return 'purple'
      case 'abandonedCart': return 'red'
      default: return undefined
    }
  },
  deviceColor: (deviceName: string) => {
    switch (deviceName) {
      case 'desktop': return '#00BCD4' // '#9C27B0'
      case 'tablet': return '#673AB7'
      case 'phone': return '#8BC34A'
      case 'console': return '#2196F3'
      case 'wearable': return '#03A9F4'
      case 'tv': return '#009688'
      default: return '#009688'
    }
  },
  // findChannelFromSourceMedium: (source, medium, channels) => {

  //   const path = source + ' / ' + medium

  //   const channel = channels.find((ch) => {

  //     return ch.sourceMediumPaths?.find((smp) => {
  //       if (smp.matchType === 'equals' && path === smp.value) {
  //         return true
  //       }

  //       if (smp.matchType === 'regex' && RegExp(smp.value).test(path)) {
  //         return true
  //       }

  //       return false
  //     })
  //   })

  //   return channel
  // },
  // channelGroupTag: (groupId, className) => {

  //   className = className ? 'no-pointer ' + className : 'no-pointer'

  //   if (groupId === 'not-mapped') {
  //     return <Tag className={className} color="#607D8B">not mapped</Tag>
  //   }

  //   return <Tag className={className} color={ChannelGroups[groupId].color}>{ChannelGroups[groupId].name}</Tag>
  // },
  deviceIcon: (deviceType: string) => {
    switch (deviceType) {
      case 'computer': return <FontAwesomeIcon icon="desktop" />
      case 'tablet': return <FontAwesomeIcon icon="tablet-alt" />
      case 'phone': return <FontAwesomeIcon icon="mobile-alt" />
      case 'console': return <FontAwesomeIcon icon="gamepad" />
      case 'wearable': return <SkinOutlined />;
      case 'tv': return <FontAwesomeIcon icon="tv" />
      default: return null
    }
  },
  int: (value: number) => N(value).format('0,0'),
  float: (value: number) => N(value).format('0,0[.]0'),
  ratio: (value: number) => N(value).format('0,0[.]0'),
  currency: currencyFormatter,
  graphCurrency: graphCurrency,

  percentage: (value: number) => N(value).format('0[.]0') + '%',
  duration: (totalSecs: number, precision?: number) => {

    if (precision === undefined) {
      precision = 2
    }

    if (!totalSecs) {
      return '0s'
    }

    totalSecs = Math.round(totalSecs)

    let result, mins, secs, hours, days

    // in days
    if (totalSecs >= 86400) {
      days = Math.round(totalSecs / 86400)
      hours = Math.round((totalSecs - (days * 86400)) / 3600)
      mins = Math.round((totalSecs - (days * 86400) - (hours * 3600)) / 60)
      secs = totalSecs - (days * 86400) - (hours * 3600) - (mins * 60)

      result = days + 'd'
      if (hours > 0 && precision >= 2) result += ' ' + hours + 'h'
      if (mins > 0 && precision >= 3) result += ' ' + mins + 'm'
      if (secs > 0 && precision >= 4) result += ' ' + secs + 's'
      return result

    } else if (totalSecs >= 3600) {

      // in hours
      hours = Math.round(totalSecs / 3600)
      mins = Math.round((totalSecs - (hours * 3600)) / 60)
      secs = totalSecs - (hours * 3600) - (mins * 60)

      result = hours + 'h'
      if (mins > 0 && precision >= 2) result += ' ' + mins + 'm'
      if (secs > 0 && precision >= 3) result += ' ' + secs + 's'
      return result

    } else if (totalSecs >= 60) {
      // in mins
      mins = Math.round(totalSecs / 60)
      secs = totalSecs % 60
      result = mins + 'm'
      if (secs > 0) result += ' ' + secs + 's'
      return result

    } else {
      return totalSecs + 's'
    }
  },
  growthRate: (currentValue: number, previousValue: number, inverse?: boolean) => {

    if (!previousValue) {
      previousValue = 0
    }

    if (!currentValue) {
      currentValue = 0
    }

    let rate = 0
    if (previousValue === 0 && currentValue !== 0) rate = 100
    else if (currentValue === 0 && previousValue !== 0) rate = -100
    else if (currentValue === previousValue) rate = 0
    else if (previousValue !== 0 && currentValue !== 0) {

      if (currentValue > previousValue) {
        rate = ((currentValue * 100) / previousValue) - 100
      } else {
        rate = -(100 - (currentValue * 100) / previousValue)
      }
    }

    // console.log('current: '+currentValue+', previous: '+previousValue+', rate', rate);

    if (isNaN(rate)) return 'NaN'
    if (rate === 0) return ''

    if (rate > 0) {
      return <span style={{ opacity: '0.8' }} className={inverse ? "color-orange padding-r-s" : "color-green padding-r-s"}>▴ {N(rate).format('0[.]0')}%</span>
    } else {
      return <span style={{ opacity: '0.8' }} className={inverse ? "color-green padding-r-s" : "color-orange padding-r-s"}>▾ {N(rate).format('0[.]0')}%</span>
    }
  },
  frequency: (dateFrom: string, count: number) => {

    if (count < 2) {
      return '-'
    }

    // count how many days between 2 dates
    const duration = Math.abs(Moment(dateFrom).diff(Moment(), 'months')) || 1
    const perMonth = count / duration

    // express per month when the first order is 12 months old
    if (duration >= 12) {
      if (perMonth >= 1) {
        // express per month when at least one per month
        return Numeral(perMonth).format('0,0[.]0') + ' / month'
      } else {
        // express float per year
        return Numeral(perMonth * 12).format('0,0[.]0') + ' / year'
      }
    }

    // default per year
    return Numeral(count).format('0,0[.]0') + ' / year'
  },
  conditionField: (kind: string, fieldKey: string, dictionnary: any) => {
    const found = Array.isArray(dictionnary[kind]) ? dictionnary[kind].find((x: any) => x.key === fieldKey) : dictionnary[kind]
    return found ? found.label : fieldKey
  },
  conditionTimeframe: (timeframe: string) => {
    switch (timeframe) {
      case 'last24hours':
        return t('in_the_last_24_hours', "in the last 24 hours")
      case 'last2days':
        return t('in_the_last_2_days', "in the last 2 days")
      case 'last7days':
        return t('in_the_last_7_days', "in the last 7 days")
      case 'last30days':
        return t('in_the_last_30_days', "in the last 30 days")
      case 'last90days':
        return t('in_the_last_90_days', "in the last 90 days")
      case 'last365days':
        return t('in_the_last_365_days', "in the last 365 days")
      default:
        return timeframe
    }
  },
  conditionOperator: (operator: string) => {
    switch (operator) {
      case 'exists':
        return t('exists', "exists")
      case 'notExist':
        return t('does_not_exist', "does not exist")
      case 'equals':
        return t('equals', "equals")
      case 'notEqual':
        return t('does_not_equal', "does not equal")
      case 'contains':
        return t('contains', "contains")
      case 'notContain':
        return t('does_not_contain', "does not contain")
      case 'isTrue':
        return t('is_true', "is true")
      case 'isFalse':
        return t('is_false', "is false")
      case 'greaterThan':
        return t('is_greater_than', "is greater than")
      case 'lessThan':
        return t('is_less_than', "is less than")
      case 'inBetween':
        return t('is_in_between', "is in between")
      case 'inTheLast':
        return t('is_in_the_last', "is in the last")
      // actions
      case 'atLeast':
        return t('at_least', "at least")
      case 'exactly':
        return t('exactly', "exactly")
      case 'atMost':
        return t('at_most', "at most")
      // notification topic
      case 'isSubscribed':
        return t('is_subscribed_to', "is subscribed to")
      case 'isNotSubscribed':
        return t('is_not_subscribed_to', "is not subscribed to")
      default:
        return operator
    }
  },
  customProperty: (value: any, timezone: string) => {
    switch (toString.call(value)) {
      case '[object Boolean]':
        return value.toString()

      case '[object Object]':

        if (value["$date"]) {
          return <Tooltip title={Moment(value["$date"]).tz(value["$timezone"] || 'UTC').format('lll') + ' (' + (value["$timezone"] || 'UTC') + ')'}>{Moment(value["$date"]).fromNow()}</Tooltip>
        }
        break

      case '[object String]':
        // is date
        if (/-?([1-9][0-9]{3,}|0[0-9]{3})-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])T(([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9](\.[0-9]+)?|(24:00:00(\.0+)?))(Z|(\+|-)((0[0-9]|1[0-3]):[0-5][0-9]|14:00))?/.test(value)) {
          return <Tooltip title={Moment(value).tz(timezone || 'UTC').format('lll') + ' (' + (timezone || 'UTC') + ')'}>{Moment(value).fromNow()}</Tooltip>
        }


        // is url
        if (/https?:\/\/([\da-zA-Z.-]+)\.([a-zA-Z.]{2,6})([/\w.-]*)*\/?/.test(value)) {
          return <a href={value} target="_blank" rel="noreferrer noopener">{value}</a>
        }

        return value

      case '[object Number]':
        return Numeral(value).format('0,0[.]00')

      case '[object Array]':
        return value.map((item: any) => <span key={item}><Tag className="no-pointer margin-r-s xsmall">{item}</Tag></span>)

      default:
        return value.toString()
    }
  },
  translateNodeType: (type: string) => {
    switch (type) {
      case 'split':
        return t('a_b_test', "A/B test")
      case 'syncGoogleAdsSegment':
        return t('sync_google_ads_segment', "Sync Google Ads segment")
      default:
        return type
    }
  },
  scenarioNodeIcon: (type: string) => {
    switch (type) {
      case 'sendMobilePush':
        return <FontAwesomeIcon icon="mobile-alt" />
      case 'sendSMS':
        return <FontAwesomeIcon icon="comment-dots" />
      case 'sendWebhook':
        return <FontAwesomeIcon icon="link" />
      case 'sendEmail':
        return <FontAwesomeIcon icon="envelope" />
      case 'sendNotification':
        return <FontAwesomeIcon icon="envelope" />
      case 'subscribeToNotificationTopic':
        return <FontAwesomeIcon icon="bell" />
      case 'unsubscribeFromNotificationTopic':
        return <FontAwesomeIcon icon="bell-slash" />
      case 'tagUser':
        return <FontAwesomeIcon icon="tag" />
      case 'untagUser':
        return <FontAwesomeIcon icon="tag" />
      case 'addToGoogleAdsAudience':
        return <GoogleOutlined />;
      case 'removeFromGoogleAdsAudience':
        return <GoogleOutlined />;
      case 'addToMailchimpAudience':
        return <FontAwesomeIcon icon={['fab', 'mailchimp']} />
      case 'removeFromMailchimpAudience':
        return <FontAwesomeIcon icon={['fab', 'mailchimp']} />
      case 'addToFacebookAudience':
        return <FacebookOutlined />;
      case 'removeFromFacebookAudience':
        return <FacebookOutlined />;
      case 'if':
        return <FontAwesomeIcon icon="question" />
      case 'split':
        return <FontAwesomeIcon icon="divide" />
      case 'wait':
        return <FontAwesomeIcon icon="hourglass-half" />
      default:
        return type
    }
  },
  segment: (segmentsMap: any, currentSegmentId: string, className?: string, separated?: boolean) => {

    if (currentSegmentId === '_all') {
      return <Tag>{t('all_users', "All users")}</Tag>
    }

    if (_.isArray(segmentsMap)) {
      segmentsMap = _.keyBy(segmentsMap, 'id')
    }

    if (!segmentsMap[currentSegmentId]) {
      return ''
    }

    const currentSegment = segmentsMap[currentSegmentId]

    if (!currentSegment.parentId) {
      return <Tag className={className} color={currentSegment.color !== 'default' && currentSegment.color}>{segmentsMap[currentSegmentId].name}</Tag>
    }

    // list the parents of this segment
    const parents: any[] = []

    const findResursiveParent = (segmentsMap: any, parentId: string, parents: any[]) => {
      if (parentId && segmentsMap[parentId]) {
        parents.push(segmentsMap[parentId])
        if (segmentsMap[parentId].parentId) {
          findResursiveParent(segmentsMap, segmentsMap[parentId].parentId, parents)
        }
      }
    }

    findResursiveParent(segmentsMap, currentSegment.parentId, parents)

    const reversed = parents.reverse()

    if (!separated) {
      return <Tag key={currentSegment.id} color={currentSegment.color !== 'default' && currentSegment.color}>
        {reversed.map(p => <span key={p.id}>{p.name} &gt; </span>)}
        {currentSegment.name}
      </Tag>
    }

    return <>
      {reversed.map(p => <span key={p.id}><Tag key={p.id} className={className} color={p.color !== 'default' && p.color}>{p.name}</Tag> &gt;&nbsp;&nbsp;</span>)}
      <Tag className={className} key={currentSegment.id} color={currentSegment.color !== 'default' && currentSegment.color}>{currentSegment.name}</Tag>
    </>

  },
  workflowTriggerForTimeline: (kind: string, segments: any, segmentId: string, eventCustomLabel?: string, leadStageId?: string) => {
    switch (kind) {
      case 'onManualExecution':
        return <span className="" style={{ color: '#00C853' }}><FontAwesomeIcon icon="toggle-on" className="margin-r-s" /> {t('manual_execution', "Manual execution")}</span>
      case 'scheduled':
        return <span className="padding-r-m" style={{ color: '#00C853' }}><FontAwesomeIcon icon="clock" className="margin-r-s" /> {t('scheduled', "Scheduled")}</span>
      case 'repeat':
        return <span className="padding-r-m" style={{ color: '#00C853' }}><FontAwesomeIcon icon="redo" className="margin-r-s" /> {t('repeat', "Repeat")}</span>
      case 'abandonedCart':
        return <>
          <span className="padding-r-m" style={{ color: '#00C853' }}><FontAwesomeIcon icon="bolt" /></span>
          <span style={{ color: '#00C853' }}>{t('abandoned_cart', "Abandoned cart")} <Tag className="xsmall margin-l-s" color="orange">abandonedCart</Tag></span>
        </>
      case 'enterSegment':
        return <>
          <span className="padding-r-m" style={{ color: '#00C853' }}><FontAwesomeIcon icon="bolt" /></span>
          <span style={{ color: '#00C853' }}>{t('enter_segment', "Enter segment")}</span> <span className="margin-l-s">{exported.segment(segments, segmentId, 'xsmall', true)}</span>
        </>
      case 'exitSegment':
        return <>
          <span className="padding-r-m" style={{ color: '#00C853' }}><FontAwesomeIcon icon="bolt" /></span>
          <span style={{ color: '#00C853' }}>{t('exit_segment', "Exit segment")}</span> <span className="margin-l-s">{exported.segment(segments, segmentId, 'xsmall', true)}</span>
        </>
      case 'custom':
        return <>
          <span className="padding-r-m" style={{ color: '#00C853' }}><FontAwesomeIcon icon="bolt" /></span>
          <Tag className="xsmall margin-l-s" color="purple">{eventCustomLabel}</Tag>
        </>
      case 'lead':
        return <>
          <span className="padding-r-m" style={{ color: '#00C853' }}><FontAwesomeIcon icon="bolt" /></span>
          <Tag className="xsmall margin-l-s" color="purple">Lead - {leadStageId}</Tag>
        </>
      default:
        return kind
    }
  },
  workflowTrigger: (workflowKind: string, trigger: any, segments: any) => {

    let executedAt: any = ''
    if (trigger.executedAt) {
      executedAt = <span className="padding-l-m"><Tooltip title={'Last execution: ' + Moment(trigger.executedAt).tz('UTC').format('lll') + ' in UTC'}><InfoCircleOutlined /></Tooltip></span>
    }

    if (workflowKind === 'generic') {
      switch (trigger.kind) {
        case 'onManualExecution':
          return <>
            <span className="" style={{ color: '#00C853' }}><FontAwesomeIcon icon="toggle-on" className="margin-r-s" /> {t('on_manual_execution', "On manual execution")}</span>
            {executedAt}
          </>
        case 'scheduled':
          return <>
            <span className="padding-r-m" style={{ color: '#00C853' }}><FontAwesomeIcon icon="clock" className="margin-r-s" /> {t('scheduled', "Scheduled")}</span>
            {' ' + Moment(trigger.scheduledAt, 'YYYY-MM-DD HH:mm:ss').format('LLLL')} ({trigger.timezone})
            {executedAt}
          </>

        case 'repeat':
          return <>
            <span className="padding-r-m" style={{ color: '#00C853' }}><FontAwesomeIcon icon="redo" className="margin-r-s" /> {t('repeat', "Repeat")}</span>
            {trigger.repeatEvery === 'year' && <span>{t('every_year', "Every year")}: {Moment(trigger.repeatMonth + '-' + trigger.repeatDayOfMonth + ' ' + trigger.repeatTime, 'M-D HH:mm:ss').format('MMMM Do LT')} ({trigger.timezone})</span>}
            {trigger.repeatEvery === 'month' && <span>{t('every_month', "Every month")}: {Moment(trigger.repeatDayOfMonth + ' ' + trigger.repeatTime, 'D HH:mm:ss').format('Do LT')} ({trigger.timezone})</span>}
            {trigger.repeatEvery === 'week' && <span>{t('every_week', "Every week")}: {Moment(trigger.repeatDayOfWeek + ' ' + trigger.repeatTime, 'd HH:mm:ss').format('dddd LT')} ({trigger.timezone})</span>}
            {trigger.repeatEvery === 'day' && <span>{t('every_day', "Every day")}: {Moment(trigger.repeatTime, 'HH:mm:ss').format('LT')} ({trigger.timezone})</span>}
            {trigger.repeatEvery === 'hour' && <span>{t('every_hour', "Every hour")}</span>}
            {executedAt}
          </>

        default:
          return <><span className="padding-r-m" style={{ color: '#00C853' }}>{trigger.kind}</span> {executedAt}</>
      }
    }


    if (workflowKind === 'userEvent') {
      switch (trigger.kind) {
        case 'abandonedCart':
          return <>
            <span className="padding-r-m" style={{ color: '#00C853' }}><FontAwesomeIcon icon="bolt" /></span>
            <span style={{ color: '#00C853' }}>{t('abandoned_cart', "Abandoned cart")} <Tag className="xsmall margin-l-s" color="orange">abandonedCart</Tag></span>
            {trigger.eventHappenedInTheLastMinutes > 0 && <span className="margin-l-s">{trigger.eventHappenedInTheLastMinutes} mins ago max</span>}
            {executedAt}
          </>

        case 'enterSegment':
          return <>
            <span className="padding-r-m" style={{ color: '#00C853' }}><FontAwesomeIcon icon="bolt" /></span>
            <span style={{ color: '#00C853' }}>{t('enter_segment', "Enter segment")} <span className="margin-l-s">{exported.segment(segments, trigger.segmentId, 'xsmall', true)}</span></span>
            {trigger.eventHappenedInTheLastMinutes > 0 && <span className="margin-l-s">{trigger.eventHappenedInTheLastMinutes} mins ago max</span>}
            {executedAt}
          </>

        case 'exitSegment':
          return <>
            <span className="padding-r-m" style={{ color: '#00C853' }}><FontAwesomeIcon icon="bolt" /></span>
            <span style={{ color: '#00C853' }}>{t('exit_segment', "Exit segment")} <span className="margin-l-s">{exported.segment(segments, trigger.segmentId, 'xsmall', true)}</span></span>
            {trigger.eventHappenedInTheLastMinutes > 0 && <span className="margin-l-s">{trigger.eventHappenedInTheLastMinutes} mins ago max</span>}
            {executedAt}
          </>

        case 'custom':
          return <>
            <span className="padding-r-m" style={{ color: '#00C853' }}><FontAwesomeIcon icon="bolt" /></span>
            <span style={{ color: '#00C853' }}>{t('custom_event', "Custom event")} <Tag className="xsmall margin-l-s" color="purple">{trigger.eventCustomLabel}</Tag></span>
            {trigger.eventHappenedInTheLastMinutes > 0 && <span className="margin-l-s">{trigger.eventHappenedInTheLastMinutes} mins ago max</span>}
            {executedAt}
          </>

        case 'lead':
          return <>
            <span className="padding-r-m" style={{ color: '#00C853' }}><FontAwesomeIcon icon="bolt" /></span>
            <span style={{ color: '#00C853' }}>Lead <Tag className="xsmall margin-l-s" color="purple">{trigger.leadStageId}</Tag></span>
            {trigger.eventHappenedInTheLastMinutes > 0 && <span className="margin-l-s">{trigger.eventHappenedInTheLastMinutes} mins ago max</span>}
            {executedAt}
          </>

        default:
          return <>
            <span className="padding-r-m" style={{ color: '#00C853' }}><FontAwesomeIcon icon="bolt" /></span>
            <span style={{ color: '#00C853' }}>{trigger.kind}</span>
            {trigger.eventHappenedInTheLastMinutes > 0 && <span className="margin-l-s">{trigger.eventHappenedInTheLastMinutes} mins ago max</span>}
            {executedAt}
          </>
      }
    }

    if (workflowKind === 'globalEvent') {
      switch (trigger.kind) {
        case 'productBackInStock':
          return <>
            <span className="padding-r-m" style={{ color: '#00C853' }}><FontAwesomeIcon icon="bolt" /></span>
            <span style={{ color: '#00C853' }}>{t('product_back_in_stock', "Product back in stock")}</span>
            {trigger.eventHappenedInTheLastMinutes > 0 && <span className="margin-l-s">{trigger.eventHappenedInTheLastMinutes} mins ago max</span>}
            {executedAt}
          </>
        case 'productPriceDrop':
          return <>
            <span className="padding-r-m" style={{ color: '#00C853' }}><FontAwesomeIcon icon="bolt" /></span>
            <span style={{ color: '#00C853' }}>{t('product_price_drop', "Product price drop")}</span>
            {trigger.eventHappenedInTheLastMinutes > 0 && <span className="margin-l-s">{trigger.eventHappenedInTheLastMinutes} mins ago max</span>}
            {executedAt}
          </>

        default:
          return <>
            <span className="padding-r-m" style={{ color: '#00C853' }}><FontAwesomeIcon icon="bolt" /></span>
            <span style={{ color: '#00C853' }}>{trigger.kind} <span className="margin-l-s">{trigger.notificationTopicId}</span></span>
            {trigger.eventHappenedInTheLastMinutes > 0 && <span className="margin-l-s">{trigger.eventHappenedInTheLastMinutes} mins ago max</span>}
            {executedAt}
          </>
      }
    }
  },
  workflowActionName: (action: any) => {
    switch (action) {
      case 'sendSMS':
        return t('send_an_SMS', "Send an SMS")
      case 'sendEmail':
        return t('send_an_email', "Send an email")
      case 'tagUser':
        return t('add_a_tag', "Add a tag")
      case 'untagUser':
        return t('remove_a_tag', "Remove a tag")

      case 'addToGoogleAdsAudience':
        return t('add_to_google_ads_audience', "Add to Google Ads audience")
      case 'removeFromGoogleAdsAudience':
        return t('remove_from_google_ads_audience', "Remove from Google Ads audience")

      case 'addToMailchimpAudience':
        return t('add_to_mailchimp_audience', "Add to Mailchimp audience")
      case 'removeFromMailchimpAudience':
        return t('remove_from_mailchimp_audience', "Remove from Mailchimp audience")

      case 'addToFacebookAudience':
        return t('add_to_facebook_audience', "Add to Facebook audience")
      case 'removeFromFacebookAudience':
        return t('remove_from_facebook_audience', "Remove from Facebook audience")
      default:
        return action
    }
  },
  multiPrices: (prices: any, currency: string) => {

    const keys = _.keys(prices)

    if (keys.length === 0) {
      return ''
    }

    // check if currency is available
    if (prices[currency]) {
      return currencyFormatter(currency, prices[currency].price)
    }

    return currencyFormatter(keys[0], prices[keys[0]].price)
  }
}

export default exported