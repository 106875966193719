import { Component } from 'react'

import t from '../../utils/translate'
import { Spin, Table, Row, Col, message } from 'antd'
import Formatters from '../../utils/formatters'
import {
  Chart,
  Interval,
  Label,
  Coordinate,
  Legend,
  Tooltip,
} from "bizcharts"
import removeLeadingDates from 'utils/removeLeadingDates'

class UsersPerDevice extends Component<any, any> {
  _isMounted = false;

  constructor(props: any) {
    super(props)

    this.state = {
      loading: true,
      currentDevices: [],
      previousDevices: [],
    }

    this.fetch = this.fetch.bind(this)
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetch()
  }

  componentDidUpdate(prevProps: any) {
    // check url params have changed
    if (prevProps.refreshAt !== this.props.refreshAt) {
      window.setTimeout(() => this.fetch())
    } else if (prevProps.project.id !== this.props.project.id) {
      window.setTimeout(() => this.fetch())
    }
  }

  fetch() {

    if (!this.state.loading) {
      this.setState({ loading: true })
    }

    const opts: any = {
      measures: ['ActiveUsers.count'],
      dimensions: ['ActiveUsers.sessionDevice'],
      timeDimensions: [
        {
          "dimension": 'ActiveUsers.truncCreatedAt',
          "granularity": null,
          "compareDateRange": [
            [this.props.dateFrom, this.props.dateTo],
            [this.props.dateFromPrevious, this.props.dateToPrevious]
          ]
        }
      ],
      timezone: this.props.timezone
    }


    this.props.cubejsApi.load(opts).then((resultSet: any) => {
      const [currentDevices, previousDevices] = resultSet.decompose()

      const currentDevicesTable = currentDevices.tablePivot()
      const previousDevicesTable = previousDevices.tablePivot()

      removeLeadingDates(currentDevicesTable)
      removeLeadingDates(previousDevicesTable)

      // console.log('currentDevices', currentDevicesTable);

      // compute totals
      const currentDevicesTotal = currentDevicesTable.reduce((acc: any, x: any) => acc + x['ActiveUsers.count'], 0)
      const previousDevicesTotal = previousDevicesTable.reduce((acc: any, x: any) => acc + x['ActiveUsers.count'], 0)

      // compute percentage of total
      currentDevicesTable.forEach((x: any, index: any) => {
        currentDevicesTable[index].key = x['ActiveUsers.sessionDevice']
        currentDevicesTable[index].percentage = currentDevicesTotal > 0 ? (x['ActiveUsers.count'] * 100) / currentDevicesTotal : 0
      })
      previousDevicesTable.forEach((x: any, index: any) => {
        previousDevicesTable[index].key = x['ActiveUsers.sessionDevice']
        previousDevicesTable[index].percentage = previousDevicesTotal > 0 ? (x['ActiveUsers.count'] * 100) / previousDevicesTotal : 0
      })

      // console.log('currentDevicesTotal', currentDevicesTotal);
      // console.log('previousDevicesTotal', previousDevicesTotal);

      this.setState({
        loading: false,
        currentDevices: currentDevicesTable,
        previousDevices: previousDevicesTable,
      })
    })
      .catch((error: any) => {

        console.log(error)

        let msg = error.message

        if (error.response && error.response.status === 400) {
          switch (error.response.data.message) {
            default:
              msg = error.response.data.message
          }
        }

        message.error(msg)
        this.setState({ loading: false })
      })
  }

  render() {

    // console.log('state kpi', this.state);
    const chartData = this.state.currentDevices.map((x: any) => {
      return { name: t(x.key, x.key), value: x.percentage }
    })

    return <div className="padding-a-m dash-table-stats">
      <Row gutter={24}>
        <Col span={12}>
          <Table rowKey="key"
            size="middle"
            showHeader={false}
            loading={this.state.loading}
            dataSource={this.state.currentDevices}
            pagination={{ pageSize: 6, hideOnSinglePage: true, showSizeChanger: false }}
            columns={[
              {
                title: '',
                key: 'name',
                render: (x: any) => {
                  return <span>{t(x.key, x.key)}</span>
                }
              },
              {
                title: '',
                key: 'percentage',
                width: 70,
                className: 'text-right',
                sorter: (a: any, b: any) => {
                  if (a.percentage < b.percentage) { return -1 }
                  if (a.percentage > b.percentage) { return 1 }
                  return 0
                },
                sortOrder: 'descend',
                render: (x: any) => <span className="value">{Formatters.percentage(x.percentage)}</span>
              },
              {
                title: '',
                key: 'diff',
                width: 80,
                className: 'text-right',
                render: (x: any) => {
                  const previousValue = this.state.previousDevices.find((c: any) => c['key'] === x['key'])
                  return <span className="size-12">{Formatters.growthRate(x.percentage, previousValue ? previousValue.percentage : 0)}</span>
                }
              }
            ]}
          />
        </Col>

        <Col span={12}>
          {this.state.loading && <div className="padding-a-l text-center"><Spin size="large" /></div>}
          {!this.state.loading && <div className="">
            <Chart data={chartData} autoFit height={180} padding={[20, 30, 20, 30]}>
              <Coordinate type="theta" innerRadius={0.7} />
              <Tooltip showTitle={false} />
              <Legend visible={false} />
              <Interval
                adjust="stack"
                position="value"
                color="name"
                tooltip={['name*value', (name, value) => {
                  return {
                    name: name,
                    value: value.toFixed(2) + '%'
                  }
                }]}
              >
                <Label content="name" />
              </Interval>
            </Chart>
          </div>}
        </Col>
      </Row>
    </div>
  }
}

export default UsersPerDevice