import React, { Component } from 'react'
import { Badge, Spin, Tooltip, Row, Col, Drawer, Table, Tag } from 'antd';
import t from '../../utils/translate'
import Formatters from '../../utils/formatters'
import Moment from 'moment-timezone'
import QS from 'qs'
import _ from 'lodash'

class ShowPaymentDrawer extends Component<any, any> {
    _isMounted = false;

    constructor(props: any) {
        super(props)

        // console.log('props', props);
        this.state = {
            paymentLoading: true,
            payment: this.props.payment || {},
            partnership: this.props.partnership || {},
            commissions: [],
            commissionsCount: 0,
            commissionsLoading: false,
            limit: 10,
            skip: 0
        }

        this.loadPayment = this.loadPayment.bind(this)
        this.fetchPayment = this.fetchPayment.bind(this)
        this.fetchCommissions = this.fetchCommissions.bind(this)
        this.handleTableChange = this.handleTableChange.bind(this)
        this.refresh = this.refresh.bind(this)
        this.onClose = this.onClose.bind(this)
        this.updateParams = this.updateParams.bind(this)
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadPayment()
    }

    componentDidUpdate(prevProps: any) {

        // change user
        if (prevProps.paymentId !== this.props.paymentId) {
            this.loadPayment()
            return
        }

        // check url params have changed (refresh...)
        if (prevProps.location.search !== this.props.location.search) {
            this.loadPayment()
            // this.fetchTimelineGraph()
        }
    }

    onClose() {
        this.updateParams([{ k: 'showPayment', v: undefined }])
    }

    loadPayment() {
        this.fetchPayment()
        this.fetchCommissions()
        // this.fetchTimelineGraph()

        if (window.cmAgent) {
            window.cmAgent.pageview({
                title: 'Show partnership payment ' + this.props.paymentId + ' (project: ' + this.props.projectLayout.project.id + ')',
                page: this.props.location.pathname,
                props: {
                    orgId: this.props.projectLayout.project.organizationId,
                    projectId: this.props.projectLayout.project.id
                }
            })
            window.cmAgent.dispatch()
        }
    }

    fetchCommissions() {

        this.setState({ commissionsLoading: true })

        let query = {
            projectId: this.props.projectLayout.project.id,
            limit: this.state.limit,
            skip: this.state.skip,
            sortKey: 'createdAt',
            sortOrder: 'descend',
            paymentId: this.props.paymentId,
        }

        this.props.app.ajaxRequest({
            method: 'get',
            url: '/partnerships.commissions',
            params: query
        }, (errorMessage: any, response: any) => {

            if (errorMessage) {
                this.props.app.addMessage('error', errorMessage)
                // this.setState({commissionsLoading: false})
                return
            }

            console.log('res', response.data);

            if (this._isMounted) {
                this.setState({
                    commissions: response.data.commissions,
                    commissionsCount: response.data.commissionsCount,
                    commissionsLoading: false
                })
            }
        })
    }

    updateParams(updatedParams: any) {
        let params = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })
        updatedParams.forEach((up: any) => {
            params[up.k] = up.v
        })
        this.props.history.push(window.location.pathname + '?' + QS.stringify(params))
    }

    refresh() {
        this.fetchPayment()
        this.fetchCommissions()
    }

    fetchPayment() {
        this.setState({ paymentLoading: true })

        let query = {
            projectId: this.props.projectLayout.project.id,
            id: this.props.paymentId
        }

        this.props.app.ajaxRequest({
            method: 'get',
            url: '/partnershipPayments.show',
            params: query
        }, (errorMessage: any, response: any) => {

            if (errorMessage) {
                this.props.app.addMessage('error', errorMessage)
                return
            }

            // console.log('res', response.data);

            if (this._isMounted) {
                this.setState({
                    payment: response.data.partnershipPayment,
                    partnership: response.data.partnership,
                    paymentLoading: false
                })
            }
        })
    }

    handleTableChange(pagination: any, filters: any, sorter: any) {
        // console.log('filters', filters);
        // console.log('pagination', pagination);
        // console.log('sorter', sorter);

        this.setState({skip: (pagination.current * pagination.pageSize) - pagination.pageSize})
        setTimeout(() => {
            this.fetchCommissions()
        })
    }

    render() {

        const payment = this.state.payment
        const partnership = this.state.partnership
        const conversionRulesMap = _.keyBy(this.props.projectLayout.project.conversionRules, 'id')

        return <Drawer
            title={this.props.paymentId}
            visible={true}
            onClose={this.onClose}
            className="drawer drawer-form"
            width="95%"
        >
            {this.state.paymentLoading && <div className="text-center margin-a-l"><Spin size="large" /></div>}
            {!this.state.paymentLoading && <Row gutter={36} className="margin-a-l">
                <Col span={8}>
                    <div className="subtitle margin-b-m">{t('payment_request', 'Payment request')}</div>

                    <table className="table-attributes">
                        <tbody>
                            <tr>
                                <td>
                                    <span className="subsider-attribute-key" style={{ width: '80px' }}>{t('date', "Date")}</span>
                                    <span className="subsider-attribute-value">{Moment(payment.createdAt).format('llll')}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="subsider-attribute-key" style={{ width: '80px' }}>{t('status', "Status")}</span>
                                    <span className="subsider-attribute-value">
                                        {payment.status === 'paid' && <Badge color="green" text={t('paid', "Paid")} />}
                                        {payment.status === 'pending' && <Badge color="orange" text={t('pending', "Pending")} />}
                                        {payment.status === 'init' && <Badge color="default" text={t('initializing', "Initializing")} />}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="subsider-attribute-key" style={{ width: '80px' }}>CPC</span>
                                    <span className="subsider-attribute-value">{Formatters.currency(payment.currency, payment.commissionsCPC || 0)}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="subsider-attribute-key" style={{ width: '80px' }}>CPA / CPL</span>
                                    <span className="subsider-attribute-value">{Formatters.currency(payment.currency, payment.commissionsConversions || 0)}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="subsider-attribute-key" style={{ width: '80px' }}><span className="semibold">Total</span></span>
                                    <span className="subsider-attribute-value"><span className="color-green semibold">{Formatters.currency(payment.currency, payment.commissionsAmount || 0)}</span></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Col>
                <Col span={8}>
                    <div className="subtitle margin-b-m">{t('media_partner_details', 'Media Partner details')}</div>
                    <table className="table-attributes">
                        <tbody>
                            <tr>
                                <td>
                                    <span className="subsider-attribute-key" style={{ width: '80px' }}>{t('organization', "Organization")}</span>
                                    <span className="subsider-attribute-value">{partnership.mediaPartnerOrganization?.name || t('not_connected', 'Not connected')}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="subsider-attribute-key" style={{ width: '80px' }}>{t('address', "Address")}</span>
                                    <span className="subsider-attribute-value">
                                        {partnership.mediaPartnerOrganization?.addressLine1 || '-'}
                                        {partnership.mediaPartnerOrganization?.addressLine2 && <div>{partnership.mediaPartnerOrganization?.addressLine2}</div>}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="subsider-attribute-key" style={{ width: '80px' }}>{t('city', "City")}</span>
                                    <span className="subsider-attribute-value">{partnership.mediaPartnerOrganization?.city || '-'}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="subsider-attribute-key" style={{ width: '80px' }}>{t('state', "State")}</span>
                                    <span className="subsider-attribute-value">{partnership.mediaPartnerOrganization?.state || '-'}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="subsider-attribute-key" style={{ width: '80px' }}>{t('postal_code', "Postal code")}</span>
                                    <span className="subsider-attribute-value">{partnership.mediaPartnerOrganization?.postalCode || '-'}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="subsider-attribute-key" style={{ width: '80px' }}>{t('country', "Country")}</span>
                                    <span className="subsider-attribute-value">{partnership.mediaPartnerOrganization?.country || '-'}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="subsider-attribute-key" style={{ width: '80px' }}>{t('telephone', "Telephone")}</span>
                                    <span className="subsider-attribute-value">{partnership.mediaPartnerOrganization?.telephone || '-'}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="subsider-attribute-key" style={{ width: '80px' }}>{t('vat', "VAT")}</span>
                                    <span className="subsider-attribute-value">{partnership.mediaPartnerOrganization?.vat || '-'}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Col>
                <Col span={8}>
                    <div className="subtitle margin-b-m">{t('advertiser_details', 'Advertiser details')}</div>
                    <table className="table-attributes">
                        <tbody>
                            <tr>
                                <td>
                                    <span className="subsider-attribute-key" style={{ width: '80px' }}>{t('organization', "Organization")}</span>
                                    <span className="subsider-attribute-value">{this.props.organizationLayout.organization.name || t('not_connected', 'Not connected')}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="subsider-attribute-key" style={{ width: '80px' }}>{t('address', "Address")}</span>
                                    <span className="subsider-attribute-value">
                                        {this.props.organizationLayout.organization.addressLine1 || '-'}
                                        {this.props.organizationLayout.organization.addressLine2 && <div>{this.props.organizationLayout.organization.addressLine2}</div>}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="subsider-attribute-key" style={{ width: '80px' }}>{t('city', "City")}</span>
                                    <span className="subsider-attribute-value">{this.props.organizationLayout.organization.city || '-'}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="subsider-attribute-key" style={{ width: '80px' }}>{t('state', "State")}</span>
                                    <span className="subsider-attribute-value">{this.props.organizationLayout.organization.state || '-'}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="subsider-attribute-key" style={{ width: '80px' }}>{t('postal_code', "Postal code")}</span>
                                    <span className="subsider-attribute-value">{this.props.organizationLayout.organization.postalCode || '-'}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="subsider-attribute-key" style={{ width: '80px' }}>{t('country', "Country")}</span>
                                    <span className="subsider-attribute-value">{this.props.organizationLayout.organization.country || '-'}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="subsider-attribute-key" style={{ width: '80px' }}>{t('telephone', "Telephone")}</span>
                                    <span className="subsider-attribute-value">{this.props.organizationLayout.organization.telephone || '-'}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="subsider-attribute-key" style={{ width: '80px' }}>{t('vat', "VAT")}</span>
                                    <span className="subsider-attribute-value">{this.props.organizationLayout.organization.vat || '-'}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Col>
            </Row>}

            <Table
                dataSource={this.state.commissions}
                rowKey="id"
                className="margin-a-m"
                onChange={this.handleTableChange}
                loading={this.state.commissionsLoading}
                pagination={{
                    pageSize: this.state.limit,
                    total: this.state.commissionsCount,
                    showSizeChanger: false
                }}
                size="small"
                columns={[
                    {
                        title: 'Session ID',
                        key: 'sessionId',
                        render: (record: any) => {
                            if (record.id.length > 15) {
                                return <Tooltip title={record.id}><span className="size-12">{_.truncate(record.id, { length: 16, omission: '...' })}</span></Tooltip>
                            }
                            return <span className="size-12">{record.id}</span>
                        }
                    },
                    {
                        title: t('channel', "Channel"),
                        key: 'channelId',
                        render: (record: any) => {
                            const channel = this.props.projectLayout.project.channels.find((x: any) => x.id === record.commissionChannelId)

                            if (!channel) return '-'
                            const channelGroup = this.props.projectLayout.project.channelGroups.find((x: any) => x.id === channel.groupId)

                            return <span>{channel.name} <Tag className="small" color={channelGroup.color}>{channelGroup.name}</Tag></span>
                        }
                    },
                    {
                        title: 'Conversion',
                        key: 'externalId',
                        render: (record: any) => {
                            if (!record.conversionExternalId) return ''
                            return <span>
                                <span className="link" onClick={this.props.projectLayout.viewOrder.bind(null, record.conversionExternalId, record.conversionRuleId)}>{record.conversionExternalId.length > 15 ?
                                    <Tooltip title={record.conversionExternalId}>{_.truncate(record.conversionExternalId, { length: 16, omission: '...' })}</Tooltip>
                                    : record.conversionExternalId
                                }
                                </span>
                                {record.conversionRevenue && <small className="margin-l-s">{Formatters.currency(this.props.projectLayout.project.currency, record.conversionRevenue)}</small>}
                            </span>
                        }
                    },
                    {
                        title: t('conversion_rule', "Conversion rule"),
                        key: 'conversionRule',
                        render: (record: any) => {
                            // console.log('record', record);
                            if (record.conversionRuleId) return conversionRulesMap[record.conversionRuleId].name
                            return ''
                        }
                    },
                    {
                        title: 'CPC',
                        key: 'cpc',
                        render: (record: any) => <span className="color-green">{Formatters.currency(this.props.projectLayout.project.currency, record.commissionCpc)}</span>
                    },
                    {
                        title: 'CPA / CPL',
                        key: 'cpa',
                        render: (record: any) => <span className="color-green">{Formatters.currency(this.props.projectLayout.project.currency, record.commissionAmount)}</span>
                    },
                    {
                        title: 'Date',
                        key: 'createdAt',
                        render: (record: any) => <small>{Moment(record.createdAt).tz('UTC').format('lll')}</small>
                    },
                ]}
            />
        </Drawer>
    }
}

export default ShowPaymentDrawer