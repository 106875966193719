import React from 'react'

import { Popover, Table } from 'antd';
import Formatters from '../../../utils/formatters'
import Numeral from 'numeral'

const role = (props: any) => {
  const data = [
    {
      key: 'alone',
      title: 'Alone',
      width: props.record.current[props.table+'.aloneRatio'] + 'px',
      color: '#607D8B',
      contributions: Numeral(props.record.current[props.table+'.aloneCount']).format('0,0'),
      contributionsRatio: props.record.current[props.table+'.aloneRatio'],
      previousContributionsRatio: props.record.previous[props.table+'.aloneRatio'],
      linearConversions: Numeral(props.record.current[props.table+'.aloneAttributionLinearConversions']).format('0,0'),
      linearRevenue: Formatters.currency(props.currency, props.record.current[props.table+'.aloneAttributionLinearRevenue'], undefined, undefined, undefined, undefined, true),
    },
    {
      key: 'initiator',
      title: 'Initiator',
      width: props.record.current[props.table+'.initiatorRatio'] + 'px',
      color: '#00BCD4',
      contributions: Numeral(props.record.current[props.table+'.initiatorCount']).format('0,0'),
      contributionsRatio: props.record.current[props.table+'.initiatorRatio'],
      previousContributionsRatio: props.record.previous[props.table+'.initiatorRatio'],
      linearConversions: Numeral(props.record.current[props.table+'.initiatorAttributionLinearConversions']).format('0,0'),
      linearRevenue: Formatters.currency(props.currency, props.record.current[props.table+'.initiatorAttributionLinearRevenue'], undefined, undefined, undefined, undefined, true),
    },
    {
      key: 'assisting',
      title: 'Assisting',
      width: props.record.current[props.table+'.assistingRatio'] + 'px',
      color: '#CDDC39',
      contributions: Numeral(props.record.current[props.table+'.assistingCount']).format('0,0'),
      contributionsRatio: props.record.current[props.table+'.assistingRatio'],
      previousContributionsRatio: props.record.previous[props.table+'.assistingRatio'],
      linearConversions: Numeral(props.record.current[props.table+'.assistingAttributionLinearConversions']).format('0,0'),
      linearRevenue: Formatters.currency(props.currency, props.record.current[props.table+'.assistingAttributionLinearRevenue'], undefined, undefined, undefined, undefined, true),
    },
    {
      key: 'closer',
      title: 'Closer',
      width: props.record.current[props.table+'.closerRatio'] + 'px',
      color: '#F06292',
      contributions: Numeral(props.record.current[props.table+'.closerCount']).format('0,0'),
      contributionsRatio: props.record.current[props.table+'.closerRatio'],
      previousContributionsRatio: props.record.previous[props.table+'.closerRatio'],
      linearConversions: Numeral(props.record.current[props.table+'.closerAttributionLinearConversions']).format('0,0'),
      linearRevenue: Formatters.currency(props.currency, props.record.current[props.table+'.closerAttributionLinearRevenue'], undefined, undefined, undefined, undefined, true),
    },
  ]

  // console.log('role', props)
  const newContent = <Table rowKey="key" dataSource={data} size="small" pagination={false} columns={[
    {
      key: 'title',
      title: '',
      render: (record: any) => record.title
    },
    {
      key: 'bar',
      title: '',
      render: (record: any) => <div>
        <span style={{ width: record.width, display: 'inline-block', backgroundColor: record.color, height: '5px' }}></span>
          <div className="size-10">{Formatters.growthRate(record.contributionsradio, props.previousContributionsRatio)}</div>
      </div>
    },
    {
      key: 'contributionRatio',
      title: '',
      render: (record: any) => Numeral(record.contributionsRatio).format('0.[0]')+'%'
    },
    {
      key: 'contributions',
      title: 'Contributions',
      render: (record: any) => record.contributions
    },
    {
      key: 'linearConversions',
      title: 'Linear conversions',
      render: (record: any) => record.linearConversions
    },
    {
      key: 'linearRevenue',
      title: 'Linear revenue',
      render: (record: any) => record.linearRevenue
    },
  ]} />

  // const content = <table style={{ width: '320px' }}>
  //   <tbody>
  //     <tr>
  //       <td className="padding-b-s">Alone</td>
  //       <td className="padding-b-s">
  //         <span style={{ width: props.record.current[props.table+'.aloneRatio'] + 'px', display: 'inline-block', backgroundColor: '#607D8B', height: '5px' }}></span>
  //         <div className="size-10">{Formatters.growthRate(props.record.current[props.table+'.aloneRatio'], props.record.previous[props.table+'.aloneRatio'])}</div>
  //       </td>
  //       <td className="padding-b-s">{Numeral(props.record.current[props.table+'.aloneCount']).format('0,0')} contrib.</td>
  //       <td className="padding-b-s">{Numeral(props.record.current[props.table+'.aloneRatio']).format('0.[0]')}%</td>
  //     </tr>
  //     <tr>
  //       <td className="padding-b-s">Initiator</td>
  //       <td className="padding-b-s">
  //         <span style={{ width: props.record.current[props.table+'.initiatorRatio'] + 'px', display: 'inline-block', backgroundColor: '#00BCD4', height: '5px' }}></span>
  //         <div className="size-10">{Formatters.growthRate(props.record.current[props.table+'.initiatorRatio'], props.record.previous[props.table+'.initiatorRatio'])}</div>
  //       </td>
  //       <td className="padding-b-s">{Numeral(props.record.current[props.table+'.initiatorCount']).format('0,0')} contrib.</td>
  //       <td className="padding-b-s">{Numeral(props.record.current[props.table+'.initiatorRatio']).format('0.[0]')}%</td>
  //     </tr>
  //     <tr>
  //       <td className="padding-b-s">Assisting</td>
  //       <td className="padding-b-s">
  //         <span style={{ width: props.record.current[props.table+'.assistingRatio'] + 'px', display: 'inline-block', backgroundColor: '#CDDC39', height: '5px' }}></span>
  //         <div className="size-10">{Formatters.growthRate(props.record.current[props.table+'.assistingRatio'], props.record.previous[props.table+'.assistingRatio'])}</div>
  //       </td>
  //       <td className="padding-b-s">{Numeral(props.record.current[props.table+'.assistingCount']).format('0,0')} contrib.</td>
  //       <td className="padding-b-s">{Numeral(props.record.current[props.table+'.assistingRatio']).format('0.[0]')}%</td>
  //     </tr>
  //     <tr>
  //       <td className="padding-b-s">Closer</td>
  //       <td className="padding-b-s">
  //         <span style={{ width: props.record.current[props.table+'.closerRatio'] + 'px', display: 'inline-block', backgroundColor: '#F06292', height: '5px' }}></span>
  //         <div className="size-10">{Formatters.growthRate(props.record.current[props.table+'.closerRatio'], props.record.previous[props.table+'.closerRatio'])}</div>
  //       </td>
  //       <td className="padding-b-s">{Numeral(props.record.current[props.table+'.closerCount']).format('0,0')} contrib.</td>
  //       <td className="padding-b-s">{Numeral(props.record.current[props.table+'.closerRatio']).format('0.[0]')}%</td>
  //     </tr>
  //   </tbody>
  // </table>

  return <div>
    <Popover content={newContent} title={null} trigger={['hover', 'onClick']} placement="left" className="padding-v-m">
      <div style={{ cursor: 'help', width: '65px' }}>
        <div style={{ marginBottom: '2px', width: (props.record.current[props.table+'.aloneRatio'] || 0) + '%', backgroundColor: '#607D8B', height: '3px' }}></div>
        <div style={{ marginBottom: '2px', width: (props.record.current[props.table+'.initiatorRatio'] || 0) + '%', backgroundColor: '#00BCD4', height: '3px' }}></div>
        <div style={{ marginBottom: '2px', width: (props.record.current[props.table+'.assistingRatio'] || 0) + '%', backgroundColor: '#CDDC39', height: '3px' }}></div>
        <div style={{ marginBottom: '2px', width: (props.record.current[props.table+'.closerRatio'] || 0) + '%', backgroundColor: '#F06292', height: '3px' }}></div>
      </div>
    </Popover>
  </div>
}

export default role