import React, { useState } from 'react'

import t from '../../utils/translate'
import { Button, Spin, Input, Modal, Form, Select, Tag } from 'antd';
import _ from 'lodash'

const ChannelGroupButton = (props: any) => {

    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();

    const toggleModal = () => {
        setVisible(!visible)
    }

    const onSubmit = () => {

        form.validateFields().then((values: any) => {
            form.resetFields()
            setLoading(true)

            if (props.channelGroup) {
                values.channelGroupId = props.channelGroup.id
            }

            values.projectId = props.project.id

            props.app.ajaxRequest({
                method: 'post',
                url: props.channelGroup ? '/channelGroups.update' : '/channelGroups.create',
                data: values
            }, (errorMessage: any, response: any) => {

                if (errorMessage) {
                    props.app.addMessage('error', errorMessage)
                    setLoading(false)
                    return
                }

                if (props.channelGroup) {
                    props.app.addMessage('success', t('channel_group_updated', 'The channel group has successfully been updated.'))
                } else {
                    props.app.addMessage('success', t('channel_group_created', 'The channel group has successfully been created.'))
                    form.resetFields()
                }
                setLoading(false)
                setVisible(false)

                let updatedProjects = _.filter(props.organizationLayout.projects, (p: any) => p.id !== props.project.id)
                updatedProjects.push(response.data.project)
                props.organizationLayout.setProjects(updatedProjects)

                if (props.onComplete) {
                    props.onComplete()
                }
            })
        }).catch((e: any) => {
            console.error(e)
        })
    }

    const initialValues = Object.assign({ color: 'magenta' }, props.channelGroup)

    return <span className="margin-r-m">
        <Button type={props.btnType || 'primary'} size={props.btnSize || 'small'} onClick={toggleModal}>{props.btnContent}</Button>

        {visible &&
            <Modal
                title={props.channelGroup ? t('update_channel_group', "Update channel group") : t('create_new_channel_group', "Create a new channel group")}
                wrapClassName="vertical-center-modal"
                visible={true}
                onCancel={toggleModal}
                footer={[
                    <Button key="back" type="ghost" loading={loading} onClick={toggleModal}>{t('cancel', "Cancel")}</Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={onSubmit}>{t('confirm', "Confirm")}</Button>,
                ]}>

                <Spin tip="Loading..." spinning={loading}>
                    <Form form={form} initialValues={initialValues} layout="vertical">
                        <Form.Item name="name" label={t('name', "Name")} rules={[{ required: true, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
                            <Input disabled={(props.channelGroup && props.channelGroup.isDefault) ? true : false} addonAfter={<Form.Item noStyle name="color"><Select style={{ width: 120 }}>
                                <Select.Option value="magenta"><Tag color="magenta">magenta</Tag></Select.Option>
                                <Select.Option value="red"><Tag color="red">red</Tag></Select.Option>
                                <Select.Option value="volcano"><Tag color="volcano">volcano</Tag></Select.Option>
                                <Select.Option value="orange"><Tag color="orange">orange</Tag></Select.Option>
                                <Select.Option value="gold"><Tag color="gold">gold</Tag></Select.Option>
                                <Select.Option value="lime"><Tag color="lime">lime</Tag></Select.Option>
                                <Select.Option value="green"><Tag color="green">green</Tag></Select.Option>
                                <Select.Option value="cyan"><Tag color="cyan">cyan</Tag></Select.Option>
                                <Select.Option value="blue"><Tag color="blue">blue</Tag></Select.Option>
                                <Select.Option value="geekblue"><Tag color="geekblue">geekblue</Tag></Select.Option>
                                <Select.Option value="purple"><Tag color="purple">purple</Tag></Select.Option>
                                <Select.Option value="grey"><Tag>grey</Tag></Select.Option>
                            </Select>
                            </Form.Item>} />
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        }
    </span>
}

export default ChannelGroupButton




// import React, { useState } from 'react'
// import {
//     Table,
//     Alert,
//     Drawer,
//     Form,
//     Input,
//     Button,
//     InputNumber,
//     Select,
//     Radio,
//     Divider,
//     Row,
//     Tag,
//     Modal,
//     Col,
//     Switch,
//     Spin,
//     // Slider,
// } from 'antd';
// import { DeleteOutlined, PlusOutlined, SearchOutlined, CloseOutlined, TagFilled } from '@ant-design/icons';
// import t from '../../utils/translate'
// import _ from 'lodash'

// const TheForm = ({ btnType, btnSize, btnContent, project, app, organizationLayout, channelGroup, onComplete }: any) => {
//     const [form] = Form.useForm()
//     const [drawerVisible, setDrawerVisible] = useState(false)
//     const [loading, setLoading] = useState(false)

//     const closeDrawer = () => {
//         setDrawerVisible(false)
//     }

//     const onFinish = (values: any) => {
//         // console.log('values', values);

//         if (loading) return

//         setLoading(true)

//         if (channelGroup) {
//             values.channelGroupId = channelGroup.id
//         }

//         values.projectId = project.id

//         app.ajaxRequest({
//             method: 'post',
//             url: channelGroup ? '/channelGroups.update' : '/channelGroups.create',
//             data: values
//         }, (errorMessage: any, response: any) => {

//             if (errorMessage) {
//                 app.addMessage('error', errorMessage)
//                 setLoading(false)
//                 return
//             }

//             if (channelGroup) {
//                 app.addMessage('success', t('channel_group_updated', 'The channel group has successfully been updated.'))
//             } else {
//                 app.addMessage('success', t('channel_group_created', 'The channel group has successfully been created.'))
//                 form.resetFields()
//             }

//             setLoading(false)

//             if (window.cmAgent) {
//                 // window.cmAgent.event({label: commissionGroup ? 'update_domain' : 'add_domain'})
//                 // window.cmAgent.dispatch();
//             }
//             setDrawerVisible(false)

//             let updatedProjects = _.filter(organizationLayout.projects, (p: any) => p.id !== project.id)
//             updatedProjects.push(response.data.project)
//             organizationLayout.setProjects(updatedProjects)

//             if (onComplete) {
//                 onComplete()
//             }
//         })
//     }


//     const radioStyle = {
//         display: 'block',
//         height: '30px',
//         lineHeight: '30px',
//     };

//     // console.log('commissionGroup', commissionGroup);

//     const initialValues = Object.assign({ color: 'blue' }, channelGroup)

//     // console.log('initialValues', initialValues);

//     return <React.Fragment>
//         <Button type={btnType || 'primary'} size={btnSize || 'small'} onClick={() => setDrawerVisible(true)}>{btnContent}</Button>

//         {drawerVisible && <Drawer
//             title={channelGroup ? t('update_channel_group', "Update channel group") : t('create_new_channel_group', "Create a new channel group")}
//             width={900}
//             visible={true}
//             onClose={closeDrawer}
//             bodyStyle={{ paddingBottom: 80 }}
//             footer={<div style={{ textAlign: 'right' }}>
//                 <Button loading={loading} onClick={closeDrawer} style={{ marginRight: 8 }}>{t('cancel', "Cancel")}</Button>
//                 <Button loading={loading} onClick={() => {
//                     form.submit()
//                 }} type="primary">{t('confirm', "Confirm")}</Button>
//             </div>}
//         >
//             <Form form={form} initialValues={initialValues} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} name="groupForm" onFinish={onFinish}>

//                 <Form.Item name="name" label={t('name', "Name")} rules={[{ required: true, type: 'string', message: t('field_invalid', "This field is not valid!") }]}>
//                     <Input placeholder="i.e: Big spenders..." addonAfter={<Form.Item noStyle name="color"><Select style={{ width: 120 }}>
//                         <Select.Option value="magenta"><Tag color="magenta">magenta</Tag></Select.Option>
//                         <Select.Option value="red"><Tag color="red">red</Tag></Select.Option>
//                         <Select.Option value="volcano"><Tag color="volcano">volcano</Tag></Select.Option>
//                         <Select.Option value="orange"><Tag color="orange">orange</Tag></Select.Option>
//                         <Select.Option value="gold"><Tag color="gold">gold</Tag></Select.Option>
//                         <Select.Option value="lime"><Tag color="lime">lime</Tag></Select.Option>
//                         <Select.Option value="green"><Tag color="green">green</Tag></Select.Option>
//                         <Select.Option value="cyan"><Tag color="cyan">cyan</Tag></Select.Option>
//                         <Select.Option value="blue"><Tag color="blue">blue</Tag></Select.Option>
//                         <Select.Option value="geekblue"><Tag color="geekblue">geekblue</Tag></Select.Option>
//                         <Select.Option value="purple"><Tag color="purple">purple</Tag></Select.Option>
//                         <Select.Option value="grey"><Tag>grey</Tag></Select.Option>
//                     </Select>
//                     </Form.Item>} />
//                 </Form.Item>
//             </Form>
//         </Drawer>}
//     </React.Fragment>
// }

// export default TheForm