import React from 'react'
import { Space, Row, Col } from 'antd'
import cn from 'classnames'

const InfoRadioGroup = ({ options, onChange, value, span, disabled, layout }: any) => {
    // console.log('value', value)
    const onClick = (key: any) => {
        if (disabled) return
        onChange(key)
    }

    return <div className="info-radio-group">
        <Row gutter={24}>
            {options.map((item: any) => <Col className={layout === 'horizontal' ? 'margin-b-m' : ''} span={layout === 'horizontal' ? 24 : span} key={item.key}>
                <div onClick={onClick.bind(null, item.key)} className={cn('info-radio-item', { checked: value === item.key, disabled: disabled })}>
                    <Space size={24} align="start">
                        <div className="info-radio-item-icon">{item.icon}</div>
                        <div className="info-radio-item-main">
                            <div className="info-radio-item-title">{item.title}</div>
                            <div className="info-radio-item-content">{item.content}</div>
                        </div>
                    </Space>
                </div>
            </Col>)}
        </Row>
    </div>
}

export default InfoRadioGroup
