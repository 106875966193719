import { useState, useEffect, useRef } from 'react';
import { Select, message } from 'antd'
import t from '../../../utils/translate'

const PartnershipSelect = ({ ajaxRequest, projectId, value, onChange }) => {
    const all = useRef({label: t('all_media_partners', 'All Media Partners'), value: '_all'})
    const [partnerships, setPartnerships] = useState([all.current])

    // fetch partnerships
    useEffect(() => {
        // console.log('use effect')
        ajaxRequest({
            method: 'get',
            url: '/partnerships.list',
            params: {
                projectId: projectId,
                limit: 1000,
            }
        }, (errorMessage: any, response: any) => {

            if (errorMessage) {
                message.error(errorMessage)
                // this.setState({loading: false})
                return
            }
            // console.log('res', response.data);
            const options = [all.current]
            
            response.data.partnerships.forEach((p: any) => {
                options.push({
                    label: <span>{p.id}</span>,
                    value: p.id,
                })
            })
            
            setPartnerships(options)
        })
    }, [ajaxRequest, projectId, all]); // empty array to simulate componentDidMount, otherwise it triggers at every props update

    if (partnerships.length === 0) return null

    return <Select
        size="small"
        dropdownMatchSelectWidth={false}
        className="size-12"
        options={partnerships}
        onChange={onChange}
        value={value || '_all'}
        placeholder="Media Partner"
    />
}

export default PartnershipSelect