import React, { Component } from 'react'

import { Button, Input, Badge, Popconfirm, Switch, Select, Tag, Form, Table, Row, Col } from 'antd';
import t from '../../utils/translate'
// import integrations from '../../utils/integrations'
import Cookies from '../../utils/cookies'
import Config from '../../config'
import _ from 'lodash'
import Moment from 'moment-timezone'
import Languages from '../../utils/languages'
import { CloseOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

class IntegrationsShopify extends Component<any, any> {
  _isMounted = false;

  constructor(props: any) {
    super(props)

    this.state = {
      settings: this.props.integration.shopifySettings ? Object.assign({}, this.props.integration.shopifySettings) : {},
      webhooks: {},
      loading: false,
      shopId: '',
      addShopifyTagVisible: false,
      shopifyTag: '',
    }

    if (!this.state.settings.conversionDomainsMapping) {
      this.state.settings.conversionDomainsMapping = []
    }

    this.goToOauth = this.goToOauth.bind(this)
    this.onChangeShopId = this.onChangeShopId.bind(this)
    this.syncCustomers = this.syncCustomers.bind(this)
    this.syncProducts = this.syncProducts.bind(this)
    this.listConnectedWebhooks = this.listConnectedWebhooks.bind(this)
    this.reconnectWebhooks = this.reconnectWebhooks.bind(this)
    this.addShopifyTag = this.addShopifyTag.bind(this)
    this.removeShopifyTag = this.removeShopifyTag.bind(this)
  }

  componentDidMount() {
    this._isMounted = true;

    if (this.props.integration.status === 'connected') {
      this.listConnectedWebhooks()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  listConnectedWebhooks() {
    this.setState({loading: true})

    this.props.app.ajaxRequest({
      method: 'get',
      url: '/integrations.listShopifyWebhooks',
      params: {
        projectId: this.props.projectLayout.project.id,
        integrationId: this.props.integration.id
      }
    }, (errorMessage: any, response: any) => {

      if (!this._isMounted) return

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({loading: false})
        return
      }

      const webhooks: any = {}

      _.forEach(response.data.webhooks, (hook) => {
        webhooks[hook.topic] = true
      })

      // console.log('response.data', response.data);
      this.setState({
        webhooks: webhooks,
        loading: false
      })
    })
  }

  reconnectWebhooks() {
    this.setState({loading: true})

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/integrations.reconnectShopifyWebhooks',
      data: {
        projectId: this.props.projectLayout.project.id,
        integrationId: this.props.integration.id
      }
    }, (errorMessage: any, response: any) => {

      if (!this._isMounted) return

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({loading: false})
        return
      }

      let projects = _.filter(this.props.organizationLayout.projects, (p: any) => p.id !== response.data.project.id)
      projects.push(response.data.project)
      this.props.organizationLayout.setProjects(projects)

      this.props.app.addMessage('success', t('reconnect_webhooks_success', "Webhooks have been successfully reconnected!"))
      this.setState({loading: false})
      this.listConnectedWebhooks()
    })
  }

  goToOauth() {
    const scopes = [
      'read_themes',
      'write_themes',
      'read_products',
      'write_products',
      'read_product_listings',
      'read_customers',
      'read_orders',
      'read_all_orders',
      'read_inventory',
      'read_locations',
      'read_script_tags',
      'write_script_tags',
      'read_shipping',
      'read_analytics',
      'read_checkouts',
      'read_marketing_events',
      'write_marketing_events',
      'read_resource_feedbacks',
      'write_resource_feedbacks',
      'read_shopify_payments_payouts',
    ]

    const state = {
      ts: new Date().getTime(),
      oid: this.props.projectLayout.project.organizationId,
      pid: this.props.projectLayout.project.id,
      iid: this.props.integration.id
    }

    Cookies.setItem('shopify_oauth_state', JSON.stringify(state), 600, '/', null, true)

    let redirect_uri = 'https://console.captainmetrics.com/shopify_redirect_uri'

    if (Config().env === 'test') {
      redirect_uri = 'https://console.test.captainmetrics.com/shopify_redirect_uri'
    }

    if (Config().env === 'development') {
      Cookies.setItem('shopify_oauth_state', JSON.stringify(state), 600, '/', null, false)
      redirect_uri = 'https://localconsole.captainmetrics.com:4433/shopify_redirect_uri'
    }

    window.location.href = 'https://'+this.state.shopId+'.myshopify.com/admin/oauth/authorize?client_id='+Config().shopify_key+'&scope='+scopes.join(',')+'&redirect_uri='+encodeURIComponent(redirect_uri)+'&state='+state.ts+'&grant_options[]='
  }

  onChangeShopId(e: any) {
    this.setState({shopId: e.target.value})
  }

  syncCustomers() {
    if (this.state.loading) {
      return
    }

    this.setState({loading: true})

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/integrations.syncShopifyCustomers',
      data: {
        projectId: this.props.projectLayout.project.id,
        integrationId: this.props.integration.id,
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({loading: false})
        return
      }
      this.props.app.addMessage('success', t('task_launched', "Task launched!"))

      this.setState({loading: false})

      this.props.history.push('/organizations/'+this.props.organizationLayout.organization.id+'/projects/'+this.props.projectLayout.project.id+'/tasks')
    })
  }

  syncProducts() {
    if (this.state.loading) {
      return
    }

    this.setState({loading: true})

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/integrations.syncShopifyProducts',
      data: {
        projectId: this.props.projectLayout.project.id,
        integrationId: this.props.integration.id,
      }
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({loading: false})
        return
      }
      this.props.app.addMessage('success', t('task_launched', "Task launched!"))

      this.setState({loading: false})

      this.props.history.push('/organizations/'+this.props.organizationLayout.organization.id+'/projects/'+this.props.projectLayout.project.id+'/tasks')
    })
  }


  addShopifyTag() {
    const settings = Object.assign({}, this.state.settings)

    settings.conversionDomainsMapping.push({
      tag: this.state.shopifyTag,
      domainId: this.state.domain,
      priority: this.state.domainPriority
    })

    this.setState({
      settings: settings,
      // reset fields
      addShopifyTagVisible: false,
      shopifyTag: '',
      domain: '',
      domainPriority: 1,
    })
    this.props.updateSettings(this.props.integration, settings)
  }

  removeShopifyTag(tag: any) {
    const newMapping = this.state.settings.conversionDomainsMapping.filter((x: any) => x.tag !== tag);
    const settings = _.cloneDeep(this.state.settings)
    settings.conversionDomainsMapping = newMapping

    this.setState({settings: settings})
    this.props.updateSettings(this.props.integration, settings)
  }

  formRef: any = React.createRef()

  render() {
    // const info = integrations.find((int) => int.id === this.props.integration)

    const timezones = Moment.tz.names()
    const mapping = [...this.state.settings.conversionDomainsMapping]

    return <div>
      <h2 className="margin-t-m">{t('settings', "Settings")}</h2>
      {(this.props.integration.status === 'setup') || (this.props.integration.status === 'disconnected') ? 
        <div style={{width: '500px', margin: '32px auto'}}>
          <div className="paper padding-a-l text-center">
            <Form ref={this.formRef} layout="vertical" onFinish={this.goToOauth}>
              <Form.Item name="shopId" label={t('enter_your_shop_id', "Enter your Shopify shop ID")+':'}>
                <Input name="shopify_shop_id" required value={this.state.shopId} onChange={this.onChangeShopId} addonBefore="https://" addonAfter=".myshopify.com" placeholder="shop_id" />
              </Form.Item>
            </Form>

            <Button size="large" type="primary" disabled={this.state.shopId.length === 0} loading={this.state.loading} onClick={this.goToOauth}>{t('connect_my_shopify', "Connect my Shopify")}</Button>
          </div>
        </div>
        :
        <div className="margin-a-m">
          <table className="key-value-table">
            <tbody>
              <tr>
                <th style={{width: '400px'}}>{t('integration_id', "Integration ID")}</th>
                <td>{this.props.integration.id}</td>
              </tr>
              <tr>
                <th>{t('status', "Status")}</th>
                <td>{this.props.integration.status === 'connected' && <span>
                  <Badge status="success" /> {t('connected', "Connected")}
                  <Popconfirm placement="top" title={t('disconnect_shopify_confirm', "Do you really want to disconnect Shopify? your data won't be synced anymore!")} onConfirm={this.props.disconnect.bind(null, this.props.integration)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                    <Button className="margin-l-m" loading={this.state.loading} ghost size="small" danger={true}>{t('disconnect', "Disconnect")}</Button>
                  </Popconfirm>
                </span>}</td>
              </tr>
              <tr>
                <th>{t('shop_id', "Shop ID")}</th>
                <td>{this.props.integration.shopifySettings.shopId}</td>
              </tr>
              <tr>
                <th>{t('is_shopify_users_source', "Is Shopify your main source of users?")}</th>
                <td>
                  <Popconfirm placement="top" title={t('toggle_main_source', "Do you really want to change the status of this source of users?")} onConfirm={this.props.toggleMainSource.bind(null, this.props.integration)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                    <Switch checked={this.props.integration.shopifySettings.isMainSource} loading={this.state.loading} />
                  </Popconfirm>
                </td>
              </tr>
              <tr>
                <th>{t('is_source_of_newsletter_consent', "Is it the source of consent to newsletter?")}</th>
                <td>
                  <Popconfirm placement="top" title={t('toggle_consent_source', "Do you really want to change the status of this source of consent?")} onConfirm={this.props.toggleNewsletterSource.bind(null, this.props.integration)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                    <Switch checked={this.props.integration.shopifySettings.isNewsletterConsentSource} loading={this.state.loading} />
                  </Popconfirm>
                </td>
              </tr>
              <tr>
                <th>{t('is_source_of_remarketing_consent', "Is it the source of consent to remarketing?")}</th>
                <td>
                  <Popconfirm placement="top" title={t('toggle_consent_source', "Do you really want to change the status of this source of consent?")} onConfirm={this.props.toggleRemarketingSource.bind(null, this.props.integration)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                    <Switch checked={this.props.integration.shopifySettings.isRemarketingConsentSource} loading={this.state.loading} />
                  </Popconfirm>
                </td>
              </tr>
              <tr>
                <th>{t('geocode_customers_addresses', "Geocode customers addresses?")}</th>
                <td>
                  <Switch checked={this.props.integration.shopifySettings.geocodeAddresses} loading={this.state.loading} onChange={this.props.toggleGeocodeAddresses.bind(null, this.props.integration)} />
                </td>
              </tr>
              <tr>
                <th>{t('geocode_customers_time_zones', "Geocode customers time zones?")}</th>
                <td>
                  <Switch checked={this.props.integration.shopifySettings.geocodeTimezones} loading={this.state.loading} onChange={this.props.toggleGeocodeTimezones.bind(null, this.props.integration)} />
                </td>
              </tr>
              <tr>
                <th>{t('orders_conversion_rule', "Orders conversion rule")}</th>
                <td>
                  <Select 
                    style={{width: '200px'}}
                    placeholder={t('select_a_value', "Select a value")}
                    allowClear={false}
                    showSearch={true}
                    value={this.props.integration.shopifySettings.conversionRuleId}
                    disabled={this.state.loading}
                    onChange={this.props.updateConversionRule.bind(null, this.props.integration)}>
                      {this.props.projectLayout.project.conversionRules.filter((cr: any) => cr.kind === "order").map((cr: any) => {
                        return <Select.Option key={cr.id} value={cr.id}>{cr.name}</Select.Option>
                      })}
                  </Select>
                </td>
              </tr>
              <tr>
                <th>{t('orders_default_conversion_domain', "Orders default conversion domain")}</th>
                <td>
                  <Select 
                    style={{width: '200px'}}
                    placeholder={t('select_a_value', "Select a value")}
                    allowClear={false}
                    value={this.props.integration.shopifySettings.conversionDomainId}
                    disabled={this.state.loading}
                    onChange={this.props.updateConversionDomain.bind(null, this.props.integration)}>
                      {this.props.projectLayout.project.domains.filter((d: any) => !d.deletedAt).map((d: any) => {
                        return <Select.Option key={d.id} value={d.id}>{d.name} - {d.kind}</Select.Option>
                      })}
                  </Select>
                </td>
              </tr>
              <tr>
                <th>{t('orders_conversion_domain_mapping', "Orders conversion domain mapping")}</th>

                <td>
                  {mapping.length > 0 && <Table
                    size="middle"
                    bordered={false}
                    pagination={false}
                    dataSource={mapping}
                    rowKey="tag"
                    className="margin-b-m"
                    style={{width: '300px'}}
                    columns={[
                      {
                        title: 'Shopify tag',
                        key: 'tag',
                        render: (record: any) => record.tag
                      }, 
                      {
                        title: t('conversion_domain', "Conversion domain"),
                        key: 'domain',
                        render: (record: any) => this.props.projectLayout.project.domains.find((x: any) => x.id === record.domainId).name
                      }, 
                      {
                        title: 'Priority',
                        key: 'priority',
                        render: (record: any) => record.priority
                      }, 
                      {
                        title: '',
                        key: 'delete',
                        render: (record: any) => <Popconfirm placement="top" title={t('remove_entry_confirm', "Do you really want to remove this entry?")} onConfirm={this.removeShopifyTag.bind(null, record.tag)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                        <Button size="small" icon={<DeleteOutlined />} />
                      </Popconfirm>
                      }, 
                    ]}
                  />}
                  {!this.state.addShopifyTagVisible && <Button type="primary" ghost onClick={() => this.setState({addShopifyTagVisible: true})}><PlusOutlined /> {t('add', "Add")}</Button>}
                  {this.state.addShopifyTagVisible && <Row className="margin-b-m" style={{width: '750px'}}>
                    <Col span={6}>
                      <Input style={{width: '100%'}} placeholder="Shopify tag" required  value={this.state.shopifyTag} onChange={(e: any) => this.setState({shopifyTag: e.target.value})} />
                    </Col>
                    <Col span={1}></Col>
                    <Col span={6}>
                      <Select 
                        style={{width: '100%'}}
                        placeholder={t('select_a_value', "Select a value")}
                        allowClear={false}
                        value={this.state.domain} 
                        onChange={(value: any) => this.setState({domain: value})}
                      >
                        {this.props.projectLayout.project.domains.filter((d: any) => !d.deletedAt).map((d: any) => {
                          return <Select.Option key={d.id} value={d.id}>{d.name} - {d.kind}</Select.Option>
                        })}
                      </Select>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={5}>
                      <Select 
                        style={{width: '100%'}}
                        placeholder={t('select_a_value', "Select a value")}
                        allowClear={false}
                        value={this.state.domainPriority} 
                        onChange={(value: any) => this.setState({domainPriority: value})}
                      >
                        <Select.Option value={1}>Priority 1</Select.Option>
                        <Select.Option value={2}>Priority 2</Select.Option>
                        <Select.Option value={3}>Priority 3</Select.Option>
                        <Select.Option value={4}>Priority 4</Select.Option>
                        <Select.Option value={5}>Priority 5</Select.Option>
                      </Select>
                    </Col>
                    <Col span={5} className="padding-l-m">
                      <Button type="primary" onClick={this.addShopifyTag} disabled={!this.state.domain}>{t('add', "Add")}</Button>
                      <Button className="margin-l-s" type="dashed" onClick={() => this.setState({addShopifyTagVisible: false})} icon={<CloseOutlined />} />
                    </Col>
                  </Row>}
                </td>
              </tr>
              <tr>
                <th>{t('default_timezone', "Default customers time zone")}</th>
                <td>
                  <Select 
                    style={{width: '200px'}}
                    placeholder={t('select_a_value', "Select a value")}
                    allowClear={false}
                    showSearch={true}
                    value={this.props.integration.shopifySettings.defaultTimezone}
                    disabled={this.state.loading}
                    onChange={this.props.updateDefaultTimezone.bind(null, this.props.integration)}
                    filterOption={(searchText: any, option: any) => {
                      return searchText !== '' && option.key.toLowerCase().includes(searchText.toLowerCase())
                    }}>
                      {timezones.map((timezone) => {
                        return <Select.Option key={timezone} value={timezone}>{timezone}</Select.Option>
                      })}
                  </Select>
                </td>
              </tr>
              <tr>
                <th>{t('default_language', "Default customers language")}</th>
                <td>
                  <Select 
                    style={{width: '200px'}}
                    placeholder={t('select_a_value', "Select a value")}
                    allowClear={false}
                    showSearch={true}
                    value={this.props.integration.shopifySettings.defaultLanguage}
                    disabled={this.state.loading}
                    onChange={this.props.updateDefaultLanguage.bind(null, this.props.integration)}
                    filterOption={(searchText: any, option: any) => {
                      return searchText !== '' && option.key.toLowerCase().includes(searchText.toLowerCase())
                    }}>
                      {Languages.map((language) => {
                        return <Select.Option key={language.alpha2} value={language.alpha2}>{language.alpha2+' - '+language.name}</Select.Option>
                      })}
                  </Select>
                </td>
              </tr>
              {_.size(this.state.webhooks) > 0 && <tr>
                <th>{t('webhooks_connected', "Webhooks connected")}</th>
                <td>
                  <Tag color={this.state.webhooks['app/uninstalled'] ? 'green': 'red'} className="margin-b-s">app/uninstalled</Tag>
                  {/*<Tag color={this.state.webhooks['customers/redact'] ? 'green': 'red'} className="margin-b-s">customers/redact</Tag>*/}
                  <Tag color={this.state.webhooks['customers/create'] ? 'green': 'red'} className="margin-b-s">customers/create</Tag>
                  <Tag color={this.state.webhooks['customers/delete'] ? 'green': 'red'} className="margin-b-s">customers/delete</Tag>
                  <Tag color={this.state.webhooks['customers/update'] ? 'green': 'red'} className="margin-b-s">customers/update</Tag>
                  <Tag color={this.state.webhooks['orders/create'] ? 'green': 'red'} className="margin-b-s">orders/create</Tag>
                  <Tag color={this.state.webhooks['orders/delete'] ? 'green': 'red'} className="margin-b-s">orders/delete</Tag>
                  <Tag color={this.state.webhooks['orders/fulfilled'] ? 'green': 'red'} className="margin-b-s">orders/fulfilled</Tag>
                  <Tag color={this.state.webhooks['orders/paid'] ? 'green': 'red'} className="margin-b-s">orders/paid</Tag>
                  <Tag color={this.state.webhooks['orders/partially_fulfilled'] ? 'green': 'red'} className="margin-b-s">orders/partially_fulfilled</Tag>
                  <Tag color={this.state.webhooks['orders/updated'] ? 'green': 'red'} className="margin-b-s">orders/updated</Tag>
                  <Tag color={this.state.webhooks['orders/cancelled'] ? 'green': 'red'} className="margin-b-s">orders/cancelled</Tag>
                  <Tag color={this.state.webhooks['refunds/create'] ? 'green': 'red'} className="margin-b-s">refunds/create</Tag>
                </td>
              </tr>}
              <tr>
                <th>{t('actions', "Actions")}</th>
                <td>
                  <p>
                    <Popconfirm placement="top" title={t('sync_customers_from_shopify_confirm', "Do you really want to sync all your customers & orders from Shopify?")} onConfirm={this.syncCustomers} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                      <Button loading={this.state.loading} type="primary">{t('sync_customers_and_orders', "Sync customers & orders")}</Button>
                    </Popconfirm>
                  </p>
                  
                  <p>
                    <Popconfirm placement="top" title={t('sync_products_from_shopify_confirm', "Do you really want to sync all your products from Shopify?")} onConfirm={this.syncProducts} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                      <Button loading={this.state.loading} type="primary">{t('sync_products', "Sync products")}</Button>
                    </Popconfirm>
                  </p>

                  {/*<p>
                    <Popconfirm placement="top" title={t('sync_products_from_shopify_confirm', "Do you really want to sync your products from Shopify?")} onConfirm={this.syncProducts} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                      <Button loading={this.state.loading} type="primary">{t('sync_products', "Sync products")}</Button>
                    </Popconfirm>
                  </p>*/}

                  <Button loading={this.state.loading} type="ghost" onClick={this.reconnectWebhooks}>{t('reconnect_webhooks', "Reconnect webhooks")}</Button>

                </td>
              </tr>
            </tbody>
          </table>
        </div>
      }

    </div>
  }
}

export default IntegrationsShopify