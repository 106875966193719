import { useRef, useEffect } from 'react'

import { message, Spin } from 'antd';
import t from '../../utils/translate'
import Cookies from '../../utils/cookies'
import QS from 'qs'
import Splash from '../app/_splash'
import {
  useLocation,
  useHistory,
} from 'react-router-dom'
import { useAppContext, AppContextValue } from '../app'

const IntegrationsShopifyRedirectUri = () => {

  const appCtx: AppContextValue = useAppContext()
  const location = useLocation() as any
  const history = useHistory() as any
  const prevSearch = useRef(undefined)

  useEffect(() => {


    if (prevSearch.current !== location.search) {
      prevSearch.current = location.search
      const params: any = QS.parse(location.search, { ignoreQueryPrefix: true })

      // retrive state from cookie
      let state: any = Cookies.getItem('shopify_oauth_state')

      if (!state) {
        message.error('Invalid OAuth state, please reconnect.')
        return
      }

      state = JSON.parse(state)

      if (params.state !== '' + state.ts) {
        message.error('Invalid OAuth state, please reconnect.')
        return
      }

      appCtx.ajaxRequest({
        method: 'post',
        url: '/integrations.connect',
        data: {
          projectId: state.pid,
          integrationId: state.iid,
          shopify: {
            shopId: params.shop,
            code: params.code,
            host: params.host,
            hmac: params.hmac,
            state: params.state,
            timestamp: params.timestamp
          }
        }
      }, (errorMessage: any, response: any) => {

        if (errorMessage) {
          message.error(errorMessage)
          return
        }

        Cookies.removeItem('shopify_oauth_state', '/', undefined)

        message.success(t('shopify_connect_success', "Shopify has successfully been connected!"))
        history.push('/organizations/' + state.oid + '/projects/' + state.pid + '/integrations?tab=' + state.iid)
      })
    }

  }, [appCtx, history, location])

  return <Splash h1={t('loading', "Loading")}>
    <Spin size="large"></Spin>
  </Splash>
}

export default IntegrationsShopifyRedirectUri