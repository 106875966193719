import React from 'react'
import { 
  // Select,
  Input, 
} from 'antd'
import Countries from '../../utils/countries'
import {parsePhoneNumber, AsYouType} from 'libphonenumber-js'

// const countriesOptions = Countries.filter((x: any) => x.dialCode && x.dialCode !== '').map((x: any) => {
//   return {label: x.emoji+' '+x.dialCode, name: x.dialCode+' '+x.name, value: x.dialCode, key: x.code}
// })

// console.log('countriesOptions', countriesOptions);

const PhoneNumberInput = ({value, onChange}: any) => {

  // let phoneNumber = value
  let code: any = ''
  // let displayNumber = value

  try {
    const phoneNumber = parsePhoneNumber(value)

    // console.log('phoneNumber', phoneNumber);
    if (phoneNumber) code = ''+phoneNumber.countryCallingCode
    // displayNumber = phoneNumber.nationalNumber
  } catch(e: any) {
  }

  const onInput = (e: any) => {
    const newValue = new AsYouType(code).input(e.target.value)
    onChange(newValue)
  }

  // const codeSelect = <Select 
  //   showSearch
  //   value={'+'+code}
  //   dropdownMatchSelectWidth={false}
  //   options={countriesOptions}
  //   onChange={newCod(e: any) => {
  //     onChange(newCode+value)
  //   }}
  //   optionFilterProp="name" 
  // />

  const codeFound = Countries.find((x: any) => x.dialCode === '+'+code)

  return <Input addonBefore={codeFound && codeFound.code} value={value} onChange={onInput} />
}

export default PhoneNumberInput