import { useCallback, useEffect, useState, useRef } from 'react'

import t from '../../utils/translate'

import { message, Table, Button, Badge, Tooltip, Tag } from 'antd'
import Moment from 'moment-timezone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import QS from 'qs'
import { truncate, get, keyBy } from 'lodash'
import ReattributeConversionsButton from './_reattributeConversionsButton'
import RefreshSegmentButton from './_refreshSegmentButton'
import AppLayout from 'components/app/layout'
import { useProjectContext, ProjectContextValue } from 'components/projects'
import { useHistory, useLocation } from 'react-router';
import { Task } from 'components/app/interfaces'

const defaultParams = {
  limit: 30,
  skip: 0,
  // sortKey: 'createdAt',
  // sortOrder: 'desc',
}

const TasksList = () => {

  const [loading, setLoading] = useState(false)
  const [tasks, setTasks] = useState<Task[]>([])
  const [tasksCount, setTasksCount] = useState(0)
  const [showReattribute, setShowReattribute] = useState(false)
  const [showRefreshSegment, setShowRefreshSegment] = useState(false)
  const projectCtx: ProjectContextValue = useProjectContext()
  const history = useHistory() as any
  const location = useLocation() as any
  const prevProjectId = useRef(undefined)
  const prevLocationSearch = useRef(location.search)


  const fetchTasks = useCallback((): Promise<void> => {
    return new Promise((resolve, reject) => {
      const params: any = QS.parse(location.search, { ignoreQueryPrefix: true })

      let query = {
        projectId: projectCtx.currentProject.id,
        status: params.status || undefined,
        limit: params.limit || defaultParams.limit,
        skip: params.skip || defaultParams.skip,
      }

      projectCtx.ajaxRequest({
        method: 'get',
        url: '/tasks.list',
        params: query
      }, (errorMessage: any, response: any) => {

        if (errorMessage) {
          return reject(errorMessage)
        }

        // console.log('res', response.data);

        setTasks(response.data.tasks)
        setTasksCount(response.data.tasksCount)
        return resolve(undefined)
      })
    })
  }, [location.search, projectCtx])


  useEffect(() => {

    if (prevProjectId.current === projectCtx.currentProject.id) return
    prevProjectId.current = projectCtx.currentProject.id

    setLoading(true)
    fetchTasks().catch(e => message.error(e)).finally(() => setLoading(false))

    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Tasks (project: ' + projectCtx.currentProject.id + ')',
        page: location.pathname,
        props: {
          orgId: projectCtx.currentOrganization.id,
          projectId: projectCtx.currentProject.id,
        }
      })
      window.cmAgent.dispatch()
    }
  }, [history, location.pathname, projectCtx.currentProject.id, projectCtx.currentOrganization, projectCtx.projects, fetchTasks])

  useEffect(() => {

    if (prevLocationSearch.current === location.search) return
    prevLocationSearch.current = location.search

    setLoading(true)
    fetchTasks().catch(e => message.error(e)).finally(() => setLoading(false))

  }, [location.search, prevLocationSearch, fetchTasks])


  useEffect(() => {

    // console.log('tasks are', projectCtx.currentProject.runningTasks)
    setLoading(true)
    fetchTasks().catch(e => message.error(e)).finally(() => setLoading(false))


  }, [projectCtx.currentProject.runningTasks, fetchTasks])


  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    // console.log('filters', filters);
    // console.log('pagination', pagination);
    // console.log('sorter', sorter);
    const params: any = QS.parse(location.search, { ignoreQueryPrefix: true })

    const newParams = Object.assign({
      limit: params.limit || defaultParams.limit,
      skip: params.skip || defaultParams.skip,
      // sortKey: params.sortKey || defaultParams.sortKey,
      // sortOrder: params.sortOrder || defaultParams.sortOrder,
      status: params.status,
    }, {
      limit: pagination.pageSize,
      skip: (pagination.current * pagination.pageSize) - pagination.pageSize,
      // sortKey: sorter.columnKey,
      // sortOrder: sorter.order,
      status: get(filters, 'status'),
    })

    updateQuery(newParams)
  }

  const toggleReattribute = () => {
    setShowReattribute(!showReattribute)
  }

  const toggleRefreshSegment = () => {
    setShowRefreshSegment(!showRefreshSegment)
  }

  const updateQuery = (update: any) => {
    const params: any = QS.parse(location.search, { ignoreQueryPrefix: true })

    let query = {
      limit: params.limit || defaultParams.limit,
      skip: params.skip || defaultParams.skip,
      // sortKey: params.sortKey || defaultParams.sortKey,
      // sortOrder: params.sortOrder || defaultParams.sortOrder,
      status: params.status || undefined,
    }

    history.push('/organizations/' + projectCtx.currentOrganization.id + '/projects/' + projectCtx.currentProject.id + '/tasks?' + QS.stringify(Object.assign(query, update), { indices: false }));
  }

  // console.log(props);
  const segmentsMap = keyBy(projectCtx.currentProject.segments, 'id')

  return <AppLayout currentProject={projectCtx.currentProject} currentOrganization={projectCtx.currentOrganization} admin={projectCtx.admin} firebaseUser={projectCtx.firebaseUser} projects={projectCtx.projects}>
    {showReattribute && <ReattributeConversionsButton
      ajaxRequest={projectCtx.ajaxRequest}
      projectId={projectCtx.currentProject.id}
      organizationId={projectCtx.currentOrganization.id}
      toggleModal={toggleReattribute}
      onComplete={() => { toggleReattribute(); }}
    />}
    {showRefreshSegment && <RefreshSegmentButton
      ajaxRequest={projectCtx.ajaxRequest}
      projectId={projectCtx.currentProject.id}
      segments={projectCtx.currentProject.segments}
      organizationId={projectCtx.currentOrganization.id}
      toggleModal={toggleRefreshSegment}
      onComplete={() => { console.log('done'); toggleRefreshSegment(); }}
    />}

    <h1>
      <div className="actions">
        <Button.Group>
          <Button type="ghost" onClick={toggleReattribute}>{t('reattribute_conversions', "Reattribute conversions")}</Button>
          <Button type="ghost" onClick={toggleRefreshSegment}>{t('refresh_segment', "Refresh segment")}</Button>
        </Button.Group>
      </div>
      {t('tasks', "Tasks")}
    </h1>

    <Table
      dataSource={tasks}
      rowKey="id"
      className="edge-table block"
      onChange={handleTableChange}
      loading={loading}
      pagination={{
        pageSize: 10,
        total: tasksCount
      }}
      columns={[
        {
          key: 'id',
          title: 'ID',
          render: (record: any) => <Tooltip title={record.id}>{truncate(record.id, { length: 16, omission: '...' })}</Tooltip>
        },
        {
          key: 'kind',
          title: t('type', "Type"),
          render: (record: any) => t('task_' + record.kind, record.kind)
        },
        {
          key: 'details',
          title: t('details', "Details"),
          render: (record: any) => <div>
            {/*record.kind === 'initReattributeOrders' && <div>{t('conversion_rule', "Conversion rule")}: {projectCtx.currentProject.conversionRules.find((cr: any) => cr.id === record.crId).name}</div>*/}
            {record.kind === 'refreshSegment' && <span><Tag color={segmentsMap[record.segmentId] && segmentsMap[record.segmentId].color}>{segmentsMap[record.segmentId]?.name || record.segmentId}</Tag> (offset: {record.offset})</span>}
            {record.kind === 'reattributeUserConversions' && <span>Offset: {record.offset}</span>}
            {record.kind === 'reattributeCommissions' && <span>{projectCtx.currentProject.conversionRules.find((x: any) => x.id === record.conversionRuleId).name}</span>}
            {record.status === 'processing' && record.subTask === 'syncCustomers' && <p>Syncing customers (offset: {record.offset})</p>}
            {record.status === 'processing' && record.subTask === 'syncOrders' && <p>Syncing orders (offset: {record.offset})</p>}
            {record.kind === 'importAdmoData' && <p>Syncing from {Moment(record.fromDate).format('YYYY-MM-DD')} (offset: {record.offset})</p>}
            {record.kind === 'syncKlaviyoLists' && <p>Syncing list {record.payload.klaviyo.connectedListName} to {record.payload.klaviyo.connectedListNotificationTopicId}</p>}
            {record.output && record.output !== '' && <div className="margin-t-s">{t('output', "Output")}: {record.output}</div>}
          </div>
        },
        {
          key: 'createdAt',
          title: t('created_at', "Created at"),
          render: (record: any) => <span className="size-12">{Moment(record.createdAt).fromNow()}</span>
        },
        {
          key: 'updatedAt',
          title: t('updated_at', "Updated at"),
          render: (record: any) => <span className="size-12">{Moment(record.updatedAt).fromNow()}</span>
        },
        {
          key: 'status',
          title: t('status', "Status"),
          filters: [
            { text: t('started', "Started"), value: 'processing' },
            { text: t('done', "Done"), value: 'done' },
            { text: t('failed', "Failed"), value: 'failed' },
            { text: t('queued', "Queued"), value: 'queued' },
          ],
          filterMultiple: false,
          onFilter: (value, record) => record.status === value,
          render: (record: any) => {
            switch (record.status) {
              case 'processing':
                return <Badge status="processing" text={t('started', "Started")} />
              case 'done':
                return <Badge status="success" text={t('done', "Done")} />
              case 'failed':
                return <Badge status="error" text={t('failed', "Failed")} />
              default:
                return <Badge status="default" text={t('queued', "Queued")} />
            }
          }
        },
        {
          key: 'actions',
          title: <span className="pull-right"><Button type="ghost" size="small" onClick={fetchTasks} disabled={loading}><FontAwesomeIcon icon="sync-alt" spin={loading} /></Button></span>,
          render: () => ''
        },
      ]}
    />
  </AppLayout>
}

export default TasksList