import React, { useState } from 'react'

import t from '../../utils/translate'
import { Drawer, Button, Input, Select, Form, message } from 'antd';
import Moment from 'moment-timezone'
import { useAppContext, AppContextValue } from '../app'

const UserSettings = ({ toggleSettings }) => {

  const appCtx: AppContextValue = useAppContext()
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const onFinish = (values: any) => {

    setLoading(true)

    appCtx.ajaxRequest({
      method: 'post',
      url: '/admins.setProfile',
      data: values
    }, (errorMessage: any, response: any) => {

      if (errorMessage) {
        message.error(errorMessage)
        setLoading(false)
        return
      }

      if (window.cmAgent) {
        window.cmAgent.event({
          label: 'updateAdminSettings'
        })
        window.cmAgent.dispatch()
      }

      setLoading(false)
      appCtx.setAdmin(response.data.admin)
      toggleSettings()
      message.success(t('your_profile_has_been_updated', "Your profile has been updated!"))
    })
  }


  const timezones = Moment.tz.names()

  const initialValues = {
    firstName: appCtx.admin.firstName,
    lastName: appCtx.admin.lastName,
    timezone: appCtx.admin.timezone,
    language: appCtx.admin.language,
    picture: appCtx.firebaseUser.photoURL,
  }

  return <Drawer
    title={t('my_settings', "My settings")}
    visible={true}
    onClose={toggleSettings}
    width={600}>
    <Form form={form} initialValues={initialValues} onFinish={onFinish} layout="vertical">
      <Form.Item name="firstName" label={t('first_name', "First name")} rules={[{ required: true, type: 'string', message: t('field_required', "This field is required") }]}>
        <Input />
      </Form.Item>

      <Form.Item name="lastName" label={t('last_name', "Last name")} rules={[{ required: true, type: 'string', message: t('field_required', "This field is required") }]}>
        <Input />
      </Form.Item>

      <Form.Item name="timezone" label={t('time_zone', "Time zone")} rules={[{ required: true, type: 'enum', enum: timezones, message: 'This time zone is not valid.' }]}>
        <Select
          placeholder={t('select_a_value', "Select a value")}
          allowClear={true}
          showSearch={true}
          filterOption={(searchText: any, option: any) => {
            return searchText !== '' && option.key.toLowerCase().includes(searchText.toLowerCase())
          }}>
          {timezones.map((timezone) => {
            return <Select.Option key={timezone} value={timezone}>{timezone}</Select.Option>
          })}
        </Select>
      </Form.Item>

      <Form.Item name="language" label={t('language', "Language")} rules={[{ required: true, type: 'enum', enum: ['en', 'fr'], message: 'This language is not valid.' }]}>
        <Select
          placeholder={t('select_a_value', "Select a value")}
          allowClear={false}>
          <Select.Option key="en" value="en">English</Select.Option>
          <Select.Option key="fr" value="fr">Français</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item name="picture" label={t('photo_url', "Photo URL")} rules={[{ required: false, type: 'url', message: t('invalid_url', "This URL is not valid") }]}>
        <Input />
      </Form.Item>

      <div className="ant-drawer-footer">
        <Button style={{ marginRight: 8 }} onClick={toggleSettings}>{t('cancel', "Cancel")}</Button>
        <Button type="primary" loading={loading} htmlType="submit">{t('confirm', "Confirm")}</Button>
      </div>
    </Form>
  </Drawer>
}

export default UserSettings