import React, { useEffect } from 'react'

import t from '../../utils/translate'
import { RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Link, useHistory } from 'react-router-dom'
import Splash from '../app/_splash'
import { useAppContext, AppContextValue } from '../app'

const OrganizationsList = () => {

  const appCtx: AppContextValue = useAppContext()
  const history = useHistory() as any

  useEffect(() => {
    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'List organizations',
        page: '/organizations' // this.props.location.pathname
      })
      window.cmAgent.dispatch()
    }
  }, [])

  return (
    <Splash h1={t('select_an_organization', "Select an organization")}>
      <div className="list">
        {(appCtx.organizations.map((org: any, i: number) => {
          return (
            <Link className="list-item" to={'/organizations/' + org.id} key={org.id}>
              <div className="list-item-title">{org.name}</div>
              <div className="list-item-icon"><RightOutlined /></div>
            </Link>
          );
        }))}
      </div>

      {appCtx.admin.isSuperAdmin && <><div className="text-center padding-v-m">{t('or', "or")}</div>

        <div className="text-center">
          <Button type="ghost" onClick={() => history.push('/create-organization')}>{t('create_an_organization', "Create a new organization")}</Button>
        </div>
      </>}
    </Splash>
  )
}

export default OrganizationsList