const getPropTypes = (value: any) => {

  switch(Object.prototype.toString.call(value)) {

    case '[object Object]': 
      if (value['$date']) {
        return 'date'
      }
      break
      
    case '[object Boolean]': 
      return 'boolean'

    case '[object String]':
      const isoRegex = /-?([1-9][0-9]{3,}|0[0-9]{3})-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])T(([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9](\.[0-9]+)?|(24:00:00(\.0+)?))(Z|(\+|-)((0[0-9]|1[0-3]):[0-5][0-9]|14:00))?/

      if (isoRegex.test(value)) {
        return 'date'
      }
      else {
        return 'string'
      }

    case '[object Number]':
      return 'number'

    case '[object Array]': 
      return 'list'

    default:
  }

  return 'string'
}

export default getPropTypes