import React, { useState } from 'react'

import t from '../../utils/translate'
import { Button, Spin, Input, Modal, Form } from 'antd';
import _ from 'lodash'

const AdminsCreateServiceAccountButton = (props: any) => {

  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm();

  const toggleModal = () => {
    setVisible(!visible)
  }

  const onSubmit = () => {

    form.validateFields().then((values: any) => {
      form.resetFields()
      setLoading(true)

      props.app.ajaxRequest({
        method: 'post',
        url: '/organizations.createServiceAccount',
        data: {
          organizationId: props.organization.id,
          name: values.name,
          emailId: values.emailId
        }
      }, (errorMessage: any) => {

        if (errorMessage) {
          props.app.addNotification('error', 'Create service account error', errorMessage)
          setLoading(false)
          return
        }

        if (window.cmAgent) {
          window.cmAgent.event({
            label: 'createServieAccount',
            props: {
              orgId: props.organization.id,
            }
          })
          window.cmAgent.dispatch()
        }

        setLoading(false)
        setVisible(false)
      })
    }).catch((e: any) => {
      console.error(e)
    })
  }

  return <span className="margin-r-m">
    <Button size={props.size} type={props.type || 'default'} style={props.style || {}} onClick={toggleModal}>{t('create_a_service_account', "Create a Service Account")}</Button>

    {visible &&
      <Modal
        title={t('create_a_service_account', "Create a Service Account")}
        wrapClassName="vertical-center-modal"
        visible={true}
        onCancel={toggleModal}
        footer={[
          <Button key="back" type="ghost" loading={loading} onClick={toggleModal}>{t('cancel', "Cancel")}</Button>,
          <Button key="submit" type="primary" loading={loading} onClick={onSubmit}>{t('confirm', "Confirm")}</Button>,
        ]}>

        <Spin tip="Loading..." spinning={loading}>
          <Form form={form} layout="vertical">
            <Form.Item
              name="name"
              label={t('name', "Name")}
              rules={[{ required: true, message: t('field_required', "This field is required!") }]}
            >
              <Input onChange={(e: any) => {
                const name = e.target.value
                if (name) {
                  let newId = _.camelCase(name).toLowerCase()
                  if (newId !== '') {
                    form.setFieldsValue({ 'emailId': newId })
                  }
                }
              }} />
            </Form.Item>

            <Form.Item
              name="emailId"
              label="ID"
              rules={[{ type: 'string', required: true, pattern: /^[a-z0-9]+$/, message: t('field_required', "This field is required") }]}
              shouldUpdate
            >
              <Input addonAfter={<span className="size-12">.{props.organization.id}.sa@captainmetrics.com</span>} />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    }
  </span>
}

export default AdminsCreateServiceAccountButton