import React, { Component, useState } from 'react'

import { Button, Badge, Popconfirm, Switch, Select, Form, Tooltip, Modal, DatePicker } from 'antd';
import { AmazonOutlined } from '@ant-design/icons';
import t from '../../utils/translate'
// import _ from 'lodash'
import Moment from 'moment-timezone'
import Languages from '../../utils/languages'
import logoAmazon from '../../images/integrations/amazon.png'
import Cookies from '../../utils/cookies'
import Config from '../../config'
// import Doc from '../docs/_doc'

const ConnectAmazon = ({ app, projectLayout, organizationLayout, integration }: any) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()

    const goToOAuth = (values: any) => {
        setLoading(true)


        const state = {
            ts: new Date().getTime(),
            oid: projectLayout.project.organizationId,
            pid: projectLayout.project.id,
            iid: integration.id,
            end: values.endpoint,
        }

        // amazon code is valid 30 secs only
        Cookies.setItem('amazon_oauth_state', JSON.stringify(state), 60, '/', null, true)

        let oauthURL = 'https://sellercentral.amazon.com/apps/authorize/consent?application_id=' + Config().amazon_app_id + '&state=' + state.ts

        if (Config().env === 'development') {
            Cookies.setItem('amazon_oauth_state_dev', JSON.stringify(state), 60, '/', null, false)
            oauthURL += '&version=beta'
        }

        window.setTimeout(() => {
            window.location.href = oauthURL
        }, 50)
    }

    return <>

        <h2 className="margin-t-m">{t('setup', "Setup")}</h2>
        <div style={{ width: '500px', margin: '32px auto' }}>
            <div className="padding-b-xl text-center">
                <img src={logoAmazon} alt="" style={{ height: '200px' }} />

                <Form form={form} layout="vertical" onFinish={goToOAuth}>
                    <Form.Item name="endpoint" label={t('region', "Region") + ':'} rules={[{ required: true, type: 'string' }]}>
                        <Select options={[
                            { label: 'North America (Canada, US, Mexico, and Brazil marketplaces)', value: 'https://sellingpartnerapi-na.amazon.com' },
                            { label: 'Europe (Spain, UK, France, Netherlands, Germany, Italy, Sweden, Poland, Turkey, U.A.E, and India marketplaces)', value: 'https://sellingpartnerapi-eu.amazon.com' },
                            { label: 'Far East (Singapore, Australia, and Japan marketplaces)', value: 'https://sellingpartnerapi-fe.amazon.com' },
                        ]} />
                    </Form.Item>

                    <div className="lwa"></div>
                    <Button size="large" block type="primary" htmlType="submit" loading={loading}><AmazonOutlined /> {t('login_with_amazon', "Login with Amazon")}</Button>
                </Form>
            </div>
        </div>
    </>
}

const SyncModal = ({ app, projectLayout, organizationLayout, integration }: any) => {
    const [loading, setLoading] = useState(false)
    // const [form] = Form.useForm()
    const [modalVisible, setModalVisible] = useState(false)
    const [syncFrom, setSyncFrom] = useState(Moment().tz('UTC').subtract(30, 'days'))
    const [syncTo, setSyncTo] = useState(Moment().tz('UTC'))

    return <>
        <Button loading={loading} onClick={() => setModalVisible(true)}>Sync now</Button>

        <Modal
            title="Sync amazon orders"
            okText="Start sync"
            visible={modalVisible}
            onCancel={() => setModalVisible(false)}
            onOk={() => {
                if (loading) return

                setLoading(true)

                app.ajaxRequest({
                    method: 'post',
                    url: '/integrations.syncAmazonOrders',
                    data: {
                        projectId: projectLayout.project.id,
                        integrationId: integration.id,
                        fromDate: syncFrom.toISOString(),
                        toDate: syncTo.toISOString(),
                    }
                }, (errorMessage: any, _response: any) => {

                    if (errorMessage) {
                        app.addMessage('error', errorMessage)
                        setLoading(false)
                        return
                    }

                    app.addMessage('success', t('sync_launched', 'The sync has been launched!'))
                    setLoading(false)
                    setModalVisible(false)
                })
            }}
        >
            <p>From date:</p>
            <div className="margin-b-m">
                <DatePicker value={syncFrom} allowClear={false} autoFocus={true} showTime onChange={(date: any) => setSyncFrom(date)} /> UTC
            </div>
            <p>To date:</p>
            <div className="margin-b-m">
                <DatePicker value={syncTo} allowClear={false} autoFocus={true} showTime onChange={(date: any) => setSyncTo(date)} /> UTC
            </div>
        </Modal>
    </>
}

class IntegrationsAmazon extends Component<any, any> {
    _isMounted = false;

    constructor(props: any) {
        super(props)

        this.state = {
            loading: false,
        }

        this.syncCustomers = this.syncCustomers.bind(this)
        this.syncProducts = this.syncProducts.bind(this)
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    syncCustomers() {
        if (this.state.loading) {
            return
        }

        this.setState({ loading: true })

        this.props.app.ajaxRequest({
            method: 'post',
            url: '/integrations.syncAmazonCustomers',
            data: {
                projectId: this.props.projectLayout.project.id,
                integrationId: this.props.integration.id,
            }
        }, (errorMessage: any, response: any) => {

            if (errorMessage) {
                this.props.app.addMessage('error', errorMessage)
                this.setState({ loading: false })
                return
            }
            this.props.app.addMessage('success', t('task_launched', "Task launched!"))

            this.setState({ loading: false })

            this.props.history.push('/organizations/' + this.props.organizationLayout.organization.id + '/projects/' + this.props.projectLayout.project.id + '/tasks')
        })
    }

    syncProducts() {
        if (this.state.loading) {
            return
        }

        this.setState({ loading: true })

        this.props.app.ajaxRequest({
            method: 'post',
            url: '/tasks.create',
            data: {
                projectId: this.props.projectLayout.project.id,
                task: 'syncAmazonProducts',
                integrationId: this.props.integration.id,
            }
        }, (errorMessage: any, response: any) => {

            if (errorMessage) {
                this.props.app.addMessage('error', errorMessage)
                this.setState({ loading: false })
                return
            }
            this.props.app.addMessage('success', t('task_launched', "Task launched!"))

            this.setState({ loading: false })

            this.props.history.push('/organizations/' + this.props.organizationLayout.organization.id + '/projects/' + this.props.projectLayout.project.id + '/tasks')
        })
    }

    render() {
        // const info = integrations.find((int) => int.id === this.props.integration)

        const timezones = Moment.tz.names()

        return <div>

            {(this.props.integration.status === 'setup') || (this.props.integration.status === 'disconnected') ?
                <ConnectAmazon
                    integration={this.props.integration}
                    app={this.props.app}
                    organizationLayout={this.props.organizationLayout}
                    projectLayout={this.props.projectLayout}
                />
                :
                <div className="margin-v-m">
                    <h2 className="margin-t-m">{t('settings', "Settings")}</h2>

                    <table className="key-value-table">
                        <tbody>
                            <tr>
                                <th style={{ width: '400px' }}>{t('integration_id', "Integration ID")}</th>
                                <td>{this.props.integration.id}</td>
                            </tr>
                            <tr>
                                <th>{t('status', "Status")}</th>
                                <td>{this.props.integration.status === 'connected' && <span>
                                    <Badge status="success" /> {t('connected', "Connected")}
                                    <Popconfirm placement="top" title={t('disconnect_amazon_confirm', "Do you really want to disconnect Amazon? your data won't be synced anymore!")} onConfirm={this.props.disconnect.bind(null, this.props.integration)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                                        <Button className="margin-l-m" loading={this.state.loading} ghost size="small" danger={true}>{t('disconnect', "Disconnect")}</Button>
                                    </Popconfirm>
                                </span>}</td>
                            </tr>
                            <tr>
                                <th>{t('region_endpoint', "Region endpoint")}</th>
                                <td>{this.props.integration.amazonSettings.endpoint}</td>
                            </tr>
                            <tr>
                                <th>{t('is_amazon_users_source', "Is Amazon your main source of users?")}</th>
                                <td>
                                    <Popconfirm placement="top" title={t('toggle_main_source', "Do you really want to change the status of this source of users?")} onConfirm={this.props.toggleMainSource.bind(null, this.props.integration)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                                        <Switch checked={this.props.integration.amazonSettings.isMainSource} loading={this.state.loading} />
                                    </Popconfirm>
                                </td>
                            </tr>
                            {/* <tr>
                                <th>{t('geocode_customers_addresses', "Geocode customers addresses?")}</th>
                                <td>
                                    <Switch checked={this.props.integration.amazonSettings.geocodeAddresses} loading={this.state.loading} onChange={this.props.toggleGeocodeAddresses.bind(null, this.props.integration)} />
                                </td>
                            </tr>
                            <tr>
                                <th>{t('geocode_customers_time_zones', "Geocode customers time zones?")}</th>
                                <td>
                                    <Switch checked={this.props.integration.amazonSettings.geocodeTimezones} loading={this.state.loading} onChange={this.props.toggleGeocodeTimezones.bind(null, this.props.integration)} />
                                </td>
                            </tr> */}
                            <tr>
                                <th>{t('orders_conversion_rule', "Orders conversion rule")}</th>
                                <td>
                                    <Select
                                        style={{ width: '200px' }}
                                        placeholder={t('select_a_value', "Select a value")}
                                        allowClear={false}
                                        showSearch={true}
                                        value={this.props.integration.amazonSettings.conversionRuleId}
                                        disabled={this.state.loading}
                                        onChange={this.props.updateConversionRule.bind(null, this.props.integration)}>
                                        {this.props.projectLayout.project.conversionRules.filter((cr: any) => cr.kind === "order").map((cr: any) => {
                                            return <Select.Option key={cr.id} value={cr.id}>{cr.name}</Select.Option>
                                        })}
                                    </Select>
                                </td>
                            </tr>
                            <tr>
                                <th>{t('orders_conversion_domain', "Orders conversion domain")}</th>
                                <td>
                                    <Select
                                        style={{ width: '200px' }}
                                        placeholder={t('select_a_value', "Select a value")}
                                        allowClear={false}
                                        value={this.props.integration.amazonSettings.conversionDomainId}
                                        disabled={this.state.loading}
                                        onChange={this.props.updateConversionDomain.bind(null, this.props.integration)}>
                                        {this.props.projectLayout.project.domains.filter((d: any) => !d.deletedAt).map((d: any) => {
                                            return <Select.Option key={d.id} value={d.id}>{d.name} - {d.kind}</Select.Option>
                                        })}
                                    </Select>
                                </td>
                            </tr>
                            <tr>
                                <th>{t('default_timezone', "Default customers time zone")}</th>
                                <td>
                                    <Select
                                        style={{ width: '200px' }}
                                        placeholder={t('select_a_value', "Select a value")}
                                        allowClear={false}
                                        showSearch={true}
                                        value={this.props.integration.amazonSettings.defaultTimezone}
                                        disabled={this.state.loading}
                                        onChange={this.props.updateDefaultTimezone.bind(null, this.props.integration)}
                                        filterOption={(searchText: any, option: any) => {
                                            return searchText !== '' && option?.key?.toLowerCase().includes(searchText.toLowerCase())
                                        }}>
                                        {timezones.map((timezone) => {
                                            return <Select.Option key={timezone} value={timezone}>{timezone}</Select.Option>
                                        })}
                                    </Select>
                                </td>
                            </tr>
                            <tr>
                                <th>{t('default_language', "Default customers language")}</th>
                                <td>
                                    <Select
                                        style={{ width: '200px' }}
                                        placeholder={t('select_a_value', "Select a value")}
                                        allowClear={false}
                                        showSearch={true}
                                        value={this.props.integration.amazonSettings.defaultLanguage}
                                        disabled={this.state.loading}
                                        onChange={this.props.updateDefaultLanguage.bind(null, this.props.integration)}
                                        filterOption={(searchText: any, option: any) => {
                                            return searchText !== '' && option.key.toLowerCase().includes(searchText.toLowerCase())
                                        }}>
                                        {Languages.map((language) => {
                                            return <Select.Option key={language.alpha2} value={language.alpha2}>{language.alpha2 + ' - ' + language.name}</Select.Option>
                                        })}
                                    </Select>
                                </td>
                            </tr>
                            {this.props.integration.amazonSettings.lastSyncAt && <tr>
                                <th>{t('last_sync', "Last sync")}</th>
                                <td><Tooltip title={Moment(this.props.integration.amazonSettings.lastSyncAt).tz(this.props.app.state.admin.timezone).format('lll') + ' (' + this.props.app.state.admin.timezone + ')'}>{Moment(this.props.integration.amazonSettings.lastSyncAt).fromNow()}</Tooltip></td>
                            </tr>}
                            <tr>
                                <th>{t('next_sync', "Next sync")}</th>
                                <td><Tooltip title={Moment(this.props.integration.amazonSettings.nextSyncAt).tz(this.props.app.state.admin.timezone).format('lll') + ' (' + this.props.app.state.admin.timezone + ')'}>{Moment(this.props.integration.amazonSettings.nextSyncAt).fromNow()}</Tooltip></td>
                            </tr>
                            <tr>
                                <th>{t('actions', "Actions")}</th>
                                <td>
                                    <SyncModal {...this.props} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }

        </div>
    }
}

export default IntegrationsAmazon