import React, { Component } from 'react'

import { ReloadOutlined } from '@ant-design/icons';

import {
  Button,
  Tooltip,
  Row,
  Col,
} from 'antd';
import t from '../../../utils/translate'
import Moment from 'moment-timezone'
import QS from 'qs'
import DateRange from '../../projects/_dateRange'
import PartnershipSelect from './_partnershipSelect'
import KPI from '../../dashboard/kpi'
import PerPartnership from './_partnershipsTable'
import TopProducts from './_topProducts'
import TopLandingPages from './_topLandingPages'
import AppLayout from 'components/app/layout';

class AnalyticsMediaPartners extends Component<any, any> {

  constructor(props: any) {
    super(props)

    this.state = {
      dateRangeVs: 'previous_period',
      dateRangeLabel: 'last_30_days',
      dateRangeValue: [Moment().tz(this.props.app.state.admin.timezone).subtract(30, 'day').startOf('day'), Moment().tz(this.props.app.state.admin.timezone).endOf('day')],
      refreshAt: Moment().unix(),
    }

    this.setDateRange = this.setDateRange.bind(this)
    this.setDateRangeVs = this.setDateRangeVs.bind(this)
    this.setPartnership = this.setPartnership.bind(this)
    this.updateParams = this.updateParams.bind(this)
    this.refresh = this.refresh.bind(this)
  }

  componentDidMount() {
    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Analytics Media Partners (project: ' + this.props.projectLayout.project.id + ')',
        page: this.props.location.pathname,
        props: {
          orgId: this.props.projectLayout.project.organizationId,
          projectId: this.props.projectLayout.project.id
        }
      })
      window.cmAgent.dispatch()
    }
  }

  refresh() {
    this.setState({ refreshAt: Moment().unix() })
  }

  setDateRange(label: string, range: any) {
    this.setState({ dateRangeValue: range, dateRangeLabel: label })
    this.refresh()
  }

  setDateRangeVs(value: any) {
    this.setState({ dateRangeVs: value })
    this.refresh()
  }

  updateParams(updatedParams: any) {
    let params = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })
    updatedParams.forEach((up: any) => {
      params[up.k] = up.v
    })
    this.props.history.push('/organizations/' + this.props.match.params.organizationId + '/projects/' + this.props.match.params.projectId + '/analytics/media-partners?' + QS.stringify(params))
  }

  setPartnership(partnership: any) {
    this.updateParams([{ k: 'partnership', v: partnership }])
  }

  render() {

    const params: any = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })

    const dateFrom = Moment(this.state.dateRangeValue[0])
    const dateTo = Moment(this.state.dateRangeValue[1])

    let dateFromPrevious: any = Moment(dateFrom)
    let dateToPrevious: any = Moment(dateTo)

    if (this.state.dateRangeVs === "previous_period") {

      // add one day to the diff to avoid days overlaping between the to ranges
      const diff = dateTo.diff(dateFrom, 'days') + 1
      // console.log('diff is', diff);

      dateToPrevious = dateToPrevious.subtract(diff, 'days')
      dateFromPrevious = dateFromPrevious.subtract(diff, 'days')
    }

    if (this.state.dateRangeVs === "previous_year") {
      dateToPrevious = dateToPrevious.subtract(1, 'years')
      dateFromPrevious = dateFromPrevious.subtract(1, 'years')
    }

    dateFromPrevious = dateFromPrevious.format('YYYY-MM-DD')
    dateToPrevious = dateToPrevious.format('YYYY-MM-DD')

    const filters: any = [{
      member: 'TimelineSessions.commissionPartnershipId',
      operator: 'set',
      // values: this.props.projectLayout.project.domains.filter((x: any) => x.kind === 'web' || x.kind === 'app').map((x: any) => x.id)
    }]

    if (params.partnership && params.partnership !== '_all') {
      filters[0].operator = 'equals'
      filters[0].values = [params.partnership]
    }

    return <AppLayout currentProject={this.props.projectLayout.project} currentOrganization={this.props.organizationLayout.organization} admin={this.props.app.state.admin} firebaseUser={this.props.app.state.firebaseUser} projects={this.props.organizationLayout.projects}>

      <h1>
        <span className="actions">
          <span className="padding-l-s"><PartnershipSelect ajaxRequest={this.props.app.ajaxRequest} projectId={this.props.projectLayout.project.id} value={params.partnership} onChange={this.setPartnership} /></span>
          <span className="padding-l-s">
            <DateRange {...this.props}
              timezone={this.props.app.state.admin.timezone}
              vs={this.state.dateRangeVs}
              dateRange={this.state.dateRangeValue}
              labelKey={this.state.dateRangeLabel}
              onVsChange={this.setDateRangeVs}
              onChange={this.setDateRange} />
            <Tooltip placement="left" title={t('last_refresh_at', "Refreshed {at}", { at: Moment.unix(this.state.refreshAt).fromNow() })}>
              <Button className="margin-l-s" size="small" type="default" shape="circle" icon={<ReloadOutlined />} onClick={this.refresh} disabled={this.state.loadingSessions} />
            </Tooltip>
          </span>
        </span>
        Media Partners Analytics
      </h1>

      <div className="dash-kpi-container">
        <KPI
          title={t('orders', "Orders")}
          measure="TimelineOrders.count"
          timeDimension="TimelineOrders.truncCreatedAt"
          filters={filters}
          color="cyan"
          projectId={this.props.projectLayout.project.id}
          cubejsApi={this.props.projectLayout.cubejsApi}
          timezone={this.props.app.state.admin.timezone}
          refreshAt={this.state.refreshAt}
          dateFrom={dateFrom.format('YYYY-MM-DD')}
          dateTo={dateTo.format('YYYY-MM-DD')}
          dateFromPrevious={dateFromPrevious}
          dateToPrevious={dateToPrevious}
          addMessage={this.props.app.addMessage}
        />
        <KPI
          title={t('revenue', "Revenue")}
          measure="TimelineOrders.revenue"
          timeDimension="TimelineOrders.truncCreatedAt"
          filters={filters}
          color="cyan"
          kind="currency"
          currency={this.props.projectLayout.project.currency}
          projectId={this.props.projectLayout.project.id}
          cubejsApi={this.props.projectLayout.cubejsApi}
          timezone={this.props.app.state.admin.timezone}
          refreshAt={this.state.refreshAt}
          dateFrom={dateFrom.format('YYYY-MM-DD')}
          dateTo={dateTo.format('YYYY-MM-DD')}
          dateFromPrevious={dateFromPrevious}
          dateToPrevious={dateToPrevious}
          addMessage={this.props.app.addMessage}
        />
        <KPI
          title={t('avg_cart', "Avg. cart")}
          measure="TimelineOrders.averageCart"
          timeDimension="TimelineOrders.truncCreatedAt"
          filters={filters}
          color="cyan"
          kind="currency"
          currency={this.props.projectLayout.project.currency}
          projectId={this.props.projectLayout.project.id}
          cubejsApi={this.props.projectLayout.cubejsApi}
          timezone={this.props.app.state.admin.timezone}
          refreshAt={this.state.refreshAt}
          dateFrom={dateFrom.format('YYYY-MM-DD')}
          dateTo={dateTo.format('YYYY-MM-DD')}
          dateFromPrevious={dateFromPrevious}
          dateToPrevious={dateToPrevious}
          addMessage={this.props.app.addMessage}
        />
        <KPI
          title="CPC"
          measure="TimelineSessions.cpc"
          timeDimension="TimelineSessions.truncCreatedAt"
          kind="currency"
          currency={this.props.projectLayout.project.currency}
          filters={filters}
          color="cyan"
          projectId={this.props.projectLayout.project.id}
          cubejsApi={this.props.projectLayout.cubejsApi}
          timezone={this.props.app.state.admin.timezone}
          refreshAt={this.state.refreshAt}
          dateFrom={dateFrom.format('YYYY-MM-DD')}
          dateTo={dateTo.format('YYYY-MM-DD')}
          dateFromPrevious={dateFromPrevious}
          dateToPrevious={dateToPrevious}
          addMessage={this.props.app.addMessage}
        />
        <KPI
          title="CPA / CPL"
          measure="TimelineSessions.cpa"
          timeDimension="TimelineSessions.truncCreatedAt"
          kind="currency"
          currency={this.props.projectLayout.project.currency}
          filters={filters}
          color="cyan"
          projectId={this.props.projectLayout.project.id}
          cubejsApi={this.props.projectLayout.cubejsApi}
          timezone={this.props.app.state.admin.timezone}
          refreshAt={this.state.refreshAt}
          dateFrom={dateFrom.format('YYYY-MM-DD')}
          dateTo={dateTo.format('YYYY-MM-DD')}
          dateFromPrevious={dateFromPrevious}
          dateToPrevious={dateToPrevious}
          addMessage={this.props.app.addMessage}
        />
        <KPI
          title={t('avg_commission_rate', "Avg. commission rate")}
          measure="TimelineSessions.averageCommissionRate"
          timeDimension="TimelineSessions.truncCreatedAt"
          kind="percentage"
          filters={filters}
          color="cyan"
          projectId={this.props.projectLayout.project.id}
          cubejsApi={this.props.projectLayout.cubejsApi}
          timezone={this.props.app.state.admin.timezone}
          refreshAt={this.state.refreshAt}
          dateFrom={dateFrom.format('YYYY-MM-DD')}
          dateTo={dateTo.format('YYYY-MM-DD')}
          dateFromPrevious={dateFromPrevious}
          dateToPrevious={dateToPrevious}
          addMessage={this.props.app.addMessage}
          isLast={true}
        />
      </div>

      <div className="block margin-t-l">
        <PerPartnership
          project={this.props.projectLayout.project}
          cubejsApi={this.props.projectLayout.cubejsApi}
          timezone={this.props.app.state.admin.timezone}
          refreshAt={this.state.refreshAt}
          dateFrom={dateFrom.format('YYYY-MM-DD')}
          dateTo={dateTo.format('YYYY-MM-DD')}
          dateFromPrevious={dateFromPrevious}
          dateToPrevious={dateToPrevious}
          addMessage={this.props.app.addMessage}
          filters={filters}
        />
      </div>

      <Row className="margin-t-l" gutter={24}>
        <Col span={12}>
          <div className="block">
            <TopProducts
              project={this.props.projectLayout.project}
              cubejsApi={this.props.projectLayout.cubejsApi}
              timezone={this.props.app.state.admin.timezone}
              refreshAt={this.state.refreshAt}
              dateFrom={dateFrom.format('YYYY-MM-DD')}
              dateTo={dateTo.format('YYYY-MM-DD')}
              dateFromPrevious={dateFromPrevious}
              dateToPrevious={dateToPrevious}
              addMessage={this.props.app.addMessage}
              filters={filters}
            />
          </div>
        </Col>

        <Col span={12}>
          <div className="block">
            <TopLandingPages
              project={this.props.projectLayout.project}
              cubejsApi={this.props.projectLayout.cubejsApi}
              timezone={this.props.app.state.admin.timezone}
              refreshAt={this.state.refreshAt}
              dateFrom={dateFrom.format('YYYY-MM-DD')}
              dateTo={dateTo.format('YYYY-MM-DD')}
              dateFromPrevious={dateFromPrevious}
              dateToPrevious={dateToPrevious}
              addMessage={this.props.app.addMessage}
              filters={filters}
            />
          </div>
        </Col>
      </Row>
    </AppLayout>
  }
}

export default AnalyticsMediaPartners