const CreateDataWithCurrentPrevious = (currentData: any, previousData: any, keyName: string) => {

    return currentData.map((currentLine: any) => {

        const previousLine = previousData.find((x: any) => x[keyName] === currentLine[keyName])

        const previous: any = {}
        
        Object.keys(currentLine).forEach((key: any) => {
            previous[key] = previousLine ? previousLine[key] : 0
        })

        const itemData: any = {
            id: currentLine[keyName],
            current: currentLine,
            previous: previous
        }

        return itemData
    })
}

const exported = {
    Blender: (destinationData: any, mergedData: any, keysName: any, defaultValues: any) => {

        destinationData.forEach((item: any) => {
            const index = mergedData.findIndex((x: any) => {
                let matched = true
                keysName.forEach((key: any) => {
                    if (x[key] !== item[key]) {
                        matched = false
                    }
                })
                return matched
            })

            // default values if not found
            if (index === -1) {
                defaultValues.forEach((x: any) => {
                    item[x.key] = x.value
                })
            } else {
                defaultValues.forEach((x: any) => {
                    item[x.key] = mergedData[index][x.key]
                })
            }
        })
    },

    CreateDataWithCurrentPrevious: CreateDataWithCurrentPrevious,

    AddDrilldownToData: (data: any, currentDrilldownData: any, previousDrilldownData: any, keyName: string, drilldownKeyName: string) => {
        // mutate the data
        data.forEach((dataLine: any, dataKey: number) => {
            if (dataLine.id === '_all') return
            
            const currentData = currentDrilldownData.filter((x: any) => x[keyName] === dataLine.id)
            const previousData = previousDrilldownData.filter((x: any) => x[keyName] === dataLine.id)

            // init children
            data[dataKey].children = CreateDataWithCurrentPrevious(currentData, previousData, drilldownKeyName)
        })
    }
}

export default exported