import React, { useState } from 'react'
import t from '../../utils/translate'
import { Button, Modal, Select, Radio, Input, InputNumber, DatePicker, Form } from 'antd';
import Moment from 'moment-timezone'
import GetPropertyType from '../../utils/getPropertyType'

const PropertyButton = (props: any) => {

  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()

  const toggleModal = () => {
    setVisible(!visible)
  }

  const timezones = Moment.tz.names()

  // console.log('props', props);
  let initialValues = {
    type: GetPropertyType(props.propertyValue),
    key: props.propertyKey,
    stringvalue: props.propertyValue,
    numbervalue: props.propertyValue,
    booleanvalue: props.propertyValue,
    listvalue: props.propertyValue,
    datevalue: props.propertyValue && Moment(props.propertyValue['$date'] ? props.propertyValue['$date'] : props.propertyValue).tz(props.propertyValue['$timezone'] ? props.propertyValue['$timezone'] : 'UTC'),
    datetimezone: props.propertyValue && props.propertyValue['$timezone'] ? props.propertyValue['$timezone'] : 'UTC',
  }

  return <>
    <Button onClick={toggleModal} type={props.type || 'ghost'} size={props.size || 'default'}>{props.children}</Button>

    {visible && <Modal
      title={(props.property) ? t('update_a_property', 'Update a attribute') : t('new_property', "New property")}
      width="900px"
      style={{ top: 20 }}
      visible={true}
      maskClosable={false}
      onCancel={toggleModal}
      footer={[
        <Button key="back" onClick={toggleModal}>{t('cancel', "Cancel")}</Button>,
        <Button key="submit" type="primary" onClick={() => {
          form.validateFields().then((values: any) => {

            // format values
            let property: any = {
              type: values.type,
              key: values.key
            }

            switch (values.type) {
              case 'string':
                property.value = values.stringvalue
                break
              case 'number':
                property.value = values.numbervalue
                break
              case 'date':
                property.value = { '$date': values.datevalue, '$timezone': values.datetimezone }
                break
              case 'boolean':
                property.value = values.booleanvalue
                break
              case 'list':
                property.value = values.listvalue
                break
              default:
                break
            }

            form.resetFields()
            setVisible(false)

            props.onComplete(property)
          }).catch(info => {
            console.log('Validate Failed:', info);
          });
        }}>{t('confirm', "Confirm")}</Button>,
      ]}
    >
      <Form initialValues={initialValues} form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 13 }} layout="horizontal">

        <Form.Item label="Type" name="type">
          <Radio.Group size="middle">
            <Radio.Button value="string">{t('type_string', "string")}</Radio.Button>
            <Radio.Button value="number">{t('type_number', "number")}</Radio.Button>
            <Radio.Button value="date">{t('type_date', "date")}</Radio.Button>
            <Radio.Button value="boolean">{t('type_boolean', "true / false")}</Radio.Button>
            <Radio.Button value="list">{t('type_list', "list")}</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item name="key" label={t('key', "Key")} rules={[{ required: true, type: 'string', pattern: /^[a-zA-Z_]([a-zA-Z0-9_.-]*[a-zA-Z0-9_])?$/, message: 'The key should be written like-this or likeThis or like_this.' }]}>
          <Input placeholder="written-like-this [a-zA-Z_]" />
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
          {(funcs) => {
            const fieldType = funcs.getFieldValue('type')
            switch (fieldType) {
              case 'string':
                return <Form.Item name="stringvalue" label={t('value', "Value")} hasFeedback rules={[{ required: true, type: 'string', message: 'This field is required.' }]}>
                  <Input />
                </Form.Item>

              case 'number':
                return <Form.Item name="numbervalue" label={t('value', "Value")} hasFeedback rules={[{ required: true, type: 'number', message: 'This field is required.' }]}>
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>

              case 'boolean':
                return <Form.Item name="booleanvalue" label={t('value', "Value")} rules={[{ required: true, type: 'boolean', message: 'This field is required.' }]}>
                  <Radio.Group size="middle">
                    <Radio.Button value={true}>True</Radio.Button>
                    <Radio.Button value={false}>False</Radio.Button>
                  </Radio.Group>
                </Form.Item>

              case 'list':
                return <Form.Item name="listvalue" label={t('value', "Value")} rules={[{ required: true, type: 'array', message: 'This field is required.' }]}>
                  <Select mode="tags" style={{ width: '100%' }} placeholder={t('press_enter_to_add_value', "Press enter to add a value")}></Select>
                </Form.Item>

              case 'date':
                return <>
                  <Form.Item name="datevalue" label={t('value', "Value")} rules={[{ required: true, type: 'object', message: 'This field is required.' }]}>
                    <DatePicker
                      style={{ width: '100%' }}
                      showTime
                      format="YYYY-MM-DD HH:mm:ss"
                      placeholder="Select Date & Time"
                    />
                  </Form.Item>

                  <Form.Item name="datetimezone" label={t('time_zone', "Time zone")} rules={[{ required: true, type: 'enum', enum: timezones, message: 'This time zone is not valid.' }]}>
                    <Select
                      style={{ width: '100%' }}
                      placeholder="Select a time zone..."
                      showSearch={true}
                      filterOption={(searchText: any, option: any) => {
                        return searchText !== '' && option.key.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
                      }}>
                      {timezones.map((timezone) => {
                        return <Select.Option key={timezone} value={timezone}>{timezone}</Select.Option>
                      })}
                    </Select>
                  </Form.Item>
                </>
              default:
                console.error('type not implemented', fieldType)
            }
          }}
        </Form.Item>
      </Form>
    </Modal>}
  </>
}

export default PropertyButton
